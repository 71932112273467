import React from "react";
import {
  WidgetControlButton,
  ButtonGroup,
} from "components/ui_components/buttons";
import WidgetType1 from "components/ui_components/Widgets/WidgetType1/WidgetType1";

const WidgetEditable = ({
  viewContent,
  editContent,
  handleSubmit,
  isEditMode,
  setEditMode,
  ...rest
}) => {
  const controls = (
    <ButtonGroup>
      {isEditMode && (
        <WidgetControlButton
          variant="outlined"
          onClick={setEditMode.bind(this, false)}
        >
          Cancel
        </WidgetControlButton>
      )}

      <WidgetControlButton
        onClick={isEditMode ? handleSubmit : setEditMode.bind(this, true)}
      >
        {isEditMode ? "Save" : "Edit"}
      </WidgetControlButton>
    </ButtonGroup>
  );

  return (
    <WidgetType1
      {...rest}
      content={isEditMode ? editContent : viewContent}
      headerRightContent={controls}
    />
  );
};

export default WidgetEditable;
