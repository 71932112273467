import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FastingIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M13.4,0c-0.4,0-0.7,0.3-0.7,0.7v9.9c0,0,0,0,0,0c0,0.4,0.3,0.7,0.7,0.7h9.9c0.4,0,0.7-0.3,0.7-0.7
	C24,4.8,19.2,0,13.4,0z M19.4,3.6l-1,1c-1.2-1-2.6-1.6-4.2-1.8V1.4C16.1,1.6,17.9,2.4,19.4,3.6z M14.1,4.3c1.2,0.1,2.3,0.6,3.2,1.3
	l-3.2,3.2V4.3z M18.3,6.6c0.7,0.9,1.2,2,1.3,3.2h-4.6L18.3,6.6z M21.1,9.9c-0.1-1.6-0.8-3.1-1.8-4.2l1-1c1.2,1.4,2,3.3,2.2,5.3H21.1
	z M10.6,24L10.6,24L10.6,24c5.8,0,10.5-4.8,10.5-10.6c0-0.4-0.3-0.7-0.7-0.7h-9.1V3.6c0-0.4-0.3-0.7-0.7-0.7C4.8,2.9,0,7.6,0,13.4
	C0,19.2,4.8,24,10.6,24z M3.6,7.4l1,1c-1,1.2-1.6,2.6-1.8,4.2H1.4C1.6,10.7,2.4,8.9,3.6,7.4z M9.9,19.7c-1.2-0.1-2.3-0.6-3.2-1.3
	l3.2-3.2V19.7z M11.3,15.1l3.2,3.2c-0.9,0.7-2,1.2-3.2,1.3V15.1z M5.7,17.4c-0.7-0.9-1.2-2-1.3-3.2h4.6L5.7,17.4z M5.6,19.3
	c1.2,1,2.6,1.6,4.2,1.8v1.5c-2-0.2-3.8-1-5.3-2.2L5.6,19.3z M11.3,21.1c1.6-0.1,3.1-0.8,4.2-1.8l1,1c-1.4,1.2-3.3,2-5.3,2.2V21.1z
	 M12.3,14.1h4.6c-0.1,1.2-0.6,2.3-1.3,3.2L12.3,14.1z M9.9,11.7L6.6,8.5c0.9-0.7,2-1.2,3.2-1.3V11.7z M8.9,12.7H4.3
	c0.1-1.2,0.6-2.3,1.3-3.2L8.9,12.7z M2.9,14.1c0.1,1.6,0.8,3.1,1.8,4.2l-1,1c-1.2-1.4-2-3.3-2.2-5.3H2.9z M17.5,19.4l-1-1
	c1-1.2,1.6-2.6,1.8-4.2h1.4C19.6,16.1,18.8,17.9,17.5,19.4z M9.9,5.7C8.3,5.8,6.8,6.5,5.6,7.5l-1-1c1.4-1.2,3.3-2,5.3-2.2V5.7z"/>
    </SvgIcon>
);

export default FastingIcon;