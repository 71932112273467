import React from 'react';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
  return (
    {
      root: {
        color: '#333333',
        fontSize: '1rem',
        textTransform: 'none',
        padding: '.3rem .3rem',
        background: '#F2F0F3',
        borderRadius: '5px'
      }
    }
  )
}

const ViewFiltersButton = withStyles(styles)(({ classes, ...props }) => (
  <MuiButton
    variant="contained"
    className={classes.root}>
    View & Filters
  </MuiButton>
));

export default ViewFiltersButton;