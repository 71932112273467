import React, { useState, useEffect } from "react";
import styles from "./ViewContainer.module.scss";
import { requisitionViewEditConfig } from "config/resources/requisitionsResource/index.js";
import Widget from "./Widget/WidgetContainer";
import ProgressBar from "components/ui_components/ProgressBar/ProgressBar";
import DownloadUploadContainer from "components/pages/requisitions/RequisitionsShow/Tabs/Billing/DownloadUpload/DownloadUploadContainer";
import helpers from "utils/helpers";
import { userPermissions } from "config/userRoles";
import Can from "Can";
import WidgetGroup from "components/ui_components/Widgets/WidgetGroup/WidgetGroup";
import { WidgetType1 } from "components/ui_components/Widgets";
import dataProvider, { GET_ONE } from "providers/dataProvider";
import GuarantorInformationContainer from "components/pages/requisitions/RequisitionsShow/Tabs/Billing/GuarantorInformation/GuarantorInformationContainer.js";
import InsuranceGroupContainer from "components/pages/requisitions/RequisitionsShow/Tabs/Billing/InsuranceGroup/InsuranceGroupContainer.js";

const {
  inputsGroupsById,
  inputsGroupsByHash,
  editInputsByHash,
  viewInputsByHash,
} = requisitionViewEditConfig;

const ViewContainer = (props) => {
  const { reqData, handleRequisitionUpdate, reqDataLoading, reqID } = props;

  const [billingData, setBillingData] = useState([]);
  const [billingDataLoaded, setBillingDataLoaded] = useState(false);

  const statusesAvailableForEdit = ["LOG", "ACC", "PROCESS"];
  const isAvailableEditByStatus = statusesAvailableForEdit.includes(props.reqData.reqStatusCode);

  useEffect(() => {
    const loadData = () => {
      dataProvider(GET_ONE, `requisitions/${reqID}/billings`).then(
        ({ data, total }) => {
          setBillingData(data);
          setBillingDataLoaded(true);
        }
      );
    };

    loadData();
  }, [reqID]);

  return (
    <div className={styles.mainSingleContainerWrapper}>
      <div className="mainSingleContainer">
        <WidgetGroup>
          {reqDataLoading && <ProgressBar />}
          {inputsGroupsById.map((groupID) => {
            let isAvailableEditMode = false;

            if (groupID === "requisitionsInformation") {
              isAvailableEditMode =
                helpers.can(
                  userPermissions.reqEntity.editRequisitionInformation
                ) &&
                isAvailableEditByStatus;
            } else if (groupID === "patientInformation") {
              isAvailableEditMode = helpers.can(
                userPermissions.reqEntity.editPatientInformation
              ) && isAvailableEditByStatus;
            } else if (groupID === "patientHistory") {
              isAvailableEditMode = helpers.can(
                userPermissions.reqEntity.editPatientHistory
              );
            }

            return (
              <Widget
                {...props}
                key={groupID}
                widgetID={groupID}
                handleRequisitionUpdate={handleRequisitionUpdate}
                title={inputsGroupsByHash[groupID].title}
                styles={`width: 100%;`}
                viewConfig={{
                  inputsById: inputsGroupsByHash[groupID].edit.inputsById,
                  inputsByHash: viewInputsByHash,
                  viewSubGroups: inputsGroupsByHash[groupID].view.byGroups,
                  data: reqData,
                }}
                withFormConfig={{
                  inputsById: inputsGroupsByHash[groupID].edit.inputsById,
                  inputsByHash: editInputsByHash,
                  initData: reqData,
                }}
                isAvailableEditMode={isAvailableEditMode}
                inputsGroupsByHash={
                  inputsGroupsByHash[groupID].edit.inputsGroupsByHash
                }
                inputsGroupsById={
                  inputsGroupsByHash[groupID].edit.inputsGroupsById
                }
              />
            );
          })}

          {billingDataLoaded && (
            <GuarantorInformationContainer
              reqID={reqID}
              billingID={billingData.id}
              data={billingData}
              editAvailable={false}
              withFormConfig={{
                inputsByHash: {},
                inputsById: [],
              }}
            />
          )}
        </WidgetGroup>

        {billingDataLoaded && (
          <InsuranceGroupContainer
            showAddForm={false}
            reqID={reqID}
            billingID={billingData.id}
            availableEdit={false}
          />
        )}

        <WidgetGroup>
          <Can allowedRoles={userPermissions.reqEntity.downloadSection}>
            <DownloadUploadContainer
              reqID={reqID}
              title="Downloads"
              showDelete={false}
              showUpload={false}
              widgetWrapStyles={"max-width: 600px;"}
            />
          </Can>

          <WidgetType1
            title="Client ICD Notes"
            wrapStyles={`
              max-width: 500px;
          `}
            widgetContentStyles="padding: 20px 27px 20px;"
            content={reqData.legacyIcdNote}
          />
        </WidgetGroup>
      </div>
    </div>
  );
};

export default ViewContainer;
