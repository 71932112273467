import moment from 'moment'
import authStorage from 'providers/authStorage.js';

let yup = require('yup');

const helpers = {
  getAllValues: (objects = [], key) => {
    return objects.length > 0 ? objects.map(item => {
      return item[key]
    }) : []
  },
  getYupRequired: (message) => yup.string()
  .required(`${message} is required`)
  .nullable(`${message} is required`)
  ,
  getYupDateDayRequired: (message, shortMessage = false) => yup
  .number()
  .typeError(shortMessage ? 'Date' : 'Date must be a number')
  .min(1, shortMessage ? 'Date' : 'Invalid date')
  .max(31, shortMessage ? 'Date' : 'Invalid date')
  .required(shortMessage ? 'Date' : `${message} is required`)
  .nullable(shortMessage ? 'Date' : `${message} is required`)
  ,
  getYupDateMonthRequired: (message, shortMessage = false) => yup
  .number()
  .typeError(shortMessage ? 'Month' : 'Month must be a number')
  .min(1, shortMessage ? 'Month' : 'Invalid month')
  .max(12, shortMessage ? 'Month' : 'Invalid month')
  .required(shortMessage ? 'Month' : `${message} is required`)
  .nullable(shortMessage ? 'Month' : `${message} is required`)
  ,
  getYupDateYearRequired: (message, shortMessage = false) => yup
  .string()
  .matches(/^\d+$/)
  .required(shortMessage ? 'Year' : 'Year is required')
  .min(4, shortMessage ? 'Year' : 'Invalid year')
  .max(4, shortMessage ? 'Year' : 'Invalid year')
  ,

  getYupTimeHoursRequired: (message, shortMessage = false) => yup
  .number()
  .typeError(shortMessage ? 'Hours' : 'Hours must be a number')
  .min(1, shortMessage ? 'Hours' : 'Invalid hours')
  .max(12, shortMessage ? 'Hours' : 'Invalid hours')
  .required(shortMessage ? 'Hours' : `${message} is required`)
  ,
  getYupTimeMinutesRequired: (message, shortMessage = false) => yup
  .number()
  .typeError(shortMessage ? 'Minutes' : 'Minutes must be a number')
  .min(0, shortMessage ? 'Minutes' : 'Invalid minutes')
  .max(59, shortMessage ? 'Minutes' : 'Invalid minutes')
  .required(shortMessage ? 'Minutes' : `${message} is required`)
  ,
  stringToArr: (str) => {
    return (typeof str === 'string' ? str.split(', ') : []).filter((item) => item !== '');
  },

  idConverter: (array, searchInArray, searchKey, returnKey) => {
    return array.map(searchItem => searchInArray.find(object => object[searchKey] === searchItem)[returnKey])
  },

  byHashByIDConfToArray: (byID, byHash) => {
    return byID.map(id => {
      return { ...byHash[id], id: id }
    })
  },
  getFormattedDateTime: (serverDate) => {
    if (serverDate === null) return false;
    return moment(serverDate).format("MM/DD/YY HH:mm")
  },
  getFormattedDate: (serverDate) => {
    if (serverDate === null) return false;
    return moment(serverDate).format("MM/DD/YY")
  },
  convertAMPMTimeTo24Format: (hours, minutes, AMPM) => {
    let nHours = Number(hours);
    let nMinutes = Number(minutes);
    if (AMPM === "pm" && nHours < 12) nHours = nHours + 12;
    if (AMPM === "am" && nHours === 12) nHours = nHours - 12;
    let sHours = nHours.toString();
    let sMinutes = nMinutes.toString();
    if (nHours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes;
  },
  can: (roles) => {
    const access = authStorage.getAccess();
    if (access && access.hasOwnProperty('userRoleCode')) {
      return roles.includes(access.userRoleCode);
    } else return false
  },

  isFunction: (functionToCheck) => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }
}

const can = (roles) => {
  return helpers.can(roles);
};

const cannot = (roles) => {
  const access = authStorage.getAccess();
  if (access && access.hasOwnProperty('userRoleCode')) {
    return !roles.includes(access.userRoleCode);
  } else return false
};

const leaf = (obj, path) => (path.split('.').reduce((value, el) => value[el], obj))

const getShadeColor = (colorHex, percent, transparency = 1) => {
  let R = parseInt(colorHex.substring(1, 3), 16);
  let G = parseInt(colorHex.substring(3, 5), 16);
  let B = parseInt(colorHex.substring(5, 7), 16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R < 255) ? R : 255;
  G = (G < 255) ? G : 255;
  B = (B < 255) ? B : 255;

  if (transparency < 1) {
    return `rgba(${R}, ${G}, ${B}, ${transparency})`;
  }

  let RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
  let GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
  let BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

  return "#" + RR + GG + BB;
}

const concatWithCommaSeparation = (array) => {
  let value = '';

  array.forEach((item, index) => {
    const renderValue = index > 0 ? `, ${item}` : item;
    if (item) {
      value += renderValue;
    }
  })

  return value;
}

const filterArrayOfObjectsByArray = (array = [], needleArray = [], needleKey = 'id') => {
  return array.filter(function(item) {
    return needleArray.includes(item[needleKey]);
  })

}

export { leaf, getShadeColor, can, concatWithCommaSeparation, filterArrayOfObjectsByArray, cannot };

export default helpers