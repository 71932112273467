export const userRoles = {
  admin: 'ADM',
  collaborator: 'COLA',
  client: 'CLNT',
  management: 'MGMT',
  physician: 'PHYS',
  sales: 'SLS',
  gdbstaff: 'STF',
  guest: 'GST'
}

export const userGroups = {
  superAdmins: [
    userRoles.admin, userRoles.management, userRoles.gdbstaff
  ],
  allRegistered: [
    userRoles.admin, userRoles.collaborator, userRoles.client, userRoles.management, userRoles.physician, userRoles.sales, userRoles.gdbstaff
  ],
  guests: [
    userRoles.guest
  ]

}

export const userPermissions = {
  userEntity: {
    resource: [userRoles.management]
  },
  userInfo: {
    resource: userGroups.allRegistered,
  },
  reqEntity: {
    editRequisitionInformation: [userRoles.admin, userRoles.management, userRoles.gdbstaff],
    editPatientInformation: [userRoles.admin, userRoles.management, userRoles.gdbstaff, userRoles.collaborator],
    editPatientHistory: [userRoles.admin, userRoles.management, userRoles.gdbstaff, userRoles.collaborator],
    downloadSection: [userRoles.admin, userRoles.management, userRoles.gdbstaff, userRoles.sales],
    show: {
      issues: {
        root: [],
        addIssue: [userRoles.admin, userRoles.management, userRoles.gdbstaff]
      },
      reportsDelivery: {
        edit: [userRoles.admin, userRoles.management, userRoles.gdbstaff],
        downloadReports: [userRoles.collaborator,]
      },
    },
    smartFilter: {
      collaboratorField: [
        userRoles.admin,
        userRoles.client,
        userRoles.management,
        userRoles.physician,
        userRoles.gdbstaff,
        userRoles.sales,
      ]
    }
  },
  notifications: {
    root: [userRoles.admin, userRoles.management, userRoles.gdbstaff, userRoles.collaborator, userRoles.sales]
  }
}