import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: (props) => {
    let styles = {
      border: "none",
      background: "transparent",
      display: "inline-block",
      position: "relative",
      width: "24px",
      height: "16px",
      cursor: "pointer",
      "& span": {
        position: "absolute",
        width: "100%",
        height: "2px",
        backgroundColor: "white",
        left: "0",
        transition: "transform .5s, opacity .6s",
        "&:nth-child(1)": {
          top: "0",
        },
        "&:nth-child(2)": {
          top: "50%",
          marginTop: "-1px",
        },
        "&:nth-child(3)": {
          bottom: "0",
        },
      },
    };

    if (props.hoverAnimation) {
      styles = {
        ...styles,
        "&:hover": {
          "& span": {
            "&:nth-child(1)": {
              transform: "translateY(-3px)",
            },

            "&:nth-child(3)": {
              transform: "translateY(3px)",
            },
          },
        },
      };
    }

    return styles;
  },
  active: (props) => {
    let styles = {
      "& span": {
        "&:nth-child(1)": {
          transform: "translateY(7px) rotate(-45deg)",
        },
        "&:nth-child(2)": {
          transform: "translateX(10px)",
          opacity: "0",
        },
        "&:nth-child(3)": {
          transform: "translateY(-7px) rotate(45deg)",
        },
      },
    };
    if (props.hoverAnimation) {
      styles = {
        ...styles,
        "&:hover": {
          "& span": {
            "&:nth-child(1)": {
              transform: "translateY(7px) rotate(-40deg)",
            },

            "&:nth-child(3)": {
              transform: "translateY(-7px) rotate(40deg)",
            },
          },
        },
      };
    }
    return styles;
  },
}));

const BurgerButton = ({ isActive, onClick, id }) => {
  const classes = useStyles();
  return (
    <button
      id={id}
      onClick={onClick}
      className={clsx({
        [classes.root]: true,
        [classes.active]: isActive,
      })}
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

BurgerButton.defaultProps = {
  id: "",
  isActive: false,
  onClick: () => {},
  hoverAnimation: true,
};

export default BurgerButton;
