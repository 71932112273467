import React, { PureComponent } from 'react';
import Modal from '@material-ui/core/Modal';
import styles from './NotificationsModalContainer.module.scss';
import ButtonBase from '@material-ui/core/ButtonBase';
import dataProvider from "providers/dataProvider";
import { READ_NOTIFICATION } from 'providers/dataProvider';
import { GET_LIST } from 'providers/dataProvider';
import { withRouter } from 'react-router-dom';
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar';
import { withNotificationCenter } from 'NotificationCenter';
import { compose } from 'redux';
import authStorage from 'providers/authStorage.js';

class NotificationsModalContainer extends PureComponent {
  access = authStorage.getAccess();

  constructor(props) {
    super(props)

    this.state = {
      data: [],
      dataLoading: false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.modalOpen && !prevProps.modalOpen) {
      this.loadData();
    }
  }

  loadData = () => {
    this.setState({
      dataLoading: true
    })
    this.access = authStorage.getAccess();

    if (this.access && this.access.hasOwnProperty('userId')) {
      const userId = this.access.userId;
      dataProvider(GET_LIST, `users/${userId}/notifications`)
      .then(({ data, total }) => {
        this.setState({
          data: data,
          dataLoading: false
        })
      })
    }
  }

  readNotification = (reqID) => {
    dataProvider(READ_NOTIFICATION, null, { userId: this.access.userId, reqID: reqID })
    .then(() => {
    })
  }

  handleListItemClick = (reqID) => {
    this.readNotification(reqID);
    this.props.handleCloseModal();
    this.props.history.push(`/requisitions/${reqID}/show/issues`);
  }

  render() {
    const { modalOpen, handleCloseModal } = this.props;

    return (
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <div className={`${styles.root} modalContainer`}>
          <h2 className={`${styles.modalTitle} ttl ttl-2`}>
            Notifications
          </h2>

          <div className={styles.notificationsList}>
            {this.state.dataLoading && <ProgressBar/>}
            {this.state.data.map((item, index) => {
              const newIssuesCount = item.issueCount - item.readCount;
              return (
                <div key={index}
                     className={styles.notificationsListItem}
                     onClick={this.handleListItemClick.bind(this, item.id)}>
                  <div className={styles.notificationsListItemContent}>
                    Requisition #{item.id} has {newIssuesCount} new/updated issues.
                  </div>
                  {newIssuesCount > 0 ? <span className={styles.activeIssuesDot}></span> : null}
                </div>
              )
            })}
          </div>

          <div className={`${'buttonGroup'} ${styles.buttonGroup}`}>
            <ButtonBase
              className="button formButton formButton__secondaryOutlined"
              onClick={handleCloseModal}>
              Cancel
            </ButtonBase>
          </div>
        </div>
      </Modal>
    );
  }
}


export default compose(
  withNotificationCenter(),
  withRouter
)(NotificationsModalContainer)