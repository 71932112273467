import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LogoIcon = ({ id, ...props }) => (
  <SvgIcon viewBox="-10 0 80 100" fill="none" {...props}>
    <path d="M64 67.2L48 57.6L32 67.2V86.4L48 96L64 86.4V67.2Z" fill={`url(#paint0_linear_${id})`} />
    <path d="M32 48L16 38.4L0 48V67.2L16 76.8L32 67.2V48Z" fill={`url(#paint1_linear_${id})`} />
    <path d="M64 28.8L48 19.2L32 28.8V48L48 57.6L64 48V28.8Z" fill={`url(#paint2_linear_${id})`} />
    <path d="M32 9.6L16 0L0 9.6V28.8L16 38.4L32 28.8V9.6Z" fill={`url(#paint3_linear_${id})`} />
    <defs>
      <linearGradient id={`paint0_linear_${id}`} x1="64" y1="0" x2="1.43051e-06" y2="96" gradientUnits="userSpaceOnUse">
        <stop offset="0.220602" stop-color="#5EADD1"/>
        <stop offset="1" stop-color="#004654"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_${id}`} x1="64" y1="0" x2="1.43051e-06" y2="96" gradientUnits="userSpaceOnUse">
        <stop offset="0.220602" stop-color="#5EADD1"/>
        <stop offset="1" stop-color="#004654"/>
      </linearGradient>
      <linearGradient id={`paint2_linear_${id}`} x1="64" y1="0" x2="1.43051e-06" y2="96" gradientUnits="userSpaceOnUse">
        <stop offset="0.220602" stop-color="#5EADD1"/>
        <stop offset="1" stop-color="#004654"/>
      </linearGradient>
      <linearGradient id={`paint3_linear_${id}`} x1="64" y1="0" x2="1.43051e-06" y2="96" gradientUnits="userSpaceOnUse">
        <stop offset="0.220602" stop-color="#5EADD1"/>
        <stop offset="1" stop-color="#004654"/>
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default LogoIcon;