import React from "react";
import {
  CheckedIconValue,
  NotCheckedIconValue,
  IconValueWarning,
} from "components/ui_components/table/IconValue";
import IconTooltip from "components/ui_components/IconTooltip/IconTooltip.js";
import { userRoles } from "config/userRoles";
import {
  formatServerDateTimeToLocal,
  formatServerDateTimeToTimeZoneWithAbbr,
} from "utils/dateTimeFormat.js";
import { Link } from "react-router-dom";
import { concatWithSeparator } from "utils/textFormat";
import helpers, { filterArrayOfObjectsByArray } from "utils/helpers";

const PartialIcon = ({ partial }) => {
  let title = null;

  if (partial === 1) {
    title = "Yes";
  } else if (partial === 0) {
    title = "No";
  } else {
    return null;
  }
  return (
    <IconTooltip title={title} arrow>
      {partial === 1 ? <CheckedIconValue /> : <NotCheckedIconValue />}
    </IconTooltip>
  );
};

const IssueWarningIcon = ({ issueCount, reqID, tableExtraData, data }) => {
  const reporterDeptCodes = helpers.stringToArr(data.reporterDeptcodes);
  const descriptionItems = filterArrayOfObjectsByArray(
    tableExtraData.depts,
    reporterDeptCodes,
    "deptCode"
  );
  const departments = descriptionItems.map((item) => {
    return <div key={item.id}>{item.deptName}</div>;
  });
  return issueCount > 0 ? (
    <Link to={`requisitions/${reqID}/show/issues`}>
      <IconTooltip
        title={
          <React.Fragment>
            Requisition contains uncompleted issues: {departments}
          </React.Fragment>
        }
        arrow
      >
        <IconValueWarning />
      </IconTooltip>
    </Link>
  ) : (
    ""
  );
};

export const getSalesRepColumnVal = (salesJson) => {
  if (salesJson) {
    const salesReps = JSON.parse(salesJson).salesReps;
    let counter = 0;
    return salesReps.map((item) => {
      if (!item.salesRepCode) return null;
      return (
        <React.Fragment key={item.salesId}>
          {counter++ > 0 && ", "}
          <Link
            onClick={(e) => {
              e.stopPropagation()
            }}
            to={`/sales/${item.salesId}/show`}
          >
            {item.salesRepCode}
          </Link>
        </React.Fragment>
      );
    });
  } else {
    return;
  }
};

const linkParams = {
  linkAccessor: (row) => `/requisitions/${row.id}/show`,
  linkPermissions: [
    userRoles.admin,
    userRoles.management,
    userRoles.gdbstaff,
    userRoles.sales,
    userRoles.collaborator,
  ],
};

const flagColumn = {
  id: "flag",
  label: "Flag",
  accessor: (d, rowExtraData, tableExtraData) => {
    const flagCodes = helpers.stringToArr(d.flag);
    const descriptionItems = filterArrayOfObjectsByArray(
      tableExtraData.flags,
      flagCodes,
      "id"
    );

    if (descriptionItems.length === 0) return d.flag;

    if (!descriptionItems) return d.flag;

    const title = descriptionItems.map((item) => {
      return (
        <div key={item.id}>
          {item.id} - {item.flagDesc}
        </div>
      );
    });

    return (
      <IconTooltip title={title} arrow>
        {d.flag}
      </IconTooltip>
    );
  },
  hideForRoles: [userRoles.sales],
};

export default {
  groupsById: [
    "All",
    "Logged",
    "Accessioned",
    "Processing",
    "Resulted",
    "Reported",
    "Released",
    "Delivered",
    "Billing",
  ],
  groupsByHash: {
    AllForClient: [
      {
        id: "id",
        label: "Req ID",
        ...linkParams,
      },
      {
        id: "patientFirstName",
        label: "Patient Name",
        accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
        ...linkParams,
      },
      {
        id: "patientDob",
        label: "Patient DOB",
      },
      {
        id: "physFirstName",
        label: "Physician",
        accessor: (d) => `${d.physLastName}, ${d.physFirstName}`,
      },
      {
        id: "clientId",
        label: "Client",
        accessor: (d) => `${d.legacyClientId} - ${d.clientName}`,
      },
      {
        id: "dateCollected",
        label: "Date Collected",
        accessor: (d) =>
          formatServerDateTimeToTimeZoneWithAbbr(
            d.dateCollected,
            "date",
            d.collectedTz,
            d.collectedTzAbbr
          ),
      },
      {
        id: "reportedStatusDate",
        label: "Report Date",
        accessor: (d) => formatServerDateTimeToLocal(d.reportedStatusDate),
      },
      {
        id: "salesRepCode",
        label: "Sale Rep.",
      },
      {
        id: "partialReport",
        label: "Partial Report",
        accessor: (d) => <PartialIcon partial={d.partialReport} />,
      },
      {
        id: "isSendNotif",
        label: "Notify Patient?",
        edit: true,
        accessor: (d) => d.isSendNotif,
        visibleForRoles: [], // [userRoles.collaborator, userRoles.client, userRoles.physician]
        autoSave: {
          resourceAccessor: (d) => {
            return `requisitions/${d.reqID}/send_notif`;
          },
        },
        type: "notifyPatientTableCell",
      },
    ],

    AllForPhysician: [
      {
        id: "id",
        label: "Req ID",
        ...linkParams,
      },
      {
        id: "patientFirstName",
        label: "Patient Name",
        accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
        ...linkParams,
      },
      {
        id: "patientDob",
        label: "Patient DOB",
      },
      {
        id: "physFirstName",
        label: "Physician",
        accessor: (d) => `${d.physLastName}, ${d.physFirstName}`,
      },
      {
        id: "clientId",
        label: "Client",
        accessor: (d) => `${d.legacyClientId} - ${d.clientName}`,
      },
      {
        id: "dateCollected",
        label: "Date Collected",
        accessor: (d) =>
          formatServerDateTimeToTimeZoneWithAbbr(
            d.dateCollected,
            "date",
            d.collectedTz,
            d.collectedTzAbbr
          ),
      },
      {
        id: "reportedStatusDate",
        label: "Report Date",
        accessor: (d) => formatServerDateTimeToLocal(d.reportedStatusDate),
      },
      {
        id: "salesRepCode",
        label: "Sale Rep.",
      },
      {
        id: "partialReport",
        label: "Partial Report",
        accessor: (d) => <PartialIcon partial={d.partialReport} />,
      },
      {
        id: "isSendNotif",
        label: "Notify Patient?",
        edit: true,
        visibleForRoles: [], // [userRoles.collaborator, userRoles.client, userRoles.physician]
        accessor: (d) => d.isSendNotif,
        autoSave: {
          resourceAccessor: (d) => {
            return `requisitions/${d.reqID}/send_notif`;
          },
        },
        type: "notifyPatientTableCell",
      },
    ],

    All: [
      {
        id: "id",
        label: "Req ID",
        ...linkParams,
      },
      {
        id: "patientFirstName",
        label: "Patient Name",
        accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
        ...linkParams,
      },
      {
        id: "patientDob",
        label: "Patient DOB",
      },
      {
        id: "reqStatusDesc",
        label: "Current Requisition Status",
      },
      {
        id: "physFirstName",
        label: "Physician",
        accessor: (d) => `${d.physLastName}, ${d.physFirstName}`,
      },
      {
        id: "clientId",
        label: "Client",
        accessor: (d) =>
          concatWithSeparator([d.legacyClientId, d.clientName], " - "),
      },
      {
        id: "dateCollected",
        label: "Date Collected",
        accessor: (d) =>
          formatServerDateTimeToTimeZoneWithAbbr(
            d.dateCollected,
            "date",
            d.collectedTz,
            d.collectedTzAbbr
          ),
      },
      {
        id: "salesJson",
        label: "Sale Rep.",
        accessor: (d) => getSalesRepColumnVal(d.salesJson),
      },
      {
        id: "testCodes",
        label: "Test Codes",
      },
      {
        id: "reqBillTypeDesc",
        label: "Bill to type",
      },
      flagColumn,
      {
        id: "issueCount",
        label: "",
        accessor: (d, rowExtraData, tableExtraData) => (
          <IssueWarningIcon
            issueCount={d.issueCount}
            reqID={d.id}
            data={d}
            tableExtraData={tableExtraData}
          />
        ),
      },
      {
        id: "isSendNotif",
        label: "Notify Patient?",
        edit: true,
        visibleForRoles: [], // [userRoles.collaborator, userRoles.client, userRoles.physician]
        accessor: (d) => d.isSendNotif,
        autoSave: {
          resourceAccessor: (d) => {
            return `requisitions/${d.reqID}/send_notif`;
          },
        },
        type: "notifyPatientTableCell",
      },
    ],
    Logged: [
      {
        id: "id",
        label: "Req ID",
        ...linkParams,
      },
      {
        id: "patientFirstName",
        label: "Patient Name",
        accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
        ...linkParams,
      },
      {
        id: "patientDob",
        label: "Patient DOB",
      },
      {
        id: "dateCollected",
        label: "Date Collected",
        accessor: (d) =>
          formatServerDateTimeToTimeZoneWithAbbr(
            d.dateCollected,
            "date",
            d.collectedTz,
            d.collectedTzAbbr
          ),
      },
      {
        id: "dateReceived",
        label: "Date Received",
        accessor: (d) =>
          `${formatServerDateTimeToTimeZoneWithAbbr(
            d.dateReceived,
            "date",
            d.labTz,
            d.labTzAbbr
          )}`,
      },
      {
        id: "dateLogged",
        label: "Date Logged",
        accessor: (d) => formatServerDateTimeToLocal(d.dateLogged),
      },
      {
        id: "testCodes",
        label: "Test Codes",
      },
      {
        id: "issueCount",
        label: "",
        accessor: (d, rowExtraData, tableExtraData) => (
          <IssueWarningIcon
            issueCount={d.issueCount}
            reqID={d.id}
            data={d}
            tableExtraData={tableExtraData}
          />
        ),
      },
    ],

    Accessioned: [
      {
        id: "id",
        label: "Req ID",
        ...linkParams,
      },
      {
        id: "colaName",
        label: "Collaborator",
      },
      {
        id: "clientName",
        label: "Client",
        accessor: (d) => `${d.legacyClientId} - ${d.clientName}`,
      },
      {
        id: "colaName",
        label: "Ordering Physician",
        accessor: (d) => `${d.physLastName}, ${d.physFirstName}`,
      },
      {
        id: "patientFirstName",
        label: "Patient Name",
        accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
        ...linkParams,
      },
      {
        id: "patientGenderDesc",
        label: "Sex",
        accessor: (d) => (d.patientGenderDesc === "Male" ? "M" : "F"),
      },
      {
        id: "patientDob",
        label: "Patient DOB",
      },
      {
        id: "patientAge",
        label: "Age",
      },
      {
        id: "testCodes",
        label: "Test Ordered",
      },
      {
        id: "dateCollected",
        label: "Date/Time Collect.",
        accessor: (d) =>
          formatServerDateTimeToTimeZoneWithAbbr(
            d.dateCollected,
            "dateTime",
            d.collectedTz,
            d.collectedTzAbbr
          ),
      },
      {
        id: "dateReceived",
        label: "Date/Time Received.",
        accessor: (d) =>
          `${formatServerDateTimeToTimeZoneWithAbbr(
            d.dateReceived,
            "dateTime",
            d.labTz,
            d.labTzAbbr
          )}`,
      },
      {
        id: "reqPassCodes",
        label: "Pass Codes",
        cache: true,
        edit: true,
        autoSave: {
          resourceAccessor: (d) => {
            return `requisitions/${d.reqID}/pass_codes`;
          },
        },
        type: "reqPassCodeEditTableCell",
        resource: "reasons/PASS",
        accessor: (d) => {
          return { value: d.passCode, label: `${d.passCode} - ${d.passDesc}` };
        },
      },
      flagColumn,
      {
        id: "issueCount",
        label: "",
        accessor: (d, rowExtraData, tableExtraData) => (
          <IssueWarningIcon
            issueCount={d.issueCount}
            reqID={d.id}
            data={d}
            tableExtraData={tableExtraData}
          />
        ),
      },
    ],
    Processing: {
      tableColById: [
        "id",
        "patientFirstName",
        "patientDob",
        "reqStatusDesc",
        "patientGenderDesc",
        "dateCollected",
        "dateReceived",
        "dateLogged",
        "testCodes",
        "issueCount",
      ],
      tableColByHash: {
        id: {
          label: "Req ID",
          ...linkParams,
        },
        patientFirstName: {
          label: "Patient Name",
          accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
          ...linkParams,
        },
        patientDob: {
          label: "Patient DOB",
        },
        reqStatusDesc: {
          label: "Current Requisition Status",
        },
        patientGenderDesc: {
          label: "Sex",
        },
        dateCollected: {
          label: "Date Collected",
          accessor: (d) =>
            formatServerDateTimeToTimeZoneWithAbbr(
              d.dateCollected,
              "date",
              d.collectedTz,
              d.collectedTzAbbr
            ),
        },
        dateReceived: {
          label: "Date Received",
          accessor: (d) =>
            `${formatServerDateTimeToTimeZoneWithAbbr(
              d.dateReceived,
              "date",
              d.labTz,
              d.labTzAbbr
            )}`,
        },
        dateLogged: {
          label: "Date Logged",
          accessor: (d) => formatServerDateTimeToLocal(d.dateLogged, "date"),
        },
        issueCount: {
          label: "",
          accessor: (d, rowExtraData, tableExtraData) => (
            <IssueWarningIcon
              issueCount={d.issueCount}
              reqID={d.id}
              data={d}
              tableExtraData={tableExtraData}
            />
          ),
        },
        testCodes: {
          label: "Test Codes",
        },
      },
    },
    Resulted: [
      {
        id: "id",
        label: "Req ID",
        ...linkParams,
      },
      {
        id: "patientFirstName",
        label: "Patient Name",
        accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
        ...linkParams,
      },
      {
        id: "patientDob",
        label: "Patient DOB",
      },
      {
        id: "reqStatusDesc",
        label: "Current Requisition Status",
      },
      {
        id: "partialReport",
        label: "Partial Report",
        accessor: (d) => <PartialIcon partial={d.partialReport} />,
      },
      {
        id: "partialRelease",
        label: "Partial Released",
        accessor: (d) => <PartialIcon partial={d.partialRelease} />,
      },
      {
        id: "dateCollected",
        label: "Date of Collection",
        accessor: (d) =>
          formatServerDateTimeToTimeZoneWithAbbr(
            d.dateCollected,
            "date",
            d.collectedTz,
            d.collectedTzAbbr
          ),
      },
      {
        id: "testCodes",
        label: "Test Codes",
      },
      {
        id: "tat",
        label: "TAT (Turn Around Time)",
      },
      {
        id: "issueCount",
        label: "",
        accessor: (d, rowExtraData, tableExtraData) => (
          <IssueWarningIcon
            issueCount={d.issueCount}
            reqID={d.id}
            data={d}
            tableExtraData={tableExtraData}
          />
        ),
      },
    ],
    Reported: [
      {
        id: "id",
        label: "Req ID",
        ...linkParams,
      },
      {
        id: "patientFirstName",
        label: "Patient Name",
        accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
        ...linkParams,
      },
      {
        id: "patientDob",
        label: "Patient DOB",
      },
      {
        id: "reqStatusDesc",
        label: "Current Requisition Status",
      },
      {
        id: "partialReport",
        label: "Partial Report",
        accessor: (d) => <PartialIcon partial={d.partialReport} />,
      },
      {
        id: "partialRelease",
        label: "Partial Released",
        accessor: (d) => <PartialIcon partial={d.partialRelease} />,
      },
      {
        id: "dateCollected",
        label: "Date of Collection",
        accessor: (d) =>
          formatServerDateTimeToTimeZoneWithAbbr(
            d.dateCollected,
            "date",
            d.collectedTz,
            d.collectedTzAbbr
          ),
      },
      {
        id: "testCodes",
        label: "Test Codes",
      },
      {
        id: "tat",
        label: "TAT (Turn Around Time)",
      },
      {
        id: "isDemQcPass",
        label: "Demographic QC",
        edit: true,
        autoSave: {
          resourceAccessor: (d) => {
            return `requisitions/${d.reqID}/dem_qc`;
          },
        },
        type: "demographicQCTableCell",
      },
      flagColumn,
      {
        id: "issueCount",
        label: "",
        accessor: (d, rowExtraData, tableExtraData) => (
          <IssueWarningIcon
            issueCount={d.issueCount}
            reqID={d.id}
            data={d}
            tableExtraData={tableExtraData}
          />
        ),
      },
    ],
    Delivered: [
      {
        id: "id",
        label: "Req ID",
        ...linkParams,
      },
      {
        id: "patientFirstName",
        label: "Patient Name",
        accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
        ...linkParams,
      },
      {
        id: "patientDob",
        label: "Patient DOB",
      },
      {
        id: "reqStatusDesc",
        label: "Current Requisition Status",
      },
      {
        id: "partialReport",
        label: "Partial Report",
        accessor: (d) => <PartialIcon partial={d.partialReport} />,
      },
      {
        id: "partialRelease",
        label: "Partial Released",
        accessor: (d) => <PartialIcon partial={d.partialRelease} />,
      },
      {
        id: "clientName",
        label: "Client",
        accessor: (d) => `${d.legacyClientId} - ${d.clientName}`,
      },
      {
        id: "dateCollected",
        label: "Date of Collection",
        accessor: (d) =>
          formatServerDateTimeToTimeZoneWithAbbr(
            d.dateCollected,
            "date",
            d.collectedTz,
            d.collectedTzAbbr
          ),
      },
      {
        id: "testCodes",
        label: "Test Ordered",
      },
      {
        id: "clientTransmitMethodDesc",
        label: "Delivery Method",
      },
      {
        id: "latestTransmitMethodDesc",
        label: "Latest Delivery Method",
      },
      {
        id: "latestDeliveryDate",
        label: "Latest Delivery Date",
        accessor: (d) =>
          formatServerDateTimeToLocal(d.latestDeliveryDate, "date"),
      },
      {
        id: "latestDeliveredByName",
        label: "Latest Delivered By",
      },
      {
        id: "reqBillTypeDesc",
        label: "Bill To",
      },
      {
        id: "issueCount",
        label: "",
        accessor: (d, rowExtraData, tableExtraData) => (
          <IssueWarningIcon
            issueCount={d.issueCount}
            reqID={d.id}
            data={d}
            tableExtraData={tableExtraData}
          />
        ),
      },
    ],
    Released: [
      {
        id: "id",
        label: "Req ID",
        ...linkParams,
      },
      {
        id: "patientFirstName",
        label: "Patient Name",
        accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
        ...linkParams,
      },
      {
        id: "clientName",
        label: "Client",
        accessor: (d) => `${d.legacyClientId} - ${d.clientName}`,
      },
      {
        id: "physFirstName",
        label: "Physician",
        accessor: (d) => `${d.physLastName}, ${d.physFirstName}`,
      },
      {
        id: "reqStatusDesc",
        label: "Current Requisition Status",
      },
      {
        id: "partialReport",
        label: "Partial Report",
        accessor: (d) => <PartialIcon partial={d.partialReport} />,
      },
      {
        id: "partialRelease",
        label: "Partial Released",
        accessor: (d) => <PartialIcon partial={d.partialRelease} />,
      },
      {
        id: "dateCollected",
        label: "Date of Collection",
        accessor: (d) =>
          formatServerDateTimeToTimeZoneWithAbbr(
            d.dateCollected,
            "date",
            d.collectedTz,
            d.collectedTzAbbr
          ),
      },
      {
        id: "testCodes",
        label: "Test Codes",
      },
      {
        id: "clientTransmitMethodDesc",
        label: "Delivery Method",
      },
    ],
    Billing: [
      {
        id: "id",
        label: "Req ID",
        ...linkParams,
      },
      {
        id: "patientFirstName",
        label: "Patient Name",
        accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
        ...linkParams,
      },
      {
        id: "patientDob",
        label: "Patient DOB",
      },
      {
        id: "reqStatusDesc",
        label: "Current Requisition Status",
      },
      {
        id: "clientId",
        label: "Client",
        accessor: (d) =>
          concatWithSeparator([d.legacyClientId, d.clientName], " - "),
      },
      {
        id: "dateCollected",
        label: "Date Collected",
        accessor: (d) =>
          formatServerDateTimeToTimeZoneWithAbbr(
            d.dateCollected,
            "date",
            d.collectedTz,
            d.collectedTzAbbr
          ),
      },
      {
        id: "testCodes",
        label: "Test Ordered",
      },
      {
        id: "reqBillTypeDesc",
        label: "Bill To",
      },
      {
        id: "insuranceCompany",
        label: "Insurance Name",
      },
      {
        id: "salesJson",
        label: "Sales Rep",
        accessor: (d) => getSalesRepColumnVal(d.salesJson),
      },
      {
        id: "issueCount",
        label: "",
        accessor: (d, rowExtraData, tableExtraData) => (
          <IssueWarningIcon
            issueCount={d.issueCount}
            reqID={d.id}
            data={d}
            tableExtraData={tableExtraData}
          />
        ),
      },
    ],
  },
};
