import helpers from 'utils/helpers';
import { formatServerDateTimeToLocal } from 'utils/dateTimeFormat.js';

export default {
  notesTableColConfig: {
    tableColById: ['labNoteCreateDate', 'labNote', 'labNoteTypeDesc', 'createByName'],
    tableColByHash: {
      labNoteCreateDate: {
        label: 'Created At',
        accessor: d => formatServerDateTimeToLocal(d.labNoteCreateDate, 'dateTime')
      },
      labNoteTypeDesc: {
        label: 'Type'
      },
      labNote: {
        label: 'Note'
      },
      createByName: {
        label: 'Created By'
      }
    }
  },

  notesHistoryTableColConfig: {
    tableColById: ['actionDate', 'labNoteTypeDesc', 'action', 'actionBy'],
    tableColByHash: {
      actionDate: {
        label: 'Date and Time',
        accessor: d => formatServerDateTimeToLocal(d.actionDate, 'dateTime'),
      },
      labNoteTypeDesc: {
        label: 'Note'
      },
      action: {
        label: 'Action'
      },
      actionBy: {
        label: 'User'
      }
    }
  },


  addNoteFormConfig: {
    inputsByHash: {
      labNoteTypeId: {
        label: 'Type of Note',
        yup: helpers.getYupRequired('Type of Note'),
        type: 'select',
        resource: 'lab_note_types',
        submitValueAccessor: (value) => {
          return parseInt(value)
        },
        accessor: (d) => {
          return ({ value: d.id, label: d.labNoteTypeDesc })
        }
      },
      note: {
        label: 'Note',
        type: 'text'
      }
    },

    inputsById: ['labNoteTypeId', 'note']
  }
}