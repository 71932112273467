import helpers from "utils/helpers";

export default {
  inputsById: ["clientId", "eventId"],
  inputsByHash: {
    clientId: {
      label: "Client",
      yup: helpers.getYupRequired("Client"),
      type: "autocomplete",
      relativeInputs: ["eventId"],
      resetRelativeInputs: ["eventId"],
      resource: "clients",
      accessor: (d) => {
        return {
          value: d.id,
          label: `${d.legacyClientId} - ${d.clientName}`,
          extraSelectedOptionData: { clientTz: d.clientTz },
        };
      },
    },
    eventId: {
      label: "Client Event",
      type: "autocomplete",
      yup: helpers.getYupRequired("Client Event"),
      relativeInputResourceAccessor: (d) => {
        return `clients/${d.clientId}/events`;
      },
      accessor: (d) => {
        return { value: d.id, label: d.eventName };
      },
    },
  },
};
