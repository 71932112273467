import moment from "moment";
import cryoXmlLayout from './cryoLayout';

export default (dymo, data) => {
    console.log(`Starting print script for req id - ${data.id}`);
    let printParams = `<LabelWriterPrintParams>
                            <JobTitle>CellarTracker Labels</JobTitle>
                            <PrintQuality>BarcodeAndGraphics</PrintQuality>
                            <TwinTurboRoll>Right</TwinTurboRoll>
                            </LabelWriterPrintParams>`;


    let barcode = data.id;
    const patientDob = data.patientDob !== null ? moment(data.patientDob).format("MMDDYY") : '';
    let renderPatientDob = patientDob;

    if (data.cryoFlag !== null) {
        renderPatientDob += data.cryoFlag;
    }
    let text = `${data.id}_${renderPatientDob}`;
    let labelSetXml = '<LabelSet>';
    let numberOfCopies = data.cryoPrintNbr;

    console.log(`cryoPrintNbr - ${data.cryoPrintNbr}`);

    for(let i = 1; i <= numberOfCopies; i++) {
        labelSetXml += `<LabelRecord>
                                <ObjectData Name="TEXT">${text}</ObjectData>
                               <ObjectData Name="BARCODE">${barcode}</ObjectData>
                        </LabelRecord>`
    }

    labelSetXml += '</LabelSet>';

    console.log(labelSetXml);


    dymo.print('DYMO LabelWriter 450 Twin Turbo', cryoXmlLayout, printParams, labelSetXml);
}