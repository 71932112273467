import React, { PureComponent } from 'react'
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import SmartInput from 'components/ui_components/smart_input'
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from "prop-types";
import helpers from 'utils/helpers';
import TooltipInfoButtonContainer
  from 'components/ui_components/buttons/TooltipInfoButtonContainer/TooltipInfoButtonContainer'
import styles from './ReqPassCodeEditTableCell.module.scss';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 600,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);


class ReqPassCodeEditTableCell extends PureComponent {
  constructor(props) {
    super(props)
    const { col, row } = this.props;
    this.state = {
      isTooltipOpen: false,
      selectOptions: row[col.id] === 'NULL' ? [] : helpers.stringToArr(row[col.id])
    }
  }

  handleTooltipClose = () => {
    this.setState({
      isTooltipOpen: false
    })
  }

  handleTooltipOpen = (e) => {
    e.stopPropagation();
    this.setState({
      isTooltipOpen: true
    })
  }

  handleInputs = (value) => {
    this.setState({
      selectOptions: value
    })
  }

  render() {
    const { col, optionsData } = this.props;
    const { selectOptions } = this.state;

    return (
      <TableCell align="right">
        <div className={styles.rootWrap}>
          <SmartInput
            reqID={this.props.reqID}
            input={{
              id: col.id,
              type: "reqPassCodeMultiSelect",
              cache: col.cache,
              resource: col.resource,
              accessor: col.accessor,
              autoSave: col.autoSave,
              value: selectOptions
            }}
            optionsInputData={optionsData}
            inputId={col.id}
            onChange={this.handleInputs}
          />
          <HtmlTooltip
            open={this.state.isTooltipOpen}
            title={
              <React.Fragment>
                {selectOptions.map((selectOption, index) => {
                  if (selectOption === '') return false;
                  const optionsDataItem = optionsData.find(option => option.passCode === selectOption)
                  if (!optionsDataItem) return false;
                  return (
                    <div key={index}>
                      {optionsDataItem.passCode} - {optionsDataItem.passDesc}
                    </div>
                  )
                })}
              </React.Fragment>
            }
          >
            <div>
              <TooltipInfoButtonContainer onMouseOver={this.handleTooltipOpen}
                                          onMouseLeave={this.handleTooltipClose}/>
            </div>
          </HtmlTooltip>
        </div>
      </TableCell>)
  }
}

export default ReqPassCodeEditTableCell


ReqPassCodeEditTableCell.propTypes = {
  optionsData: PropTypes.array.isRequired
}

ReqPassCodeEditTableCell.defaultProps = {
  optionsData: []
}