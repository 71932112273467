import React, { useState } from "react";
import withForm from "withForm";
import { loginFormConfig } from "config/loginPage";
import { compose } from "redux";
import { useAuthCenter } from "AuthCenter";
import { useNotificationCenter } from "NotificationCenter";
import { BaseForm } from "components/ui_components/Forms";
import { ButtonGroup } from "components/ui_components/buttons";
import ButtonBase from "@material-ui/core/ButtonBase";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

const LoginForm = (props) => {
  const [isMfaAuth, setIsMfaAuth] = useState(
    process.env.REACT_APP_HOST_ENV === "prod"
  );
  const {
    isFormValid,
    resetErrors,
    getSubmitData,
    inputsById,
    inputsByHash,
    handleInputs,
  } = props;
  const authCenter = useAuthCenter();
  const notificationCenter = useNotificationCenter();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid()) {
      resetErrors();

      try {
        props.setDataLoading(true);
        if (isMfaAuth) {
          await authCenter
            .cognitoLogIn({ ...getSubmitData() })
            .then(() => {
              props.setDataLoading(false);
            })
            .catch((err) => {
              notificationCenter.show(err.message, "error");
              props.setDataLoading(false);
            });
        } else {
          await authCenter
            .oldLogIn({ ...getSubmitData() })
            .then(() => {
              notificationCenter.show("Successfully login");
              history.push("/");
              props.setDataLoading(false);
            })
            .catch((err) => {
              notificationCenter.show(err.message, "error");
              props.setDataLoading(false);
            });
        }
      } catch (e) {
        notificationCenter.show(e.message, "error");
      }
    }
  };

  const handleIsMfaAuthCheckbox = (event) => {
    setIsMfaAuth(event.target.checked);
  };

  return (
    <React.Fragment>
      <form>
        <BaseForm
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          inputWrapStyles={`width: 100%;`}
        />

        {process.env.REACT_APP_HOST_ENV !== "prod" && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isMfaAuth}
                onChange={handleIsMfaAuthCheckbox}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Use MFA auth"
          />
        )}
        <ButtonGroup rootStyles={"padding: 10px 0 0; text-align: right;"}>
          <Button
            color="primary"
            onClick={() => {
              authCenter.setAuthStatus("forgotPassword");
            }}
          >
            Forgot Password
          </Button>
        </ButtonGroup>

        <ButtonGroup rootStyles={"padding: 20px 0 0; text-align: center;"}>
          <ButtonBase
            type="submit"
            onClick={handleSubmit}
            className="button formButton formButton__mainContained"
          >
            Login
          </ButtonBase>
        </ButtonGroup>
      </form>
    </React.Fragment>
  );
};

export default compose(withForm(loginFormConfig))(LoginForm);
