import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableCheckbox from 'components/ui_components/table/TableCheckbox';

export default function ({ row, defaultSelected, readMode, handleSelectRow, isSelected}) {
  const labelId = `enhanced-table-checkbox-${row.id}`;
  const selectedByDefault = (id) => defaultSelected.indexOf(id) !== -1;

  return (
    <TableCell className="checkbox-col" padding="checkbox">
      <TableCheckbox
        value
        disabled={readMode || selectedByDefault(row.id)}
        onClick={event => handleSelectRow(event, row)}
        checked={isSelected || readMode}
        key={labelId}
        inputProps={{ 'aria-labelledby': labelId }}
      />
    </TableCell>
  )
}