import moment from 'moment-timezone';

const pad = (number) => {
  const parsedNumber = parseInt(number);
  if (parsedNumber < 10) {
    return '0' + parsedNumber;
  }
  return parsedNumber;
}

// const dateToServerISOCustomFormat = (date) => {
//   return pad(date.getUTCMonth()) +
//     '/' + pad(date.getUTCDate()) +
//     '/' + date.getUTCFullYear() +
//     ' ' + pad(date.getUTCHours()) +
//     ':' + pad(date.getUTCMinutes()) +
//     ':' + pad(date.getUTCSeconds())
// }

const formatDateTime = (date, format) => {
  const availableFormats = {
    date: 'MM/DD/YY',
    dateTime: 'MM/DD/YY HH:mm',
    time12: 'hh:mm A',
    time24: 'HH:mm',
    year: 'YYYY',
    month: 'M',
    day: 'D',
    hours: 'H',
    hours12: 'h',
    minutes: 'm',
    tod: 'A',
  }
  if (date === null) return false;
  return moment(date).format(availableFormats[format] || availableFormats.date)
}

const formatServerDateTimeToLocal = (date, format) => {
  if (date === null) return false;
  return formatDateTime(moment.utc(date).local(), format)
}

const formatServerDateTimeToTimeZone = (date, format, timeZone) => {
  if (date === null) return '';
  return formatDateTime(moment(date).tz(timeZone), format)
}

const formatServerDateTimeToTimeZoneWithAbbr = (date, format, timeZone = null, timeZoneAbbr = null) => {
  if (date === null) return '';
  let momentDate = null;
  if (timeZone === 'TZNA' || timeZone === null) {
    momentDate = moment(date);
  }
  else {
    momentDate = moment.utc(date).tz(timeZone);
  }

  let returnDateTime = `${formatDateTime(momentDate, format)}`

  if (timeZoneAbbr) {
    returnDateTime = `${returnDateTime} ${timeZoneAbbr}`
  }
  return returnDateTime;
}

const convertSeparatedDateTimeWithTimeZoneToServerUTC = (
  year,
  month,
  day,
  hours,
  minutes,
  tod = null,
  timeZone) => {

  const dateString = getDateStringFromSeparatedDateTime(
    year,
    month,
    day,
    hours,
    minutes,
    tod,
  )

  return moment.tz(dateString, timeZone).utc().format('MM/DD/YYYY HH:mm')
}

const getDateStringFromSeparatedDateTime = (year, month, day, hours, minutes, tod = null) => {
  let hours24format = hours;
  if (tod) {
    hours24format = convert12HoursTo24(hours, tod);
  }

  return `${year}-${pad(month)}-${pad(day)} ${(hours24format)}:${pad(minutes)}`
}

const convert12HoursTo24 = (hours, AMPM) => {
  let nHours = Number(hours);
  if ((AMPM === "pm" || AMPM === 'PM') && nHours < 12) nHours = nHours + 12;
  if ((AMPM === "am" || AMPM === 'AM') && nHours === 12) nHours = nHours - 12;
  let sHours = nHours.toString();
  if (nHours < 10) sHours = "0" + sHours;
  return sHours;
}

export {
  formatServerDateTimeToLocal,
  formatDateTime,
  formatServerDateTimeToTimeZone,
  formatServerDateTimeToTimeZoneWithAbbr,
  convert12HoursTo24,
  convertSeparatedDateTimeWithTimeZoneToServerUTC,
  getDateStringFromSeparatedDateTime,
  pad,
}