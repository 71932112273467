const styles = () => ({
  main: {
    margin: '32px 26px 0',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '30px 15px 30px'
  },
  stepper: {
    background: 'transparent',
    padding: '24px 24px 0'
  },
  controls: {
    textAlign: 'center',
    padding: '39px 0 0',
    '& button': {
      margin: '0 10px'

    }
  },
  form: {
    margin: 'auto',
    maxWidth: '1130px'
  },
  radioGroup: {
    flexDirection: 'column'
  }
});

export { styles }