import React from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TableHeader from './TableHeader';
import BaseTableRow from './BaseTableRow'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableToolbar from './TableToolbar';
import './style.scss'
import styles from './BaseTable.module.scss';
import clsx from 'clsx';

const BaseTable = (props) => {
  let rowRefs = [];

  const renderEmptyRow = () => {
    let colSpan = props.columns.length;
    if (props.customRowActions) colSpan++;

    return (
      <TableRow>
        <TableCell className="EmptyRow"
                   colSpan={colSpan}>{props.dataLoading ? 'Data loading' : 'Empty Table'}</TableCell>
      </TableRow>
    )
  }

  const handleShowAllCollapse = () => {
    rowRefs.forEach((ref) => {
      ref.current.handleShowCollapse();
    })
  }

  const handleHideAllCollapse = () => {
    rowRefs.forEach((ref) => {
      ref.current.handleHideCollapse();
    })
  }

  const {
    extraData,
    customRowActions,
    rows
  } = props;
  let isEmptyTable = rows.length === 0

  return (
    <div>
      <div className={clsx({
        'base-table': true,
        [styles.baseSubTable]: props.isSubTable,
      })}>
        <TableToolbar
          selectedObjects={props.selectedRowsObjects}
          selectAllClick={props.handleSelectAllRows}
          handleClickDelete={props.handleClickDelete}
          numSelected={props.selectedRowsObjects.length}
          isEmptyTable={isEmptyTable}
          rows={props.rows}
          showSelect={props.showSelect}
          customRowActions={props.customRowActions}
          showDelete={props.showDelete}
          showPagination={props.showPagination}
          showSelectAll={props.showSelectAll}
          total={props.total}
          page={props.page}
          perPage={props.perPage}
          handleSetPage={props.handleSetPage}
          handleSetPerPage={props.handleSetPerPage}
          deleteButtonIcon={props.deleteButtonIcon}
          customToolbarActions={props.customToolbarActions}
          toolbarExtraComponents={props.toolbarExtraComponents}
          actionDeleteInProgress={props.actionDeleteInProgress}
          handleShowAllCollapse={handleShowAllCollapse}
          handleHideAllCollapse={handleHideAllCollapse}
          collapsibleTable={props.collapsibleTable}
        />

        {props.subFilter}

        <Paper
          className={clsx({
            [styles.paper]: true,
            [styles.stickyHeader]: props.stickyHeader,
          })}
        >
          <Table
            aria-labelledby="tableTitle"
            className={styles.tableRoot}
          >
            <TableHeader
              showSelect={props.showSelect}
              rows={props.rows}
              editableTable={props.editableTable}
              collapsibleTable={props.collapsibleTable}
              columns={props.columns}
              handleSetSort={props.handleSetSort}
              order={props.order}
              customRowActions={props.customRowActions}
              orderBy={props.orderBy}
              isSubTable={props.isSubTable}
            />
            <TableBody className="table-body">
              {isEmptyTable && renderEmptyRow()}

              {rows.map((row, index) => {
                let ref = React.createRef();
                rowRefs.push(ref);
                const isSelectedRow = props.selectedRowsObjects.some(selectedRow => selectedRow.id === row.id);

                return (
                  <BaseTableRow
                    ref={ref}
                    index={index}
                    row={row}
                    key={index}
                    push={props.push}
                    collapsibleTableContentType={props.collapsibleTableContentType}
                    collapsibleTableProps={props.collapsibleTableProps}
                    readMode={props.readMode}
                    columns={props.columns}
                    handleRowClick={props.handleRowClick}
                    subTableData={props.subTableData}
                    isSelected={isSelectedRow}
                    handleSelectRow={props.handleSelectRow}
                    extraData={extraData}
                    smartTable={props.smartTable}
                    rowAccessorExtraData={props.rowAccessorExtraData}
                    customRowActions={customRowActions}
                    showSelect={props.showSelect}
                    defaultSelected={props.defaultSelected}
                    editableTable={props.editableTable}
                    subTableConfig={props.subTableConfig}
                    collapsibleTable={props.collapsibleTable}
                    handleSubTableRowsInputData={props.handleSubTableRowsInputData}
                    parentRow={props.parentRow}
                  />
                )
              })}
            </TableBody>
          </Table>
        </Paper>

      </div>
    </div>
  )
}


export default BaseTable;

BaseTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  style: PropTypes.object,
  showSelect: PropTypes.bool,
  showDelete: PropTypes.bool,
  readMode: PropTypes.bool,
}

BaseTable.defaultProps = {
  handleSelectRow: (i) => {
  },
  handleClick: (i) => {
  },
  handleSetSort: (i) => {
  },
  collapsibleTableProps: {
    isVisibleShowButtonAccessor: (i) => {
    },
  },
  selectedRowsObjects: [],
  defaultSelected: [],
  extraData: {},
  rowAccessorExtraData: {},
  toolbarExtraComponents: null,
  subFilter: null,
  isSubTable: false,
  order: 'asc',
  orderBy: '',
  showSelect: false,
  showSelectAll: true,
  showPagination: false,
  useGlobalSelect: false,
  showDelete: true,
  deleteButtonIcon: null,
  readMode: false,
  editableTable: false,
  collapsibleTable: false,
  total: 0,
  page: 1,
  perPage: 10,
  selected: [],
  globalSelected: [],
  subTableData: {}
}