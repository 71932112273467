import { withNotificationCenter } from 'NotificationCenter'
import withForm from 'withForm';
import MainHeader from "components/templateParts/MainHeader";
import SmartInput from 'components/ui_components/smart_input'
import { editConfig } from "config/resources/usersResource";
import style from './UserEditContainer.module.scss'
import React from 'react';
import dataProvider, { UPDATE } from "providers/dataProvider";
import { compose } from 'redux'
import clsx from 'clsx';
import BaseFormControls from 'components/ui_components/BaseFormControls/BaseFormControls';
import MainSingleFormContent from 'components/templateParts/MainSingleFormContent/MainSingleFormContent';
import { getFilteredIds, dynamicValidationInputId } from 'components/pages/users/utils/helpers';

const UserEditContainer = (props) => {
  const { inputsById, inputsByHash } = props;
  const { inputsGroupsById, inputsGroupsByHash } = editConfig;


  const handleSubmit = () => {
    const { isFormValid, isInputValid, resetErrors, inputsByHash } = props;

    const userRoleCodeValue = inputsByHash['userRoleCode'].value;

    resetErrors();

    const isMainFormValid = isFormValid();
    const isSpecialInputValid = dynamicValidationInputId.hasOwnProperty(userRoleCodeValue) ? isInputValid(
      dynamicValidationInputId[userRoleCodeValue]) : true;

    if (isMainFormValid && isSpecialInputValid) {
      submitData();
    }
  }

  const submitData = () => {
    const { notificationCenter, getSubmitData, inputsByHash } = props;

    const submitData = {
      ...getSubmitData()
    }

    if (dynamicValidationInputId.hasOwnProperty(inputsByHash['userRoleCode'].value)) {
      submitData.entityId = inputsByHash[dynamicValidationInputId[inputsByHash['userRoleCode'].value]].value
    }

    dataProvider(UPDATE, `mfa/users/${props.id}`, { data: submitData })
    .then((response) => {
      notificationCenter.show('Successfully saved', 'info');
      props.history.push('/users/' + response.data.id + '/show');
    })
    .catch(error => {
      notificationCenter.show(error.message, 'warning')
    });
  }

  return (
    <div className={style.root}>
      <MainHeader title={"Edit User"}/>

      <MainSingleFormContent>
        <div className={style.form}>
          {inputsById.length > 0 ? inputsGroupsById.map(groupId => {
            return (
              <div key={groupId} className={style['inputs-' + groupId]}>
                {getFilteredIds(inputsGroupsByHash[groupId].ids, inputsByHash).map((inputId, index) => {
                  const input = inputsByHash[inputId];

                  return (
                    <div key={index}
                         className={clsx(style['inputWrap-' + inputId], style.inputWrap)}>
                      <SmartInput
                        key={index}
                        inputId={inputId}
                        input={input}
                        style={style}
                        handleInputs={props.handleInputs}
                      />
                    </div>
                  )
                })}
              </div>
            )
          }) : null}
        </div>
        <BaseFormControls
          type={'edit'}
          handleClickSubmit={handleSubmit}
          submitLabel="Save User"
          deleteConfirmationMessagePostfix={'user'}
          resource="users"
          id={props.id}
        />

      </MainSingleFormContent>
    </div>
  );
}

export default compose(
  withForm({ ...editConfig, mode: 'edit' }),
  withNotificationCenter()
)(UserEditContainer)