import React, { PureComponent } from 'react'
import WidgetSuggestedPatientsContainer from './WidgetSuggestedPatientsContainer';
import BaseForm from 'components/ui_components/BaseForm/BaseForm.js';
import stylesCreate
  from 'components/pages/requisitions/RequisitionsCreate/WidgetPatient.module.scss';

class WidgetPatientContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedPatient: null,
      isNewPatient: false,
      selectedPatientIsEdited: false
    }
  }

  handleClickSuggestedPatient = (patient) => {
    this.setState({
      selectedPatient: patient,
      selectedPatientIsEdited: false
    })
    let updateInputs = [];

    this.props.inputsById.forEach(id => {
      updateInputs.push({ id: id, value: patient[id] })
    })
    this.props.handleBulkInputs(updateInputs);
  }

  handleNewPatientCheck = (e) => {
    this.setState((prevState) => {
      return {
        isNewPatient: !prevState.isNewPatient
      }
    })
  }

  handleEditSelectedPatient = () => {
    this.setState({
      selectedPatientIsEdited: true
    })
  }

  render() {
    const { inputsById, inputsByHash, handleInputs, getSubmitData, inputsGroupsByHash, inputsGroupsById, baseFormStyles } = this.props;
    const formSubmitData = getSubmitData(false);

    let submitData = {};

    inputsById.forEach(id => {
      if (id === 'patientRaceCode' || id === 'patientEthnicityCode') return;
      submitData[id] = formSubmitData[id]
    })
    
    const extendedBaseFormStyles = {
      ...baseFormStyles,
      groupTitleStyles: 'font-weight: 700; font-size: 14px; margin: 10px 0 0;'
    };
    return (
      <div className={stylesCreate.patientContent}>
        <div className={stylesCreate.patientListInputsCol}>
          <BaseForm
            inputsByHash={inputsByHash}
            inputsById={inputsById}
            inputsGroupsByHash={inputsGroupsByHash}
            inputsGroupsById={inputsGroupsById}
            handleInputs={handleInputs}
            {...extendedBaseFormStyles}
          />
        </div>

        <div className={stylesCreate.suggestedPatientListCol}>
          <WidgetSuggestedPatientsContainer
            inputsById={inputsById}
            inputsByHash={inputsByHash}
            submitData={submitData}
            handleEditSelectedPatient={this.handleEditSelectedPatient}
            handleClickSuggestedPatient={this.handleClickSuggestedPatient}/>
        </div>

      </div>
    )
  }
}

export default WidgetPatientContainer