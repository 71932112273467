import { useState } from "react";
import { useNotificationCenter } from "NotificationCenter";

import dataProvider, {
  UPLOAD_FILE,
  UPLOAD_FILE_S3,
  CREATE,
  BULK_REPORT_UPLOADER,
} from "providers/dataProvider";

export default function () {
  const [isUploading, setIsUploading] = useState(false);
  const notificationCenter = useNotificationCenter();

  const upload = async ({ dropZoneFiles, batchTypeCode }) => {
    setIsUploading(true);

    let fileNames = [];
    let files = [];

    dropZoneFiles.forEach((file, index) => {
      fileNames.push(file.name);
      files.push(file);
    });

    try {
      const gdbInitResponse = await dataProvider(
        UPLOAD_FILE,
        `bulk_report_uploader/init`,
        {
          data: {
            fileNames: fileNames,
            batchTypeCode: batchTypeCode,
          },
        }
      );

      const gdbBulkResponse = await dataProvider(
        BULK_REPORT_UPLOADER,
        `bulk_report_uploader/${gdbInitResponse.data[0].id}/files`,
        {
          pagination: {
            perPage: 100,
          },
        }
      );

      for (const [
        index,
        gdbUploadResponseItem,
      ] of gdbBulkResponse.data.entries()) {
        await uploadFile({
          gdbInitResponse: gdbInitResponse,
          gdbBulkResponse: gdbBulkResponse,
          gdbUploadResponseItem: gdbUploadResponseItem,
          batchTypeCode: batchTypeCode,
          files: files,
          index: index,
        });
      }
      setIsUploading(false);
    } catch (e) {
      notificationCenter.show(e.message, "error");
      setIsUploading(false);
    }
  };

  const uploadFile = async ({
    gdbInitResponse,
    gdbBulkResponse,
    gdbUploadResponseItem,
    batchTypeCode,
    files,
    index,
  }) => {
    let uploadUrl = "";

    try {
      uploadUrl = JSON.parse(gdbUploadResponseItem.uploadUrl);
    } catch (e) {
      console.error(e.message);
      console.error(gdbUploadResponseItem);
      notificationCenter.show(e.message, "error");
      setIsUploading(false);
    }
    const s3Request = { ...uploadUrl.fields };

    const s3Response = await dataProvider(UPLOAD_FILE_S3, uploadUrl.url, {
      data: {
        ...s3Request,
        downloadFile: files[index],
      },
    });

    if (s3Response.status !== 204) {
      notificationCenter.show("S3 error", "error");
      return false;
    }

    const gdbConfirmUploadResponse = await dataProvider(
      CREATE,
      `bulk_report_uploader/${gdbInitResponse.data[0].id}/finish/${gdbUploadResponseItem.id}`,
      {
        data: {
          batchTypeCode: batchTypeCode,
        },
      }
    );

    if (gdbBulkResponse.data.length - 1 === index) {
      notificationCenter.show(gdbConfirmUploadResponse.message, "info");
    }
  };

  return {
    upload,
    isUploading,
  };
}
