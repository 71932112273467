import React from 'react'
import { BaseWidgetViewList } from 'components/ui_components/Widgets/WidgetViewLists';
import styled from 'styled-components';

const defaultRootStyles = ``;

const StyledRoot = styled.div`
 &&{
    ${defaultRootStyles}
    ${props => props.styles}
  }
`;

const WidgetViewModeByGroups = ({ theme, viewSubGroups, data, inputsByHash, rootStyles }) => {

  return (
    <StyledRoot styles={rootStyles}>
      {viewSubGroups.map((group, index) => {
        let itemStyles = 'width: 100%; max-width: 200px;';

        if (group.itemStyles) {
          itemStyles += group.itemStyles;
        }
       return (
         <BaseWidgetViewList key={index}
                             title={group.title}
                             ids={group.ids}
                             theme={theme}
                             inputsByHash={inputsByHash}
                             data={data}
                             itemStyles={itemStyles}
         />
       )
      }
      )}
    </StyledRoot>
  )
}

export default WidgetViewModeByGroups

WidgetViewModeByGroups.defaultProps = {
  itemStyles: '',
}