import React, { useState, useEffect } from 'react'
import dataProvider, { GET_ONE } from "providers/dataProvider";
import MainHeader from 'components/templateParts/MainHeader';
import SingleViewHeader from 'components/templateParts/SingleViewHeader/SingleViewHeader';
import { PhoneIcon, EmailIcon, DIcon, Person3Icon } from 'icons';
import ActionsWidgetContainer from './ActionsWidgetContainer';
import RolesWidgetComponent from './RolesWidgetComponent';
import { WidgetGroup } from 'components/ui_components/Widgets'
import { phoneFormat } from 'utils';

const UserShowContainer = (props) => {
  const [data, setData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const loadData = () => {
      dataProvider(GET_ONE, props.resource, { id: props.id })
      .then(({ data, total }) => {
        setData(data);
        setDataLoaded(true);
      })
    }

    loadData();
  }, [props.id, props.resource]);

  const widgetsConfigByHash = {
    about: {
      title: 'User’s Information',
      type: '1',
      iconsData: [
        {
          id: 'userPhone',
          title: 'Phone Number',
          value: phoneFormat(data.userPhone),
          icon: <PhoneIcon/>
        },
        {
          id: 'userEmail',
          title: 'Email',
          value: data.userEmail,
          icon: <EmailIcon/>
        },
        {
          id: 'userRoleDesc',
          title: 'Role',
          value: data.userRoleDesc,
          icon: <DIcon/>
        },
        {
          id: 'userName',
          title: 'Username',
          value: data.userName,
          icon: <Person3Icon/>
        },
        {
          id: 'entityName',
          title: 'Entity Name',
          value: data.entityName,
          icon: <Person3Icon/>
        },
        {
          id: 'isSubscribed',
          title: 'Subscribe to email notification',
          value: data.isSubscribed === 1 ? "Yes" : "No",
          icon: <EmailIcon/>
        },
      ]
    }
  }

  const widgetsConfigById = ['about']

  const widgetsConfigGroups = [
    ['about']
  ]

  if (!dataLoaded) return null;

  return (
    <div>
      <MainHeader title="View User"/>
      <SingleViewHeader
        editLink={"/users/" + data.id}
        title={data.userFirstName + ' ' + data.userLastName}
        widgetsConfigByHash={widgetsConfigByHash}
        widgetsConfigById={widgetsConfigById}
        widgetsConfigGroups={widgetsConfigGroups}
      />

      <div className="mainSingleContainer">
        <WidgetGroup>
          <RolesWidgetComponent userDepartments={data.depts || [] }/>
          <ActionsWidgetContainer
            userID={props.id}
            userStatusCode={data.userStatusCode}
          />
        </WidgetGroup>
      </div>
    </div>
  );
}

export default UserShowContainer