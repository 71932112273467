import React from 'react'
import styles from "./RolesWidget.module.scss";
import { UsersIcon } from 'icons';
import { WidgetType1 } from 'components/ui_components/Widgets'
import PropTypes from 'prop-types';

const RolesWidgetComponent = ({ userDepartments }) => {
  const content = <div className={styles.widgetList}> {userDepartments.map((department, index) =>
    <div key={index} className={styles.widgetListItem}>
      <div className={styles.widgetListItemTitle}>
        {department.deptName}
      </div>
      {index === 0 && <UsersIcon/>}
    </div>
  )}
  </div>

  return (
    <WidgetType1
      title="Departments"
      content={content}
      wrapStyles={`max-width: 500px;`}
    />
  )
}

RolesWidgetComponent.propTypes = {
  userDepartments: PropTypes.array.isRequired
}

RolesWidgetComponent.defaultProps = {
  userDepartments: []
}

export default RolesWidgetComponent