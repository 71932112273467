import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PersonIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M6.9,7.3c0.2,2,1,1.2,1.1,2.1c0.3,1.5,1.6,2.6,2.4,2.9c0.5,0.2,1,0.3,1.5,0.3v0c0,0,0,0,0,0c0,0,0,0,0,0v0
	c0.5,0,1-0.1,1.5-0.3c0.8-0.4,2.1-1.4,2.4-2.9c0.2-0.9,1,0,1.1-2.1c0-0.8-0.4-1-0.4-1s0.2-1.2,0.3-2.1C17,3,16.2,0,12,0
	c0,0,0,0-0.1,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0-0.1,0c-4.2,0-5,3-4.9,4.1c0.1,0.9,0.3,2.1,0.3,2.1
	S6.9,6.5,6.9,7.3z M21.5,17.3c-2.1-1.1-5.2-2.4-5.3-2.5c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7,0.2-0.8,0.5c0,0,0,0,0,0
	c-0.5,1.3-1.5,3.7-2.2,4.8L12.8,17c0,0,0.9-2,1-2.2c0.2-0.4,0-0.8-0.4-0.8c-0.2,0-0.8,0-1.3,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.6,0-1.1,0-1.3,0c-0.5,0-0.7,0.4-0.4,0.8c0.1,0.2,1,2.2,1,2.2
	l-0.1,3.2c-0.6-1.1-1.6-3.5-2.2-4.8c0,0,0,0,0,0c-0.1-0.3-0.4-0.5-0.8-0.5c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.1,0-3.2,1.4-5.3,2.5
	C1.6,17.8,1,18.4,1,19c0,2.1,0,5,0,5H12h0h0h0h0h0h0h0h0h0h0h0h0H23c0,0,0-2.9,0-5C23,18.4,22.4,17.8,21.5,17.3z"/>
    </SvgIcon>
);

export default PersonIcon;