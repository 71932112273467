import React, { Component } from "react";
import dataProvider, { GET_LIST } from "providers/dataProvider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import style from "./RadioContainer.module.scss";
import clsx from "clsx";

const styles = (theme) => ({
  label: {
    padding: "7px 0 0",
  },

  radioGroupError: {
    "& label": {
      color: "red",
    },
  },
});

const StyledRadio = withStyles({
  root: {
    "&$checked": {
      color: "#53C4CC",
    },

    "& svg": {
      width: "16px",
      height: "16px",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class RadioContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };

    if (!this.props.input.hasOwnProperty("relativeInputsFilterAccessor")) {
      this.loadData();
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.initOption(this.props.input);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initOption = (input) => {
    if (input.hasOwnProperty("resource")) {
      this.loadData();
    } else if (input.hasOwnProperty("options")) {
      this.setState({
        options: input.options,
      });
    }
  };

  getFilter = (input) => {
    try {
      return (
        input.filter ||
        (input.relativeInputsFilterAccessor &&
          input.relativeInputsFilterAccessor(input.relativeInputsData)) ||
        {}
      );
    } catch {
      return false;
    }
  };

  getResource = (input, options) => {
    try {
      return (
        input.resource ||
        (input.resourceAccessor && input.resourceAccessor(options)) ||
        input.relativeInputResourceAccessor(input.relativeInputsData)
      );
    } catch {
      return false;
    }
  };

  loadData() {
    const { input, options } = this.props;
    const filter = this.getFilter(input);
    const resource = this.getResource(input, options);

    if (!filter) return;
    if (!resource) return;

    dataProvider(GET_LIST, resource, { filter }).then(({ data, total }) => {
      let options = data.map(input.accessor);
      if (this._isMounted) {
        this.setState({
          isDataLoaded: true,
          options,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.input.relativeInputsData) !==
      JSON.stringify(prevProps.input.relativeInputsData)
    ) {
      this.loadData();
    }
  }

  handleChange = (e) => {
    this.props.handleInputs(e);
  };

  render() {
    const { options } = this.state;
    const { classes, input, value, inputId, parentStyles } = this.props;

    return (
      <div
        className={clsx(style.radioGroupWrap, {
          [style.radioGroupError]: input.hasOwnProperty("yup") && input.error,
        })}
      >
        <div className={parentStyles.radioInputLabel || style.radioInputLabel}>
          {input.error ? input.errorMessage : input.label}
        </div>

        <RadioGroup
          name={inputId}
          onChange={this.handleChange}
          value={value === null || value === undefined ? "" : value.toString()}
          className={parentStyles.radioGroup || style.radioGroup}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              disabled={input.disabled}
              value={option.value.toString()}
              className={classes.label}
              control={<StyledRadio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </div>
    );
  }
}

RadioContainer.defaultProps = {
  parentStyles: {},
};

export default withStyles(styles)(RadioContainer);
