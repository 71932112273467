export default {
  orderingPhysician: {
    title: "Ordering Physician",
    inputsById: [
      "collaborator",
      "clientId",
      "orderingClientPhysId",
      "eventId",
      "extOrderId",
      "labId",
    ],
    inputsGroupsByHash: {
      group1: {
        ids: [
          [
            "collaborator",
            "clientId",
            "orderingClientPhysId",
            "labId",
            "eventId",
            "extOrderId",
          ],
        ],
        inputWrapStyles: "width: 16.66%; max-width: none;",
      },
    },
    inputsGroupsById: ["group1"],
  },
  createPatient: {
    title: "Create Patient",
    inputsById: [
      "patientDobMonth",
      "patientDobDate",
      "patientDobYear",
      "patientLastName",
      "patientFirstName",
      "patientMiddleName",
      "patientGenderCode",
      "patientRaceCode",
      "patientEthnicityCode",
    ],
    inputsGroupsByHash: {
      group1: {
        title: "Patient date of birth",
        ids: [
          [
            "patientDobMonth",
            "patientDobDate",
            "patientDobYear",
            "patientLastName",
            "patientFirstName",
            "patientMiddleName",
          ],
        ],
      },
      group2: {
        ids: [["patientGenderCode", "patientRaceCode", "patientEthnicityCode"]],
      },
    },
    inputsGroupsById: ["group1", "group2"],
  },
  specimenInformation: {
    title: "Specimen Information",
    inputsById: [
      "dateCollectedMonth",
      "dateCollectedDate",
      "dateCollectedYear",
      "timeCollectedHours",
      "timeCollectedMinutes",
      "timeCollectedAmPm",
      "collectCode",
      "collectedBy",
    ],
    inputsGroupsByHash: {
      group1: {
        ids: [
          [
            "dateCollectedMonth",
            "dateCollectedDate",
            "dateCollectedYear",
            "timeCollectedHours",
            "timeCollectedMinutes",
            "timeCollectedAmPm",
            "collectCode",
            "collectedBy",
          ],
        ],
      },
    },
    inputsGroupsById: ["group1"],
  },
  patientHistory: {
    title: "Patient History",
    inputsById: [
      "sbp",
      "dbp",
      "isSmoker",
      "isDiabetic",
      "isFamilyHistory",
      "isCovidVac",
      "famHistT2DCode",
      "isSelfT2DGest",
      "weight",
      "weightUom",
      "heightFeet",
      "heightInches",
      "heightCm",
      "heightUom",
    ],
    inputsGroupsByHash: {
      group1: {
        ids: [
          ["sbp", "dbp", "heightFeet", "heightInches", "heightCm", "heightUom"],
          ["weight", "weightUom", "isSmoker", "isDiabetic", "isFamilyHistory"],
          [
            "isSelfT2DGest",
            "famHistT2DCode",
            "isCovidVac",
          ]
        ],
      },
    },
    inputsGroupsById: ["group1"],
  },
};
