import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FaxIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M18,5l-2.9,0c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2l2.9,0c0.1,0,0.2-0.1,0.2-0.2C18.2,5.2,18.1,5,18,5z
	 M20.9,3.6l-5.8,0c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2l5.8,0c0.1,0,0.2-0.1,0.2-0.2C21.1,3.7,21,3.6,20.9,3.6z M20.9,2.1
	l-5.8,0c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2l5.8,0c0.1,0,0.2-0.1,0.2-0.2C21.1,2.2,21,2.1,20.9,2.1z M22,4.4
	c0-1.2,0-2.4,0-3.7c-2.7,0-5.4,0-8.2,0c0,1.2,0,2.4,0,3.7c-1.1,0-2.2,0-3.2,0c0.3,0.7,0.4,1.3,0.4,2.1c0,4.2,0,8.3,0,12.5
	c0,0.7-0.1,1.4-0.4,2.1c3.8,0,7.6,0,11.4,0c1.1,0,2-0.9,2-2c0-4.2,0-8.5,0-12.7C24,5.3,23.1,4.4,22,4.4z M14.4,1.2h7.2v5h-7.2V1.2z
	 M14.9,16.8c-0.9,0-1-0.1-1-1c0-0.9,0.1-1,1-1c0.9,0,1,0.1,1,1C15.9,16.7,15.8,16.8,14.9,16.8z M14.9,13.7c-0.9,0-1-0.1-1-1
	c0-0.9,0.1-1,1-1c0.9,0,1,0.1,1,1C15.9,13.6,15.8,13.7,14.9,13.7z M14.9,10.7c-0.9,0-1-0.1-1-1c0-0.9,0.1-1,1-1c0.9,0,1,0.1,1,1
	C15.9,10.6,15.8,10.7,14.9,10.7z M18,16.8c-0.9,0-1-0.1-1-1c0-0.9,0.1-1,1-1c0.9,0,1,0.1,1,1C19,16.7,18.9,16.8,18,16.8z M18,13.7
	c-0.9,0-1-0.1-1-1c0-0.9,0.1-1,1-1c0.9,0,1,0.1,1,1C19,13.6,18.9,13.7,18,13.7z M18,10.7c-0.9,0-1-0.1-1-1c0-0.9,0.1-1,1-1
	c0.9,0,1,0.1,1,1C19,10.6,18.9,10.7,18,10.7z M21,16.8c-0.9,0-1-0.1-1-1c0-0.9,0.1-1,1-1c0.9,0,1,0.1,1,1C22.1,16.7,22,16.8,21,16.8
	z M21,13.7c-0.9,0-1-0.1-1-1c0-0.9,0.1-1,1-1c0.9,0,1,0.1,1,1C22.1,13.6,22,13.7,21,13.7z M21,10.7c-0.9,0-1-0.1-1-1
	c0-0.9,0.1-1,1-1c0.9,0,1,0.1,1,1C22.1,10.6,22,10.7,21,10.7z M6,2.4c-2.3,0-4.1,1.8-4.1,4.1c0,4.2,0,8.3,0,12.5
	c0,2.3,1.8,4.1,4.1,4.1c2.3,0,4.1-1.8,4.1-4.1c0-4.2,0-8.3,0-12.5C10.1,4.2,8.3,2.4,6,2.4z M1.3,4.5C0.5,4.8,0,5.5,0,6.4
	c0,4.2,0,8.5,0,12.7c0,0.8,0.5,1.6,1.3,1.8C1,20.3,0.9,19.7,0.9,19c0-4.2,0-8.3,0-12.5C0.9,5.8,1,5.2,1.3,4.5z"/>
    </SvgIcon>
);

export default FaxIcon;