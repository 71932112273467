import React, { useState } from 'react';

export default function ({ disable, value, valueOnFocus, onChange }) {
  const [inFocus, setInFocus] = useState(false);

  return (
    <input
      onFocus={() => {setInFocus(true)}}
      onBlur={() => {setInFocus(false)}}
      className="edit-input"
      disabled={disable}
      type="text"
      value={inFocus ? valueOnFocus : value}
      onChange={onChange}/>
  )
}