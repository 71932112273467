import React from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
import style from "./SingleViewHeader.module.scss";
import ReactHtmlParser from "react-html-parser";

const WidgetListItemComponent = props => {
    const {icon} = props;
    return (
        <div
             className={clsx([style.widgetListItem])}
        >
            <div className={style.widgetListItemTitle}>
                {icon.title}
            </div>
            <div className={style.widgetListItemValue}>
                {ReactHtmlParser(icon.value)}
            </div>
            {icon.icon}
        </div>
    );
};

WidgetListItemComponent.propTypes = {
    icon: PropTypes.object.isRequired
};

export default WidgetListItemComponent;