import helpers from 'utils/helpers';

export default {
  inputsById: [
    'issueWorkLog',
  ],
  inputsByHash: {
    issueWorkLog: {
      label: 'Issue Work Log',
      type: 'multiline',
      yup: helpers.getYupRequired('Issue Work Log'),
    },
  },
  inputsGroupsByHash: {
    group1: {
      ids: [
        ['issueWorkLog'],
      ]
    },
  },

  inputsGroupsById: [
    'group1',
  ],
}