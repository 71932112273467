import React, { useState, useEffect } from 'react'
import AuthCenterContext from './AuthCenterContext'
import '@aws-amplify/ui/dist/style.css';
import axios from 'axios'
import config from "config";
import Amplify, { Auth } from 'aws-amplify';
import apiError from 'providers/handleError.js';
import authStorage, { storage } from 'providers/authStorage.js';
import { useNotificationCenter } from 'NotificationCenter';
import history from 'history.js';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar'

const useStyles = makeStyles({
  root: {
    background: fade('#ffffff', 0.5),
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: '1000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

Amplify.configure({
  Auth: {
    region: config.cognito.region,
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.userPoolWebClientId,
  }
});

const AuthCenterProvider = ({ children }) => {
  const [loginPassword, setLoginPassword] = useState(null);

  const notificationCenter = useNotificationCenter();

  const [token, setToken] = useState(authStorage.getToken());
  const [userDepts, setUserDepts] = useState(authStorage.getUserDepts());
  const [access, setAccess] = useState(authStorage.getAccess());

  const [cognitoUser, setCognitoUser] = useState(null);
  const [cognitoToken, setCognitoToken] = useState(authStorage.getCognitoToken());

  const [authStatus, setAuthStatus] = useState('login');
  const [refreshTokenInProgress, setRefreshTokenInProgress] = useState(authStorage.getIsRefreshTokenFlowInProgress());

  const classes = useStyles();

  useEffect(() => {
    function handleStorageChange(event) {
      if (event.key === storage.key('refreshTokenFlowInProgress')) {
        setRefreshTokenInProgress(JSON.parse(event.newValue))
      }
      else if (event.key === storage.key('token')) {
        setToken(JSON.parse(event.newValue))
      }
      else if (event.key === storage.key('userDepts')) {
        setUserDepts(JSON.parse(event.newValue))
      }
      else if (event.key === storage.key('access')) {
        setAccess(JSON.parse(event.newValue))
      }
    }
  
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  });

  useEffect(() => {
    if (refreshTokenInProgress) {
      setTimeout(() => {
        authStorage.finishRefreshTokenFlow();
      }, 10000)
    }
  }, [refreshTokenInProgress]);

  useEffect(() => {
    apiError.on(apiError.status420, () => {
      console.log('handle-status420');
      if (!refreshTokenInProgress) {
        authStorage.startRefreshTokenFlow();
        authStorage.logout();
        refreshToken();
      }
    });
    return () => apiError.off(apiError.status420);
  });

  useEffect(() => { 
    apiError.on(apiError.status408, () => {
      console.log('handle-status408');
      if (!refreshTokenInProgress) {
        authStorage.logout();
        setAuthStatus('login');
        history.push("/login");
        // window.location.href = "/login";
      }
    });
    return () => apiError.off(apiError.status408);
  });

  useEffect(() => {
    apiError.on(apiError.status490, () => {
      console.log('handle-status490');
      setAuthStatus('passwordExpired');
    });
    return () => apiError.off(apiError.status403);
  });

  useEffect(() => {
    authStorage.on(authStorage.CHANGE_COGNITO_AUTH, setAuthCognitoState);
    return () => authStorage.off(setAuthCognitoState);
  });

  useEffect(() => {
    authStorage.on(authStorage.CHANGE_REFRESH_TOKEN_FLOW_STATUS, setRefreshTokenInProgressToState);
    return () => authStorage.off(setRefreshTokenInProgressToState);
  });

  const setRefreshTokenInProgressToState = (status) => {
    setRefreshTokenInProgress(status);
  }

  const setAuthCognitoState = ({ cognitoAccessToken }) => {
    setCognitoToken(cognitoAccessToken);
  }

  async function cognitoLogIn({ username, password }) {
    console.log('cognitoLogIn');
    try {
      const user = await Auth.signIn(username, password);

      setLoginPassword(password);
      setCognitoUser(user);

      if (user.challengeName === 'SMS_MFA' ||
        user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setAuthStatus('confirmSignIn');

      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setAuthStatus('changeTemporaryPassword');
      }

      else if (user.authenticationFlowType === 'USER_SRP_AUTH') {
        const { accessToken } = user.signInUserSession;

        authStorage.authenticateCognito(accessToken.jwtToken);
        await mfaLogin(accessToken.jwtToken)
        .then(() =>{
          notificationCenter.show('Successfully login')
          history.push("/");
        })
        .catch((e) => {
          notificationCenter.show(e, 'error');
        })
      }

      return user;
    }
    catch (err) {
      throw err;
    }
  }

  async function confirmSignIn(code) {
    console.log('confirmSignIn');
    try {
      // const cognitoUser = await Auth.currentAuthenticatedUser();
      const loggedUser = await Auth.confirmSignIn(
        cognitoUser,   // Return object from Auth.signIn()
        code,   // Confirmation code
        cognitoUser.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );

      const jwtToken = loggedUser.signInUserSession.accessToken.jwtToken;

      authStorage.authenticateCognito(jwtToken);

      return jwtToken;
    }
    catch (err) {
      if (err.code === 'NotAuthorizedException') {
        setAuthStatus('login');
      }
      throw err;
    }
  }

  const refreshToken = async () => {
    console.log('refreshToken');
    setRefreshTokenInProgress(true); // update flag directly, because handling storage won't work on the same page that is making the changes
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        const jwtToken = session.accessToken.jwtToken;
        authStorage.authenticateCognito(jwtToken);
        mfaLogin(jwtToken).then(() => {
          authStorage.finishRefreshTokenFlow();
          setRefreshTokenInProgress(false); // update flag directly, because handling storage won't work on the same page that is making the changes
        })
      });
    }
    catch (e) {
      history.push("/login");
      authStorage.finishRefreshTokenFlow()
      setRefreshTokenInProgress(false); // update flag directly, because handling storage won't work on the same page that is making the changes
      console.log('Unable to refresh Token', e);
    }
  }

  async function oldLogIn({ username, password }) {
    try {
      return axios.request({
          method: 'POST',
          url: `${config.apiUrl}/login`,
          data: {
            "userName": username,
            'pwd': password
          }
        }
      )
      .then(response => {
        const { data } = response.data;
        authStorage.authenticate(data.token, data.userDepts, data.access);
      
        // update token directly, because handling storage won't work on the same page that is making the changes
        setToken(data.token)
        setUserDepts(data.userDepts)
        setAccess(data.access)
        setAuthStatus('login');

        return response.data
      })
      .catch((err) => {
        return apiError.handle(err)
      })
    }
    catch (err) {
      throw err;
    }
  }

  const changePassword = async (newPassword) => {
    console.log('changePassword');
    try {
      const loggedUser = await Auth.completeNewPassword(
        cognitoUser,
        newPassword,
      );
      if (loggedUser.challengeName === 'SMS_MFA' ||
        loggedUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setAuthStatus('confirmSignIn');

      } else if (loggedUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setAuthStatus('changeTemporaryPassword');
      }

      return loggedUser;
    }
    catch (err) {
      throw err;
    }
  }

  const mfaChangePassword = async (currentPassword, newPassword) => {
    console.log('mfaChangePassword');

    // const jwt = cognitoToken;
    // const currentSession = await Auth.currentSession();
    // let jwt = currentSession.accessToken.jwtToken;

    // Auth.currentCredentials(credentials => {
    //   const tokens = Auth.essentialCredentials(credentials);
    //
    //   console.log(tokens);
    // })

    return axios.request({
        method: 'PUT',
        url: `${config.apiUrl}/mfa/profile/chg_pwd`,
        headers: {
          token: authStorage.getToken(),
        },
        data: {
          currentPwd: currentPassword,
          newPwd: newPassword,
          accessToken: cognitoToken
        },
      }
    )
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return apiError.handle(err)
    })
  }

  const confirmPasswordMfa = (userName, newPassword, verificationCode) => {
    console.log('confirmPasswordMfa');
    return axios.request({
        method: 'PUT',
        url: `${config.apiUrl}/mfa/confirm_pwd`,
        data: {
          userName: userName,
          newPwd: newPassword,
          verificationCode: verificationCode,
        },
      }
    )
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return apiError.handle(err)
    })
  }

  const mfaChangeExpiredPassword = async (newPassword) => {
    console.log('mfaChangeExpiredPassword');
    const cognitoUser = await Auth.currentAuthenticatedUser();

    return axios.request({
        method: 'PUT',
        url: `${config.apiUrl}/mfa/profile/chg_exp_pwd`,
        data: {
          currentPwd: loginPassword,
          newPwd: newPassword,
          accessToken: cognitoUser.signInUserSession.accessToken.jwtToken,
        },
      }
    )
    .then(response => {
      setAuthStatus('login');
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return apiError.handle(err)
    })
  }

  const mfaLogin = (accessToken) => {
    console.log('mfaLogin');
    return axios.request({
        method: 'POST',
        url: config.apiUrl + '/mfa/login',
        data: {
          "accessToken": accessToken,
        }
      }
    )
    .then(response => {
      const { data } = response.data;
      authStorage.authenticate(data.token, data.userDepts, data.access);
      
      // update token directly, because handling storage won't work on the same page that is making the changes
      setToken(data.token)
      setUserDepts(data.userDepts)
      setAccess(data.access)

      setAuthStatus('login');

      return response.data
    })
    .catch((err) => {
      return apiError.handle(err)
    })
  }

  const logOutOk = (response = null) => {
    authStorage.logout();
    authStorage.logoutCognito();
    return Promise.resolve(response)
  }

  const logOut = () => {
    console.log('logOut');
    try {
      return axios.request({
        method: 'PUT',
        url: `${config.apiUrl}/mfa/logout/${authStorage.access.userId}`,
        headers: {
          token: authStorage.getToken(),
        },
        data: {},
      }
      )
        .then(response => {
          return logOutOk(response);
        })
        .catch((err) => {
          return apiError.handle(err)
        })
    }
    catch (e) {
      return logOutOk();
    }
  }

  const forgotPassword = ({ username }) => {
    return axios.request({
        method: 'PUT',
        url: `${config.apiUrl}/mfa/forgot_pwd/${username}`,
        data: {}
      }
    )
      .then(response => {  
        notificationCenter.show(response.data.message)
        setAuthStatus('login');
      return response
    })
      .catch((err) => {
        notificationCenter.show(err, 'error')
      return apiError.handle(err)
    })
  }

  const can = (roles) => {
    if (access && access.hasOwnProperty('userRoleCode')) {
      return roles.includes(access.userRoleCode);
    } else {
      return false
    }
  }

  const value = {
    oldLogIn: oldLogIn,
    cognitoLogIn: cognitoLogIn,
    confirmSignIn: confirmSignIn, 
    changePassword: changePassword,
    mfaChangePassword: mfaChangePassword,
    confirmPasswordMfa: confirmPasswordMfa,
    mfaChangeExpiredPassword: mfaChangeExpiredPassword,
    setAuthStatus: setAuthStatus,
    mfaLogin: mfaLogin,
    logOut: logOut,
    can: can,
    forgotPassword: forgotPassword,
    token: token,
    userDepts: userDepts,
    access: access,
    authStatus: authStatus,
  }

  return (
    <React.Fragment>
      <AuthCenterContext.Provider
        value={value}
      >
        {refreshTokenInProgress && <div className={classes.root}>
          <div>
            <ProgressBar/>
          </div>
        </div>}
        {children}
      </AuthCenterContext.Provider>
    </React.Fragment>
  )
}

export default AuthCenterProvider;