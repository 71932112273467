import React, { useState } from 'react'
import { SmartTable } from 'components/ui_components/table'
import { Search } from 'components/ui_components'
import ButtonBase from '@material-ui/core/ButtonBase';
import helpers from 'utils/helpers';
import styles from 'components/pages/clients/ClientsShow/AddPhysicianModal/AddPhysicianModal.module.scss';
import dataProvider, { UPDATE } from "providers/dataProvider";
import { useNotificationCenter } from 'NotificationCenter'

const clientTableColConfig = [
  {
    id: 'id',
    label: 'Physician ID'
  },
  {
    id: 'physFirstName',
    label: 'Physician Name',
    accessor: (d) => {
      return d.physFirstName + ' ' + d.physLastName
    }
  }
]

const ListingMode = (props) => {
  const [searchString, setSearchString] = useState(null);
  const [selectedPhysicians, setSelectedPhysicians] = useState([]);
  const { clientID, handleAddNewAssociatedPhysicians, handleCloseModal } = props;
  const { selected } = props;

  const notificationCenter = useNotificationCenter();

  const searchCallback = (searchString) => {
    setSearchString(searchString);
  }

  const handleSelectedPhysicians = (physicians) => {
    setSelectedPhysicians(physicians);
  }

  const handleSubmit = () => {
    let data = {
      physIds: helpers.getAllValues(selectedPhysicians, 'id')
    }

    dataProvider(UPDATE, 'clients', { data: data, id: clientID })
    .then((response) => {
      notificationCenter.show('Successfully updated', 'info')
      handleCloseModal();
      handleAddNewAssociatedPhysicians()
    })
    .catch(error => {
      notificationCenter.show(error.message, 'warning')
    });
  }
  return (
    <React.Fragment>
      <div>
        <Search callback={searchCallback}/>
        <SmartTable
          columns={clientTableColConfig}
          resource={'physicians'}
          showSelect={true}
          showDelete={false}
          showSelectAll={false}
          searchString={searchString}
          searchBy="physFirstName"
          handleSelectRowCallBack={handleSelectedPhysicians}
          defaultSelected={selected}
          useGlobalSelect={true}
          order='desc'
        />
      </div>

      <div className={`${'buttonGroup'} ${styles.buttonGroup}`}>
        <ButtonBase
          className="button formButton formButton__secondaryOutlined"
          onClick={handleCloseModal}>
          Cancel
        </ButtonBase>
        <ButtonBase
          className="button formButton formButton__mainContained"
          onClick={handleSubmit}
        >
          Add
        </ButtonBase>
      </div>
    </React.Fragment>
  );
}

export default ListingMode;