import React from 'react'
import PropTypes from 'prop-types'
import Text from './text'
import Number from './NumberContainer'
import Select from './select'
import Autocomplete from './autocomplete'
import Multiline from './multiline'
import DateRange from './DateRangeContainer'
import Date from './DateContainer'
import ReqPassCodeMultiSelectContainer from './ReqPassCodeMultiSelectContainer';
import TestPassCodeMultiSelectContainer from './TestPassCodeMultiSelectContainer';
import TestFailCodeMultiSelectContainer from './TestFailCodeMultiSelectContainer';
import Time from './Time/TimeContainer';
import Radio from './RadioContainer';
import Checkbox from './Checkbox/CheckboxContainer';
import Password from './Password';
import SimpleInput from 'components/ui_components/smart_input/SimpleInput';
import SingleCheckbox from 'components/ui_components/smart_input/SingleCheckbox/SingleCheckbox';
import Phone from 'components/ui_components/smart_input/Phone/Phone';

const SmartInput = React.forwardRef((props, ref) => {
  const getBlockComponent = () => {
    let key = props.inputId
    switch (props.input.type) {
      case 'phone':
        return <Phone key={key} {...props} value={props.input.value}/>

      case 'radio':
        return <Radio key={key} {...props} value={props.input.value}/>

      case 'checkbox':
        return <Checkbox key={key} {...props} value={props.input.value} ref={ref}/>

      case 'singleCheckbox':
        return <SingleCheckbox key={key} {...props} value={props.input.value} ref={ref}/>

      case 'select':
        return <Select key={key} {...props} value={props.input.value}/>

      case 'reqPassCodeMultiSelect':
        return <ReqPassCodeMultiSelectContainer key={key} {...props} value={props.input.value}/>

      case 'testPassCodeMultiSelect':
        return <TestPassCodeMultiSelectContainer key={key} {...props} value={props.input.value}/>

      case 'testFailCodeMultiSelect':
        return <TestFailCodeMultiSelectContainer key={key} {...props} value={props.input.value}/>

      case 'password':
        return <Password key={key} {...props} value={props.input.value}/>

      case 'text':
        return <Text key={key} {...props} value={props.input.value}/>

      case 'number':
        return <Number key={key} {...props} value={props.input.value}/>

      case 'input':
        return <SimpleInput key={key} {...props} value={props.input.value}/>

      case 'multiline':
        return <Multiline key={key} {...props} value={props.input.value}/>

      case 'autocomplete':
        return <Autocomplete key={key} {...props} value={props.input.value}/>

      case 'dateRange':
        return <DateRange key={key} {...props} value={props.input.value}/>

      case 'date':
        return <Date key={key} {...props} value={props.input.value}/>

      case 'time':
        return <Time key={key} {...props} value={props.input.value}/>

      default:
        return <Text key={key} {...props} value={props.input.value}/>
    }
  }

  return (
    getBlockComponent()
  )
})

SmartInput.propTypes = {
  input: PropTypes.object.isRequired,
  inputId: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
}

SmartInput.defaultProps = {
  handleInputs: (i) => {
  },
  style: {},
}

export default SmartInput