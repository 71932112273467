import React from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import PencilIcon from '../../../icons/PencilIcon';
import Button from '@material-ui/core/Button';
import style from './BaseSingleViewHeader.module.scss';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const styles = theme => {
    return ({
        introBar: {
            background: theme.palette.secondary2.main,
            padding: `30px 0 30px`,
            minHeight: '102px'
        },
        introBarTitle: {
            color: 'white',
            fontSize: '27px',
            lineHeight: '38px'
        },
        introBarSubtitle: {
            color: 'white',
            fontSize: '20px',
            lineHeight: '29px'
        },
        infoWidgetWrapper: {
            position: 'relative',
            top: '-48px',
            margin: '0 0 -48px'
        },
        infoWidgetRow: {
            margin: '0 -25px',
            display: 'flex',
            alignItems: 'flex-start'
        },

        infoWidgetTitle: {
            padding: '10px 13px 10px 27px',
            fontSize: '19px',
            lineHeight: '27px',
        },
        editButton: {
            border: 'none',
            textTransform: 'none',
            fontWeight: 700,
            fontSize: 20,
            color: 'white',
            transition: 'opacity .5s',
            '&:hover': {
                background: 'none',
                opacity: '.5',
            },
            '& svg': {
                width: 17,
                height: 17,
                color: 'white',
                margin: '0 17px 0 0'
            }
        }
    });
}

const BaseSingleViewHeader = (props) => {
    const { classes, editLink, title, subtitle } = props;
    const history = useHistory();

    return (
        <div className={classes.root}>
            <div className={classes.introBar}>
                <div className={clsx({
                    'singlePage__container': true,
                    [style.introBarContainer]: true,
                })}>
                    <div className={classes.introBarTitle}>
                        {title}
                        <div className={classes.introBarSubtitle}>
                            {subtitle}
                        </div>
                    </div>
                    <div className={classes.introBarEditBtn}>
                        <Button onClick={() => history.push(editLink)} variant="outlined" color="default" className={classes.editButton}>
                            <PencilIcon/>
                            Edit
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

BaseSingleViewHeader.propTypes = {
    data: PropTypes.object.isRequired,
}


BaseSingleViewHeader.defaultProps = {
    editLink: null,
    title: '',
    subtitle: '',
    data: {},
    viewInputsByHash: {},
    viewGroupsById: [],
    viewGroupsByHash: {}
}

export default withStyles(styles)(BaseSingleViewHeader);