import {
    OPEN_ADD_SPECIMEN_LOCATION_MODAL,
    CLOSE_ADD_SPECIMEN_LOCATION_MODAL
} from './../config/actionTypes';

const initState = {
    addSpecimenLocationModalIsOpen: false
}

export default (state = initState, action) => {
    switch (action.type) {
        case OPEN_ADD_SPECIMEN_LOCATION_MODAL:
            return {...state, addSpecimenLocationModalIsOpen: true}

        case CLOSE_ADD_SPECIMEN_LOCATION_MODAL:
            return {...state, addSpecimenLocationModalIsOpen: false}

        default:
            return state
    }
}