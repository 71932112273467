export default function (str) {
  const cleaned = ('' + str).replace(/\D/g, '');

  const match = cleaned.match(/^(\d)(\d)(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `+${match[1]}${match[2]}(${match[3]}) ${match[4]}-${match[5]}`;
  }
  else {
    return str;
  }
};