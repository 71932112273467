import React from "react";
import ReactApexChart from "react-apexcharts";
import { fade } from "@material-ui/core";
import styles from "./AreaChart.module.scss";

const AreaChart = ({ series, height = "400", options, noData }) => {
  const defaultOptions = {
    grid: {
      padding: {
        left: 20,
        right: 20,
      },
    },
    chart: {
      type: "area",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      events: {
        dataPointSelection: function (e, chart, opt) {
          const tooltipEl = opt.w.globals.tooltip.getElTooltip();

          if (opt.selectedDataPoints[0].length) {
            console.log('added');
            tooltipEl.classList.add("apexcharts-tooltip-active");
          } else {
            console.log('removed');
            tooltipEl.classList.remove("apexcharts-tooltip-active");
          }
        },
      },
    },
    colors: ["#4F93F9", "#00C696"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.3,
        opacityTo: 0,
      },
    },
    markers: {
      size: 6,
      hover: {
        size: 8,
      },
    },
    legend: {
      showForSingleSeries: true,
      show: true,
      position: "top",
      horizontalAlign: "left",
      padding: {
        left: 0,
        right: 0,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: fade("#2C3367", 0.4),
        },
      },
    },
    xaxis: {
      type: "datetime",
      tickPlacement: "on",
      labels: {
        style: {
          colors: fade("#2C3367", 0.6),
        },
      },
    },
  };

  if (noData) {
    defaultOptions.noData = {
      text: "No data found",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    };
  }

  const mergedOptions = { ...defaultOptions, ...options };

  return (
    <ReactApexChart
      styles={styles}
      series={series}
      options={mergedOptions}
      type="area"
      height={height}
    />
  );
};

export default AreaChart;