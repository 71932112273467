import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import styles from "./PrintPreviewModalContainer.module.scss";
import dataProvider, { GET_LIST } from "providers/dataProvider";
import ProgressBar from "components/ui_components/ProgressBar/ProgressBar";
import PrintMultiPreviewLayoutComponent from "./PrintMultiPreviewLayoutComponent";
import PrintSinglePreviewLayoutComponent from "./PrintSinglePreviewLayoutComponent";

class PrintPreviewModalContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      physList: [],
      isDataLoading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.modalOpen && !prevProps.modalOpen) {
      this.loadData();
    }
  }

  loadData() {
    this.setState({
      isDataLoading: true,
    });
    dataProvider(GET_LIST, `clients/${this.props.clientId}/physicians`, {
      pagination: {
        perPage: -1,
      },
      sort: {
        field: "id",
        order: "asc",
      },
    }).then(({ data, total }) => {
      if (this._isMounted) {
        this.setState({
          isDataLoading: false,
          physList: data,
        });
      }
    });
  }

  render() {
    const { modalOpen, handleCloseModal, clientData } = this.props;
    const { isDataLoading, physList } = this.state;

    return (
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <div className={`${styles.root} modalContainer`}>
          {isDataLoading && <ProgressBar />}

          {physList.length === 1 && (
            <PrintSinglePreviewLayoutComponent
              physList={physList}
              clientData={clientData}
            />
          )}
          {physList.length > 1 && (
            <PrintMultiPreviewLayoutComponent
              physList={physList}
              clientData={clientData}
            />
          )}
        </div>
      </Modal>
    );
  }
}

export default PrintPreviewModalContainer;
