import { useState } from "react";
import cloneDeep from "lodash/cloneDeep";

const useForm = ({ config, ...rest }) => {
  const inputsByIdInitState = () => {
    return config.map((input) => input.id);
  };
  const inputsByHashInitState = () => {
    const inputsByHash = {};

    config.forEach((filter) => {
      inputsByHash[filter.id] = {
        ...filter,
        value: filter.type === "checkbox" ? [] : "",
        viewValue: "",
      };
    });
    return inputsByHash;
  };

  const [inputsById] = useState(inputsByIdInitState);
  const [inputsByHash, setInputsByHash] = useState(inputsByHashInitState);

  const handleInputs = (e, extraSelectedOptionData, viewValue = null) => {
    setInputsByHash({
      ...inputsByHash,
      [e.target.name]: {
        ...inputsByHash[e.target.name],
        value: e.target.value,
        viewValue: viewValue || e.target.value,
      },
    });
  };

  const clearAllInputs = async () => {
    const initialState = inputsByHashInitState();
    setInputsByHash(initialState);
    return initialState;
  };

  const getInputsByHash = () => {
    return inputsByHash;
  };

  const handleBulkInputs = async (inputs) => {
    const updatedInputsByHash = cloneDeep(inputsByHash);

    inputs.forEach((input) => {
      updatedInputsByHash[input.id] = {
        ...updatedInputsByHash[input.id],
        value: input.value,
        viewValue: input.viewValue || input.value,
      };
    });
    setInputsByHash(updatedInputsByHash);
    return updatedInputsByHash;
  };

  return {
    inputsById,
    inputsByHash,
    handleInputs,
    clearAllInputs,
    getInputsByHash,
    handleBulkInputs,
  };
};

export default useForm;
