import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PulseIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M8,12.3l1.6-3.1c0.1-0.3,0.4-0.4,0.7-0.4c0.3,0,0.5,0.2,0.6,0.5l1.5,5.2l1.3-2c0.1-0.2,0.3-0.3,0.6-0.3h8.7
	c0.6-1.1,0.9-2.2,0.9-3.5c0-4-3.2-7.2-7.2-7.2c-1.8,0-3.5,0.7-4.8,1.8c-1.3-1.2-3-1.8-4.8-1.8C3.2,1.7,0,4.9,0,8.8
	c0,1.2,0.3,2.4,0.9,3.5H8z M14.8,13.7l-2,2.9c-0.1,0.2-0.4,0.3-0.6,0.3c0,0-0.1,0-0.1,0c-0.3,0-0.5-0.2-0.6-0.5l-1.4-5l-1,1.9
	c-0.1,0.2-0.4,0.4-0.6,0.4H1.9c0.1,0.1,0.3,0.3,0.4,0.4l8.4,7.7c0.4,0.3,0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.4-0.6l8.3-7.7
	c0.1-0.1,0.3-0.3,0.4-0.4H14.8z"/>
    </SvgIcon>
);

export default PulseIcon;