import { getArrayOfObjectFromObjectByKeys } from "utils/formatObject";

const getConvertedInputs = (smartValues) => {
  const response = [];
  for (const [key, value] of Object.entries(smartValues)) {
    response.push({
      id: key,
      value: value.value,
      viewValue: value.viewValue || value.value,
    });
  }
  return response;
};

const getDataForQueryUrl = (inputsByHash) => {
  return getArrayOfObjectFromObjectByKeys(inputsByHash, [
    "id",
    "value",
    "viewValue",
  ]).filter((item) => item["value"] !== "");
};

export { getConvertedInputs, getDataForQueryUrl };
