import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types'
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar';
import TableCheckbox from 'components/ui_components/table/TableCheckbox';
import BaseTablePagination from 'components/ui_components/table/BaseTablePagination';
import styles from './TableToolbar.module.scss';
import CollapseControls
  from 'components/ui_components/table/TableToolbar/CollapseControls/CollapseControls';
import IconTooltip from 'components/ui_components/IconTooltip/IconTooltip.js';

function TableToolbar(props) {
  const { numSelected, rows, selectAllClick, showSelect, customToolbarActions } = props;
  const { showDelete, showPagination, showSelectAll, isEmptyTable } = props;
  const { total, perPage, page, handleSetPage, handleSetPerPage } = props;

  if (props.readMode) {
    return (null)
  }

  const renderCustomActions = () => {
    const { customToolbarActions, selectedObjects } = props;

    if (customToolbarActions) {
      return customToolbarActions.map((action, index) => {
        if (action.hasOwnProperty('isHiddenAccessor') && action.isHiddenAccessor(
          rows, selectedObjects
        )) return false;
        return (
          <IconTooltip key={index} title={action.tooltipText}>
            <IconButton key={index}
                        className={styles.bulkActionsBtn}
              onClick={() => {
                action.handleOnClick(selectedObjects);
              }}>
              {action.icon}
            </IconButton>
          </IconTooltip>
        )
      })
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.tableToolBarActions}>
        {showSelect && showSelectAll && (
          <TableCheckbox
            className={styles.toolbarCheckAllRowsBtn}
            indeterminate={numSelected > 0 && numSelected < rows.length}
            checked={rows.length > 0 && numSelected === rows.length}
            onClick={event => selectAllClick(event)}
          />
        )}

        {numSelected > 0 && showDelete && (
          <IconTooltip title='Delete'>
            <IconButton className={styles.bulkActionsBtn}
                        aria-label="Delete"
                        disabled={props.actionDeleteInProgress}
                        onClick={props.handleClickDelete}>
              {props.actionDeleteInProgress ? <ProgressBar size="small"/> : null}
              {props.deleteButtonIcon ? props.deleteButtonIcon : <DeleteIcon/>}
            </IconButton>
          </IconTooltip>
        )}

        {customToolbarActions && numSelected > 0 && renderCustomActions()}

        {props.toolbarExtraComponents}
      </div>


      {!isEmptyTable &&
      <div className={styles.toolbarRightСol}>
        {props.collapsibleTable && <CollapseControls
          handleShowAllCollapse={props.handleShowAllCollapse}
          handleHideAllCollapse={props.handleHideAllCollapse}
        />

        }
        {showPagination &&
        <BaseTablePagination
          total={total}
          perPage={perPage}
          page={page}
          handleSetPage={handleSetPage}
          handleSetPerPage={handleSetPerPage}
        />}
      </div>
      }
    </div>
  );
}

TableToolbar.defaultProps = {
  actionDeleteInProgress: true
}

TableToolbar.propTypes = {
  rows: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired
}

export default TableToolbar