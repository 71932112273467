import React, { Component } from "react";
import { SmartTable } from "components/ui_components/table";
import withModal from "withModal";
import { TableHeaderAddButton } from "components/ui_components/buttons";
import AddPhysicianModal from "components/pages/clients/ClientsShow/AddPhysicianModal/AddPhysicianModal.js";
import PrintPreviewModalContainer from "components/pages/clients/ClientsShow/PrintPreview/PrintPreviewModalContainer.js";
import { compose } from "redux";

const tableColConfig = [
  {
    id: "physId",
    label: "Physician ID",
  },
  {
    id: "patientName",
    label: "Physician Name",
    accessor: (d) => d.physFirstName + " " + d.physLastName,
    linkAccessor: (row) => `/physicians/${row.physId}/show`,
  },
];

class PhysicianTab extends Component {
  constructor(props) {
    super(props);

    this.isEditAvailable = props.isEditAvailable;
  }

  handleAddNewAssociatedPhysicians = () => {
    this.smartTable.loadData();
  };

  render() {
    return (
      <React.Fragment>
        {this.isEditAvailable && (
          <React.Fragment>
            <AddPhysicianModal
              clientId={this.props.clientId}
              modalOpen={
                this.props.modalOpen && this.props.modalID === "physModal"
              }
              handleCloseModal={this.props.handleCloseModal}
              handleAddNewAssociatedPhysicians={
                this.handleAddNewAssociatedPhysicians
              }
            />
            <PrintPreviewModalContainer
              clientData={this.props.clientData}
              clientId={this.props.clientId}
              modalOpen={
                this.props.modalOpen && this.props.modalID === "printPreview"
              }
              handleCloseModal={this.props.handleCloseModal}
            />
          </React.Fragment>
        )}

        <SmartTable
          onRef={(ref) => (this.smartTable = ref)}
          resource={"clients/" + this.props.clientId + "/physicians"}
          perPage={-1}
          showSelect={this.isEditAvailable}
          showSelectAll={this.isEditAvailable}
          showPagination={false}
          deleteByKey={"physId"}
          deleteConfirmationMessagePostfix={"associated physician"}
          columns={tableColConfig}
          toolbarExtraComponents={
            this.isEditAvailable && (
              <div className="tableHeaderAddButtonGroup">
                <TableHeaderAddButton
                  onClick={this.props.handleOpenModal.bind(this, "physModal")}
                >
                  Physician
                </TableHeaderAddButton>
                <TableHeaderAddButton
                  onClick={this.props.handleOpenModal.bind(
                    this,
                    "printPreview"
                  )}
                >
                  Print Blank Requisition Form
                </TableHeaderAddButton>
              </div>
            )
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(withModal)(PhysicianTab);
