import React from 'react';
import withForm from 'withForm';
import { resetPasswordConfig } from "config";
import { compose } from 'redux'
import { BaseForm } from 'components/ui_components/Forms';
import { ButtonGroup } from 'components/ui_components/buttons';
import ButtonBase from '@material-ui/core/ButtonBase';

const NewPasswordForm = (props) => {
  const { isFormValid, resetErrors, getSubmitData, inputsById, inputsByHash, handleInputs, onSubmitOk } = props;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid()) {
      resetErrors();

      onSubmitOk(getSubmitData());
    }
  }

  return (
    <React.Fragment>
      <h2 className="ttl ttl-6 text-center">
        New password required.
      </h2>
      <BaseForm
        inputsById={inputsById}
        inputsByHash={inputsByHash}
        handleInputs={handleInputs}
        inputWrapStyles={`width: 100%;`}
      />

      <ButtonGroup rootStyles={'padding: 20px 0 0; text-align: center;'}>
        <ButtonBase
          onClick={handleSubmit}
          className="button formButton formButton__mainContained"
        >
          Login
        </ButtonBase>
      </ButtonGroup>
    </React.Fragment>
  );
}

export default compose(
  withForm(resetPasswordConfig)
)(NewPasswordForm)

NewPasswordForm.defaultProps = {
  type: 'changeTemporaryPassword',
}