import React, { useState } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import dataProvider, { CREATE } from "providers/dataProvider";
import withForm from "withForm";
import { compose } from "redux";
import { BaseForm } from "components/ui_components/Forms";
import { useNotificationCenter } from "NotificationCenter";
import BaseModal from "components/ui_components/BaseModal/BaseModal.js";
import { SmartTable } from "components/ui_components/table";
import { billingTabConfig } from "config/resources/requisitionsResource";
import Alert from '@material-ui/lab/Alert';
import { Box } from '@material-ui/core';


const { prevInsurancesConfig } = billingTabConfig;

const PrevInsuranceModal = (props) => {
  const {
    handleCloseModal,
    clearInputs,
    modalIsOpen,
    reqID,
    getSubmitData,
    patientId,
    onSubmitOk,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableError, setTableError] = useState(false);

  const notificationCenter = useNotificationCenter();

  const isTableValid = () => {
    if (!selectedRow) {
      setTableError(true);
      return false;
    } else {
      setTableError(false);
      return true;
    }
  }

  const handleSubmit = () => {
    props.resetErrors();

    const formValid = props.isFormValid();
    const tableValid = isTableValid();

    if (!formValid || !tableValid) return;

    setIsSubmitting(true);

    const submitData = {
      ...getSubmitData(),
      patientId: patientId,
      useBillId: selectedRow.billId,
      useInsuranceId: selectedRow.insuranceId,
    };

    dataProvider(CREATE, `requisitions/${reqID}/prev_insurance`, {
      data: submitData,
    })
      .then((response) => {
        notificationCenter.show(response.message, "info");
        handleCloseModal();
        clearInputs();
        onSubmitOk();
        setIsSubmitting(false);
      })
      .catch((error) => {
        notificationCenter.show(error.message, "warning");
        setIsSubmitting(false);
      });
  };

  const handleSelectRow = (rows) => {
    if (rows[0]) {
      setSelectedRow(rows[0]);
    } else {
      setSelectedRow(null);
    }
  };

  return (
    <BaseModal
      isOpen={modalIsOpen}
      onClose={handleCloseModal}
      title="Previous Insurances"
      showProgressBar={isSubmitting}
      controls={
        <React.Fragment>
          <ButtonBase
            className="button formButton formButton__secondaryOutlined"
            onClick={handleCloseModal}
          >
            Cancel
          </ButtonBase>
          <ButtonBase
            className="button formButton formButton__mainContained"
            onClick={handleSubmit}
          >
            Submit
          </ButtonBase>
        </React.Fragment>
      }
      rootStyles={`max-width: 900px;`}
    >
      <BaseForm
        inputsById={props.inputsById}
        inputsByHash={props.inputsByHash}
        handleInputs={props.handleInputs}
        listStyles={`
            display: flex;
            flex-wrap: wrap;
          `}
      />

      <SmartTable
        resource={`patients/${patientId}/insurances`}
        orderBy={'reqId'}
        showPagination={false}
        columns={prevInsurancesConfig.tableConfig}
        showSelect={true}
        showDelete={false}
        showSelectAll={false}
        singleRowSelect={true}
        handleSelectRowCallBack={handleSelectRow}
      />
      {tableError && <Box mt={'10px'}><Alert severity={'error'}>Please select insurance</Alert></Box>}
    </BaseModal>
  );
};

export default compose(withForm())(PrevInsuranceModal);
