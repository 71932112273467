import React, {   useState } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import dataProvider, { UPDATE_ITEM } from "providers/dataProvider";
import withForm from 'withForm'
import { compose } from 'redux'
import { BaseForm } from 'components/ui_components/Forms';
import { useNotificationCenter } from 'NotificationCenter';
import BaseModal from 'components/ui_components/BaseModal/BaseModal.js';

const editConfigExtraParams = {
  policyNumber: {
    accessor: d => d.policyNo,
  },
  billingOrder: {
    accessor: d => d.billingorder
  },
  insuranceDobDate: {
    accessor: d => d.insuredDob && d.insuredDob.split("/")[0]
  },
  insuranceDobMonth: {
    accessor: d => d.insuredDob && d.insuredDob.split("/")[1]
  },
  insuranceDobYear: {
    accessor: d => d.insuredDob && d.insuredDob.split("/")[2]
  }
}

const EditInsuranceModal = (props) => {
  const {
    handleCloseModal,
    clearInputs,
    modalIsOpen,
    handleEditInsurance,
    reqID,
    handleBulkInputs,
    forwardRef,
    billingID,
    getSubmitData,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [insuranceData, setInsuranceData] = useState(false);

  const notificationCenter = useNotificationCenter();

  const handleSubmit = () => {
    props.resetErrors();
    if (!props.isFormValid()) return;

    setIsSubmitting(true);

    const allSubmitData = getSubmitData(false);

    const submitData = getSubmitData(true, true, true);

    if (
      allSubmitData.insuranceDobMonth &&
      allSubmitData.insuranceDobDate &&
      allSubmitData.insuranceDobYear
    ) {
      submitData.insuredDob = `${allSubmitData.insuranceDobMonth}/${allSubmitData.insuranceDobDate}/${allSubmitData.insuranceDobYear}`
    }

    dataProvider(UPDATE_ITEM, `requisitions/${reqID}/billings/${billingID }/insurances/${insuranceData.id}`, { data: submitData })
    .then((response) => {
      notificationCenter.show('Successfully create', 'info')
      handleCloseModal();
      clearInputs();
      handleEditInsurance();
      setIsSubmitting(false);
    })
    .catch(error => {
      notificationCenter.show(error.message, 'warning')
      setIsSubmitting(false);
    });
  }

  forwardRef((data) => fillForm(data));

  const fillForm = (data) => {
    setInsuranceData(data);
    let updateInputs = [];

    props.inputsById.forEach(id => {
      let value = data[id];
      if (editConfigExtraParams.hasOwnProperty(id)) {
        value = editConfigExtraParams[id].accessor(data);
      }
      updateInputs.push({ id: id, value: value })
    })
    handleBulkInputs(updateInputs);
  }

  return (
    <BaseModal
      isOpen={modalIsOpen}
      onClose={handleCloseModal}
      title='Edit insurance information'
      showProgressBar={isSubmitting}
      controls={<React.Fragment>
        <ButtonBase
          className="button formButton formButton__secondaryOutlined"
          onClick={handleCloseModal}>
          Cancel
        </ButtonBase>
        <ButtonBase
          className="button formButton formButton__mainContained"
          onClick={handleSubmit}
        >
          Save
        </ButtonBase>
      </React.Fragment>}
      rootStyles={`max-width: 460px;`}
    >
      <BaseForm
        inputsById={props.inputsById}
        inputsByHash={props.inputsByHash}
        handleInputs={props.handleInputs}
        listStyles={
          `
            display: flex;
            flex-wrap: wrap;
          `
        }
      />
    </BaseModal>
  );
}

export default compose(
  withForm()
)(EditInsuranceModal)