import { useState } from 'react';

export default function() {
  const [dropZoneError, setError] = useState(false);
  const [dropZoneFiles, setFiles] = useState([]);
  const [isDropZoneUploading, setDropZoneUploading] = useState(false);

  const resetErrors = () => {
    setError(false);
  }

  const handleDropFiles = (files) => {
    resetErrors();
    setFiles(files);
  }

  const handleDropZoneErrors = () => {
    setError(true);
  }

  const isValidDropZone = ({ handleErrors = false }) => {
    if (dropZoneFiles.length === 0) {
      if (handleErrors) {
        handleDropZoneErrors();
      }
      return false;
    }
    else {
      return true;
    }
  }

  return [
    dropZoneError,
    dropZoneFiles,
    handleDropFiles,
    isValidDropZone,
    isDropZoneUploading,
    setDropZoneUploading
  ]
}