import React, { PureComponent } from 'react'
import IssuesListContainer from './IssuesListContainer';
import styles from './IssuesContainer.module.scss';
import dataProvider, { GET_LIST } from "providers/dataProvider";
import AddIssueContainer from './AddIssueContainer'
import Can from 'Can';
import { userPermissions } from "config/userRoles";
import authStorage from 'providers/authStorage.js';
import { withRouter } from 'react-router-dom'
import { getSalesRepColumnVal } from "config/resources/requisitionsResource/tableCol";

class IssuesContainer extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      issuesList: [],
      issueStatusList: [],
      issuesDataLoading: false,
      userDepartmentCodes: authStorage.getUserDepts(),
    }
  }

  componentDidMount() {
    this.loadIssues();
    this.loadIssueStatus();
  }

  loadIssueStatus = () => {
    dataProvider(GET_LIST, `lovs/ISSUESTATUS`, {
      pagination: {
        perPage: -1
      }
    })
    .then(({ data, total }) => {
      this.setState({
        issueStatusList: data
      })
    })
  }

  loadIssues = () => {
    const { reqID } = this.props;
    this.setState({
      issuesDataLoading: true
    })

    dataProvider(GET_LIST, `requisitions/${reqID}/issues`, {
      sort: {
        field: 'issueStatusDate',
        order: 'DESC'
      }
    })
    .then(({ data, total }) => {
      this.setState({
        issuesList: data,
        issuesDataLoading: false
      })
    })
  }

  handleSuccessfullySubmit = () => {
    this.loadIssues();
  }

  render() {
    const { issuesList, issueStatusList, issuesDataLoading, userDepartmentCodes } = this.state;
    const { reqID, reqData } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.container}>
          {reqData.salesJson && <div
            className={styles.salesRepCode}
          >
            Sales name : {getSalesRepColumnVal(reqData.salesJson)}
          </div>}

          <Can allowedRoles={userPermissions.reqEntity.show.issues.addIssue}>
            <AddIssueContainer
              reqID={reqID}
              handleSuccessfullySubmit={this.handleSuccessfullySubmit}
            />
          </Can>
          <IssuesListContainer
            issuesList={issuesList}
            issueStatusList={issueStatusList}
            reqID={reqID}
            issuesDataLoading={issuesDataLoading}
            userDepartmentCodes={userDepartmentCodes}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(IssuesContainer)