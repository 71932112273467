import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MedicUserIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M16.7,4.7V1c0-0.1-0.1-0.1-0.1-0.2c-2.9-1.1-6.2-1.1-9.1,0C7.4,0.9,7.3,0.9,7.3,1v3.7
	c0,0,0,0,0,0.1v2.4c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7V4.7C16.7,4.7,16.7,4.7,16.7,4.7z M13.1,3h-0.5v0.5c0,0.3-0.3,0.6-0.6,0.6
	c-0.3,0-0.6-0.3-0.6-0.6V3h-0.6c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h0.6V1.3c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6
	v0.6h0.5c0.3,0,0.6,0.3,0.6,0.6C13.7,2.8,13.4,3,13.1,3z M22.3,17.9c-1.5-2.6-3.9-4.4-6.7-5.3c-0.1,0-0.1,0-0.2,0
	c-1.3,1.3-3,2.3-3.4,2.6c-0.4-0.3-2.3-1.5-3.4-2.6c0,0-0.1-0.1-0.2,0c-2.9,0.9-5.2,2.8-6.7,5.3c0,0.1,0,0.1,0,0.2
	C3.8,21.7,7.8,24,12,24c4.2,0,8.2-2.3,10.3-5.9C22.4,18.1,22.4,18,22.3,17.9z M14.1,20.3v-2.4h1.5v-0.8c0-0.3,0.3-0.6,0.6-0.6
	s0.6,0.3,0.6,0.6v0.8h1.5v2.4H14.1z"/>
    </SvgIcon>
);

export default MedicUserIcon;