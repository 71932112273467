import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Delivery2Icon = (props) => (
    <SvgIcon {...props}>
        <path d="M23.1,14.7v-2.6c0-1-0.8-1.8-1.8-1.8l-1.2-0.2L18,7c0,0-0.1-0.1-0.1-0.1c-0.3-0.4-0.8-0.7-1.4-0.7h-4V5.1
	c0-0.6-0.4-1-0.9-1H0.9C0.4,4.1,0,4.5,0,5.1V12v3.7v0.2v1c0,0.6,0.5,1,1,1h1.4c0.3,1.1,1.4,2,2.6,2c1.2,0,2.3-0.8,2.6-2h3.3h5.6
	c0.3,1.1,1.4,2,2.6,2c1.2,0,2.3-0.8,2.6-2H23c0.6,0,1-0.5,1-1v-1.3C24,15.2,23.6,14.7,23.1,14.7z M5.1,18.6c-0.8,0-1.4-0.6-1.4-1.4
	s0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4S5.8,18.6,5.1,18.6z M14.4,10.3c-0.1,0-0.1-0.1-0.1-0.1V7.6c0-0.1,0.1-0.1,0.1-0.1h2.2
	c0,0,0.1,0,0.1,0.1l1.7,2.5c0.1,0.1,0,0.2-0.1,0.2L14.4,10.3L14.4,10.3z M19.1,18.6c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4
	c0.8,0,1.4,0.6,1.4,1.4S19.9,18.6,19.1,18.6z"/>
    </SvgIcon>
);

export default Delivery2Icon;