import React from 'react';
import styles from './ResetPassword.module.scss'
import { resetPasswordConfigWithUserName } from 'config'
import ButtonBase from '@material-ui/core/ButtonBase';
import withForm from 'withForm';
import SmartInput from 'components/ui_components/smart_input'
import { useNotificationCenter } from 'NotificationCenter'
import { useAuthCenter } from 'AuthCenter';

const ResetPasswordContainer = (props) => {
  const { getSubmitData, handleInputs, inputsByHash, inputsById, isFormValid, resetErrors } = props;
  const notificationCenter = useNotificationCenter();
  const verificationCode = props.match.params.token;
  const authCenter = useAuthCenter();


  const handleSubmit = () => {
    if (isFormValid()) {
      resetErrors();

      let submitData = {
        ...getSubmitData()
      }

      authCenter.confirmPasswordMfa(submitData.userName, submitData.newPwd, verificationCode)
      .then(() => {
        notificationCenter.show('Successfully saved');
        props.history.push('/login');
      })
      .catch(e => {
        notificationCenter.show(e.message, 'error')
      })
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.formWrapper}>
          {inputsById.map((inputId, index) => {
            const input = inputsByHash[inputId];

            return (
              <SmartInput
                key={index}
                inputId={inputId}
                input={input}
                handleInputs={handleInputs}
              />
            )
          })}
          <div className={styles.buttonGroup}>
            <ButtonBase
              onClick={handleSubmit}
              className="button formButton formButton__mainContained"
            >
              Submit
            </ButtonBase>
          </div>
        </div>
      </div>
    </div>
  );

}

export default withForm({ ...resetPasswordConfigWithUserName })(ResetPasswordContainer);