import React from 'react';
import { PlusIcon } from 'icons';
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar';
import styles from './TableHeaderAddButton.module.scss';
import clsx from 'clsx';

const AddPostButton = ({
                         showIcon,
                         showProgressBar,
                         icon,
                         children,
                         ...rest
                       }) => {
  return (
    <a className={clsx({
      [styles.root]: true,
      [styles.showProgressBar]: showProgressBar,
    })}
       {...rest}>
      {showIcon && icon}
      {children}
      {showProgressBar && <ProgressBar size="small"/>}
    </a>
  );
}

export default AddPostButton;

AddPostButton.defaultProps = {
  icon: <PlusIcon/>,
  showProgressBar: false,
  showIcon: true,
}