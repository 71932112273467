import React from "react";
import useForm from "hooks/useForm";

export default function (Component) {
  return function (props) {
    // console.log('props');
    // console.log(props.smartFilterConfig);
    return (
      <Component
        {...props}
        {...useForm({ config: props.smartFilterConfig })}
      />
    );
  };
}
