let yup = require("yup");

export const physiciansResource = {
  tableColConfig: [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "physFirstName",
      label: "First Name",
    },
    {
      id: "physLastName",
      label: "Last Name",
    },
    {
      id: "physTitle",
      label: "Title",
    },
    {
      id: "npiNumber",
      label: "NPI Number",
    },
    {
      id: "ptanNumber",
      label: "PTAN#",
    },
  ],
  createPhysConfig: {
    inputsById: [
      "physFirstName",
      "physMiddleName",
      "physLastName",
      "physTitle",
      "npiNumber",
      "ptanNumber",
      "physPortalPrefCode",
    ],
    inputsByHash: {
      physFirstName: {
        label: "First Name",
        yup: yup
          .string()
          .required("First Name is required")
          .nullable("First Name is required"),
        autoFocus: true,
      },
      physMiddleName: {
        label: "Middle Name",
      },
      physLastName: {
        label: "Last Name",
        yup: yup
          .string()
          .required("Last Name is required")
          .nullable("Last Name is required"),
      },
      physTitle: {
        label: "Title",
        type: "select",
        options: [
          { value: null, label: "None" },
          { value: "MD", label: "MD" },
          { value: "DO", label: "DO" },
          { value: "NP", label: "NP" },
          { value: "PA", label: "PA" },
          { value: "ND", label: "ND" },
          { value: "OD", label: "OD" },
          { value: "PhD", label: "PhD" },
          { value: "FNP", label: "FNP" },
          { value: "DNP", label: "DNP" },
          { value: "DC", label: "DC" },
          { value: "PA-C", label: "PA-C" },
          { value: "BC", label: "BC" },
          { value: "NMD", label: "NMD" },
          { value: "APRN", label: "APRN" },
          { value: "FNP-BC", label: "FNP-BC" },
          { value: "MPAS", label: "MPAS" },
          { value: "RPH", label: "RPH" },
          { value: "FNP-C", label: "FNP-C" },
          { value: "MD.,FACP", label: "MD.,FACP" },
          { value: "CRNP", label: "CRNP" },
          { value: "ARNP", label: "ARNP" },
          { value: "NP-C", label: "NP-C" },
          { value: "CNP", label: "CNP" },
        ],
        yup: yup
          .string()
          .required("Title is required")
          .nullable("Title is required"),
      },
      npiNumber: {
        label: "NPI#",
        yup: yup
          .string()
          .required("NPI# is required")
          .nullable("NPI# is required"),
      },
      ptanNumber: {
        label: "PTAN#",
      },
      physPortalPrefCode: {
        label: "Patient Portal Preferences",
        type: "autocomplete",
        resource: "lovs/PORTALPREF",
        accessor: (d) => ({
          value: d.portalPrefCode,
          label: d.portalPrefDesc,
        }),
      },
    },
    inputsGroupsByHash: {
      group1: {
        title: "Physician Information",
        ids: [
          ["physFirstName", "physMiddleName", "physLastName"],
          ["physTitle"],
          ["npiNumber"],
          ["ptanNumber"],
          ["physPortalPrefCode"],
        ],
      },
    },

    inputsGroupsById: ["group1"],
  },
};
