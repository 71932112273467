import React, { useState } from "react";
import styles from "./AddHocReports.module.scss";
import clsx from "clsx";
import { addHocReportsConfig } from "config/resources/reportsResource";
import dataProvider, { DOWNLOAD_FILE } from "providers/dataProvider";
import withForm from "withForm";
import SmartInput from "components/ui_components/smart_input";
import { compose } from "redux";
import { withNotificationCenter } from "NotificationCenter";
import {
  WidgetControlButton,
  ButtonGroup,
} from "components/ui_components/buttons";

const AddHocReportsContainer = (props) => {
  const { inputsByHash, inputsById, handleInputs } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleClickSubmit = () => {
    const {
      notificationCenter,
      isFormValid,
      resetErrors,
      getSubmitData,
    } = props;

    if (isFormValid()) {
      resetErrors();
      setIsLoading(true);

      
      dataProvider(DOWNLOAD_FILE, "adhoc_reqs/download", {
        data: getSubmitData(),
      }, {apiEndointType: 'report'})
        .then(({ data }) => {
          window.open(data.url);
          notificationCenter.showDownloadNotification(data.url);
          setIsLoading(false);
        })
        .catch((error) => {
          notificationCenter.show(error.message, "warning");
          setIsLoading(false);
        });
    }
  };

  return (
    <div className={styles.root}>
      {inputsById.map((inputId, index) => {
        const input = inputsByHash[inputId];
        let params = { inputId, input, styles, handleInputs };
        return (
          <div
            key={index}
            className={clsx(
              styles.inputWrap,
              styles["inputWrap-" + input.type]
            )}
          >
            <SmartInput {...params} />
          </div>
        );
      })}

      <ButtonGroup rootStyles={"padding: 20px 0 0;"}>
        <WidgetControlButton onClick={handleClickSubmit} loading={isLoading}>
          Download
        </WidgetControlButton>
      </ButtonGroup>
    </div>
  );
};

AddHocReportsContainer.propTypes = {};

export default compose(
  withNotificationCenter(),
  withForm({
    inputsByHash: addHocReportsConfig.inputsByHash,
    inputsById: addHocReportsConfig.inputsById,
  })
)(AddHocReportsContainer);
