import React, { useState } from 'react'
import styles from './ReportsTableRow.module.scss';
import PropTypes from "prop-types";
import withForm from 'withForm';
import clsx from "clsx";
import dataProvider, { DOWNLOAD_FILE } from "providers/dataProvider";
import SmartInput from 'components/ui_components/smart_input'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { DownloadIcon } from 'icons';
import IconButton from '@material-ui/core/IconButton';
import { useNotificationCenter } from 'NotificationCenter'
import { compose } from 'redux'
import CircularProgress from '@material-ui/core/CircularProgress';

const ReportsTableRow = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const { inputsById, inputsByHash } = props;
  const { inputsGroupsById, inputsGroupsByHash, handleInputs } = props;
  const notificationCenter = useNotificationCenter();

  const handleClickSubmit = () => {
    const {isFormValid, resetErrors, getSubmitData, reportCode } = props;

    let data = {
      ...getSubmitData(),
      reportCode: reportCode
    }

    if (isFormValid()) {
      resetErrors();

      setDataLoading(true);

      dataProvider(DOWNLOAD_FILE, props.downloadResource, { data: data }, {apiEndointType: 'report'})
      .then(({ data, message }) => {
        if (data.hasOwnProperty('url')) {
          window.open(data.url);
          notificationCenter.show(
            'If download failed, please click here',
            'info',
            'Download',
            () => {},
            data.url
          )
        }
        else {
          notificationCenter.show(message, 'warning')
        }
        setDataLoading(false);
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
        setDataLoading(false);
      });
    }
  }

  return (
    <TableRow className={styles.root}>
      <TableCell scope="row" className={styles.reportDesc}>
        {props.reportDesc}
      </TableCell>
      {inputsById.length > 0 ? inputsGroupsById.map(groupId => {
        return (
          <TableCell scope="row" key={groupId}>
            <div key={groupId} className={clsx(styles['inputs-' + groupId], styles.formGroup)}>
              <div className={styles.groupTitle}>
                {inputsGroupsByHash[groupId].title}
              </div>
              <div className={styles.groupInputsList}>
                {inputsGroupsByHash[groupId].ids.map((inputId, index) => {
                  const input = inputsByHash[inputId];
                  let params = { inputId, input, styles, handleInputs }
                  return (
                    <SmartInput key={index} {...params} />
                  )
                })}
              </div>
            </div>
          </TableCell>
        )
      }) : null}

      <TableCell scope="row">
        <IconButton onClick={handleClickSubmit} className={styles.actionIcon}>
          {dataLoading ? <CircularProgress size={20}/> : <DownloadIcon/>}
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

ReportsTableRow.propTypes = {
  reportCode: PropTypes.string.isRequired,
  reportDesc: PropTypes.string.isRequired,
  withFormConfig: PropTypes.object.isRequired,
  inputsGroupsById: PropTypes.array.isRequired,
  inputsGroupsByHash: PropTypes.object.isRequired
}


export default compose(
  withForm()
)(ReportsTableRow)