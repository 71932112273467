import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DrugsIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M22.1,1.9c-2.5-2.5-6.5-2.5-9,0L8,7.1l5.9,5.9c1.2-1,2.8-1.7,4.5-1.7c1,0,1.9,0.2,2.8,0.6l1-1.1
	C24.6,8.3,24.6,4.3,22.1,1.9z M12.9,14L7,8.1l-5.1,5.1c-2.5,2.5-2.5,6.5,0,9c2.5,2.5,6.5,2.5,9,0l1-1c-0.4-0.8-0.6-1.8-0.6-2.8
	C11.3,16.7,11.9,15.2,12.9,14z M12.7,18.4c0,2.9,2.2,5.2,4.9,5.6V12.8C14.9,13.2,12.7,15.5,12.7,18.4z M19.1,12.8v11.1
	c2.8-0.3,4.9-2.7,4.9-5.6C24,15.5,21.8,13.2,19.1,12.8z"/>
    </SvgIcon>
);

export default DrugsIcon;