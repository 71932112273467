import React from "react";
import { WidgetType1 } from "components/ui_components/Widgets";
import { WidgetControlButton } from "components/ui_components/buttons";
import BaseInputList from "components/ui_components/BaseInputList/BaseInputList";

const AddWidgetContainer = (props) => {
  const {
    handleInputs,
    inputsById,
    inputsByHash,
    widgetTitle,
    dataLoading,
    handleSubmit,
    reqID,
    inputWrapStyles,
    rightContent,
  } = props;

  return (
    <WidgetType1
      title={widgetTitle}
      content={
        <BaseInputList
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          smartInputOptions={{ reqID: reqID }}
          inputWrapStyles={inputWrapStyles}
        />
      }
      headerRightContent={
        <React.Fragment>
          {rightContent}
          <WidgetControlButton onClick={handleSubmit}>Add</WidgetControlButton>
        </React.Fragment>
      }
      showProgressBar={dataLoading}
      wrapStyles={props.widgetWrapStyles}
    />
  );
};

export default AddWidgetContainer;

AddWidgetContainer.defaultProps = {
  widgetWrapStyles: "",
  rightContent: null,
};
