import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';

const FormButton = withStyles(theme => ({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: 700,
        padding: '2px 12px',
        border: '3px solid',
        borderColor: theme.palette.primary.main,
        lineHeight: 1.5,
        '&:hover': {
            border: '3px solid',
            borderColor: theme.palette.primary.main
        },
    },
}))(Button);


export default FormButton;