import React from 'react';
import { connect } from 'react-redux';
import styles from './AuthModal.module.scss'
import { compose } from 'redux'
import { withNotificationCenter } from 'NotificationCenter'
import { bindActionCreators } from 'redux'
import AuthForm from 'components/AuthForm/AuthForm';
import Modal from '@material-ui/core/Modal';
import { openAuthModal, closeAuthModal } from "actions/index"
import { withAuthCenter } from 'AuthCenter';

const AuthModalContainer = (props) => {
  const { authModalIsOpen, closeAuthModal } = props;

  const onLoginOk = () => {
    closeAuthModal();
  }

  return (
    <Modal
      open={authModalIsOpen}
      onClose={closeAuthModal}
      BackdropProps={{
        className: styles.backDrop
      }}
    >
      <div className={`${styles.root} modalContainer`}>
        <h2 className="ttl ttl-2">
          Session expired, please re-login.
        </h2>
        <div>
          <AuthForm
            onLoginOk={onLoginOk}
          />
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ auth }) => ({
  authModalIsOpen: auth.authModalIsOpen,
})


const mapDispatchToProps = dispatch =>
  bindActionCreators({
    closeAuthModal,
    openAuthModal
  }, dispatch)


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNotificationCenter(),
  withAuthCenter(),
)(AuthModalContainer)