import dataProvider, {
  CREATE,
  UPLOAD_FILE,
  UPLOAD_FILE_S3,
} from "providers/dataProvider";

const docTypeCodeUrlPostfix = {
  SCANREQ: "scanreqs",
  INSCARD: "inscards",
  ADDON: "addons",
  SCANEOB: "scaneobs",
  SCANINV: "scaninvoices",
  CHECK: "checks",
};

export default function (reqID, files, docTypeCode) {
  return new Promise(function (resolve, reject) {
    let downloadFile = {};
    let fileName = "";

    files.forEach((file, index) => {
      if (index > 0) return;
      fileName = file.name;
      downloadFile = file;
    });

    const gdbRequest = { fileName: fileName, docTypeCode: docTypeCode };

    dataProvider(UPLOAD_FILE, `requisitions/${reqID}/upload`, {
      data: gdbRequest,
    })
      .then((gdbResponse) => {
        const s3Request = { ...gdbResponse.data.fields };
        const newFileName = gdbResponse.data.newFileName;

        dataProvider(UPLOAD_FILE_S3, gdbResponse.data.url, {
          data: {
            ...s3Request,
            downloadFile,
          },
        })
          .then((s3Response) => {
            if (s3Response.status === 204) {
              dataProvider(
                CREATE,
                `requisitions/${reqID}/${docTypeCodeUrlPostfix[docTypeCode]}`,
                {
                  data: {
                    originalFileName: fileName,
                    fileName: newFileName,
                  },
                }
              )
                .then((createRepResponse) => {
                  resolve();
                })
                .catch((error) => reject(error));
            }
          })
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
}
