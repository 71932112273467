import { can } from "utils/helpers";
import { userPermissions, userRoles } from "config/userRoles";
import { concatWithSeparator } from 'utils/textFormat';

export default () => {
  let collaboratorResource = "collaborators";

  if (can([userRoles.sales, userRoles.physician])) {
    collaboratorResource = "filter_dd/collaborators";
  }

  return [
    {
      id: "reqId",
      label: "Accession #",
    },
    {
      id: "reqStatusCode",
      label: "Status",
      type: "autocomplete",
      hideForRoles: [userRoles.client, userRoles.physician],
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      resource: "req_status_types",
      accessor: (d) => ({ value: d.reqStatusCode, label: d.reqStatusDesc }),
    },
    {
      id: "issueCount",
      label: "Has issues",
      hideForRoles: [userRoles.client, userRoles.physician],
      type: "checkbox",
      subType: "singleCheckbox",
      submitAccessor: (d) => (d[0] === "1" ? "1" : ""),
      options: [
        {
          value: 1,
        },
      ],
    },
    {
      id: "isNonResultedOnly",
      label: "Show non-resulted test only",
      hideForRoles: [userRoles.client, userRoles.physician, userRoles.sales],
      type: "checkbox",
      subType: "singleCheckbox",
      submitAccessor: (d) => (d[0] === "1" ? "1" : "0"),
      options: [
        {
          value: 1,
        },
      ],
    },
    {
      id: "colaId",
      label: "Collaborator",
      hideForRoles: [userRoles.client, userRoles.physician, userRoles.sales],
      type: "autocomplete",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      resource: collaboratorResource,
      accessor: (d) => ({ value: d.id, label: d.colaName }),
      can: can(userPermissions.reqEntity.smartFilter.collaboratorField),
    },
    {
      id: "reqPassCodes",
      label: "Pass Code",
      hideForRoles: [userRoles.client, userRoles.physician, userRoles.sales],
      type: "autocomplete",
      resource: "lovs/PASSCODE",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      accessor: (d) => ({ value: d.passCode, label: d.passDesc }),
    },
    {
      id: "reqFailCodes",
      label: "Fail Code",
      hideForRoles: [userRoles.client, userRoles.physician, userRoles.sales],
      type: "autocomplete",
      resource: "lovs/FAILCODE",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      accessor: (d) => ({ value: d.failCode, label: d.failDesc }),
    },
    {
      id: "labNoteCodes",
      label: "Lab Note",
      hideForRoles: [userRoles.client, userRoles.physician, userRoles.sales],
      type: "autocomplete",
      resource: "lovs/LABNOTE",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      accessor: (d) => ({ value: d.labNoteTypeCode, label: d.labNoteTypeDesc }),
    },
    {
      id: "patientFirstName",
      label: "Patient First Name",
    },
    {
      id: "patientLastName",
      label: "Patient Last Name",
    },
    {
      id: "clientId",
      type: "autocomplete",
      resource: "filter_dd/clients",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      accessor: (d) => ({ value: d.id, label: d.clientName }),
      label: "Client",
    },
    {
      id: "clientOrderId",
      label: "Client Order ID",
      hideForRoles: [userRoles.client, userRoles.physician, userRoles.sales],
    },
    {
      id: "extAccessionId",
      label: "External Acc#",
      hideForRoles: [userRoles.client, userRoles.physician, userRoles.sales],
    },
    {
      id: "physId",
      type: "autocomplete",
      resource: "filter_dd/physicians",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      accessor: (d) => ({
        value: d.id,
        label: `${d.physLastName}, ${d.physFirstName}`,
      }),
      label: "Physicians",
    },
    {
      id: "testCodes",
      type: "autocomplete",
      hideForRoles: [userRoles.client, userRoles.physician],
      label: "Test Code",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      resource: "lovs/TESTCODE",
      accessor: (d) => ({ value: d.testCode, label: d.testDesc }),
    },
    {
      id: "reqBillTypeDesc",
      type: "autocomplete",
      visibleForRoles: [userRoles.sales],
      label: "Bill To",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      resource: "lovs/REQBILLTYPE",
      accessor: (d) => ({
        value: d.reqBillTypeCode,
        label: d.reqBillTypeDesc,
      }),
    },
    {
      id: "testComboCode",
      type: "autocomplete",
      hideForRoles: [userRoles.client, userRoles.physician, userRoles.sales],
      label: "Test Combo Code",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      resource: "lovs/TESTCOMBO",
      accessor: (d) => ({ value: d.testComboCode, label: d.testComboDesc }),
    },
    {
      id: "dateCollected",
      label: "Date Collected From - To",
      type: "dateRange",
    },
    {
      id: "dateReceived",
      label: "Date Received From - To",
      hideForRoles: [userRoles.client, userRoles.physician],
      type: "dateRange",
    },
    {
      id: "reqBillTypeCode",
      label: "Bill to",
      hideForRoles: [userRoles.client, userRoles.physician, userRoles.sales],
      type: "autocomplete",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      resource: "lovs/REQBILLTYPEFILTER",
      accessor: (d) => ({ value: d.reqBillTypeCode, label: d.reqBillTypeDesc }),
    },
    {
      id: "salesRepCode",
      label: "Sales Rep Code",
      hideForRoles: [userRoles.client, userRoles.physician, userRoles.sales],
      type: "autocomplete",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      resource: "sales",
      accessor: (d) => ({
        value: d.salesRepCode,
        label: concatWithSeparator([`${d.salesLastName}, ${d.salesFirstName}`, d.salesRepCode], ' -'),
      }),
    },
    {
      id: "labId",
      label: "Lab ID",
      hideForRoles: [userRoles.client, userRoles.physician, userRoles.sales],
      type: "autocomplete",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      resource: "labs",
      accessor: (d) => ({ value: d.id, label: d.labName }),
    },
    {
      id: "reporterDeptId",
      label: "Reporter Dept",
      type: "autocomplete",
      hideForRoles: [userRoles.sales, userRoles.client],
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      resource: "depts",
      accessor: (d) => ({ value: d.id, label: d.deptName }),
    },
    {
      id: "resolverDeptId",
      label: "Resolver Dept",
      type: "autocomplete",
      hideForRoles: [userRoles.sales, userRoles.client],
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      resource: "depts",
      accessor: (d) => ({ value: d.id, label: d.deptName }),
    },
    {
      id: "labQcStatusCode",
      label: "Lab QC Status",
      type: "autocomplete",
      autocompleteOptions: {
        loadDataAfterOpenMenu: true,
      },
      resource: "lovs/LABQCSTATUSFILTER",
      accessor: (d) => ({ value: d.qcTypeCode, label: d.qcTypeDesc }),
    },
  ];
};
