import React from 'react';
import styled from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';

const StyledInfoICon = styled(InfoIcon)`
    &&{
      display: inline-block;
      cursor: pointer;
      transition: .5s;
      
      &:hover {
        color: #3F51B5;
      }
      }
    `;


export default function TooltipInfoButtonContainer (props) {
    return (
        <StyledInfoICon onMouseOver={props.onMouseOver} onMouseLeave={props.onMouseLeave}/>
    );
}