import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BuildingsIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M22.9,0H7.4C6.8,0,6.3,0.5,6.3,1.1v9.8H1.1C0.5,10.9,0,11.4,0,12v10.9C0,23.5,0.5,24,1.1,24h10.4h11.5
	c0.6,0,1.1-0.5,1.1-1.1V12V1.1C24,0.5,23.5,0,22.9,0z M8.5,2.2h13.4v8l-4.1-2.3c-0.3-0.2-0.7-0.2-1.1,0l-5.5,3H8.5L8.5,2.2z
	 M2.2,13.1h5.2h3v8.7H2.2V13.1z M21.8,21.8h-9.3v-9.2l4.6-2.6l4.6,2.6L21.8,21.8L21.8,21.8z M5.2,14.5H3.5c-0.2,0-0.3,0.1-0.3,0.3
	v1.6c0,0.2,0.1,0.3,0.3,0.3h1.6c0.2,0,0.3-0.1,0.3-0.3v-1.6C5.5,14.6,5.3,14.5,5.2,14.5z M9,14.5H7.4c-0.2,0-0.3,0.1-0.3,0.3v1.6
	c0,0.2,0.1,0.3,0.3,0.3H9c0.2,0,0.3-0.1,0.3-0.3v-1.6C9.3,14.6,9.2,14.5,9,14.5z M5.2,18.3H3.5c-0.2,0-0.3,0.1-0.3,0.3v1.6
	c0,0.2,0.1,0.3,0.3,0.3h1.6c0.2,0,0.3-0.1,0.3-0.3v-1.6C5.5,18.4,5.3,18.3,5.2,18.3z M9,18.3H7.4c-0.2,0-0.3,0.1-0.3,0.3v1.6
	c0,0.2,0.1,0.3,0.3,0.3H9c0.2,0,0.3-0.1,0.3-0.3v-1.6C9.3,18.4,9.2,18.3,9,18.3z M16.1,14.5h-1.6c-0.2,0-0.3,0.1-0.3,0.3v1.6
	c0,0.2,0.1,0.3,0.3,0.3h1.6c0.2,0,0.3-0.1,0.3-0.3v-1.6C16.4,14.6,16.2,14.5,16.1,14.5z M18.3,16.6h1.6c0.2,0,0.3-0.1,0.3-0.3v-1.6
	c0-0.2-0.1-0.3-0.3-0.3h-1.6c-0.2,0-0.3,0.1-0.3,0.3v1.6C18,16.5,18.1,16.6,18.3,16.6z M16.1,18.3h-1.6c-0.2,0-0.3,0.1-0.3,0.3v1.6
	c0,0.2,0.1,0.3,0.3,0.3h1.6c0.2,0,0.3-0.1,0.3-0.3v-1.6C16.4,18.4,16.2,18.3,16.1,18.3z M18.3,20.5h1.6c0.2,0,0.3-0.1,0.3-0.3v-1.6
	c0-0.2-0.1-0.3-0.3-0.3h-1.6c-0.2,0-0.3,0.1-0.3,0.3v1.6C18,20.3,18.1,20.5,18.3,20.5z M10.6,6.1h1.6c0.2,0,0.3-0.1,0.3-0.3V4.2
	c0-0.2-0.1-0.3-0.3-0.3h-1.6c-0.2,0-0.3,0.1-0.3,0.3v1.6C10.4,6,10.5,6.1,10.6,6.1z M14.5,6.1h1.6c0.2,0,0.3-0.1,0.3-0.3V4.2
	c0-0.2-0.1-0.3-0.3-0.3h-1.6c-0.2,0-0.3,0.1-0.3,0.3v1.6C14.2,6,14.3,6.1,14.5,6.1z M12.3,9.9c0.2,0,0.3-0.1,0.3-0.3V8
	c0-0.2-0.1-0.3-0.3-0.3h-1.6c-0.2,0-0.3,0.1-0.3,0.3v1.6c0,0.2,0.1,0.3,0.3,0.3H12.3z M18.3,6.1h1.6c0.2,0,0.3-0.1,0.3-0.3V4.2
	c0-0.2-0.1-0.3-0.3-0.3h-1.6C18.1,3.9,18,4,18,4.2v1.6C18,6,18.1,6.1,18.3,6.1z"/>
    </SvgIcon>
);

export default BuildingsIcon;