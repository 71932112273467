import React from 'react'
import MainHeader from 'components/templateParts/MainHeader';
import BaseSingleViewHeader
  from 'components/templateParts/BaseSingleViewHeader/BaseSingleViewHeader';
import { SmartTable } from 'components/ui_components/table'
import { TableHeaderAddButton } from 'components/ui_components/buttons';
import useSinglePostLoadData from 'hooks/useSinglePostLoadData';
import dataProvider, { BULK_DELETE } from "providers/dataProvider";
import {
  viewInputsByHash,
  viewGroupsById,
  viewGroupsByHash,
  tableTestRangesConfig,
} from 'config/resources/collaboratorsResource/view';
import WidgetViewModeByGroups
  from 'components/pages/requisitions/RequisitionsShow/Tabs/View/Widget/WidgetViewModeByGroups';
import WidgetGroup from 'components/ui_components/Widgets/WidgetGroup/WidgetGroup';
import WidgetType1 from 'components/ui_components/Widgets/WidgetType1/WidgetType1';
import styles from './CollaboratorsShow.module.scss';
import useModal from 'hooks/useModal.js';
import AddTestModal from './AddTestModal.js';

let smartTableRef = {};

const CollaboratorsShow = (props) => {
  const colaID = props.match.params.id;

  const [data, isDataLoaded] = useSinglePostLoadData({
    resource: props.resource,
    id: colaID
  })

  const { modalIsOpen, handleOpenModal, handleCloseModal } = useModal();

  const handleDeleteCallBack = (items, resource, loadData) => {
    let ids = items.map((i) => (i.id))
    const deleteResource = `collaborators/${colaID}/test_ranges`;

    dataProvider(BULK_DELETE, deleteResource, { id: ids })
    .then(({ data, total }) => {
      loadData()
    })
  }

  const handleAddNewTest = () => {
    smartTableRef.current.loadData();
  }

  return (
    <div>
      <MainHeader title="View Collaborator"/>

      <BaseSingleViewHeader
        editLink={"/collaborators/" + data.id}
        title={isDataLoaded && `${data.id} - ${data.colaName}`}
        subtitle={isDataLoaded && data.colaAlias}
        data={data}
        isDataLoaded={isDataLoaded}
      />

      <div className={styles.mainSingleContainerWrapper}>
        <div className="mainSingleContainer">
          <WidgetGroup>
            {viewGroupsById.map((groupID) => {

                const widgetContent = <React.Fragment>
                  <WidgetViewModeByGroups
                    data={data}
                    viewSubGroups={viewGroupsByHash[groupID].subGroups}
                    inputsByHash={viewInputsByHash}
                    theme={viewGroupsByHash[groupID].theme}
                  />
                </React.Fragment>

                let wrapWidgetStyles = `width: 60%;`;

                if (groupID === 'contact') {
                  wrapWidgetStyles = `width: 40%`;
                }

                return (
                  <WidgetType1
                    key={groupID}
                    title={viewGroupsByHash[groupID].title}
                    content={widgetContent}
                    wrapStyles={wrapWidgetStyles}
                  />
                )
              }
            )}
          </WidgetGroup>

            <div className="singlePage__title4">
              Custom Test Reference Range
            </div>

            <div className={styles.tableWrap}>
              <SmartTable
                onRef={ref => (smartTableRef.current = ref)}
                resource={props.resource + '/' + props.id + '/test_ranges'}
                columns={tableTestRangesConfig}
                perPage={-1}
                deleteResource="test ranges"
                showPagination={false}
                deleteCallback={handleDeleteCallBack}
                toolbarExtraComponents={
                  <TableHeaderAddButton onClick={handleOpenModal}>Add test</TableHeaderAddButton>}
              />
            </div>

          <AddTestModal
            modalIsOpen={modalIsOpen}
            handleCloseModal={handleCloseModal}
            colaID={colaID}
            handleAddNewTest={handleAddNewTest}
          />
        </div>
      </div>
    </div>
  );
}

export default CollaboratorsShow;