import React, {PureComponent} from 'react';
import MultiselectComponent from 'components/ui_components/smart_input/MultiselectComponent';
import withOptions from "components/ui_components/smart_input/withOptions";
import withAutoSave from 'components/ui_components/smart_input/withAutoSave';
import styles from './ReqFailPassCodesMultiSelectContainer.module.scss';
import helpers from 'utils/helpers';
import { userGroups } from 'config/userRoles';

class ReqFailPassCodesMultiSelectContainer extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedOptions: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {input, reqReasons} = this.props;

        if (JSON.stringify(prevProps.reqReasons) !== JSON.stringify(this.props.reqReasons)) {
            this.setState({
                selectedOptions: input.selectedOptionsAccessor(reqReasons)
            })
        }
    }

    handleChange = e => {
        const {input, reqID, inputId} = this.props;

        const value = e.target.value;

        this.setState({
            selectedOptions: value
        })

        const submitMessage = inputId === 'failCode' ? 'Fail Codes successfully updated' : 'Pass Codes successfully updated';

        if (this.props.input.hasOwnProperty('autoSave')) {
            const resource = input.autoSave.hasOwnProperty('resourceAccessor') ? input.autoSave.resourceAccessor({reqID: reqID}) : null;
            const data = input.autoSave.hasOwnProperty('dataAccessor') ? input.autoSave.dataAccessor(value) : value;
            this.props.autoSave(data, resource, submitMessage);
        }
    }

    handleClick = e => {
        e.stopPropagation()
    }

    render() {
        const {input, inputId, onMouseLeave, onMouseOver, options} = this.props;
        const {selectedOptions} = this.state;

        return (
            <div className={styles.inputGroup}>
                <div className={styles.inputWrap}>
                    <MultiselectComponent
                        disabled={!helpers.can(userGroups.superAdmins)}
                        onMouseLeave={onMouseLeave}
                        onMouseOver={onMouseOver}
                        selectedOptions={selectedOptions}
                        classes={{}}
                        options={options}
                        label={selectedOptions.length === 0 ? input.label : ''}
                        value={selectedOptions}
                        name={inputId}
                        onClick={this.handleClick}
                        onChange={this.handleChange}
                        renderValue={selected => {return input.label}}
                    />
                </div>

                <div className={styles.selectedOptionsList}>
                    {options.filter(item => {
                        return selectedOptions.includes(item.value)
                    }).map((item, index) => {
                        return <div key={index}>{item.label}</div>
                    })}
                </div>
            </div>
        )
    }
}

export default withAutoSave(withOptions(ReqFailPassCodesMultiSelectContainer));
