import React from "react";
import LightingIcon from "../../../icons/LightingIcon";
import PlusIcon from "../../../icons/PlusIcon";
import styles from "./AddPostButton.module.scss";
import { Link } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  mobileButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  desktopButton: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const AddPostButton = (props) => {
  const classes = useStyles();

  const buttonContent = (
    <React.Fragment>
      <div className={classes.desktopButton}>
        <PlusIcon className={styles.plusIcon} />
        <span className={styles.label}>{props.title}</span>
        <div className={styles.lightingIconWrap}>
          <LightingIcon className={styles.lightingIcon} />
        </div>
      </div>

      <Fab
        size="small"
        color="primary"
        aria-label="Add"
        className={`${classes.mobileButton} ${classes.margin}`}
      >
        <AddIcon />
      </Fab>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {props.onClick ? (
        <span className={styles.root} onClick={props.onClick}>
          {buttonContent}
        </span>
      ) : (
        <Link className={styles.root} to={props.href}>
          {buttonContent}
        </Link>
      )}
    </React.Fragment>
  );
};

AddPostButton.defaultProps = {
  onClick: null,
};

export default AddPostButton;
