import React from "react";
import MainHeader from "components/templateParts/MainHeader";
import styles from "./TestReportsUpload.module.scss";
import dataProvider, { DOWNLOAD_FILE_GET } from "providers/dataProvider";
import SearchBar from "components/templateParts/SearchBar";
import SmartViewsSidebar from "components/SmartViewsSidebar/SmartViewsSidebar";
import Footer from "components/Footer/Footer";
import {
  smartFilterConfig,
  tableColConfig,
} from "config/resources/testReportsUploadResource";
import SmartTable from "components/ui_components/table/SmartTable";
import { DownloadIcon } from "icons";
import { withSmartFilter } from "SmartFilterProvider";
import { compose } from "redux";
import { withNotificationCenter } from "NotificationCenter";
import DownloadTableToolbar from "./DownloadTableToolbar";

let smartTableRef = {};

const TestReportsUploadContainer = ({ ...props }) => {
  const handleDownloadClick = (rows, e) => {
    dataProvider(DOWNLOAD_FILE_GET, `bulk_report_uploader/${rows[0].id}/failed`)
      .then((response) => {
        if (response.data.url) {
          window.open(response.data.url);
          props.notificationCenter.showDownloadNotification(response.data.url);
        } else {
          props.notificationCenter.show(response.message, "info");
        }
      })
      .catch((error) => {
        props.notificationCenter.show(error.message, "error");
      });
  };

  const onUploadOk = () => {
    smartTableRef.current.loadData();
  };

  console.log(props);

  return (
    <div>
      <MainHeader title="Batch File Upload" />

      <SearchBar />
      <SmartViewsSidebar
        isOpen={true}
        resource="physicians"
        smartFilterConfig={smartFilterConfig}
      >
        <div className="mainContainer">
          <div className="mainContainer__inner">
            <div className={styles.root}>
              <SmartTable
                toolbarExtraComponents={
                  <DownloadTableToolbar onUploadOk={onUploadOk} />
                }
                onRef={(ref) => (smartTableRef.current = ref)}
                resource="bulk_report_uploader"
                columns={tableColConfig}
                order="desc"
                orderBy={"loadDate"}
                filter={props.smartFilter.getFilter()}
                collapsibleTable={true}
                showSelect={false}
                showDelete={false}
                showSelectAll={false}
                collapsibleTableContentType={["testReportsUpload"]}
                customRowActions={[
                  {
                    icon: <DownloadIcon />,
                    handleOnClick: handleDownloadClick,
                    tooltipText: "Download report",
                  },
                ]}
              />
            </div>
          </div>
          <Footer />
        </div>
      </SmartViewsSidebar>
    </div>
  );
};

export default compose(
  withNotificationCenter(),
  withSmartFilter()
)(TestReportsUploadContainer);
