import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MainHeader from 'components/templateParts/MainHeader';
import dataProvider, { UPDATE } from "providers/dataProvider";
import withForm from 'withForm';
import { clientsResource } from "config/resources/clientsResource";
import { compose } from 'redux'
import { withNotificationCenter } from 'NotificationCenter'
import BaseForm from 'components/ui_components/BaseForm/BaseForm';
import BaseFormControls from 'components/ui_components/BaseFormControls/BaseFormControls';

const styles = () => ({
  main: {
    margin: '32px 26px 0',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '30px 15px 30px'
  }
});

const { inputsById, inputsGroupsByHash } = clientsResource.editConfig;
const inputsByHash = clientsResource.createConfig.inputsByHash;
const inputsGroupsById = clientsResource.createConfig.inputsGroupsById;

const withFormConfig = {inputsByHash, inputsById };

const ClientEditContainer = (props) => {
  const { classes, inputsById, inputsByHash, handleInputs } = props;

  const handleSubmit = () => {
    const { notificationCenter, history, isFormValid, resetErrors, getSubmitData } = props;

    if (isFormValid()) {
      resetErrors();
      const submitData = getSubmitData()

      dataProvider(UPDATE, 'clients', { data: submitData, id: props.id })
      .then((response) => {
        notificationCenter.show('Successfully updated', 'info')
        history.push('/clients/' + response.data.id + '/show');
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
      });
    }
  }

  return (
    <div>
      <MainHeader title="Edit Client"/>
      <div className={classes.main}>
        <BaseForm
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          inputsGroupsById={inputsGroupsById}
          inputsGroupsByHash={inputsGroupsByHash}
        />

        <BaseFormControls
          type={'edit'}
          handleClickSubmit={handleSubmit}
          submitLabel='Update Client'
          deleteConfirmationMessagePostfix={'client'}
          resource='clients'
          id={props.id}
        />
      </div>
    </div>
  );
}

export default compose(
  withNotificationCenter(),
  withForm({ ...withFormConfig, mode: 'edit' }),
  withStyles(styles)
)(ClientEditContainer)