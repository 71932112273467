export const SMART_VIEWS_SIDEBAR_SHOW = 'SMART_VIEWS_SIDEBAR_SHOW';
export const SMART_VIEWS_SIDEBAR_HIDE = 'SMART_VIEWS_SIDEBAR_HIDE';
export const SMART_VIEWS_SIDEBAR_SWITCH_TAB = 'SMART_VIEWS_SIDEBAR_SWITCH_TAB';

export const UPDATE_SMART_FILTER_INPUT_VALUE = 'UPDATE_SMART_FILTER_INPUT_VALUE';
export const INIT_SMART_FILTERS = 'INIT_SMART_FILTERS';
export const CLEAR_ALL_SMART_FILTER_TAG_VALUES = 'CLEAR_ALL_SMART_FILTER_TAG_VALUES';
export const CLEAR_SMART_FILTER_TAG_VALUE = 'CLEAR_SMART_FILTER_TAG_VALUE';
export const APPLY_SMART_FILTERS = 'APPLY_SMART_FILTERS';
export const CLEAR_ALL_SMART_FILTER_INPUT_VALUES = 'CLEAR_ALL_SMART_FILTER_INPUT_VALUES';
export const APPLY_SMART_FILTER_CONFIG = 'APPLY_SMART_FILTER_CONFIG';

export const UPDATE_STATUS    = 'UPDATE_STATUS';
export const INIT_STATUS_FILTERS    = 'INIT_STATUS_FILTERS';
export const LOAD_STATUSES    = 'LOAD_STATUSES';

export const INIT_CACHE_RESOURCE = 'INIT_CACHE_RESOURCE';
export const SET_CACHE_RESOURCE_VALUE = 'SET_CACHE_RESOURCE_VALUE';

export const OPEN_ADD_SPECIMEN_LOCATION_MODAL = 'OPEN_ADD_SPECIMEN_LOCATION_MODAL';
export const CLOSE_ADD_SPECIMEN_LOCATION_MODAL = 'CLOSE_ADD_SPECIMEN_LOCATION_MODAL';

export const USER_READ_ALL_NOTIFICATIONS = 'USER_READ_ALL_NOTIFICATIONS';
export const USER_HAS_UNREAD_NOTIFICATIONS = 'USER_HAS_UNREAD_NOTIFICATIONS';

export const SET_SIDEBAR_VISIBILITY = 'SET_SIDEBAR_VISIBILITY';

export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL';
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL';