import dataProvider, { GET_ONE } from "providers/dataProvider";
import { useState, useEffect } from 'react';

export default function({ resource, id}) {
  const [data, setData] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const loadData = () => {
      dataProvider(GET_ONE, resource, { id: id })
      .then(({ data }) => {
        setData(data);
        setIsDataLoaded(true);
      })
    }

    loadData();
  }, [id, resource]);

  return [
    data,
    isDataLoaded,
  ]
}