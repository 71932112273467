import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CloseIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M16.6,7.4c-0.4-0.4-1.1-0.4-1.5,0L12,10.5L8.8,7.4C8.4,7,7.8,7,7.4,7.4C7,7.8,7,8.4,7.4,8.8l3.2,3.2l-3.2,3.2
	c-0.4,0.4-0.4,1.1,0,1.5c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l3.2-3.2l3.2,3.2c0.2,0.2,0.5,0.3,0.7,0.3
	c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1.1,0-1.5L13.5,12l3.2-3.2C17,8.4,17,7.8,16.6,7.4z M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12
	c6.6,0,12-5.4,12-12C24,5.4,18.6,0,12,0z M12,21.9c-5.5,0-9.9-4.5-9.9-9.9c0-5.5,4.5-9.9,9.9-9.9c5.5,0,9.9,4.5,9.9,9.9
	C21.9,17.5,17.5,21.9,12,21.9z"/>
    </SvgIcon>
);

export default CloseIcon;