import React, { useState, useEffect } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledCheckbox from 'components/ui_components/StyledCheckbox/StyledCheckbox.js';
import PropTypes from "prop-types";
import ButtonBase from '@material-ui/core/ButtonBase';
import dataProvider, { SEND_PASSWORD_LINK, UPDATE } from "providers/dataProvider";
import { useAuthCenter } from 'AuthCenter';
import { useNotificationCenter } from 'NotificationCenter'
import { WidgetType1 } from 'components/ui_components/Widgets'

let timer = null;

const ActionsWidgetContainer = (props) => {
  const [checked, setChecked] = useState(false);
  const notificationCenter = useNotificationCenter();
  const authCenter = useAuthCenter();

  let currentUserId = null;

  if (authCenter.access && authCenter.access.hasOwnProperty('userId')) {
    currentUserId = authCenter.access.userId;
  }

  const handleChange = () => {
    setChecked(prevState => {
      const newCheckedState = !prevState;
      autoSave(newCheckedState);
      return newCheckedState
    })
  }
  useEffect(() => {
    setChecked(props.userStatusCode === 'ACT');
  }, [props.userStatusCode]);

  const autoSave = (newCheckedState) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      const data = {
        userStatusCode: newCheckedState ? 'ACT' : 'LCK',
      };

      dataProvider(UPDATE, `users/${props.userID}`, { data: data })
      .then((response) => {
        notificationCenter.show('Successfully updated', 'info')
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
      });
    }, 500)
  }

  const handleResetButtonClick = () => {

    const { userID } = props;
    dataProvider(
      SEND_PASSWORD_LINK,
      null,
      {
        userId: userID,
        adminId: currentUserId,
      })
    .then((response) => {
      notificationCenter.show('Send ok', 'info')
    })
    .catch(error => {
      notificationCenter.show(error.message, 'warning')
    });
  }

  const content = <React.Fragment>
    <FormControlLabel control={<StyledCheckbox
    checked={checked}
    onChange={handleChange}
  />} label="Enabled"/>


    <ButtonBase
      onClick={handleResetButtonClick}
      className="button formButton formButton__mainOutlined">
      Reset Password
    </ButtonBase>
  </React.Fragment>

  return (
    <WidgetType1
      title='Actions'
      content={content}
      wrapStyles={`width: auto;`}
      widgetContentStyles={`margin: 20px 0 0;`}
    />
  )
}

ActionsWidgetContainer.propTypes = {
  userID: PropTypes.string.isRequired
}

export default ActionsWidgetContainer;