import React, {PureComponent} from 'react'
import withAutoSave from "../../smart_input/withAutoSave";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledCheckbox from 'components/ui_components/StyledCheckbox/StyledCheckbox.js';

class NotifyPatientEditTableCell extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            checked: props.value === 1
        }
    }

    handleChange = (e) => {
        this.props.autoSave({isSendNotif: !this.state.checked}, `requisitions/${this.props.reqID}/send_notif`, 'Successfully updated');
        this.setState((prevState) => {
            return {
                checked: !prevState.checked
            }
        })
    }

    onClick = (e) => {
        e.stopPropagation()
    }

    render() {
        return (
          <FormControlLabel
            style={{ margin: 0, width: '100%', justifyContent: 'center' }}
            onClick={this.onClick}
            control={(
              <StyledCheckbox
                checked={this.state.checked}
                onChange={this.handleChange}
                value="checked"
              />
            )}
            label=""
          />
        )
    }
}

export default withAutoSave(NotifyPatientEditTableCell)