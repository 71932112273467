import React, { useState } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import dataProvider, { CREATE } from "providers/dataProvider";
import withForm from 'withForm'
import { compose } from 'redux'
import addWorkLogForm from 'config/resources/requisitionsResource/issuesTab/addWorkLogForm.js'
import BaseForm from 'components/ui_components/BaseForm/BaseForm';
import { useNotificationCenter } from 'NotificationCenter';
import BaseModal from 'components/ui_components/BaseModal/BaseModal.js';

const AddWorkLogModal = (props) => {
  const {
    handleCloseModal,
    clearInputs,
    modalIsOpen,
    handleAddNewWorkLog,
    issueID,
    reqID,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const notificationCenter = useNotificationCenter();

  const handleSubmit = () => {
    props.resetErrors();
    if (!props.isFormValid()) return;

    setIsSubmitting(true);

    dataProvider(CREATE, `requisitions/${reqID}/issues/${issueID}/work_logs`, { data: props.getSubmitData(true, true, true) })
    .then((response) => {
      notificationCenter.show('Successfully create', 'info')
      handleCloseModal();
      clearInputs();
      handleAddNewWorkLog();
      setIsSubmitting(false);
    })
    .catch(error => {
      notificationCenter.show(error.message, 'warning')
      setIsSubmitting(false);
    });
  }

  return (
    <BaseModal
      isOpen={modalIsOpen}
      onClose={handleCloseModal}
      title='Add Test'
      showProgressBar={isSubmitting}
      controls={<React.Fragment>
        <ButtonBase
          className="button formButton formButton__secondaryOutlined"
          onClick={handleCloseModal}>
          Cancel
        </ButtonBase>
        <ButtonBase
          className="button formButton formButton__mainContained"
          onClick={handleSubmit}
        >
          Add
        </ButtonBase>
      </React.Fragment>}
      rootStyles={`max-width: 460px;`}
    >
      <BaseForm
        inputsById={props.inputsById}
        inputsByHash={props.inputsByHash}
        handleInputs={props.handleInputs}
        inputsGroupsById={addWorkLogForm.inputsGroupsById}
        inputsGroupsByHash={addWorkLogForm.inputsGroupsByHash}
        inputWrapStyles={`width: 100%`}
        groupTitleStyles={`font-size: 15px;`}
        groupStyles={`
              padding: 15px 0 0;
              + .formGroup {
                padding: 25px 0 0;
              }
          `}
      />
    </BaseModal>
  );

}

export default compose(
  withForm(addWorkLogForm)
)(AddWorkLogModal)