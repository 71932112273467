import React, { Component } from "react";
import SmartTable from "components/ui_components/table/SmartTable";
import { Delivery2Icon, DownloadIcon } from "icons";
import DeliveryModalContainer from "components/pages/requisitions/RequisitionsShow/Tabs/ReportsDelivery/DeliveryModalContainer";
import { compose } from "redux";
import withModal from "withModal";
import helpers from "utils/helpers";
import dataProvider, { DOWNLOAD_FILE } from "providers/dataProvider";
import { withNotificationCenter } from "NotificationCenter";
import { formatServerDateTimeToLocal } from "utils/dateTimeFormat";
import { userRoles } from 'config/userRoles';

const tableColById = [
  "reportOriginalFileName",
  "labQcStatusCode",
  "latestTransmitMethodDesc",
  "latestDeliveryDate",
];
const tableColByHash = {
  reportOriginalFileName: {
    label: "Report Name",
  },
  labQcStatusCode: {
    label: "Lab QC Result",
    edit: true,
    autoSave: {
      resourceAccessor: (d) => {
        return `requisitions/${d.reqID}/reports/${d.reportID}`;
      },
    },
    type: "labQcStatus",
  },
  latestTransmitMethodDesc: {
    label: "Latest Delivery",
  },
  latestDeliveryDate: {
    label: "Latest Delivery Date",
    accessor: (d) =>
      formatServerDateTimeToLocal(d.latestDeliveryDate, "dateTime"),
  },
};

const tableColByHashWithOutEditableLabQC = {
  reportOriginalFileName: {
    label: "Report Name",
  },
  labQcStatusCode: {
    label: "Lab QC Result",
    hideForRoles: [userRoles.client, userRoles.physician],
  },
  latestTransmitMethodDesc: {
    label: "Latest Delivery",
  },
  latestDeliveryDate: {
    label: "Latest Delivery Date",
    accessor: (d) =>
      formatServerDateTimeToLocal(d.latestDeliveryDate, "dateTime"),
  },
};

class ReleasedReportsSubTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
    };
  }

  handleDeliveryClick = (rows) => {
    this.props.handleOpenModal();
    this.setState({
      selectedRows: rows,
    });
  };

  handleDownloadClick = (rows) => {
    const { reqID, notificationCenter } = this.props;

    dataProvider(DOWNLOAD_FILE, `requisitions/${reqID}/download`, {
      data: {
        reportIds: helpers.getAllValues(rows, "id"),
        callSource: this.props.downloadCallSource,
      },
    })
      .then((response) => {
        if (response.data !== null && response.data.hasOwnProperty("url")) {
          window.open(response.data.url);
          this.props.notificationCenter.showDownloadNotification(
            response.data.url
          );
        } else {
          this.props.notificationCenter.show(response.message, "error");
        }
      })
      .catch((error) => {
        notificationCenter.show(error.message, "error");
      });
  };

  reloadReportTable = () => {
    this.smartTable.loadData();
  };

  render() {
    const {
      reqID,
      modalOpen,
      handleCloseModal,
      showDeliveryAction,
      tableColConfigByHashWithExtraData,
      extraData,
    } = this.props;
    const { selectedRows } = this.state;

    const customRowActions = [];

    showDeliveryAction &&
      customRowActions.push({
        icon: <Delivery2Icon />,
        handleOnClick: this.handleDeliveryClick,
        tooltipText: "Delivery",
      });
    customRowActions.push({
      icon: <DownloadIcon />,
      handleOnClick: this.handleDownloadClick,
      tooltipText: "Download report",
      isHiddenAccessor: (d) => d.labQcStatusCode !== 'RELEASE'
    });

    return (
      <React.Fragment>
        <DeliveryModalContainer
          modalOpen={modalOpen}
          handleCloseModal={handleCloseModal}
          resource={`requisitions/${reqID}/delivery`}
          handleSubmitOK={this.reloadReportTable}
          submitData={{ reportIds: helpers.getAllValues(selectedRows, "id") }}
        />
        <SmartTable
          onRef={(ref) => (this.smartTable = ref)}
          resource={`requisitions/${reqID}/reports`}
          columns={helpers.byHashByIDConfToArray(
            tableColById,
            this.props.showLabQcResultSelect
              ? tableColConfigByHashWithExtraData || tableColByHash
              : tableColByHashWithOutEditableLabQC
          )}
          isSubTable={true}
          showSelect={false}
          showPagination={false}
          parentRow={this.props.parentRow}
          customRowActions={customRowActions}
          extraData={extraData}
        />
      </React.Fragment>
    );
  }
}

ReleasedReportsSubTable.defaultProps = {
  showDeliveryAction: false,
  downloadCallSource: "RELEASE",
  extraData: {},
};

export default compose(
  withNotificationCenter(),
  withModal
  // withTableExtraData({
  //   tableColById: tableColById,
  //   tableColByHash: tableColByHash,
  // })
)(ReleasedReportsSubTable);
