import {
    SMART_VIEWS_SIDEBAR_SHOW,
    SMART_VIEWS_SIDEBAR_HIDE,
    SMART_VIEWS_SIDEBAR_SWITCH_TAB,
    UPDATE_SMART_FILTER_INPUT_VALUE,
    INIT_SMART_FILTERS,
    CLEAR_ALL_SMART_FILTER_TAG_VALUES,
    CLEAR_SMART_FILTER_TAG_VALUE,
    APPLY_SMART_FILTERS,
    CLEAR_ALL_SMART_FILTER_INPUT_VALUES,
    APPLY_SMART_FILTER_CONFIG,
    UPDATE_STATUS,
    LOAD_STATUSES,
    OPEN_ADD_SPECIMEN_LOCATION_MODAL,
    CLOSE_ADD_SPECIMEN_LOCATION_MODAL,
    USER_HAS_UNREAD_NOTIFICATIONS,
    USER_READ_ALL_NOTIFICATIONS,
    SET_SIDEBAR_VISIBILITY,
    OPEN_AUTH_MODAL,
    CLOSE_AUTH_MODAL
} from './../config/actionTypes';

export const setSidebarVisibility = (payload) => {
    return {
        type: SET_SIDEBAR_VISIBILITY,
        payload: payload
    };
};

export const smartViewSidebarShow = (payload) => {
    return {
        type: SMART_VIEWS_SIDEBAR_SHOW,
        payload: payload
    };
};
export const smartViewSidebarHide = (payload) => {
    return {
        type: SMART_VIEWS_SIDEBAR_HIDE,
        payload: payload
    };
};

export const loadStatuses = (payload) => {
    return {
        type: LOAD_STATUSES,
        payload: payload
    };
};


export const updateStatus = (payload) => {
    return {
        type: UPDATE_STATUS,
        payload: payload
    };
};

export const smartViewSidebarSwitchTab = (payload) => {
    return {
        type: SMART_VIEWS_SIDEBAR_SWITCH_TAB,
        payload: payload
    };
};


export const updateSmartFilterInputValue = (payload) => {
    return {
        type: UPDATE_SMART_FILTER_INPUT_VALUE,
        payload: payload
    };
};

export const applySmartFilters = () => {
    return {
        type: APPLY_SMART_FILTERS
    };
};


export const initSmartFilters = (payload) => {
    return {
        type: INIT_SMART_FILTERS,
        payload: payload
    };
};

export const clearAllSmartFilterTagValues = () => {
    return {
        type: CLEAR_ALL_SMART_FILTER_TAG_VALUES
    };
};

export const clearSmartFilterTagValue = (payload) => {
    return {
        type: CLEAR_SMART_FILTER_TAG_VALUE,
        payload: payload
    }
}

export const clearAllSmartFilterInputValues = () => {
    return {
        type: CLEAR_ALL_SMART_FILTER_INPUT_VALUES
    };
};

export const applySmartFilterConfig = (payload) => {
    return {
        type: APPLY_SMART_FILTER_CONFIG,
        payload: payload
    }
}

export const openAddSpecimenLocationModal = () => {
    return {
        type: OPEN_ADD_SPECIMEN_LOCATION_MODAL,
    }
}

export const closeAddSpecimenLocationModal = () => {
    return {
        type: CLOSE_ADD_SPECIMEN_LOCATION_MODAL,
    }
}

export const userHasUnreadNotifications = () => {
    return {
        type: USER_HAS_UNREAD_NOTIFICATIONS
    }
}

export const userReadAllNotifications = () => {
    return {
        type: USER_READ_ALL_NOTIFICATIONS
    }
}

export const openAuthModal = () => {
    return {
        type: OPEN_AUTH_MODAL
    }
}

export const closeAuthModal = () => {
    return {
        type: CLOSE_AUTH_MODAL
    }
}