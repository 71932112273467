import React, { PureComponent } from "react";
import Select from "components/ui_components/smart_input/select";
import PropTypes from "prop-types";
import withAutoSave from "../../smart_input/withAutoSave";
import helpers from "utils/helpers";
import { userPermissions } from "config/userRoles";

class LabQcStatusEditTableCell extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: props.value || "",
    };
  }

  handleInputs = (e) => {
    const value = e.target.value;
    const { reqID, reportID, autoSave } = this.props;

    this.setState({
      selectedOption: value,
    });

    const resource = this.props.col.autoSave.hasOwnProperty("resourceAccessor")
      ? this.props.col.autoSave.resourceAccessor({
          reqID: reqID,
          reportID: reportID,
        })
      : null;
    autoSave(
      { labQcStatusCode: value },
      resource,
      "Lab QC Result Successfully Update"
    ).then(() => {
      this.props.smartTable.loadData();
    })
  };

  render() {
    const { optionsData } = this.props;
    const { selectedOption } = this.state;

    return (
      <Select
        handleInputs={this.handleInputs}
        disabled={
          !helpers.can(userPermissions.reqEntity.show.reportsDelivery.edit)
        }
        style={{}}
        value={selectedOption}
        input={{
          options: optionsData.map((item) => ({
            value: item.qcTypeCode,
            label: item.qcTypeDesc,
          })),
          customOptionType: true,
        }}
      />
    );
  }
}

export default withAutoSave(LabQcStatusEditTableCell);

LabQcStatusEditTableCell.propTypes = {
  optionsData: PropTypes.array.isRequired,
};

LabQcStatusEditTableCell.defaultProps = {
  optionsData: [],
};
