import React from 'react';
import SmartInput from 'components/ui_components/smart_input'
import { InputWrap } from 'components/ui_components/smart_input';
import styled from 'styled-components';

const defaultStyles = `
    display: flex;
    margin: 0 -8px;
    flex-wrap: wrap;
  `

const StyledList = styled.div`
   &&{
      ${defaultStyles}
      ${props => props.styles}
    }
  `;

const BaseInputList = ({ inputsById, inputsByHash, handleInputs, smartInputOptions, listStyles, inputWrapStyles }) => {
 return (
   <StyledList styles={listStyles}>
     {inputsById.map((inputId) => {
       const input = inputsByHash[inputId];

       const inputWrapStylesMerged = `
         ${inputWrapStyles}
         ${input.inputWrapStyles ? input.inputWrapStyles : ''}
       `

       return (
         <InputWrap key={inputId} styles={inputWrapStylesMerged}>
           <SmartInput
             inputId={inputId}
             input={input}
             handleInputs={handleInputs}
             options={smartInputOptions}
           />
         </InputWrap>
       )
     })}
   </StyledList>
 )
}

export default BaseInputList;

BaseInputList.defaultProps = {
  smartInputOptions: {},
  handleInputs: () => {},
  inputsById: [],
  inputsByHash: {},
  inputWrapStyles: '',
}