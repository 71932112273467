import { formatServerDateTimeToLocal } from "utils/dateTimeFormat";

export default [
  {
    id: "docOriginalFileName",
    label: "Document Name",
  },
  {
    id: "docCreateDate",
    label: "Upload Date",
    accessor: (d) =>
      d.docCreateDate &&
      formatServerDateTimeToLocal(d.docCreateDate, "dateTime"),
  },
  {
    id: "docCreateByName",
    label: "Uploaded By",
  },
];
