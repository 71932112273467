import React from 'react';

const withAutoFill = WrappedComponent => {
    class HOC extends React.Component {
        handleAutoFill = () => {
            const {input, inputId, handleInputs} = this.props;

            let e = {target: {value: input.autoFillAccessor(input.relativeAutoFillInputsData), name: inputId}}
            handleInputs(e)
        }

        componentDidUpdate(prevProps) {
            if (JSON.stringify(this.props.input.relativeAutoFillInputsData) !== JSON.stringify(prevProps.input.relativeAutoFillInputsData)) {
                this.handleAutoFill();
            }
        }


        render() {
            return <WrappedComponent
                {...this.props}
                />;
            }
    }

    return HOC;
};

export default withAutoFill;