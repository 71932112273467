import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {SmartTable} from 'components/ui_components/table'
import {Search} from 'components/ui_components'

const styles = () => ({
    root: {
        margin: 'auto',
        maxWidth: '1130px',
        padding: '30px 0 0'
    }
});

class PhysiciansCreateStep2Container extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            searchString: null
        }
    }
    getSelectedIds = () => {
        return this.props.selectedClients.map(item => {return item.id})
    }

    searchCallback = (searchString)=> {
        this.setState({searchString})
    }

    render () {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
              <Search callback={this.searchCallback} />
              <SmartTable
                columns={this.props.colConfig}
                resource={'clients'}
                showSelect={true}
                order={'desc'}
                showDelete={false}
                searchString={this.state.searchString}
                searchBy='clientName'
                handleSelectRowCallBack={this.props.handleSelectedClients}
                selected={this.getSelectedIds()}
              />
            </div>
        );
    }
}

export default (withStyles(styles)(PhysiciansCreateStep2Container))