import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import styles from './WarningIcon.module.scss';

const WarningIcon = (props) => (
  <SvgIcon {...props}>
      <path className={styles.st1} d="M15.1,2.9l8.4,14.5c0.3,0.5,0.5,1.1,0.5,1.8c0,2-1.6,3.6-3.6,3.6H12L9.1,12L12,1.1C13.3,1.1,14.5,1.9,15.1,2.9
		z"/>
      <path className={styles.st2}
            d="M8.9,2.9L0.5,17.4C0.2,18,0,18.6,0,19.3c0,2,1.6,3.6,3.6,3.6H12V1.1C10.7,1.1,9.5,1.9,8.9,2.9z"/>
      <path className={styles.st3} d="M22.3,18.2L13.9,3.7c-0.4-0.7-1.1-1.1-1.8-1.1l7.2,18.9h1.2c1.2,0,2.2-1,2.2-2.2
		C22.6,18.9,22.5,18.5,22.3,18.2z"/>
      <path className={styles.st4} d="M20.9,18.2c0.2,0.3,0.3,0.7,0.3,1.1c0,1.2-0.8,2.2-1.9,2.2H3.6c-1.2,0-2.2-1-2.2-2.2c0-0.4,0.1-0.8,0.3-1.1
		l8.4-14.5C10.5,3,11.2,2.6,12,2.6c0,0,0,0,0.1,0c0.6,0,1.2,0.4,1.6,1.1L20.9,18.2z"/>
      <path className={styles.st1} d="M12,16.6V19c0.7,0,1.2-0.5,1.2-1.2C13.2,17.1,12.7,16.6,12,16.6z"/>
      <path className={styles.st2}
            d="M12,16.6c0.1,0,0.2,0.5,0.2,1.2c0,0.7-0.1,1.2-0.2,1.2c-0.7,0-1.2-0.5-1.2-1.2C10.8,17.1,11.3,16.6,12,16.6z"
      />
      <path className={styles.st1} d="M12,6.2v8.9c0.7,0,1.2-0.5,1.2-1.2V7.4C13.2,6.8,12.7,6.2,12,6.2z"/>
      <path className={styles.st2} d="M12,6.2c0.1,0,0.2,0.5,0.2,1.2v6.5c0,0.7-0.1,1.2-0.2,1.2c-0.7,0-1.2-0.5-1.2-1.2V7.4
		C10.8,6.8,11.3,6.2,12,6.2z"/>

  </SvgIcon>
);

export default WarningIcon;