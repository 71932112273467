import PropTypes from "prop-types";
import authStorage from "providers/authStorage.js";

const Can = ({ allowedRoles, notAllowedRoles, children }) => {
  let userRoleCode = null;
  try {
    userRoleCode = authStorage.getAccess().userRoleCode;
  } catch (e) {
    userRoleCode = "guest";
  }

  if (allowedRoles) {
    return allowedRoles.includes(userRoleCode) ? children : null;
  } else if (notAllowedRoles) {
    return !notAllowedRoles.includes(userRoleCode) ? children : null;
  } else {
    return null;
  }
};

Can.defaultProps = {
  allowedRoles: null,
  notAllowedRoles: null,
};

Can.propTypes = {
  allowedRoles: PropTypes.array,
  notAllowedRoles: PropTypes.array,
};

export default Can;
