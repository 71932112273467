import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import StyledCheckbox from 'components/ui_components/StyledCheckbox/StyledCheckbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import style from './SingleCheckbox.module.scss';
import clsx from "clsx";

const styles = theme => ({
  label: {
    padding: '7px 0 0',
    margin: '0'
  },

  radioGroup: {
    padding: '0 13px'
  },

  radioGroupError: {
    '& label': {
      color: 'red'
    }
  },
});

const SingleCheckbox = (props) => {
  const { classes, input, inputId } = props;

  const handleChange = (e) => {
    props.handleInputs({
      target: {
        value: e.target.checked ? 1 : 0,
        name: e.target.name
      }
    })
  }

  return (
    <div className={clsx(style.radioGroupWrap,
      { [style.radioGroupError]: input.hasOwnProperty('yup') && input.error })}>
      <div className={style.radioInputLabel}>
        {input.error ? input.errorMessage : null}
      </div>

      <div className={style.formGroupWrap}>
        <FormGroup>
          <FormControlLabel
            className={classes.label}
            control={<StyledCheckbox size="small"/>}
            onChange={handleChange}
            checked={input.value === 1}
            value={input.value === 1}
            name={inputId}
            label={input.label}
          />
        </FormGroup>
      </div>

    </div>
  );
}

export default withStyles(styles)(SingleCheckbox);