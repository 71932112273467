import React from 'react';
import useDropZone from 'hooks/useDropZone.js';

export default function (Component) {
  return function (props) {
    const [
      dropZoneError,
      dropZoneFiles,
      handleDropFiles,
      isValidDropZone,
      isDropZoneUploading,
      setDropZoneUploading,
    ] = useDropZone();
    return <Component {...props} {...
      {
        dropZoneError,
        dropZoneFiles,
        handleDropFiles,
        isValidDropZone,
        isDropZoneUploading,
        setDropZoneUploading,
      }} />;
  };
}