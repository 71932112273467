import React from "react";
import MobileTopMenu from "components/templateParts/MobileTopMenu.js";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import FilterListIcon from "@material-ui/icons/FilterList";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { smartViewSidebarShow, smartViewSidebarHide } from "actions";

const useStyles = makeStyles((theme) => ({
  pageTitleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #C9C9C9",
    padding: `0 ${theme.mainLayout.paddingRight} 0 ${theme.mainLayout.paddingLeft}`,
    minHeight: "56px",
    boxSizing: "border-box",
  },

  pageTitle: {
    fontSize: "26.5px",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  controls: {
    display: "flex",
    margin: `0 -${theme.spacing(1)}px`,
  },
  margin: {
    margin: theme.spacing(1),
  },
  filterButton: {
    [theme.breakpoints.up("sm")]: {
      display: 'none',
    },
  },
}));

const MainHeader = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MobileTopMenu />
      <div className={classes.pageTitleRow}>
        <div className={classes.pageTitle}>{props.title}</div>
        <div className={classes.controls}>
          {props.showFilterButton && <Fab
            size="small"
            color="primary"
            aria-label="Filter"
            className={`${classes.filterButton} ${classes.margin}`}
            onClick={
              props.smartViewSidebarOpen
                ? props.smartViewSidebarHide
                : props.smartViewSidebarShow
            }
          >
            <FilterListIcon />
          </Fab>}
          {props.button ? props.button : null}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      smartViewSidebarShow,
      smartViewSidebarHide,
    },
    dispatch
  );

const mapStateToProps = ({ layout }) => ({
  smartViewSidebarOpen: layout.smartViewSidebarOpen,
});

MainHeader.defaultProps = {
  showFilterButton: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
