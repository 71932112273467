import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const GlassTubeIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M13,5.3c-0.4-0.4-1.1-0.4-1.5,0c-0.4,0.4-0.4,0.9-0.2,1.3L1,16.9c-1.4,1.4-1.4,3.7,0,5.1c1.4,1.4,3.7,1.4,5.1,0
	l10.3-10.3c0.4,0.2,0.9,0.2,1.3-0.2c0.4-0.4,0.4-1.1,0-1.5L13,5.3z M14.5,11l-0.9-0.9c-0.2-0.2-0.6-0.2-0.8,0
	c-0.2,0.2-0.2,0.6,0,0.8l0.9,0.9l-1.8,1.8L11,12.7c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8l0.9,0.9l-6.2,6.2
	c-0.7,0.7-1.9,0.7-2.6,0c-0.7-0.7-0.7-1.9,0-2.6L12.5,7.9l2.6,2.6L14.5,11z M2.9,18.8c-0.3,0.3-0.3,0.9,0,1.3c0.3,0.3,0.9,0.3,1.3,0
	l4.2-4.2l-1.3-1.3L2.9,18.8z M23.7,1.9c-0.5-0.5-1.2-0.5-1.7,0c-0.5,0.5-0.5,1.2,0,1.7c0.5,0.5,1.2,0.5,1.7,0
	C24.2,3.1,24.2,2.3,23.7,1.9z M17.2,5.3c0.1-0.1,0.2-0.3,0.3-0.4c0.5-0.5,1.2-0.7,1.8-0.6c0.5-0.8,0.4-2-0.3-2.7
	c-0.8-0.8-2.2-0.8-3.1,0c-0.8,0.8-0.8,2.2,0,3.1C16.3,5,16.7,5.2,17.2,5.3z M17.8,5.1c-0.7,0.7-0.7,1.7,0,2.4c0.7,0.7,1.7,0.7,2.4,0
	c0.7-0.7,0.7-1.7,0-2.4C19.5,4.5,18.4,4.5,17.8,5.1z"/>
    </SvgIcon>
);

export default GlassTubeIcon;