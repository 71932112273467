import React from 'react';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import styles from './search.module.scss';


function Search(props) {
  const DEFAULT_TIMEOUT = 500
  let timer = null

  const onChange = (event)=> {
    let value = event.target.value
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(()=>{props.callback(value)}, DEFAULT_TIMEOUT)
  }

  function renderLabel() {
    return (
      <div className={styles.label}>
        <SearchIcon />
        <span>Search</span>
      </div>
    )
  }

  return (
    <div className={styles.searchWrap}>
      <TextField
        className={styles.input}
        label={renderLabel()}
        type="search"
        autoComplete="Search"
        onChange={onChange}
        margin="normal"
      />
    </div>
  );
}

export {
  Search
}