import React from "react";
import helpers from "utils/helpers";
import IconTooltip from "components/ui_components/IconTooltip/IconTooltip.js";
import { IconValueWarning } from "components/ui_components/table/IconValue";
import { getSalesRepColumnVal } from "config/resources/requisitionsResource/tableCol";

let yup = require("yup");

const labIdsInput = {
  label: "Performing Laboratories",
  type: "autocomplete",
  resource: "labs",
  subType: "multi",
  yup: helpers.getYupRequired("Performing Laboratories"),
  accessor: (d) => {
    return { value: d.id, label: d.labName };
  },
};

const salesIdInput = {
  label: "Sales Rep.",
  type: "autocomplete",
  subType: "multi",
  autocompleteOptions: {
    isClearable: true,
  },
  selectProps: { menuPlacement: "top" },
  resource: "sales",
  filter: { salesTypeCode: "SLS" },
  accessor: (d) => ({
    value: d.id,
    label: `${d.salesFirstName} ${d.salesLastName} ${
      d.salesRepCode !== null ? " - " + d.salesRepCode : ""
    }`,
  }),
};

export const clientsResource = {
  tableColConfig: [
    {
      id: "legacyClientId",
      label: "Client ID",
    },
    {
      id: "clientName",
      label: "Name",
    },
    {
      id: "salesJson",
      label: "Sales Rep",
      accessor: (d) => getSalesRepColumnVal(d.salesJson)
    },
    {
      id: "clientFaxEmail",
      label: "Fax",
    },
    {
      id: "clientPhone",
      label: "Phone Number",
    },
    {
      id: "clientTz",
      label: "Timezone",
    },
    {
      id: "partialReport",
      label: "",
      accessor: (d) => {
        return (
          d.clientTz === "TZNA" && (
            <IconTooltip title="Unable to calculate client’s timezone.  Please correct the client’s city, state and country.">
              <IconValueWarning />
            </IconTooltip>
          )
        );
      },
    },
  ],

  editConfig: {
    inputsById: [
      "clientName",
      "clientTransmitMethodCode",
      "clientBillTypeCode",
      "clientAddr1",
      "clientAddr2",
      "clientCountryId",
      "clientCity",
      "clientZip",
      "clientStateId",
      "clientPhone",
      "clientEmail",
      "clientFaxEmail",
      "clientDeliveryNote",
      "clientNote",
      "colaId",
      "serviceId",
      "clientReqTypeCode",
      "clientName2",
      "clientPhone2",
      "clientEmail2",
      "isContractPhleb",
      "clientPortalPrefCode",
    ],

    inputsGroupsByHash: {
      group1: {
        title: "Info",
        ids: [
          ["colaId", "clientName", "clientPhone", "clientEmail"],
          ["clientAddr1", "clientAddr2", "clientCity"],
          ["clientCountryId", "clientStateId", "clientZip"],
          ["clientNote", "isContractPhleb"],
        ],
        inputStyles: `width: 25%;`,
      },
      group2: {
        title: "Secondary Client Information",
        ids: [
          [
            "clientName2",
            "clientPhone2",
            "clientEmail2",
            "clientPortalPrefCode",
          ],
        ],
        inputStyles: `width: 25%;`,
      },
      group3: {
        title: "Delivery Method",
        ids: [
          [
            "clientFaxEmail",
            "clientReqTypeCode",
            "clientTransmitMethodCode",
            "clientBillTypeCode",
          ],
          ["clientDeliveryNote"],
        ],
        inputStyles: `width: 25%;`,
      },
      group4: {
        title: "Related Sales Information",
        ids: [["serviceId"]],
        inputStyles: `width: 25%;`,
      },
    },
  },

  createConfig: {
    inputsById: [
      "clientName",
      "clientTransmitMethodCode",
      "clientBillTypeCode",
      "clientAddr1",
      "clientAddr2",
      "clientCountryId",
      "clientCity",
      "clientZip",
      "clientStateId",
      "clientPhone",
      "clientEmail",
      "clientFaxEmail",
      "clientDeliveryNote",
      "clientNote",
      "colaId",
      "salesIds",
      "serviceId",
      "clientReqTypeCode",
      "clientName2",
      "clientPhone2",
      "clientEmail2",
      "isContractPhleb",
      "clientPortalPrefCode",
      "labIds",
    ],

    inputsGroupsByHash: {
      group1: {
        title: "Info",
        ids: [
          ["colaId", "clientName", "clientPhone", "clientEmail"],
          ["clientAddr1", "clientAddr2", "clientCity"],
          ["clientCountryId", "clientStateId", "clientZip"],
          ["clientNote", "isContractPhleb"],
        ],
        inputStyles: `width: 25%;`,
      },
      group2: {
        title: "Secondary Client Information",
        ids: [
          [
            "clientName2",
            "clientPhone2",
            "clientEmail2",
            "clientPortalPrefCode",
          ],
        ],
        inputStyles: `width: 25%;`,
      },
      group3: {
        title: "Delivery Method",
        ids: [
          [
            "clientFaxEmail",
            "clientReqTypeCode",
            "clientTransmitMethodCode",
            "clientBillTypeCode",
          ],
          ["clientDeliveryNote"],
        ],
        inputStyles: `width: 25%;`,
      },
      group4: {
        title: "Related Sales Information",
        ids: [["salesIds", "serviceId", "labIds"]],
        inputStyles: `width: 25%;`,
      },
    },

    inputsGroupsById: ["group1", "group3", "group2", "group4"],

    inputsByHash: {
      clientReqTypeCode: {
        label: "Requisition Type",
        type: "autocomplete",
        resource: "lovs/CLIENTREQTYPE",
        accessor: (d) => ({
          value: d.clientReqTypeCode,
          label: d.clientReqTypeDesc,
        }),
      },
      clientName2: {
        label: "Name",
      },
      clientPhone2: {
        label: "Phone",
      },
      clientEmail2: {
        label: "Email",
      },

      clientTransmitMethodCode: {
        label: "Test Result Delivery Method",
        type: "autocomplete",
        resource: "lovs/TRANSMITMETHOD",
        accessor: (d) => ({
          value: d.transmitMethodCode,
          label: d.transmitMethodDesc,
        }),
        yup: yup
          .string()
          .required("Test Result Delivery Method is required")
          .nullable("Test Result Delivery Method is required"),
      },
      clientBillTypeCode: {
        label: "Bill To",
        type: "autocomplete",
        resource: "lovs/BILLTYPE",
        yup: helpers.getYupRequired("Bill To"),
        accessor: (d) => ({ value: d.billTypeCode, label: d.billTypeDesc }),
      },
      clientName: {
        label: "Client Name",
        yup: helpers.getYupRequired("Name"),
      },
      clientAddr1: {
        label: "Address 1",
        yup: yup
          .string()
          .required("Address 1 is required")
          .nullable("Address 1 is required"),
      },
      clientAddr2: {
        label: "Address 2",
      },
      clientCountryId: {
        label: "Country",
        type: "autocomplete",
        resource: "countries",
        relativeInputs: ["clientStateId"],
        resetRelativeInputs: ["clientStateId"],
        accessor: (d) => ({ value: d.id, label: d.countryLongName }),
        yup: yup
          .string()
          .required("Country is required")
          .nullable("Country is required"),
      },
      clientCity: {
        label: "City",
        yup: yup
          .string()
          .required("City is required")
          .nullable("City is required"),
      },
      clientZip: {
        label: "Zip",
        yup: yup
          .string()
          .required("Zip is required")
          .nullable("Zip is required"),
      },
      clientStateId: {
        label: "State",
        type: "autocomplete",
        relativeInputResourceAccessor: (d) => {
          if (!d.hasOwnProperty("clientCountryId")) {
            throw new Error();
          }
          return `countries/${d.clientCountryId}/states`;
        },
        accessor: (d) => ({ value: d.id, label: d.stateLongname }),
        yup: yup
          .string()
          .required("State is required")
          .nullable("State is required"),
      },
      clientPhone: {
        label: "Phone",
        yup: yup
          .string()
          .required("Phone is required")
          .nullable("Phone is required"),
      },
      clientEmail: {
        label: "Email",
      },
      clientFaxEmail: {
        label: "Secure Fax",
      },
      clientDeliveryNote: {
        label: "Delivery Notes",
        type: "multiline",
        styles: `width: 100%;`,
      },
      clientNote: {
        label: "Client Notes",
        type: "multiline",
        styles: `width: 100%;`,
      },
      colaId: {
        label: "Collaborator",
        type: "autocomplete",
        resource: "collaborators",
        yup: helpers.getYupRequired("Collaborator"),
        accessor: (d) => ({ value: d.id, label: d.colaName }),
        setInitValueFromData: {
          inputKey: "colaName",
          outputKey: "id",
          value: "Predictive Health Diagnostics Company",
        },
      },
      salesIds: {
        ...salesIdInput,
      },
      serviceId: {
        label: "Service Rep.",
        type: "autocomplete",
        autocompleteOptions: {
          isClearable: true,
        },
        selectProps: { menuPlacement: "top" },
        resource: "sales",
        filter: { salesTypeCode: "SVC" },
        accessor: (d) => ({ value: d.id, label: d.salesFirstName }),
      },
      isContractPhleb: {
        label: "Contracted Phlebotomist",
        type: "radio",
        options: [
          { value: 1, label: "Yes" },
          { value: "0", label: "No" },
        ],
        value: "0",
      },
      clientPortalPrefCode: {
        label: "Patient Portal Preferences",
        type: "autocomplete",
        resource: "lovs/PORTALPREF",
        accessor: (d) => ({
          value: d.portalPrefCode,
          label: d.portalPrefDesc,
        }),
      },
      labIds: labIdsInput,
    },
  },
};

export const createLabConfig = {
  inputsById: ["labIds"],
  inputsByHash: {
    labIds: {
      ...labIdsInput,
      yup: helpers.getYupRequired("Performing Laboratories"),
    },
  },
  inputsGroupsByHash: {
    group1: {
      ids: [["labIds"]],
    },
  },

  inputsGroupsById: ["group1"],
};

export const createSalesRepsConfig = {
  inputsById: ["salesIds"],
  inputsByHash: {
    salesIds: {
      ...salesIdInput,
      yup: helpers.getYupRequired("Sales Rep."),
    },
  },
  inputsGroupsByHash: {
    group1: {
      ids: [["salesIds"]],
    },
  },

  inputsGroupsById: ["group1"],
};
