import React from 'react';
import SmartInput from 'components/ui_components/smart_input'
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const defaultInputWrapStyles = `
  margin: 20px 0 0;
  box-sizing: border-box;
  padding: 0 10px 0;
  width: 33.3333%;
  
  >div {
    width: 100%;
  }
`

const InputWrap = styled.div`
 &&{
    ${defaultInputWrapStyles}
    ${props => props.styles}
  }
`;

const InputList = styled.div`
 &&{
    margin: 0 -10px;
    ${props => props.styles}
  }
`;

const BaseForm = (props) => {
  const { inputsById, inputsByHash, handleInputs, readOnly, listStyles } = props;
  return (
    <InputList styles={listStyles}>
      {inputsById.map((inputId, index) => {
        if (!inputsByHash.hasOwnProperty(inputId)) {
          console.error(`${inputId} - missing in inputsByHash or inputsById`)
          return null;
        }
        const input = inputsByHash[inputId];

        let params = {
          inputId,
          input,
          handleInputs,
          autoComplete: input.autocomplete,
        };
        let inputWrapStyles = ``;

        if (props.inputWrapStyles) inputWrapStyles += props.inputWrapStyles;
        if (input.inputWrapStyles) inputWrapStyles += input.inputWrapStyles;
        if (input.inputStyles) inputWrapStyles+= input.inputStyles;

        return (
          <InputWrap
            key={index}
            styles={inputWrapStyles}
          >
            {readOnly ?
              <TextField
                key={inputId}
                name={inputId}
                defaultValue={input.value}
                required
                label={input.error ? input.errorMessage : input.label}
                InputProps={{
                  readOnly: true
                }}
              /> :
              <SmartInput key={index} {...params} />
            }
          </InputWrap>
        )
      })}
    </InputList>
  )
}

BaseForm.defaultProps = {
  inputsByHash: {},
  inputsById: [],
  handleInputs: () => {},
  readOnly: false,
  inputWrapStyles: null,
  listStyles: '',
};

export default BaseForm;