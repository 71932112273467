import React, { Fragment, useState } from "react";
import { TableHeaderAddButton } from "components/ui_components/buttons";
import DropZoneModal from "components/DropZoneModal/DropZoneModal.js";
import useDropZone from "hooks/useDropZone.js";
import useModal from "hooks/useModal.js";
import uploadFiles from "utils/uploadFiles.js";
import { useNotificationCenter } from "NotificationCenter";

const UploadButton = ({ children, initResource, finishResource, onSubmitOk }) => {
  const [dropZoneError, dropZoneFiles, handleDropFiles] = useDropZone();
  const { modalIsOpen, handleCloseModal, handleOpenModal } = useModal();
  const [isUploading, setIsUploading] = useState(false);
  const notificationCenter = useNotificationCenter();

  const handleSubmitUpload = ({ dropZoneFiles }) => {
    setIsUploading(true);
    uploadFiles({
      initResource: initResource,
      finishResource: finishResource,
      dropZoneFiles: dropZoneFiles,
    })
      .then((data) => {
        setIsUploading(false);
        handleCloseModal();
        onSubmitOk();
        notificationCenter.show(data.message, "info");
      })
      .catch((error) => {
        setIsUploading(false);
        notificationCenter.show(error.message, "error");
      });
  };

  return (
    <Fragment>
      <DropZoneModal
        modalOpen={modalIsOpen}
        handleSubmit={() => {
          handleSubmitUpload({ dropZoneFiles });
        }}
        handleDropFiles={handleDropFiles}
        handleCloseDropZone={handleCloseModal}
        isUploading={isUploading}
        dropZoneFiles={dropZoneFiles}
        error={dropZoneError}
      />
      <TableHeaderAddButton onClick={handleOpenModal}>
        {children}
      </TableHeaderAddButton>
    </Fragment>
  );
};

export default UploadButton;
