import React, { useState } from 'react';
import dataProvider from "providers/dataProvider"
import { DOWNLOAD_FILE_GET } from 'providers/dataProvider';
import { useNotificationCenter } from 'NotificationCenter';
import {
  Delivery2Icon
} from 'icons'
import { TableHeaderAddButton } from 'components/ui_components/buttons';
import PropTypes from 'prop-types';

const ExportAllButton = ({ resource }) => {
  const [downloadReportsInProgress, setDownloadReportsInProgress] = useState(false);
  const notificationCenter = useNotificationCenter();

  const handleDownloadReport = () => {
    setDownloadReportsInProgress(true);
    dataProvider(DOWNLOAD_FILE_GET, resource).then(({ data }) => {
      window.open(data.url);
      notificationCenter.show(
        'If download failed, please click here',
        'info',
        'Download',
        () => {
        },
        data.url
      )
      setDownloadReportsInProgress(false);
    }).catch(error => {
      notificationCenter.show(error.message, 'warning')
      setDownloadReportsInProgress(false);
    });
  }

  return (
    <TableHeaderAddButton
      icon={<Delivery2Icon/>}
      onClick={handleDownloadReport}
      showProgressBar={downloadReportsInProgress}
    >
      Export All
    </TableHeaderAddButton>
  )
}

export default ExportAllButton;

ExportAllButton.propTypes = {
  resource: PropTypes.string.isRequired
}