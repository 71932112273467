import React, { useState } from 'react'
import styles from './AddNoteContainer.module.scss';
import clsx from "clsx";
import { compose } from 'redux'
import { withNotificationCenter } from 'NotificationCenter'
import withForm from 'withForm';
import SmartInput from 'components/ui_components/smart_input'
import dataProvider, { CREATE } from "providers/dataProvider";
import { WidgetType1 } from 'components/ui_components/Widgets';
import { WidgetControlButton } from 'components/ui_components/buttons';

const AddNoteContainer = (props) => {
  const [dataLoading, setDataLoading] = useState(false);

  const { notificationCenter, isFormValid, getSubmitData, resetErrors, reqID, handleSuccessfullySubmit, clearInputs } = props;
  const { handleInputs, inputsById, inputsByHash } = props;

  const handleSubmit = () => {
    if (isFormValid()) {
      resetErrors();
      setDataLoading(true);

      dataProvider(CREATE, `requisitions/${reqID}/lab_notes`, { data: getSubmitData() })
      .then((response) => {
        setDataLoading(false);
        notificationCenter.show('Successfully created', 'info')
        handleSuccessfullySubmit();
        clearInputs();
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
        setDataLoading(false);
      });
    }
  }

  return (
    <div className={styles.root}>
      <WidgetType1
        title="Lab Notes"
        content={<div className={styles.inputList}>
          {inputsById.map((inputId, index) => {
            const input = inputsByHash[inputId];
            let params = { inputId, input, handleInputs }
            return (
              <div key={index}
                   className={clsx([styles[`inputWrap-${inputId}`]], [styles.inputWrap])}>
                <SmartInput {...params}/>
              </div>
            )
          })}
        </div>}
        showProgressBar={dataLoading}
        headerRightContent={<WidgetControlButton
          variant="contained"
          onClick={handleSubmit}
        >
          Add
        </WidgetControlButton>}
      />
    </div>
  );
}

export default compose(
  withNotificationCenter(),
  withForm()
)(AddNoteContainer)