import React from "react";
import ViewFiltersButton from "../ViewFiltersButton";
import SearchInput from "../SearchInput";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { smartViewSidebarShow, smartViewSidebarHide } from "actions";
import SearchTags from "./SearchTags/SearchTags";

const styles = (theme) => ({
  root: {
    padding: `.7rem ${theme.mainLayout.paddingRight} .7rem ${theme.mainLayout.paddingLeft}`,
    borderBottom: "1px solid #C9C9C9",
    display: "flex",
    alignItems: "center",
  },
  viewFiltersButtonWrap: {
    margin: "0  1rem 0 0",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searchInputWrap: {
    margin: "13px 0 0",
    width: "100%",
    maxWidth: "378px",
    padding: "0 0 0 10px",
    position: "relative",
    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      background: "#C9C9C9",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      width: "1px",
      height: "110%",
    },
  },
});

const SearchBar = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div
        className={classes.viewFiltersButtonWrap}
        onClick={
          props.smartViewSidebarOpen
            ? props.smartViewSidebarHide
            : props.smartViewSidebarShow
        }
      >
        <ViewFiltersButton />
      </div>

      {props.isShowSearchInput && (
        <div className={classes.searchInputWrap}>
          <SearchInput className={classes.searchInput} />
        </div>
      )}

      <div className={classes.searchTagsWrap}>
        <SearchTags />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      smartViewSidebarShow,
      smartViewSidebarHide,
    },
    dispatch
  );

const mapStateToProps = ({ layout }) => ({
  smartViewSidebarOpen: layout.smartViewSidebarOpen,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SearchBar));

SearchBar.defaultProps = {
  isShowSearchInput: false,
};
