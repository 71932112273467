import React from 'react';
import { BanIcon } from 'icons'
import styles from './NotCheckedCell.module.scss';

const NotCheckedCell = React.forwardRef((props, ref) =>
  <span {...props} ref={ref}>
      <BanIcon className={styles.root}/>
  </span>
);


export default NotCheckedCell;