import React, { PureComponent } from 'react'
import dataProvider, { BULK_DELETE, GET_LIST } from "providers/dataProvider";
import { BaseTable } from 'components/ui_components/table'
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar'
import { remove } from 'lodash';
import styles from './SmartTable.module.scss';
import clsx from "clsx";
import { withAuthCenter } from 'AuthCenter';
import withModal from 'withModal'
import { compose } from 'redux'
import DeleteConfirmModal
  from 'components/ui_components/table/DeleteConfimModal/DeleteConfirmModal';
import { withNotificationCenter } from 'NotificationCenter'
import VisibilitySensor from "react-visibility-sensor";
import withExtraDataLoader from '../../../hocs/withExtraDataLoader';
import authStorage from 'providers/authStorage.js';
import filterArrayConfigByPermissions from 'utils/filterArrayConfigByPermissions'
import axios from 'axios';

class SmartTable extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props)

    this.CancelToken = axios.CancelToken;
    this.source = this.CancelToken.source();

    const access = authStorage.getAccess();

    this.state = {
      rows: [],
      page: 1,
      tableKey: null,
      selectedRowsObjects: props.defaultSelected,
      orderBy: this.props.orderBy,
      order: this.props.order,
      dataLoading: true,
      perPage: this.props.perPage,
      actionDeleteInProgress: false,
      columns: filterArrayConfigByPermissions(this.props.columns, access.userRoleCode),
      visibility: false,
    }

    this.visibilityTimer = null;

    this.filter = (data) => {
      return data
    }

    this.dataLoadedByVisibility = false;
  }

  componentDidMount() {
    this._isMounted = true;

    if (!this.props.extraDataLoading && !this.props.hasExternalInitData && !this.props.externalDataLoading) {
      if (this.state.visibility) {
        this.loadData()
      }
    }

    this.props.onRef(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.onRef(undefined)
    this.cancelLoadData();
  }

  componentDidUpdate(prevProps) {
    let filterChanged = JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)
    let searchStringChanged = prevProps.searchString !== this.props.searchString
    let columns = JSON.stringify(prevProps.columns) !== JSON.stringify(this.props.columns)

    if (filterChanged && !this.props.extraDataLoading) {
      this.setState({
        page: 1,
      })
      this.loadData({page: 1})
    }

    if ((searchStringChanged || columns) && !this.props.extraDataLoading) {
      if (this.state.visibility) {
        this.loadData()
      }
    }
    if (prevProps.authCenter.token !== this.props.authCenter.token) {
      if (this.state.visibility) {
        this.loadData()
      }
    }
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({
        rows: this.props.data,
      })
    }
  }

  cancelLoadData(){
    this.source.cancel("Smart table request canceled");
    this.source = this.CancelToken.source();
  }

  loadData = (options = {}) => {
    const { page = null } = options;
    this.cancelLoadData();
    this.setState({
      dataLoading: true,
      rows: []
    })
    let search = {}
    if (this.props.searchString && this.props.searchString !== '') {
      search[this.props.searchBy] = this.props.searchString
    }
    dataProvider(GET_LIST, this.props.resource, {
      filter: { ...this.props.filter, ...search },
      pagination: {
        page: page || this.state.page,
        perPage: this.state.perPage
      },
      sort: {
        field: this.state.orderBy,
        order: this.state.order
      },
    }, {
      cancelToken: this.source.token
    })
    .then(({ data, total }) => {
      if (this._isMounted) {
        let columns = [];

        if (this.props.columns.length > 0) {
          const access = authStorage.getAccess();
          columns = filterArrayConfigByPermissions(this.props.columns, access.userRoleCode)
        }
        else if(data[0]){
          for (const [key] of Object.entries(data[0])) {
            columns.push({
              id: key,
              label: key,
            })
          }
        }




        this.props.dataIsLoaded(data)
        this.setState({
          rows: data,
          columns: columns,
          total,
          dataLoading: false,
          selectedRowsObjects: []
        })
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }

  handleClickDelete = () => {
    this.props.handleOpenModal();
  }

  deleteSelectedRows = () => {
    if (this.props.deleteCallback) {
      this.props.deleteCallback(this.state.selectedRowsObjects, this.props.resource, this.loadData)
    } else {
      let ids = this.state.selectedRowsObjects.map((i) => (i[this.props.deleteByKey]))

      this.setState({
        actionDeleteInProgress: true,
      })

      dataProvider(BULK_DELETE,
        this.props.deleteResource ? this.props.deleteResource : this.props.resource,
        { id: ids },
      )
      .then(() => {
        this.props.notificationCenter.show('Successfully deleted', 'info')
        this.setState({
          actionDeleteInProgress: false,
        })
        this.loadData();
      })
      .catch(error => {
        this.props.notificationCenter.show(error.message, 'error')
        this.setState({
          actionDeleteInProgress: false
        })
      });
    }
  }

  handleRowClick = (event, index, row) => {
    this.props.handleRowClick(event, index, row)
  }

  handleSetPerPage = (event) => {
    let perPage = event.target.value
    let page = 1
    this.setState({
      perPage,
      page
    }, this.loadData)
  }

  handleSetPage = (event, page) => {
    let newPage = page + 1
    this.setState({
      page: newPage
    }, this.loadData)
  }

  setPage = (event, page) => {
    let newPage = page + 1
    this.setState({
      page: newPage
    })
  }


  handleSetSort = (orderBy) => {
    const isDesc = this.state.orderBy === orderBy && this.state.order === 'desc'
    let order = isDesc ? 'asc' : 'desc'

    this.setState({
      orderBy: orderBy,
      order: order
    }, this.loadData)
  }

  handleSelectRow = (event, selectedRow) => {
    const currentSelectedRowsObjects = this.props.singleRowSelect ? [] : [...this.state.selectedRowsObjects]
    const rowSelected = this.state.selectedRowsObjects.some(row => row.id === selectedRow.id)

    if (rowSelected) {
      remove(currentSelectedRowsObjects, e => e.id === selectedRow.id)
    } else {
      currentSelectedRowsObjects.push(selectedRow);
    }

    this.setState({
      selectedRowsObjects: currentSelectedRowsObjects
    }, () => {
      this.props.handleSelectRowCallBack(currentSelectedRowsObjects)
    })
  }

  handleSelectAllRows = (event) => {
    let currentSelectedRowsObjects = []

    if (event.target.checked) {
      currentSelectedRowsObjects = [...this.state.rows]
    }
    this.setState({
        selectedRowsObjects: currentSelectedRowsObjects
      }, () => [
        this.props.handleSelectRowCallBack(currentSelectedRowsObjects)
      ]
    )
  }

  onChangeVisibility = (isVisible) => {
    clearTimeout(this.visibilityTimer);
    this.visibilityTimer = setTimeout(() => {
      if (isVisible && !this.dataLoadedByVisibility) {
        this.dataLoadedByVisibility = true;
        this.loadData();
      }
      this.setState({
        visibility: true
      })
    }, 500)
  }

  render() {
    const { editableTable, collapsibleTable, modalOpen, handleCloseModal } = this.props
    const filteredCustomRowActionsByPermissions = filterArrayConfigByPermissions(this.props.customRowActions, this.props.authCenter.access.userRoleCode);
    const filteredCustomToolbarActionsByPermissions = filterArrayConfigByPermissions(this.props.customToolbarActions, this.props.authCenter.access.userRoleCode);

    return (

      <VisibilitySensor onChange={this.onChangeVisibility} partialVisibility={true}>
        <div className={clsx([this.props.className, styles.root])}>
          <DeleteConfirmModal
            modalOpen={modalOpen}
            handleCloseModal={handleCloseModal}
            handleConfirmCallback={this.deleteSelectedRows}
            deleteConfirmationMessagePostfix={this.props.deleteConfirmationMessagePostfix}
            resource={this.props.resource}
            numberOfItems={this.state.selectedRowsObjects.length}
          />
          {(this.props.tableIsReloading || this.state.dataLoading || this.props.externalDataLoading || this.props.isExtraDataLoading) && <ProgressBar/>}
          <BaseTable
            collapsibleTableContentType={this.props.collapsibleTableContentType}
            collapsibleTableProps={this.props.collapsibleTableProps}
            dataLoading={this.state.dataLoading}
            order={this.state.order}
            orderBy={this.state.orderBy}
            columns={this.state.columns}
            rows={this.state.rows}
            isSubTable={this.props.isSubTable}
            subTableConfig={this.props.subTableConfig}
            extraData={this.props.extraData}
            subTableData={this.props.subTableData}
            rowAccessorExtraData={this.props.rowAccessorExtraData}
            handleClickDelete={this.handleClickDelete}
            deleteButtonIcon={this.props.deleteButtonIcon}
            handleRowClick={this.handleRowClick}
            handleSetPage={this.handleSetPage}
            handleSetSort={this.handleSetSort}
            handleSetPerPage={this.handleSetPerPage}
            stickyHeader={this.props.stickyHeader}
            smartTable={{
              loadData: this.loadData
            }}
            showSelectAll={this.props.showSelectAll}

            selectedRowsObjects={this.state.selectedRowsObjects}
            handleSelectRow={this.handleSelectRow}
            handleSelectAllRows={this.handleSelectAllRows}

            handleSubTableRowsInputData={this.props.handleSubTableRowsInputData}
            editableTable={editableTable}
            collapsibleTable={collapsibleTable}

            showSelect={this.props.showSelect}
            showDelete={this.props.showDelete}
            showPagination={this.props.showPagination}

            total={this.state.total}
            page={this.state.page}
            perPage={this.state.perPage}
            parentRow={this.props.parentRow}
            customToolbarActions={filteredCustomToolbarActionsByPermissions}
            customRowActions={filteredCustomRowActionsByPermissions}
            toolbarExtraComponents={this.props.toolbarExtraComponents}
            actionDeleteInProgress={this.state.actionDeleteInProgress}
          />
        </div>
        </VisibilitySensor>

    )
  }
}

export default compose(
  withModal,
  withAuthCenter(),
  withNotificationCenter(),
  withExtraDataLoader(),
)(SmartTable);

SmartTable.defaultProps = {
  onRef: () => {},
  collapsibleTableProps: {
    isVisibleShowButtonAccessor: (i) => true
  },
  deleteConfirmationMessagePostfix: 'item',
  defaultSelected: [],
  handleSelectRowCallBack: (i) => {},
  handleRowClick: (i) => {},
  dataIsLoaded: (i) => {},
  columns: [],
  collapsibleTable: false,
  isSubTable: false,
  deleteButtonIcon: null,
  extraData: {},
  subTableData: {},
  rowAccessorExtraData: {},
  showSelect: true,
  showSelectAll: true,
  singleRowSelect: false,
  showDelete: true,
  showPagination: true,
  customRowActions: [],
  customToolbarActions: [],
  total: 0,
  page: 1,
  perPage: 25,
  orderBy: 'id',
  order: 'asc',
  selected: [],
  searchString: null,
  searchBy: null,
  deleteCallback: null,
  deleteByKey: 'id',
  deleteResource: null,
  extraDataLoading: false,
  externalDataLoading: false,
  hasExternalInitData: false,
  data: [],
  stickyHeader: false,
}