import React, { PureComponent } from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import SortAndFilter from "./SortAndFilter";
import SmartViews from "./SmartViews";
import { bindActionCreators } from "redux";
import { smartViewSidebarSwitchTab } from "actions";
import style from "./SmartViewsSidebar.module.scss";
import { withSmartFilter } from "SmartFilterProvider";
import withFormV2 from "hocs/withFormV2";
import { compose } from "redux";
import urlQuery from "urlQuery";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#4A5BB9",
    borderRadius: "5px",
    height: "4px",
  },
})((props) => (
  <Tabs
    {...props}
    variant="fullWidth"
    TabIndicatorProps={{ children: <div /> }}
  />
));

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    flexGrow: 1,
    maxWidth: "none",
    flexBasis: 0,
    flexShrink: 1,
    minHeight: "55px",
    minWidth: "auto",

    "& .MuiTab-wrapper": {
      fontSize: "1.2em",
      lineHeight: "22px",
    },
  },
  selected: {
    color: "#4A5BB9",
  },
}))((props) => <Tab {...props} />);

const styles = (theme) => ({
  smartViewSidebar: {
    overflow: "hidden",
    height: "100%",
  },
  smartViewSidebarOpen: {
    minWidth: theme.smartViewSidebar.width,
    width: theme.smartViewSidebar.width,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      width: '100%',
    }
  },
  smartViewSidebarClose: {
    width: "0",
  },
  smartViewSidebarInner: {
    minWidth: theme.smartViewSidebar.width,
    width: theme.smartViewSidebar.width,
    margin: "1rem 0 0",
    border: "1px solid #C9C9C9",
    boxSizing: "border-box",
    backgroundColor: "white",
    height: "100%",
    overflow: "auto",
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      width: '100%',
    }
    
  },
  formTitle: {
    fontSize: "18px",
    fontWeight: "700",
    padding: "17px 0 0",
  },
  formControl: {
    padding: "15px 0 0",
    width: "100%",
  },
  formLabel: {
    margin: "0 0 6px",
    fontSize: "16px",
  },
  formButtonGroup: {
    display: "flex",
    margin: "0 -6px",
    padding: "23px 0 0",
    boxSizing: "border-box",
  },
  formButton: {
    margin: "0 6px",
  },
});

class SmartViewsSidebar extends PureComponent {
  updateHeightResizeDelay = null;

  constructor(props) {
    super(props);
    this.filterConfig = this.props.smartFilterConfig;

    this.initTagsByHash = {};

    this.filterConfig.forEach((filter) => {
      this.initTagsByHash[filter.id] = {
        ...filter,
        inputValue: filter.type === "checkbox" ? [] : "",
        tagValue: "",
      };
    });

    this.rootRef = React.createRef();

    this.state = {
      height: 0,
    };
  }

  setValue = (event, newValue) => {
    this.props.smartViewSidebarSwitchTab(newValue);
  };

  handlePopStateChange = () => {
    this.setInputsFromUrlQuery();
  }

  setInputsFromUrlQuery = () => {
    if (urlQuery.getByKey("smartFilter")) {
      const values = urlQuery.getByKey("smartFilter");
      this.props.handleBulkInputs(values)
    }
    else {
      this.props.clearAllInputs();
    }
  }

  componentDidMount = () => {
    this.setInputsFromUrlQuery();
    this.props.smartFilter.init(this.props.smartFilterConfig);
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
    window.addEventListener("popstate", this.handlePopStateChange);
  };  

  updateHeight = () => {
    let self = this;
    clearTimeout(self.updateHeightResizeDelay);
    self.updateHeightResizeDelay = setTimeout(() => {
      self.setState({
        height:
          window.innerHeight - self.rootRef.current.getBoundingClientRect().top,
      });
    }, 500);
  };

  componentWillUnmount() {
    clearTimeout(this.updateHeightResizeDelay);
    window.removeEventListener("resize", this.updateHeight);
    window.removeEventListener("popstate", this.handlePopStateChange)
  }

  render() {
    const { classes, smartViewSidebarTabIndex } = this.props;

    return (
      <div
        className={style.root}
        ref={this.rootRef}
        style={{ height: this.state.height + "px" }}
      >
        <div
          className={clsx(classes.smartViewSidebar, {
            [classes.smartViewSidebarOpen]: this.props.smartViewSidebarOpen,
            [classes.smartViewSidebarClose]: !this.props.smartViewSidebarOpen,
          })}
        >
          <div className={classes.smartViewSidebarInner}>
            <StyledTabs
              value={smartViewSidebarTabIndex}
              onChange={this.setValue}
            >
              <AntTab label="Smart Views" />
              <AntTab label="Sort and Filter" />
            </StyledTabs>

            <div className={style.tabContainer}>
              {smartViewSidebarTabIndex === 0 && (
                <SmartViews
                  resource={this.props.resource}
                  smartViewSidebarOpen={this.props.smartViewSidebarOpen}
                  handleBulkInputs={this.props.handleBulkInputs}
                  clearAllInputs={this.props.clearAllInputs}
                />
              )}
              {smartViewSidebarTabIndex === 1 && (
                <SortAndFilter
                  smartFilterForm={{
                    inputsById: this.props.inputsById,
                    inputsByHash: this.props.inputsByHash,
                    handleInputs: this.props.handleInputs,
                    clearAllInputs: this.props.clearAllInputs,
                    getInputsByHash: this.props.getInputsByHash,
                  }}
                  resource={this.props.resource}
                />
              )}
            </div>
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = ({ layout }) => ({
  smartViewSidebarOpen: layout.smartViewSidebarOpen,
  smartViewSidebarTabIndex: layout.smartViewSidebarTabIndex,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // initSmartFilters,
      smartViewSidebarSwitchTab,
    },
    dispatch
  );

export default compose(
  withFormV2,
  withSmartFilter(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(SmartViewsSidebar)