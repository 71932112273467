import React, { Component } from "react";
import styles from "./DownloadUploadContainer.module.scss";
import { SmartTable } from "components/ui_components/table";
import { compose } from "redux";
import PropTypes from "prop-types";
import { billingTabConfig } from "config/resources/requisitionsResource/";
import { DownloadIcon } from "icons";
import withDropZone from "withDropZone";
import { withNotificationCenter } from "NotificationCenter";
import { TableHeaderAddButton } from "components/ui_components/buttons";
import DropZoneModal from "components/DropZoneModal/DropZoneModal";
import dataProvider, {
  UPLOAD_FILE,
  UPLOAD_FILE_S3,
  DOWNLOAD_FILE,
  CREATE,
  DELETE,
  GET_LIST,
} from "providers/dataProvider";
import { Delete } from "@material-ui/icons";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import WidgetType1 from "components/ui_components/Widgets/WidgetType1/WidgetType1";
import { withAuthCenter } from 'AuthCenter';
import filterArrayConfigByPermissions from 'utils/filterArrayConfigByPermissions';
import { userRoles } from 'config/userRoles';
import Can from "Can";
import { isMobile } from 'react-device-detect';
import { canByRoles } from "utils/permissions";

const { downloadUpload } = billingTabConfig;
const { tableConfig, sectionsConfig } = downloadUpload;

class DownloadUploadContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRows: [],
      isUploading: false,
      docTypeCode: null,
      reloadingTables: {},
      tableData: [],
      tableDataLoading: false,
    };
    this.smartTableRefs = [];
    this.isSalesRole = canByRoles(userRoles.sales);
    this.isViewReportLogic = isMobile;
  }

  getTablesData = () => {
    this.setState({
      tableDataLoading: true,
    });
    dataProvider(GET_LIST, `requisitions/${this.props.reqID}/docs`)
      .then((response) => {

        const tableData = {};
        
        response.data.forEach(item => {
          if (!tableData.hasOwnProperty(item.docTypeCode)) {
            tableData[item.docTypeCode] = []
          }
          tableData[item.docTypeCode].push(item)
        })

        this.setState({
          tableData: tableData,
          tableDataLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          tableDataLoading: false,
        });
        this.props.notificationCenter.show(error.message, "error");
      });
  };

  componentDidMount = () => {
    this.getTablesData();
  };

  handleDeleteClick = (rows) => {
    const { reqID } = this.props;

    const row = rows[0];

    this.setState(function (prevState, props) {
      return {
        reloadingTables: {
          ...prevState.reloadingTables,
          [row.docTypeCode]: true,
        },
      };
    });

    dataProvider(DELETE, `requisitions/${reqID}/docs/${row.id}`)
      .then((response) => {
        this.smartTableRefs[row.docTypeCode].loadData();

        this.setState(function (prevState, props) {
          return {
            reloadingTables: {
              ...prevState.reloadingTables,
              [row.docTypeCode]: false,
            },
          };
        });
      })
      .catch((error) => {
        this.props.notificationCenter.show(error.message, "error");
      });
  };

  handleDownloadClick = (rows) => {
    const { reqID, notificationCenter } = this.props;
    const resource = this.isViewReportLogic ? `requisitions/${reqID}/docs/${rows[0].id}/view` : `requisitions/${reqID}/download_docs/${rows[0].id}`

    dataProvider(
      DOWNLOAD_FILE,
      resource,
      { data: {} }
    )
      .then((response) => {

        const url = this.isViewReportLogic ? response.data : response.data.url;
        window.open(url);
        notificationCenter.showDownloadNotification(url);
      })
      .catch((error) => {
        this.props.notificationCenter.show(error.message, "error");
      });

    this.setState({
      selectedRows: rows,
    });
  };
  handleSubmitUpload = () => {
    const {
      reqID,
      notificationCenter,
      handleCloseDropZone,
      dropZoneFiles,
      isValidDropZone,
      resetDropZoneErrors,
    } = this.props;
    const { docTypeCode } = this.state;

    resetDropZoneErrors();
    if (!isValidDropZone()) return;

    this.setState({
      isUploading: true,
    });

    let downloadFile = {};
    let fileName = "";

    dropZoneFiles.forEach((file, index) => {
      if (index > 0) return;
      fileName = file.name;
      downloadFile = file;
    });

    const docTypeCodeUrlPostfix = {
      SCANREQ: "scanreqs",
      INSCARD: "inscards",
      ADDON: "addons",
      SCANEOB: "scaneobs",
      SCANINV: "scaninvoices",
      CHECK: "checks",
    };

    const gdbRequest = { fileName: fileName, docTypeCode: docTypeCode };

    dataProvider(UPLOAD_FILE, `requisitions/${reqID}/upload`, {
      data: gdbRequest,
    })
      .then((gdbResponse) => {
        const s3Request = { ...gdbResponse.data.fields };
        const newFileName = gdbResponse.data.newFileName;

        dataProvider(UPLOAD_FILE_S3, gdbResponse.data.url, {
          data: {
            ...s3Request,
            downloadFile,
          },
        })
          .then((s3Response) => {
            if (s3Response.status === 204) {
              dataProvider(
                CREATE,
                `requisitions/${reqID}/${docTypeCodeUrlPostfix[docTypeCode]}`,
                {
                  data: {
                    originalFileName: fileName,
                    fileName: newFileName,
                  },
                }
              )
                .then((createRepResponse) => {
                  this.smartTableRefs[docTypeCode].loadData();
                  notificationCenter.show("Successfully uploaded", "info");
                  handleCloseDropZone();
                  this.handleStopUploading();
                })
                .catch((error) => {
                  notificationCenter.show(error.message, "warning");
                  this.handleStopUploading();
                });
            }
          })
          .catch((error) => {
            notificationCenter.show(error.message, "error");
            this.handleStopUploading();
          });
      })
      .catch((error) => {
        notificationCenter.show(error.message, "error");
        this.handleStopUploading();
      });
  };

  handleStopUploading = () => {
    this.setState({
      isUploading: false,
    });
  };

  handleOpenDropZone = (docTypeCode) => {
    this.setState(
      {
        docTypeCode: docTypeCode,
      },
      () => {
        this.props.handleOpenDropZone();
      }
    );
  };

  render() {
    const {
      reqID,
      dropZoneOpen,
      handleCloseDropZone,
      handleDropFiles,
      dropZoneFiles,
      dropZoneError,
      title,
      showDelete,
      showUpload,
    } = this.props;
    const { isUploading } = this.state;

    let customRowActions = [
      {
        icon: this.isViewReportLogic ? <VisibilityOutlinedIcon/> : <DownloadIcon/>,
        handleOnClick: this.handleDownloadClick,
        tooltipText: this.isViewReportLogic ? "View report" : "Download reports",
      },
    ];

    showDelete &&
      customRowActions.push({
        icon: <Delete />,
        handleOnClick: this.handleDeleteClick,
        tooltipText: "Delete",
      });
    
    const filteredConfigByPermissions = filterArrayConfigByPermissions(sectionsConfig, this.props.authCenter.access.userRoleCode)

    const content = (
      <div className={styles.downloadSectionList}>
        {filteredConfigByPermissions.map((section, index) => {
          const isTableReloading =
            this.state.reloadingTables.hasOwnProperty(section.docTypeCode) &&
            this.state.reloadingTables[section.docTypeCode];
          
          let tableData = [];

          if (this.state.tableData.hasOwnProperty(section.docTypeCode)) {
            tableData = this.state.tableData[section.docTypeCode];
          }
          return (
            <div key={index} className={styles.downloadSectionListItem}>
              <h6 className="ttl ttl-6">{section.title}</h6>

              <div className={styles.tableWrap}>
                <SmartTable
                  resource={section.tableResourceAccessor({ reqID: reqID })}
                  filter={{ docTypeCode: section.docTypeCode }}
                  data={tableData}
                  showPagination={false}
                  showSelect={false}
                  showDelete={false}
                  showSelectAll={false}
                  tableIsReloading={isTableReloading}
                  externalDataLoading={this.state.tableDataLoading}
                  hasExternalInitData={true}
                  onRef={(ref) =>
                    (this.smartTableRefs[section.docTypeCode] = ref)
                  }
                  toolbarExtraComponents={
                    showUpload && (
                      <Can allowedRoles={[userRoles.admin, userRoles.management, userRoles.gdbstaff]}>
                        <TableHeaderAddButton
                          onClick={this.handleOpenDropZone.bind(
                            this,
                            section.docTypeCode
                          )}
                        >
                          Upload
                        </TableHeaderAddButton>
                      </Can>
                    )
                  }
                  columns={tableConfig}
                  customRowActions={customRowActions}
                />
              </div>
            </div>
          );
        })}
      </div>
    );

    return (
      <React.Fragment>
        {showUpload && (
          <DropZoneModal
            modalOpen={dropZoneOpen}
            handleSubmit={this.handleSubmitUpload}
            handleDropFiles={handleDropFiles}
            handleCloseDropZone={handleCloseDropZone}
            isUploading={isUploading}
            dropZoneFiles={dropZoneFiles}
            error={dropZoneError}
          />
        )}
        <WidgetType1
          title={title}
          content={content}
          wrapStyles={this.props.widgetWrapStyles}
        />
      </React.Fragment>
    );
  }
}

DownloadUploadContainer.propTypes = {
  reqID: PropTypes.string.isRequired,
};

DownloadUploadContainer.defaultProps = {
  title: "Downloads/Uploads",
  showDelete: true,
  showUpload: true,
  widgetWrapStyles: "",
};

export default compose(
  withAuthCenter(),
  withNotificationCenter(),
  withDropZone
)(DownloadUploadContainer);
