import authStorage from "providers/authStorage.js";
import { find } from "lodash";

const canByDepartments = (departments = []) => {
  if (departments.length === 0) return true;
  const userDepts = authStorage.getUserDepts();

  if (
    find(userDepts, (o) => {
      return departments.includes(o.deptCode);
    })
  ) {
    return true;
  } else {
    return false;
  }
};

const canByRoles = (roles = []) => {
  if (roles.length === 0) return true;

  const access = authStorage.getAccess();
  if (access && access.hasOwnProperty("userRoleCode")) {
    return roles.includes(access.userRoleCode);
  } else return false;
};

const canNotByRoles = (roles = []) => {
  return !canByRoles(roles);
};

export { canByDepartments, canByRoles, canNotByRoles };
