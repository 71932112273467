import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  rootSmall: {
    '& svg': {
      width: '16px',
      height: '16px'
    }
  },
  checked: {
    color: '#53C4CC',
  }
});

// Inspired by blueprintjs
export default function (props) {
  const classes = useStyles();

  return (
    <Checkbox
      classes={{
       root: props.size === 'small' && classes.rootSmall,
       checked: classes.checked
      }}
      color="default"
      {...props}
    />
  );
}