import React, { useState, useEffect } from 'react'
import styles from './ActivityContainer.module.scss';
import {SmartTable} from 'components/ui_components/table'
import {activityTabConfig} from 'config/resources/requisitionsResource'
import PropTypes from "prop-types";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledCheckbox from 'components/ui_components/StyledCheckbox/StyledCheckbox';

const {tableColConfig} = activityTabConfig;

let timer = null;
const ActivityContainer = (props) => {
    const [checked, setChecked] = useState(false);
    const [filter, setFilter] = useState(null);

    const applyFilterWithDelay = () => {
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
            setFilter(checked ? {reqActionCode: 'STATUS'} : null)
        }, 500);
    }

    const handleCheckBoxChange = () => {
        setChecked(!checked)
        applyFilterWithDelay();
    }

    useEffect(applyFilterWithDelay,[checked]);

    return (
        <div className={styles.root}>

            <div className={styles.main}>

                <FormControlLabel control={ <StyledCheckbox
                    checked={checked}
                    onChange={handleCheckBoxChange}
                    value="checked"
                />} label="Show only the Status change" />

                <SmartTable
                    columns={tableColConfig}
                    resource={`requisitions/${props.reqID}/req_hists`}
                    filter={filter}
                    showSelect={false}
                    showDelete={false}
                    showSelectAll={false}
                    showPagination={true}
                    collapsibleTableContentType={['activity']}
                    collapsibleTable={true}
                />
            </div>
        </div>
    );
}

ActivityContainer.propTypes = {
    reqID: PropTypes.string.isRequired
}

export default ActivityContainer;