import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import BaseForm from 'components/ui_components/BaseForm/BaseForm.js';
import PropTypes from 'prop-types';
import { BaseTable } from 'components/ui_components/table'
import styles from './PhysiciansCreateStep3Container.module.scss';

const PhysiciansCreateStep3Container = (props) => {
  const { inputsGroupsById, inputsGroupsByHash, classes, inputsById, inputsByHash, selectedClients, colConfig } = props;
  return (
    <div className={styles.root}>

      <BaseForm
        inputsById={inputsById}
        inputsByHash={inputsByHash}
        inputsGroupsById={inputsGroupsById}
        inputsGroupsByHash={inputsGroupsByHash}
        readOnly={true}
      />

      {selectedClients.length > 0 ?
        <div className={styles.selectedClients}>
          <h2 className={styles.title}>
            Associated Clients
          </h2>
          <div className={styles.tableWrap}>
            <BaseTable
              className={classes.table}
              columns={colConfig}
              rows={selectedClients}
              showSelect={true}
              readMode={true}
            />
          </div>
        </div> : null}
    </div>
  );
}

PhysiciansCreateStep3Container.propTypes = {
  inputsById: PropTypes.array.isRequired,
  inputsByHash: PropTypes.object.isRequired
}

export default (withStyles(styles)(PhysiciansCreateStep3Container))