import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LargeLogoIcon = (props) => (
  <SvgIcon width="100%" viewBox="0 0 379 96" fill="none" {...props}>
    <path d="M64 67.2L48 57.6L32 67.2V86.4L48 96L64 86.4V67.2Z" fill="url(#paint0_linear)"/>
    <path d="M32 48L16 38.4L0 48V67.2L16 76.8L32 67.2V48Z" fill="url(#paint1_linear)"/>
    <path d="M64 28.8L48 19.2L32 28.8V48L48 57.6L64 48V28.8Z" fill="url(#paint2_linear)"/>
    <path d="M32 9.6L16 0L0 9.6V28.8L16 38.4L32 28.8V9.6Z" fill="url(#paint3_linear)"/>
    <path d="M86.615 58.5067V48.6456H93.5967C99.0795 48.6456 102.156 44.8984 102.156 40.4411C102.156 35.9445 99.1189 32.1972 93.5967 32.1972H82V58.5067H86.615ZM92.9656 44.5828H86.615V36.26H92.9656C95.5689 36.26 97.4228 37.9167 97.4228 40.4411C97.4228 42.9261 95.5689 44.5828 92.9656 44.5828Z" fill="#333333"/>
    <path d="M110.06 58.5067V45.5295C110.888 44.1489 113.215 42.9656 114.99 42.9656C115.503 42.9656 115.898 43.005 116.253 43.0839V38.9817C113.807 38.9817 111.519 40.4017 110.06 42.2161V39.455H105.918V58.5067H110.06Z" fill="#333333"/>
    <path d="M128.428 58.98C131.426 58.98 134.227 57.9939 136.159 56.1795L134.266 53.4578C132.886 54.8384 130.716 55.5878 128.823 55.5878C125.233 55.5878 123.103 53.1817 122.788 50.3417H137.461V49.3161C137.461 43.3206 133.832 38.9817 128.113 38.9817C122.472 38.9817 118.449 43.4389 118.449 48.9611C118.449 54.9961 122.669 58.98 128.428 58.98ZM133.477 47.3834H122.748C122.906 45.0956 124.563 42.3739 128.073 42.3739C131.821 42.3739 133.359 45.135 133.477 47.3834Z" fill="#333333"/>
    <path d="M159.102 58.5067V32.1972H154.96V42.0978C153.461 40.0467 151.253 38.9817 148.846 38.9817C143.955 38.9817 140.405 42.8078 140.405 48.9611C140.405 55.2723 143.995 58.98 148.846 58.98C151.331 58.98 153.501 57.7967 154.96 55.9034V58.5067H159.102ZM150.069 55.3117C146.756 55.3117 144.665 52.6689 144.665 48.9611C144.665 45.2928 146.756 42.65 150.069 42.65C152.002 42.65 154.053 43.7939 154.96 45.2139V52.7478C154.053 54.1678 152.002 55.3117 150.069 55.3117Z" fill="#333333"/>
    <path d="M166.559 37.1278C167.979 37.1278 169.123 35.9839 169.123 34.5639C169.123 33.1439 167.979 32 166.559 32C165.139 32 163.995 33.1439 163.995 34.5639C163.995 35.9839 165.139 37.1278 166.559 37.1278ZM168.61 58.5067V39.455H164.469V58.5067H168.61Z" fill="#333333"/>
    <path d="M182.661 58.98C186.408 58.98 188.617 57.3628 189.958 55.5878L187.236 53.0239C186.171 54.5228 184.712 55.3117 182.858 55.3117C179.426 55.3117 177.099 52.6689 177.099 48.9611C177.099 45.2534 179.426 42.65 182.858 42.65C184.712 42.65 186.171 43.36 187.236 44.8589L189.958 42.3739C188.617 40.5595 186.408 38.9817 182.661 38.9817C176.902 38.9817 172.839 43.2022 172.839 48.9611C172.839 54.72 176.902 58.98 182.661 58.98Z" fill="#333333"/>
    <path d="M199.387 58.98C201.28 58.98 202.503 58.4278 203.253 57.7573L202.267 54.6017C201.951 54.9567 201.241 55.3117 200.452 55.3117C199.269 55.3117 198.638 54.3256 198.638 53.0239V43.0445H202.503V39.455H198.638V34.2483H194.496V39.455H191.34V43.0445H194.496V54.0495C194.496 57.2445 196.153 58.98 199.387 58.98Z" fill="#333333"/>
    <path d="M208.315 37.1278C209.735 37.1278 210.879 35.9839 210.879 34.5639C210.879 33.1439 209.735 32 208.315 32C206.895 32 205.751 33.1439 205.751 34.5639C205.751 35.9839 206.895 37.1278 208.315 37.1278ZM210.366 58.5067V39.455H206.224V58.5067H210.366Z" fill="#333333"/>
    <path d="M225.205 58.5067L232.976 39.455H228.558L222.997 53.7339L217.435 39.455H212.978L220.748 58.5067H225.205Z" fill="#333333"/>
    <path d="M244.412 58.98C247.41 58.98 250.21 57.9939 252.143 56.1795L250.25 53.4578C248.869 54.8384 246.7 55.5878 244.807 55.5878C241.217 55.5878 239.087 53.1817 238.772 50.3417H253.445V49.3161C253.445 43.3206 249.816 38.9817 244.097 38.9817C238.456 38.9817 234.433 43.4389 234.433 48.9611C234.433 54.9961 238.653 58.98 244.412 58.98ZM249.461 47.3834H238.732C238.89 45.0956 240.547 42.3739 244.057 42.3739C247.804 42.3739 249.343 45.135 249.461 47.3834Z" fill="#333333"/>
    <path d="M290.738 58.5067V32.1972H286.123V42.9261H272.436V32.1972H267.821V58.5067H272.436V46.9889H286.123V58.5067H290.738Z" fill="#333333"/>
    <path d="M305.043 58.98C308.04 58.98 310.841 57.9939 312.774 56.1795L310.88 53.4578C309.5 54.8384 307.33 55.5878 305.437 55.5878C301.848 55.5878 299.718 53.1817 299.402 50.3417H314.075V49.3161C314.075 43.3206 310.447 38.9817 304.727 38.9817C299.087 38.9817 295.063 43.4389 295.063 48.9611C295.063 54.9961 299.284 58.98 305.043 58.98ZM310.092 47.3834H299.363C299.52 45.0956 301.177 42.3739 304.688 42.3739C308.435 42.3739 309.973 45.135 310.092 47.3834Z" fill="#333333"/>
    <path d="M333.981 58.5067V45.845C333.981 40.7567 330.273 38.9817 326.013 38.9817C322.976 38.9817 320.215 39.9283 318.006 42.0583L319.741 44.9378C321.358 43.2811 323.252 42.4922 325.382 42.4922C327.985 42.4922 329.839 43.8334 329.839 46.0422V48.8822C328.419 47.265 326.25 46.4367 323.646 46.4367C320.53 46.4367 317.059 48.2511 317.059 52.6689C317.059 56.85 320.57 58.98 323.646 58.98C326.171 58.98 328.419 58.0728 329.839 56.4162V58.5067H333.981ZM325.303 56.14C322.976 56.14 321.201 54.7989 321.201 52.7084C321.201 50.6573 322.976 49.2767 325.303 49.2767C327.078 49.2767 328.853 49.9473 329.839 51.2489V54.1678C328.853 55.4695 327.078 56.14 325.303 56.14Z" fill="#333333"/>
    <path d="M343.491 58.5067V32.1972H339.349V58.5067H343.491Z" fill="#333333"/>
    <path d="M354.623 58.98C356.516 58.98 357.739 58.4278 358.488 57.7573L357.502 54.6017C357.187 54.9567 356.477 55.3117 355.688 55.3117C354.504 55.3117 353.873 54.3256 353.873 53.0239V43.0445H357.739V39.455H353.873V34.2483H349.732V39.455H346.576V43.0445H349.732V54.0495C349.732 57.2445 351.388 58.98 354.623 58.98Z" fill="#333333"/>
    <path d="M378.5 58.5067V45.0167C378.5 41.1117 376.449 38.9817 372.347 38.9817C369.349 38.9817 366.903 40.52 365.641 42.0189V32.1972H361.499V58.5067H365.641V45.1745C366.588 43.9122 368.402 42.65 370.532 42.65C372.859 42.65 374.358 43.5178 374.358 46.5156V58.5067H378.5Z" fill="#333333"/>
    <path d="M110.622 83.1673C115.75 83.1673 119.054 79.6414 119.054 74.9566C119.054 70.2966 115.75 66.7214 110.622 66.7214H105V83.1673H110.622ZM110.622 81.3427H107.046V68.5459H110.622C114.69 68.5459 116.934 71.4061 116.934 74.9566C116.934 78.4825 114.616 81.3427 110.622 81.3427Z" fill="#333333"/>
    <path d="M124.114 69.5569C124.804 69.5569 125.346 69.0144 125.346 68.324C125.346 67.6336 124.804 67.0665 124.114 67.0665C123.423 67.0665 122.856 67.6336 122.856 68.324C122.856 69.0144 123.423 69.5569 124.114 69.5569ZM125.026 83.1673V71.2582H123.177V83.1673H125.026Z" fill="#333333"/>
    <path d="M138.997 83.1673V74.9813C138.997 72.0965 136.925 70.9623 134.386 70.9623C132.438 70.9623 130.909 71.6034 129.627 72.9348L130.49 74.217C131.55 73.0581 132.709 72.565 134.139 72.565C135.865 72.565 137.147 73.4773 137.147 75.0553V77.2004C136.186 76.1155 134.83 75.5977 133.202 75.5977C131.18 75.5977 129.06 76.8306 129.06 79.5181C129.06 82.1071 131.205 83.4632 133.202 83.4632C134.805 83.4632 136.186 82.8961 137.147 81.8112V83.1673H138.997ZM133.893 82.1317C132.142 82.1317 130.934 81.0468 130.934 79.5181C130.934 78.0141 132.142 76.9292 133.893 76.9292C135.175 76.9292 136.432 77.4223 137.147 78.3839V80.6523C136.432 81.6386 135.175 82.1317 133.893 82.1317Z" fill="#333333"/>
    <path d="M148.497 88C151.407 88 154.193 86.7671 154.193 82.9947V71.2582H152.344V73.0581C151.382 71.7513 149.927 70.9623 148.325 70.9623C145.193 70.9623 143.024 73.3293 143.024 77.1511C143.024 80.9975 145.218 83.3152 148.325 83.3152C150.001 83.3152 151.456 82.4029 152.344 81.1948V82.9947C152.344 85.5097 150.47 86.4713 148.497 86.4713C146.796 86.4713 145.612 86.0275 144.626 84.8686L143.689 86.2C145.119 87.6055 146.5 88 148.497 88ZM148.793 81.6632C146.377 81.6632 144.947 79.7647 144.947 77.1511C144.947 74.5128 146.377 72.6143 148.793 72.6143C150.248 72.6143 151.678 73.5019 152.344 74.5128V79.74C151.678 80.7756 150.248 81.6632 148.793 81.6632Z" fill="#333333"/>
    <path d="M168.77 83.1673V74.8087C168.77 72.2198 167.463 70.9623 164.997 70.9623C163.197 70.9623 161.57 71.9732 160.732 72.9841V71.2582H158.882V83.1673H160.732V74.4635C161.447 73.5019 162.803 72.6143 164.258 72.6143C165.86 72.6143 166.945 73.2553 166.945 75.3758V83.1673H168.77Z" fill="#333333"/>
    <path d="M178.676 83.4632C182.3 83.4632 184.569 80.6523 184.569 77.2004C184.569 73.7485 182.3 70.9623 178.676 70.9623C175.051 70.9623 172.783 73.7485 172.783 77.2004C172.783 80.6523 175.051 83.4632 178.676 83.4632ZM178.676 81.8112C176.136 81.8112 174.731 79.6414 174.731 77.2004C174.731 74.7841 176.136 72.6143 178.676 72.6143C181.215 72.6143 182.621 74.7841 182.621 77.2004C182.621 79.6414 181.215 81.8112 178.676 81.8112Z" fill="#333333"/>
    <path d="M192.367 83.4632C195.424 83.4632 197.027 81.8605 197.027 79.8633C197.027 75.2279 189.654 76.9292 189.654 74.3402C189.654 73.28 190.665 72.4663 192.293 72.4663C193.846 72.4663 195.128 73.1074 195.843 73.9704L196.706 72.6882C195.769 71.7266 194.339 70.9623 192.293 70.9623C189.457 70.9623 187.879 72.5157 187.879 74.3896C187.879 78.8031 195.252 77.0278 195.252 79.9126C195.252 81.0961 194.241 81.9591 192.416 81.9591C190.789 81.9591 189.285 81.1701 188.496 80.2578L187.559 81.5893C188.767 82.8468 190.394 83.4632 192.367 83.4632Z" fill="#333333"/>
    <path d="M204.05 83.4632C205.11 83.4632 205.776 83.1426 206.244 82.6988L205.702 81.2934C205.455 81.5646 204.987 81.8112 204.445 81.8112C203.656 81.8112 203.261 81.1701 203.261 80.2825V72.8855H205.677V71.2582H203.261V68.0035H201.387V71.2582H199.415V72.8855H201.387V80.677C201.387 82.4522 202.275 83.4632 204.05 83.4632Z" fill="#333333"/>
    <path d="M210.188 69.5569C210.879 69.5569 211.421 69.0144 211.421 68.324C211.421 67.6336 210.879 67.0665 210.188 67.0665C209.498 67.0665 208.931 67.6336 208.931 68.324C208.931 69.0144 209.498 69.5569 210.188 69.5569ZM211.1 83.1673V71.2582H209.251V83.1673H211.1Z" fill="#333333"/>
    <path d="M221.027 83.4632C223.197 83.4632 224.479 82.5755 225.392 81.392L224.159 80.2578C223.37 81.318 222.359 81.8112 221.126 81.8112C218.611 81.8112 217.008 79.8387 217.008 77.2004C217.008 74.5621 218.611 72.6143 221.126 72.6143C222.359 72.6143 223.37 73.0581 224.159 74.143L225.392 73.0334C224.479 71.8499 223.197 70.9623 221.027 70.9623C217.477 70.9623 215.085 73.6745 215.085 77.2004C215.085 80.7263 217.477 83.4632 221.027 83.4632Z" fill="#333333"/>
    <path d="M232.791 83.4632C235.848 83.4632 237.451 81.8605 237.451 79.8633C237.451 75.2279 230.079 76.9292 230.079 74.3402C230.079 73.28 231.09 72.4663 232.717 72.4663C234.27 72.4663 235.552 73.1074 236.268 73.9704L237.13 72.6882C236.194 71.7266 234.763 70.9623 232.717 70.9623C229.881 70.9623 228.303 72.5157 228.303 74.3896C228.303 78.8031 235.676 77.0278 235.676 79.9126C235.676 81.0961 234.665 81.9591 232.84 81.9591C231.213 81.9591 229.709 81.1701 228.92 80.2578L227.983 81.5893C229.191 82.8468 230.818 83.4632 232.791 83.4632Z" fill="#333333"/>
    <path d="M256.674 83.4632C259.584 83.4632 261.556 82.0824 262.937 80.1838L261.211 79.2469C260.274 80.6277 258.548 81.6386 256.674 81.6386C253.074 81.6386 250.337 78.8524 250.337 74.9566C250.337 71.0116 253.074 68.2747 256.674 68.2747C258.548 68.2747 260.274 69.261 261.211 70.6664L262.912 69.7048C261.605 67.8309 259.584 66.4501 256.674 66.4501C251.94 66.4501 248.217 69.9021 248.217 74.9566C248.217 80.0112 251.94 83.4632 256.674 83.4632Z" fill="#333333"/>
    <path d="M271.66 83.4632C275.284 83.4632 277.553 80.6523 277.553 77.2004C277.553 73.7485 275.284 70.9623 271.66 70.9623C268.035 70.9623 265.767 73.7485 265.767 77.2004C265.767 80.6523 268.035 83.4632 271.66 83.4632ZM271.66 81.8112C269.12 81.8112 267.715 79.6414 267.715 77.2004C267.715 74.7841 269.12 72.6143 271.66 72.6143C274.2 72.6143 275.605 74.7841 275.605 77.2004C275.605 79.6414 274.2 81.8112 271.66 81.8112Z" fill="#333333"/>
    <path d="M297.778 83.1673V74.5621C297.778 72.1705 296.619 70.9623 294.449 70.9623C292.723 70.9623 291.12 72.0965 290.455 73.1814C290.11 71.9485 289.074 70.9623 287.274 70.9623C285.499 70.9623 283.921 72.2198 283.403 72.9841V71.2582H281.554V83.1673H283.403V74.4635C284.044 73.5019 285.326 72.6143 286.534 72.6143C288.112 72.6143 288.729 73.5759 288.729 75.0799V83.1673H290.578V74.4389C291.194 73.4773 292.501 72.6143 293.734 72.6143C295.287 72.6143 295.928 73.5759 295.928 75.0799V83.1673H297.778Z" fill="#333333"/>
    <path d="M308.321 83.4632C311.453 83.4632 313.622 81.0468 313.622 77.2004C313.622 73.3293 311.453 70.9623 308.321 70.9623C306.645 70.9623 305.165 71.8253 304.302 73.0334V71.2582H302.453V87.7041H304.302V81.3674C305.289 82.6742 306.694 83.4632 308.321 83.4632ZM307.853 81.8112C306.398 81.8112 304.943 80.9235 304.302 79.888V74.5128C304.943 73.4773 306.398 72.6143 307.853 72.6143C310.245 72.6143 311.675 74.5621 311.675 77.2004C311.675 79.8387 310.245 81.8112 307.853 81.8112Z" fill="#333333"/>
    <path d="M326.845 83.1673V74.9813C326.845 72.0965 324.774 70.9623 322.234 70.9623C320.286 70.9623 318.758 71.6034 317.475 72.9348L318.338 74.217C319.399 73.0581 320.558 72.565 321.988 72.565C323.714 72.565 324.996 73.4773 324.996 75.0553V77.2004C324.034 76.1155 322.678 75.5977 321.051 75.5977C319.029 75.5977 316.908 76.8306 316.908 79.5181C316.908 82.1071 319.053 83.4632 321.051 83.4632C322.653 83.4632 324.034 82.8961 324.996 81.8112V83.1673H326.845ZM321.741 82.1317C319.99 82.1317 318.782 81.0468 318.782 79.5181C318.782 78.0141 319.99 76.9292 321.741 76.9292C323.023 76.9292 324.281 77.4223 324.996 78.3839V80.6523C324.281 81.6386 323.023 82.1317 321.741 82.1317Z" fill="#333333"/>
    <path d="M341.45 83.1673V74.8087C341.45 72.2198 340.143 70.9623 337.677 70.9623C335.877 70.9623 334.25 71.9732 333.411 72.9841V71.2582H331.562V83.1673H333.411V74.4635C334.127 73.5019 335.483 72.6143 336.937 72.6143C338.54 72.6143 339.625 73.2553 339.625 75.3758V83.1673H341.45Z" fill="#333333"/>
    <path d="M345.512 87.8274C345.832 87.926 346.449 88 346.843 88C348.421 87.9753 349.63 87.3096 350.345 85.5836L356.311 71.2582H354.314L350.345 81.0222L346.4 71.2582H344.378L349.358 83.2906L348.569 85.0905C348.15 86.0768 347.608 86.348 346.794 86.348C346.498 86.348 346.054 86.274 345.783 86.1507L345.512 87.8274Z" fill="#333333"/>
    <defs>
      <linearGradient id="paint0_linear" x1="64" y1="0" x2="1.43051e-06" y2="96" gradientUnits="userSpaceOnUse">
        <stop offset="0.220602" stop-color="#5EADD1"/>
        <stop offset="1" stop-color="#004654"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="64" y1="0" x2="1.43051e-06" y2="96" gradientUnits="userSpaceOnUse">
        <stop offset="0.220602" stop-color="#5EADD1"/>
        <stop offset="1" stop-color="#004654"/>
      </linearGradient>
      <linearGradient id="paint2_linear" x1="64" y1="0" x2="1.43051e-06" y2="96" gradientUnits="userSpaceOnUse">
        <stop offset="0.220602" stop-color="#5EADD1"/>
        <stop offset="1" stop-color="#004654"/>
      </linearGradient>
      <linearGradient id="paint3_linear" x1="64" y1="0" x2="1.43051e-06" y2="96" gradientUnits="userSpaceOnUse">
        <stop offset="0.220602" stop-color="#5EADD1"/>
        <stop offset="1" stop-color="#004654"/>
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default LargeLogoIcon;