import { useEffect } from "react";

const useOutsideAlerter = (ref, handler, options = {}) => {
  const { excludeIds = ["mobileSidebarTrigger"] } = options;
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */

    function handleClickOutside(event) {
      let { target } = event;
      let clickByIds = false;

      if (excludeIds.includes(target.id)) {
        clickByIds = true;
      }
      while (target.parentNode && !clickByIds) {
        target = target.parentNode;

        try {
          if (excludeIds.includes(target.id)) {
            clickByIds = true;
            break;
          }
        } catch (e) {
          return;
        }
      }

      if (ref.current && !ref.current.contains(event.target) && !clickByIds) {
        handler();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler, excludeIds]);
};

export default useOutsideAlerter;
