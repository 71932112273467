import helpers from 'utils/helpers';
import { formatServerDateTimeToLocal } from 'utils/dateTimeFormat.js';
import { concatWithSeparator } from 'utils/textFormat.js';

export default {
  tableColConfig: [
    {
      id: 'specLocTypeDesc',
      label: 'Specimen #'
    },
    {
      id: 'location',
      label: 'Location',
      accessor: d => concatWithSeparator([d.sampleStorageDesc, d.shelf, d.rack, d.box], ' > ')
    },
    {
      id: 'rowLetter',
      label: 'Position',
      accessor: d => concatWithSeparator([d.rowLetter, d.slot], '')
    },
    {
      id: 'date',
      label: 'Date',
      accessor: (d) => {
        return (!d.discardDate ? formatServerDateTimeToLocal(d.placedDate, 'dateTime') : formatServerDateTimeToLocal(d.discardDate, 'dateTime'))
      },
    },
    {
      id: 'operator',
      label: 'Operator',
      accessor: (d) => {
        return (!d.discardDate ? d.placedByUsername : d.discardByUsername)
      },
    },
    {
      id: 'status',
      label: 'Status',
      accessor: (d) => {
        return (!d.discardDate ? 'Placed' : 'Discard')
      },
    }
  ],


  createSpecimenLocationTableConfig: [
    {
      id: 'sampleStorageDesc',
      label: 'Freezer'
    },
    {
      id: 'shelf',
      label: 'Shelf'
    },
    {
      id: 'rack',
      label: 'Rack'
    },
    {
      id: 'box',
      label: 'Box'
    },
    {
      id: 'rowLetter',
      label: 'Grid Letter'
    },
    {
      id: 'slot',
      label: 'Grid #'
    }
  ],

  createSpecimenTableFilterConfig: {},

  createSpecimenLocationFormConfig: {
    inputsById: ['specLocTypeCode', 'sampleSizeCode', 'aliquot'],
    inputsByHash: {
      specLocTypeCode: {
        label: 'Specimen Type',
        yup: helpers.getYupRequired('Specimen Type'),
        type: 'autocomplete',
        resource: 'lovs/SPECLOCTYPE',
        accessor: (d) => {
          return ({ value: d.specLocTypeCode, label: d.specLocTypeDesc })
        },
        setInitValueFromData: {
          inputKey: 'specLocTypeDesc',
          outputKey: 'specLocTypeCode',
          value: 'Serum'
        }
      },
      sampleSizeCode: {
        label: 'Aliquot/Container',
        yup: helpers.getYupRequired('Aliquot/Container'),
        type: 'autocomplete',
        resource: 'lovs/SAMPLESIZE',
        accessor: (d) => {
          return ({ value: d.sampleSizeCode, label: d.sampleSizeDesc })
        },
        setInitValueFromData: {
          inputKey: 'sampleSizeDesc',
          outputKey: 'sampleSizeCode',
          value: 'Aliquot'
        }
      },
      aliquot: {
        label: 'Aliquot',
        yup: helpers.getYupRequired('Aliquot'),
        value: '1'
      }
    }
  },

  locationsTabFilterConfig: {
    inputsByHash: {
      sampleStorageCode: {
        label: 'Freezer',
        type: 'autocomplete',
        relativeInputs: ['shelf', 'rack', 'box'],
        resetRelativeInputs: ['shelf', 'rack', 'box'],
        resourceAccessor: (d) => `labs/${d.labID}/dd_freezer`,
        accessor: (d) => {
          return ({ value: d.sampleStorageCode, label: d.sampleStorageCode })
        }
      },
      shelf: {
        label: 'Shelf',
        type: 'autocomplete',
        relativeInputs: ['rack', 'box'],
        resetRelativeInputs: ['rack', 'box'],
        relativeInputResourceAccessor: (d, options) => {
          return `labs/${options.labID}/dd_freezer/${d.sampleStorageCode}/dd_shelf`
        },
        accessor: (d) => {
          return ({ value: d.shelf, label: d.shelf })
        }
      },
      rack: {
        label: 'Rack',
        type: 'autocomplete',
        relativeInputs: ['box'],
        resetRelativeInputs: ['box'],
        relativeInputResourceAccessor: (d, options) => {
          if (d.hasOwnProperty('sampleStorageCode') && d.hasOwnProperty('shelf')) {
            return `labs/${options.labID}/dd_freezer/${d.sampleStorageCode}/dd_shelf/${d.shelf}/dd_rack`
          } else {
            return false;
          }
        },
        accessor: (d) => {
          return ({ value: d.rack, label: d.rack })
        }
      },
      box: {
        label: 'Box',
        type: 'autocomplete',
        relativeInputResourceAccessor: (d, options) => {
          if (d.hasOwnProperty('sampleStorageCode') && d.hasOwnProperty('shelf') && d.hasOwnProperty(
            'rack')) {
            return `labs/${options.labID}/dd_freezer/${d.sampleStorageCode}/dd_shelf/${d.shelf}/dd_rack/${d.rack}/dd_box`
          } else {
            return false
          }
        },
        accessor: (d) => {
          return ({ value: d.box, label: d.box })
        }
      }

    },

    inputsById: ['sampleStorageCode', 'shelf', 'rack', 'box']
  }
}