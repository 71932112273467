import helpers from 'utils/helpers';

export default {
  inputsById: [
    'defaultTests',
    'significantDigits',
    'reportLo',
    'reportHi',
    'mNormalLo',
    'mNormalHi',
    'mBorderLo',
    'mBorderHi',
    'mUnfavLo',
    'mUnfavHi',
    'fNormalLo',
    'fNormalHi',
    'fBorderLo',
    'fBorderHi',
    'fUnfavLo',
    'fUnfavHi',
    'colaUnits',

  ],
  inputsByHash: {
    defaultTests: {
      label: 'Select test',
      type: 'autocomplete',
      resource: 'default_tests',
      relativeAutoFillInputs: [
        'significantDigits',
        'reportLo',
        'reportHi',
        'mNormalLo',
        'mNormalHi',
        'mBorderLo',
        'mBorderHi',
        'mUnfavLo',
        'mUnfavHi',
        'fNormalLo',
        'fNormalHi',
        'fBorderLo',
        'fBorderHi',
        'fUnfavLo',
        'fUnfavHi',
      ],
      submitViaKey: 'testId',
      submitValueAccessor: d => d.id,
      accessor: (d) => ({ value: d, label: d.testDesc }),
      yup: helpers.getYupRequired('Test id'),
    },
    significantDigits: {
      label: '',
      autoFillAccessor: (d) => d.significantDigits,
      submitViaKey: 'colaSigDigits',
    },
    reportLo: {
      label: 'Low',
      autoFillAccessor: (d) => d.reportLo,
      submitViaKey: 'colaReportLo',
    },
    reportHi: {
      label: 'Hi',
      autoFillAccessor: (d) => d.reportHi,
      submitViaKey: 'colaReportHi',
    },
    mNormalLo: {
      label: 'Low',
      autoFillAccessor: (d) => d.mNormalLo,
      submitViaKey: 'colaMNormalLo',
    },
    mNormalHi: {
      label: 'Hi',
      autoFillAccessor: (d) => d.mNormalHi,
      submitViaKey: 'colaMNormalHi',
    },
    mBorderLo: {
      label: 'Low',
      autoFillAccessor: (d) => d.mBorderLo,
      submitViaKey: 'colaMBorderLo',
    },
    mBorderHi: {
      label: 'Hi',
      autoFillAccessor: (d) => d.mBorderHi,
      submitViaKey: 'colaMBorderHi',
    },
    mUnfavLo: {
      label: 'Low',
      autoFillAccessor: (d) => d.mUnfavLo,
      submitViaKey: 'colaMUnfavLo',
    },
    mUnfavHi: {
      label: 'Hi',
      autoFillAccessor: (d) => d.mUnfavHi,
      submitViaKey: 'colaMUnfavHi',
    },
    fNormalLo: {
      label: 'Low',
      autoFillAccessor: (d) => d.fNormalLo,
      submitViaKey: 'colaFNormalLo',
    },
    fNormalHi: {
      label: 'Hi',
      autoFillAccessor: (d) => d.fNormalHi,
      submitViaKey: 'colaFNormalHi',
    },
    fBorderLo: {
      label: 'Low',
      autoFillAccessor: (d) => d.fBorderLo,
      submitViaKey: 'colaFBorderLo',
    },
    fBorderHi: {
      label: 'Hi',
      autoFillAccessor: (d) => d.fBorderHi,
      submitViaKey: 'colaFBorderHi',
    },
    fUnfavLo: {
      label: 'Low',
      autoFillAccessor: (d) => d.fUnfavLo,
      submitViaKey: 'colaFUnfavLo',
    },
    fUnfavHi: {
      label: 'Hi',
      autoFillAccessor: (d) => d.fUnfavHi,
      submitViaKey: 'colaFUnfavHi',
    },
    colaUnits: {
      label: 'Units',
    },
  },
  inputsGroupsByHash: {
    group1: {
      ids: [
        ['defaultTests', 'colaUnits'],
      ]
    },
    group2: {
      title: 'Sig Digits',
      ids: [
        ['significantDigits'],
      ],
    },
    group3: {
      title: 'Report',
      ids: [
        [
          'reportLo',
          'reportHi',
        ]
      ],
    },
    group4: {
      title: 'Male Normal',
      ids: [
        [
          'mNormalLo',
          'mNormalHi',
        ],
      ],
    },
    group5: {
      title: 'Male Border',
      ids: [
        [
          'mBorderLo',
          'mBorderHi',
        ],
      ],
    },
    group6: {
      title: 'Male Unfavorable',
      ids: [
        [
          'mUnfavLo',
          'mUnfavHi',
        ],
      ],
    },
    group7: {
      title: 'Female Normal',
      ids: [
        [
          'fNormalLo',
          'fNormalHi',
        ],
      ],
    },
    group8: {
      title: 'Female Border',
      ids: [
        [
          'fBorderLo',
          'fBorderHi',
        ],
      ],
    },
    group9: {
      title: 'Female Unfavorable',
      ids: [
        [
          'fUnfavLo',
          'fUnfavHi',
        ],
      ],
    },
  },

  inputsGroupsById: [
    'group1',
    'group2',
    'group3',
    'group4',
    'group5',
    'group6',
    'group7',
    'group8',
    'group9',
  ],
}