import { useState } from 'react';

export default function() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalID, setModalID] = useState(null);

  const handleOpenModal = (modalID = null) => {
    setModalIsOpen(true);
    setModalID(modalID);
  }

  const handleCloseModal = () => {
    setModalIsOpen(false);
  }

  return {
    modalIsOpen,
    modalID,
    handleOpenModal,
    handleCloseModal,
  }
}