import React from 'react'
import styles from './QAReports.module.scss';
import ReportsTable from 'components/ui_components/table/ReportsTable/ReportsTable.js';

const QAReportsContainer = () => {

  return (
    <div className={styles.root}>
      <ReportsTable
        listResource='qa_reports'
        downloadResource='qa_reports/download'
        apiEndointType='reports'
      />
    </div>
  );
}


export default QAReportsContainer;