import React, { PureComponent } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import styles from "./select.module.scss";
import dataProvider, { GET_LIST } from "providers/dataProvider";
import FormHelperText from "@material-ui/core/FormHelperText";

class SelectField extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    const { input } = this.props;
    this.initOption(input);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.input.options) !==
      JSON.stringify(this.props.input.options)
    ) {
      this.setState({
        options: this.props.input.options,
      });
    }
  }

  initOption = (input) => {
    if (input.hasOwnProperty("resource")) {
      this.loadOptions();
    } else if (input.hasOwnProperty("options")) {
      this.setState({
        options: input.options,
      });
    }
  };

  getFilter = (input) => {
    try {
      return (
        input.filter ||
        (input.relativeInputsFilterAccessor &&
          input.relativeInputsFilterAccessor(input.relativeInputsData)) ||
        {}
      );
    } catch {
      return false;
    }
  };

  getResource = (input, options) => {
    try {
      return (
        input.resource ||
        (input.resourceAccessor && input.resourceAccessor(options)) ||
        input.relativeInputResourceAccessor(input.relativeInputsData)
      );
    } catch {
      return false;
    }
  };

  loadOptions() {
    const { input, options } = this.props;
    const filter = this.getFilter(input);
    const resource = this.getResource(input, options);

    if (!filter) return;
    if (!resource) return;

    dataProvider(GET_LIST, resource, { filter }).then(({ data, total }) => {
      let options = data.map(input.accessor);
      this.setState({
        options,
      });
    });
  }

  onKeyPress = (e) => {
    const { inputId, handleInputs } = this.props;
    const option = this.state.options.filter(
      (item) => item.shortKeyCode === e.key
    )[0];

    if (!option) return;

    handleInputs({
      target: {
        value: option.value,
        name: inputId,
      },
    });
  };

  render() {
    const {
      input,
      inputId,
      style,
      handleInputs,
      value,
      disabled,
      selectStyles,
    } = this.props;
    const renderStyles = selectStyles ? selectStyles : styles;
    const { options } = this.state;
    return (
      <FormControl className={style.textField} disabled={disabled} error={input.error} required={input.hasOwnProperty('yup')}>
        <InputLabel htmlFor="age-simple">{input.label}</InputLabel>
        {options.length > 0 && (
          <React.Fragment>
            <Select
              onKeyPress={this.onKeyPress}
        
              error={input.error}
              value={value === null ? "" : value}
              onChange={handleInputs}
              name={inputId}
              inputProps={{ "aria-label": "Without label" }}
              className={renderStyles.field}
            >
              {options.map((option, key) => (
                <MenuItem key={key} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{input.error && input.errorMessage}</FormHelperText>
          </React.Fragment>
        )}
      </FormControl>
    );
  }
}

SelectField.defaultProps = {
  style: {
    textField: "",
  },
};

export default SelectField;
