import React, { PureComponent } from 'react'
import dataProvider, { GET_LIST } from "providers/dataProvider";
import SuggestionsPatientsListComponent
  from 'components/pages/requisitions/SuggestionsPatientsListComponent';
import WidgetType1 from 'components/ui_components/Widgets/WidgetType1/WidgetType1';
import styles from './WidgetPatient.module.scss'
import BaseForm from 'components/ui_components/BaseForm/BaseForm.js';

class WidgetPatientContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.timer = null

    this.state = {
      dataLoading: false,
      patientsData: []
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.submitData) === JSON.stringify(this.props.submitData)) return;

    const filterData = {};

    this.props.inputsById.forEach(id => {
      if (this.props.submitData[id] === '' ||
        this.props.submitData[id] === undefined ||
        Number.isNaN(this.props.submitData[id])
      ) return false;
      filterData[id] = this.props.submitData[id]
    })

    if (this.timer) {
      clearTimeout(this.timer)
    }

    this.props.handleEditSelectedPatient();

    this.timer = setTimeout(() => {
      this.loadPatients(filterData)
    }, 1000)
  }

  loadPatients = (filterData) => {
    if (this.props.isNewPatient) return;
    this.setState({
      dataLoading: true
    })
    dataProvider(GET_LIST, 'patients', {
      filter: filterData,
      pagination: {
        perPage: -1,
      }
    })
    .then(({ data, total }) => {
      this.setState({
        dataLoading: false,
        patientsData: data
      })
    })
  }

  handleInputs = (e) => {
    this.props.handleInputs(e);
  }

  render() {
    const { inputsByHash, title, inputsGroupsByHash, inputsGroupsById, baseFormStyles } = this.props;

    const content = <div className={styles.patientContent}>
      <div className={styles.patientListInputsCol}>

        <BaseForm
          {...baseFormStyles}
          inputsByHash={inputsByHash}
          inputsGroupsByHash={inputsGroupsByHash}
          inputsGroupsById={inputsGroupsById}
          handleInputs={this.handleInputs}
          groupTitleStyles={'font-weight: 700; font-size: 14px; margin: 10px 0 0;'}
        />
      </div>

      <div className={styles.suggestedPatientListCol}>
        <SuggestionsPatientsListComponent
          dataLoading={this.state.dataLoading}
          handleNewPatientCheck={this.props.handleNewPatientCheck}
          handleClickSuggestedPatient={this.props.handleClickSuggestedPatient}
          patientsData={this.state.patientsData}
          isNewPatient={this.props.isNewPatient}
        />
      </div>

    </div>

    return (
      <WidgetType1
        title={title}
        content={content}
        styles={`width: 100%;`}
      />
    )
  }
}


export default WidgetPatientContainer

WidgetPatientContainer.defaultProps = {
  inputsById: [],
};