import React from 'react';
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon';
import styled from 'styled-components';

const StyledNotificationIcon = styled(SvgIcon)`
&& {
    circle {
        transition: .5s;
    }
  }
`;


const NotificationIcon = (props) => {
    return (
        <StyledNotificationIcon>
            <path d="M11.5,24c1.4,0,2.5-1.1,2.5-2.5H9C9,22.9,10.1,24,11.5,24z"/>
            <path d="M18.9,16.6v-6.2c0-3.8-2-6.9-5.5-7.8V1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8v0.8c-3.5,0.8-5.5,4-5.5,7.8
	v6.2l-2.5,2.5v1.2h19.7v-1.2L18.9,16.6z M16.4,17.8H6.6v-7.4c0-3.1,1.9-5.5,4.9-5.5c3.1,0,4.9,2.5,4.9,5.5V17.8z"/>
            <circle style={{color: props.isActive ? 'red' : 'transparent'}} cx="16.5" cy="7" r="5"/>
        </StyledNotificationIcon>
    );
}

export default NotificationIcon;

NotificationIcon.propTypes = {
    isActive: PropTypes.bool
}

NotificationIcon.defaultProps = {
    isActive: false
}