export default [
  {
    id: 'id',
    label: 'Collaborator ID'
  },
  {
    id: 'colaName',
    label: 'Collaborator Name'
  },
  {
    id: 'colaAlias',
    label: 'Collaborator Alias'
  },
  {
    id: 'colaPhone',
    label: 'Phone'
  },
  {
    id: 'colaFaxEmail',
    label: 'Email'
  },
]