import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import BaseModal from "../BaseModal/BaseModal.js";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography'

const ConfirmModal = (props) => {
  const {
    title,
    message,
    handleCloseModal,
    modalIsOpen,
    handleSubmit,
  } = props;

  return (
    <BaseModal
      isOpen={modalIsOpen}
      onClose={handleCloseModal}
      title={title}
      controls={
        <React.Fragment>
          <ButtonBase
            className="button formButton formButton__secondaryOutlined"
            onClick={handleCloseModal}
          >
            No
          </ButtonBase>
          <ButtonBase
            className="button formButton formButton__mainContained"
            onClick={handleSubmit}
          >
            Yes
          </ButtonBase>
        </React.Fragment>
      }
      rootStyles={`max-width: 660px;`}
    >
      {message && (
        <div style={{ padding: '10px 0' }}>
          <Typography variant="h5">{message}</Typography>
        </div>
      )}
    </BaseModal>
  );
};

ConfirmModal.defaultProps = {
  modalIsOpen: false,
  title: null,
};

ConfirmModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
};

export default ConfirmModal;
