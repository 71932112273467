import React from "react";
import {
  CardIcon,
  HouseIcon,
  PersonIcon,
  EmailIcon,
  PhoneIcon,
  FaxIcon,
} from "icons";
import { concatWithCommaSeparation } from "utils/helpers";

const getWidgetsConfigByHash = (data = {}) => {
    return {
    about: {
      title: "About",
      type: "1",
      iconsData: [
        {
          id: "collaborator",
          title: "Collaborator",
          value: data.colaName,
          icon: <PersonIcon />,
        },
        {
          id: "address1",
          title: "Address",
          value: `
              ${concatWithCommaSeparation([data.clientAddr1])}<br/>  
              ${concatWithCommaSeparation([data.clientAddr2])}<br/>
              ${concatWithCommaSeparation([
                data.clientCity,
                `${data.clientState} ${data.clientZip}`,
                data.clientCountry,
              ])}
              `,
          icon: <HouseIcon />,
        },
        {
          id: "managerOfPerson",
          title: "External Client ID",
          value: data.clientExtId,
          icon: <CardIcon />,
        },
        {
          id: "clientTransmitMethodDesc",
          title: "Transmit Method",
          value: data.clientTransmitMethodDesc,
          icon: <PersonIcon />,
        },
        {
          id: "clientBillTypeCode",
          title: "Bill To",
          value: data.clientBillTypeDesc,
          icon: <PersonIcon />,
        },
        {
          id: "serviceRep",
          title: "Service Rep.",
          value: data.serviceName,
          icon: <PersonIcon />,
        },
        {
          id: "clientReqTypeDesc",
          title: "Requisition Type",
          value: data.clientReqTypeDesc,
          icon: <PersonIcon />,
        },
        {
          id: "isContractPhleb",
          title: "Contracted Phlebotomist",
          value: data.isContractPhleb === 1 ? "Yes" : "No",
          icon: <PersonIcon />,
        },
        {
          id: "clientPortalPrefDesc",
          title: "Patient Portal Preferences",
          value: data.clientPortalPrefDesc,
          icon: <PersonIcon />,
        },
      ],
      notesList: [
        {
          id: "clientTimeZone",
          title: "Client timezone",
          content: `${data.clientTz ? data.clientTz : ""}<br/>
                <i>*Client timezone is estimated using geo location.  If you think the timezone displayed is inaccurate, please contact LIS administrator immediately.</i>
              `,
        },
      ],
    },
    contact: {
      id: "contact",
      title: "Contact",
      type: "2",
      iconsDataByID: [
        "email",
        "phone",
        "fax",
        "clientPhone2",
        "clientEmail2",
        "clientName2",
      ],
      iconsDataByHash: {
        email: {
          title: "Email",
          value: `<a href="${data.clientEmail}">${data.clientEmail}</a>`,
          icon: <EmailIcon />,
        },
        phone: {
          title: "Phone",
          value: data.clientPhone,
          icon: <PhoneIcon />,
        },
        fax: {
          title: "Secure Fax Email",
          value: data.clientFaxEmail,
          icon: <FaxIcon />,
        },
        clientPhone2: {
          title: "Phone",
          value: data.clientPhone2,
          icon: <PhoneIcon />,
        },
        clientEmail2: {
          title: "Email",
          value: `<a href="${data.clientName}">${data.clientEmail2}</a>`,
          icon: <EmailIcon />,
        },
        clientName2: {
          title: "Name",
          value: data.clientName2,
          icon: <PersonIcon />,
        },
      },
      iconsDataGroupByHash: {
        primary: {
          iconsData: ["email", "phone", "fax"],
        },
        secondary: {
          title: "Secondary Information",
          iconsData: ["clientName2", "clientPhone2", "clientEmail2"],
        },
      },
      iconsDataGroupsByID: ["primary", "secondary"],
    },
  };
};

const widgetsConfigById = ["about", "contact"];

const widgetsConfigGroups = [["about", "contact"]];

export { widgetsConfigById, widgetsConfigGroups, getWidgetsConfigByHash };
