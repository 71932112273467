import {
    SMART_VIEWS_SIDEBAR_SHOW,
    SMART_VIEWS_SIDEBAR_HIDE,
    SMART_VIEWS_SIDEBAR_SWITCH_TAB,
    SET_SIDEBAR_VISIBILITY
} from './../config/actionTypes';


const initState = {
    isSidebarOpen: false,
    smartViewSidebarOpen: true,
    smartViewSidebarTabIndex: 1
}

export default (previousState = initState, action) => {
    switch (action.type) {
        case SET_SIDEBAR_VISIBILITY:
            return {
                ...previousState,
                isSidebarOpen: action.payload
            }
        case SMART_VIEWS_SIDEBAR_SHOW:
            return {
                ...previousState,
                smartViewSidebarOpen: true
            }

        case SMART_VIEWS_SIDEBAR_HIDE:
            return {
                ...previousState,
                smartViewSidebarOpen: false
            }
        case SMART_VIEWS_SIDEBAR_SWITCH_TAB:
            return {
                ...previousState,
                smartViewSidebarTabIndex: action.payload
            }
        default:
            return previousState
    }
}