import React from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
import style from "./SingleViewHeader.module.scss";
import WidgetListItemComponent from './WidgetListItemComponent';

const WidgetListComponent = props => {
    const {iconsById, iconsByHash} = props;
    return (
        <div className={clsx([style.widgetList])}>
            {iconsById.map(iconID => <WidgetListItemComponent key={iconID} icon={iconsByHash[iconID]}/>)}
        </div>
    );
};

WidgetListComponent.propTypes = {
    iconsById: PropTypes.array.isRequired,
    iconsByHash: PropTypes.object.isRequired,

};

export default WidgetListComponent;