import React from 'react';
import MainHeader from 'components/templateParts/MainHeader';
import SearchBar from 'components/templateParts/SearchBar';
import SmartViewsSidebar from '../../../SmartViewsSidebar/SmartViewsSidebar'
import AddPostButton from '../../../ui_components/buttons/AddPostButton';
import Footer from 'components/Footer/Footer';
import { BaseListComponent } from 'components/base'
import { tableColConfig, smartFilterConfig } from 'config/resources/collaboratorsResource';

const CollaboratorsList = (props) => {
  return (
    <div>
      <MainHeader title="Collaborators"
                  button={<AddPostButton title="Collaborators" href="/collaborators/create"/>}/>
      <SearchBar/>
      <SmartViewsSidebar isOpen={true}
                         resource="collaborators"
                         smartFilterConfig={smartFilterConfig}>
        <div className="mainContainer">
          <div className="mainContainer__inner">
            <BaseListComponent
              {...props}
              tableColConfig={tableColConfig}
              deleteConfirmationMessagePostfix="collaborator"
            />
          </div>
          <Footer/>
        </div>
      </SmartViewsSidebar>
    </div>
  );
}


export default CollaboratorsList;