import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SheetIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M15.1,0H5.5C4.2,0,3.2,1.1,3.2,2.4l0,19.2c0,1.3,1.1,2.4,2.4,2.4h14.4c1.3,0,2.4-1.1,2.4-2.4V7.2
	L15.1,0z M13.9,8.4V1.8l6.6,6.6H13.9z"/>
    </SvgIcon>
);

export default SheetIcon;