import React, { useState } from 'react'
import styles from './AddIssueContainer.module.scss';
import clsx from "clsx";
import { compose } from 'redux'
import { issuesTabConfig } from 'config/resources/requisitionsResource'
import withForm from 'withForm';
import SmartInput from 'components/ui_components/smart_input'
import dataProvider, { CREATE } from "providers/dataProvider";
import { withNotificationCenter } from 'NotificationCenter'
import { WidgetType1 } from 'components/ui_components/Widgets';
import { WidgetControlButton } from 'components/ui_components/buttons';

const { inputsById, inputsByHash } = issuesTabConfig;
const withFormConfig = { inputsById, inputsByHash }

const AddIssueContainer = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleInputs, inputsById, inputsByHash } = props;
  const { notificationCenter, isFormValid, getSubmitData, resetErrors, reqID, handleSuccessfullySubmit, clearInputs } = props;

  const handleSubmit = () => {

    if (isFormValid()) {
      setIsSubmitting(true);

      resetErrors();

      dataProvider(CREATE, `requisitions/${reqID}/issues`, { data: getSubmitData() })
      .then((response) => {
        notificationCenter.show('Successfully created', 'info')
        handleSuccessfullySubmit();
        clearInputs();
        setIsSubmitting(false);
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
        setIsSubmitting(false);
      });
    }
  }

  return (
    <div className={styles.root}>
      <WidgetType1
        title="Add Issue"
        content={<div className={styles.inputList}>
          {inputsById.map((inputId, index) => {
            const input = inputsByHash[inputId];
            let params = { inputId, input, handleInputs }
            return (
              <div key={index}
                   className={clsx([styles[`inputWrap-${inputId}`]], [styles.inputWrap])}>
                <SmartInput {...params}/>
              </div>
            )
          })}
        </div>}
        headerRightContent={
          <WidgetControlButton
            onClick={handleSubmit}
          >
            Create
          </WidgetControlButton>}
        showProgressBar={isSubmitting}
      />
    </div>
  );
}

export default compose(
  withNotificationCenter(),
  withForm(withFormConfig)
)(AddIssueContainer)