import React, { Component } from "react";
import styles from "./ReportsDeliveryContainer.module.scss";
import { SmartTable } from "components/ui_components/table";
import { compose } from "redux";
import PropTypes from "prop-types";
import { reportsDeliveryTabConfig } from "config/resources/requisitionsResource/index.js";
import { connect } from "react-redux";
import DemographicWidgetContainer from "./DemographicWidgetContainer.js";
import { Delivery2Icon, DownloadIcon } from "icons";
import DeliveryModalContainer from "./DeliveryModalContainer";
import withModal from "withModal";
import withDropZone from "withDropZone";
import helpers from "utils/helpers";
import { TableHeaderAddButton } from "components/ui_components/buttons";
import DropZoneModal from "components/DropZoneModal/DropZoneModal";
import { withNotificationCenter } from "NotificationCenter";
import dataProvider, {
  UPLOAD_FILE,
  UPLOAD_FILE_S3,
  DOWNLOAD_FILE,
  CREATE,
} from "providers/dataProvider";
import { userPermissions, userRoles } from "config/userRoles";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { isMobile } from "react-device-detect";
import { canByRoles } from "utils/permissions";

const {
  reportsTableColConfig,
  reportsHistoryTableColConfig,
} = reportsDeliveryTabConfig;

const reportsExtraDataConfig = {
  byId: ["labQcStatus"],
  byHash: {
    labQcStatus: {
      resource: "lovs/LABQCSTATUS",
    },
  },
};

class LocationsContainer extends Component {
  constructor(props) {
    super(props);

    this.permissionsToEdit = helpers.can(
      userPermissions.reqEntity.show.reportsDelivery.edit
    );
    this.permissionsToDownloadReports = helpers.can(
      userPermissions.reqEntity.show.reportsDelivery.downloadReports
    );

    this.isSalesRole = canByRoles(userRoles.sales);

    this.isViewReportLogic = isMobile || this.isSalesRole;

    this.state = {
      selectedRows: [],
      isUploading: false,
    };
  }

  handleDownloadClick = (rows) => {
    const { reqID, notificationCenter } = this.props;
    const resource = this.isViewReportLogic
      ? `requisitions/${reqID}/reports/${rows[0].id}/view`
      : `requisitions/${reqID}/download`;

    const data = {
      callSource: "VIEW",
    };

    if (!this.isViewReportLogic) {
      data.reportIds = helpers.getAllValues(rows, "id");
    }

    dataProvider(DOWNLOAD_FILE, resource, {
      data: data,
    })
      .then((response) => {
        const url = this.isViewReportLogic ? response.data : response.data.url;
        window.open(url);
        this.props.notificationCenter.showDownloadNotification(url);
      })
      .catch((error) => {
        notificationCenter.show(error.message, "error");
      });
  };

  handleDeliveryClick = (rows) => {
    this.props.handleOpenModal();
    this.setState({
      selectedRows: rows,
    });
  };

  handleSubmitUpload = () => {
    const {
      reqID,
      notificationCenter,
      handleCloseDropZone,
      dropZoneFiles,
    } = this.props;

    this.setState({
      isUploading: true,
    });

    let downloadFile = {};
    let fileName = "";

    dropZoneFiles.forEach((file, index) => {
      if (index > 0) return;
      fileName = file.name;
      downloadFile = file;
    });

    const gdbRequest = {
      fileName: fileName,
      docTypeCode: "REPORT",
    };

    dataProvider(UPLOAD_FILE, `requisitions/${reqID}/upload`, {
      data: gdbRequest,
    })
      .then((gdbResponse) => {
        const s3Request = { ...gdbResponse.data.fields };
        const newFileName = gdbResponse.data.newFileName;

        dataProvider(UPLOAD_FILE_S3, gdbResponse.data.url, {
          data: {
            ...s3Request,
            downloadFile,
          },
        })
          .then((s3Response) => {
            if (s3Response.status === 204) {
              dataProvider(CREATE, `requisitions/${reqID}/reports`, {
                data: {
                  reportFileName: newFileName,
                  reportOriginalFileName: fileName,
                },
              })
                .then((createRepResponse) => {
                  notificationCenter.show(createRepResponse.message, "info");
                  this.smartTable.loadData();
                  handleCloseDropZone();
                  this.handleStopUploading();
                })
                .catch((error) => {
                  notificationCenter.show(error.message, "warning");
                  this.handleStopUploading();
                });
            }
          })
          .catch((error) => {
            notificationCenter.show(error.message, "warning");
            this.handleStopUploading();
          });
      })
      .catch((error) => {
        notificationCenter.show(error.message, "warning");
        this.handleStopUploading();
      });
  };

  handleStopUploading = () => {
    this.setState({
      isUploading: false,
    });
  };

  reloadReportTable = () => {
    this.smartTable.loadData();
  };

  render() {
    const {
      reqID,
      isDemQcPass,
      modalOpen,
      dropZoneOpen,
      handleCloseModal,
      handleOpenDropZone,
      handleCloseDropZone,
      tableColConfigByHashWithExtraData,
      handleDropFiles,
      dropZoneFiles,
    } = this.props;
    const { selectedRows, isUploading } = this.state;

    const customRowActions = [];
    const customToolbarActions = [];

    if (
      this.permissionsToEdit ||
      this.permissionsToDownloadReports ||
      this.isSalesRole
    ) {
      customRowActions.push({
        isHiddenAccessor: (d) => {
          return d.reportIsDelivered === 0;
        },
        icon: this.isViewReportLogic ? (
          <VisibilityOutlinedIcon />
        ) : (
          <DownloadIcon />
        ),
        handleOnClick: this.handleDownloadClick,
        tooltipText: this.isViewReportLogic ? "View report" : "Download reports",
      });

      if (!isMobile) {
        customToolbarActions.push({
          icon: <DownloadIcon />,
          handleOnClick: this.handleDownloadClick,
          tooltipText: "Download reports",
        });
      }
    }

    if (this.permissionsToEdit) {
      customToolbarActions.push({
        icon: <Delivery2Icon />,
        handleOnClick: this.handleDeliveryClick,
        tooltipText: "Delivery",
      });
    }

    return (
      <div className={styles.root}>
        <DropZoneModal
          modalOpen={dropZoneOpen}
          handleSubmit={this.handleSubmitUpload}
          handleDropFiles={handleDropFiles}
          handleCloseDropZone={handleCloseDropZone}
          isUploading={isUploading}
          dropZoneFiles={dropZoneFiles}
        />
        <DeliveryModalContainer
          modalOpen={modalOpen}
          handleCloseModal={handleCloseModal}
          resource={`requisitions/${reqID}/delivery`}
          handleSubmitOK={this.reloadReportTable}
          submitData={{ reportIds: helpers.getAllValues(selectedRows, "id") }}
        />

        <div className={styles.main}>
          <DemographicWidgetContainer reqID={reqID} isDemQcPass={isDemQcPass} />

          <div className={styles.tableWrap}>
            <h3 className="ttl ttl-3">Reports</h3>

            <SmartTable
              resource={`requisitions/${reqID}/reports`}
              showPagination={true}
              showSelect={
                this.permissionsToEdit || this.permissionsToDownloadReports
              }
              showDelete={this.permissionsToEdit}
              showSelectAll={
                this.permissionsToEdit || this.permissionsToDownloadReports
              }
              onRef={(ref) => (this.smartTable = ref)}
              extraDataConfig={reportsExtraDataConfig}
              toolbarExtraComponents={
                this.permissionsToEdit && (
                  <TableHeaderAddButton
                    onClick={handleOpenDropZone}
                    name="upload-report-btn"
                  >
                    Upload Report
                  </TableHeaderAddButton>
                )
              }
              columns={helpers.byHashByIDConfToArray(
                reportsTableColConfig.tableColById,
                tableColConfigByHashWithExtraData ||
                  reportsTableColConfig.tableColByHash
              )}
              customRowActions={customRowActions}
              customToolbarActions={customToolbarActions}
            />
          </div>

          <div className={styles.tableWrap}>
            <h3 className="ttl ttl-3">Report History</h3>
            <SmartTable
              columns={reportsHistoryTableColConfig}
              resource={`requisitions/${reqID}/rep_histories`}
              showPagination={true}
              showSelect={this.permissionsToEdit}
              showDelete={this.permissionsToEdit}
              showSelectAll={this.permissionsToEdit}
            />
          </div>
        </div>
      </div>
    );
  }
}

LocationsContainer.propTypes = {
  reqID: PropTypes.string.isRequired,
};

const mapStateToProps = ({ specimenLocations }) => ({
  addSpecimenLocationModalIsOpen:
    specimenLocations.addSpecimenLocationModalIsOpen,
});

export default compose(
  connect(mapStateToProps, null),
  withDropZone,
  withModal,
  withNotificationCenter()
)(LocationsContainer);
