const getFilteredIds = (ids, inputsByHash) => {
  let filteredIds = [];

  const hideFields = {
    default: ["collaborator", "sales", "physician", "clientId", "labId"],
    CLNT: ["collaborator", "sales", "physician", "labId"],
    COLA: ["clientId", "sales", "physician", "labId"],
    PHYS: ["clientId", "collaborator", "sales", "labId"],
    SLS: ["clientId", "collaborator", "physician", "labId"],
    STF: ["collaborator", "sales", "physician", "clientId"]
  }

  const userRoleCodeValue = inputsByHash['userRoleCode'].value;
  if (userRoleCodeValue && hideFields.hasOwnProperty(userRoleCodeValue)) {
    filteredIds = ids.filter(id => {
      return hideFields[userRoleCodeValue].indexOf(id) < 0
    });
  } else {
    filteredIds = ids.filter(id => {
      return hideFields['default'].indexOf(id) < 0
    });
  }

  return filteredIds;
}

const dynamicValidationInputId = {
  CLNT: "clientId",
  COLA: "collaborator",
  PHYS: "physician",
  SLS: "sales",
  STF: "labId"
}

export {getFilteredIds, dynamicValidationInputId}