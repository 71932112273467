import { useState } from "react";
import dataProvider, { DOWNLOAD_FILE } from "providers/dataProvider";
import { useNotificationCenter } from "NotificationCenter";

const useDownloader = ({ resourceAccessor }) => {
  const notificationCenter = useNotificationCenter();
  const [dataLoading, setDataLoading] = useState(false);

  const handleDownloadClick = (rows) => {
    setDataLoading(true);
    dataProvider(DOWNLOAD_FILE, resourceAccessor(rows[0]), { data: {} })
      .then((response) => {
        setDataLoading(false);
        let url = response.data.url;
        if (response.data.hasOwnProperty("url")) {
          url = response.data.url;
        } else {
          url = response.data;
        }
        window.open(url);
        notificationCenter.showDownloadNotification(url);
      })
      .catch((error) => {
        setDataLoading(false);
        notificationCenter.show(error.message, "error");
      });
  };

  return { handleDownloadClick, dataLoading };
};

export default useDownloader;
