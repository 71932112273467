import moment from "moment";
import barcodeXmlLayout from "./barcodeLayout";

export default (dymo, data) => {
  const testCodes = data.testCodes ? data.testCodes.replace(/,/g, "") : "";
  const patientDob =
    data.hasOwnProperty("patientDob") && data.patientDob
      ? moment(data.patientDob).format("MM/DD/YY")
      : "";
  const dateCollected =
    data.hasOwnProperty("dateCollected") && data.dateCollected
      ? moment(data.dateCollected).format("MM/DD/YY")
      : "";
  const testCodesLengthLimit = 9;

  const getTextLayoutType1 = () => {
    return `
<ObjectData Name="TEXT">
${data.patientLastName}, ${data.patientFirstName}
DOB ${patientDob}, DOC ${dateCollected} ${testCodes}
</ObjectData>`;
  };

  const getTextLayoutType2 = () => {
    const testCodesFirstRow = testCodes.slice(0, testCodesLengthLimit);
    const testCodesRest = testCodes.slice(testCodesLengthLimit);
    return `
<ObjectData Name="TEXT">
${data.patientLastName}, ${data.patientFirstName}
DOB ${patientDob}, DOC ${dateCollected} ${testCodesFirstRow}
${testCodesRest}
</ObjectData>`;
  };

  const getLabelRecords = () => {
    let labelRecords = ``;
    for (let i = 1; i <= 6; i++) {
      let barcode = data.id;
      if (i === 5) {
        barcode += "S";
      } else if (i === 6) {
        if (data.isShowDp) {
          barcode += "DP";
        } else {
          continue;
        }
      }

      labelRecords += `<LabelRecord>
                            ${
                              testCodes.length > testCodesLengthLimit
                                ? getTextLayoutType2()
                                : getTextLayoutType1()
                            }
                            <ObjectData Name="BARCODE">${barcode}</ObjectData>
                        </LabelRecord>`;
    }

    return labelRecords;
  };

  let printParams = `<LabelWriterPrintParams>
                        <JobTitle>CellarTracker Labels</JobTitle>
                            <PrintQuality>BarcodeAndGraphics</PrintQuality>
                        <TwinTurboRoll>Left</TwinTurboRoll>
                    </LabelWriterPrintParams>`;

  let labelSetXml = `<LabelSet>
                        ${getLabelRecords()}
                    </LabelSet>`;

  dymo.print(
    "DYMO LabelWriter 450 Twin Turbo",
    barcodeXmlLayout,
    printParams,
    labelSetXml
  );
};
