import {
    INIT_CACHE_RESOURCE,
    SET_CACHE_RESOURCE_VALUE
} from './../config/actionTypes';

const initState = {
    cacheById: [],
    cacheByHash: {}
}

export default (state = initState, action) => {
    switch (action.type) {
        case INIT_CACHE_RESOURCE:
            return {
                ...state,
                cacheById: [...state.cacheById, action.payload.id],
                cacheByHash: {
                    ...state.cacheByHash
                }
            }

        // case DATA_LOADING:
        //     return {
        //         ...state,
        //         cacheByHash: {
        //             ...state.cacheByHash,
        //             [action.payload.id]: {
        //                 ...state.cacheByHash[action.payload.id],
        //                 data: action.payload.value
        //             }
        //         }
        //     }

        case SET_CACHE_RESOURCE_VALUE:
            return {
                ...state,
                cacheByHash: {
                    ...state.cacheByHash,
                    [action.payload.id]: {
                        ...state.cacheByHash[action.payload.id],
                        data: action.payload.value
                    }
                }
            }

        default:
            return state
    }
}