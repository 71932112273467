import React from "react";
import MyChip from "../../../MyChip";
import styles from "./SearchTags.module.scss";
import { useSmartFilter } from "SmartFilterProvider";

const SearchTags = () => {
  const {
    inputsByHash,
    inputsById,
    clearAllTagValues,
    clearTagValue,
  } = useSmartFilter();

  let counter = 0;

  return (
    <div className={styles.tagsWrap}>
      {inputsById.map(id => {
        const input = inputsByHash[id];
        const viewValue = input.viewValue;
        const chipLabel =
          input.subType === "singleCheckbox"
            ? input.label
            : input.label + ": " + viewValue;
        if (
          input.subType === "singleCheckbox" &&
          viewValue &&
          viewValue.length === 0
        )
          return false;
        if (viewValue === undefined || viewValue === "") return false;

        counter++;
        return (
          <MyChip
            key={id}
            label={chipLabel}
            onDelete={clearTagValue.bind(this, id)}
          />
        );
      })}

      {counter > 0 ? (
        <div className={styles.clearAllButton} onClick={clearAllTagValues}>
          Clear All
        </div>
      ) : null}
    </div>
  );
};
export default SearchTags;
