export default [
  {
    id: "userFirstName",
    label: "First Name",
  },
  {
    id: "userLastName",
    label: "Last Name",
  },
  {
    id: "userEmail",
    label: "Email",
  },
  {
    id: "userName",
    label: "Username",
  },
  {
    id: "userRoleCode",
    type: "autocomplete",
    resource: "roles",
    accessor: (d) => ({ value: d.roleCode, label: d.roleDesc }),
    label: "Roles",
  },
  {
    id: "userStatusCode",
    label: "Enabled/Disabled",
    type: "select",
    options: [
      { value: "LCK", label: "Disable" },
      { value: "ACT", label: "Enable" },
    ],
  },
];
