import React, {PureComponent} from 'react';
import styles from './TimeContainer.module.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import clsx from "clsx";

class Time extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...props
        }
    }


    // componentDidUpdate(prevProps) {
    //     if (prevProps.value !== this.props.value) {
    //         let date = '';
    //
    //         if (this.props.value !== ''){
    //             date = Date.parse(this.props.value)
    //         }
    //
    //         this.setState({
    //             time: date
    //         })
    //     }
    // }

    handleChange = (time) => {
        // this.setState({time})
        // console.log(time);
        // const value = time === '' ? '' : moment(time).locale('de').format('LT')
        let e = {target: { value: time, name: this.props.inputId}}

        this.props.handleInputs(e)
    }

    render() {
        const {input} = this.props;

        return (
            <div className={styles.root}>

                <DatePicker
                    placeholderText={`${input.yup ? '*' : ''}${input.label}`}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    className={clsx({
                        [styles.field]: true,
                        [styles.error]: input.error
                    })}
                    selected={input.value}
                    onChange={this.handleChange}
                />
            </div>

        );
    }
}

export default Time;