import moment from 'moment'

export default function (year, month, date) {
  if (!moment(`${month}/${date}/${year}`, 'M/D/YYYY',true).isValid()) {
    throw new Error(`Date ${month}/${date}/${year} is invalid`)
  }

  let inputDate = new Date(year, month - 1, date);
  let today = new Date();
  today.setHours(0, 0, 0, 0);

  if (inputDate > today) {
    throw new Error(`Future date ${month}/${date}/${year} not allowed`)
  }

  return true
}