import {
    formatServerDateTimeToTimeZoneWithAbbr,
} from 'utils/dateTimeFormat.js';
import { toFixed } from 'utils/textFormat.js';

export default {
    inputsByHash: {
        panelIds: {
            label: 'Panel Tests',
            type: 'checkbox',
            resourceAccessor: (d) => {
                return (`requisitions/${d.reqID}/panels`)
            },
            accessor: (d) => {
                return ({value: d.id, label: `${d.panelCode} - ${d.panelDesc}`})
            }
        },
        testIds: {
            label: 'A La Carte Tests',
            type: 'checkbox',
            resourceAccessor: (d) => {
                return (`requisitions/${d.reqID}/single_tests`)
            },
            accessor: (d) => {
                return ({value: d.id, label: `${d.testCode} - ${d.testDesc}`})
            }
        }
    },
    inputsById: [
        'panelIds', 'testIds'
    ],

    tableColConfig: {
        tableColById: [
            'testCode', 'testDesc', 'processDate', 'result', 'reportableResult', 'interpretation', 'testFailCodes', 'testPassCodes', 'isRetest'
        ],

        tableColByHash: {
            testCode: {
                label: 'Test Code'
            },

            testDesc: {
                label: 'Test Name'
            },
            processDate: {
                label: 'Processed At',
                accessor: (d, rowAccessorExtraData) => formatServerDateTimeToTimeZoneWithAbbr(d.processDate, 'dateTime', rowAccessorExtraData.reqData.labTz, rowAccessorExtraData.reqData.labTzAbbr),
            },
            result: {
                label: 'Result',
                accessor: d => !isNaN(d.result) ? toFixed(d.result, d.significantDigits) : d.result,
            },
            reportableResult: {
                label: 'Reportable Result'
            },
            interpretation: {
                label: 'Interpretation'
            },
            testFailCodes: {
                label: 'Fail Code',
                cache: true,
                editAccessor: (row, rowAccessorExtraData) => {
                    return rowAccessorExtraData.isEditAvailable
                },
                autoSave: {
                    resourceAccessor: (d) => {
                        return (`requisitions/${d.reqID}/req_tests/${d.testID}/fail_codes`)
                    }
                },
                type: 'testFailCodeEditTableCell',
                extraData: {
                    extraDataById: ['testFailCodes'],
                    extraDataByHash: {
                        testFailCodes: {
                            resource: 'reasons/FAIL'
                        }
                    }
                }
            },
            testPassCodes: {
                label: 'Pass Code',
                cache: true,
                editAccessor: (row, rowAccessorExtraData) => {
                    return rowAccessorExtraData.isEditAvailable
                },
                autoSave: {
                    resourceAccessor: (d) => {
                        return (`requisitions/${d.reqID}/req_tests/${d.testID}/pass_codes`)
                    }
                },
                type: 'testPassCodeEditTableCell',
                extraData: {
                    extraDataById: ['testPassCodes'],
                    extraDataByHash: {
                        testPassCodes: {
                            resource: 'reasons/PASS'
                        }
                    }
                }
            },
            isRetest: {
                label: 'Retest',
                accessor: (d) => {
                    return d.isRetest === 1 ? 'Yes' : 'No'
                }
            }

        }
    }
}