import React, { PureComponent } from "react";
import { SmartTable } from "components/ui_components/table";
import { compose } from "redux";
import withForm from "withForm";
import dataProvider, { CREATE } from "providers/dataProvider";
import AddWidgetContainer from "../AddWidget/AddWidgetContainer";
import { withNotificationCenter } from "NotificationCenter";
import helpers from "utils/helpers";
import styles from "./ICDGroup.module.scss";
import Can from "Can";
import { userPermissions } from "config/userRoles";
// import { WidgetType1 } from 'components/ui_components/Widgets';
import ClientICDNotes from "components/pages/requisitions/RequisitionsShow/Tabs/Billing/ClientICDNotes/ClientICDNotes.js";
import { clientICDNotesConfig } from "config/resources/requisitionsResource/billingTab";

class ICDGroupContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.permissionsToEdit = helpers.can(
      userPermissions.reqEntity.show.reportsDelivery.edit
    );

    this.state = {
      dataLoading: false,
      addWidgetKey: Math.random().toString(36).substring(7),
    };
  }

  resetAddWidgetKey = () => {
    this.setState({
      addWidgetKey: Math.random().toString(36).substring(7),
    });
  };

  handleSubmit = () => {
    const {
      notificationCenter,
      isFormValid,
      getSubmitData,
      resetErrors,
      reqID,
      clearInputs,
      isInputValid,
    } = this.props;

    resetErrors().then(() => {
      const isSelectIcsValid = isInputValid("selectIcd", false);
      const isIcdCodeValid = isInputValid("icdCode", false);
      const isIcdDescValid = isInputValid("icdDesc", false);

      if (!isSelectIcsValid && !isIcdCodeValid && !isIcdDescValid) {
        isFormValid();
        return;
      }

      if (isIcdCodeValid) {
        if (!isInputValid("icdDesc")) return;
      }

      if (isIcdDescValid) {
        if (!isInputValid("icdCode")) return;
      }

      this.setState({
        dataLoading: true,
      });

      const submitData = getSubmitData();

      let data = {};
      let customIcd = {};

      if (submitData.icdCode) customIcd.icdCode = submitData.icdCode;
      if (submitData.icdDesc) customIcd.icdDesc = submitData.icdDesc;
      if (submitData.icdCode || submitData.icdDesc) data.customIcd = customIcd;
      if (submitData.selectIcd) data.icdIds = submitData.selectIcd;

      dataProvider(CREATE, `requisitions/${reqID}/req_icds`, { data: data })
        .then((response) => {
          this.setState({
            dataLoading: false,
          });
          notificationCenter.show("Successfully created", "info");
          this.smartTable.loadData();
          this.resetAddWidgetKey();
          clearInputs();
        })
        .catch((error) => {
          notificationCenter.show(error.message, "warning");
          this.setState({
            dataLoading: false,
          });
        });
    });
  };

  render() {
    const {
      handleInputs,
      inputsById,
      inputsByHash,
      reqID,
      tableConfig,
      reqData,
    } = this.props;
    const { dataLoading } = this.state;

    return (
      <div className={styles.root}>
        <Can allowedRoles={userPermissions.reqEntity.show.reportsDelivery.edit}>
          <AddWidgetContainer
            key={this.state.addWidgetKey}
            handleInputs={handleInputs}
            inputsById={inputsById}
            inputsByHash={inputsByHash}
            dataLoading={dataLoading}
            handleSubmit={this.handleSubmit}
            widgetTitle="ICD"
            reqID={reqID}
            inputWrapStyles={`width: 50%;`}
            widgetWrapStyles={"padding: 20px 0 0; "}
          />
        </Can>
        <ClientICDNotes
          reqData={reqData}
          setReqDataByKey={this.props.setReqDataByKey}
          withFormConfig={{
            inputsByHash: clientICDNotesConfig.formConfig.inputsByHash,
            inputsById: clientICDNotesConfig.formConfig.inputsById,
            initData: reqData,
          }}
        />
        <div className={styles.tableWrap}>
          <SmartTable
            resource={`requisitions/${reqID}/req_icds`}
            showPagination={true}
            showSelect={this.permissionsToEdit}
            showDelete={this.permissionsToEdit}
            showSelectAll={this.permissionsToEdit}
            onRef={(ref) => (this.smartTable = ref)}
            columns={tableConfig}
          />
        </div>
      </div>
    );
  }
}

export default compose(withNotificationCenter(), withForm())(ICDGroupContainer);
