import { combineReducers } from "redux";

import layout from "./layout";
import notifications from "./notifications";
import smartFilter from "./smartFilter";
import specimenLocations from "./specimenLocations";
import statusFilter from "./statusFilter";
import withResource from "./withResource";
import auth from "./auth";

export default combineReducers({
    layout,
    notifications,
    smartFilter,
    specimenLocations,
    statusFilter,
    withResource,
    auth
});