import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import styled from "styled-components";
import theme from "config/theme";
import { leaf, getShadeColor } from "utils/helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const StyledButtonBase = styled(({ styles, children, ...rest }) => (
  <ButtonBase {...rest}>{children}</ButtonBase>
))`
  && {
    ${(props) => props.styles}
  }
`;

const StyledButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const parseThemePaletteColor = (themePalette, color) => {
  try {
    return leaf(themePalette, color);
  } catch (e) {
    console.error(`Paring Error`);
    console.error(`Theme palette doesn't have color by key ${color}`);
    console.error(e);
  }
};

const BaseButton = ({
  onClick,
  children,
  color,
  themePaletteColor,
  styles,
  variant,
  defaultStyles,
  containedHoverRule,
  outlinedHoverRule,
  loading,
}) => {
  const themeColorHex = themePaletteColor
    ? parseThemePaletteColor(theme.palette, themePaletteColor)
    : null;
  const mergedPropsColor = color || themeColorHex;

  const classes = useStyles();

  let renderBackgroundColor = null;
  let renderColor = null;
  let hoverStyles = null;

  if (mergedPropsColor) {
    if (variant === "contained") {
      renderBackgroundColor = `background-color: ${mergedPropsColor};`;
      renderColor = `color: ${theme.palette.getContrastText(
        mergedPropsColor
      )};`;
      hoverStyles = `&:hover {
        background-color: ${getShadeColor(
          mergedPropsColor,
          containedHoverRule.shade,
          containedHoverRule.transparency
        )};
      }`;
    } else if (variant === "outlined") {
      renderColor = `color: ${mergedPropsColor};`;
      hoverStyles = `&:hover {
        background-color: ${getShadeColor(
          mergedPropsColor,
          outlinedHoverRule.shade,
          outlinedHoverRule.transparency
        )};
      }`;
    }
  }

  let renderStyles = `
    ${defaultStyles}
    ${renderColor || ""}
    ${renderBackgroundColor || ""}
    transition: background-color .5s;
    ${hoverStyles}
    ${styles}
  `;

  if (loading) {
    renderStyles += `
      background-color: rgba(0, 0, 0, 0.3);
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
      `;
  }

  return (
    <StyledButtonWrapper>
      <StyledButtonBase
        onClick={onClick}
        styles={renderStyles}
        disabled={loading}
      >
        {children}
      </StyledButtonBase>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </StyledButtonWrapper>
  );
};

export default BaseButton;

BaseButton.defaultProps = {
  color: null,
  loading: false,
  themePaletteColor: null,
  variant: "contained",
  containedHoverRule: {
    shade: 0,
    transparency: 0.7,
  },
  outlinedHoverRule: {
    shade: 99,
    transparency: 0.1,
  },
  styles: ``,
};
