import React from 'react';
import styles from './Footer.module.scss';

function Foooter(props) {
  return (
    <div className={styles.footer}>
      <div className={styles.footerLeftCol}>
        Copyright PHD | 2020
      </div>
      <div className={styles.footerRightCol}>
        <p>Contact information for LIS administrator</p>
        <a href="mailto:Lishelp@pulstest.com">Lishelp@pulstest.com</a>
      </div>
    </div>
  );
}

export default Foooter;