import React from 'react'
import SmartTable from 'components/ui_components/table/SmartTable'
import dataProvider, { UPDATE } from "providers/dataProvider";
import { compose } from 'redux'
import { withNotificationCenter } from 'NotificationCenter';
import { Save } from '@material-ui/icons'
import helpers from 'utils/helpers';

const tableColConfig = {
  tableColById: [
    'testCode',
    'testDesc',
    'testFailCodes',
    'testPassCodes',
    'result',
  ],
  tableColByHash: {
    testCode: {
      label: 'Test Code'
    },
    testDesc: {
      label: 'Test Name'
    },
    result: {
      type: 'processingResultEditTableCell',
      label: 'Test Result',
      edit: true
    },
    testFailCodes: {
      label: 'Fail Code',
      cache: true,
      edit: true,
      autoSave: {
        resourceAccessor: (d) => {
          return (`requisitions/${d.reqID}/req_tests/${d.testID}/fail_codes`)
        }
      },
      type: 'testFailCodeEditTableCell',
    },
    testPassCodes: {
      label: 'Pass Code',
      cache: true,
      edit: true,
      autoSave: {
        resourceAccessor: (d) => {
          return (`requisitions/${d.reqID}/req_tests/${d.testID}/pass_codes`)
        }
      },
      type: 'testPassCodeEditTableCell',
    },
  }
}

let smartTableRef = {};

const ProcessingSubTable = (props) => {
  const { reqID, notificationCenter, collapsibleTableProps } = props;

  const tableFilter = {
    testCodes: collapsibleTableProps.filter.testCodes || '',
    testComboCode: collapsibleTableProps.filter.testComboCode || '',
    isRetest: '0',
    isNonResultedOnly: collapsibleTableProps.filter.isNonResultedOnly,
  }

  if (tableFilter.testCodes === '' && tableFilter.testComboCode === '') {
    tableFilter.isCalculated = '0'
  }

  const rowNotEdited = (row, extraData) => {
    let response = true;

    extraData.inputDataById.forEach(id => {
      if (extraData.inputDataByHash[id] !== row[id]) {
        response = false
      }
    })

    return response;
  }

  const handleSaveRowClick = (rows, inputDataByHash) => {
    const row = rows[0];

    const data = {
      "reqTests": [
        { "reqTestId": row.id, "result": inputDataByHash.result }
      ]
    }

    dataProvider(UPDATE, `requisitions/${reqID}/req_tests/bulk_edit`, { data: data })
    .then((response) => {
      notificationCenter.show(response.message, 'info')
      smartTableRef.current.loadData();
    })
    .catch(error => {
      notificationCenter.show(error.message, 'warning')
    });
  }


  return (
    <SmartTable
      resource={`requisitions/${reqID}/req_tests`}
      columns={helpers.byHashByIDConfToArray(tableColConfig.tableColById, tableColConfig.tableColByHash)}
      extraDataLoading={props.extraDataLoading}
      isSubTable={true}
      showSelect={false}
      filter={tableFilter}
      extraData={props.extraData}
      perPage={-1}
      editableTable={true}
      showPagination={false}
      handleSubTableRowsInputData={props.handleSubTableRowsInputData}
      parentRow={props.parentRow}
      onRef={ref => (smartTableRef.current = ref)}
      customRowActions={[
        {
          icon: <Save/>,
          handleOnClick: handleSaveRowClick,
          tooltipText: 'Save',
          isHiddenAccessor: rowNotEdited
        }
      ]
      }
    />
  )
}

export default compose(
  withNotificationCenter(),
)(ProcessingSubTable);