export default [
  "collaborator",
  "clientId",
  "orderingClientPhysId",
  "eventId",
  "extOrderId",
  "labId",

  "patientDobMonth",
  "patientDobDate",
  "patientDobYear",
  "patientFirstName",
  "patientMiddleName",
  "patientLastName",
  "patientGenderCode",
  "patientRaceCode",
  "patientEthnicityCode",
  "patientSsn",

  "dateCollectedMonth",
  "dateCollectedDate",
  "dateCollectedYear",
  "timeCollectedHours",
  "timeCollectedMinutes",
  "timeCollectedAmPm",
  "collectCode",
  "collectedBy",

  "sbp",
  "dbp",
  "isSmoker",
  "isDiabetic",
  "isFamilyHistory",
  "isCovidVac",
  "famHistT2DCode",
  "isSelfT2DGest",
  "weight",
  "weightUom",
  "heightFeet",
  "heightInches",
  "heightCm",
  "heightUom",
];
