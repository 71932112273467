import React, { PureComponent } from "react";
import { SmartTable } from "components/ui_components/table";
import { compose } from "redux";
import { withNotificationCenter } from "NotificationCenter";
import withForm from "withForm";
import dataProvider, { CREATE } from "providers/dataProvider";
import AddWidgetContainer from "../AddWidget/AddWidgetContainer";
import styles from "./InsuranceGroupContainer.module.scss";
import Can from "Can";
import { userPermissions } from "config/userRoles";
import helpers from "utils/helpers";
import { billingTabConfig } from "config/resources/requisitionsResource";
import { Edit } from "@material-ui/icons";
import EditInsuranceModal from "components/pages/requisitions/RequisitionsShow/Tabs/Billing/InsuranceGroup/EditInsuranceModal.js";
import PrevInsuranceModal from "components/pages/requisitions/RequisitionsShow/Tabs/Billing/InsuranceGroup/PrevInsuranceModal.js";
import withModal from "withModal";
import { WidgetControlButton } from "components/ui_components/buttons";
import { Box } from "@material-ui/core";

const { billingConfig, prevInsurancesConfig } = billingTabConfig;

class InsuranceGroupContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.permissionsToEdit = helpers.can(
      userPermissions.reqEntity.show.reportsDelivery.edit
    );
    this.state = {
      dataLoading: false,
    };
  }

  handleSubmit = () => {
    const {
      notificationCenter,
      isFormValid,
      getSubmitData,
      resetErrors,
      reqID,
      clearInputs,
      billingID,
    } = this.props;

    resetErrors().then(() => {
      if (!isFormValid()) return;

      this.setState({
        dataLoading: true,
      });

      let allFieldsData = getSubmitData(false);

      let data = {
        ...getSubmitData(true, true, true),
      };

      if (
        allFieldsData.insuranceDobMonth &&
        allFieldsData.insuranceDobDate &&
        allFieldsData.insuranceDobYear
      ) {
        data.insuredDob = `${allFieldsData.insuranceDobMonth}/${allFieldsData.insuranceDobDate}/${allFieldsData.insuranceDobYear}`;
      }

      dataProvider(
        CREATE,
        `requisitions/${reqID}/billings/${billingID}/insurances`,
        { data }
      )
        .then((response) => {
          this.setState({
            dataLoading: false,
          });
          notificationCenter.show("Successfully created", "info");
          this.smartTable.loadData();
          clearInputs();
        })
        .catch((error) => {
          notificationCenter.show(error.message, "warning");
          this.setState({
            dataLoading: false,
          });
        });
    });
  };

  handleEditInsurance = () => {
    this.smartTable.loadData();
    this.props.handlePatientUpdate();
  };

  handleOnClickEditInsurance = (rows) => {
    this.props.handleOpenModal("editInsurance");

    this.funcComRef(rows[0]);
  };

  handleOpenPrevInsurancesModal = () => {
    this.props.handleOpenModal("prevInsurance");
  };

  render() {
    const {
      handleInputs,
      inputsById,
      inputsByHash,
      reqID,
      billingID,
      showAddForm,
      modalOpen,
      modalID,
      handleCloseModal,
      patientId,
    } = this.props;
    const { dataLoading } = this.state;

    return (
      <div className={styles.root}>
        {showAddForm && (
          <Can
            allowedRoles={userPermissions.reqEntity.show.reportsDelivery.edit}
          >
            <EditInsuranceModal
              modalIsOpen={modalID === "editInsurance" && modalOpen}
              reqID={reqID}
              billingID={billingID}
              handleCloseModal={handleCloseModal}
              editInsuranceItem={this.state.editInsuranceItem}
              handleEditInsurance={this.handleEditInsurance}
              forwardRef={(c) => {
                this.funcComRef = c;
              }}
              withFormConfig={{
                inputsById: billingConfig.formConfig.inputsById,
                inputsByHash: billingConfig.formConfig.inputsByHash,
              }}
            />
            <PrevInsuranceModal
              modalIsOpen={modalID === "prevInsurance" && modalOpen}
              reqID={reqID}
              patientId={patientId}
              handleCloseModal={handleCloseModal}
              onSubmitOk={this.handleEditInsurance}
              withFormConfig={{
                inputsById: prevInsurancesConfig.formConfig.inputsById,
                inputsByHash: prevInsurancesConfig.formConfig.inputsByHash,
              }}
            />
            <AddWidgetContainer
              handleInputs={handleInputs}
              inputsById={inputsById}
              inputsByHash={inputsByHash}
              dataLoading={dataLoading}
              handleSubmit={this.handleSubmit}
              widgetTitle="Insurance Information"
              reqID={reqID}
              widgetWrapStyles={"padding: 20px 0 0;"}
              rightContent={
                <Box component={"span"} mr={"10px"}>
                  <WidgetControlButton
                    onClick={this.handleOpenPrevInsurancesModal}
                  >
                    Previous Insurances
                  </WidgetControlButton>
                </Box>
              }
            />
          </Can>
        )}
        <div className={styles.tableWrap}>
          <SmartTable
            resource={`requisitions/${reqID}/billings/${billingID}/insurances`}
            showPagination={false}
            onRef={(ref) => (this.smartTable = ref)}
            columns={billingConfig.tableConfig}
            showSelect={this.permissionsToEdit && this.props.availableEdit}
            showDelete={this.permissionsToEdit && this.props.availableEdit}
            showSelectAll={this.permissionsToEdit && this.props.availableEdit}
            customRowActions={[
              {
                tooltipText: "Edit",
                icon: <Edit />,
                handleOnClick: this.handleOnClickEditInsurance,
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withModal,
  withNotificationCenter(),
  withForm({
    inputsById: billingConfig.formConfig.inputsById,
    inputsByHash: billingConfig.formConfig.inputsByHash,
  })
)(InsuranceGroupContainer);

InsuranceGroupContainer.defaultProps = {
  showAddForm: true,
  availableEdit: true,
};
