import viewInputsByHash from './viewInputsByHash';
import editInputsByHash from './editInputsByHash';
import inputsGroupsByHash from './inputsGroupsByHash';
import inputsGroupsById from './inputsGroupsById';

export { viewInputsByHash, editInputsByHash, inputsGroupsByHash, inputsGroupsById };

export default {
  viewInputsByHash: viewInputsByHash,
  editInputsByHash: editInputsByHash,
  inputsGroupsById: inputsGroupsById,
  inputsGroupsByHash: inputsGroupsByHash
}