import React from "react";
import { WidgetType1 } from "components/ui_components/Widgets";
import { WidgetControlButton } from "components/ui_components/buttons";
import BaseInputList from "components/ui_components/BaseInputList/BaseInputList";
import { uploadFormConfig } from "config/resources/batchCsvUpload";
import DropZoneModal from "components/DropZoneModal/DropZoneModal";
import withForm from "withForm";
import { compose } from "redux";
import withDropZone from "withDropZone";
import useUploader from "hooks/useUploader";

const UploadWidget = (props) => {
  const {
    handleInputs,
    inputsById,
    inputsByHash,
    dataLoading,
    reqID,
    inputWrapStyles,
    dropZoneFiles,
    handleCloseDropZone,
    dropZoneOpen,
    handleOpenDropZone,
    handleDropFiles,
    resetErrors,
    isFormValid,
    getSubmitData,
    onUploadOk,
  } = props;

  const uploader = useUploader();

  const handleClickProcess = () => {
    if (!isFormValid()) return;
    resetErrors();
    handleOpenDropZone();
  };

  const handleSubmitUpload = async () => {
    const { loadFileTypeCode } = getSubmitData();

    await uploader.upload({
      dropZoneFiles: dropZoneFiles,
      loadFileTypeCode: loadFileTypeCode,
    });

    handleCloseDropZone();
    onUploadOk();
  };

  return (
    <React.Fragment>
      <DropZoneModal
        modalOpen={dropZoneOpen}
        handleSubmit={handleSubmitUpload}
        handleDropFiles={handleDropFiles}
        handleCloseDropZone={handleCloseDropZone}
        isUploading={uploader.isUploading}
        dropZoneFiles={dropZoneFiles}
      />
      <WidgetType1
        title={"Batch Data Load"}
        content={
          <BaseInputList
            inputsById={inputsById}
            inputsByHash={inputsByHash}
            handleInputs={handleInputs}
            smartInputOptions={{ reqID: reqID }}
            inputWrapStyles={inputWrapStyles}
          />
        }
        headerRightContent={
          <WidgetControlButton onClick={handleClickProcess}>
            Process
          </WidgetControlButton>
        }
        showProgressBar={dataLoading}
        wrapStyles={`margin: 0 0 1rem`}
      />
    </React.Fragment>
  );
};

export default compose(withDropZone, withForm(uploadFormConfig))(UploadWidget);
