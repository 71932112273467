import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PressureToolIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M21.2,14.2v3.4c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-3.4c-1.4,0.3-2.5,1.4-2.7,2.7h-5.8v-1.4h1.4
	c1,0,1.9-0.6,2.4-1.4H6.3c-2.4,0-4.3-1.7-4.8-3.9C0.6,10.7,0,11.7,0,12.8c0,1.6,1.3,2.8,2.8,2.8h7V17H7.7c-1.9,0-3.5,1.6-3.5,3.5
	c0,1.9,1.6,3.5,3.5,3.5c1.9,0,3.5-1.6,3.5-3.5v-2.1H17c0.3,1.6,1.7,2.8,3.4,2.8c1.9,0,3.5-1.6,3.5-3.5C24,16,22.8,14.6,21.2,14.2z
	 M9.8,20.5c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1c0-1.2,0.9-2.1,2.1-2.1h2.1V20.5z M0,3.5v6.1C0.4,9.3,0.9,9,1.4,8.8V3.5
	c0-0.7,0.2-1.5,0.5-2.1C0.8,1.5,0,2.4,0,3.5z M21.2,4.3V3.5c0-1.2-0.9-2.1-2.1-2.1h-3.7C15,0.6,14.3,0,13.4,0h-7
	C4.9,0,2.8,1.3,2.8,3.5v5.7c0,1.9,1.6,3.5,3.5,3.5h9.1c0-2.6,0-9.8,0-9.9h3.6c0.4,0,0.7,0.3,0.7,0.7v0.8C18.2,4.7,17,6.4,17,8.4
	c0,2.3,1.6,4.2,3.5,4.2c1.9,0,3.5-1.9,3.5-4.2C24,6.4,22.8,4.7,21.2,4.3z M6.3,9.9c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
	C6.7,8.5,7,8.8,7,9.2C7,9.6,6.7,9.9,6.3,9.9z M6.3,4.2c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7C6.7,2.8,7,3.1,7,3.5
	C7,3.9,6.7,4.2,6.3,4.2z"/>
    </SvgIcon>
);

export default PressureToolIcon;