import { createMuiTheme } from "@material-ui/core/styles";
import themeSCSS from "config/theme.scss";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: themeSCSS["palette-primary-main"],
    },
    secondary: {
      main: themeSCSS["palette-secondary-main"],
    },
    secondary2: {
      main: themeSCSS["palette-secondary2-main"],
    },
    error: {
      main: themeSCSS["palette-error-main"],
    },
  },
  typography: {
    fontSize: 12,
  },
  mainLayout: {
    paddingLeft: themeSCSS["mainLayout-paddingLeft"],
    paddingRight: themeSCSS["mainLayout-paddingRight"],
  },
  smartViewSidebar: {
    width: themeSCSS["smartViewSidebar-width"],
  },
  sidebar: {
    backgroundColor: "#282828",
  },
  overrides: {
    MuiTableRow: {
      root: {
        height: "auto",
        "&.Mui-selected": {
          backgroundColor: "rgba(0, 0, 0, 0.08);",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.15);",
          },
        },
        "&.MuiTableRow-hover": {
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08);",
          },
        },
      },
    },
  },
});

export default theme;