import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EmailIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M21.6,2.1H2.4C1.1,2.1,0,3.2,0,4.6l0,14.8c0,1.4,1.1,2.5,2.4,2.5h19.2c1.3,0,2.4-1.1,2.4-2.5V4.6
	C24,3.2,22.9,2.1,21.6,2.1z M21.6,7.1L12,13.2L2.4,7.1V4.6l9.6,6.2l9.6-6.2V7.1z"/>
    </SvgIcon>
);

export default EmailIcon;