import React, { useState, useEffect } from 'react'
import dataProvider, { GET_ONE } from "providers/dataProvider";
import MainHeader from 'components/templateParts/MainHeader';
import SingleViewHeader from 'components/templateParts/SingleViewHeader/SingleViewHeader';
import { PhoneIcon, EmailIcon, Person3Icon } from 'icons';
import ChangePasswordWidget from './ChangePasswordWidget';
import { WidgetGroup } from 'components/ui_components/Widgets'
import { phoneFormat } from 'utils';
import authStorage from 'providers/authStorage.js';
import RolesWidgetComponent from 'components/pages/users/UserShow/RolesWidgetComponent.js';

const UserProfileShow = (props) => {
  const [data, setData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const access = authStorage.getAccess();

  useEffect(() => {
    const loadData = () => {
      dataProvider(GET_ONE, props.resource, { id: props.id })
      .then(({ data, total }) => {
        setData(data);
        setDataLoaded(true);
      })
    }

    loadData();
  }, [props.id, props.resource]);

  const widgetsConfigByHash = {
    about: {
      title: 'User’s Information',
      type: '1',
      iconsData: [
        {
          id: 'userPhone',
          title: 'Phone Number',
          value: phoneFormat(data.userPhone),
          icon: <PhoneIcon/>
        },
        {
          id: 'userEmail',
          title: 'Email',
          value: data.userEmail,
          icon: <EmailIcon/>
        },
        {
          id: 'userFirstName',
          title: 'User First Name',
          value: data.userFirstName,
          icon: <Person3Icon/>
        },
        {
          id: 'userLastName',
          title: 'User Last Name',
          value: data.userLastName,
          icon: <Person3Icon/>
        },
        {
          id: 'userRoleDesc',
          title: 'Role',
          value: data.userRoleDesc,
          icon: <Person3Icon/>
        },
        {
          id: 'entityName',
          title: 'Entity Name',
          value: data.entityName,
          icon: <Person3Icon/>
        },
        {
          id: 'isSubscribed',
          title: 'Subscribe to email notification',
          value: data.isSubscribed === 1 ? "Yes" : "No",
          icon: <EmailIcon/>
        },
      ]
    }
  }

  const widgetsConfigById = ['about']

  const widgetsConfigGroups = [
    ['about']
  ]

  if (!dataLoaded) return null;

  return (
    <div>
      <MainHeader title="User Profile"/>
      <SingleViewHeader
        editLink='/user-profile/edit'
        title={data.userFirstName + ' ' + data.userLastName}
        widgetsConfigByHash={widgetsConfigByHash}
        widgetsConfigById={widgetsConfigById}
        widgetsConfigGroups={widgetsConfigGroups}
      />

      <div className="mainSingleContainer">
        <WidgetGroup>
          <RolesWidgetComponent userDepartments={data.depts || [] }/>
          <ChangePasswordWidget userID={access.userId}/>
        </WidgetGroup>
      </div>
    </div>
  );
}

export default UserProfileShow