import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import theme from 'config/theme';

const StyledCheckbox = styled(Checkbox)`
    {
        &.Mui-checked {
            svg {
                color: ${props => props.checkedcolor};
            }
        }   
        svg {
            color: #333333;
        }
    }
`

const TableCheckbox = (props) => {
    return <StyledCheckbox {...props} color="default" checkedcolor={theme.palette.secondary.main}/>
}

export default TableCheckbox;