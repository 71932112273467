import React from 'react';
import Lock from '@material-ui/icons/Lock';
let yup = require('yup');

export default {
  label: 'Confirm New Password',
  type: 'password',
  yup: yup
  .string()
  .oneOf([yup.ref('newPwd'), null], "Passwords don't match")
  .required('Password confirm is required'),
  autocomplete: 'new-password',
  icon: <Lock/>,
}