import { useState } from "react";

export default function () {
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return [value, handleChangeTab];
}
