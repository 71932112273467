import React from "react";
import StatusTableCellValueContainer from "components/ui_components/table/StatusTableCellValue/StatusTableCellValueContainer";
import { formatServerDateTimeToLocal } from "utils/dateTimeFormat";

export default [
  {
    id: "id",
    label: "Upload ID",
  },
  {
    id: "loadDate",
    label: "Upload Date",
    accessor: (d) => formatServerDateTimeToLocal(d.loadDate, "dateTime"),
  },
  {
    id: "loadFileTypeDesc",
    label: "File Type",
  },
  {
    id: "totalCount",
    label: "Total Rows Uploaded",
  },
  {
    id: "successFailCount",
    label: "Success/Failed Rows",
    accessor: (d) => {
      return (
        <div>
          {d.successCount}/
          <StatusTableCellValueContainer status={"statusError"}>
            {d.errorCount}
          </StatusTableCellValueContainer>
        </div>
      );
    },
  },
  {
    id: "loadByName",
    label: "Uploaded By",
  },
];
