import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PencilIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M0,19v5h5L19.8,9.2l-5-5L0,19z M23.6,5.4c0.5-0.5,0.5-1.4,0-1.9l-3.1-3.1c-0.5-0.5-1.4-0.5-1.9,0l-2.4,2.4l5,5
	L23.6,5.4z"/>
    </SvgIcon>
);

export default PencilIcon;