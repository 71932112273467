import React, { useState } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase';
import { useNotificationCenter} from 'NotificationCenter'
import { WidgetType1 } from 'components/ui_components/Widgets'
import NewPasswordForm from './NewPasswordForm.js';
import { useAuthCenter } from 'AuthCenter';

const ChangePasswordWidget = () => {
  const [mode, setMode] = useState('init');
  const notificationCenter = useNotificationCenter();
  const authCenter = useAuthCenter();

  const handleSubmitNewPassword = (currentPassword, newPassword) => {
    authCenter.mfaChangePassword(currentPassword, newPassword)
    .then(({ message }) => {
      setMode('init');
      notificationCenter.show(message);
    })
    .catch(e => {
      notificationCenter.show(e.message, 'error');
    })
  }

  const content = <React.Fragment>
    {mode === 'init' &&
    <ButtonBase
      onClick={setMode.bind(this, 'newPassword')}
      className="button formButton formButton__mainOutlined">
      Change Password
    </ButtonBase>
    }
    {mode === 'newPassword' &&
      <NewPasswordForm onSubmitOk={handleSubmitNewPassword}/>
    }
  </React.Fragment>

  return (
    <WidgetType1
      title='Actions'
      content={content}
      wrapStyles={`min-width: 500px; width: auto;`}
      widgetContentStyles={`margin: 20px 0 0;`}
    />
  )
}

export default ChangePasswordWidget;