import React from "react";
import { SmartTable } from "components/ui_components/table";
import withModal from "withModal";
import { TableHeaderAddButton } from "components/ui_components/buttons";
import { compose } from "redux";
import AddModal from "components/pages/clients/ClientsShow/AddModal";
import { createLabConfig } from "config/resources/clientsResource";

const withFormConfig = {
  inputsById: createLabConfig.inputsById,
  inputsByHash: createLabConfig.inputsByHash,
};

const { inputsGroupsById, inputsGroupsByHash } = createLabConfig;

let smartTableRef = {};

const tableColConfig = [
  {
    id: "labCode",
    label: "Lab Code",
  },
  {
    id: "labName",
    label: "Lab Name",
  },
];

const LabsTab = ({
  modalOpen,
  handleOpenModal,
  handleCloseModal,
  modalID,
  clientId,
  isEditAvailable,
}) => {
  const handleSubmitOk = () => {
    smartTableRef.current.loadData();
  };

  return (
    <React.Fragment>
      {isEditAvailable && (
        <AddModal
          title={"Add Labs"}
          submitResource={`clients/${clientId}/labs`}
          clientId={clientId}
          modalOpen={modalOpen && modalID === "labModal"}
          handleCloseModal={handleCloseModal}
          onSubmitOk={handleSubmitOk}
          inputsGroupsById={inputsGroupsById}
          inputsGroupsByHash={inputsGroupsByHash}
          withFormConfig={withFormConfig}
        />
      )}

      <SmartTable
        onRef={(ref) => (smartTableRef.current = ref)}
        resource={`clients/${clientId}/labs`}
        perPage={-1}
        showPagination={false}
        showSelect={isEditAvailable}
        showSelectAll={isEditAvailable}
        deleteConfirmationMessagePostfix={"lab"}
        columns={tableColConfig}
        toolbarExtraComponents={
          isEditAvailable && (
            <div className="tableHeaderAddButtonGroup">
              <TableHeaderAddButton
                onClick={handleOpenModal.bind(this, "labModal")}
              >
                Lab
              </TableHeaderAddButton>
            </div>
          )
        }
      />
    </React.Fragment>
  );
};

export default compose(withModal)(LabsTab);
