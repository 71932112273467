import React from "react";
import { GET_ONE } from "providers/dataProvider";
import MainHeader from "components/templateParts/MainHeader";
import SingleViewHeader from "components/templateParts/SingleViewHeader/SingleViewHeader";
import withModal from "withModal";
import { compose } from "redux";
import { withNotificationCenter } from "NotificationCenter";
import { withAuthCenter } from "AuthCenter";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useTabs from "hooks/useTabs";
import Box from "@material-ui/core/Box";
import {
  widgetsConfigById,
  widgetsConfigGroups,
  getWidgetsConfigByHash,
} from "config/resources/clientsResource/show/widgetConfig.js";
import useLoadData from "hooks/useLoadData.js";
import {
  ClientHistoryTab,
  EventsTab,
  PhysicianTab,
  ClientNotesTab,
  LabsTab,
  SalesRepsTab,
  AuthorizedUserTab,
} from "components/pages/clients/ClientsShow/Tabs";
import ClientDocuments from "components/pages/clients/ClientsShow/ClientDocuments.js";
import ClientTestPrices from "components/pages/clients/ClientsShow/ClientTestPrices/ClientTestPrices.js";
import { userRoles } from "config/userRoles";
import { canNotByRoles } from "utils/permissions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    margin: "20px 0 0",
    background: theme.palette.secondary2.main,
    textTransform: "none",
  },

  tab: {
    textTransform: "none",
    fontWeight: 700,
    letterSpacing: "-0.1",
    cursor: "pointer",
    transition: ".5s",
    opacity: 0.5,
    fontSize: 14,
    padding: "25px 14.3px 20px",
    minWidth: "auto",
  },
  box: {
    padding: "20px 30px 0",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.box}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

const ClientsShowContainer = ({ modalOpen, handleCloseModal, ...props }) => {
  const [tabIndex, handleChangeTab] = useTabs();
  const clientId = props.match.params.id;
  const classes = useStyles();
  const isEditAvailable = canNotByRoles([userRoles.sales]);

  const { data, dataLoaded } = useLoadData(GET_ONE, props.resource, {
    id: clientId,
  });

  return (
    <div>
      <MainHeader title="View Client" />

      <SingleViewHeader
        editLink={isEditAvailable ? "/clients/" + clientId : false}
        title={dataLoaded && `${data.legacyClientId} - ${data.clientName}`}
        subtitle={data.clientId}
        widgetsConfigByHash={getWidgetsConfigByHash(data)}
        widgetsConfigById={widgetsConfigById}
        widgetsConfigGroups={widgetsConfigGroups}
      />

      <AppBar position="static" color="primary" className={classes.appBar}>
        <Tabs
          value={tabIndex}
          onChange={handleChangeTab}
        >
          <Tab label="Physicians" className={classes.tab} />
          <Tab label="Labs" className={classes.tab} />
          <Tab label="Events" className={classes.tab} />
          <Tab label="Documents" className={classes.tab} />
          <Tab label="Test Prices" className={classes.tab} />
          <Tab label="Client Notes" className={classes.tab} />
          <Tab label="Activity" className={classes.tab} />
          <Tab label="Sales Rep." className={classes.tab} />
          <Tab label="Authorized User" className={classes.tab} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0} className={classes.tabPanel}>
        <PhysicianTab clientData={data} clientId={clientId} isEditAvailable={isEditAvailable}/>
      </TabPanel>
      <TabPanel value={tabIndex} index={1} className={classes.tabPanel}>
        <LabsTab clientData={data} clientId={clientId} isEditAvailable={isEditAvailable}/>
      </TabPanel>
      <TabPanel value={tabIndex} index={2} className={classes.tabPanel}>
        <EventsTab clientId={clientId} isEditAvailable={isEditAvailable}/>
      </TabPanel>
      <TabPanel value={tabIndex} index={3} className={classes.tabPanel}>
        <ClientDocuments clientId={clientId} isEditAvailable={isEditAvailable}/>
      </TabPanel>
      <TabPanel value={tabIndex} index={4} className={classes.tabPanel}>
        <ClientTestPrices clientId={clientId} isEditAvailable={isEditAvailable}/>
      </TabPanel>
      <TabPanel value={tabIndex} index={5} className={classes.tabPanel}>
        <ClientNotesTab clientData={data}/>
      </TabPanel>
      <TabPanel value={tabIndex} index={6} className={classes.tabPanel}>
        <ClientHistoryTab clientId={clientId} />
      </TabPanel>
      <TabPanel value={tabIndex} index={7} className={classes.tabPanel}>
        <SalesRepsTab clientId={clientId} isEditAvailable={isEditAvailable}/>
      </TabPanel>
      <TabPanel value={tabIndex} index={8} className={classes.tabPanel}>
        <AuthorizedUserTab clientId={clientId}/>
      </TabPanel>
    </div>
  );
};

export default compose(
  withModal,
  withNotificationCenter(),
  withAuthCenter()
)(ClientsShowContainer);
