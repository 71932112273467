import helpers from "utils/helpers";
import { formatServerDateTimeToTimeZoneWithAbbr } from "utils/dateTimeFormat.js";
import {
  inputWeight,
  inputWeightUom,
  inputHeightFeet,
  inputHeightInches,
  inputHeightCm,
  inputHeightUom,
} from "config/inputs/heightWeight";
import {
  isCovidVac,
  famHistT2DCode,
  isSelfT2DGest,
} from "config/resources/requisitionsResource/createEditInputs";

const statusesAvailableForEdit = ["LOG", "ACC", "PROCESS"];
const isAvailableEditByStatus = (data) =>
  statusesAvailableForEdit.includes(data.reqStatusCode);

export default {
  colaId: {
    label: "Collaborator",
    type: "autocomplete",
    resource: "collaborators",
    relativeInputs: ["clientId", "copyToClientId"],
    resetRelativeInputs: [
      "clientId",
      "orderingClientPhysId",
      "eventId",
      "copyToClientId",
      "copyToClientPhysId",
    ],
    accessor: (d) => {
      return { value: d.id, label: d.colaName };
    },
    yup: helpers.getYupRequired("Collaborator"),
  },
  clientId: {
    label: "Client",
    type: "autocomplete",
    resource: "clients",
    relativeInputs: ["orderingClientPhysId", "eventId", "labId"],
    resetRelativeInputs: ["orderingClientPhysId", "eventId", "labId"],
    relativeInputsFilterAccessor: (d) => {
      return { colaId: d.colaId };
    },
    accessor: (d) => {
      return {
        value: d.id,
        label: `${d.legacyClientId} - ${d.clientName}`,
        extraSelectedOptionData: { clientTz: d.clientTz },
      };
    },
    yup: helpers.getYupRequired("Physician First Name"),
  },
  orderingClientPhysId: {
    label: "Physician",
    type: "autocomplete",
    relativeInputResourceAccessor: (d) => {
      return `clients/${d.clientId}/physicians`;
    },
    accessor: (d) => {
      return { value: d.id, label: `${d.physFirstName}, ${d.physLastName}` };
    },
    yup: helpers.getYupRequired("Physician First Name"),
  },
  copyToClientId: {
    label: "Copy to Client",
    type: "autocomplete",
    resource: "clients",
    relativeInputs: ["copyToClientPhysId"],
    resetRelativeInputs: ["copyToClientPhysId"],
    relativeInputsFilterAccessor: (d) => {
      return { colaId: d.colaId };
    },
    accessor: (d) => {
      return { value: d.id, label: `${d.id} - ${d.clientName}` };
    },
  },
  copyToClientPhysId: {
    label: "Copy to Physician",
    type: "autocomplete",
    relativeInputResourceAccessor: (d) => {
      return `clients/${d.copyToClientId}/physicians`;
    },
    accessor: (d) => {
      return { value: d.id, label: `${d.physFirstName}, ${d.physLastName}` };
    },
  },
  eventId: {
    label: "Client Events",
    type: "autocomplete",
    relativeInputResourceAccessor: (d) => {
      return `clients/${d.clientId}/events`;
    },
    accessor: (d) => {
      return { value: d.id, label: d.eventName };
    },
    yup: helpers.getYupRequired("Client Events"),
  },
  physFirstName: {
    label: "Physician First Name",
    yup: helpers.getYupRequired("Physician First Name"),
  },
  physLastName: {
    label: "Physician Last Name",
    yup: helpers.getYupRequired("Physician Last Name"),
  },
  extOrderId: {
    label: "External Order Id",
    submitValueAccessor: (d) => d.toString(),
  },
  labId: {
    label: "Lab",
    type: "autocomplete",
    yup: helpers.getYupRequired("Lab"),
    relativeInputResourceAccessor: (d) => {
      return `clients/${d.clientId}/labs`;
    },
    accessor: (d) => {
      return { value: d.labId, label: d.labName };
    },
  },

  extAccessionId: {
    label: "External Acc#.",
    submitValueAccessor: (d) => {
      console.log(d);
      return d ? d.toString() : d;
    },
  },
  isFasting: {
    label: "Fasting",
    type: "radio",
    options: [
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
  },
  dateCollectedMonth: {
    label: "Date Collected Month",
    yup: helpers.getYupDateMonthRequired("Collected Month"),
    excludeSubmit: true,
    setInitValueAccessor: (d) =>
      formatServerDateTimeToTimeZoneWithAbbr(
        d.dateCollected,
        "month",
        d.collectedTz
      ),
  },
  dateCollectedDate: {
    label: "Date Collected Day",
    yup: helpers.getYupDateDayRequired("Collected Day"),
    excludeSubmit: true,
    setInitValueAccessor: (d) =>
      formatServerDateTimeToTimeZoneWithAbbr(
        d.dateCollected,
        "day",
        d.collectedTz
      ),
  },
  dateCollectedYear: {
    label: "Date Collected Year",
    yup: helpers.getYupDateYearRequired("Collected Year"),
    excludeSubmit: true,
    setInitValueAccessor: (d) =>
      formatServerDateTimeToTimeZoneWithAbbr(
        d.dateCollected,
        "year",
        d.collectedTz
      ),
  },
  legacyIcdNote: {
    label: "Client ICD Note",
    type: "multiline",
  },
  timeCollectedHours: {
    label: "Time Collected Hour",
    yup: helpers.getYupTimeHoursRequired("Collected Hours"),
    excludeSubmit: true,
    setInitValueAccessor: (d) =>
      formatServerDateTimeToTimeZoneWithAbbr(
        d.dateCollected,
        "hours12",
        d.collectedTz
      ),
  },
  timeCollectedMinutes: {
    label: "Time Collected Minutes",
    yup: helpers.getYupTimeMinutesRequired("Collected Minutes"),
    excludeSubmit: true,
    setInitValueAccessor: (d) =>
      formatServerDateTimeToTimeZoneWithAbbr(
        d.dateCollected,
        "minutes",
        d.collectedTz
      ),
  },
  timeCollectedAmPm: {
    label: "Time Collected AM/PM",
    type: "select",
    options: [
      {
        value: "AM",
        label: "AM",
        shortKeyCode: "a",
      },
      {
        value: "PM",
        label: "PM",
        shortKeyCode: "p",
      },
    ],
    setInitValueAccessor: (d) =>
      formatServerDateTimeToTimeZoneWithAbbr(
        d.dateCollected,
        "tod",
        d.collectedTz
      ),
    excludeSubmit: true,
  },
  timeCentrifugedHour: {
    label: "Time Centrifuged Hour",
    yup: helpers.getYupRequired("Time Centrifuged Hour"),
    excludeSubmit: true,
  },
  timeCentrifugedMin: {
    label: "Time Centrifuged Minutes",
    yup: helpers.getYupRequired("Time Centrifuged Minutes"),
    excludeSubmit: true,
  },
  timeCentrifugedTod: {
    label: "Time Centrifuged AM/PM",
    type: "select",
    options: [
      {
        value: "AM",
        label: "AM",
        shortKeyCode: "a",
      },
      {
        value: "PM",
        label: "PM",
        shortKeyCode: "p",
      },
    ],
    excludeSubmit: true,
    setInitValueAccessor: (d) =>
      formatServerDateTimeToTimeZoneWithAbbr(
        d.dateCollected,
        "tod",
        d.collectedTz
      ),
  },
  collectCode: {
    label: "Method of Collection",
    type: "autocomplete",
    resource: "lovs/COLLECTIONMETHOD",
    accessor: (d) => ({
      value: d.collectionMethodCode,
      label: d.collectionMethodDesc,
    }),
  },
  collectedBy: {
    label: "Collected By",
  },

  patientCountryId: {
    label: "Country",
    type: "autocomplete",
    resource: "countries",
    relativeInputs: ["patientStateId"],
    resetRelativeInputs: ["patientStateId"],
    accessor: (d) => ({ value: d.id, label: d.countryLongName }),
    excludeSubmit: true,
  },
  patientStateId: {
    label: "State",
    type: "autocomplete",
    relativeInputResourceAccessor: (d) => {
      return `countries/${d.patientCountryId}/states`;
    },
    accessor: (d) => ({ value: d.id, label: d.stateLongname }),
    relativeValidationFromInputsIds: ["patientCountryId"],
    singleValidation: true,
    yup: helpers.getYupRequired("State"),
  },
  patientCity: {
    label: "City",
  },
  patientZip: {
    label: "Zip",
  },
  patientAddr1: {
    label: "Address 1",
  },
  patientAddr2: {
    label: "Address 2",
  },
  patientEmail: {
    label: "Email",
  },
  patientHomePhone: {
    label: "Home Phone",
  },
  patientWorkPhone: {
    label: "Work Phone",
  },
  patientDobMonth: {
    label: "Month",
    yup: helpers.getYupDateMonthRequired("Month"),
    submitValueAccessor: (d) => parseInt(d),
    inputWrapStyles: "max-width: 70px;",
  },

  patientDobDate: {
    label: "Day",
    yup: helpers.getYupDateDayRequired("Day"),
    submitValueAccessor: (d) => parseInt(d),
    inputWrapStyles: "max-width: 70px;",
  },

  patientDobYear: {
    label: "Year",
    yup: helpers.getYupDateYearRequired("Year"),
    submitValueAccessor: (d) => parseInt(d),
    inputWrapStyles: "max-width: 70px;",
  },

  patientFirstName: {
    label: "First Name",
    yup: helpers.getYupRequired("First Name"),
  },
  patientMiddleName: {
    label: "Middle Name",
  },
  patientLastName: {
    label: "Last Name",
    yup: helpers.getYupRequired("Last Name"),
  },
  patientGenderCode: {
    label: "Sex",
    yup: helpers.getYupRequired("Gender"),
    type: "select",
    options: [
      { value: "MALE", label: "Male" },
      { value: "FEMALE", label: "Female" },
    ],
  },
  patientRaceCode: {
    label: "Race",
    type: "autocomplete",
    resource: "lovs/RACE",
    accessor: (d) => ({ value: d.patientRaceCode, label: d.patientRaceDesc }),
  },
  patientEthnicityCode: {
    label: "Ethnicity",
    type: "autocomplete",
    resource: "lovs/ETHNICITY",
    accessor: (d) => ({
      value: d.patientEthnicityCode,
      label: d.patientEthnicityDesc,
    }),
  },
  patientSsn: {
    label: "Patient SSN",
  },
  weight: {
    ...inputWeight,
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  weightUom: {
    ...inputWeightUom,
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  heightFeet: {
    ...inputHeightFeet,
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  heightInches: {
    ...inputHeightInches,
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  heightCm: {
    ...inputHeightCm,
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  heightUom: {
    ...inputHeightUom,
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  isSmoker: {
    label: "Smoker",
    type: "radio",
    options: [
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  isDiabetic: {
    label: "Diabetic",
    type: "radio",
    options: [
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  isFamilyHistory: {
    label: "Family HX of MI",
    type: "radio",
    options: [
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  isPatientHistory: {
    label: "Patient HX of MI",
    type: "radio",
    options: [
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
    yup: helpers.getYupRequired("Patient HX of MI"),
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  isStress: {
    label: "Stress",
    type: "radio",
    options: [
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
    yup: helpers.getYupRequired("Stress"),
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  isLowHdl: {
    label: "Diabetic Medication",
    type: "radio",
    options: [
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
    yup: helpers.getYupRequired("Diabetic Medication"),
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  isLipidMed: {
    label: "Lipid Lowering Medication",
    type: "radio",
    options: [
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
    yup: helpers.getYupRequired("Lipid Lowering Medication"),
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  isHypertension: {
    label: "Hypertension",
    type: "radio",
    options: [
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
    yup: helpers.getYupRequired("Hypertension"),
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  isBloodMed: {
    label: "Blood Pressure Medication",
    type: "radio",
    options: [
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
    yup: helpers.getYupRequired("Blood Pressure Medication"),
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  isMedNecessity: {
    label: "Medical Necessity",
    type: "radio",
    options: [
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
    yup: helpers.getYupRequired("Medical Necessity"),
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  sbp: {
    label: "SBP",
    type: "number",
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  dbp: {
    label: "DBP",
    type: "number",
    fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  },
  isCovidVac: isCovidVac,
  famHistT2DCode: famHistT2DCode,
  isSelfT2DGest: isSelfT2DGest,
};
