import React, { useState } from "react";
import { clientEventRequisitions } from "config/resources/reportsResource";
import dataProvider, { DOWNLOAD_FILE } from "providers/dataProvider";
import withForm from "withForm";
import { compose } from "redux";
import { useNotificationCenter } from "NotificationCenter";
import { BaseForm } from "components/ui_components/Forms";
import {
  WidgetControlButton,
  ButtonGroup,
} from "components/ui_components/buttons";

const ClientEventRequisitions = (props) => {
  const { inputsByHash, inputsById, handleInputs } = props;
  const notificationCenter = useNotificationCenter();

  const [isLoading, setIsLoading] = useState(false);

  const handleClickSubmit = () => {
    const { isFormValid, resetErrors, getSubmitData } = props;

    if (isFormValid()) {
      setIsLoading(true);
      resetErrors();

      dataProvider(DOWNLOAD_FILE, "event_reqs/download", {
        data: getSubmitData(),
      }, {apiEndointType: 'report'})
        .then(({ data }) => {
          window.open(data.url);
          notificationCenter.showDownloadNotification(data.url);
          setIsLoading(false);
        })
        .catch((error) => {
          notificationCenter.show(error.message, "error");
          setIsLoading(false);
        });
    }
  };

  return (
    <div>
      <BaseForm
        inputsById={inputsById}
        inputsByHash={inputsByHash}
        handleInputs={handleInputs}
      />

      <ButtonGroup rootStyles={"padding: 20px 0 0;"}>
        <WidgetControlButton onClick={handleClickSubmit} loading={isLoading}>
          Download
        </WidgetControlButton>
      </ButtonGroup>
    </div>
  );
};

ClientEventRequisitions.propTypes = {};

export default compose(
  withForm({
    inputsByHash: clientEventRequisitions.inputsByHash,
    inputsById: clientEventRequisitions.inputsById,
  })
)(ClientEventRequisitions);
