import React, { Component } from 'react'
import styles from './BillingContainer.module.scss';
import { compose } from 'redux'
import PropTypes from "prop-types";
import { billingTabConfig } from "config/resources/requisitionsResource";
import ICDGroupContainer from './ICDGroup/ICDGroupContainer';
import BillingNotesGroupContainer from './BillingNotesGroup/BillingNotesGroupContainer';
import InsuranceGroupContainer from './InsuranceGroup/InsuranceGroupContainer.js';
import GuarantorInformationContainer from "./GuarantorInformation/GuarantorInformationContainer";
import DownloadUploadContainer from "./DownloadUpload/DownloadUploadContainer";
import PatientInformationContainer from './PatientInformation/PatientInformationContainer';
import dataProvider, { GET_ONE } from "providers/dataProvider";
import Can from 'Can';
import { userGroups, userPermissions } from 'config/userRoles';
import clsx from "clsx";
import helpers from 'utils/helpers';

const { patientInformation, guarantorInformation, icdConfig, billingNotesConfig } = billingTabConfig;

class BillingContainer extends Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedRows: [],
      isUploading: false,
      data: null,
      dataLoaded: false
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { reqID } = this.props;

    dataProvider(GET_ONE, `requisitions/${reqID}/billings`)
    .then(({ data, total }) => {
      if (!data) return;
      this.setState({
        dataLoaded: true,
        data: data
      })
    })
  }

  handlePatientUpdate = () => {
    this.props.handlePatientInformationUpdate();
    this.loadData();
  }

  render() {
    const { reqID, reqData } = this.props;
    const { data, dataLoaded } = this.state;

    return (
      <div className={styles.root}>
        <div className="mainSingleContainer">
          <div className={styles.main}>
            <div className={clsx([helpers.can(userGroups.superAdmins) ? styles.infoCol : styles.infoColFullWidth])}>
              <PatientInformationContainer
                reqID={reqID}
                data={reqData}
                handlePatientUpdate={this.handlePatientUpdate}
                withFormConfig={{
                  inputsByHash: patientInformation.editInputsByHash,
                  inputsById: patientInformation.editInputsById,
                  initData: reqData,
                }}
                viewConfig={{
                  inputsById: patientInformation.viewInputsById,
                  inputsByHash: patientInformation.viewInputsByHash,
                }}
              />
              {dataLoaded && <GuarantorInformationContainer
                reqID={reqID}
                billingID={data.id}
                data={data}
                widgetWrapStyles={`padding: 20px 0 0;`}
                inputsGroupsByHash={guarantorInformation.inputsGroupsByHash}
                inputsGroupsById={guarantorInformation.inputsGroupsById}
                withFormConfig={{
                  inputsByHash: guarantorInformation.editInputsByHash,
                  inputsById: guarantorInformation.editInputsById,
                  initData: data,
                  options: {
                    extraData: {
                      reqStatusCode: reqData.reqStatusCode
                    }
                  }
                }}
              />}

              {dataLoaded && <InsuranceGroupContainer
                reqID={reqID}
                billingID={data.id}
                patientId={reqData.patientId}
                handlePatientUpdate={this.handlePatientUpdate}
              />}

              <ICDGroupContainer
                withFormConfig={icdConfig.formConfig}
                tableConfig={icdConfig.tableConfig}
                reqID={reqID}
                reqData={reqData}
                setReqDataByKey={this.props.setReqDataByKey}
              />

              <BillingNotesGroupContainer
                withFormConfig={billingNotesConfig.formConfig}
                tableConfig={billingNotesConfig.tableConfig}
                reqID={reqID}
              />

            </div>
            <Can allowedRoles={userPermissions.reqEntity.downloadSection}>
              <div className={styles.uploadCol}>
                <DownloadUploadContainer reqID={reqID}/>
              </div>
            </Can>
          </div>
        </div>
      </div>
    );
  }
}

BillingContainer.propTypes = {
  reqID: PropTypes.string.isRequired
}


export default compose()(BillingContainer)