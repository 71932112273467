import React, { PureComponent } from "react";
import dataProvider, { GET_LIST } from "providers/dataProvider";
import style from "./StatusBar.module.scss";
import { tableColConfig } from "config/resources/requisitionsResource/index.js";
import { withAuthCenter } from "AuthCenter";
import { compose } from "redux";

class StatusBarContainer extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      statuses: {},
    };
  }

  componentDidMount() {
    this._isMounted = true;
    setTimeout(() => {
      this.loadData();
    }, 100);
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.onRef(undefined);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authCenter.token !== this.props.authCenter.token) {
      this.loadData();
    }
  }

  loadData = () => {
    dataProvider(GET_LIST, "requisitions/status_count").then(
      ({ data, total }) => {
        if (this._isMounted) {
          let statuses = {};
          data.forEach((d) => {
            statuses[d.reqStatusDesc] = d.reqStatusCount;
          });
          this.setState({ statuses });
        }
      }
    );
  };

  render() {
    let { statuses } = this.state;
    const { currentFilterStatus } = this.props;
    return (
      <div className={style.root}>
        {tableColConfig.groupsById.map((status, key) => {
          const isCurrentTab = currentFilterStatus === status;
          return (
            <div
              key={key}
              className={[style.item, isCurrentTab && style.active].join(" ")}
              onClick={() =>
                !isCurrentTab && this.props.handleChangeStatus(status)
              }
            >
              <span className={style.statusName}>{status}</span>
              <span className={style.statusCount}>{statuses[status] || 0}</span>
            </div>
          );
        })}
      </div>
    );
  }
}

export default compose(withAuthCenter())(StatusBarContainer);
