import React from "react";
import { SmartTable } from "components/ui_components/table";
import withModal from "withModal";
import { TableHeaderAddButton } from "components/ui_components/buttons";
import { compose } from "redux";
import AddModal from "components/pages/clients/ClientsShow/AddModal";
import { createSalesRepsConfig } from "config/resources/clientsResource";

let smartTableRef = {};

const withFormConfig = {
  inputsById: createSalesRepsConfig.inputsById,
  inputsByHash: createSalesRepsConfig.inputsByHash,
};

const { inputsGroupsById, inputsGroupsByHash } = createSalesRepsConfig;


const tableColConfig = [
  {
    id: "salesRepCode",
    label: "Sales Rep Code",
  },
  {
    id: "salesFirstName",
    label: "First Name",
  },
  {
    id: "salesMiddleName",
    label: "Middle Name",
  },
  {
    id: "salesLastName",
    label: "Last Name",
  },
  {
    id: "salesPhone",
    label: "Phone"
  }
];

const SalesRepsTab = ({
  modalOpen,
  handleOpenModal,
  handleCloseModal,
  modalID,
  clientId,
  isEditAvailable,
}) => {
  const handleSubmitOk = () => {
    smartTableRef.current.loadData();
  };

  return (
    <React.Fragment>
      {isEditAvailable && (
        <AddModal
        title={"Add Sales Reps"}
        submitResource={`clients/${clientId}/sales`}
        clientId={clientId}
        modalOpen={modalOpen && modalID === "salesRepsModal"}
        handleCloseModal={handleCloseModal}
        onSubmitOk={handleSubmitOk}
        inputsGroupsById={inputsGroupsById}
        inputsGroupsByHash={inputsGroupsByHash}
        withFormConfig={withFormConfig}
      />
      )}

      <SmartTable
        onRef={(ref) => (smartTableRef.current = ref)}
        resource={`clients/${clientId}/sales`}
        perPage={-1}
        showPagination={false}
        showSelect={isEditAvailable}
        showSelectAll={isEditAvailable}
        deleteConfirmationMessagePostfix={"sales"}
        columns={tableColConfig}
        toolbarExtraComponents={
          isEditAvailable && (
            <div className="tableHeaderAddButtonGroup">
              <TableHeaderAddButton
                onClick={handleOpenModal.bind(this, "salesRepsModal")}
              >
                Sales Reps
              </TableHeaderAddButton>
            </div>

          )
        }
      />
    </React.Fragment>
  );
};

export default compose(withModal)(SalesRepsTab);
