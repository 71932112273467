import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MainHeader from "components/templateParts/MainHeader";
import dataProvider, { UPDATE } from "providers/dataProvider";
import withForm from 'withForm';
import { physiciansResource } from "config/resources/physiciansResource";
import { compose } from 'redux'
import { withNotificationCenter } from 'NotificationCenter'
import BaseForm from 'components/ui_components/BaseForm/BaseForm';
import BaseFormControls from 'components/ui_components/BaseFormControls/BaseFormControls';

const styles = () => ({
  main: {
    margin: '32px 26px 0',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '30px 15px 30px'
  },
});

let withFormConfig = physiciansResource.createPhysConfig;
const inputsGroupsById = withFormConfig.inputsGroupsById;
const inputsGroupsByHash = withFormConfig.inputsGroupsByHash;

const PhysiciansEditContainer = (props) => {
  const { history, notificationCenter, classes, inputsById, inputsByHash, handleInputs } = props;

  const handleSubmit = () => {
    if (props.isFormValid()) {
      props.resetErrors();

      let data = props.getSubmitData();

      dataProvider(UPDATE, 'physicians', { data: data, id: props.id })
      .then((response) => {
        notificationCenter.show('Successfully updated', 'info')
        history.push('/physicians/' + response.data.id + '/show');
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
      });
    }
  }

  return (
    <div className={classes.root}>
      <MainHeader title="Edit Physician"/>
      <div className={classes.main}>
        <BaseForm
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          inputsGroupsById={inputsGroupsById}
          inputsGroupsByHash={inputsGroupsByHash}
        />

        <BaseFormControls
          type={'edit'}
          handleClickSubmit={handleSubmit}
          submitLabel="Update Physician"
          deleteConfirmationMessagePostfix={'physician'}
          resource="physicians"
          id={props.id}
        />
      </div>
    </div>
  );
}


export default compose(
  withForm({ ...withFormConfig, mode: 'edit' }),
  withStyles(styles),
  withNotificationCenter()
)(PhysiciansEditContainer)