import helpers from "utils/helpers";
import { formatServerDateTimeToLocal } from "utils/dateTimeFormat";
import { userRoles } from "config/userRoles";
import { concatWithSeparator } from "utils/textFormat";

let yup = require("yup");

const statusesForEditSpecialGuarantorFields = ["LOG", "ACC"];
const hideSpecialFields = (d, extraData) => {
  const isSelf = d === "SELF";
  const isSpecialStatus = !statusesForEditSpecialGuarantorFields.includes(
    extraData.reqStatusCode
  );
  if (isSelf && isSpecialStatus) return true;
};

yup.addMethod(yup.string, "notMatches", function (regExp, message) {
  return this.test("test-name", message, function (value) {
    const { createError } = this;
    const match = new RegExp(regExp).test(value);
    return !match || createError();
  });
});

export default {
  patientInformation: {
    viewInputsById: [
      "patientAddr1",
      "patientAddr2",
      "patientCity",
      "patientCountry",
      "patientState",
      "patientZip",
      "patientHomePhone",
      "patientWorkPhone",
      "patientSsn",
    ],
    viewInputsByHash: {
      patientCountry: {
        label: "Country",
      },
      patientState: {
        label: "State",
      },
      patientCity: {
        label: "City",
      },
      patientZip: {
        label: "Zip",
      },
      patientAddr1: {
        label: "Address 1",
      },
      patientAddr2: {
        label: "Address 2",
      },
      patientEmail: {
        label: "Email",
      },
      patientHomePhone: {
        label: "Home Phone",
      },
      patientWorkPhone: {
        label: "Work Phone",
      },
      patientDob: {
        label: "Date of Birth",
      },
      patientSsn: {
        label: "Patient SSN",
      },
    },

    editInputsById: [
      "patientAddr1",
      "patientAddr2",
      "patientCity",
      "patientCountryId",
      "patientStateId",
      "patientZip",
      "patientHomePhone",
      "patientWorkPhone",
      "patientSsn",
    ],
    editInputsByHash: {
      patientCountryId: {
        label: "Country",
        type: "autocomplete",
        resource: "countries",
        relativeInputs: ["patientStateId"],
        resetRelativeInputs: ["patientStateId"],
        accessor: (d) => ({ value: d.id, label: d.countryLongName }),
        excludeSubmit: true,
      },
      patientStateId: {
        label: "State",
        type: "autocomplete",
        relativeInputResourceAccessor: (d) => {
          return `countries/${d.patientCountryId}/states`;
        },
        accessor: (d) => ({ value: d.id, label: d.stateLongname }),
        relativeValidationFromInputsIds: ["patientCountryId"],
        singleValidation: true,
        yup: helpers.getYupRequired("State"),
      },
      patientCity: {
        label: "City",
        yup: helpers.getYupRequired("City"),
      },
      patientZip: {
        label: "Zip",
        yup: helpers.getYupRequired("Zip"),
      },
      patientAddr1: {
        label: "Address 1",
        yup: helpers.getYupRequired("Address 1"),
      },
      patientAddr2: {
        label: "Address 2",
      },
      patientEmail: {
        label: "Email",
        yup: helpers.getYupRequired("Email"),
      },
      patientHomePhone: {
        label: "Home Phone",
      },
      patientWorkPhone: {
        label: "Work Phone",
      },
      patientSsn: {
        label: "Patient SSN",
      },
    },
  },

  guarantorInformation: {
    viewInputsByHash: {
      guarantorGenderCode: {
        label: "Guarantor Gender",
        accessor: (d) => (d.guarantorGenderCode === "MALE" ? "Male" : "Female"),
      },
      guarantorFirstName: {
        label: "Guarantor First Name",
      },
      guarantorLastName: {
        label: "Guarantor Last Name",
      },
      guarantorMiddleName: {
        label: "Guarantor Middle Name",
      },
      guarantorDob: {
        label: "Date of Birth",
      },
      relationTypeDesc: {
        label: "Guarantor>Patient Relationship",
      },
      guarantorStateName: {
        label: "Guarantor State",
      },
      guarantorCity: {
        label: "Guarantor City",
      },
      guarantorZip: {
        label: "ZIP",
      },
      guarantorAddr1: {
        label: "Guarantor’s Address 1",
      },
      guarantorAddr2: {
        label: "Guarantor’s Address 2",
      },
    },
    viewInputsById: [
      "relationTypeDesc",
      "guarantorFirstName",
      "guarantorMiddleName",
      "guarantorLastName",
      "guarantorDob",
      "guarantorGenderCode",
      "guarantorAddr1",
      "guarantorAddr2",
      "guarantorCity",
      "guarantorStateName",
      "guarantorZip",
    ],
    editInputsByHash: {
      guarantorGenderCode: {
        label: "Guarantor Gender",
        yup: helpers.getYupRequired("Guarantor Gender"),
        type: "select",
        options: [
          { value: "MALE", label: "Male" },
          { value: "FEMALE", label: "Female" },
        ],
        hideVisibilityAccessor: (d, extraData) => {
          return hideSpecialFields(d, extraData);
        },
      },
      guarantorFirstName: {
        label: "Guarantor First Name",
        yup: helpers.getYupRequired("Guarantor First Name"),
        hideVisibilityAccessor: (d, extraData) => {
          return hideSpecialFields(d, extraData);
        },
      },
      guarantorLastName: {
        label: "Guarantor Last Name",
        yup: helpers.getYupRequired("Guarantor Last Name"),
        hideVisibilityAccessor: (d, extraData) => {
          return hideSpecialFields(d, extraData);
        },
      },
      guarantorMiddleName: {
        label: "Guarantor Middle Name",
        hideVisibilityAccessor: (d, extraData) => {
          return hideSpecialFields(d, extraData);
        },
      },
      guarantorDob: {
        label: "Date of Birth",
        yup: helpers.getYupRequired("Date of Birth"),
        hideVisibilityAccessor: (d, extraData) => {
          return hideSpecialFields(d, extraData);
        },
      },
      relationTypeCode: {
        label: "Guarantor>Patient Relationship",
        yup: helpers.getYupRequired("Guarantor>Patient Relationship"),
        type: "autocomplete",
        resource: "lovs/RELATIONTYPE",
        relativeHideVisibilityInputs: [
          "guarantorFirstName",
          "guarantorLastName",
          "guarantorMiddleName",
          "guarantorGenderCode",
          "guarantorDob",
        ],
        accessor: (d) => ({
          value: d.relationTypeCode,
          label: d.relationTypeDesc,
        }),
      },
      guarantorStateId: {
        label: "Guarantor State",
        yup: helpers.getYupRequired("Guarantor State"),
        type: "autocomplete",
        resource: "states",
        accessor: (d) => ({ value: d.id, label: d.stateLongname }),
      },
      guarantorCity: {
        label: "Guarantor City",
        yup: helpers.getYupRequired("Guarantor City"),
      },
      guarantorZip: {
        label: "ZIP",
        yup: helpers.getYupRequired("ZIP"),
      },
      guarantorAddr1: {
        label: "Guarantor’s Address 1",
        yup: helpers.getYupRequired("Guarantor’s Address 1"),
      },
      guarantorAddr2: {
        label: "Guarantor’s Address 2",
      },
    },
    editInputsById: [
      "relationTypeCode",
      "guarantorFirstName",
      "guarantorMiddleName",
      "guarantorLastName",
      "guarantorDob",
      "guarantorGenderCode",
      "guarantorAddr1",
      "guarantorAddr2",
      "guarantorCity",
      "guarantorStateId",
      "guarantorZip",
    ],
    inputsGroupsByHash: {
      group1: {
        ids: [
          [
            "relationTypeCode",
            "guarantorFirstName",
            "guarantorMiddleName",
            "guarantorLastName",
            "guarantorDob",
            "guarantorGenderCode",
            "guarantorAddr1",
            "guarantorAddr2",
            "guarantorCity",
            "guarantorStateId",
            "guarantorZip",
          ],
        ],
        inputStyles: `width: 25%;`,
      },
    },
    inputsGroupsById: ["group1"],
  },

  billingConfig: {
    formConfig: {
      inputsByHash: {
        billingOrder: {
          label: "Billing Order",
          type: "select",
          options: [
            { value: "1", label: "Primary" },
            { value: "2", label: "Secondary" },
            { value: "3", label: "3th" },
            { value: "4", label: "4th" },
          ],
          inputWrapStyles: `width: 50%;`,
          yup: helpers.getYupRequired("ICD Code"),
        },
        reqBillTypeCode: {
          label: "Bill To",
          type: "autocomplete",
          resource: "lovs/REQBILLTYPE",
          inputWrapStyles: `width: 50%;`,
          relativeInputsValueAccessor: (d) => {
            return d.insuranceCompany === 'N/A' ? 'NA' : null;
          },
          accessor: (d) => ({
            value: d.reqBillTypeCode,
            label: d.reqBillTypeDesc,
          }),
        },
        insuranceCompany: {
          label: "Insurance Name",
          type: "autocomplete",
          relativeInputs: ["insuranceCompanyNo", "reqBillTypeCode"],
          resource: "ins_co",
          inputWrapStyles: `width: 100%;`,
          accessor: (d) => {
            return {
              value: d.insCompanyName,
              label: `${d.insCompanyId} - ${d.insCompanyName}`,
              relatedValue: d.insCompanyId,
            }
          },
        },
        insuranceCompanyNo: {
          label: "Insurance ID",
          disabled: true,
          relativeInputsValueAccessor: (d) => {
            return d.insuranceCompany;
          },
          accessor: (d) => {
            return d.insuranceCompanyNo;
          },
          inputWrapStyles: `width: 33%;`,
        },
        policyHolder: {
          label: "Policy Holder",
          inputWrapStyles: `width: 33%;`,
        },
        policyNumber: {
          label: "Policy Number",
          inputWrapStyles: `width: 33%;`,
        },
        groupNo: {
          label: "Group Number",
          inputWrapStyles: `width: 100%;`,
        },
      },

      inputsById: [
        "billingOrder",
        "reqBillTypeCode",
        "insuranceCompany",
        "insuranceCompanyNo",
        "policyNumber",
        "policyHolder",
        "groupNo",
      ],
    },
    tableConfig: [
      {
        id: "billingorder",
        label: "Type",
        accessor: (d) => {
          const values = {
            1: "Primary",
            2: "Secondary",
            3: "3th",
            4: "4th",
          };
          return values[d.billingorder];
        },
      },
      {
        id: "reqBillTypeDesc",
        label: "Bill To",
      },
      {
        id: "insuranceCompanyNo",
        label: "Insurance ID",
      },
      {
        id: "insuranceCompany",
        label: "Insurance Name",
      },
      {
        id: "policyNo",
        label: "Policy Number",
      },
      {
        id: "policyHolder",
        label: "Policy Holder",
      },
      {
        id: "groupNo",
        label: "Group Number",
      },
    ],
  },

  prevInsurancesConfig: {
    formConfig: {
      inputsByHash: {
        billingOrder: {
          label: "Choose a new billing order",
          type: "select",
          options: [
            { value: "1", label: "Primary" },
            { value: "2", label: "Secondary" },
            { value: "3", label: "3th" },
            { value: "4", label: "4th" },
          ],
          inputWrapStyles: `width: 50%;`,
          yup: helpers.getYupRequired("New billing order"),
        },
      },

      inputsById: ["billingOrder"],
    },
    tableConfig: [
      {
        id: "reqId",
        label: "Req ID"
      },
      {
        id: "billingorder",
        label: "Billing Order"
      },
      {
        id: "guarantorName",
        label: "Guarantor Name",
        accessor: (d) =>
          concatWithSeparator(
            [d.guarantorFirstName, d.guarantorMiddleName, d.guarantorLastName],
            ", "
          ),
      },
      {
        id: "relationTypeDesc",
        label: "Patient Relationship",
      },
      {
        id: "guarantorDob",
        label: "Guarantor Dob",
      },
      {
        id: "guarantorGenderCode",
        label: "Guarantor Gender",
      },
      {
        id: "address",
        label: "Address",
        accessor: (d) =>
          concatWithSeparator(
            [
              d.guarantorAddr1,
              d.guarantorAddr2,
              d.guarantorCity,
              d.guarantorStateName,
              d.guarantorZip,
            ],
            ", "
          ),
      },
      {
        id: "",
        label: "Bill To",
      },
      {
        id: "insuranceCompanyNo",
        label: "Insurance ID",
      },
      {
        id: "insuranceCompany",
        label: "Insurance Name",
      },
      {
        id: "policyNo",
        label: "Policy No.",
      },
      {
        id: "policyHolder",
        label: "Policy Holder",
      },
      {
        id: "groupNo",
        label: "Group No.",
      },
    ],
  },

  icdConfig: {
    formConfig: {
      inputsByHash: {
        icdCode: {
          label: "ICD Code",
          yup: yup
            .string()
            .matches(
              /^([a-zA-Z][\w]+)/,
              "ICD Code should not start with number"
            )
            .notMatches(
              /[^0-9a-z]$/,
              "No special symbols are allowed at the end of the ICD Code name"
            )
            .notMatches(
              /^[E][8-9][0-9][0-9]$/,
              "ICD Codes E800-E999 not available"
            )
            .notMatches(
              /^V([0-7][0-9]|8[0-2])$/,
              "ICD Codes V01-V82 not available"
            )
            .notMatches(
              /^M(8[0-9][0-9][0-9]|9[0-8][0-9][0-9]|99[0-6][0-9]|9970)$/,
              "ICD Codes M8000-M9970 not available"
            )
            .required("ICD Code is required"),
          singleValidation: true,
        },
        icdDesc: {
          label: "ICD Description",
          yup: helpers.getYupRequired("ICD Description"),
          singleValidation: true,
        },
        selectIcd: {
          label: "Select ICD",
          type: "autocomplete",
          sort: {
            field: "icdCode",
            order: "asc",
          },
          subType: "multi",
          accessor: (d) => ({
            value: d.id,
            label: `${d.icdCode} - ${d.icdDesc}`,
          }),
          resource: "icds",
          yup: helpers.getYupRequired("ICD Description"),
          singleValidation: true,
        },
      },

      inputsById: ["icdCode", "icdDesc", "selectIcd"],
    },
    tableConfig: [
      {
        id: "icdCode",
        label: "ICD Code",
      },
      {
        id: "icdDesc",
        label: "ICD Description",
      },
    ],
  },
  billingNotesConfig: {
    formConfig: {
      inputsByHash: {
        note: {
          label: "Note",
          yup: helpers.getYupRequired("ICD Code"),
          type: "multiline",
          singleValidation: true,
        },
      },

      inputsById: ["note"],
    },
    tableConfig: [
      {
        id: "billNoteCreateDate",
        label: "Created At",
        accessor: (d) =>
          formatServerDateTimeToLocal(d.billNoteCreateDate, "dateTime"),
      },
      {
        id: "billNote",
        label: "Note",
      },
      {
        id: "billNoteCreateByName",
        label: "Created By",
      },
    ],
  },
  downloadUpload: {
    sectionsConfig: [
      {
        title: "Scanned Requisition",
        tableResourceAccessor: (d) => `requisitions/${d.reqID}/docs`,
        docTypeCode: "SCANREQ",
      },
      {
        title: "Insurance card",
        hideForRoles: [userRoles.sales],
        tableResourceAccessor: (d) => `requisitions/${d.reqID}/docs`,
        docTypeCode: "INSCARD",
      },
      {
        title: "Add-On Document",
        hideForRoles: [userRoles.sales],
        tableResourceAccessor: (d) => `requisitions/${d.reqID}/docs`,
        docTypeCode: "ADDON",
      },
      {
        title: "Scanned EOB",
        hideForRoles: [userRoles.sales],
        tableResourceAccessor: (d) => `requisitions/${d.reqID}/docs`,
        docTypeCode: "SCANEOB",
      },
      {
        title: "Scanned Invoice",
        hideForRoles: [userRoles.sales],
        tableResourceAccessor: (d) => `requisitions/${d.reqID}/docs`,
        docTypeCode: "SCANINV",
      },
      {
        title: "Received Check",
        hideForRoles: [userRoles.sales],
        tableResourceAccessor: (d) => `requisitions/${d.reqID}/docs`,
        docTypeCode: "CHECK",
      },
    ],
    tableConfig: [
      {
        id: "docCreateDate",
        label: "Time",
        accessor: (d) =>
          d.docCreateDate &&
          formatServerDateTimeToLocal(d.docCreateDate, "dateTime"),
      },
      {
        id: "docOriginalFileName",
        label: "Filename",
      },
    ],
  },
};

const clientICDNotesConfig = {
  formConfig: {
    inputsByHash: {
      legacyIcdNote: {
        label: "",
        type: "multiline",
      },
    },

    inputsById: ["legacyIcdNote"],
  },

  viewConfig: {
    inputsByHash: {
      legacyIcdNote: {
        label: "",
      },
    },
    inputsById: ["legacyIcdNote"],
  },
};

export { clientICDNotesConfig };
