import React, {Component} from 'react'
import styles from './LabNotesContainer.module.scss';
import {SmartTable} from 'components/ui_components/table'
import {compose} from 'redux'
import PropTypes from "prop-types";
import {paymentsTabConfig} from "config/resources/requisitionsResource/index.js";
import {connect} from 'react-redux';
import helpers from 'utils/helpers';
import AddNoteContainer from './AddPaymentContainer.js';
const {tableColConfig, addFormConfig} = paymentsTabConfig;

class PaymentsContainer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedRows: [],
            isUploading: false
        }
    }

    handleSuccessfullySubmit = () => {
        this.smartTable.loadData();
    }

    render() {
        const {reqID} = this.props;

        return (
            <div className={styles.root}>
                <div className={styles.main}>
                    <AddNoteContainer withFormConfig={addFormConfig} reqID={reqID} handleSuccessfullySubmit={this.handleSuccessfullySubmit}/>

                    <div className={styles.tableWrap}>

                        <SmartTable
                            resource={`requisitions/${reqID}/payments`}
                            showPagination={true}
                            showSelect={true}
                            showDelete={true}
                            showSelectAll={true}
                            onRef={ref => (this.smartTable = ref)}
                            columns={
                                helpers.byHashByIDConfToArray(tableColConfig.tableColById, tableColConfig.tableColByHash)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

PaymentsContainer.propTypes = {
    reqID: PropTypes.string.isRequired
}

const mapStateToProps = ({specimenLocations}) => ({
    addSpecimenLocationModalIsOpen: specimenLocations.addSpecimenLocationModalIsOpen
})

export default compose(
    connect(
        mapStateToProps
    )
)(PaymentsContainer)