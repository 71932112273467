import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';

const StyledTooltipChildrent = styled.span`
  transition: opacity .5s;
  &:hover {
    opacity: .7;
    cursor: pointer;
  }
`;

const IconTooltip = React.forwardRef(({ title, children, placement }, ref) => {
  return (
    <Tooltip ref={ref} title={title} placement={placement} arrow>
      <StyledTooltipChildrent>
        {children}
      </StyledTooltipChildrent>
    </Tooltip>
  )
})

IconTooltip.defaultProps = {
  placement: 'top'
}

export default IconTooltip;