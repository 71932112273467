/* eslint-disable */

/**
 * @deprecated. Use concatWithSeparator instead.
 */

const concatWithCommaSeparation = (array) => {
  let value = '';

  array.forEach((item, index) => {
    const renderValue = index > 0 ? `, ${item}` : item;
    if (item) {
      value += renderValue;
    }
  })

  return value;
}

const toFixed = (num, fixed) => {
  if (num === '' || num === null || num === 'N/A') return null;
  const parseNum = parseFloat(num).toFixed(fixed);

  if (parseNum === 'NaN') return null;
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return parseNum.toString().match(re)[0];
}

const concatWithSeparator = (array, separator = ', ') => {
  let value = '';
  let counter = 0;

  array.forEach(item => {
    if (item === 'null' ||
      item === '' ||
      item === null
    ) return;
  
    const renderValue = counter > 0 ? `${separator}${item}` : item;
    value += renderValue;

    counter++;
  })

  return value;
}

const concatClientCityStateZip = (city, state, zip) => {
  let address = concatWithSeparator([city, state]);
  if (zip) {
    if (city || state) {
      address += ` `  
    }
    address += `${zip}`;
  }
  return address;
}

export {
  concatWithCommaSeparation,
  toFixed,
  concatWithSeparator,
  concatClientCityStateZip,
};