import React from "react";
import SmartTable from "components/ui_components/table/SmartTable";
import { compose } from "redux";
import { withNotificationCenter } from "NotificationCenter";

const columns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "loadErrorDesc",
    label: "Reason",
  },
];

const BatchCsvUploadSubTable = ({
  handleSubTableRowsInputData,
  parentRow,
  uploadId,
}) => {

  return (
    <SmartTable
      resource={`bulk_csv_uploader/${uploadId}/files`}
      columns={columns}
      isSubTable={true}
      showSelect={false}
      perPage={-1}
      editableTable={true}
      showPagination={false}
      handleSubTableRowsInputData={handleSubTableRowsInputData}
      parentRow={parentRow}
    />
  );
};

export default compose(withNotificationCenter())(BatchCsvUploadSubTable);
