import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import ReqPassCodeEditTableCell
  from 'components/ui_components/table/editTableCells/ReqPassCodeEditTableCell'
import TestPassCodeEditTableCell
  from 'components/ui_components/table/editTableCells/TestPassCodeEditTableCell'
import TestFailCodeEditTableCell
  from 'components/ui_components/table/editTableCells/TestFailCodeEditTableCell'
import LabQcStatusEditTableCell
  from 'components/ui_components/table/editTableCells/LabQcStatusEditTableCell';
import DemographicQcEditTableCell
  from 'components/ui_components/table/editTableCells/DemographicQcEditTableCell';
import TableCellInput from 'components/ui_components/table/TableCellInput.js';
import { toFixed } from 'utils/textFormat.js';
import NotifyPatientEditTableCell from './NotifyPatientEditTableCell'

export default function ({
                           col,
                           row,
                           extraData,
                           rowValue,
                           inputDataByHash,
                           handleUpdateRow,
                           smartTable
}) {

  switch (col.type) {
    case 'reqPassCodeEditTableCell':
      return <ReqPassCodeEditTableCell
        col={col}
        reqID={row.id} row={row}
        optionsData={extraData.reasonsPass}/>

    case 'testPassCodeEditTableCell':
      return <TestPassCodeEditTableCell
        col={col}
        reqID={row.reqId}
        testID={row.id}
        row={row}
        optionsData={extraData.reasonsPass}
        loadDataSmartTableData={smartTable.loadData}
      />

    case 'testFailCodeEditTableCell':
      return <TestFailCodeEditTableCell
        col={col}
        reqID={row.reqId}
        testID={row.id}
        row={row}
        optionsData={extraData.reasonsFail}
        loadDataSmartTableData={smartTable.loadData}
      />
    case 'labQcStatus':
      return <TableCell>
        <LabQcStatusEditTableCell
          col={col}
          reqID={row.reqId}
          reportID={row.id}
          value={rowValue}
          optionsData={extraData.labQcStatus}
          smartTable={smartTable}
        />
      </TableCell>
    case 'demographicQCTableCell':
      return <TableCell>
        <DemographicQcEditTableCell
          col={col}
          reqID={row.id}
          value={rowValue}/>
      </TableCell>

    case 'notifyPatientTableCell':
      return <TableCell>
        <NotifyPatientEditTableCell
          col={col}
          reqID={row.id}
          value={rowValue}/>
      </TableCell>

    case 'processingResultEditTableCell':
      const value = inputDataByHash.hasOwnProperty(col.id) ? inputDataByHash[col.id] : row[col.id];

      return <TableCell align="right">
        <TableCellInput
          disabled={row.isCalculated !== 0}
          value={!isNaN(value) ? toFixed(value, row.significantDigits) : value}
          valueOnFocus={value}
          onChange={(event) => handleUpdateRow(event, col.id)}
        />
      </TableCell>

    default:
      return <TableCell>{rowValue}</TableCell>
  }
}