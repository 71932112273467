import React, { useRef } from 'react';
import SidebarMenu from 'components/SidebarMenu/SidebarMenuComponent';
import SidebarSubMenu from 'components/SidebarSubMenu/SidebarSubMenuContainer.js';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import style from './Sidebar.module.scss';
import { compose } from 'redux';
import {
  setSidebarVisibility,
} from 'actions';
import useOutsideClick from 'hooks/useOutsideClick';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LogoIcon from '../../icons/LogoIcon'

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    background: '#282828',
    border: 'none',
    overflow: 'visible',
  },
  closeButton: {
    // [theme.breakpoints.up('sm')]: {
    //     display: 'none',
    // }
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 56
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: 3,
  },
});

const MySidebar = (props) => {
  const { classes, isSidebarOpen } = props;

  const handleSidebarOpen = (e) => {
    if ((e && e.hasOwnProperty('type') && e.type === 'mouseenter') && window.innerWidth < 600) return;
    props.setSidebarVisibility(true);
  }

  const handleSidebarClose = (e) => {
    if ((e && e.hasOwnProperty('type') && e.type === 'mouseleave') && window.innerWidth < 600) return;
      props.setSidebarVisibility(false);
  }


  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, handleSidebarClose, {excludeSelector: '#mobileSidebarTrigger'});

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx({
          [classes.drawer]: true,
          [classes.drawerOpen]: isSidebarOpen,
          [classes.drawerClose]: !isSidebarOpen,
        }),
      }}
      open={isSidebarOpen}
      ref={wrapperRef}
    >

      <div onClick={isSidebarOpen ? handleSidebarClose : handleSidebarOpen}
           className={clsx({
             [style.closeButton]: true,
             [style.sidebarOpen]: isSidebarOpen,
           })}>
        <ArrowForwardIosIcon/>
      </div>
      <div className={style.sidebarLogoWrap}>
        <div className={style.sidebarLogo}>
          <LogoIcon id="sidebar" className={style.logo}/>
          <span>PHD</span>
        </div>
      </div>

      <div className={style.sidebarMenuWrap}>
        <SidebarMenu/>
        <SidebarSubMenu handleSidebarClose={handleSidebarClose}/>
      </div>
    </Drawer>
  )

}

const mapStateToProps = ({ layout }) => ({
  isSidebarOpen: layout.isSidebarOpen
});

export default compose(
  connect(mapStateToProps, { setSidebarVisibility }),
  withStyles(styles)
)(MySidebar);