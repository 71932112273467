import React, { useState } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import styles from "./WorkLogList.module.scss";
import AddWorkLogModal from "./AddWorkLogModal.js";
import useModal from "hooks/useModal.js";
import { SmartTable } from "components/ui_components/table";
import workLogTable from "config/resources/requisitionsResource/issuesTab/workLogTable.js";
import Can from "Can";
import { userRoles } from "config/userRoles";

let smartTableRef = {};

const WorkLogList = ({ reqID, issueID }) => {
  const [isVisibleWorkLogTable, showWorkLogTable] = useState(false);

  const { modalIsOpen, handleOpenModal, handleCloseModal } = useModal();

  const handleAddNewWorkLog = () => {
    showWorkLogTable(true);
    smartTableRef.current.loadData();
  };

  return (
    <div className={styles.root}>
      <AddWorkLogModal
        modalIsOpen={modalIsOpen}
        handleCloseModal={handleCloseModal}
        reqID={reqID}
        issueID={issueID}
        handleAddNewWorkLog={handleAddNewWorkLog}
      />
      <div className={`${"buttonGroup"} ${styles.buttonGroup}`}>
        <ButtonBase
          onClick={showWorkLogTable.bind(this, !isVisibleWorkLogTable)}
          className="button formButton formButton__mainContained"
        >
          {isVisibleWorkLogTable ? "Hide Work Log" : "Show Work Log"}
        </ButtonBase>
        <Can notAllowedRoles={[userRoles.sales]}>
          <ButtonBase
            onClick={handleOpenModal}
            className="button formButton formButton__mainContained"
          >
            Add Work Log
          </ButtonBase>
        </Can>
      </div>
      <div className={styles.listWrap}>
        {isVisibleWorkLogTable && (
          <SmartTable
            onRef={(ref) => (smartTableRef.current = ref)}
            resource={`requisitions/${reqID}/issues/${issueID}/work_logs`}
            orderBy="workLogDate"
            order="desc"
            columns={workLogTable}
            showPagination={false}
            showSelect={false}
          />
        )}
      </div>
    </div>
  );
};

export default WorkLogList;
