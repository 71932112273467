export default [
  {
    id: "loadId",
    label: "Load #",
  },
  {
    id: "reportOriginalFileName",
    label: "File Name",
  },
  {
    id: "reportLoadStatusCode",
    type: "select",
    label: "Upload Status",
    options: [
      { value: "INIT", label: "Initializing" },
      { value: "FILENOTFOUND", label: "File not found" },
      { value: "COMPLETE", label: "Complete" },
    ],
  },
  {
    id: "loadDate",
    label: "Date Collected From - To",
    type: "dateRange",
  },
  {
    id: "batchTypeCode",
    label: "Batch Type",
    type: "autocomplete",
    accessor: (d) => ({
      value: d.batchTypeCode,
      label: d.batchTypeDesc,
    }),
    resource: "lovs/BATCHTYPE",
  },
];
