import React from 'react';
import dataProvider, {GET_LIST} from "providers/dataProvider";

const withOptions = WrappedComponent => {
    class HOC extends React.Component {
        constructor(props){
            super(props);

            this.state = {
                options: []
            }

            this.resource = props.input.resource;

            this.loadData();
        }

        getFilter = (input) => {
            try {
                return input.filter || (input.relativeInputsFilterAccessor && input.relativeInputsFilterAccessor(input.relativeInputsData)) || {};
            } catch {
                return false;
            }
        }

        getResource = (input, options) => {
            try {
                return input.resource || (input.resourceAccessor && input.resourceAccessor(options)) || input.relativeInputResourceAccessor(input.relativeInputsData);
            } catch {
                return false
            }
        }

        loadData = () =>   {
            const {input, options} = this.props;
            const filter = this.getFilter(input);
            const resource = this.getResource(input, options)

            if (!filter) return;
            if (!resource) return;

            dataProvider(GET_LIST, resource, {filter})
                .then(({data, total}) => {
                    let options = data.map(input.accessor)
                    this.setState({
                        isDataLoaded: true,
                        options,
                    })
                })
        }

        render() {
            const {options} = this.state;
            return <WrappedComponent
                {...this.props}
                options={options}
            />;
        }
    }

    return HOC;
};


export default withOptions;