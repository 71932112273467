import React from 'react';

const withModal = WrappedComponent => {
    class HOC extends React.Component {
        constructor(props){
            super(props);

            this.state = {
                open: false
            }
        }

        handleOpenModal = (modalID) => {
            this.setState({
                open: true,
                modalID: modalID
            })
        }

        handleCloseModal = () => {
            this.setState({
                open: false
            })
        }

        render() {
            return <WrappedComponent
                {...this.props}
                modalOpen={this.state.open}
                modalID={this.state.modalID}
                handleOpenModal={this.handleOpenModal}
                handleCloseModal={this.handleCloseModal}

            />;
        }
    }

    return HOC;
};

export default withModal;