import { formatServerDateTimeToLocal } from "utils/dateTimeFormat";

const sectionsConfig = [
  {
    title: "Scanned Requisition",
    docTypeCode: "SCANREQ",
  },
  {
    title: "Insurance card",
    docTypeCode: "INSCARD",
  },
  {
    title: "Add-On Document",
    docTypeCode: "ADDON",
  },
  {
    title: "Scanned EOB",
    docTypeCode: "SCANEOB",
  },
  {
    title: "Scanned Invoice",
    docTypeCode: "SCANINV",
  },
  {
    title: "Received Check",
    docTypeCode: "CHECK",
  },
];

const tableConfig = [
  {
    id: "reqId",
    label: "Req ID",
    linkAccessor: (row) => `/requisitions/${row.reqId}/show`,
  },
  {
    id: "docCreateDate",
    label: "Date Uploaded",
    accessor: (d) =>
      d.docCreateDate &&
      formatServerDateTimeToLocal(d.docCreateDate, "dateTime"),
  },
  {
    id: "docOriginalFileName",
    label: "Filename",
  },
];

export { sectionsConfig, tableConfig };
