import helpers from 'utils/helpers';
import { newPassword, newPasswordConfirm } from 'config/inputs';

export default {
  inputsById: [
    'userName', 'newPwd', 'newPwdConfirm'
  ],

  inputsByHash: {
    userName: {
      label: 'Username',
      yup: helpers.getYupRequired('Username'),
    },
    newPwd: newPassword,
    newPwdConfirm:newPasswordConfirm,
  }
}