import React, { useCallback } from 'react'
import Modal from '@material-ui/core/Modal';
import ButtonBase from '@material-ui/core/ButtonBase';
import styles from './DropZoneModal.module.scss';
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar'
import { useDropzone } from 'react-dropzone'

const DropZoneModal = (props) => {

  const { modalOpen, handleCloseDropZone, handleSubmit, isUploading, handleDropFiles, dropZoneFiles, error } = props;

  const onDrop = useCallback(acceptedFiles => {
    handleDropFiles(acceptedFiles);
  }, [handleDropFiles])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Modal open={modalOpen} onClose={handleCloseDropZone}>
      <div className={`${styles.root} modalContainer`}>

        {isUploading && <div className={styles.progressBar}><ProgressBar/></div>}
        <h2 className="ttl ttl-2">

        </h2>
        <div>
          <div {...getRootProps({ className: styles.dropzone })}>
            <input {...getInputProps()} />
            {
              isDragActive ?
                <p>Drop the files here ...</p> :
                <p>Drag 'n' drop some files here, or click to select files</p>
            }
          </div>

          {error && <div className={styles.errorMessage}>
            Please Upload File
          </div>}
          {dropZoneFiles.length > 0 && <aside>
            <h4>Files</h4>
            <ul>
              {dropZoneFiles.map(file => (
                <li key={file.name}>
                  {file.name} - {file.size} bytes
                </li>
              ))}
            </ul>
          </aside>}
        </div>

        <div className={`${'buttonGroup'} ${styles.buttonGroup}`}>
          <ButtonBase
            className="button formButton formButton__secondaryOutlined"
            onClick={handleCloseDropZone}>
            Cancel
          </ButtonBase>
          <ButtonBase
            className="button formButton formButton__mainContained"
            onClick={handleSubmit}
          >
            Add
          </ButtonBase>
        </div>
      </div>
    </Modal>
  );
}

export default DropZoneModal;