import React, { PureComponent } from 'react'
import styles from "./WidgetContainer.module.scss";
import withForm from 'withForm';
import BaseForm from 'components/ui_components/BaseForm/BaseForm.js';
import { compose } from 'redux'
import dataProvider, { UPDATE } from "providers/dataProvider";
import WidgetEditPatientContentComponent from './WidgetEditPatientContentComponent';
import { withNotificationCenter } from 'NotificationCenter'
import WidgetType1 from 'components/ui_components/Widgets/WidgetType1/WidgetType1';
import WidgetViewModeByGroups
  from 'components/pages/requisitions/RequisitionsShow/Tabs/View/Widget/WidgetViewModeByGroups';
import { WidgetControlButton, ButtonGroup } from 'components/ui_components/buttons';
import { dateOfBirthValidation } from 'utils';
import {
  convertDateCollectedFromToServerUTC,
  getClientTzFromExtraSelectedOptionData,
} from 'components/pages/requisitions/RequisitionsCreate/reqCreateHelpers';

class Widget extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEditMode: false,
      isFormSubmitting: false
    }
  }

  switchToEditMode = () => {
    this.setState({
      isEditMode: true
    })
  }

  switchToViewMode = () => {
    this.setState({
      isEditMode: false
    })
  }

  handleSubmit = (widgetID) => {
    const {
      notificationCenter,
      isFormValid,
      resetErrors,
      reqData,
      handleRequisitionUpdate,
      getSubmitData,
      isValidRelativeInputs,
      isInputValid,
      getExtraSelectedOptionData,
    } = this.props;

    const resource = {
      requisitionsInformation: `requisitions/${reqData.id}`,
      patientInformation: `requisitions/${reqData.id}/patients/${reqData.patientId}`,
      patientHistory: `requisitions/${reqData.id}/patient_hists/${reqData.patientHistId}`
    }

    let submitData = getSubmitData(true);
    let submitDataAll = getSubmitData(false);

    let sendData = { reqStatusCode: reqData.reqStatusCode };

    if (widgetID === 'patientInformation') {

      const dateOfBirthMonth = submitData.patientDobMonth;
      const dateOfBirthDate = submitData.patientDobDate;
      const dateOfBirthYear = submitData.patientDobYear;

      const dateOfBirth = `${dateOfBirthMonth}/${dateOfBirthDate}/${dateOfBirthYear}`;

      sendData = {
        ...sendData,
        patientDob: dateOfBirth,
        patientFirstName: submitData.patientFirstName,
        patientMiddleName: submitData.patientMiddleName,
        patientLastName: submitData.patientLastName,
        patientGenderCode: submitData.patientGenderCode,
        patientRaceCode: submitData.patientRaceCode,
        patientEthnicityCode: submitData.patientEthnicityCode,
        patientSsn: submitData.patientSsn,
        patientId: reqData.patientId
      }
    } else if (widgetID === 'requisitionsInformation') {


      let dateCollectedInUTC = null;
      let extraSelectedOptionData = getExtraSelectedOptionData();
      let clientTz = getClientTzFromExtraSelectedOptionData(extraSelectedOptionData);

      if (!clientTz) {
        clientTz = reqData.clientTz
      }

      try {
        dateCollectedInUTC = convertDateCollectedFromToServerUTC(
          submitDataAll,
          clientTz,
        )
      }

      catch (e) {
        notificationCenter.show(e.message, 'error');
        return;
      }

      sendData = {
        ...sendData,
        ...submitData,
        dateCollected: dateCollectedInUTC,
      }
    } else {
      sendData = { ...sendData, ...submitData }
    }

    let specialFieldsValid = true;

    if (submitData.heightUom === 'CM') {
      specialFieldsValid = isInputValid('heightCm');
    }
    else if (submitData.heightUom === 'INCH') {
      specialFieldsValid = isInputValid('heightInches');
    }

    const isFormValidVar = isFormValid();
    const isValidRelativeInputsVar = isValidRelativeInputs();

    if (
      isFormValidVar &&
      isValidRelativeInputsVar &&
      specialFieldsValid
    ) {
      if (widgetID === 'patientInformation') {
        try {
          dateOfBirthValidation(submitData.patientDobYear,
            submitData.patientDobMonth,
            submitData.patientDobDate);
        }
        catch (e) {
          notificationCenter.show(e.message, 'error')
          return;
        }
      } else if (widgetID === 'requisitionsInformation') {
        try {
          dateOfBirthValidation(
            submitDataAll.dateCollectedYear,
            submitDataAll.dateCollectedMonth,
            submitDataAll.dateCollectedDate,
          );
        }
        catch (e) {
          notificationCenter.show(e.message, 'error')
          return;
        }
      }


      this.setState({
        isFormSubmitting: true
      })
      resetErrors();

      dataProvider(UPDATE, resource[widgetID], { data: sendData })
      .then(({ message }) => {
        this.switchToViewMode();
        handleRequisitionUpdate();
        notificationCenter.show(message, 'info')
        this.setState({
          isFormSubmitting: false
        })
      })
      .catch(error => {
        this.switchToViewMode();
        handleRequisitionUpdate();
        notificationCenter.show(error.message, 'warning')
        this.setState({
          isFormSubmitting: false
        })
      });
    }
  }

  render() {
    const {
      inputsByHash,
      inputsById,
      title,
      handleInputs,
      viewConfig,
      widgetID,
      getSubmitData,
      isAvailableEditMode,
      inputsGroupsByHash,
      inputsGroupsById

    } = this.props;

    const { isEditMode, isFormSubmitting } = this.state;

    const baseFormStyles = {
      rootStyles: 'max-width: none;',
      inputWrapStyles: 'max-width: 180px; width: 100%;',
      groupStyles: 'padding: 0;',
      groupTitleStyles: 'font-weight: 700; font-size: 18px; margin: 10px 0 0;',
    }

    const widgetEdit = widgetID === 'patientInformation' ?
      <WidgetEditPatientContentComponent
        inputsByHash={inputsByHash}
        inputsById={inputsById}
        handleInputs={handleInputs}
        inputsGroupsByHash={inputsGroupsByHash}
        inputsGroupsById={inputsGroupsById}
        getSubmitData={getSubmitData}
        handleBulkInputs={this.props.handleBulkInputs}
        baseFormStyles={baseFormStyles}
      /> :
      <BaseForm
        {...baseFormStyles}
        inputsByHash={inputsByHash}
        inputsById={inputsById}
        inputsGroupsByHash={inputsGroupsByHash}
        inputsGroupsById={inputsGroupsById}
        handleInputs={handleInputs}
        smartInputOptions={{ clientId: this.props.viewConfig.data.clientId }}
      />

    const discrepancyMsg = viewConfig.data.discrepancyMsg ?
      <div className={styles.discrepancyMsgWrap}>
        <div className={styles.discrepancyMsg}>{viewConfig.data.discrepancyMsg}</div>
      </div> : null;

    const controls = isAvailableEditMode &&
      <ButtonGroup>
        {isEditMode && <WidgetControlButton
          variant="outlined"
          onClick={this.switchToViewMode}
        >
          Cancel
        </WidgetControlButton>}

        <WidgetControlButton
          onClick={isEditMode ? this.handleSubmit.bind(this, widgetID) : this.switchToEditMode}
        >
          {isEditMode ? 'Save' : 'Edit'}
        </WidgetControlButton>
      </ButtonGroup>

    const columnStyles = `
    display: flex;
    @media(max-width: 1000px) {
      flex-wrap: wrap;
    }
    >div {
      width: 100%; max-width: 200px;
    }`;

    const content = <React.Fragment>
      {widgetID === 'patientHistory' && discrepancyMsg ? discrepancyMsg : null}

      {isEditMode ?
        widgetEdit :
        <WidgetViewModeByGroups
          viewSubGroups={this.props.viewConfig.viewSubGroups}
          data={this.props.viewConfig.data}
          inputsByHash={this.props.viewConfig.inputsByHash}
          rootStyles={(widgetID === 'patientHistory' || widgetID === 'requisitionsInformation') && columnStyles}
        />
      }
    </React.Fragment>

    return (
      <WidgetType1
        title={title}
        content={content}
        headerRightContent={controls}
        showProgressBar={isFormSubmitting}
        styles={`width: 100%;`}
      />
    )
  }
}

export default compose(
  withForm(),
  withNotificationCenter()
)(Widget)