import React from 'react'
import dataProvider, { CREATE } from "providers/dataProvider";
import { compose } from 'redux'
import BaseForm from 'components/ui_components/BaseForm/BaseForm.js';
import { physiciansResource } from "config/resources/physiciansResource";
import styles from 'components/pages/clients/ClientsShow/AddPhysicianModal/AddPhysicianModal.module.scss';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useNotificationCenter } from 'NotificationCenter'
import withForm from 'withForm';

const withFormConfig = physiciansResource.createPhysConfig;
const inputsGroupsById = withFormConfig.inputsGroupsById;
const inputsGroupsByHash = withFormConfig.inputsGroupsByHash;

const CreatePhysicianMode = (props) => {
  const { inputsById, inputsByHash, handleInputs, handleCloseModal, getSubmitData, setModalMode } = props;

  const notificationCenter = useNotificationCenter();

  const handleSubmit = () => {
    dataProvider(CREATE, 'physicians', { data: getSubmitData() })
    .then((response) => {
      notificationCenter.show('Successfully created', 'info')
      setModalMode('listing');
    })
    .catch(error => {
      notificationCenter.show(error.message, 'warning')
    });
  }

  return (
    <React.Fragment>
      <BaseForm
        inputsById={inputsById}
        inputsByHash={inputsByHash}
        handleInputs={handleInputs}
        inputsGroupsById={inputsGroupsById}
        inputsGroupsByHash={inputsGroupsByHash}
        groupTitleStyles={`display: none;`}
      />
      <div className={`${'buttonGroup'} ${styles.buttonGroup}`}>
        <ButtonBase
          className="button formButton formButton__secondaryOutlined"
          onClick={handleCloseModal}>
          Cancel
        </ButtonBase>
        <ButtonBase
          className="button formButton formButton__mainContained"
          onClick={handleSubmit}
        >
          Create
        </ButtonBase>
      </div>
    </React.Fragment>
  );

}

export default compose(
  withForm(withFormConfig)
)(CreatePhysicianMode);