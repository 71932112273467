import React from "react";
import { TableHeaderAddButton } from "components/ui_components/buttons";
import BaseInputList from "components/ui_components/BaseInputList/BaseInputList";
import { uploadFormConfig } from "config/resources/testReportsUploadResource";
import DropZoneModal from "components/DropZoneModal/DropZoneModal";
import withForm from "withForm";
import { compose } from "redux";
import withDropZone from "withDropZone";
import useBulkUploader from "hooks/useBulkUploader";
import { Box } from "@material-ui/core";

const UploadWidget = ({
  handleInputs,
  inputsById,
  inputsByHash,
  reqID,
  dropZoneFiles,
  handleCloseDropZone,
  dropZoneOpen,
  handleOpenDropZone,
  handleDropFiles,
  resetErrors,
  isFormValid,
  getSubmitData,
  onUploadOk,
}) => {
  const uploader = useBulkUploader();

  const handleClickProcess = () => {
    if (!isFormValid()) return;
    resetErrors();
    handleOpenDropZone();
  };

  const handleSubmitUpload = async () => {
    const { batchTypeCode } = getSubmitData();

    try {
      await uploader.upload({
        dropZoneFiles: dropZoneFiles,
        batchTypeCode: batchTypeCode,
      });
  
      handleCloseDropZone();
      onUploadOk();
    }
    catch (e) {
      console.error(e);
    }
  };

  return (
    <Box display="flex">
      <DropZoneModal
        modalOpen={dropZoneOpen}
        handleSubmit={handleSubmitUpload}
        handleDropFiles={handleDropFiles}
        handleCloseDropZone={handleCloseDropZone}
        isUploading={uploader.isUploading}
        dropZoneFiles={dropZoneFiles}
      />
      <Box minWidth="200px">
        <BaseInputList
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          smartInputOptions={{ reqID: reqID }}
          inputWrapStyles={"margin: 0; width: 100%"}
        />
      </Box>
      <Box ml={2}>
        <TableHeaderAddButton onClick={handleClickProcess}>
          Upload
        </TableHeaderAddButton>
      </Box>
    </Box>
  );
};

export default compose(withDropZone, withForm(uploadFormConfig))(UploadWidget);
