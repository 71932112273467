import React from 'react';
import Modal from '@material-ui/core/Modal';
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar.js';
import styled from 'styled-components';

const defaultModalRootStyles = `
  max-width: 640px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 100%;
  border-radius: 10px;
  padding: 37px 45px;
  box-sizing: border-box;
  max-height: 100vh;
  overflow: auto;`;
  

const defaultButtonGroupStyles = `
    text-align: center;
    padding: 40px 0 0;
    `;



const StyledModalRoot = styled.div`
 &&{
    ${defaultModalRootStyles}
    ${props => props.styles}
  }
`;

const StyledButtonGroup = styled.div`
 &&{
    ${defaultButtonGroupStyles}
    ${props => props.styles}
  }
`;

const BaseModal = (props) => {
  const {
    isOpen,
    handleCloseModal,
    title,
    children,
    controls,
    rootStyles,
    buttonGroupStyles,
    showProgressBar
  } = props;

  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
        <StyledModalRoot styles={rootStyles}>
          {showProgressBar && <ProgressBar/>}

          {title && <h2 className="ttl ttl-2">
            {title}
          </h2>}

          {children}

          {controls && <StyledButtonGroup styles={buttonGroupStyles} className='buttonGroup'>
            {controls}
          </StyledButtonGroup>}
        </StyledModalRoot>
    </Modal>
  )
}

BaseModal.defaultProps = {
  rootStyles: ``,
  buttonGroupStyles: ``,
  isOpen: false,
  handleCloseModal: () => {},
  title: null,
  children: null,
  content: null,
  controls: null,
  showProgressBar: false,
};

export default BaseModal;