import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PhoneIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M4.8,10.4c1.9,3.8,5,6.9,8.8,8.8l2.9-2.9c0.4-0.4,0.9-0.5,1.4-0.3c1.5,0.5,3.1,0.8,4.8,0.8
	c0.7,0,1.3,0.6,1.3,1.3v4.7c0,0.7-0.6,1.3-1.3,1.3C10.1,24,0,13.9,0,1.3C0,0.6,0.6,0,1.3,0H6c0.7,0,1.3,0.6,1.3,1.3
	c0,1.7,0.3,3.3,0.8,4.8c0.1,0.5,0,1-0.3,1.4L4.8,10.4z"/>
    </SvgIcon>
);

export default PhoneIcon;