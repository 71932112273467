import React from 'react';
import MainHeader from 'components/templateParts/MainHeader';
import SearchBar from 'components/templateParts/SearchBar';
import SmartViewsSidebar from '../../../SmartViewsSidebar/SmartViewsSidebar'
import AddPostButton from '../../../ui_components/buttons/AddPostButton';
import Footer from 'components/Footer/Footer';
import {BaseListComponent} from 'components/base'
import {smartFilterConfig} from "config/resources/salesResource/index.js";
import ExportAllButton from 'components/ui_components/buttons/ExportAllButton.js';

const SalesListComponent = props => {
    return (
        <div>
            <MainHeader title="Sales" button={<AddPostButton title="Sales" href="/sales/create"/>}/>
            <SearchBar/>
            <SmartViewsSidebar isOpen={true} resource="sales" smartFilterConfig={smartFilterConfig}>
                <div className="mainContainer">
                    <div className="mainContainer__inner">
                        <BaseListComponent
                          {...props}
                          deleteConfirmationMessagePostfix='sales'
                          toolbarExtraComponents={
                            <ExportAllButton
                              resource='sales/export'
                            />
                          }
                        />
                    </div>
                    <Footer/>
                </div>
            </SmartViewsSidebar>
        </div>
    );
}


export default SalesListComponent;