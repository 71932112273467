import React from 'react';
import StatusTableCellValueContainer from 'components/ui_components/table/StatusTableCellValue/StatusTableCellValueContainer';
import { formatServerDateTimeToLocal } from 'utils/dateTimeFormat';

export default [
    {
        id: 'id',
        label: 'Upload ID'
    },
    {
        id: 'loadDate',
        label: 'Upload Date',
        accessor: d => formatServerDateTimeToLocal(d.loadDate, 'dateTime'),
    },
    {
        id: 'batchTypeDesc',
        label: 'Batch Type',
    },
    {
        id: 'rate',
        label: 'File Upload Rate',
        accessor: d => <StatusTableCellValueContainer status={d.totalFilesCompleted < d.totalFiles ? 'statusError' : 'statusOk'}>{d.totalFilesCompleted}/{d.totalFiles}</StatusTableCellValueContainer>
    }
]
