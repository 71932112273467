import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import SimpleInput from "components/ui_components/smart_input/SimpleInput.js";
import { compose } from "redux";
import FormControl from "@material-ui/core/FormControl";
import FormButton from "../ui_components/buttons/FormButton";
import dataProvider, { CREATE } from "../../providers/dataProvider";
import { withNotificationCenter } from "NotificationCenter";
import { isEmpty } from "lodash";
import { withSmartFilter } from "SmartFilterProvider";
let yup = require("yup");

const styles = (theme) => ({
  formTitle: {
    fontSize: "18px",
    fontWeight: "700",
    padding: "17px 0 0",
  },
  formControl: {
    padding: "15px 0 0",
    width: "100%",
  },
  formLabel: {
    margin: "0 0 6px",
    fontSize: "16px",
  },
  formButtonGroup: {
    display: "flex",
    margin: "0 -6px",
    padding: "23px 0 0",
    boxSizing: "border-box",
  },
  formButton: {
    margin: "0 6px",
  },
});

let schema = yup.object().shape({
  smartName: yup.string().required("Smart Filter Name is required"),
});

class CustomSmartView extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      smartFilterNameValue: "",
      smartFilterNameError: false,
    };
  }

  onCustomSmartViewCreated = () => {
    this.props.notificationCenter.show("Successfully created", "notify");

    this.setState({
      smartFilterNameValue: "",
    });
  };

  onSaveSmartView = () => {
    const { inputsByHash, inputsById, resource } = this.props;

    this.resetErrors();
    if (!this.isFormValid()) {
      return;
    }

    const data = {
      smartName: this.state.smartFilterNameValue,
      smartScreen: resource,
      smartValues: {},
    };

    inputsById.forEach((id) => {
      const input = inputsByHash[id];
      if (!(input.value === "" || !input.value === null)) {
        data.smartValues[id] = {
          value: input.value,
          viewValue: inputsByHash[id].viewValue,
        };
      }
    });

    if (isEmpty(data.smartValues)) {
      this.props.notificationCenter.show("Form is error", "error");
      return;
    }

    dataProvider(CREATE, "smart_filters", { data: data })
      .then((response) => {
        this.props.notificationCenter.show("Successfully created", "info");
        this.onCustomSmartViewCreated();
      })
      .catch((error) => {
        this.props.notificationCenter.show(error.message, "error");
      });
  };

  isFormValid = () => {
    try {
      return schema.validateSync(this.getInputValues(), { abortEarly: false });
    } catch (error) {
      this.handleErrors(error);
      return false;
    }
  };

  resetErrors = () => {
    this.setState({
      smartFilterNameError: false,
    });
  };

  handleErrors = (error) => {
    error.inner.forEach((validationError) => {
      if (validationError.path === "smartName") {
        this.setState(
          {
            smartFilterNameError: true,
          },
          () => {
            // this.props.notificationCenter.show(validationError.message, 'warning')
          }
        );
      }
    });
  };

  getInputValues = () => {
    return { smartName: this.state.smartFilterNameValue };
  };

  handleCustomSmartViewNameChange = (e) => {
    const targetValue = e.target.value;

    this.setState({
      smartFilterNameValue: targetValue,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.form}>
        <div className={classes.formTitle}>Custom Smart View</div>

        <FormControl className={classes.formControl}>
          <div className={classes.formLabel}>Name</div>
          <SimpleInput
            className={classes.formInput}
            value={this.state.smartFilterNameValue}
            handleInputs={this.handleCustomSmartViewNameChange}
            error={this.state.smartFilterNameError}
          />
        </FormControl>

        <div className={classes.formButtonGroup}>
          <FormButton
            variant="outlined"
            color="primary"
            onClick={this.onSaveSmartView}
            fullWidth={true}
            className={classes.formButton}
          >
            Save as New Smart View
          </FormButton>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = ({smartFilter}) => ({
//     tagsById: smartFilter.tagsById,
//     tagsByHash: smartFilter.tagsByHash
// })

// const mapDispatchToProps = dispatch =>
//     bindActionCreators({
//         // showNotification
//     }, dispatch)

export default compose(
  withSmartFilter(),
  withStyles(styles),
  withNotificationCenter()
)(CustomSmartView);
