import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-input': {
      padding: '6px 10px 7px'
    }
  },
}));

const helperUseStyles = makeStyles(() => ({
  helperText: {
    lineHeight: 1.1,
    letterSpacing: 0,
  }
}));

const Text = ({ input, inputId, handleInputs, value }) => {
  const classes = useStyles();
  const helperClasses = helperUseStyles();

  return (
    <TextField
      classes={classes}
      error={input.error}
      key={inputId}
      name={inputId}
      value={value || ''}
      multiline
      rows="4"
      required={input.hasOwnProperty('yup')}
      label={input.label}
      helperText={input.error && input.errorMessage}
      onChange={handleInputs}
      FormHelperTextProps={{
        className: helperClasses.helperText
      }}
    />
  );
}

export default Text;