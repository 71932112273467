import {
    UPDATE_STATUS,
    LOAD_STATUSES,
    INIT_STATUS_FILTERS,
} from './../config/actionTypes';

const initState = {
    filter: {},
    statuses: {},
    activeStatus: 'All'
}

export default (state = initState, action) => {
    switch (action.type) {
        case INIT_STATUS_FILTERS:
            return {...state, ...action.payload}

        case UPDATE_STATUS:
            return {...state, filter: action.payload.filter, activeStatus: action.payload.activeStatus}

        case LOAD_STATUSES:
            return {...state, statuses: action.payload}


        default:
            return state
    }
}