import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MainHeader from 'components/templateParts/MainHeader';
import dataProvider, { UPDATE } from "providers/dataProvider";
import withForm from 'withForm';
import { createConfig } from "config/resources/collaboratorsResource";
import { compose } from 'redux'
import { withNotificationCenter } from 'NotificationCenter'
import BaseForm from 'components/ui_components/BaseForm/BaseForm';
import BaseFormControls from 'components/ui_components/BaseFormControls/BaseFormControls';

const styles = () => ({
  main: {
    margin: '32px 26px 0',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '30px 15px 30px'
  }
});

const inputsGroupsById = createConfig.inputsGroupsById;
const inputsGroupsByHash = createConfig.inputsGroupsByHash;

const CollaboratorEdit = (props) => {
  const { classes, inputsById, inputsByHash, handleInputs } = props;

  const handleSubmit = () => {
    const { notificationCenter, history, isFormValid, inputsById, inputsByHash, resetErrors } = props;

    if (isFormValid()) {
      resetErrors();
      const data = {};

      inputsById.forEach((id) => {
        const inputValue = inputsByHash[id].value;
        if (inputValue === null) return;
        data[id] = inputValue
      })

      dataProvider(UPDATE, 'collaborators', { data: data, id: props.id })
      .then((response) => {
        notificationCenter.show('Successfully updated', 'info')
        history.push('/collaborators/' + response.data.id + '/show');
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
      });
    }
  }

  return (
    <div>
      <MainHeader title="Edit Collaborator"/>
      <div className={classes.main}>
        <BaseForm
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          inputsGroupsById={inputsGroupsById}
          inputsGroupsByHash={inputsGroupsByHash}
        />

        <BaseFormControls
          type={'edit'}
          handleClickSubmit={handleSubmit}
          submitLabel='Update Collaborator'
          deleteConfirmationMessagePostfix={'collaborator'}
          resource='collaborators'
          id={props.id}
        />
      </div>
    </div>
  );
}

export default compose(
  withNotificationCenter(),
  withForm({ ...createConfig, mode: 'edit' }),
  withStyles(styles)
)(CollaboratorEdit)