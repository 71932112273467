import { useState } from "react";
import { useNotificationCenter } from "NotificationCenter";

import dataProvider, {
  UPLOAD_FILE,
  UPLOAD_FILE_S3,
  CREATE,
} from "providers/dataProvider";

export default function () {
  const [isUploading, setIsUploading] = useState(false);
  const notificationCenter = useNotificationCenter();

  const upload = async ({ dropZoneFiles, loadFileTypeCode }) => {
    setIsUploading(true);

    let downloadFile = {};
    let fileName = "";

    dropZoneFiles.forEach((file, index) => {
      if (index > 0) return;
      fileName = file.name;
      downloadFile = file;
    });

    try {
      const gdbInitUploadResponse = await dataProvider(
        UPLOAD_FILE,
        `bulk_csv_uploader/init`,
        {
          data: {
            fileName: fileName,
          },
        },
        {
          apiEndointType: "report",
        }
      );

      const s3Response = await dataProvider(
        UPLOAD_FILE_S3,
        gdbInitUploadResponse.data.url,
        {
          data: {
            ...gdbInitUploadResponse.data.fields,
            downloadFile: downloadFile,
          },
        }
      );

      if (s3Response.status !== 204) {
        notificationCenter.show("S3 error", "error");
        return false;
      }

      const gdbConfirmUploadResponse = await dataProvider(
        CREATE,
        `bulk_csv_uploader/process`,
        {
          data: {
            loadFileTypeCode: loadFileTypeCode,
            fileName: gdbInitUploadResponse.data.newFileName,
            originalFileName: fileName,
          },
        },
        {
          apiEndointType: "report",
        }
      );
      notificationCenter.show(gdbConfirmUploadResponse.message, "info");
      setIsUploading(false);
    } catch (e) {
      notificationCenter.show(e.message, "error");
      setIsUploading(false);
    }
  };

  return {
    upload,
    isUploading,
  };
}
