import React from 'react';
import { CheckIcon } from 'icons'
import styles from './CheckedCell.module.scss';

const CheckedCell  = React.forwardRef((props, ref) =>
  <span {...props} ref={ref}>
      <CheckIcon className={styles.root}/>
  </span>
);


export default CheckedCell;