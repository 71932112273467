import React, { PureComponent } from "react";
import styles from "./StatusWidgetContainer.module.scss";
import SingleSelectContainer from "components/ui_components/smart_input/SingleSelectContainer";

import { statusTabConfig } from "config/resources/requisitionsResource";
import helpers from "utils/helpers";
import { userGroups } from "config/userRoles";
import WidgetType1 from "components/ui_components/Widgets/WidgetType1/WidgetType1";
import InputWrap from "components/ui_components/smart_input/InputWrap";

const { inputsByHash, inputsById } = statusTabConfig.statusWidget;

class StatusWidgetContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      statusTypes: [],
    };
  }

  render() {
    const { reqReasons } = this.state;
    const { reqID, reqStatusCode, handleRequisitionUpdate } = this.props;

    return (
      <WidgetType1
        title="Status"
        wrapStyles="width: 40%"
      >
        <div className={styles.inputList}>
          {inputsById.map((inputId, index) => {
            const input = inputsByHash[inputId];

            let params = { inputId, input };
            return (
              <InputWrap>
                <SingleSelectContainer
                  key={index}
                  {...params}
                  reqReasons={reqReasons}
                  disabled={!helpers.can(userGroups.superAdmins)}
                  reqID={reqID}
                  value={reqStatusCode}
                  autoSaveCallBack={handleRequisitionUpdate}
                />
              </InputWrap>
            );
          })}
        </div>
      </WidgetType1>
    );
  }
}

export default StatusWidgetContainer;
