import { emptyStringToNull } from "utils/validation.js";
let yup = require("yup");

export const inputWeight = {
  label: "Weight",
  yup: yup
    .number()
    .typeError("Weight is required")
    .min(1, "Weight must be great than 0")
    .transform(emptyStringToNull)
    .nullable(),
  required: false,
  submitValueAccessor: (d) => (d ? parseInt(d) : null),
  type: "number",
};

export const inputWeightUom = {
  label: "Weight UOM",
  type: "autocomplete",
  resource: "lovs/WEIGHTUOM",
  accessor: (d) => ({ value: d.weigtUom, label: d.weightUomDesc }),
};

export const inputHeightFeet = {
  label: "Height Feet",
  type: "number",
  yup: yup
    .number()
    .min(0)
    .max(8, "Height must be less than or equal to 8 feet.")
    .transform(emptyStringToNull)
    .nullable(),
  required: false,
  hideVisibilityAccessor: (d) => d === "CM",
  setInitValueAccessor: (d) => Math.floor(d.height / 12),
  excludeSubmit: true,
};

export const inputHeightInches = {
  label: "Height Inches",
  type: "number",
  yup: yup
    .number()
    .min(0, "Height Inches must be positive")
    .max(96, "Height must be less than or equal to 96 inches.")
    .typeError("Height Inches is required")
    .transform(emptyStringToNull)
    .nullable(),
  required: false,
  hideVisibilityAccessor: (d) => d === "CM",
  setInitValueAccessor: (d) => d.height % 12,
  submitViaKey: "height",
  singleValidation: true,
  submitValueGlobalAccessor: (d) => {
    let convertedFeetToInches = null;
    let parsedInches = null;
    let returnVal = null;

    if (d.heightFeet.value) {
      convertedFeetToInches = parseInt(d.heightFeet.value) * 12;
      returnVal = convertedFeetToInches;
    }

    if (d.heightInches.value) {
      parsedInches = parseInt(d.heightInches.value);
      if (convertedFeetToInches) {
        returnVal += parsedInches;
      } else {
        returnVal = parsedInches;
      }
    }
    return returnVal;
  },
};

export const inputHeightCm = {
  label: "Height Cm",
  type: "number",
  yup: yup
    .number()
    .min(0, "Height Cm must be great than 0")
    .typeError("Height Cm is required"),
  hideVisibilityAccessor: (d) => d === "INCH",
  setInitValueAccessor: (d) => d.height,
  singleValidation: true,
  submitViaKey: "height",
};

export const inputHeightUom = {
  label: "Height UOM",
  type: "autocomplete",
  resource: "lovs/HEIGHTUOM",
  relativeHideVisibilityInputs: ["heightFeet", "heightInches", "heightCm"],
  accessor: (d) => ({ value: d.heightUom, label: d.heightUomDesc }),
};
