import React from 'react';
import styles from "./SuggestionsPatientsListComponent.module.scss";
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar'
import StyledCheckbox from 'components/ui_components/StyledCheckbox/StyledCheckbox.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const SuggestionsPatientsListComponent = props => {
    const {isNewPatient, handleNewPatientCheck, dataLoading, patientsData, handleClickSuggestedPatient} = props;

    return (
        <div className={styles.suggestionsPatients}>
            <FormControlLabel control={<StyledCheckbox
                checked={isNewPatient}
                onChange={handleNewPatientCheck}
                value="checked"
            />} label="New Patient (hide suggestions)"/>

            <div className={styles.suggestionsPatientsHeader}>
                Suggested Patients list
            </div>

            {!isNewPatient &&
            <ul className={styles.suggestionsPatientsList}>
                {dataLoading && <ProgressBar/>}
                {patientsData.map(patient => {
                    const dob = `${('0' + patient.patientDobMonth).slice(-2)}/${('0' + patient.patientDobDate).slice(-2)}/${patient.patientDobYear}`;
                    return <li key={patient.id}
                               onClick={handleClickSuggestedPatient.bind(this, patient)}>
                        {patient.patientLastName} {patient.patientFirstName} {patient.patientMiddleName}, {dob}, {patient.patientGenderDesc}
                    </li>
                })}
            </ul>
            }
        </div>
    );
}

export default SuggestionsPatientsListComponent;