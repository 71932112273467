import helpers from "utils/helpers";

export default {
  inputsById: [
    "colaName",
    "colaAlias",
    "colaCountryId",
    "colaCity",
    "colaAddr1",
    "colaAddr2",
    "colaPhone",
    "colaEmail",
    "colaCity",
    "colaZip",
    "colaStateId",
    "colaNote",
    "colaOfficeContact",
    "colaLabDirector",
    "colaLabNpi",
    "cola2ndContact",
    "colaFaxEmail",
    "colaTransmitMethodCode",
    "colaBillTypeCode",
    "fax",
    "colaDeliveryNote",
    "reportNameFormatCode",
    "templateFilename",
    "reportDeliverToCode",
    "idPrintOutCode",
    "isLisInterface",
    "isOrderIdAvailable",
    "colaPortalPrefCode",
  ],

  inputsByHash: {
    colaName: {
      label: "Name",
      yup: helpers.getYupRequired("Name"),
    },
    colaAlias: {
      label: "Alias",
      yup: helpers.getYupRequired("Alias"),
    },
    colaCountryId: {
      label: "Country",
      type: "autocomplete",
      resource: "countries",
      relativeInputs: ["colaStateId"],
      resetRelativeInputs: ["colaStateId"],
      yup: helpers.getYupRequired("Country"),
      accessor: (d) => ({ value: d.id, label: d.countryLongName }),
    },
    colaStateId: {
      label: "State",
      yup: helpers.getYupRequired("State"),
      type: "autocomplete",
      relativeInputResourceAccessor: (d) => {
        return `countries/${d.colaCountryId}/states`;
      },
      accessor: (d) => ({ value: d.id, label: d.stateLongname }),
    },
    colaZip: {
      label: "Zip",
      yup: helpers.getYupRequired("Zip"),
    },
    colaCity: {
      label: "City",
      yup: helpers.getYupRequired("City"),
    },
    colaAddr1: {
      label: "Address 1",
      yup: helpers.getYupRequired("Address 1"),
    },
    colaAddr2: {
      label: "Address 2",
    },
    colaPhone: {
      label: "Phone",
    },
    colaEmail: {
      label: "Email",
    },
    colaNote: {
      label: "Note",
    },
    colaOfficeContact: {
      label: "Office Contact",
    },
    colaLabDirector: {
      label: "Lab Director",
    },
    colaLabNpi: {
      label: "Lab NPI",
    },
    cola2ndContact: {
      label: "Second contact",
    },
    colaFaxEmail: {
      label: "Secure Fax/Email",
    },
    colaTransmitMethodCode: {
      label: "Transmit method",
      type: "autocomplete",
      resource: "lovs/TRANSMITMETHOD",
      accessor: (d) => ({
        value: d.transmitMethodCode,
        label: d.transmitMethodDesc,
      }),
    },
    colaBillTypeCode: {
      label: "Bill To",
      type: "autocomplete",
      resource: "lovs/BILLTYPE",
      accessor: (d) => ({ value: d.billTypeCode, label: d.billTypeDesc }),
    },
    fax: {
      label: "Fax",
    },
    colaDeliveryNote: {
      label: "Delivery Note",
    },
    reportNameFormatCode: {
      label: "Name Format Code",
      type: "autocomplete",
      yup: helpers.getYupRequired("Name Format Code"),
      resource: "lovs/REPORTNAMEFORMAT",
      accessor: (d) => ({
        value: d.reportNameFormatCode,
        label: d.reportNameFormatDesc,
      }),
    },
    templateFilename: {
      label: "Template File Name",
    },
    reportDeliverToCode: {
      label: "Delivery to Code",
      type: "autocomplete",
      resource: "lovs/REPORTDELIVERTO",
      accessor: (d) => ({
        value: d.reportDeliverToCode,
        label: d.reportDeliverToDesc,
      }),
    },
    idPrintOutCode: {
      label: "Print Out Code",
      type: "autocomplete",
      resource: "lovs/IDPRINTOUT",
      accessor: (d) => ({ value: d.idPrintOutCode, label: d.idPrintOutDesc }),
    },
    isLisInterface: {
      label: "LIS Interface",
      type: "singleCheckbox",
      yup: helpers.getYupRequired("LIS Interface"),
    },
    isOrderIdAvailable: {
      label: "Order ID Available",
      type: "singleCheckbox",
      yup: helpers.getYupRequired("Order ID Available"),
    },
    colaPortalPrefCode: {
      label: "Patient Portal Preferences",
      type: "autocomplete",
      resource: "lovs/PORTALPREF",
      accessor: (d) => ({
        value: d.portalPrefCode,
        label: d.portalPrefDesc,
      }),
    },
  },

  inputsGroupsByHash: {
    group1: {
      title: "Info",
      ids: [
        ["colaName", "colaAlias"],
        ["colaAddr1", "colaAddr2", "colaCountryId", "colaStateId", "colaZip"],
        ["colaCity"],
        [
          "colaOfficeContact",
          "colaEmail",
          "colaPhone",
          "cola2ndContact",
          "colaFaxEmail",
        ],
        ["isLisInterface", "isOrderIdAvailable"],
        ["colaNote"],
      ],
      inputStyles: `width: 20%;`,
      groupStyles: `width: 100%`,
    },
    group2: {
      title: "Laboratory Information",
      ids: [["colaLabDirector"], ["colaLabNpi"]],
      inputStyles: `width: 100%;`,
      groupStyles: `width: 33.333%`,
    },
    group4: {
      title: "Delivery Method",
      ids: [
        ["colaBillTypeCode"],
        ["colaTransmitMethodCode"],
        // ['clientFaxEmail'],
        ["colaDeliveryNote"],
      ],
      inputStyles: `width: 100%;`,
      groupStyles: `width: 33.333%`,
    },
    group5: {
      title: "Reports Information",
      ids: [
        ["reportNameFormatCode"],
        ["templateFilename"],
        ["reportDeliverToCode"],
        ["idPrintOutCode"],
        ["colaPortalPrefCode"],
      ],
      inputStyles: `width: 100%;`,
      groupStyles: `width: 33.333%`,
    },
  },

  inputsGroupsById: ["group1", "group4", "group2", "group5"],
};
