const statusesAvailableForEdit = ["LOG", "ACC", "PROCESS"];
const isAvailableEditByStatus = (data) =>
  statusesAvailableForEdit.includes(data.reqStatusCode);

export const isCovidVac = {
  label: "Covid Vaccinated",
  type: "radio",
  options: [
    { value: 1, label: "Yes" },
    { value: "0", label: "No" },
  ],
  fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  value: "0",
};
export const famHistT2DCode = {
  label: "Family history of type 2 diabetes",
  type: "autocomplete",
  resource: "lovs/FAMHISTT2D",
  fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  accessor: (d) => ({ value: d.famHistT2DCode, label: d.famHistT2DDesc }),
  value: "0"
};
export const isSelfT2DGest = {
  label: "Personal HX of T2D/Gest. Diabetes",
  type: "radio",
  fieldVisibilityAccessorByLoadedData: (d) => isAvailableEditByStatus(d),
  options: [
    { value: 1, label: "Yes" },
    { value: "0", label: "No" },
  ],
  value: "0",
};