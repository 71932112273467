import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const UserIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M12,0c2.6,0,4.7,2.1,4.7,4.7v2.4c0,2.6-2.1,4.7-4.7,4.7c-2.6,0-4.7-2.1-4.7-4.7V4.7
	C7.3,2.1,9.4,0,12,0z M22.3,17.9c0,0.1,0,0.1,0,0.2C20.2,21.7,16.2,24,12,24c-4.2,0-8.2-2.3-10.3-5.9c0-0.1,0-0.1,0-0.2
	c1.5-2.6,3.9-4.5,6.8-5.3c0.1,0,0.1,0,0.2,0c1.1,1.1,3,2.3,3.4,2.6c0.4-0.2,2.1-1.3,3.4-2.6c0,0,0.1-0.1,0.2,0
	C18.4,13.5,20.8,15.4,22.3,17.9z"/>
    </SvgIcon>
);

export default UserIcon;