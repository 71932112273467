import React from 'react';
import SmartTable from '../SmartTable'
import { formatServerDateTimeToTimeZoneWithAbbr } from '../../../../utils/dateTimeFormat'

const tableColConfig = [
  {
    id: 'receivedAt',
    label: 'Received At',
    accessor: (d, extraData) => {
      return (`${formatServerDateTimeToTimeZoneWithAbbr(d.dateReceived, 'dateTime', extraData.reqData.labTz, extraData.reqData.labTzAbbr)}`)
    }
  },
  {
    id: 'specTypeDesc',
    label: 'Specimen'
  },
  {
    id: 'temperatureTypeDesc',
    label: 'Temperature'
  },
  {
    id: 'containerTypeDesc',
    label: 'Container'
  },
  {
    id: 'tubeCount',
    label: 'Tubes #'
  },
  {
    id: 'specCreateByUsername',
    label: 'Received By'
  }
];

export default function SpecimentsSubTable({ reqId, reqData }) {
  console.log(reqData);
  return (
    <SmartTable
      resource={`requisitions/${reqId}/specimens`}
      columns={tableColConfig}
      isSubTable={true}
      showSelect={false}
      showPagination={false}
      rowAccessorExtraData={{
        reqData: {
          labTz: reqData.labTz,
          labTzAbbr: reqData.labTzAbbr,
        },
      }}
    />
  );
}
