import React, { useState } from "react";
import { SmartTable } from "components/ui_components/table";
import { TableHeaderAddButton } from "components/ui_components/buttons";
import {
  tableColConfig,
  addFormConfig,
  editFormConfig,
} from "config/resources/clientsResource/clientTestPrices.js";
import useModal from "hooks/useModal";
import ModalWithAddForm from "components/ui_components/modals/ModalWithAddForm.js";
import withForm from "withForm";
import { Edit } from "@material-ui/icons";

let smartTableRef = {};

const ClientTestPrices = ({
  clientId,
  clearInputs,
  getSubmitData,
  inputsById,
  inputsByHash,
  handleBulkInputs,
  handleInputs,
  resetErrors,
  isFormValid,
  isEditAvailable,
}) => {
  const { modalIsOpen, handleCloseModal, handleOpenModal } = useModal();
  const [isEditMode, setIsEditMode] = useState();
  const [editData, setEditData] = useState(null);

  const handleClickAddTest = () => {
    setIsEditMode(false);
    handleOpenModal();
  };

  const handleEditTest = (row) => {
    const data = row[0];
    setEditData(data);
    setIsEditMode(true);
    handleOpenModal();
    let updateInputs = [];

    inputsById.forEach((id) => {
      let value = data[id];

      updateInputs.push({ id: id, value: value });
    });
    handleBulkInputs(updateInputs);
  };

  return (
    <React.Fragment>
      {tableColConfig && (
        <ModalWithAddForm
          title={isEditMode ? "Edit Test" : "Add Test"}
          modalIsOpen={modalIsOpen}
          submitResource={
            isEditMode
              ? `clients/${clientId}/prices/${editData.id}`
              : `clients/${clientId}/prices`
          }
          isEditMode={isEditMode}
          clearInputs={clearInputs}
          getSubmitData={getSubmitData}
          handleInputs={handleInputs}
          resetErrors={resetErrors}
          isFormValid={isFormValid}
          submitOkCallback={() => {
            smartTableRef.current.loadData();
          }}
          inputsById={isEditMode ? editFormConfig.inputsById : inputsById}
          inputsByHash={inputsByHash}
          handleCloseModal={handleCloseModal}
          inputsGroupsById={addFormConfig.inputsGroupsById}
          inputsGroupsByHash={
            isEditMode
              ? editFormConfig.inputsGroupsByHash
              : addFormConfig.inputsGroupsByHash
          }
        />
      )}

      <SmartTable
        resource={`clients/${clientId}/prices`}
        onRef={(ref) => (smartTableRef.current = ref)}
        columns={tableColConfig}
        perPage={-1}
        showPagination={false}
        showSelect={isEditAvailable}
        showSelectAll={isEditAvailable}
        toolbarExtraComponents={
          isEditAvailable && (
            <TableHeaderAddButton onClick={handleClickAddTest}>
              Add test
            </TableHeaderAddButton>
          )
        }
        customRowActions={
          isEditAvailable
            ? [
                {
                  tooltipText: "Edit",
                  icon: <Edit />,
                  handleOnClick: handleEditTest,
                },
              ]
            : []
        }
      />
    </React.Fragment>
  );
};

export default withForm({ ...addFormConfig })(ClientTestPrices);
