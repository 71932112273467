import helpers from "utils/helpers";

export default {
  inputsById: ["userFirstName", "userLastName", "isSubscribed"],

  inputsByHash: {
    userFirstName: {
      label: "First Name",
      yup: helpers.getYupRequired("First Name"),
    },
    userLastName: {
      label: "Last Name",
      yup: helpers.getYupRequired("Last Name"),
    },
    isSubscribed: {
      label: "Subscribe to email notification",
      type: "radio",
      options: [
        { value: "1", label: "Yes" },
        { value: "0", label: "No" },
      ],
      value: "0",
      submitValueAccessor: (d) => parseInt(d),
    },
  },

  inputsGroupsByHash: {
    group1: {
      ids: [["userFirstName", "userLastName"], ["isSubscribed"]],
    },
  },

  inputsGroupsById: ["group1"],
};
