import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NextStatusIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M23.9,11.7C23.9,11.7,23.9,11.7,23.9,11.7l-6.9-7c-0.2-0.2-0.6-0.2-0.8,0c-0.1,0.1-0.2,0.2-0.2,0.4v3.7H7.3
	c-0.3,0-0.7,0.1-0.7,0.4v5.8c0,0.4,0.3,0.7,0.7,0.7h8.8V19c0,0.2,0.1,0.4,0.3,0.5h0.2c0.2,0,0.3-0.1,0.4-0.2l6.8-6.9
	C24,12.2,24.1,11.9,23.9,11.7z M4.9,8.8C4.6,8.8,4.4,9,4.4,9.3v5.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5V9.3
	C5.5,9,5.2,8.8,4.9,8.8z M2.7,8.8C2.4,8.8,2.2,9,2.2,9.3v5.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5V9.3C3.3,9,3,8.8,2.7,8.8z
	 M0.5,8.8C0.2,8.8,0,9,0,9.3v5.8c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V9.3C1.1,9,0.9,8.8,0.5,8.8z"/>
  </SvgIcon>
);

export default NextStatusIcon;