import { formatServerDateTimeToTimeZoneWithAbbr } from "utils/dateTimeFormat.js";
import { userRoles } from "config/userRoles";

const linkParams = {
  linkAccessor: (row) => `/requisitions/${row.reqId}/show`,
  linkPermissions: [
    userRoles.admin,
    userRoles.management,
    userRoles.gdbstaff,
    userRoles.sales,
    userRoles.collaborator,
  ],
};

export default [
  {
    id: "sampleStorageCode",
    label: "Freezer",
  },
  {
    id: "shelf",
    label: "Shelf",
  },
  {
    id: "rack",
    label: "Rack",
  },
  {
    id: "box",
    label: "Box",
  },
  {
    id: "slot",
    label: "Slot",
    accessor: (d) => `${d.rowLetter} ${d.slot}`,
  },
  {
    id: "reqId",
    label: "Req id",
    ...linkParams,
  },
  {
    id: "clientName",
    label: "Client",
  },
  {
    id: "physName",
    label: "Physician",
    accessor: (d) => `${d.physLastName}, ${d.physFirstName}`,
  },
  {
    id: "patientName",
    label: "Patient Name",
    accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
  },
  {
    id: "dateCollected",
    label: "Date Collected",
    accessor: (d) =>
      formatServerDateTimeToTimeZoneWithAbbr(
        d.dateCollected,
        "date",
        d.collectedTz,
        d.collectedTzAbbr
      ),
  },
  {
    id: "dateReceived",
    label: "Date Received",
    accessor: (d) =>
      `${formatServerDateTimeToTimeZoneWithAbbr(
        d.dateReceived,
        "date",
        d.labTz,
        d.labTzAbbr
      )}`,
  },
];
