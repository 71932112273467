import React, { PureComponent } from "react";
import styles from "./FailPassCodesWidgetContainer.module.scss";
import ReqFailPassCodesMultiSelectContainer from "./ReqFailPassCodesMultiSelectContainer";
import dataProvider, { GET_LIST } from "providers/dataProvider";
import { statusTabConfig } from "config/resources/requisitionsResource";
import WidgetType1 from "components/ui_components/Widgets/WidgetType1/WidgetType1";

const { inputsByHash, inputsById } = statusTabConfig.failPassCodesWidget;

class FailPassCodesWidgetContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      reqReasons: [],
    };

    this.loadSelectedOption();
  }

  loadSelectedOption = () => {
    const { reqID } = this.props;

    dataProvider(GET_LIST, `requisitions/${reqID}/req_reasons`).then(
      ({ data, total }) => {
        this.setState({
          reqReasons: data,
        });
      }
    );
  };

  render() {
    const { reqReasons } = this.state;
    const { reqID } = this.props;

    return (
      <WidgetType1 title="Fail / Pass Codes" wrapStyles="width: 60%">
        <div className={styles.inputList}>
          {inputsById.map((inputId, index) => {
            const input = inputsByHash[inputId];

            let params = { inputId, input };
            return (
              <ReqFailPassCodesMultiSelectContainer
                key={index}
                {...params}
                reqReasons={reqReasons}
                reqID={reqID}
              />
            );
          })}
        </div>
      </WidgetType1>
    );
  }
}

export default FailPassCodesWidgetContainer;
