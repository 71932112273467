import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "../../icons/CloseIcon";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { smartViewSidebarSwitchTab } from "actions";
import styles from "./FilterList.module.scss";
import ProgressBar from "components/ui_components/ProgressBar/ProgressBar.js";
import { useSmartFilter } from "SmartFilterProvider";
import { getConvertedInputs } from 'utils/smartFilter';

const FilterList = ({
  list,
  smartViewSidebarSwitchTab,
  deleteSmartView,
  dataLoading,
  handleBulkInputs,
  clearAllInputs,
}) => {

  const { applyConfig } = useSmartFilter();
  const handleApplySmartFilter = async (e, smartValues) => {
    smartViewSidebarSwitchTab(1);
    applyConfig(smartValues);
    await clearAllInputs();
    await handleBulkInputs(getConvertedInputs(smartValues));
  };

  const handleDelete = (id, e) => {
    e.stopPropagation();
    deleteSmartView(id);
  };

  return (
    <div className={styles.root}>
      {dataLoading && <ProgressBar />}
      {list.map((item) => (
        <div
          key={item.id}
          className={styles.listItem}
          onClick={(e) =>
            handleApplySmartFilter(e, JSON.parse(item.smartValues))
          }
        >
          <div className={styles.itemTitle}>{item.smartName}</div>

          <div>
            <IconButton
              className={styles.deleteBtn}
              onClick={handleDelete.bind(this, item.id)}
            >
              <CloseIcon className={styles.iconClose} />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      smartViewSidebarSwitchTab,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(FilterList);