import React from 'react';

const withDropZone = WrappedComponent => {
    class HOC extends React.Component {
        constructor(props){
            super(props);

            this.state = {
                open: false,
                files: [],
                error: false
            }
        }
        handleDropFiles = (files) => {
            this.resetErrors();
            this.setState({
                files: files
            })
        }

        clearFiles = () => {
            this.setState({
                files: []
            })
        }

        handleOpenDropZone = (code) => {
            this.setState({
                open: true
            })
        }

        handleCloseDropZone = () => {
            this.clearFiles();
            this.setState({
                open: false,
                error: false
            })
        }

        handleErrors = () => {
            this.setState({
                error: true
            })
        }

        resetErrors = () => {
            this.setState({
                error: false
            })
        }

        isValid = () => {
            if (this.state.files.length === 0) {
                this.handleErrors();
            }
            else {
                return true;
            }
        }

        render() {
            return <WrappedComponent
                {...this.props}
                dropZoneOpen={this.state.open}
                handleOpenDropZone={this.handleOpenDropZone}
                handleCloseDropZone={this.handleCloseDropZone}
                handleDropFiles={this.handleDropFiles}
                dropZoneFiles={this.state.files}
                clearDropZoneFiles={this.clearFiles}
                isValidDropZone={this.isValid}
                resetDropZoneErrors={this.resetErrors}
                dropZoneError={this.state.error}

            />;
        }
    }

    return HOC;
};

export default withDropZone;