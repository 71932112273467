import { formatServerDateTimeToLocal } from 'utils/dateTimeFormat.js';

export default [
  {
    'id': 'issueWorkLog',
    'label': 'Issue Work Log'
  },
  {
    'id': 'createdByUserName',
    'label': 'Updated by'
  },
  {
    'id': 'workLogDate',
    'label': 'Date',
    accessor: d => formatServerDateTimeToLocal(d.workLogDate, 'dateTime'),
  }
]