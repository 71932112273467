import React, { Component } from "react";
import MainHeader from "components/templateParts/MainHeader";
import dataProvider, { GET_ONE } from "providers/dataProvider";
import Can from "Can";
import styles from "./RequisitionsShow.module.scss";
import { withAuthCenter } from "AuthCenter";
import { compose } from "redux";
import { cloneDeep } from "lodash";
import { Link, Route } from "react-router-dom";
import {
  IssuesContainer,
  ViewContainer,
  LocationsContainer,
  SpecimenContainer,
  StatusContainer,
  TestsContainer,
  ReportsDeliveryContainer,
  ActivityContainer,
  LabNotesContainer,
  BillingContainer,
  PaymentsContainer,
  PatientHistory,
} from "./Tabs";
import clsx from "clsx";
import AddPostButton from "components/ui_components/buttons/AddPostButton";
import { userRoles } from "config/userRoles";
import helpers from "utils/helpers";

const specialBilledStatuses = [
  "READYBILL",
  "INBILL",
  "BILL",
  "OABILL",
  "XEROBILL",
  "INSPAYRECD",
  "INVOICE",
  "PATPAYRECD",
  "MISSBILL",
  "HOLD",
  "EOB",
  "NONCOLLECT",
];

class RequisitionsShowContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.tabsConfigById = [
      "view",
      "issues",
      "specimens",
      "locations",
      "tests",
      "status",
      "reportsDelivery",
      "activity",
      "labNotes",
      "billing",
      "payments",
      "patientHistory",
    ];

    this.state = {
      data: null,
      reqDataLoading: false,
      reqID: this.props.match.params.id,
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.loadData();
    }
    if (prevProps.authCenter.token !== this.props.authCenter.token) {
      this.loadData();
    }
  }

  componentDidMount() {
    if (helpers.can([userRoles.sales])) {
      this.tabsConfigById = [
        "view",
        "issues",
        "specimens",
        "tests",
        "status",
        "reportsDelivery",
        "billing",
      ];
    } else if (helpers.can([userRoles.collaborator])) {
      this.tabsConfigById = [
        "view",
        "issues",
        "specimens",
        "tests",
        "status",
        "reportsDelivery",
        "billing",
      ];
    }

    this.loadData();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData() {
    this.setState({
      reqDataLoading: true,
    });
    dataProvider(GET_ONE, "requisitions", {
      id: this.props.match.params.id,
    }).then(({ data, total }) => {
      if (this._isMounted) {
        this.setState({
          reqDataLoading: false,
        });
        if (!data) return;
        this.setState({
          dataLoaded: true,
          data: data,
        });
      }
    });
  }

  handleRequisitionUpdate = () => {
    this.loadData();
  };

  handlePatientInformationUpdate = () => {
    this.loadData();
  };

  setReqDataByKey = (data, key) => {
    const updatedData = cloneDeep(this.state.data);
    updatedData[key] = data[key];

    this.setState({
      data: updatedData,
    });
  };

  handleTabChange = (id) => {
    const { history, match } = this.props;
    history.push(`${match.url}/` + id);
  };

  render() {
    const { data, dataLoaded, reqDataLoading } = this.state;
    const { match } = this.props;
    const title = this.state.data
      ? `#${this.state.data.id} | ${this.state.data.patientFirstName} ${this.state.data.patientLastName} (${this.state.data.patientDob})`
      : null;
    const tabsConfigByHash = {
      view: {
        label: "View",
        component: (
          <ViewContainer
            {...this.props}
            reqID={this.props.match.params.id}
            reqData={this.state.data}
            handleRequisitionUpdate={this.handleRequisitionUpdate}
            reqDataLoading={reqDataLoading}
          />
        ),
      },
      issues: {
        label: "Issues",
        component: (
          <IssuesContainer
            reqID={this.props.match.params.id}
            reqData={this.state.data}
          />
        ),
      },
      specimens: {
        label: "Specimens",
        component: (
          <SpecimenContainer
            reqID={this.props.match.params.id}
            reqData={this.state.data}
          />
        ),
      },
      locations: {
        label: "Locations",
        component: (
          <LocationsContainer
            reqID={this.props.match.params.id}
            labID={
              dataLoaded ? (data.hasOwnProperty("labId") ? data.labId : "") : ""
            }
          />
        ),
        mainHeader: {
          title: "Locations",
        },
      },
      tests: {
        label: "Tests",
        component: (
          <TestsContainer
            reqID={this.props.match.params.id}
            reqData={this.state.data}
          />
        ),
      },
      status: {
        label: "Status",
        component: (
          <StatusContainer
            reqID={this.props.match.params.id}
            reqStatusCode={
              dataLoaded
                ? data.hasOwnProperty("reqStatusCode")
                  ? data.reqStatusCode
                  : ""
                : ""
            }
            handleRequisitionUpdate={this.handleRequisitionUpdate}
          />
        ),
        mainHeader: {
          title: "Requisition Status",
        },
      },
      reportsDelivery: {
        label: "Reports/Delivery",
        component: (
          <ReportsDeliveryContainer
            reqID={this.props.match.params.id}
            isDemQcPass={dataLoaded ? data.isDemQcPass : 0}
          />
        ),
        mainHeader: {
          title: "Requisition Reports/Delivery",
        },
      },
      activity: {
        label: "Activity",
        component: <ActivityContainer reqID={this.props.match.params.id} />,
        mainHeader: {
          title: "Requisition Activity",
        },
      },
      labNotes: {
        label: "QA Notes",
        component: <LabNotesContainer reqID={this.props.match.params.id} />,
        mainHeader: {
          title: "Requisition QA Notes",
        },
      },
      billing: {
        label: "Billing",
        component: (
          <BillingContainer
            reqID={this.props.match.params.id}
            reqData={this.state.data}
            handlePatientInformationUpdate={this.handlePatientInformationUpdate}
            setReqDataByKey={this.setReqDataByKey}
          />
        ),

        mainHeader: {
          title: "Requisition Billing",
        },
      },
      payments: {
        label: "Payments",
        component: <PaymentsContainer reqID={this.props.match.params.id} />,
        mainHeader: {
          title: "Requisition Payments",
        },
      },
      patientHistory: {
        label: "Patient History",
        component: <PatientHistory reqData={this.state.data} />,
        mainHeader: {
          title: "Patient History",
        },
      },
    };

    const currentTabComponent = (props) => {
      const tabID = tabsConfigByHash.hasOwnProperty(props.match.params.tabSlug)
        ? props.match.params.tabSlug
        : "view";
      const currentTab = tabsConfigByHash[tabID];

      let statusLink = "/requisitions?status=";

      if (this.state.data) {
        if (specialBilledStatuses.includes(this.state.data.reqStatusCode)) {
          statusLink += "Billed";
        } else {
          statusLink += this.state.data.reqStatusDesc;
        }
      }

      return (
        <div>
          <MainHeader
            title={
              currentTab.hasOwnProperty("mainHeader") &&
              currentTab.mainHeader.hasOwnProperty("title")
                ? currentTab.mainHeader.title
                : "View Requisition"
            }
            button={
              <div className={styles.mainHeaderButtonGroup}>
                <div className={styles.mainHeaderButtonWrap}>
                  <Can
                    allowedRoles={[
                      userRoles.admin,
                      userRoles.collaborator,
                      userRoles.management,
                      userRoles.gdbstaff,
                    ]}
                  >
                    <AddPostButton
                      title="Requisition"
                      onClick={() =>
                        this.props.history.push("/requisitions/create")
                      }
                    />
                  </Can>
                </div>
                {currentTab.hasOwnProperty("mainHeader") &&
                currentTab.mainHeader.hasOwnProperty("button") ? (
                  <div className={styles.mainHeaderButtonWrap}>
                    {currentTab.mainHeader.button}
                  </div>
                ) : null}
              </div>
            }
          />
          <div className={clsx([styles.introBar])}>
            <div className={`${"singlePage__container"}`}>
              <div className={styles.introBarTitle}>
                <span>{title}</span>

                {this.state.data && (
                  <Link className={styles.currentStatus} to={statusLink}>
                    {this.state.data.reqStatusDesc}
                  </Link>
                )}
              </div>

              {this.state.data?.sourceReqId && (
                <div className={styles.copyBarTitle}>
                  <span>Copy of #{this.state.data.sourceReqId}</span>
                </div>
              )}

              <ul className={styles.tabControls}>
                {this.tabsConfigById.map((id) => (
                  <li
                    key={id}
                    onClick={this.handleTabChange.bind(this, id)}
                    className={clsx(
                      { [styles.tabItem__active]: id === tabID },
                      [styles.tabItem]
                    )}
                  >
                    {tabsConfigByHash[id].label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {this.state.dataLoaded ? currentTab.component : null}
        </div>
      );
    };

    return (
      <div className={styles.root}>
        <Route exact path={`${match.url}`} component={currentTabComponent} />
        <Route path={`${match.url}/:tabSlug`} component={currentTabComponent} />
      </div>
    );
  }
}

export default compose(withAuthCenter())(RequisitionsShowContainer);
