const filterArrayConfigByPermissions = (columns, role) => {
  return columns.filter((item) => {
    if (
      item.hasOwnProperty("hideForRoles") &&
      item.hideForRoles.includes(role)
    ) {
      return false;
    } else if (
      item.hasOwnProperty("visibleForRoles") &&
      !item.visibleForRoles.includes(role)
    ) {
      return false;
    } else {
      return true;
    }
  });
};

export default filterArrayConfigByPermissions;
