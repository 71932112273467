import React from 'react';

const withCollapse = Component => {
    class HOC extends React.Component {
        constructor(props){
            super(props);

            this.state = {
                open: false
            }
        }

        hide = () => {
            this.setState({
                open: false
            })
        }

        show = () => {
            this.setState({
                open: true
            })
        }

        render() {
            const {forwardedRef, ...rest} = this.props;

            return <Component ref={forwardedRef} {...rest}
                  handleHideCollapse={this.hide}
                  handleShowCollapse={this.show}
                  isCollapseOpen={this.state.open}/>;
        }
    }

    return React.forwardRef((props, ref) => {
        return <HOC {...props} forwardedRef={ref} />;
    });
};

export default withCollapse;