const viewGroupsByHash = {
  info: {
    title: 'Info',
    subGroups: [
      {
        ids: ['colaLabDirector', 'colaLabNpi']
      },
      {
        ids: ['colaBillTypeDesc', 'colaTransmitMethodDesc']
      },
      {
        ids: ['isLisInterface', 'isOrderIdAvailable'],
      },
      {
        ids: ['colaNote']
      },
      {
        ids: ['colaDeliveryNote']
      },
      {
        ids: ['colaPortalPrefDesc']
      },
    ],
  },
  reportsInformation: {
    title: 'Reports Information',
    subGroups: [
      {
        ids: ['reportNameFormatDesc'],
        itemStyles: 'width: 100%; max-width: auto;',
        
      },
      {
        ids: ['templateFilename', 'reportDeliverToDesc', 'idPrintoutDesc'],    
      },
    ],
  },
  contact: {
    title: 'Contact',
    theme: 'contact',
    subGroups: [
      {
        ids: ['colaAddr1']
      },
      {
        ids: ['colaAddr2']
      },
      {
        ids: ['colaCity', 'colaState', 'colaZip', 'colaCountry'],
        itemStyles: 'width: 25%',
      },
      {
        title: 'Office Contact',
        ids: ['colaEmail', 'colaPhone'],
      },
      {
        title: 'Secondary Contact',
        ids: ['colaEmail', 'colaPhone'],
      },
      {
        ids: ['colaFaxEmail'],
      },
    ],
  }
}

const viewGroupsById = [
  'info',
  'contact',
  'reportsInformation'
]

export { viewGroupsById, viewGroupsByHash }