import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BanIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12c6.6,0,12-5.4,12-12C24,5.4,18.6,0,12,0z M2.2,12
	c0-5.4,4.4-9.8,9.8-9.8c2.4,0,4.7,0.9,6.4,2.4L4.6,18.4C3.1,16.7,2.2,14.4,2.2,12z M12,21.8c-2.2,0-4.2-0.7-5.8-1.9L19.9,6.2
	c1.2,1.6,1.9,3.6,1.9,5.8C21.8,17.4,17.4,21.8,12,21.8z"/>
    </SvgIcon>
);

export default BanIcon;