import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const WeightIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M23,22.6L20.4,8.3c-0.1-0.6-0.6-1-1.2-1H15c0.8-0.8,1.3-1.9,1.3-3.1c0-2.4-1.9-4.3-4.3-4.3
	c-2.4,0-4.3,1.9-4.3,4.3c0,1.2,0.5,2.3,1.3,3.1H4.7c-0.6,0-1.1,0.4-1.2,1L1,22.6c-0.1,0.4,0,0.7,0.3,1C1.4,23.8,1.8,24,2.1,24h19.7
	c0.4,0,0.7-0.2,0.9-0.4C23,23.3,23.1,22.9,23,22.6z M12,2.4c1.1,0,1.9,0.9,1.9,1.9c0,1.1-0.9,1.9-1.9,1.9c-1.1,0-1.9-0.9-1.9-1.9
	C10.1,3.2,10.9,2.4,12,2.4z"/>
    </SvgIcon>
);

export default WeightIcon;