import React, { Component } from "react";
import dataProvider, {
  BULK_DELETE,
  GET_LIST,
  GET_ONE,
} from "providers/dataProvider";
import MainHeader from "components/templateParts/MainHeader";
import SingleViewHeader from "components/templateParts/SingleViewHeader/SingleViewHeader";
import { SmartTable } from "components/ui_components/table";
import MedicUserIcon from "../../../../icons/MedicUserIcon";
import CardIcon from "../../../../icons/CardIcon";
import styles from "./PhysiciansShow.module.scss";
import AddClientsModal from "components/pages/physicians/PhysiciansShow/AddClientModal/AddClientsModal.js";
import withModal from "withModal";
import helpers from "utils/helpers";
import { TableHeaderAddButton } from "components/ui_components/buttons";
import { withAuthCenter } from "AuthCenter";
import { compose } from "redux";
import { userRoles } from "config/userRoles";
import { canNotByRoles } from "utils/permissions";

class PhysiciansShowContainer extends Component {
  constructor(props) {
    super(props);

    this.isEditAvailable = canNotByRoles([userRoles.sales]);

    this.state = {
      data: null,
      dataLoaded: false,
      associatedClientsData: null,
      clientsData: null,
      clientsDataLoaded: false,
    };

    this.loadData();
    this.loadAssociatedClients();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authCenter.token !== this.props.authCenter.token) {
      this.loadData();
    }
  }

  loadData() {
    dataProvider(GET_ONE, this.props.resource, { id: this.props.id }).then(
      ({ data, total }) => {
        this.setState({
          dataLoaded: true,
          data: data,
        });
      }
    );
  }

  loadAssociatedClients = () => {
    dataProvider(GET_LIST, this.props.resource, {}).then(
      (associatedClientsData) => {
        console.log(associatedClientsData);
        this.setState({ associatedClientsData });
      }
    );
  };

  handleAddNewAssociatedClients = () => {
    this.smartTable.loadData();
  };

  deleteCallback = (items, resource, loadData) => {
    let ids = items.map((i) => i.clientId);

    dataProvider(BULK_DELETE, `physicians/${this.props.id}/clients`, {
      id: ids,
    }).then(({ data, total }) => {
      loadData();
    });
  };

  render() {
    if (!this.state.dataLoaded) return null;
    const { data, associatedClientsData } = this.state;
    const colConfig = [
      {
        id: "legacyClientId",
        label: "Client ID",
      },
      {
        id: "clientName",
        label: "Name",
        linkAccessor: (row) => `/clients/${row.clientId}/show`,
      },
      {
        id: "",
        label: "Sales Rep. last name",
      },
      {
        id: "",
        label: "Fax",
      },
      {
        id: "clientPhone",
        label: "Phone Number",
      },
    ];
    const widgetsConfigByHash = {
      about: {
        title: "About",
        type: "1",
        iconsData: [
          {
            id: "title",
            title: "Title",
            value: data.physTitle,
            icon: <MedicUserIcon />,
          },
          {
            id: "npi",
            title: "NPI #",
            value: data.npiNumber,
            icon: <CardIcon />,
          },
          {
            id: "ptanNumber",
            title: "PTAN#",
            value: data.ptanNumber,
            icon: <CardIcon />,
          },
          {
            id: "physPortalPrefDesc",
            title: "Patient Portal Preferences",
            value: data.physPortalPrefDesc,
            icon: <CardIcon />,
          },
        ],
      },
    };

    const widgetsConfigById = ["about"];
    const widgetsConfigGroups = [["about"]];

    return (
      <div>
        <MainHeader title="View Physician" />
        <SingleViewHeader
          editLink={this.isEditAvailable ? "/physicians/" + data.id : false}
          title={
            data.physFirstName +
            " " +
            data.physMiddleName +
            " " +
            data.physLastName
          }
          widgetsConfigByHash={widgetsConfigByHash}
          widgetsConfigById={widgetsConfigById}
          widgetsConfigGroups={widgetsConfigGroups}
        />

        {associatedClientsData && this.isEditAvailable ? (
          <AddClientsModal
            physId={this.props.id}
            modalOpen={this.props.modalOpen}
            handleCloseModal={this.props.handleCloseModal}
            selected={helpers.getAllValues(associatedClientsData, "clientId")}
            handleAddNewAssociatedClients={this.handleAddNewAssociatedClients}
          />
        ) : null}

        <div className={`${styles.sectionClientsTable} "singlePage__section"`}>
          <div className={`${"singlePage__container"}`}>
            <div className="singlePage__title4">Clients</div>
            <div className={styles.tableWrap}>
              <SmartTable
                onRef={(ref) => (this.smartTable = ref)}
                columns={colConfig}
                deleteConfirmationMessagePostfix={"associated client"}
                showPagination={false}
                editableTable={this.isEditAvailable}
                showSelect={this.isEditAvailable}
                showSelectAll={this.isEditAvailable}
                showDelete={this.isEditAvailable}
                deleteCallback={this.deleteCallback}
                resource={
                  this.props.resource + "/" + this.props.id + "/clients"
                }
                toolbarExtraComponents={
                  this.isEditAvailable && (
                    <TableHeaderAddButton onClick={this.props.handleOpenModal}>
                      Client
                    </TableHeaderAddButton>
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withModal, withAuthCenter())(PhysiciansShowContainer);
