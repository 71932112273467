import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BanIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M22.7,18.5c0-0.1,0-0.2,0-0.3V8.3c0-0.5-0.4-0.8-0.8-0.8h-4.7V18c0,0.2,0,0.3-0.1,0.5h-0.6c0-0.1,0-0.2,0-0.3V4.6
		c0-0.5-0.4-0.8-0.8-0.8H8.3c-0.5,0-0.8,0.4-0.8,0.8v13.6c0,0.1,0,0.2,0,0.3H6.9c-0.1-0.2-0.1-0.3-0.1-0.5V7.5H2.3
		c-0.5,0-0.8,0.4-0.8,0.8v9.9c0,0.1,0,0.2,0,0.3H0v1.8h24v-1.8H22.7L22.7,18.5z M4,15H2.7v-1.2H4V15z M4,12.9H2.7v-1.2H4V12.9z
		 M4,10.8H2.7V9.6H4V10.8z M5.8,15H4.6v-1.2h1.2V15z M5.8,12.9H4.6v-1.2h1.2V12.9z M5.8,10.8H4.6V9.6h1.2V10.8z M13.9,18.4h-3.6V14
		h3.6V18.4z M12,10.1c-1.6,0-3-1.3-3-3c0-1.6,1.3-3,3-3c1.6,0,3,1.3,3,3S13.6,10.1,12,10.1z M19.7,15h-1.2v-1.2h1.2V15z M19.7,12.9
		h-1.2v-1.2h1.2V12.9z M19.7,10.8h-1.2V9.6h1.2V10.8z M21.6,15h-1.2v-1.2h1.2V15z M21.6,12.9h-1.2v-1.2h1.2V12.9z M21.6,10.8h-1.2
		V9.6h1.2V10.8z M12.7,6.4h1.1v1.5h-1.1V9h-1.5V7.9h-1.1V6.4h1.1V5.3h1.5V6.4z"/>
    </SvgIcon>
);

export default BanIcon;