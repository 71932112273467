import React from "react";
import { SmartTable } from "components/ui_components/table";
import clientHistoryTableConfig from "config/resources/clientsResource/clientHistoryTable.js";

const ClientHistory = ({ clientId }) => {
  return (
    <div>
      <div>
        <SmartTable
          resource={`clients/${clientId}/histories`}
          columns={clientHistoryTableConfig}
          showSelect={false}
          showDelete={false}
          showSelectAll={false}
          showPagination={true}
          collapsibleTableContentType={["activity"]}
          collapsibleTable={true}
        />
      </div>
    </div>
  );
};

export default ClientHistory;
