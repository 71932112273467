import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CardIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M22.9,3.5H1.1C0.5,3.5,0,4,0,4.6v14.8c0,0.6,0.5,1.1,1.1,1.1h21.8c0.6,0,1.1-0.5,1.1-1.1V4.6
	C24,4,23.5,3.5,22.9,3.5z M10.3,15.8l0,1.7H2.8l0-1.7v-1.4c0-0.7,0.8-1,1.4-1.2c0.1-0.1,0.3-0.1,0.4-0.2c0,0-0.1-0.1-0.1-0.1
	c-0.5-0.6-1.1-1.2-1.1-2.4c0-0.1-0.1-0.7-0.1-0.9c-0.2-2,0.9-2.3,2.4-2.8C6,6.8,6.2,6.7,6.5,6.7c0.3-0.1,0.6-0.2,0.9-0.2
	C8,6.5,8.6,6.7,9,7.2c0.5,0.6,0.8,1.5,0.7,2.5l-0.1,0.9c0,1.2-0.6,1.8-1.1,2.4c0,0-0.1,0.1-0.1,0.1c0.1,0.1,0.3,0.1,0.4,0.2
	c0.6,0.2,1.4,0.5,1.4,1.2V15.8z M21.1,16.8h-7.9v-1.5h7.9L21.1,16.8L21.1,16.8z M21.1,13.4h-7.9v-1.5h7.9L21.1,13.4L21.1,13.4z
	 M21.1,10h-7.9V8.5h7.9L21.1,10L21.1,10z M6.5,13c1.3,0,2.4-1.1,2.4-2.5V9.8c0-0.1,0-0.2,0-0.2H8.6C8.1,9.6,7.9,9,7.8,8.7
	C7.2,8.9,5.8,9.5,4.1,9.6c0,0.1,0,0.2,0,0.2v0.7C4.1,11.8,5.2,12.9,6.5,13z"/>
    </SvgIcon>
);

export default CardIcon;