import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux'
import { withNotificationCenter } from 'NotificationCenter'
import withForm from 'withForm';
import MainHeader from "components/templateParts/MainHeader";
import SmartInput from 'components/ui_components/smart_input'
import { createConfig } from "config/resources/usersResource";
import style from './UserCreateContainer.module.scss'
import { styles } from 'components/base/BaseCreateComponent'
import React from 'react';
import dataProvider, { CREATE } from "providers/dataProvider";
import clsx from 'clsx';
import BaseFormControls from 'components/ui_components/BaseFormControls/BaseFormControls';
import MainSingleFormContent from 'components/templateParts/MainSingleFormContent/MainSingleFormContent';
import { getFilteredIds, dynamicValidationInputId } from 'components/pages/users/utils/helpers';

const { inputsGroupsById, inputsGroupsByHash } = createConfig;

const UserCreateContainer = (props) => {
  const { inputsById, inputsByHash, handleInputs } = props;

  const handleSubmit = () => {
    const { isFormValid, isInputValid, resetErrors, inputsByHash } = props;

    const userRoleCodeValue = inputsByHash['userRoleCode'].value;

    resetErrors();

    const isMainFormValid = isFormValid();
    const isSpecialInputValid = dynamicValidationInputId.hasOwnProperty(userRoleCodeValue) ? isInputValid(
      dynamicValidationInputId[userRoleCodeValue]) : true;

    if (isMainFormValid && isSpecialInputValid) {
      submitData();
    }
  }

  const submitData = () => {
    const { notificationCenter, getSubmitData, inputsByHash } = props;

    let submitData = {
      ...getSubmitData()
    }

    if (dynamicValidationInputId.hasOwnProperty(inputsByHash['userRoleCode'].value)) {
      submitData.entityId = inputsByHash[dynamicValidationInputId[inputsByHash['userRoleCode'].value]].value
    }

    dataProvider(CREATE, 'mfa/users', { data: submitData })
    .then((response) => {
      notificationCenter.show('Successfully created', 'info')
      props.history.push('/users/' + response.data.id + '/show');
    })
    .catch(error => {
      notificationCenter.show(error.message, 'warning')
    });
  }

  return (
    <div className={style.root}>
      <MainHeader title={"Create User"}/>
      <MainSingleFormContent>
        <div className={style.form}>
          {inputsById.length > 0 ? inputsGroupsById.map(groupId => {
            return (
              <div key={groupId} className={style['inputs-' + groupId]}>
                {getFilteredIds(inputsGroupsByHash[groupId].ids, inputsByHash).map((inputId, index) => {
                  const input = inputsByHash[inputId];

                  return (
                    <div key={index}
                         className={clsx(style['inputWrap-' + inputId], style.inputWrap)}>
                      <SmartInput
                        autoComplete="new-password"
                        key={index}
                        inputId={inputId}
                        input={input}
                        handleInputs={handleInputs}
                      />
                    </div>
                  )
                })}
              </div>
            )
          }) : null}
        </div>

        <BaseFormControls
          type={'create'}
          handleClickSubmit={handleSubmit}
          submitLabel="Create User"
          resource="users"
          id={props.id}
        />
      </MainSingleFormContent>
    </div>
  );
}

export default compose(
  withNotificationCenter(),
  withForm(createConfig),
  withStyles(styles)
)(UserCreateContainer);