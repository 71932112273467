import React, { useState } from 'react';
import dataProvider, { BULK_DELETE } from "providers/dataProvider";
import ButtonBase from '@material-ui/core/ButtonBase';
import DeleteConfirmModal
  from 'components/ui_components/table/DeleteConfimModal/DeleteConfirmModal';
import styles from './BaseFormControls.module.scss';
import withModal from 'withModal'
import { useHistory } from 'react-router-dom';

const BaseFormControls = ({
                            handleClickSubmit,
                            submitLabel,
                            modalOpen,
                            handleCloseModal,
                            handleOpenModal,
                            deleteConfirmationMessagePostfix,
                            resource,
                            id,
                            type,
                          }) => {

  const [actionDeleteInProgress, setActionDeleteInProgress] = useState(false);

  const history = useHistory();

  const handleConfirmDelete = () => {
    setActionDeleteInProgress(true);

    dataProvider(BULK_DELETE, resource, { id: [id] })
    .then(() => {
      setActionDeleteInProgress(false);
      history.push(`/${resource}`)
    })
    .catch(error => {
      setActionDeleteInProgress(false);
    });
  }

  const handleClickCancel = () => {
    const routes = {
      edit: `/${resource}/${id}/show`,
      create: `/${resource}`
    }

    history.push(routes[type]);
  }

  return (
    <React.Fragment>
      {type === 'edit' && <DeleteConfirmModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        handleConfirmCallback={handleConfirmDelete}
        deleteConfirmationMessagePostfix={deleteConfirmationMessagePostfix}
        actionDeleteInProgress={actionDeleteInProgress}
      />}
      <div className={styles.controls}>
        <ButtonBase
          onClick={handleClickSubmit}
          className="button formButton formButton__mainContained"
        >
          {submitLabel}
        </ButtonBase>
        <ButtonBase
          onClick={handleClickCancel}
          className="button formButton formButton__secondaryOutlined"
        >
          Cancel
        </ButtonBase>

        {type === 'edit' && <ButtonBase
          onClick={handleOpenModal}
          className="button formButton formButton__errorContained"
        >
          Delete
        </ButtonBase>}
      </div>
    </React.Fragment>
  )
}


BaseFormControls.defaultProps = {
  type: 'edit'
}

export default withModal(BaseFormControls);