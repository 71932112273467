export default [
  {
    id: 'testCode',
    label: 'Test code'
  },
  {
    id: 'testDesc',
    label: 'Test description'
  },
  {
    id: 'colaSigDigits',
    label: 'Sig Digits'
  },
  {
    id: 'report',
    label: 'Report',
    accessor: (d) => `${d.colaReportLo} - ${d.colaReportHi}`
  },
  {
    id: 'eventName',
    label: 'Male Normal',
    accessor: (d) => `${d.colaMNormalLo} - ${d.colaMNormalHi}`
  },
  {
    id: 'eventName',
    label: 'Male Border',
    accessor: (d) => `${d.colaMBorderLo} - ${d.colaMBorderHi}`
  },
  {
    id: 'eventName',
    label: 'Male Unfavorable',
    accessor: (d) => `${d.colaMUnfavLo} - ${d.colaMUnfavHi}`
  },
  {
    id: 'eventName',
    label: 'Female Normal',
    accessor: (d) => `${d.colaFNormalLo} - ${d.colaFNormalHi}`,
  },
  {
    id: 'eventName',
    label: 'Female Border',
    accessor: (d) => `${d.colaFBorderLo} - ${d.colaFBorderHi}`,
  },
  {
    id: 'eventName',
    label: 'Female Unfavorable',
    accessor: (d) => `${d.colaFUnfavLo} - ${d.colaFUnfavHi}`,
  },
]