import React from 'react';
import styled from 'styled-components';

const defaultRootStyles = `
  margin: 0 -8px;
    >* {
      margin: 0 8px;
    }
  `;

const StyledButtonGroup = styled.div`
   &&{
      ${defaultRootStyles}
      ${props => props.styles}
    }
  `;

const ButtonGroup = ({ children, rootStyles }) => {
  return (
    <StyledButtonGroup styles={rootStyles}>
      {children}
    </StyledButtonGroup>
    )
}

export default ButtonGroup;

ButtonGroup.defaultProps = {
  rootStyles: '',
};