import { convertSeparatedDateTimeWithTimeZoneToServerUTC } from 'utils/dateTimeFormat.js';

const convertDateCollectedFromToServerUTC = (inputData, clientTz) => {
  if (clientTz === 'TZNA' || clientTz === null) {
    throw new Error(`Client Timezone is not available, please make sure the client’s timezone is set correctly.`)
  } else {
    return convertSeparatedDateTimeWithTimeZoneToServerUTC(
      inputData.dateCollectedYear,
      inputData.dateCollectedMonth,
      inputData.dateCollectedDate,
      inputData.timeCollectedHours,
      inputData.timeCollectedMinutes,
      inputData.timeCollectedAmPm,
      clientTz,
    )
  }
}

const getClientTzFromExtraSelectedOptionData = (extraSelectedOptionData) => {
  if (extraSelectedOptionData.hasOwnProperty('clientId') && extraSelectedOptionData.clientId.hasOwnProperty('clientTz')) {
    return extraSelectedOptionData.clientId.clientTz;
  }
  else {
    return false;
  }
}

export {
  convertDateCollectedFromToServerUTC,
  getClientTzFromExtraSelectedOptionData,
}