import React from "react";
import styles from "./SpecimenContainer.module.scss";
import { SmartTable } from "components/ui_components/table";
import SmartInput from "components/ui_components/smart_input";
import { specimenTabConfig } from "config/resources/requisitionsResource";
import withForm from "withForm";
import ButtonBase from "@material-ui/core/ButtonBase";
import { compose } from "redux";
import dataProvider, { CREATE } from "providers/dataProvider";
import PropTypes from "prop-types";
import helpers from "utils/helpers";
import Can from "Can";
import clsx from "clsx";
import { useNotificationCenter } from "NotificationCenter";
import { userRoles, userGroups } from "config/userRoles";
import { convertSeparatedDateTimeWithTimeZoneToServerUTC } from "utils/dateTimeFormat.js";

const {
  inputsByHash,
  inputsById,
  inputsGroupsById,
  inputsGroupsByHash,
  tableColConfig,
} = specimenTabConfig;

const withFormConfig = { inputsByHash, inputsById };

let smartTableRef = {};

const SpecimenContainer = (props) => {
  const {
    isFormValid,
    resetErrors,
    clearInputs,
    getSubmitData,
    handleInputs,
    inputsByHash,
    inputsById,
    reqID,
  } = props;

  const notificationCenter = useNotificationCenter();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid()) {
      resetErrors();

      let allFieldsData = getSubmitData(false);

      let data = {
        ...getSubmitData(),
        dateReceived: convertSeparatedDateTimeWithTimeZoneToServerUTC(
          allFieldsData.dateReceivedYear,
          allFieldsData.dateReceivedMonth,
          allFieldsData.dateReceivedDate,
          allFieldsData.dateReceivedHours,
          allFieldsData.dateReceivedMinutes,
          allFieldsData.dateReceivedAmPm,
          props.reqData.labTz
        ),
      };

      const resource = `requisitions/${reqID}/specimens`;

      dataProvider(CREATE, resource, { data: data })
        .then((response) => {
          smartTableRef.current.loadData();
          clearInputs(true);
          handleInputs({
            target: {
              name: "tubeCount",
              value: 1,
            },
          });
          notificationCenter.show("Successfully created", "info");
        })
        .catch((error) => {
          notificationCenter.show(error.message, "warning");
        });
    }
  };

  return (
    <div className={styles.root}>
      <Can allowedRoles={userGroups.superAdmins}>
        <div className={styles.sidebar}>
          <div className={styles.formTitle}>Add Specimen</div>

          <form className={styles.form}>
            {inputsById.length > 0
              ? inputsGroupsById.map((groupId) => {
                  return (
                    <div
                      key={groupId}
                      className={clsx(
                        styles["formGroup-" + groupId],
                        styles.formGroup
                      )}
                    >
                      <div className={styles.groupTitle}>
                        {inputsGroupsByHash[groupId].title}
                      </div>
                      <div className={styles.groupInputsList}>
                        {inputsGroupsByHash[groupId].ids.map(
                          (inputId, index) => {
                            const input = inputsByHash[inputId];
                            return (
                              <div key={index} className={styles.inputWrap}>
                                <SmartInput
                                  key={inputId}
                                  parentStyles={styles}
                                  inputId={inputId}
                                  input={input}
                                  handleInputs={handleInputs}
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                })
              : null}

            <div className={styles.formControls}>
              <ButtonBase
                onClick={handleSubmit}
                type="submit"
                className={`${styles.submitButton} button formButton formButton__mainContained`}
              >
                Add Specimen to Requisition
              </ButtonBase>
            </div>
          </form>
        </div>
      </Can>

      <div
        className={clsx([
          helpers.can(userGroups.superAdmins)
            ? styles.main
            : styles.mainFullWidth,
        ])}
      >
        <SmartTable
          onRef={(ref) => (smartTableRef.current = ref)}
          columns={tableColConfig}
          resource={`requisitions/${reqID}/specimens`}
          showPagination={false}
          perPage={-1}
          showSelect={true}
          showDelete={!helpers.can(userRoles.sales)}
          showSelectAll={true}
          rowAccessorExtraData={{
            reqData: {
              labTz: props.reqData.labTz,
              labTzAbbr: props.reqData.labTzAbbr,
            },
          }}
        />
      </div>
    </div>
  );
};

SpecimenContainer.propTypes = {
  reqID: PropTypes.string.isRequired,
};

export default compose(withForm(withFormConfig))(SpecimenContainer);
