import React from 'react'
import styles from "./WidgetType1.module.scss";
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar'
import styled from 'styled-components';

const StyledWidgetWrap = styled.div`
 &&{
    box-sizing: border-box;
    ${props => props.styles}
  }
`;

const StyledWidget = styled.div`
 &&{
    ${props => props.styles}
  }
`;

const StyledWidgetContent = styled.div`
 &&{
    ${props => props.styles}
  }
`;

const WidgetType1 = (props) => {
  const {title, content, headerRightContent, showProgressBar, children } = props;

  return (
    <StyledWidgetWrap styles={props.wrapStyles}>
      <StyledWidget className={styles.root} styles={props.styles}>
        {showProgressBar && <ProgressBar/>}
        <div className={styles.widgetHeader}>
          <div className={styles.widgetTitle}>
            {title}
          </div>

          {headerRightContent && <div>
            {headerRightContent}
          </div>}
        </div>

        <StyledWidgetContent styles={props.widgetContentStyles} className={styles.widgetContent}>
          {content || children}
        </StyledWidgetContent>
      </StyledWidget>
    </StyledWidgetWrap>
  )
}

WidgetType1.defaultProps = {
  title: 'Title',
  content: null,
  controls: null,
  showProgressBar: false,
  headerRightContent: null,
  widgetContentStyles: '',
  styles: '',
  wrapStyles: '',
}

export default WidgetType1