import helpers from 'utils/helpers';


export default {
    inputsById: [
        'colaId',
        'reqIds'
    ],
    inputsByHash: {
        colaId: {
            label: 'For Lab/Collaborator',
            yup: helpers.getYupRequired('For Lab/Collaborator'),
            type: 'autocomplete',
            resource: 'collaborators',
            accessor: (d) => {
                return ({value: d.id, label: d.colaName})
            }
        },
        reqIds: {
            label: 'Requisition(s)',
            yup: helpers.getYupRequired('Requisition(s)'),
            submitValueAccessor: (d) => d.split(',')
        }
    }
}