import helpers from "utils/helpers";
import { formatServerDateTimeToLocal } from "utils/dateTimeFormat";

export default {
  tableColConfig: {
    tableColById: [
      "paymentTypeDesc",
      "payerTypeDesc",
      "payer",
      "paymentNo",
      "paymentDate",
      "amount",
      "paymentNote",
      "createdAt",
      "createByName",
    ],
    tableColByHash: {
      paymentTypeDesc: {
        label: "Payment Type",
      },
      payerTypeDesc: {
        label: "Payer type",
      },
      payer: {
        label: "Payer",
      },
      paymentNo: {
        label: "Check/Conf.#",
      },
      paymentDate: {
        label: "Payment Date",
        accessor: (d) =>
          d.paymentDate && helpers.getFormattedDate(d.paymentDate),
      },
      amount: {
        label: "Amount",
        accessor: (d) => `$${d.amount}`,
      },
      paymentNote: {
        label: "Note",
      },
      createdAt: {
        label: "Created At",
        accessor: (d) => formatServerDateTimeToLocal(d.createDate, "dateTime"),
      },
      createByName: {
        label: "Created By",
      },
    },
  },

  addFormConfig: {
    inputsByHash: {
      paymentTypeCode: {
        label: "Payment Type",
        yup: helpers.getYupRequired("Payment Type"),
        type: "radio",
        resource: "lovs/PAYMENTTYPE",
        accessor: (d) => ({
          value: d.paymentTypeCode,
          label: d.paymentTypeDesc,
        }),
      },
      payerTypeCode: {
        label: "Payer Type",
        yup: helpers.getYupRequired("Payer Type"),
        type: "radio",
        resource: "lovs/PAYERTYPE",
        accessor: (d) => ({ value: d.payerTypeCode, label: d.payerTypeDesc }),
      },
      payer: {
        label: "Paid by",
        yup: helpers.getYupRequired("Paid by"),
      },
      amount: {
        label: "Amount",
        yup: helpers.getYupRequired("Amount"),
      },
      paymentNote: {
        label: "Note",
        yup: helpers.getYupRequired("Note"),
      },
      paymentMonth: {
        label: "Month",
        yup: helpers.getYupDateMonthRequired("Month"),
        submitValueAccessor: (d) => parseInt(d),
        excludeSubmit: true,
      },
      paymentDate: {
        label: "Day",
        yup: helpers.getYupDateDayRequired("Day"),
        submitValueAccessor: (d) => parseInt(d),
        excludeSubmit: true,
      },
      paymentYear: {
        label: "Year",
        yup: helpers.getYupDateYearRequired("Year"),
        submitValueAccessor: (d) => parseInt(d),
        excludeSubmit: true,
      },
      paymentNo: {
        label: "Check / Confirmation #",
        yup: helpers.getYupRequired("Check / Confirmation #"),
      },
    },

    inputsById: [
      "paymentTypeCode",
      "payerTypeCode",
      "payer",
      "amount",
      "paymentNote",
      "paymentMonth",
      "paymentDate",
      "paymentYear",
      "paymentNo",
    ],

    inputsGroupsByHash: {
      group1: {
        ids: [
          "paymentTypeCode",
          "payerTypeCode",
          "payer",
          "amount",
          "paymentNote",
        ],
      },
      group2: {
        title: "Payment Date",
        ids: ["paymentMonth", "paymentDate", "paymentYear"],
      },
      group3: {
        ids: ["paymentNo"],
      },
    },

    inputsGroupsById: ["group1", "group2", "group3"],
  },
};
