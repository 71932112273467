import React from "react";
import { Component } from "react";
import { SmartTable } from "components/ui_components/table";
import { resourcesConfig } from "../../config/resources";
import { compose } from "redux";
import { withSmartFilter } from "SmartFilterProvider";

class BaseList extends Component {
  handleRowClick = (e, index, row) => {
    this.props.history.push(`${this.props.resource}/${row.id}/show`);
  };

  colConfig = () => {
    return this.props.tableColConfig
      ? this.props.tableColConfig
      : resourcesConfig[this.props.resource].tableColConfig;
  };

  render() {
    let columns = this.colConfig();

    return (
      <div>
        <SmartTable
          {...this.props}
          columns={columns}
          handleSelected={this.handleSelected}
          filter={this.props.smartFilter.getFilter()}
          handleRowClick={this.props.disableRowClick ? () => { } : this.handleRowClick}
          extraData={this.props.extraData}
          deleteConfirmationMessagePostfix={
            this.props.deleteConfirmationMessagePostfix
          }
          deleteResource={this.props.deleteResource}
          {...this.props.tableProps}
        />
      </div>
    );
  }
}

BaseList.defaultProps = {
  subFilter: null,
  showNextStatusAction: false,
  showPrintAction: false,
  editableTable: false,
  collapsibleTable: false,
  subTableConfig: null,
  showDelete: true,
  showDownloadAction: false,
  deleteResource: null,
  handleRowClick: true,
  disableRowClick: false,
}

const BaseListComponent = compose(
  withSmartFilter(),
)(BaseList);

export { BaseListComponent, BaseList };
