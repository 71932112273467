import React, { useState, useEffect, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import styles from './BaseTableRow.module.scss';
import withCollapse from './withCollapse';
import EditTableCell from 'components/ui_components/table/editTableCells/EditTableCell';
import BaseTableCollapseContent from 'components/ui_components/table/BaseTableCollapseContent';
import BaseTableCollapseTrigger
  from 'components/ui_components/table/BaseTableCollapseTrigger/BaseTableCollapseTrigger'
import BaseTableCellSelect from 'components/ui_components/table/BaseTableCellSelect';
import IconTooltip from 'components/ui_components/IconTooltip/IconTooltip.js';
import { useAuthCenter } from 'AuthCenter';
import {
  Link
} from "react-router-dom";
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar.js';
import clsx from 'clsx';


const BaseTableRow = React.forwardRef((props, ref) => {
  const authCenter = useAuthCenter();
  const [inputDataById, setInputDataById] = useState([]);
  const [inputDataByHash, setInputDataByHash] = useState({});

  const {
    columns,
    row,
    customRowActions,
    parentRow,
    isCollapseOpen,
    isSelected,
    index,
    showSelect,
    extraData,
    collapsibleTableContentType,
    collapsibleTableProps,
    collapsibleTable,
    rowAccessorExtraData,
  } = props;

  useEffect(() => {
    const initInputData = () => {
      let inputDataById = [];
      let inputDataByHash = {};

      columns.forEach(column => {
        if (column.hasOwnProperty('edit') && column.edit) {
          inputDataById.push(column.id);
          inputDataByHash[column.id] = row[column.id]
        }
      })

      setInputDataById(inputDataById);
      setInputDataByHash(inputDataByHash);
    }

    initInputData();
  }, [columns, row]);

  const handleSelectRow = (event, row) => {
    event.stopPropagation()
    props.handleSelectRow(event, row)
  }

  const renderCustomRowActions = () => {
    return <React.Fragment>
      {customRowActions.map((action, index) => {
        if (action.hasOwnProperty('isHiddenAccessor') && action.isHiddenAccessor(
          row,
          {
            inputDataById: inputDataById,
            inputDataByHash: inputDataByHash,
          })) return false;
        
        let disabled = action.progress || false;

        if (!action.hasOwnProperty('handleOnClick')) {
          disabled = true;
        }

        return (
          <IconTooltip key={index} title={action.tooltipText}>
            <IconButton className={clsx({
                                [styles.actionIcon] : action.hasOwnProperty('handleOnClick')
                            })}
                        disabled={disabled}
                        onClick={(e) => {
                          e.stopPropagation();
                          action.handleOnClick([row], inputDataByHash)
              }}>
              {action.showProgress && <ProgressBar
                rootStyles="border-radius: 50%;"
                size="small"
              />}
              {action.icon}
            </IconButton>
          </IconTooltip>
        )
      })}
    </React.Fragment>
  }

  const handleRowClick = (event, index, row) => {
    props.handleRowClick(event, index, row);
  }

  const handleUpdateRow = (event, field) => {
    let value = event.target.value

    setInputDataByHash((prevState) => {
      const newState = { ...prevState, [field]: value };

      props.handleSubTableRowsInputData({
        inputDataByHash: newState,
        inputDataById: inputDataById,
        row: row,
        parentRow: parentRow
      })

      return newState
    })
  }

  const handleHideCollapse = () => {
    props.handleHideCollapse();
  }

  const handleShowCollapse = () => {
    props.handleShowCollapse();
  }

  useImperativeHandle(ref, () => ({
    handleShowCollapse() {
      props.handleShowCollapse();
    },
    handleHideCollapse() {
      props.handleHideCollapse();
    }
  }));

  const handleCollapseClick = (e) => {
    e.stopPropagation()

    isCollapseOpen ? handleHideCollapse() : handleShowCollapse()
  }

  let collapseShowButtonAccessor = collapsibleTableProps.isVisibleShowButtonAccessor(row);
  let showCollapseTable = props.collapsibleTable && collapseShowButtonAccessor;

  return (
    <React.Fragment
    >
      <TableRow
        ref={ref}
        hover
        role="checkbox"
        aria-checked={isSelected}
        tabIndex={-1}
        key={index}
        selected={isSelected}
        onClick={event => handleRowClick(event, index, row)}
      >
        {showSelect && <BaseTableCellSelect
          row={row}
          defaultSelected={props.defaultSelected}
          readMode={props.readMode}
          handleSelectRow={handleSelectRow}
          isSelected={props.isSelected}
        />}

        {collapsibleTable &&
        <TableCell className={styles.tableCellCollapseTrigger}>
          {collapseShowButtonAccessor &&
          <BaseTableCollapseTrigger
            handleCollapseClick={handleCollapseClick}
            isCollapseOpen={props.isCollapseOpen}
          />}
        </TableCell>

        }

        {columns.map((col, index) => {
          let rowValue = col.accessor ? col.accessor(row, rowAccessorExtraData, extraData) : row[col.id]
          const colOnClick = col.hasOwnProperty('onClick') ? col.onClick : false;
          const colOnClickPermissions = col.hasOwnProperty('onClickPermissions') ? authCenter.can(
            col.onClickPermissions) : true;

          const linkAccessor = col.hasOwnProperty('linkAccessor') ? col.linkAccessor : false;
          const linkPermissions = col.hasOwnProperty('linkPermissions') ? authCenter.can(col.linkPermissions) : true;

          let activeCellValueProps = {};

          if (colOnClick && colOnClickPermissions) {
            activeCellValueProps = {
              className: styles.cellValueLink,
              onClick: () => {
                col.onClick(row)
              }
            }
          }

          let colEdit = false;

          if (col.hasOwnProperty('edit')) {
            colEdit = col.edit
          }

          if (col.hasOwnProperty('editAccessor')) {
            colEdit = col.editAccessor(row, rowAccessorExtraData)
          }
          
          return colEdit ?
            <EditTableCell
              key={index}
              extraData={extraData}
              col={col}
              row={row}
              rowValue={rowValue}
              inputDataByHash={inputDataByHash}
              handleUpdateRow={handleUpdateRow}
              smartTable={props.smartTable}
            /> :
            <TableCell key={index}
                       className={styles[`tableCell-${col.id}`]}
            >
              {linkAccessor && linkPermissions ?
                <Link to={linkAccessor(row)} className={styles.cellValueLink}>
                  {rowValue}
                </Link>
                :
                <span
                  {...activeCellValueProps}>
                  {rowValue}
                </span>
              }
            </TableCell>;
        })}

        {(props.customRowActions || props.editableTable) &&
        <TableCell className="actions-col">
          <div className={styles.actionColButtonGroup}>
            {props.customRowActions && renderCustomRowActions()}
          </div>
        </TableCell>
        }
      </TableRow>

      {showCollapseTable && <BaseTableCollapseContent
        row={row}
        handleSubTableRowsInputData={props.handleSubTableRowsInputData}
        columns={columns}
        extraData={extraData}
        subTableData={props.subTableData}
        isCollapseOpen={props.isCollapseOpen}
        handleHideCollapse={props.handleHideCollapse}
        collapsibleTableContentType={collapsibleTableContentType}
        collapsibleTableProps={collapsibleTableProps}

      />}
    </React.Fragment>
  )
})

BaseTableRow.propTypes = {
  columns: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  showSelect: PropTypes.bool,
  extraData: PropTypes.object
}

BaseTableRow.defaultProps = {
  handleSelected: (i) => {
  },
  isVisibleShowButtonAccessor: () => true,
  collapsibleTableProps: {},
  handleClick: (i) => {
  },
  deleteSelected: (i) => {
  },
  extraData: {},
  rowAccessorExtraData: {},
  showSelect: false
}


export default withCollapse(BaseTableRow)
