import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const UsersIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M16.4,10.9c1.8,0,3.3-1.5,3.3-3.3s-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3S14.6,10.9,16.4,10.9z
	 M7.6,10.9c1.8,0,3.3-1.5,3.3-3.3S9.4,4.4,7.6,4.4S4.4,5.8,4.4,7.6S5.8,10.9,7.6,10.9z M7.6,13.1c-2.5,0-7.6,1.3-7.6,3.8v2.7h15.3
	v-2.7C15.3,14.4,10.2,13.1,7.6,13.1z M16.4,13.1c-0.3,0-0.7,0-1.1,0.1c1.3,0.9,2.1,2.1,2.1,3.8v2.7H24v-2.7
	C24,14.4,18.9,13.1,16.4,13.1z"/>
    </SvgIcon>
);

export default UsersIcon;