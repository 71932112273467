import React from "react";
import ModalWithAddForm from "components/ui_components/modals/ModalWithAddForm.js";
import withForm from "withForm";
import PropTypes from "prop-types";

const AddModal = (props) => {
  const {
    modalOpen,
    submitResource,
    clearInputs,
    getSubmitData,
    handleInputs,
    resetErrors,
    isFormValid,
    handleCloseModal,
    onSubmitOk,
    inputsGroupsById,
    inputsGroupsByHash,
    title,
  } = props

  return (
    <ModalWithAddForm
      title={title}
      modalIsOpen={modalOpen}
      submitResource={submitResource}
      isEditMode={false}
      clearInputs={clearInputs}
      getSubmitData={getSubmitData}
      handleInputs={handleInputs}
      resetErrors={resetErrors}
      isFormValid={isFormValid}
      submitOkCallback={onSubmitOk}
      inputsById={props.inputsById}
      inputsByHash={props.inputsByHash}
      handleCloseModal={handleCloseModal}
      inputsGroupsById={inputsGroupsById}
      inputsGroupsByHash={inputsGroupsByHash}
      inputWrapStyles={"width: 100%; margin: 7px 0 0;"}
    />
  );
};

AddModal.defaultProps = {
  onSubmitOk: () => {},
};

AddModal.propTypes = {
  onSubmitOk: PropTypes.func.isRequired,
};

export default withForm()(AddModal);
