import React from 'react';
import styled from 'styled-components';

const defaultStyles = `
   text-align: center;
    padding: 39px 0 0;
    
    >button {
      margin: 0 10px;
    }
  `

const StyledFormControlsGroup = styled.div`
   &&{
      ${defaultStyles}
      ${props => props.styles}
    }
  `;

const FormControlsGroup = ({ rootStyles, children }) => {
  return (
    <StyledFormControlsGroup styles={rootStyles}>
      {children}
    </StyledFormControlsGroup>
  )
}

export default FormControlsGroup;

FormControlsGroup.defaultProps = {
  rootStyles: '',
}