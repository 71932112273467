import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import PencilIcon from "../../../icons/PencilIcon";
import Button from "@material-ui/core/Button";
import style from "./SingleViewHeader.module.scss";
import WidgetListByGroupsComponent from "./WidgetListByGroupsComponent";
import WidgetListItemComponent from "./WidgetListItemComponent";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";

const styles = (theme) => {
  return {
    introBar: {
      background: theme.palette.secondary2.main,
      padding: `30px 0 30px`,
      minHeight: "102px",
    },
    introBarTitle: {
      color: "white",
      fontSize: "27px",
      lineHeight: "38px",
    },
    introBarSubtitle: {
      color: "white",
      fontSize: "20px",
      lineHeight: "29px",
    },
    infoWidgetWrapper: {
      position: "relative",
      top: "-48px",
      margin: "0 0 -48px",
    },
    infoWidgetRow: {
      margin: "0 -25px",
      display: "flex",
      alignItems: "flex-start",
    },

    infoWidgetTitle: {
      padding: "10px 13px 10px 27px",
      fontSize: "19px",
      lineHeight: "27px",
    },
    editButton: {
      border: "none",
      textTransform: "none",
      fontWeight: 700,
      fontSize: 20,
      color: "white",
      transition: "opacity .5s",
      "&:hover": {
        background: "none",
        opacity: ".5",
      },
      "& svg": {
        width: 17,
        height: 17,
        color: "white",
        margin: "0 17px 0 0",
      },
    },
  };
};

const SingleViewHeader = (props) => {
  const {
    classes,
    editLink,
    title,
    subtitle,
    widgetsConfigByHash,
    widgetsConfigGroups,
    widgetsConfigById,
  } = props;
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.introBar}>
        <div
          className={clsx({
            singlePage__container: true,
            [style.introBarContainer]: true,
            [style.containerSingleWidget]: widgetsConfigById.length === 1,
          })}
        >
          <div className={classes.introBarTitle}>
            {title}
            <div className={classes.introBarSubtitle}>{subtitle}</div>
          </div>
          {editLink && (
            <div className={classes.introBarEditBtn}>
              <Button
                onClick={() => history.push(editLink)}
                variant="outlined"
                color="default"
                className={classes.editButton}
              >
                <PencilIcon />
                Edit
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className={classes.infoWidgetWrapper}>
        <div
          className={clsx({
            singlePage__container: true,
            [style.containerSingleWidget]: widgetsConfigById.length === 1,
          })}
        >
          {widgetsConfigGroups.map((widgetsIDs, index) => (
            <div className={classes.infoWidgetRow} key={index}>
              {widgetsIDs.map((widgetID) => {
                const widget = widgetsConfigByHash[widgetID];

                return (
                  <div
                    key={widgetID}
                    className={clsx(
                      [style.infoWidget],
                      style["infoWidgetType" + widget.type]
                    )}
                  >
                    <div className={classes.infoWidgetTitle}>
                      {widget.title}
                    </div>

                    {widget.hasOwnProperty("iconsDataGroupsByID") ? (
                      <WidgetListByGroupsComponent
                        iconsDataByHash={widget.iconsDataByHash}
                        iconsDataGroupByHash={widget.iconsDataGroupByHash}
                        iconsDataGroupsByID={widget.iconsDataGroupsByID}
                      />
                    ) : null}

                    {widget.hasOwnProperty("iconsData") ? (
                      <div className={clsx([style.widgetList])}>
                        {widget.iconsData.map((listItem) => (
                          <WidgetListItemComponent
                            key={listItem.id}
                            icon={listItem}
                          />
                        ))}
                      </div>
                    ) : null}

                    {widget.notesList ? (
                      <div className={style.notesList}>
                        {widget.notesList.map((note) => (
                          <div key={note.id} className={style.notesListItem}>
                            <div className={style.notesListItemTitle}>
                              {note.title}
                            </div>
                            <div className={style.notesListItemContent}>
                              {ReactHtmlParser(note.content)}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(SingleViewHeader);
