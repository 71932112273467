import React from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import styles from './BaseTableCollapseTrigger.module.scss';

export default function ({ handleCollapseClick, isCollapseOpen }) {
  return (
    <div>
      <div className={styles.collapseTrigger} onClick={handleCollapseClick}>
        <span className={styles.test}>{isCollapseOpen ? 'Hide' : 'Show'}</span>
        <span>{isCollapseOpen ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}</span>
      </div>
    </div>
  )
}