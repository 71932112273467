import React, {PureComponent} from 'react';
import styles from './date.module.scss';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DateField extends PureComponent {
    constructor(props) {
        super(props)
        const [from, to] = this.initDate(props.value || '')
        this.state = {
            ...props,
            from: from,
            to: to,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            const [from, to] = this.initDate(this.props.value)
            this.setState({
                from: from,
                to: to,
            })
        }
    }

    initDate(value) {
        return value.replace("[", '').replace("]", '').split('-').map(d => Date.parse(d))
    }


    changeFrom = (from)=> {
        this.setState({ from })
        this.handleInputs(from, this.state.to)
    }


    changeTo = (to) => {
        this.setState({to})
        this.handleInputs(this.state.from, to)
    }

    handleInputs(from, to) {
       if (from !== '' && to !== '') {
           const format = 'MM/DD/YYYY'
           const from_r = moment(from).format(format)
           const to_r = moment(to).format(format)
           let value = `[${from_r}-${to_r}]`
           const label = `${from_r} - ${to_r}`
           let e = {target: { value, name: this.props.inputId}}
           this.props.handleInputs(e, label)
       }
    }

    render() {
        const {inputId} = this.props;
        const {from, to} = this.state
        return (
            <div className={styles.root}>
                <DatePicker
                    className={styles.field}
                    selected={from}
                    onChange={this.changeFrom}
                    name={inputId + '-from'}
                />
                <DatePicker
                    className={styles.field}
                    selected={to}
                    onChange={this.changeTo}
                    popperPlacement={'bottom-end'}
                    name={inputId + '-to'}
                />
            </div>

        );
    }
}

export default DateField;