import React from "react";
import {
  MedicUserIcon,
  StatIcon,
  SheetIcon,
  UsersIcon,
  HospitalIcon,
  SalesIcon,
  GlobeIcon,
} from "icons/index";
import PublishIcon from "@material-ui/icons/Publish";
import Can from "Can";
import { userGroups, userPermissions, userRoles } from "config/userRoles";

import CustomMenuItem from "components/SidebarSubMenu/CustomMenuItem";
import styles from "./SidebarMenu.module.scss";
import { NavLink } from "react-router-dom";
import { AcUnit, MoreHoriz, LibraryBooks } from "@material-ui/icons";
import routesConfig from "config/routesConfig";
import CustomDropdownMenuItem from "components/SidebarSubMenu/CustomDropdownMenuItem";
import { filter } from "lodash";
import { canByRoles } from 'utils/permissions';

const menuConfig = [
  {
    label: "Collaborators",
    icon: <GlobeIcon />,
    allowedRoles: userGroups.superAdmins,
    url: routesConfig.resources.collaborator.baseUrl,
  },
  {
    label: "Clients",
    icon: <HospitalIcon />,
    allowedRoles: [...userGroups.superAdmins, userRoles.sales],
    url: routesConfig.resources.clients.baseUrl,
  },
  {
    label: "Physicians",
    icon: <MedicUserIcon />,
    allowedRoles: [...userGroups.superAdmins, userRoles.sales],
    url: routesConfig.resources.physicians.baseUrl,
  },
  {
    label: "Sales",
    icon: <SalesIcon />,
    allowedRoles: userGroups.superAdmins,
    url: routesConfig.resources.sales.baseUrl,
  },
  {
    label: "Requisitions",
    icon: <SheetIcon />,
    allowedRoles: userGroups.allRegistered,
    url: routesConfig.resources.requisitions.baseUrl,
  },
  {
    label: "Reports",
    icon: <StatIcon />,
    allowedRoles: userGroups.superAdmins,
    url: routesConfig.resources.reports.baseUrl,
  },
  {
    label: "Users",
    icon: <UsersIcon />,
    allowedRoles: userPermissions.userEntity.resource,
    url: routesConfig.resources.users.baseUrl,
  },
  {
    label: "Test Reports Upload",
    icon: <PublishIcon />,
    allowedRoles: userGroups.superAdmins,
    url: routesConfig.resources.testReportsUpload.baseUrl,
  },
  {
    label: "More",
    type: "dropdown",
    icon: <MoreHoriz />,
    subItems: [
      {
        label: "Freezer Reverse Lookup",
        icon: <AcUnit />,
        url: routesConfig.resources.freezerSpecs.baseUrl,
        allowedRoles: userGroups.superAdmins,
      },
      {
        label: "Batch CSV Upload",
        icon: <LibraryBooks />,
        url: routesConfig.resources.batchCsvUpload.baseUrl,
        allowedRoles: [userRoles.management, userRoles.admin, userRoles.gdbstaff],
      },
    ],
  },
];

const getMenuItems = (items) => {
  return items.map((item, index) => {
    if (item.type === "dropdown") {
      const subItemsAllowedRoles = filter(item.subItems, (item) => {
        return canByRoles(item.allowedRoles);
      });
      
      if (subItemsAllowedRoles.length === 0) return null;

      return (
        <CustomDropdownMenuItem
          key={index}
          label={
            <React.Fragment>
              {item.icon} {item.label}
            </React.Fragment>
          }
        >
          {getMenuItems(item.subItems)}
        </CustomDropdownMenuItem>
      );
    }
    return (
      <Can key={index} allowedRoles={item.allowedRoles}>
        <CustomMenuItem>
          <NavLink to={`/${item.url}`}>
            {item.icon} {item.label}
          </NavLink>
        </CustomMenuItem>
      </Can>
    );
  });
};

const MyMenu = () => {
  return <ul className={styles.menu}>{getMenuItems(menuConfig)}</ul>;
};

export default MyMenu;
