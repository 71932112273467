import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ClockIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,21.4c-5.2,0-9.4-4.2-9.4-9.4
	S6.8,2.6,12,2.6s9.4,4.2,9.4,9.4S17.2,21.4,12,21.4z M18.3,11.6h-5.4V5.1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1v7.5c0,0.5,0.4,1,1,1h6.4
	c0.5,0,1-0.4,1-1C19.3,12.1,18.8,11.6,18.3,11.6z"/>
    </SvgIcon>
);

export default ClockIcon;