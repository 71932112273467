import React, { useState, useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import SimpleInput from "components/ui_components/smart_input/SimpleInput";
import FormControl from "@material-ui/core/FormControl";
import FilterList from "./FilterList";
import { withNotificationCenter } from "NotificationCenter";
import { compose } from "redux";
import dataProvider, { GET_LIST, DELETE } from "providers/dataProvider";

const styles = (theme) => ({
  formControl: {
    padding: "15px 0 0",
    width: "100%",
  },
});

let filterInputDelayTimer = 0;

const SmartViews = ({
  classes,
  resource,
  notificationCenter,
  handleBulkInputs,
  clearAllInputs,
}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  const loadSmartViews = useCallback(
    (filterInput = null) => {
      let filter = { smartScreen: resource };
      if (filterInput) filter.smartName = filterInput;

      setDataLoading(true);
      dataProvider(GET_LIST, "smart_filters", {
        pagination: {
          page: 1,
          perPage: 100,
        },
        sort: { field: null, order: null },
        filter: filter,
      })
        .then(({ data, total }) => {
          setData(data);
          setDataLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [resource]
  );

  useEffect(() => {
    loadSmartViews();
  }, [loadSmartViews]);

  const deleteSmartView = (id) => {
    deleteSmartViewFromState(id);

    dataProvider(DELETE, "smart_filters", { id: id }).then(
      ({ data, total }) => {
        notificationCenter.show("Smart filter deleted successfully", "info");
      }
    );
  };

  const deleteSmartViewFromState = (id) => {
    const updatedData = data.filter((item) => item.id !== id);
    setData(updatedData);
  };

  const handleSearchInput = (e) => {
    clearTimeout(filterInputDelayTimer);
    const targetValue = e.target.value;

    filterInputDelayTimer = setTimeout(function () {
      loadSmartViews(targetValue);
    }, 1000);
  };

  return (
    <div>
      <div>
        <FormControl className={classes.formControl}>
          <SimpleInput
            placeholder="Filter Smart Views"
            className={classes.formInput}
            handleInputs={handleSearchInput}
          />
          <FilterList
            dataLoading={dataLoading}
            list={data}
            deleteSmartView={deleteSmartView}
            handleBulkInputs={handleBulkInputs}
            clearAllInputs={clearAllInputs}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default compose(
  withStyles(styles),
  withNotificationCenter()
)(SmartViews);
