import React from 'react';
import dataProvider, {UPDATE_LIST} from "providers/dataProvider";
import { compose } from 'redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './withAutoSave.module.scss'
import {withNotificationCenter} from 'NotificationCenter';

const withAutoSave = WrappedComponent => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);

            this.state =  {
                dataLoading: false
            }
            this.timer = null
        }

        autoSave = (data, resource, successfullyMessage) => {

            let self = this;

            return new Promise(function(resolve, reject) {
                if (self.timer) {
                    clearTimeout(self.timer)
                }

                self.setState({
                    dataLoading: true
                })

                self.timer = setTimeout(()=>{
                    dataProvider(UPDATE_LIST, resource, {data: data})
                        .then((response) => {
                            resolve();
                            self.props.notificationCenter.show(successfullyMessage, 'info')
                            self.setState({
                                dataLoading: false
                            })
                            self.props.autoSaveCallBack();
                        })
                        .catch(error => {
                            reject(error)
                            self.props.notificationCenter.show(error.message, 'warning')
                            self.setState({
                                dataLoading: false
                            })
                        });
                }, 1000)
            });
        }

        render() {
            const {dataLoading} = this.state;

            return<React.Fragment>
                <div className={styles.autoSaveInputWrap}>
                    {dataLoading && <div className={styles.autoSaveInputProgress}>
                        <CircularProgress/>
                    </div>}
                    <WrappedComponent
                        {...this.props}
                        autoSave={this.autoSave}
                        />
                </div>
            </React.Fragment>
            }
    }


    HOC.defaultProps = {
        autoSaveCallBack: () => {}
    }

    return compose(
        withNotificationCenter()
        )(HOC);
};

export default withAutoSave;