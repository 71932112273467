import React from "react";
import clsx from "clsx";
import styles from "./IssuesListItemComponent.module.scss";
import ButtonBase from "@material-ui/core/ButtonBase";
import Select from "components/ui_components/smart_input/select.js";
import dataProvider, { UPDATE_ITEM } from "providers/dataProvider";
import CircularProgress from "@material-ui/core/CircularProgress";
import WorkLogList from "./WorkLogList.js";
import { formatServerDateTimeToLocal } from "utils/dateTimeFormat.js";
import { find } from "lodash";
import Can from "Can";
import { userRoles } from "config/userRoles";

const IssuesListItemContainer = (props) => {
  const [isUpdatingStatus, setUpdatingStatus] = React.useState(false);
  const [statusValue, setStatusValue] = React.useState(
    props.data.issueStatusCode
  );
  const { issueStatusList, data, userDepartmentCodes } = props;

  const handleStatusChange = (e) => {
    setUpdatingStatus(true);
    const value = e.target.value;

    dataProvider(UPDATE_ITEM, `requisitions/${props.reqID}/issues/${data.id}`, {
      data: { issueStatusCode: value },
    }).then(() => {
      setStatusValue(value);
      setUpdatingStatus(false);
    });
  };

  const handleClose = () => {
    setUpdatingStatus(true);
    dataProvider(UPDATE_ITEM, `requisitions/${props.reqID}/issues/${data.id}`, {
      data: { issueStatusCode: "CLOSED" },
    }).then(() => {
      setStatusValue("CLOSED");
      setUpdatingStatus(false);
    });
  };

  let selectStatusOptions = issueStatusList.map((item) => ({
    value: item.issueStatusCode,
    label: item.issueStatusDesc.toUpperCase(),
  }));

  const isDisabled = statusValue === "CLOSED";

  if (isDisabled) {
    selectStatusOptions = [{ value: "CLOSED", label: "CLOSED" }];
  }

  const reporterDeptCodeMatchedWithUserDepts = find(userDepartmentCodes, [
    "deptCode",
    data.reporterDeptCode,
  ]);

  return (
    <div className={styles.root}>
      {isUpdatingStatus && (
        <div className={styles.loadingProcess}>
          <CircularProgress />
        </div>
      )}

      <div className={styles.controls}>
        <div className={styles.selectWrap}>
          <Can
            notAllowedRoles={[
              userRoles.sales,
            ]}
          >
            <Select
              disabled={isDisabled}
              input={{
                customOptionType: true,
                options: selectStatusOptions,
              }}
              value={statusValue}
              handleInputs={handleStatusChange}
            />
          </Can>
        </div>
        <div className={styles.closeButtonWrap}>
          {reporterDeptCodeMatchedWithUserDepts && statusValue !== "CLOSED" && (
            <ButtonBase
              onClick={handleClose}
              className="button formButton formButton__mainContained"
            >
              Close
            </ButtonBase>
          )}
        </div>
      </div>

      <div className={styles.itemHeader}>
        <ul className={clsx(styles.list, styles.listPreview)}>
          <li>
            <strong>Resolver Department:</strong> {data.resolverDeptName}
          </li>
          <li>
            <strong>Report Date:</strong>{" "}
            {formatServerDateTimeToLocal(data.issueStatusDate, "dateTime")}
          </li>
          <li>
            <strong>Updated By:</strong> {data.issueUserName}
          </li>
        </ul>

        <div className={styles.description}>{data.issueDesc}</div>

        <div className={styles.note}>
          <strong>Notes:</strong> {data.issueNote}
        </div>

        <ul className={styles.list}>
          <li>
            <strong>Reported By Department:</strong> {data.reporterDeptName}
          </li>
          <li>
            <strong>Closed By:</strong> {data.issueCloseUserName}
          </li>
          <li>
            <strong>Close Date:</strong>{" "}
            {formatServerDateTimeToLocal(data.issueCloseDate, "dateTime")}
          </li>
        </ul>

        <WorkLogList reqID={props.reqID} issueID={data.id} />
      </div>
    </div>
  );
};

export default IssuesListItemContainer;
