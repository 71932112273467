import React, { useState } from "react";
import styles from "./TestsContainer.module.scss";
import ButtonBase from "@material-ui/core/ButtonBase";
import { compose } from "redux";
import SmartInput from "components/ui_components/smart_input";
import { testsTabConfig } from "config/resources/requisitionsResource";
import dataProvider, { CREATE } from "providers/dataProvider";
import withForm from "withForm";
import { useNotificationCenter } from "NotificationCenter";

const { inputsByHash, inputsById } = testsTabConfig;
const withFormConfig = { inputsByHash, inputsById };

const SidebarAddTestForm = (props) => {
  const [formError, setFormError] = useState(false);
  const elRefs = React.useRef([]);

  const notificationCenter = useNotificationCenter();

  const {
    clearInputs,
    reqID,
    getSubmitData,
    inputsByHash,
    inputsById,
    reloadTableCallBack,
  } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = getSubmitData();

    if (isFormValid(data)) {
      resetErrors();
      const resource = `requisitions/${reqID}/req_tests`;

      dataProvider(CREATE, resource, { data: data })
        .then((response) => {
          reloadTableCallBack();
          refreshInputsData();
          clearInputs();
          notificationCenter.show(response.message, "info");
        })
        .catch((error) => {
          notificationCenter.show(error.message, "warning");
        });
    } else {
      setFormError(true);
    }
  };

  props.forwardRef(() => refreshInputsData());

  const refreshInputsData = () => {
    elRefs.current.forEach((item, index) => {
      item.current.loadData();
    });
  };

  const handleInputs = (e) => {
    if (formError && isFormValid(getSubmitData())) {
      resetErrors();
    }
    props.handleInputs(e);
  };

  const resetErrors = () => {
    setFormError(false);
  };

  const isFormValid = (data) => {
    return data.panelIds.length > 0 || data.testIds.length > 0;
  };
  if (elRefs.current.length !== inputsById.length) {
    elRefs.current = Array(inputsById.length)
      .fill()
      .map((_, i) => elRefs.current[i] || React.createRef());
  }

  return (
    <div className={styles.sidebar}>
      <form>
        <div className={styles.formTitle}>Add Tests</div>

        <div className={styles.form}>
          {inputsById.map((inputId, index) => {
            const input = inputsByHash[inputId];

            return (
              <div key={index} className={styles.inputWrap}>
                <SmartInput
                  ref={elRefs.current[index]}
                  inputId={inputId}
                  input={input}
                  handleInputs={handleInputs}
                  refreshInputsData={refreshInputsData}
                  options={{ reqID: reqID }}
                />
              </div>
            );
          })}

          {formError ? (
            <div className={styles.formErrorMessage}>Check any test</div>
          ) : null}
        </div>
        <div className={styles.formControls}>
          <ButtonBase
            onClick={handleSubmit}
            type="submit"
            className={`${styles.submitButton} button formButton formButton__mainContained`}
          >
            Add Test to Requisition
          </ButtonBase>
          </div>
        </form>
    </div>
  );
};

export default compose(withForm(withFormConfig))(SidebarAddTestForm);