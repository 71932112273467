import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Button from '@material-ui/core/Button'
import NotificationCenterContext from './NotificationCenterContext'
import styled from 'styled-components';
import theme from 'config/theme';

const StyledSnackbarContent = styled(({ type, ...rest }) => (
  <SnackbarContent
    {...rest}
  />))`
  &&{
     background: ${props => props.type === 'error' && theme.palette.error.main}
  }
`;

const NotificationCenterProvider = (props) => {
  const { children } = props;

  const [message, setMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('info');
  const [action, setAction] = useState(null);
  const [handleAction, setHandleAction] = useState(null);
  const [actionDownloadLink, setActionDownloadLink] = useState(null);

  const show = (message,
                type,
                action,
                handleAction,
                actionDownloadLink,
                ) => {
    const renderMessage = typeof message === 'string' ? message : 'Unknown error';

    setOpen(true);
    setType(type);
    setMessage(renderMessage);
    setAction(action);
    setHandleAction(() => () => {
      handleAction()
    });
    setActionDownloadLink(actionDownloadLink);
  }

  const showDownloadNotification = (downloadLink) => {
    setOpen(true);
    setType('info');
    setAction('Download');
    setMessage('If download failed, please click here');
    setActionDownloadLink(downloadLink);
  }

  const handleActionClick = () => {
    handleAction();
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
    setHandleAction(null);
  }
  return (
    <React.Fragment>
      <NotificationCenterContext.Provider
        value={{
          show: show,
          showDownloadNotification: showDownloadNotification,
        }}
      >
        {children}
      </NotificationCenterContext.Provider>
      <Snackbar
        open={open}
        autoHideDuration={actionDownloadLink ? 10000 : 5000}
        onClose={handleClose}
      >
        <StyledSnackbarContent
          type={type}
          message={message || ''}
          action={action != null && (
            <Button
              color="secondary"
              size="small"
              download
              href={actionDownloadLink && actionDownloadLink}
              target={actionDownloadLink && '_blank'}
              onClick={handleAction && handleActionClick}
            >
              {action}
            </Button>
          )}
        />
      </Snackbar>
    </React.Fragment>
  )

}

export default NotificationCenterProvider;

NotificationCenterProvider.propTypes = {
  children: PropTypes.node,
}