export default  [
    {
        id: 'salesFirstName',
        label: 'First Name'
    },
    {
        id: 'salesLastName',
        label: 'Last Name'
    },
    {
        id: 'salesEmail',
        label: 'Email'
    },
    {
        id: 'salesTitle',
        label: 'Profession'
    },
    {
        id: 'ReportTo',
        label: 'Manage of Person'
    },
    {
        id: 'salesCompanyName',
        label: 'Sales Company Name'
    }
]