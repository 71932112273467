import React from 'react'
import styles from './SummaryReports.module.scss';
import ReportsTable from 'components/ui_components/table/ReportsTable/ReportsTable.js';

const SummaryReports = () => {

  return (
    <div className={styles.root}>
      <ReportsTable
        listResource='summary_reports'
        downloadResource='summary_reports/download'
      />
    </div>
  );
}


export default SummaryReports;