import React, { useState } from "react";
import withForm from "withForm";
import dataProvider, { UPDATE } from "providers/dataProvider";
import Can from "Can";
import { userGroups } from "config/userRoles";
import { withNotificationCenter } from "NotificationCenter";
import { compose } from "redux";
import {
  WidgetControlButton,
  ButtonGroup,
} from "components/ui_components/buttons";
import WidgetType1 from "components/ui_components/Widgets/WidgetType1/WidgetType1";
import { BaseWidgetViewList } from "components/ui_components/Widgets/WidgetViewLists";
import BaseInputList from "components/ui_components/BaseInputList/BaseInputList";

const updatePatientExtraFields = [
  "patientLastName",
  "patientFirstName",
  "patientMiddleName",
  "patientGenderCode",
  "patientRaceCode",
  "patientEthnicityCode",
];

const PatientInformationContainer = (props) => {
  const [isEditMode, setEditMode] = useState(false);
  const {
    notificationCenter,
    isFormValid,
    resetErrors,
    reqID,
    getSubmitData,
    handlePatientUpdate,
    data,
  } = props;
  const { inputsByHash, inputsById, handleInputs, viewConfig } = props;
  
  const handleSubmit = async () => {
    resetErrors();

    if (isFormValid()) {
      const submitData = getSubmitData();

      const updatePatientSubmitData = {
        patientSsn: submitData.patientSsn,
        patientDob: `${data.patientDobMonth}/${data.patientDobDate}/${data.patientDobYear}`,
      };
      
      updatePatientExtraFields.forEach(id => {
        if (data.hasOwnProperty(id)) {
          if (data[id] !== null && data[id] !== '') {
            updatePatientSubmitData[id] = data[id];
          } 
        }
      })

      const updatePatientHistory = dataProvider(
        UPDATE,
        `requisitions/${reqID}/patient_hists/${data.patientHistId}`,
        { data: submitData }
      );

      const updatePatient = dataProvider(
        UPDATE,
        `requisitions/${reqID}/patients/${data.patientId}`,
        { data: updatePatientSubmitData }
      );

      Promise.all([updatePatient, updatePatientHistory])
        .then((data) => {
          let fullMessage = '';
          data.forEach(({ message }) => {
            fullMessage += ` ${message}`;
          })
          notificationCenter.show(fullMessage, "info");
          setEditMode(false);
          handlePatientUpdate();
        })
        .catch((error) => {
          notificationCenter.show(error.message, "error");
          setEditMode(false);
          handlePatientUpdate();
        });
    }
  };

  const controls = (
    <Can allowedRoles={userGroups.superAdmins}>
      <ButtonGroup>
        {isEditMode && (
          <WidgetControlButton
            variant="outlined"
            onClick={setEditMode.bind(this, false)}
          >
            Cancel
          </WidgetControlButton>
        )}

        <WidgetControlButton
          onClick={isEditMode ? handleSubmit : setEditMode.bind(this, true)}
        >
          {isEditMode ? "Save" : "Edit"}
        </WidgetControlButton>
      </ButtonGroup>
    </Can>
  );

  const content = isEditMode ? (
    <BaseInputList
      inputsByHash={inputsByHash}
      inputsById={inputsById}
      handleInputs={handleInputs}
    />
  ) : (
    <BaseWidgetViewList
      ids={viewConfig.inputsById}
      inputsByHash={viewConfig.inputsByHash}
      data={props.data}
      showIcons={false}
    />
  );

  return (
    <WidgetType1
      title={"Patient Information"}
      content={content}
      headerRightContent={controls}
    />
  );
};

export default compose(
  withForm(),
  withNotificationCenter()
)(PatientInformationContainer);
