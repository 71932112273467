import dataProvider, {
  CREATE,
  UPLOAD_FILE,
  UPLOAD_FILE_S3,
} from "providers/dataProvider";

export default function ({ initResource, finishResource, dropZoneFiles }) {
  return new Promise(function (resolve, reject) {
    let downloadFile = {};
    let fileName = "";

    dropZoneFiles.forEach((file, index) => {
      if (index > 0) return;
      fileName = file.name;
      downloadFile = file;
    });

    const gdbRequest = { fileName: fileName };

    dataProvider(UPLOAD_FILE, initResource, {
      data: gdbRequest,
    })
      .then((gdbResponse) => {
        const s3Request = { ...gdbResponse.data.fields };
        const newFileName = gdbResponse.data.newFileName;

        dataProvider(UPLOAD_FILE_S3, gdbResponse.data.url, {
          data: {
            ...s3Request,
            downloadFile,
          },
        })
          .then((s3Response) => {
            if (s3Response.status === 204) {
              dataProvider(CREATE, finishResource, {
                data: {
                  originalFileName: fileName,
                  fileName: newFileName,
                },
              })
                .then((data) => {
                  resolve(data);
                })
                .catch((error) => reject(error));
            }
          })
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
}
