import { useState } from "react";
import dataProvider from "providers/dataProvider";
import useDeepEffect from "hooks/useDeepEffect.js";
import { useAuthCenter } from "AuthCenter";

const useLoadData = (type, resource, params = {}) => {
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token } = useAuthCenter();

  useDeepEffect(() => {
    setDataLoading(true);
    dataProvider(type, resource, params)
      .then(({ data, total }) => {
        setData(data);
        setDataLoading(false);
        setDataLoaded(true);
      })
      .catch((error) => {
        setError(error);
      });
  }, [type, resource, params, token]);

  return {
    data,
    dataLoading,
    dataLoaded,
    error,
  };
};

export default useLoadData;
