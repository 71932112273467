import React from 'react'
import Modal from '@material-ui/core/Modal';
import styles from './AddClientsModal.module.scss';
import { withNotificationCenter } from 'NotificationCenter'
import { compose } from 'redux'
import ListingMode from './ListingMode.js';

const AddClientsModal = (props) => {
  const { physId, handleAddNewAssociatedClients, handleCloseModal } = props;
  const { modalOpen, selected } = props;

  return (
    <Modal open={modalOpen} onClose={handleCloseModal}>
      <div className={`${styles.root} modalContainer`}>
        <div className={styles.modalHeader}>
          <h2 className="ttl ttl-2">
            Add Client
          </h2>
        </div>

        <ListingMode
          physId={physId}
          handleAddNewAssociatedClients={handleAddNewAssociatedClients}
          selected={selected}
          handleCloseModal={handleCloseModal}
        />
      </div>
    </Modal>
  );
}

export default compose(
  withNotificationCenter()
)(AddClientsModal);