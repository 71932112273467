import React from "react";
import { concatWithCommaSeparation } from "utils/textFormat";
import { formatServerDateTimeToTimeZoneWithAbbr } from "utils/dateTimeFormat.js";
import { yesNoAccessorFull } from "utils/accessors";

import {
  UserIcon,
  MedicUserIcon,
  SalesIcon,
  BagIcon,
  ListIcon,
  BadgeIcon,
  FastingIcon,
  CalendarIcon,
  ClockIcon,
  GlassTubeIcon,
  HouseIcon,
  GenderIcon,
  CigaretteIcon,
  GlassTube2Icon,
  PulseIcon,
  LightingIcon,
  DrugsIcon,
  HeadFlashIcon,
  MedicineCaseIcon,
  PressureToolIcon,
  WeightIcon,
  RulerIcon,
  EmailIcon,
  PhoneIcon,
} from "icons";

export default {
  physician: {
    icon: <MedicUserIcon />,
    label: "Physician",
    accessor: (d) => `${d.physFirstName}, ${d.physLastName}`,
    linkAccessor: (d) => `/physicians/${d.physId}/show`,
  },
  colaName: {
    icon: <SalesIcon />,
    label: "Collaborator",
    linkAccessor: (d) => `/collaborators/${d.colaId}/show`,
  },
  clientName: {
    icon: <BagIcon />,
    label: "Client",
    accessor: (d) => `${d.legacyClientId} - ${d.clientName}`,
    linkAccessor: (d) => `/clients/${d.clientId}/show`,
  },
  eventName: {
    icon: <ListIcon />,
    label: "Client Events",
  },
  extOrderId: {
    icon: <BadgeIcon />,
    label: "External Order Id",
  },
  labName: {
    icon: <BadgeIcon />,
    label: "Lab",
  },
  extAccessionId: {
    icon: <BadgeIcon />,
    label: "External Acc#",
  },
  isFasting: {
    icon: <FastingIcon />,
    label: "Fasting",
    accessor: (d) => `${d.isFasting === 1 ? "Yes" : "No"}`,
  },
  dateCollected: {
    icon: <CalendarIcon />,
    label: "Date Collected",
    accessor: (d) =>
      formatServerDateTimeToTimeZoneWithAbbr(
        d.dateCollected,
        "date",
        d.collectedTz,
        d.collectedTzAbbr
      ),
  },
  timeCollected: {
    icon: <ClockIcon />,
    label: "Time Collected",
    accessor: (d) =>
      formatServerDateTimeToTimeZoneWithAbbr(
        d.dateCollected,
        "time12",
        d.collectedTz,
        d.collectedTzAbbr
      ),
  },
  timeCentrifuged: {
    icon: <ClockIcon />,
    label: "Time Centrifuged",
    accessor: (d) =>
      formatServerDateTimeToTimeZoneWithAbbr(
        d.timeCentrifuged,
        "time12",
        d.collectedTz,
        d.collectedTzAbbr
      ),
  },
  collectDesc: {
    icon: <GlassTubeIcon />,
    label: "Method of Collection",
  },
  collectedBy: {
    icon: <SalesIcon />,
    label: "Collected By",
  },
  copyToClient: {
    icon: <SalesIcon />,
    label: "Copy to Client",
    accessor: (d) => `${d.copyToClientName}`,
  },
  copyToPhysician: {
    icon: <SalesIcon />,
    label: "Copy to Physician",
    accessor: (d) =>
      concatWithCommaSeparation([d.copyToPhysFirstName, d.copyToPhysLastName]),
  },
  name: {
    icon: <UserIcon />,
    label: "Name",
    accessor: (d) => `${d.patientFirstName}, ${d.patientLastName}`,
  },

  address: {
    icon: <HouseIcon />,
    label: "Address",
    accessor: (d) =>
      `${d.patientAddr1 ? d.patientAddr1 : ""}${
        d.patientAddr2 ? ", " + d.patientAddr2 : ""
      }${d.patientCity ? ", " + d.patientCity : ""}${
        d.patientState ? ", " + d.patientState : ""
      }${d.patientZip ? ", " + d.patientZip : ""}${
        d.patientCountry ? ", " + d.patientCountry : ""
      }`,
  },

  patientEmail: {
    icon: <EmailIcon />,
    label: "Email",
    tooltipValue: true,
  },
  patientWorkPhone: {
    icon: <PhoneIcon />,
    label: "Work Phone",
  },
  patientHomePhone: {
    icon: <PhoneIcon />,
    label: "Home Phone",
  },

  patientGenderDesc: {
    icon: <GenderIcon />,
    label: "Sex",
  },
  patientRaceDesc: {
    icon: <GenderIcon />,
    label: "Race",
  },
  patientEthnicityDesc: {
    icon: <GenderIcon />,
    label: "Ethnicity",
  },
  patientSsn: {
    icon: <GenderIcon />,
    label: "Patient SSN",
  },
  patientDob: {
    icon: <CalendarIcon />,
    label: "Date of Birth",
  },
  weight: {
    icon: <WeightIcon />,
    label: "Weight",
    accessor: (d) =>
      `${d.weight !== null ? d.weight : ""} ${
        d.weightUom !== null ? d.weightUom : ""
      }`,
  },
  height: {
    icon: <RulerIcon />,
    label: "Height",
    accessor: (d) => {
      let value = "";

      if (d.heightUom === "CM") {
        value = `${d.height} ${d.heightUom}`;
      } else if (d.heightUom === "INCH") {
        const inchesNumValue = d.height % 12;
        const inchesValue = `${inchesNumValue} Inches`;

        value = `${Math.floor(d.height / 12)} Feet ${
          inchesNumValue !== 0 ? inchesValue : ""
        }`;
      }
      return value;
    },
  },
  isSmoker: {
    icon: <CigaretteIcon />,
    label: "Smoker",
    accessor: (d) => `${d.isSmoker === 1 ? "Yes" : "No"}`,
  },
  isDiabetic: {
    icon: <GlassTube2Icon />,
    label: "Diabetic",
    accessor: (d) => `${d.isDiabetic === 1 ? "Yes" : "No"}`,
  },
  isFamilyHistory: {
    icon: <PulseIcon />,
    label: "Family HX of MI",
    accessor: (d) => {
      return `${d.isFamilyHistory === 1 ? "Yes" : "No"}`;
    },
  },
  isPatientHistory: {
    icon: <PulseIcon />,
    label: "Patient HX of MI",
    accessor: (d) => `${d.isPatientHistory === 1 ? "Yes" : "No"}`,
  },
  isStress: {
    icon: <LightingIcon />,
    label: "Stress",
    accessor: (d) => `${d.isStress === 1 ? "Yes" : "No"}`,
  },
  isLowHdl: {
    icon: <DrugsIcon />,
    label: "Diabetes Medication",
    accessor: (d) => `${d.isLowHdl === 1 ? "Yes" : "No"}`,
  },
  isLipidMed: {
    icon: <DrugsIcon />,
    label: "Lipid Lowering Medication",
    accessor: (d) => `${d.isLipidMed === 1 ? "Yes" : "No"}`,
  },
  isHypertension: {
    icon: <HeadFlashIcon />,
    label: "Hypertension",
    accessor: (d) => `${d.isHypertension === 1 ? "Yes" : "No"}`,
  },
  isBloodMed: {
    icon: <DrugsIcon />,
    label: "Blood Pressure Medication",
    accessor: (d) => `${d.isBloodMed === 1 ? "Yes" : "No"}`,
  },
  isMedNecessity: {
    icon: <MedicineCaseIcon />,
    label: "Medical Necessity",
    accessor: (d) => `${d.isMedNecessity === 1 ? "Yes" : "No"}`,
  },
  sbp: {
    icon: <PressureToolIcon />,
    label: "SBP",
  },
  dbp: {
    icon: <PressureToolIcon />,
    label: "DBP",
  },
  isCovidVac: {
    icon: <PressureToolIcon />,
    label: "Covid Vaccinated",
    accessor: (d) => `${d.isCovidVac === 1 ? "Yes" : "No"}`,
  },
  famHistT2DDesc: {
    icon: <PressureToolIcon />,
    label: "Family history of type 2 diabetes",
  },
  isSelfT2DGest: {
    icon: <PressureToolIcon />,
    label: "Personal HX of T2D/Gest. Diabetes",
    accessor: (d) => yesNoAccessorFull(d.isSelfT2DGest),
  },
  isObesity: {
    icon: <PressureToolIcon />,
    label: "Obesity",
    accessor: (d) => `${d.isObesity === 1 ? "Yes" : "No"}`,
  },
};
