import React, { useCallback } from 'react'
import styles from './DropZone.module.scss';
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar'
import { useDropzone } from 'react-dropzone'

const DropZone = (props) => {
  const { isDropZoneUploading, handleDropFiles, dropZoneFiles, dropZoneError } = props;

  const onDrop = useCallback(acceptedFiles => {
    handleDropFiles(acceptedFiles);
  }, [handleDropFiles])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div className={`${styles.root} modalContainer`}>

      {isDropZoneUploading && <div className={styles.progressBar}><ProgressBar/></div>}
      <h2 className="ttl ttl-2">

      </h2>
      <div>
        <div {...getRootProps({ className: styles.dropzone })}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag 'n' drop some files here, or click to select files</p>
          }
        </div>

        {dropZoneError && <div className={styles.errorMessage}>
          Please Upload File
        </div>}
        {dropZoneFiles.length > 0 && <aside>
          <h4>Files</h4>
          <ul>
            {dropZoneFiles.map(file => (
              <li key={file.name}>
                {file.name} - {file.size} bytes
              </li>
            ))}
          </ul>
        </aside>}
      </div>
    </div>
  )
}

export default DropZone;