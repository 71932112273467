import styles from './CollapseControls.module.scss';
import React from 'react';

export default function CollapseControls (props) {
  return (
    <div className={styles.root}>
      <span className={styles.btn} onClick={props.handleShowAllCollapse}>
        Expand
      </span>
      <span className={styles.btn} onClick={props.handleHideAllCollapse}>
        Collapse
      </span>
    </div>
  )
}