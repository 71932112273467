import React, { PureComponent } from "react";
import Select from "react-select";
import dataProvider, { GET_LIST } from "providers/dataProvider";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { ArrowDropDown } from "@material-ui/icons";
import withAutoFill from "./withAutoFill";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import clsx from "clsx";
import style from "./Autocomplete.module.scss";
import { find, isArray, get } from "lodash";
import { withAuthCenter } from "AuthCenter";
import { compose } from "redux";
import axios from "axios";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 20,
  }),
  clearIndicator: () => ({
    ">svg": {
      width: "15px",
      height: "15px",
      margin: "3px 0 0",
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16,
    color: "red",
  },
  indicatorSeparator: {
    display: "none",
  },
  chip: {
    margin: "5px",
  },
});

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      error={TextFieldProps.input.error}
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

const DropdownIndicator = (props) => {
  return <ArrowDropDown />;
};

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

const components = {
  Control,
  DropdownIndicator,
  MultiValue,
};

class Autocomplete extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      options: [],
      dataLoading: false,
      inputValue: "",
      dataLoaded: false,
      isDisabled: false,
    };

    this.loadDataAfterMenuOpen =
      get(this.props.input, "autocompleteOptions.loadDataAfterOpenMenu") ||
      false;
    this.forceLoadingDataWhenValueAvailable =
      get(
        this.props.input,
        "autocompleteOptions.forceLoadingDataWhenValueAvailable"
      ) || true;
    this.isClearable =
      get(this.props.input, "autocompleteOptions.isClearable") || false;

    this.CancelToken = axios.CancelToken;
    this.source = this.CancelToken.source();
  }

  componentDidMount() {
    if (this.props.input.value && this.forceLoadingDataWhenValueAvailable) {
      this.loadData();
    } else {
      if (!this.loadDataAfterMenuOpen) {
        this.loadData();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.input.value === "" &&
      this.props.input.value !== "" &&
      this.forceLoadingDataWhenValueAvailable &&
      this.state.options.length === 0
    ) {
      this.loadData();
    }

    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }

    if (this.props.input.hasOwnProperty('relativeInputsValueAccessor') && this.props.input.hasOwnProperty('relativeInputsData')) {
      const newValue = this.props.input.relativeInputsValueAccessor(this.props.input.relativeInputsData);
      if (newValue) {
        const selectedOption = this.getSelectedOption(newValue);
        if (selectedOption && !this.state.isDisabled) {
          this.setState({ isDisabled: true });
          this.handleChange(selectedOption);
        }
      }
      if (!newValue && this.state.isDisabled) {
        this.setState({ isDisabled: false });
      }
    }
    if (
      JSON.stringify(this.props.input.relativeInputsData) !==
      JSON.stringify(prevProps.input.relativeInputsData)
    ) {
      if (!this.loadDataAfterMenuOpen) {
        this.loadData();
      }
    }
    if (prevProps.authCenter.token !== this.props.authCenter.token) {
      if (!this.loadDataAfterMenuOpen) {
        this.loadData();
      }
    }
  }

  componentWillUnmount() {
    this.cancelLoadData();
  }

  getFilter = (input) => {
    try {
      return (
        input.filter ||
        (input.relativeInputsFilterAccessor &&
          input.relativeInputsFilterAccessor(input.relativeInputsData)) ||
        {}
      );
    } catch {
      return false;
    }
  };

  getResource = (input, options) => {
    try {
      return (
        input.resource ||
        (input.resourceAccessor && input.resourceAccessor(options)) ||
        input.relativeInputResourceAccessor(input.relativeInputsData, options)
      );
    } catch {
      return false;
    }
  };

  cancelLoadData() {
    this.source.cancel();
    this.source = this.CancelToken.source();
  }

  loadData() {
    const { input, options } = this.props;
    this.cancelLoadData();
    this.setState({
      options: [],
    });

    const filter = this.getFilter(input);
    const resource = this.getResource(input, options);

    if (!filter) return;
    if (!resource) return;

    this.setState({
      dataLoading: true,
    });

    dataProvider(
      GET_LIST,
      resource,
      {
        filter: filter,
        pagination: {
          perPage: -1,
        },
        sort: input.sort,
      },
      {
        cancelToken: this.source.token,
      }
    )
      .then(({ data, total }) => {
        let options = data.map(input.accessor);
        this.setState(
          {
            dataLoded: true,
            dataLoading: false,
            options,
          },
          this.setInitValue(data)
        );
      })
      .catch((e) => {});
  }

  setInitValue = (rows) => {
    const { input } = this.props;
    if (!input.hasOwnProperty("setInitValueFromData")) return;

    const defaultSetInitValueFromData = {
      inputKey: null,
      outputKey: null,
      value: null,
      forceUpdate: false,
    };

    const setInitValueFromData = {
      ...defaultSetInitValueFromData,
      ...input.setInitValueFromData,
    };

    if (input.value && !setInitValueFromData.forceUpdate) return;

    const row = rows.find(
      (row) => row[setInitValueFromData.inputKey] === setInitValueFromData.value
    );
    if (row) {
      const response = row[setInitValueFromData.outputKey];
      this.handleChange({ value: response });
    }
  };

  handleChangeMulti = (selectedOption) => {
    if (!selectedOption) {
      this.props.handleInputs({
        target: { value: [], name: this.props.inputId },
      });
      return;
    }
    const updatedSelectedOption = selectedOption.map((option) => {
      if (option.hasOwnProperty("value")) {
        return option.value;
      } else {
        return option;
      }
    });

    let e = {
      target: { value: updatedSelectedOption, name: this.props.inputId },
    };
    this.props.handleInputs(e);
  };

  handleChange = (selectedOption, options = {}) => {
    const { action = "select-option" } = options;

    if (action === "clear") {
      let e = { target: { value: null, name: this.props.inputId } };
      this.props.handleInputs(e);
    } else if (action === "select-option") {
      let extraSelectedOptionData = null;

      if (selectedOption.relatedValue) {
        this.props.input.relatedValue = selectedOption.relatedValue;
      }
      extraSelectedOptionData = selectedOption.extraSelectedOptionData;
      let e = {
        target: { value: selectedOption.value, name: this.props.inputId },
      };
      this.props.handleInputs(e, extraSelectedOptionData, selectedOption.label);
    }
  };

  onInputChange = (inputValue, { action }) => {
    if (action !== "set-value") {
      this.setState({ inputValue });

      return inputValue;
    }

    return this.state.inputValue;
  };

  onOpen = () => {
    if (!this.state.dataLoded) {
      this.loadData();
    }
  };

  getSelectedOption = (value) => {
    let selectedOption = null;

    const { input } = this.props;
    const { options } = this.state;

    if (
      input.subType === "multi" &&
      value &&
      isArray(value) > 0 &&
      options.length > 0
    ) {
      selectedOption = value.map((option) => {
        if (option.hasOwnProperty("value")) {
          return option;
        } else {
          return {
            value: option,
            label: find(options, ["value", option]).label,
          };
        }
      });
    } else if (input.subType !== "multi" && value) {
      selectedOption = options.find((d) => d.value === value);
    }

    return selectedOption;
  };

  render() {
    const { classes, input, inputId, value } = this.props;
    const { options, dataLoading, isDisabled } = this.state;
    const isDataLoaded = true;

    const selectedOption = this.getSelectedOption(value);

    return (
      <div
        className={clsx(style.root, this.props.style.textField, {
          [style.rootDisabled]: !isDataLoaded,
        })}
      >
        <Select
          {...input.selectProps}
          isDisabled={!isDataLoaded || input.disabled || isDisabled}
          isClearable={this.isClearable}
          value={selectedOption}
          menuPortalTarget={document.body}
          onChange={
            input.subType === "multi"
              ? this.handleChangeMulti
              : this.handleChange
          }
          onFocus={this.onOpen}
          isLoading={dataLoading}
          name={inputId}
          TextFieldProps={{
            required: input.hasOwnProperty("yup"),
            input: input,
            name: inputId,
            label: input.error ? input.errorMessage : input.label,
            InputLabelProps: {
              htmlFor: "react-select-single",
              shrink: true,
            },
          }}
          closeMenuOnSelect={!(input.subType === "multi")}
          components={components}
          options={options}
          classes={classes}
          isMulti={input.subType === "multi"}
          styles={customStyles}
          onInputChange={this.onInputChange}
        />
      </div>
    );
  }
}

export default compose(
  withAutoFill,
  withStyles(styles),
  withAuthCenter()
)(Autocomplete);
