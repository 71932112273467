import React from "react";
import MainHeader from "components/templateParts/MainHeader";
import styles from "./BatchCsvUpload.module.scss";
import {
  tableColConfig,
} from "config/resources/batchCsvUpload";
import SmartTable from "components/ui_components/table/SmartTable";
import UploadWidget from 'components/pages/batchCsvUpload/UploadWidget/UploadWidget.js';

let smartTableRef = {};

const BatchCsvUpload = () => {

  const onUploadOk = () => {
    smartTableRef.current.loadData();
  }

  return (
    <div>
      <MainHeader title="Batch CSV Upload" />

      <div className="mainContainer">
        <div className="mainContainer__inner">
          <div className={styles.root}>

            <UploadWidget onUploadOk={onUploadOk}/>
            <SmartTable
              onRef={(ref) => (smartTableRef.current = ref)}
              resource="bulk_csv_uploader"
              columns={tableColConfig}
              order="desc"
              orderBy={"loadDate"}
              collapsibleTable={true}
              showSelect={false}
              showDelete={false}
              showSelectAll={false}
              collapsibleTableContentType={["batchCsvUpload"]}
            />
          </div>
        </div>
      </div>      
    </div>
  );
};

export default BatchCsvUpload;
