import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import StyledCheckbox from 'components/ui_components/StyledCheckbox/StyledCheckbox';
import { makeStyles } from '@material-ui/core/styles';
import style from './Multiselect.module.scss';

const useStyles = makeStyles({
  paper: {
    maxWidth: '350px',
  },
  list: {
    maxHeight: '600px',
    '& li': {
      whiteSpace: 'normal',
      // maxWidth: '350px',
      // width: '100%',
    }
  }
});

const MultiselectComponent = (props) => {
  const { options, selectedOptions, inputId, onClick, onChange, renderValue, label, onMouseOver, onMouseLeave, disabled } = props;
  const classes = useStyles();


  return (<FormControl className={style.formControl}>
    <InputLabel htmlFor="age-simple">{label}</InputLabel>

    <Select
      autoWidth={false}
      multiple
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      disabled={disabled}
      value={selectedOptions}
      name={inputId}
      onClick={onClick}
      onChange={onChange}
      input={<Input id="select-multiple-checkbox"/>}
      renderValue={renderValue}
      MenuProps={{
        classes: classes
      }}
    >
      {
        options.map(option => {
          return (
            <MenuItem key={option.value} value={option.value}>
              <StyledCheckbox checked={selectedOptions.indexOf(option.value) > -1}/>
              <ListItemText primary={option.label}/>
            </MenuItem>
          )
        })
      }
    </Select>
  </FormControl>)
}


MultiselectComponent.defaultProps = {
  disabled: false,
}


export default MultiselectComponent;
