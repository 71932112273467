import helpers from "utils/helpers";

const tableColConfig = [
  {
    id: "testCode",
    label: "Test Code",
  },
  {
    id: "testDesc",
    label: "Test Description",
  },
  {
    id: "price",
    label: "Price",
  },
  {
    id: "currencyCode",
    label: "Currency",
  },
  {
    id: "priceComment",
    label: "Comment",
  },
];

const addFormConfig = {
  inputsById: ["price", "priceComment", "testId", "currencyCode"],
  inputsByHash: {
    price: {
      label: "Price",
      yup: helpers.getYupRequired("Price"),
      submitValueAccessor: (d) => parseFloat(d),
      inputWrapStyles: `width: 50%;`,
    },
    currencyCode: {
      label: "Currency",
      type: "autocomplete",
      resource: "lovs/CURRENCY",
      accessor: (d) => {
        return { value: d.currencyCode, label: d.currencyDesc };
      },
      setInitValueFromData: {
        inputKey: "currencyCode",
        outputKey: "currencyCode",
        value: "USD",
      },
      inputWrapStyles: `width: 50%;`,
    },
    priceComment: {
      label: "Price Commment",
      type: "multiline",
      inputWrapStyles: `width: 100%;`,
    },
    testId: {
      label: "Test Code",
      yup: helpers.getYupRequired("Test Code"),
      type: "autocomplete",
      resource: "default_tests",
      accessor: (d) => ({ value: d.id, label: d.testDesc }),
      submitValueAccessor: (d) => parseInt(d),
      inputWrapStyles: `width: 100%;`,
    },
  },
  inputsGroupsByHash: {
    group1: {
      ids: [["testId"], ["price", "currencyCode"], ["priceComment"]],
      inputStyles: `width: 33.333%;`,
    },
  },

  inputsGroupsById: ["group1"],
};

const editFormConfig = {
  inputsById: ["price", "priceComment", "currencyCode"],
  inputsGroupsByHash: {
    group1: {
      ids: [["price", "currencyCode"], ["priceComment"]],
      inputStyles: `width: 33.333%;`,
    },
  },

  inputsGroupsById: ["group1"],
};

export { tableColConfig, addFormConfig, editFormConfig };
