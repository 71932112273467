import helpers from "utils/helpers";
import {
  inputWeight,
  inputWeightUom,
  inputHeightFeet,
  inputHeightInches,
  inputHeightCm,
  inputHeightUom,
} from "config/inputs/heightWeight";
import {
  isCovidVac,
  famHistT2DCode,
  isSelfT2DGest,
} from "config/resources/requisitionsResource/createEditInputs";

export default {
  labId: {
    label: "Lab",
    type: "autocomplete",
    yup: helpers.getYupRequired("Lab"),
    accessor: (d) => {
      return { value: d.labId, label: d.labName };
    },
    relativeInputResourceAccessor: (d) => {
      return `clients/${d.clientId}/labs`;
    },
    setInitValueFromData: {
      inputKey: "labName",
      outputKey: "labId",
      value: "GD Biosciences - Irvine",
    },
  },
  collaborator: {
    label: "Collaborator",
    yup: helpers.getYupRequired("Collaborator"),
    type: "autocomplete",
    relativeInputs: ["clientId"],
    resetRelativeInputs: ["clientId", "orderingClientPhysId", "eventId"],
    resource: "collaborators",
    accessor: (d) => {
      return { value: d.id, label: d.colaName };
    },
    setInitValueFromData: {
      inputKey: "colaName",
      outputKey: "id",
      value: "Predictive Health Diagnostics Company",
    },
  },
  clientId: {
    label: "Client",
    yup: helpers.getYupRequired("Client"),
    type: "autocomplete",
    relativeInputs: ["orderingClientPhysId", "eventId", "labId"],
    resetRelativeInputs: ["orderingClientPhysId", "eventId", "labId"],
    resource: "clients",
    relativeInputsFilterAccessor: (d) => {
      return { colaId: d.collaborator };
    },
    accessor: (d) => {
      return {
        value: d.id,
        label: `${d.legacyClientId} - ${d.clientName}`,
        extraSelectedOptionData: { clientTz: d.clientTz },
      };
    },
  },
  orderingClientPhysId: {
    label: "Physician",
    yup: helpers.getYupRequired("Physician"),
    type: "autocomplete",
    relativeInputResourceAccessor: (d) => {
      return `clients/${d.clientId}/physicians`;
    },
    accessor: (d) => {
      return { value: d.id, label: `${d.physFirstName} ${d.physLastName}` };
    },
  },
  eventId: {
    label: "Client Event",
    type: "autocomplete",
    yup: helpers.getYupRequired("Client Event"),
    relativeInputResourceAccessor: (d) => {
      return `clients/${d.clientId}/events`;
    },
    accessor: (d) => {
      return { value: d.id, label: d.eventName };
    },
    setInitValueFromData: {
      inputKey: "eventName",
      outputKey: "id",
      value: "Standard Patient Draw",
    },
  },
  extOrderId: {
    label: "External Order Id",
  },

  patientDobMonth: {
    label: "Month",
    yup: helpers.getYupDateMonthRequired("Month"),
    submitValueAccessor: (d) => parseInt(d),
    excludeSubmit: true,
    inputWrapStyles: "max-width: 70px;",
  },

  patientDobDate: {
    label: "Day",
    yup: helpers.getYupDateDayRequired("Day"),
    submitValueAccessor: (d) => parseInt(d),
    excludeSubmit: true,
    inputWrapStyles: "max-width: 70px;",
  },

  patientDobYear: {
    label: "Year",
    yup: helpers.getYupDateYearRequired("Year"),
    submitValueAccessor: (d) => parseInt(d),
    excludeSubmit: true,
    inputWrapStyles: "max-width: 70px;",
  },

  patientFirstName: {
    label: "First Name",
    yup: helpers.getYupRequired("First Name"),
  },
  patientMiddleName: {
    label: "Middle Name",
  },
  patientLastName: {
    label: "Last Name",
    yup: helpers.getYupRequired("Last Name"),
  },
  patientGenderCode: {
    label: "Sex",
    yup: helpers.getYupRequired("Gender"),
    type: "select",
    options: [
      { value: "MALE", label: "Male", shortKeyCode: "m" },
      {
        value: "FEMALE",
        label: "Female",
        shortKeyCode: "f",
      },
    ],
  },
  patientRaceCode: {
    label: "Race",
    type: "autocomplete",
    resource: "lovs/RACE",
    accessor: (d) => ({ value: d.patientRaceCode, label: d.patientRaceDesc }),
    setInitValueFromData: {
      inputKey: "patientRaceCode",
      outputKey: "patientRaceCode",
      value: "NA",
    },
  },
  patientEthnicityCode: {
    label: "Ethnicity",
    type: "autocomplete",
    resource: "lovs/ETHNICITY",
    accessor: (d) => ({
      value: d.patientEthnicityCode,
      label: d.patientEthnicityDesc,
    }),
    setInitValueFromData: {
      inputKey: "patientEthnicityCode",
      outputKey: "patientEthnicityCode",
      value: "NA",
    },
  },
  patientSsn: {
    label: "Patient SSN",
  },

  dateCollectedMonth: {
    label: "Date Collected Month",
    yup: helpers.getYupDateMonthRequired("Collected Month"),
    excludeSubmit: true,
    inputWrapStyles: "max-width: 170px;",
  },
  dateCollectedDate: {
    label: "Date Collected Day",
    yup: helpers.getYupDateDayRequired("Collected Day"),
    excludeSubmit: true,
    inputWrapStyles: "max-width: 170px;",
  },
  dateCollectedYear: {
    label: "Date Collected Year",
    yup: helpers.getYupDateYearRequired("Collected Year"),
    excludeSubmit: true,
    inputWrapStyles: "max-width: 170px;",
  },
  timeCollectedMinutes: {
    label: "Minutes Collected",
    yup: helpers.getYupTimeMinutesRequired("Collected Minutes"),
    excludeSubmit: true,
    inputWrapStyles: "max-width: 140px;",
  },
  timeCollectedHours: {
    label: "Hours Collected",
    yup: helpers.getYupTimeHoursRequired("Collected Hours"),
    excludeSubmit: true,
    inputWrapStyles: "max-width: 140px;",
  },
  timeCollectedAmPm: {
    label: "AM/PM",
    yup: helpers.getYupRequired("Time of day"),
    type: "select",
    options: [
      { value: "am", label: "AM", shortKeyCode: "a" },
      { value: "pm", label: "PM", shortKeyCode: "p" },
    ],
    value: "am",
    excludeSubmit: true,
    inputWrapStyles: "max-width: 90px;",
  },
  collectCode: {
    label: "Method of Collection",
    type: "autocomplete",
    resource: "lovs/COLLECTIONMETHOD",
    accessor: (d) => {
      return { value: d.collectionMethodCode, label: d.collectionMethodDesc };
    },
    inputWrapStyles: "max-width: 160px;",
  },
  collectedBy: {
    label: "Collected By",
    inputWrapStyles: "max-width: 120px;",
  },
  sbp: {
    label: "SBP",
  },
  dbp: {
    label: "DBP",
  },
  isSmoker: {
    label: "Smoker",
    type: "radio",
    options: [
      { value: 1, label: "Yes" },
      { value: "0", label: "No" },
    ],
    value: "0",
  },
  isDiabetic: {
    label: "Diabetic",
    type: "radio",
    options: [
      { value: 1, label: "Yes" },
      { value: "0", label: "No" },
    ],
    value: "0",
  },
  isFamilyHistory: {
    label: "Family HX of MI",
    type: "radio",
    options: [
      { value: 1, label: "Yes" },
      { value: "0", label: "No" },
    ],
    value: "0",
  },
  isCovidVac: isCovidVac,
  famHistT2DCode: famHistT2DCode,
  isSelfT2DGest: isSelfT2DGest,
  weight: {
    ...inputWeight,
  },
  weightUom: {
    ...inputWeightUom,
    value: "LBS",
  },
  heightFeet: {
    ...inputHeightFeet,
  },
  heightInches: {
    ...inputHeightInches,
  },
  heightCm: {
    ...inputHeightCm,
  },
  heightUom: {
    ...inputHeightUom,
    value: "INCH",
  },
};
