import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const GenderIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M14.7,0l-3.3,0.4C10.9,0.5,10.7,1,11,1.3l0.7,0.7L9.5,4.4C7.7,3.3,5.3,3.5,3.8,5C2,6.8,2,9.7,3.8,11.5
	c1.8,1.8,4.7,1.8,6.4,0c1.5-1.5,1.7-3.9,0.6-5.6l2.3-2.3L14,4.2c0.3,0.3,0.8,0.1,0.9-0.3l0.4-3.3C15.3,0.2,15,0,14.7,0z M8.7,9.9
	c-0.9,0.9-2.4,0.9-3.4,0C4.4,9,4.4,7.5,5.3,6.6c0.9-0.9,2.4-0.9,3.4,0C9.6,7.5,9.6,9,8.7,9.9z M12.4,12.3c-1.5,1.5-1.7,3.9-0.6,5.6
	l-1,1L10,18.1c-0.2-0.2-0.5-0.2-0.6,0l-0.8,0.8c-0.2,0.2-0.2,0.5,0,0.6l0.8,0.8l-2.1,2.1c-0.2,0.2-0.2,0.5,0,0.6L8,23.9
	c0.2,0.2,0.5,0.2,0.6,0l2.1-2.1l0.8,0.8c0.2,0.2,0.5,0.2,0.6,0l0.8-0.8c0.2-0.2,0.2-0.5,0-0.6l-0.8-0.8l1-1c1.8,1.1,4.1,0.9,5.6-0.6
	c1.8-1.8,1.8-4.7,0-6.4C17.1,10.5,14.2,10.5,12.4,12.3z M17.3,17.2c-0.9,0.9-2.4,0.9-3.4,0c-0.9-0.9-0.9-2.4,0-3.4
	c0.9-0.9,2.4-0.9,3.4,0C18.2,14.7,18.2,16.3,17.3,17.2z"/>
    </SvgIcon>
);

export default GenderIcon;