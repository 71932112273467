import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const StatIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M20.6,0H3.4C2,0,0.9,1.1,0.9,2.5v17.2c0,1.4,1.1,2.5,2.5,2.5h17.2c1.4,0,2.5-1.1,2.5-2.5V2.5
	C23.1,1.1,22,0,20.6,0z M8.3,17.2H5.8V8.6h2.5V17.2z M13.2,17.2h-2.5V4.9h2.5V17.2z M18.2,17.2h-2.5v-4.9h2.5V17.2z"/>
    </SvgIcon>
);

export default StatIcon;