import React from "react";
import SmartTable from "components/ui_components/table/SmartTable";
import { formatServerDateTimeToLocal } from "utils/dateTimeFormat";

const tableColConfig = [
  {
    id: "docCreateDate",
    label: "Upload Time",
    accessor: (d) =>
      d.docCreateDate &&
      formatServerDateTimeToLocal(d.docCreateDate, "dateTime"),
  },
  {
    id: "docOriginalFileName",
    label: "Scanned Req",
  },
];

export default function ScanDocumentsSubTable({ reqId }) {
  return (
    <SmartTable
      resource={`requisitions/${reqId}/docs`}
      columns={tableColConfig}
      isSubTable={true}
      showSelect={false}
      showPagination={false}
    />
  );
}
