import React from "react";
import styles from "./PrintMultiPreviewLayout.module.scss";
import { concatClientCityStateZip } from "utils/textFormat";
import { getSalesRepColumnVal } from "config/resources/requisitionsResource/tableCol";

const PrintMultiPreviewLayoutComponent = (props) => {
  const { physList, clientData } = props;

  return (
    <div className={styles.root}>
      <div className={styles.colPhysList}>
        <ul>
          {physList.map((item) => {
            return (
              <li key={item.id}>
                [ ] {item.physLastName}, {item.physFirstName}, {item.physTitle}
              </li>
            );
          })}
        </ul>
      </div>
      <div className={styles.colClientInfo}>
        <ul>
          <li>
            {clientData.legacyClientId} {clientData.clientName}
          </li>
          <li>
            {clientData.clientAddr1} {clientData.clientAddr2}
          </li>
          <li>
            {concatClientCityStateZip(
              clientData.clientCity,
              clientData.clientState,
              clientData.clientZip
            )}
          </li>

          {clientData.clientCountry === "United States" ? null : 
            <li>{clientData.clientCountry}</li>
          }
          <li>Phone: {clientData.clientPhone}</li>
          {clientData.clientFaxEmail && (
            <li>Fax: {clientData.clientFaxEmail}</li>
          )}
          <li>{getSalesRepColumnVal(clientData.salesJson)}</li>
        </ul>
      </div>
    </div>
  );
};

export default PrintMultiPreviewLayoutComponent;
