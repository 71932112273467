import helpers from "utils/helpers";
import { formatServerDateTimeToLocal } from "utils/dateTimeFormat.js";

export default {
  reportsTableColConfig: {
    tableColById: [
      "reportOriginalFileName",
      "labQcStatusCode",
      "reviewDate",
      "latestTransmitMethodDesc",
      "latestDeliveredByName",
      "latestDeliveryDate",
    ],
    tableColByHash: {
      reportOriginalFileName: {
        label: "Report Name",
      },
      labQcStatusCode: {
        label: "Lab QC Result",
        edit: true,
        autoSave: {
          resourceAccessor: (d) => {
            return `requisitions/${d.reqID}/reports/${d.reportID}`;
          },
        },
        type: "labQcStatus",
      },
      reviewDate: {
        label: "QC Date",
        accessor: (d) => {
          let reviewDate = d.reviewDate;

          if (d.labQcStatusCode === "RELEASE") {
            reviewDate = d.releaseDate;
          } else if (d.labQcStatusCode === "REPEAT") {
            reviewDate = d.repeatDate;
          } else if (d.labQcStatusCode === "MD") {
            reviewDate = d.reviewDate;
          }
          return formatServerDateTimeToLocal(reviewDate, "dateTime");
        },
      },
      latestTransmitMethodDesc: {
        label: "Latest Delivery",
      },
      latestDeliveredByName: {
        label: "Delivered By",
      },
      latestDeliveryDate: {
        label: "Latest Delivery Date",
        accessor: (d) =>
          formatServerDateTimeToLocal(d.latestDeliveryDate, "dateTime"),
      },
    },
  },

  reportsHistoryTableColConfig: [
    {
      id: "reportActionDate",
      label: "Date and Time",
      accessor: (d) =>
        formatServerDateTimeToLocal(d.reportActionDate, "dateTime"),
    },
    {
      id: "reportOriginalFileName",
      label: "Report Name",
    },
    {
      id: "reportActionDesc",
      label: "Action",
    },
    {
      id: "reportActionByName",
      label: "User",
    },
  ],

  demographicWidgetConfig: {
    inputsByHash: {
      pass: {
        type: "checkbox",
        options: [
          {
            value: 1,
            label: "Pass",
          },
        ],
      },
    },

    inputsById: ["pass"],
  },
  deliveryModalConfig: {
    inputsByHash: {
      deliveryMethod: {
        type: "autocomplete",
        resource: "lovs/TRANSMITMETHOD",
        yup: helpers.getYupRequired("Delivery Method"),
        accessor: (d) => {
          return { value: d.transmitMethodCode, label: d.transmitMethodDesc };
        },
      },
    },

    inputsById: ["deliveryMethod"],
  },
};
