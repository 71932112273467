import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal';
import styles from './AddPhysicianModal.module.scss';
import CreateMode from 'components/pages/clients/ClientsShow/AddPhysicianModal/Mods/CreateMode.js';
import ListingMode from 'components/pages/clients/ClientsShow/AddPhysicianModal/Mods/ListingMode.js';
import TableHeaderAddButton from 'components/ui_components/buttons/TableHeaderAddButton/TableHeaderAddButton.js'

const AddPhysicianModal = (props) => {
  const [modalMode, setModalMode] = useState('listing');
  const { clientId, handleAddNewAssociatedPhysicians, handleCloseModal } = props;
  const { modalOpen, selected } = props;

  const handleChangeModalModel = () => {
    modalMode === 'listing' ? setModalMode('create') : setModalMode('listing')
  }

  return (
    <Modal open={modalOpen} onClose={handleCloseModal}>
      <div className={`${styles.root} modalContainer`}>
        <div className={styles.modalHeader}>
          <h2 className="ttl ttl-2">
            {modalMode === 'listing' ? 'Add Physician' : 'Create Physician'}
          </h2>
          <TableHeaderAddButton
          onClick={handleChangeModalModel}
          showIcon={modalMode === 'listing' }
          >
          {modalMode === 'listing' ? 'Create Physician' : 'Back to Listing'}
          </TableHeaderAddButton>
        </div>

        {modalMode === 'listing' && <ListingMode
          clientID={clientId}
          selected={selected}
          handleAddNewAssociatedPhysicians={handleAddNewAssociatedPhysicians}
          handleCloseModal={handleCloseModal}
        />}

        {modalMode === 'create' && <CreateMode
          handleCloseModal={handleCloseModal}
          setModalMode={setModalMode}
        />}
      </div>
    </Modal>
  );
}

export default AddPhysicianModal;