import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SidebarContainer from "components/Sidebar/SidebarContainer";
import Footer from 'components/Footer/Footer.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    position: "relative",
    overflow: "hidden",
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  contentWithSidebar: {
    display: "flex",
    flexGrow: 1,
    width: "calc(100% - 56px)",
    [theme.breakpoints.up("sm")]: {
      margin: "0 0 0 56px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    width: "100%",
  },
  contentInnerWrap: {
    padding: (props) => props.showFooter ? '0 0 20px' : 0,
  }
}));

const MainLayout = ({ children, showFooter }) => {
  const classes = useStyles({showFooter});
  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <main className={classes.contentWithSidebar}>
          <SidebarContainer className={classes.sidebar} />
          <div className={classes.content}>
            <div className={classes.contentInnerWrap}>
              {children}
            </div>
            {showFooter && <Footer />}
          </div>
        </main>
      </div>
    </div>
  );
};

MainLayout.defaultProps = {
  showFooter: false,
};

export default MainLayout;