import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SalesIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M12,9.5c2.6,0,4.7-2.1,4.7-4.7C16.7,2.1,14.6,0,12,0C9.4,0,7.3,2.1,7.3,4.7C7.3,7.3,9.4,9.5,12,9.5z M18.4,11.8
	C18.4,11.8,18.3,11.8,18.4,11.8c-0.6-0.2-2.5-0.4-2.5-0.4c-0.2-0.1-0.4,0-0.5,0.3l-2.8,7.8c-0.2,0.4-0.8,0.4-1,0l-2.8-7.8
	c-0.1-0.2-0.2-0.3-0.4-0.3c0,0-2,0.3-2.6,0.4c-1.8,0.4-2.9,2.2-2.9,4v6.5c0,1,0.8,1.8,1.8,1.8h14.9c1,0,1.8-0.8,1.8-1.8v-6.5
	C21.2,13.9,20.1,12.3,18.4,11.8z M13.1,11.2C13,11.1,12.8,11,12.6,11h-1.3c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.2-0.2,0.5-0.1,0.7l0.7,1
	l-0.3,2.6l0.6,1.6c0.1,0.2,0.3,0.2,0.4,0l0.6-1.6l-0.3-2.6l0.7-1C13.3,11.7,13.2,11.4,13.1,11.2z"/>
    </SvgIcon>
);

export default SalesIcon;