export default [
  {
    id: "sampleStorageCode",
    label: "Freezer",
  },
  {
    id: "shelf",
    label: "Shelf",
  },
  {
    id: "rack",
    label: "Rack",
  },
  {
    id: "box",
    label: "Box",
  },
  {
    id: "reqId",
    label: "Req ID",
  },
  {
    id: "clientId",
    label: "Client",
    type: "autocomplete",
    resource: "filter_dd/clients",
    autocompleteOptions: {
      loadDataAfterOpenMenu: true,
    },
    accessor: (d) => ({ value: d.id, label: d.clientName }),
  },
  {
    id: "physId",
    type: "autocomplete",
    resource: "filter_dd/physicians",
    autocompleteOptions: {
      loadDataAfterOpenMenu: true,
    },
    accessor: (d) => ({
      value: d.id,
      label: `${d.physLastName}, ${d.physFirstName}`,
    }),
    label: "Physicians",
  },
  {
    id: "patientFirstName",
    label: "Patient First Name",
  },
  {
    id: "patientLastName",
    label: "Patient Last Name",
  },
  {
    id: "dateCollected",
    label: "Date Collected From - To",
    type: "dateRange",
  },
  {
    id: "dateReceived",
    label: "Date Received From - To",
    type: "dateRange",
  },
];
