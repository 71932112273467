export default {
  resources: {
    collaborator: {
      baseUrl: 'collaborators',
    },
    clients: {
      baseUrl: 'clients'
    },
    physicians: {
      baseUrl: 'physicians'
    },
    sales: {
      baseUrl: 'sales'
    },
    requisitions: {
      baseUrl: 'requisitions'
    },
    reports: {
      baseUrl: 'reports'
    },
    users: {
      baseUrl: 'users'
    },
    testReportsUpload: {
      baseUrl: 'test-reports-upload'
    },
    userProfile: {
      baseUrl: 'user-profile',
    },
    freezerSpecs: {
      baseUrl: 'freezer-specs',
    },
    batchCsvUpload: {
      baseUrl: 'batch-csv-upload',
    }
  }
}