import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BagIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M9.9,12.7h4.2v1.4H9.9V12.7z M19.5,14.1h-4v0.7c0,0.4-0.3,0.7-0.7,0.7H9.2c-0.4,0-0.7-0.3-0.7-0.7v-0.7h-4
	c-0.9,0-1.7-0.6-2-1.4L0,5.1v15.4c0,1.2,0.9,2.1,2.1,2.1h19.8c1.2,0,2.1-0.9,2.1-2.1V5.1l-2.5,7.6C21.2,13.5,20.4,14.1,19.5,14.1z
	 M14.8,1.5H9.2C8,1.5,7.1,2.4,7.1,3.6v0.7H1.2l2.7,8c0.1,0.3,0.4,0.5,0.7,0.5h4V12c0-0.4,0.3-0.7,0.7-0.7h5.6c0.4,0,0.7,0.3,0.7,0.7
	v0.7h4c0.3,0,0.6-0.2,0.7-0.5l2.7-8h-5.9V3.6C16.9,2.4,16,1.5,14.8,1.5z M8.5,4.3V3.6c0-0.4,0.3-0.7,0.7-0.7h5.6
	c0.4,0,0.7,0.3,0.7,0.7v0.7H8.5z"/>
    </SvgIcon>
);

export default BagIcon;