import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PlusIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M17.8,12.9h-4.8V18
	c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9v-5.1H6.2c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h4.8V6.5c0-0.5,0.4-0.9,0.9-0.9
	s0.9,0.4,0.9,0.9v4.6h4.8c0.5,0,0.9,0.4,0.9,0.9S18.3,12.9,17.8,12.9z"/>
    </SvgIcon>
);

export default PlusIcon;