import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";

const styles = theme => ({
  root: {
    border: '1px solid #6D6D6D;',
    padding: '5px 10px',
    fontSize: '0.8568rem',
    width: '100%',
    boxSizing: 'border-box',
  },
  error: {
    background: 'rgba(255, 0, 0, 0.2)'
  }
});

const SimpleInput = props => {
  const { classes } = props;

  return (
    <input
      placeholder={props.placeholder}
      className={clsx({
        [classes.root]: true,
        [classes.error]: props.error,
      })}
      onChange={props.handleInputs}
      onKeyDown={props.onKeyDown}
      name={props.inputId}
      value={props.value}
    />
  );
}

SimpleInput.defaultProps = {
  onKeyDown: () => {},
}

export default withStyles(styles)(SimpleInput);