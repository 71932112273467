import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MainHeader from 'components/templateParts/MainHeader';
import dataProvider, { CREATE } from "providers/dataProvider";
import withForm from 'withForm';
import { clientsResource } from "config/resources/clientsResource";
import style from './ClientCreateContainer.module.scss'
import withModal from 'withModal';
import PhysCreatePromptModalContainer from './PhysCreatePromptModalContainer';
import { compose } from 'redux'
import { withNotificationCenter } from 'NotificationCenter'
import BaseForm from 'components/ui_components/BaseForm/BaseForm';
import BaseFormControls from 'components/ui_components/BaseFormControls/BaseFormControls';

const styles = () => ({
  main: {
    margin: '32px 26px 0',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '30px 15px 30px'
  },
});

const withFormConfig = clientsResource.createConfig;
const inputsGroupsById = withFormConfig.inputsGroupsById;
const inputsGroupsByHash = withFormConfig.inputsGroupsByHash;

class ClientCreateContainer extends PureComponent {
  createdClientID = null;

  handleSubmit = () => {
    const { notificationCenter, handleOpenModal, isFormValid, resetErrors, getSubmitData } = this.props;

    if (isFormValid()) {
      resetErrors();

      dataProvider(CREATE, 'clients', { data: getSubmitData(true, true, true) })
        .then((response) => {
        notificationCenter.show(response.message, 'info')
        handleOpenModal();
        this.createdClientID = response.data.id;
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
      });
    }
  }

  handleClickNoPhysCreatePrompt = () => {
    this.props.history.push('/clients/' + this.createdClientID + '/show');
  }

  render() {
    const { classes, inputsById, inputsByHash, handleInputs, modalOpen, history, handleCloseModal } = this.props;
    return (
      <div className={style.root}>
        <PhysCreatePromptModalContainer
          modalOpen={modalOpen}
          handleCloseModal={handleCloseModal}
          handleClickNo={this.handleClickNoPhysCreatePrompt}
          handleClickYes={() => history.push(`/physicians/create?relatedClient=${this.createdClientID}`)}
        />
        <MainHeader title="Create Client"/>
        <div className={classes.main}>

          <BaseForm
            inputsById={inputsById}
            inputsByHash={inputsByHash}
            handleInputs={handleInputs}
            inputsGroupsById={inputsGroupsById}
            inputsGroupsByHash={inputsGroupsByHash}
          />

          <BaseFormControls
            type={'create'}
            handleClickSubmit={this.handleSubmit}
            submitLabel='Create Client'
            resource='clients'
            id={this.props.id}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withNotificationCenter(),
  withForm({ ...withFormConfig }),
  withStyles(styles),
  withModal
)(ClientCreateContainer)