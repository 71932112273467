import React from "react";
import helpers from "utils/helpers";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Lock from "@material-ui/icons/Lock";
let yup = require("yup");

const loginFormConfig = {
  inputsById: ["username", "password"],

  inputsByHash: {
    username: {
      label: "Username",
      autoComplete: "username",
      yup: helpers.getYupRequired("Username"),
      icon: <AccountCircle />,
    },
    password: {
      label: "Password",
      type: "password",
      autoComplete: "current-password",
      yup: helpers.getYupRequired("Password"),
      icon: <Lock />,
    },
  },
};

const resetFormConfig = {
  inputsById: ["username"],

  inputsByHash: {
    username: {
      label: "Username",
      autoComplete: "username",
      yup: helpers.getYupRequired("Username"),
      icon: <AccountCircle />,
    },
  },
};

const verifyCodeFormConfig = {
  inputsById: ["code"],

  inputsByHash: {
    code: {
      label: "Authentication Code",
      yup: yup
        .string()
        .required(
          "Authentication failed. Please enter a valid authentication code."
        )
        .nullable(
          "Authentication failed. Please enter a valid authentication code."
        ),
      icon: <Lock />,
    },
  },
};

export { loginFormConfig, verifyCodeFormConfig, resetFormConfig };
