import React from 'react';
import styles from './TableFilter.module.scss';
import SmartInput from 'components/ui_components/smart_input'
import withForm from 'withForm';
import PropTypes from "prop-types";

const TableFilter = (props) => {
    const {inputsById, inputsByHash, labID} = props;

    const handleFilterInputs = (e) => {
        props.handleInputs(e).then((resolve) => {
            props.handleFilters(props.getSubmitData(true, true, true))
        })

    }

    return (
        <div className={styles.root}>
            <div className={styles.inputList}>
                {inputsById.map((inputId, index) => {
                    const input = inputsByHash[inputId];
                    let params = {inputId, input, handleInputs: handleFilterInputs}
                    return (
                        <div key={index} className={styles.inputWrap}>
                            <SmartInput
                              key={index}
                              {...params}
                              options={{labID: labID}}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default withForm()(TableFilter);

TableFilter.propTypes = {
    inputsById: PropTypes.array.isRequired,
    inputsByHash: PropTypes.object.isRequired
}