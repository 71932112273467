import React, { useState } from "react";
import MainHeader from "components/templateParts/MainHeader";
import SearchBar from "components/templateParts/SearchBar";
import SmartViewsSidebar from "../../../SmartViewsSidebar/SmartViewsSidebar";
import AddPostButton from "../../../ui_components/buttons/AddPostButton";
import Footer from "components/Footer/Footer";
import { BaseListComponent } from "components/base";
import {
  smartFilterConfig,
  tableColConfig,
} from "config/resources/usersResource";
import { TableHeaderAddButton } from "components/ui_components/buttons";
import { Delivery2Icon } from "icons";
import dataProvider, { GET_LIST } from "providers/dataProvider";
import { useSmartFilter } from "SmartFilterProvider";
import { useNotificationCenter } from "NotificationCenter";

const UsersListComponent = (props) => {
  const [downloadInProgress, setDownloadInProgress] = useState();
  const { getFilter } = useSmartFilter();
  const notificationCenter = useNotificationCenter();

  const handleDownloadReportsByFilter = () => {
    setDownloadInProgress(true);
    dataProvider(
      GET_LIST,
      `users/export`,
      {
        filter: { ...getFilter() },
      },
      {
        apiEndointType: "report",
      }
    )
      .then(({ data, total, message }) => {
        if (data !== null && data.hasOwnProperty("url")) {
          window.open(data.url);
          notificationCenter.showDownloadNotification(data.url);
        } else {
          notificationCenter.show(message, "error");
        }
        setDownloadInProgress(false);
      })
      .catch((error) => {
        notificationCenter.show(error.message, "warning");
        setDownloadInProgress(false);
      });
  };

  return (
    <div>
      <MainHeader
        title="Users"
        button={<AddPostButton title="Users" href="/users/create" />}
      />
      <SearchBar />
      <SmartViewsSidebar
        isOpen={true}
        resource="users"
        smartFilterConfig={smartFilterConfig}
      >
        <div className="mainContainer">
          <div className="mainContainer__inner">
            <BaseListComponent
              {...props}
              tableColConfig={tableColConfig}
              deleteResource="mfa/users"
              tableProps={{
                toolbarExtraComponents: (
                  <TableHeaderAddButton
                    icon={<Delivery2Icon />}
                    onClick={handleDownloadReportsByFilter}
                    showProgressBar={downloadInProgress}
                  >
                    Export All
                  </TableHeaderAddButton>
                ),
              }}
            />
          </div>
          <Footer />
        </div>
      </SmartViewsSidebar>
    </div>
  );
};

export default UsersListComponent;
