import React from 'react';
import MainHeader from "components/templateParts/MainHeader";
import dataProvider, { CREATE } from "providers/dataProvider";
import { withNotificationCenter } from 'NotificationCenter'
import styles from './SalesCreateContainer.module.scss'
import { compose } from 'redux'
import { salesResource } from "config/resources/salesResource";
import withForm from 'withForm';
import BaseForm from 'components/ui_components/BaseForm/BaseForm.js';
import BaseFormControls from 'components/ui_components/BaseFormControls/BaseFormControls';
import { useHistory } from 'react-router-dom';

const withFormConfig = salesResource.createConfig;
const inputsGroupsById = withFormConfig.inputsGroupsById;
const inputsGroupsByHash = withFormConfig.inputsGroupsByHash;

const SalesCreate = ({ notificationCenter, isFormValid, resetErrors, getSubmitData, inputsById, inputsByHash, handleInputs }) => {
  const history = useHistory();

  const handleSubmit = () => {
    if (isFormValid()) {
      resetErrors();

      dataProvider(CREATE, 'sales', { data: getSubmitData(true, true, true) })
      .then((response) => {
        notificationCenter.show('Successfully created', 'info')
        history.push(`/sales/` + response.data.id + '/show');
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
      });
    }
  }

  return (
    <div className={styles.root}>
      <MainHeader title="Create Sales"/>
      <div className={styles.main}>

        <BaseForm
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          inputsGroupsById={inputsGroupsById}
          inputsGroupsByHash={inputsGroupsByHash}
        />

        <BaseFormControls
          type={'create'}
          handleClickSubmit={handleSubmit}
          submitLabel="Create Sales"
          resource="sales"
        />
      </div>
    </div>
  );
}

export default compose(
  withNotificationCenter(),
  withForm(withFormConfig)
)(SalesCreate)
