import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MedicineCaseIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M0.9,5C0.3,5.7,0,6.5,0,7.5v13c0,1,0.3,1.8,0.9,2.5c0.6,0.7,1.3,1,2.2,1h0.4V4H3.1C2.2,4,1.5,4.3,0.9,5z
	 M17.3,1.5c0-0.4-0.1-0.8-0.4-1.1C16.7,0.1,16.3,0,16,0H8C7.7,0,7.3,0.1,7.1,0.4C6.8,0.7,6.7,1.1,6.7,1.5V4H4.5v20h15V4h-2.2V1.5z
	 M8.5,2h7.1v2H8.5V2z M17.3,15.5c0,0.1,0,0.3-0.1,0.4C17.1,16,17,16,16.9,16h-3.1v2.9c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.3,0.1
	h-2.6c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4V16H7.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-3c0-0.1,0-0.3,0.1-0.4
	C6.9,12,7,12,7.1,12h3.1V9.1c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.3-0.1h2.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.4V12h3.1
	c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.4L17.3,15.5z M23.1,5c-0.6-0.7-1.3-1-2.2-1h-0.4v20h0.4c0.8,0,1.6-0.3,2.2-1
	c0.6-0.7,0.9-1.5,0.9-2.5v-13C24,6.5,23.7,5.7,23.1,5z"/>
    </SvgIcon>
);

export default MedicineCaseIcon;