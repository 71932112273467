import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
  },
}));

const Phone = ({ input, inputId, handleInputs, value }) => {
  const classes = useStyles();

  return (
    <TextField
      InputProps={{
        className: classes.input,
      }}
      type="number"
      key={inputId}
      name={inputId}
      value={value === null ? "" : value}
      required={input.hasOwnProperty("yup")}
      label={input.error ? input.errorMessage : input.label}
      onChange={handleInputs}
      helperText="[country code][area code][phone number]"
    />
  );
};

export default Phone;
