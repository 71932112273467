import React, { PureComponent } from 'react'
import withForm from 'withForm';
import { withNotificationCenter } from 'NotificationCenter'
import dataProvider, { UPDATE } from "providers/dataProvider";
import Can from 'Can';
import { compose } from 'redux'
import { userGroups } from 'config/userRoles';
import { BaseWidgetViewList } from 'components/ui_components/Widgets/WidgetViewLists';
import WidgetType1 from 'components/ui_components/Widgets/WidgetType1/WidgetType1';
import { WidgetControlButton, ButtonGroup } from 'components/ui_components/buttons';
import BaseForm from 'components/ui_components/BaseForm/BaseForm.js';
import {billingTabConfig} from "config/resources/requisitionsResource";

const { guarantorInformation } = billingTabConfig;

class Widget extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEditMode: false,
      data: props.data
    }
  }

  switchToEditMode = () => {
    this.setState({
      isEditMode: true
    })
  }

  switchToReadMode = () => {
    this.setState({
      isEditMode: false
    })
  }

  handleSubmit = () => {
    const { notificationCenter, isFormValid, resetErrors, reqID, billingID, getSubmitData } = this.props;
    resetErrors();

    if (isFormValid()) {

      dataProvider(UPDATE, `requisitions/${reqID}/billings/${billingID}`, { data: getSubmitData() })
      .then((response) => {
        console.log(response)
        this.setState({
          data: response.data
        }, () => {
          this.switchToReadMode();
        })
        notificationCenter.show('Successfully updated', 'info')
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
      });
    }
  }

  handleInputs = (e) => {
    if (e.target.name === 'relationTypeCode') {
      this.props.clearInputsById(['guarantorGenderCode', 'guarantorFirstName', 'guarantorLastName', 'guarantorMiddleName', 'guarantorDob']);

      if (e.target.value === 'DEP') {
        this.props.clearInputsById(['guarantorStateId', 'guarantorCity', 'guarantorZip', 'guarantorAddr1', 'guarantorAddr2']);
      }
    }

    this.props.handleInputs(e);
  }

  render() {
    const { inputsByHash, inputsById } = this.props;
    const { isEditMode } = this.state;

    const controls = <Can allowedRoles={userGroups.superAdmins}>
      <ButtonGroup>
        {isEditMode ? <WidgetControlButton
          onClick={this.switchToReadMode}
          variant="outlined"
        >
          Cancel
        </WidgetControlButton> : null}

        <WidgetControlButton
          onClick={isEditMode ? this.handleSubmit : this.switchToEditMode}
        >
          {isEditMode ? 'Save' : 'Edit'}
        </WidgetControlButton>
      </ButtonGroup>
    </Can>;


    const content = isEditMode ?
    <BaseForm
    inputsByHash={inputsByHash}
    inputsById={inputsById}
    handleInputs={this.handleInputs}
    inputsGroupsByHash={this.props.inputsGroupsByHash}
    inputsGroupsById={this.props.inputsGroupsById}

  />
      :
      <BaseWidgetViewList ids={guarantorInformation.viewInputsById}
                          inputsByHash={guarantorInformation.viewInputsByHash}
                          data={this.state.data}
                          showIcons={false}
      />

    return (
      <WidgetType1
        title={'Guarantor Information'}
        content={content}
        headerRightContent={ this.props.editAvailable && controls }
        wrapStyles={this.props.widgetWrapStyles}
      />
    )
  }
}


export default compose(
  withNotificationCenter(),
  withForm()
)(Widget)

Widget.defaultProps = {
  editAvailable: true,
  widgetWrapStyles: '',
}