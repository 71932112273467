import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const GlobeIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M24,11.2C23.6,5.2,18.8,0.4,12.8,0v0H12h0h-0.8v0C5.2,0.4,0.4,5.2,0,11.2h0V12v0.8h0c0.4,6,5.2,10.8,11.2,11.2v0H12h0.8v0
	c6-0.4,10.8-5.2,11.2-11.2h0V12L24,11.2L24,11.2z M7.5,2.6c-0.8,1-1.4,2.3-1.9,3.8H3.2C4.3,4.8,5.8,3.5,7.5,2.6z M2.4,8h2.8
	C5,9,4.9,10.1,4.8,11.2H1.6C1.7,10.1,2,9,2.4,8z M1.6,12.8h3.2C4.9,13.9,5,15,5.2,16H2.4C2,15,1.7,13.9,1.6,12.8z M3.2,17.6h2.4
	c0.5,1.5,1.1,2.8,1.9,3.8C5.8,20.5,4.3,19.2,3.2,17.6z M11.2,22.3c-1.6-0.4-3.1-2.2-3.9-4.7h3.9V22.3z M11.2,16H6.8
	c-0.2-1-0.4-2.1-0.4-3.2h4.8V16z M11.2,11.2H6.4C6.5,10.1,6.6,9,6.8,8h4.4V11.2z M11.2,6.4H7.3c0.9-2.5,2.3-4.3,3.9-4.7V6.4z
	 M20.8,6.4h-2.4c-0.5-1.5-1.1-2.8-1.9-3.8C18.2,3.5,19.7,4.8,20.8,6.4z M12.8,1.7c1.6,0.4,3.1,2.2,3.9,4.7h-3.9V1.7z M12.8,8h4.4
	c0.2,1,0.4,2.1,0.4,3.2h-4.8V8z M12.8,12.8h4.8c0,1.1-0.2,2.2-0.4,3.2h-4.4V12.8z M12.8,22.3v-4.7h3.9
	C15.9,20.1,14.4,21.9,12.8,22.3z M16.5,21.4c0.8-1,1.4-2.3,1.9-3.8h2.4C19.7,19.2,18.2,20.5,16.5,21.4z M21.6,16h-2.8
	c0.2-1,0.3-2.1,0.4-3.2h3.2C22.3,13.9,22,15,21.6,16z M19.2,11.2c0-1.1-0.2-2.2-0.4-3.2h2.8c0.4,1,0.7,2.1,0.8,3.2H19.2z"/>
  </SvgIcon>
);

export default GlobeIcon;