import React, { Component } from 'react'
import SmartTable from 'components/ui_components/table/SmartTable'
import dataProvider, { CREATE } from "providers/dataProvider";
import { Refresh } from '@material-ui/icons'
import { compose } from 'redux'
import StatusTableCellValueContainer
  from 'components/ui_components/table/StatusTableCellValue/StatusTableCellValueContainer';
import { withNotificationCenter } from 'NotificationCenter';
import { formatServerDateTimeToLocal } from 'utils/dateTimeFormat';

const columns = [
  {
    id: 'reportOriginalFileName',
    label: 'File Name'
  },
  {
    id: 'reportLoadStatusDesc',
    label: 'Upload Status',
    accessor: d => {
      let status = 'statusNormal';

      if (d.reportLoadStatusCode !== 'COMPLETE' && d.reportLoadStatusCode !== 'INIT') {
        status = 'statusError';
      } else if (d.reportLoadStatusCode === 'COMPLETE') {
        status = 'statusOk';
      }

      return (
        <StatusTableCellValueContainer status={status}>
          {d.reportLoadStatusDesc} {d.reportLoadStatusCode === 'FILENOTFOUND' && ' - Please re-upload'}
        </StatusTableCellValueContainer>)
    }
  },
  {
    id: 'statusDate',
    label: 'Completed on',
    accessor: d => formatServerDateTimeToLocal(d.statusDate, 'dateTime'),
  }

]

class ProcessingSubTable extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleProcessAgain = (rows) => {
    const { notificationCenter } = this.props;

    rows.forEach((row, index) => {
      dataProvider(CREATE, `bulk_report_uploader/${row.loadId}/finish/${row.id}`, { data: {} })
      .then((response) => {
        notificationCenter.show(response.message, 'info')
        if (rows.length - 1 === index) {
          this.smartTable.loadData()
        }
      })
    })
  }

  render() {
    const { uploadId } = this.props;

    return (
      <SmartTable
        onRef={ref => (this.smartTable = ref)}
        resource={`bulk_report_uploader/${uploadId}/files`}
        columns={columns}
        isSubTable={true}
        showSelect={false}
        perPage={-1}
        editableTable={true}
        showPagination={false}
        handleSubTableRowsInputData={this.props.handleSubTableRowsInputData}
        parentRow={this.props.parentRow}
        customRowActions={[
          {
            icon: <Refresh/>,
            handleOnClick: this.handleProcessAgain,
            tooltipText: 'Retry requisition assignment',
            isHiddenAccessor: row => (row.reportLoadStatusCode === 'COMPLETE') || (row.reportLoadStatusCode === 'FILENOTFOUND')
          }
        ]}
      />
    )
  }
}

export default compose(
  withNotificationCenter()
)(ProcessingSubTable);