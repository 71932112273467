import React from "react";
import withForm from "withForm";
import { resetFormConfig } from "config/loginPage";
import { compose } from "redux";
import { useAuthCenter } from "AuthCenter";
import { useNotificationCenter } from "NotificationCenter";
import { BaseForm } from "components/ui_components/Forms";
import { ButtonGroup } from "components/ui_components/buttons";
import ButtonBase from "@material-ui/core/ButtonBase";
import Button from "@material-ui/core/Button";

const ForgotPasswordForm = (props) => {
  const {
    isFormValid,
    resetErrors,
    getSubmitData,
    inputsById,
    inputsByHash,
    handleInputs,
  } = props;
  const authCenter = useAuthCenter();
  const notificationCenter = useNotificationCenter();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid()) {
      resetErrors();

      try {
        props.setDataLoading(true);
        await authCenter
          .forgotPassword({ ...getSubmitData() })
          .then(() => {
            props.setDataLoading(false);
          })
          .catch((err) => {
            props.setDataLoading(false);
          });
      } catch (e) {
        notificationCenter.show(e.message, "error");
      }
    }
  };

  return (
    <React.Fragment>
      <form>
        <h2 className="ttl ttl-6 text-center">Forgot Password</h2>
        <BaseForm
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          inputWrapStyles={`width: 100%;`}
        />

        <ButtonGroup rootStyles={"padding: 10px 0 0; text-align: right;"}>
          <Button
            color="primary"
            onClick={() => {
              authCenter.setAuthStatus("login");
            }}
          >
            Back to login
          </Button>
        </ButtonGroup>

        <ButtonGroup rootStyles={"padding: 20px 0 0; text-align: center;"}>
          <ButtonBase
            type="submit"
            onClick={handleSubmit}
            className="button formButton formButton__mainContained"
          >
            Reset
          </ButtonBase>
        </ButtonGroup>
      </form>
    </React.Fragment>
  );
};

export default compose(withForm(resetFormConfig))(ForgotPasswordForm);
