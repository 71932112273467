import React, { PureComponent } from "react";
import { NotificationIcon } from "icons";
import withModal from "withModal";
import NotificationsModalContainer from "./NotificationsModalContainer";
import CustomMenuItem from "components/SidebarSubMenu/CustomMenuItem";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userHasUnreadNotifications, userReadAllNotifications } from "actions";
import { GET_LIST } from "providers/dataProvider";
import dataProvider from "providers/dataProvider";
import { bindActionCreators, compose } from "redux";
import authStorage from "providers/authStorage.js";

class NotificationMenuItemContainer extends PureComponent {
  componentDidMount() {
    this.props.history.listen((location) => {
      this.checkAndSetUserNotificationStatus();
    });

    this.checkAndSetUserNotificationStatus();
  }

  checkAndSetUserNotificationStatus = () => {
    const access = authStorage.getAccess();

    if (access && access.hasOwnProperty("userId")) {
      const userId = access.userId;

      dataProvider(GET_LIST, `users/${userId}/notifications`).then(
        ({ data, total }) => {
          for (let i = 0; i < data.length; i++) {
            if (data[i].issueCount - data[i].readCount > 0) {
              this.props.userHasUnreadNotifications();
              break;
            }
          }
        }
      );
    }
  };

  handleClickMenuItem = () => {
    this.props.handleSidebarClose();
    this.props.handleOpenModal();
  };

  render() {
    const { userHasNotification } = this.props;
    return (
      <React.Fragment>
        <NotificationsModalContainer
          modalOpen={this.props.modalOpen}
          handleCloseModal={this.props.handleCloseModal}
        />
        <CustomMenuItem>
          <span onClick={this.handleClickMenuItem}>
            <NotificationIcon isActive={userHasNotification} />
            Notifications
          </span>
        </CustomMenuItem>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ notifications }) => ({
  userHasNotification: notifications.userHasNotification,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userHasUnreadNotifications,
      userReadAllNotifications,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withModal,
  withRouter
)(NotificationMenuItemContainer);
