export default  [
    {
        id: 'physFirstName',
        label: 'First Name'
    },
    {
        id: 'physLastName',
        label: 'Last Name'
    },
    {
        id: 'npiNumber',
        label: 'NPI#'
    },
    {
        id: 'clientName',
        label: 'Client Name'
    },
    {
        id: 'legacyClientId',
        label: 'Client ID'
    }
]