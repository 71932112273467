import React, {Component} from 'react'
import Modal from '@material-ui/core/Modal';
import ButtonBase from '@material-ui/core/ButtonBase';
import styles from './DeliveryModalContainer.module.scss';
import {reportsDeliveryTabConfig} from "config/resources/requisitionsResource/index";
import {compose} from 'redux'
import SmartInput from 'components/ui_components/smart_input/SmartInputContainer'
import dataProvider, {CREATE} from "providers/dataProvider";
import withForm from 'withForm';
import PropTypes from "prop-types";
import { withNotificationCenter } from 'NotificationCenter';


class DeliveryModalContainer extends Component {
    handleSubmit = () => {
        const {isFormValid, getSubmitData, submitData, resource, notificationCenter, handleCloseModal, handleSubmitOK} = this.props;

        if (!isFormValid()) return;

        const data = {
            ...submitData,
            transmitMethodCode: getSubmitData().deliveryMethod
        }

        dataProvider(CREATE, resource, {data: data})
            .then((response) => {
                notificationCenter.show('Successfully created', 'info')
                handleCloseModal();
                handleSubmitOK();
            })
            .catch(error => {
                notificationCenter.show(error.message, 'warning')
            });
    }

    render() {
        const {modalOpen, handleCloseModal, inputsById, inputsByHash, handleInputs} = this.props;
        return (
            <Modal open={modalOpen} onClose={handleCloseModal}>
                <div className={`${styles.root} modalContainer`}>
                    <h2 className="ttl ttl-2">
                        Delivery
                    </h2>

                    <div className={styles.inputList}>
                        {inputsById.map((inputId, index) => {
                            const input = inputsByHash[inputId];

                            let params = {inputId, input, handleInputs}
                            return (
                                <div key={index} className={styles.inputWrap}>
                                    <SmartInput {...params}/>
                                </div>
                            )
                        })}
                    </div>

                    <div className={`${'buttonGroup'} ${styles.buttonGroup}`}>
                        <ButtonBase
                            className="button formButton formButton__secondaryOutlined"
                            onClick={handleCloseModal}>
                            Cancel
                        </ButtonBase>
                        <ButtonBase
                            className="button formButton formButton__mainContained"
                            onClick={this.handleSubmit}
                        >
                            Add
                        </ButtonBase>
                    </div>
                </div>
            </Modal>
        );
    }
}

DeliveryModalContainer.defaultProps = {
    submitData: {},
    handleSubmitOK: () => {}
}

DeliveryModalContainer.propTypes = {
    resource: PropTypes.string.isRequired
}


export default compose(
    withNotificationCenter(),
    withForm(reportsDeliveryTabConfig.deliveryModalConfig)
)(DeliveryModalContainer)