import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import dataProvider, { CREATE } from "providers/dataProvider";
import withForm from 'withForm'
import { compose } from 'redux'
import { addTestFormConfig } from 'config/resources/collaboratorsResource/view'
import BaseForm from 'components/ui_components/BaseForm/BaseForm';
import { useNotificationCenter } from 'NotificationCenter';
import BaseModal from 'components/ui_components/BaseModal/BaseModal.js';

const AddTestModal = (props) => {
  const {
    handleCloseModal,
    clearInputs,
    modalIsOpen,
    handleAddNewTest,
  } = props;

  const notificationCenter = useNotificationCenter();

  const handleSubmit = () => {
    props.resetErrors();

    if (!props.isFormValid()) return;

    const submitData = {
      colaRanges: [
        {
          ...props.getSubmitData(true, true, true)
        }
      ]
    }

    dataProvider(CREATE, `collaborators/${props.colaID}/test_ranges`, { data: submitData })
    .then((response) => {
      notificationCenter.show('Successfully create', 'info')
      handleAddNewTest();
      handleCloseModal();
      clearInputs();
    })
    .catch(error => {
      notificationCenter.show(error.message, 'warning')
    });
  }

  return (
    <BaseModal
      isOpen={modalIsOpen}
      onClose={handleCloseModal}
      title="Add Test"
      controls={<React.Fragment>
        <ButtonBase
          className="button formButton formButton__secondaryOutlined"
          onClick={handleCloseModal}>
          Cancel
        </ButtonBase>
        <ButtonBase
          className="button formButton formButton__mainContained"
          onClick={handleSubmit}
        >
          Add
        </ButtonBase>
      </React.Fragment>}
      rootStyles={`max-width: 460px;`}
    >
      <BaseForm
        inputsById={props.inputsById}
        inputsByHash={props.inputsByHash}
        handleInputs={props.handleInputs}
        inputsGroupsById={addTestFormConfig.inputsGroupsById}
        inputsGroupsByHash={addTestFormConfig.inputsGroupsByHash}
        inputWrapStyles={`
            width: 50%;
            margin: 7px 0 0;
          `
        }
        groupTitleStyles={`font-size: 13px;`}
        groupStyles={`
              padding: 7px 0 0;
              + .formGroup {
                padding: 10px 0 0;
              }
          `}
      />
    </BaseModal>
  );

}

export default compose(
  withForm(addTestFormConfig)
)(AddTestModal)