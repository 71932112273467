import React from "react";
import BaseTable from "components/ui_components/table/BaseTable";
import { yesNoAccessor, heightAccessor, weightAccessor } from "utils/accessors";

const tableColConfig = [
  {
    id: "sbp",
    label: "SBP",
  },
  {
    id: "dbp",
    label: "DBP ",
  },

  {
    id: "isSmoker",
    label: "Smoker",
    accessor: (d) => yesNoAccessor(d, "isSmoker"),
  },

  {
    id: "isDiabetic",
    label: "Diabetic",
    accessor: (d) => yesNoAccessor(d, "isDiabetic"),
  },

  {
    id: "isFamilyHistory",
    label: "Fam. HX of MI",
    accessor: (d) => yesNoAccessor(d, "isFamilyHistory"),
  },
  {
    id: "isSelfT2DGest",
    label: "T2/Ges. Diabetes",
    accessor: (d) => yesNoAccessor(d, "isSelfT2DGest"),
  },
  {
    id: "famHistT2DDesc",
    label: "Fam. HX of Diabetes",
  },
  {
    id: "height",
    label: "Height",
    accessor: (d) => heightAccessor(d.height, d.heightUom),
  },
  {
    id: "weight",
    label: "Weight",
    accessor: (d) => weightAccessor(d.weight, d.weightUom),
  },
  {
    id: "patientRaceDesc",
    label: "Race",
  },
  {
    id: "patientEthnicityDesc",
    label: "Ethnicity",
  },
];

export default function HistorySubTable({ parentRow }) {
  return (
    <BaseTable
      columns={tableColConfig}
      isSubTable={true}
      showSelect={false}
      showPagination={false}
      rows={[parentRow]}
    />
  );
}
