import React from "react";
import TextField from "@material-ui/core/TextField";
import withAutoFill from "./withAutoFill";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      padding: "6px 10px 7px",
    },
  },
}));

const helperUseStyles = makeStyles(() => ({
  helperText: {
    lineHeight: 1.1,
    letterSpacing: 0,
  },
}));

const Text = (props) => {
  let { input, inputId, handleInputs, value } = props;
  const classes = useStyles();
  const helperClasses = helperUseStyles();

  let inputValue = value;
  if (input.hasOwnProperty('relativeInputsValueAccessor') && input.hasOwnProperty('relativeInputsData')) {
    inputValue = input.relativeInputsValueAccessor(input.relativeInputsData)
    input.value = inputValue;
  }
  if (value === null) {
    inputValue = '';
  }

  let required = input.hasOwnProperty("yup");

  if (input.hasOwnProperty("required")) {
    required = input.required;
  }

  const InputProps = {};

  if (input.icon) {
    InputProps.startAdornment = (
      <InputAdornment position="start">{input.icon}</InputAdornment>
    );
  }

  return (
    <TextField
      classes={classes}
      error={input.error}
      key={inputId}
      name={inputId}
      disabled={input?.disabled}
      value={inputValue}
      required={required}
      autoFocus={input.autoFocus}
      label={input.label}
      autoComplete={input.autoComplete}
      onChange={handleInputs}
      helperText={input.error && input.errorMessage}
      inputProps={{
        required: false,
      }}
      InputProps={InputProps}
      FormHelperTextProps={{
        className: helperClasses.helperText,
      }}
    />
  );
};

export default withAutoFill(Text);
