import { currentPassword, newPassword, newPasswordConfirm } from 'config/inputs';

export default {
  inputsById: [
    'currentPassword', 'newPwd', 'newPwdConfirm'
  ],

  inputsByHash: {
    currentPassword: currentPassword,
    newPwd: newPassword,
    newPwdConfirm: newPasswordConfirm,
  }
}