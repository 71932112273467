import React, { useEffect, useCallback } from "react";
import SmartFilterContext from "./SmartFilterContext";
import { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import urlQuery from "urlQuery";
import { getArrayOfObjectFromObjectByKeys } from "utils/formatObject";
import { isEmpty } from "lodash";

const getInitValue = (input) => {
  return input.type === "checkbox" ? [] : "";
};

const getInitViewValue = () => {
  return "";
};

const SmartFilterProvider = ({ children }) => {
  const inputsByIdInitState = (config) => {
    return config.map((input) => input.id);
  };
  const inputsByHashInitState = (config) => {
    const inputsByHash = {};

    config.forEach((filter) => {
      inputsByHash[filter.id] = {
        ...filter,
        value: getInitValue(filter),
        viewValue: getInitViewValue(),
      };
    });
    return inputsByHash;
  };

  const [inputsById, setInputsById] = useState([]);
  const [inputsByHash, setInputsByHash] = useState({});

  const getInputsByHashWithDataFromUrlQuery = useCallback((inputsById, inputsByHash) => {
    const updatedInputsByHash = cloneDeep(inputsByHash);
    const smartFilterUrlQuery = urlQuery.getByKey("smartFilter");
    if (smartFilterUrlQuery && smartFilterUrlQuery.length > 0) {
      const values = urlQuery.getByKey("smartFilter");

      values.forEach((item) => {
        updatedInputsByHash[item.id] = {
          ...updatedInputsByHash[item.id],
          value: item.value,
          viewValue: item.viewValue,
        };
      });
    } else {
      inputsById.forEach((id) => {
        updatedInputsByHash[id].value = "";
        updatedInputsByHash[id].viewValue = "";
      });
    }
    return updatedInputsByHash
  }, [])

  const handlePopStateChange = useCallback(() => {
    console.log('handlePopStateChange');
    const inputsByHashWithDataFromQueryUrl = getInputsByHashWithDataFromUrlQuery(inputsById, inputsByHash)
    console.log(inputsByHashWithDataFromQueryUrl);
    setInputsByHash(inputsByHashWithDataFromQueryUrl);
  }, [getInputsByHashWithDataFromUrlQuery, setInputsByHash, inputsById, inputsByHash]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopStateChange);
    return () => window.removeEventListener("popstate", handlePopStateChange);
  }, [handlePopStateChange]);

  const init = async (config) => {
    const inputsByHash = inputsByHashInitState(config);
    const inputsById = inputsByIdInitState(config);
    const inputsByHashWithDataFromQueryUrl = getInputsByHashWithDataFromUrlQuery(inputsById, inputsByHash);
    setInputsById(inputsById);
    setInputsByHash(inputsByHashWithDataFromQueryUrl);
  };

  const applyFormInputs = (inputsByHash) => {
    refreshUrlQuery(inputsByHash);
    setInputsByHash(inputsByHash);
  };

  const getDataForQueryUrl = (inputsByHash) => {
    return getArrayOfObjectFromObjectByKeys(inputsByHash, [
      "id",
      "value",
      "viewValue",
    ]).filter((item) => {
      if (item.value === "") {
        return false;
      } else if (Array.isArray(item.value) && isEmpty(item.value)) {
        return false;
      } else {
        return true;
      }
    });
  };

  const clearAllTagValues = () => {
    const updatedInputsByHash = cloneDeep(inputsByHash);
    inputsById.forEach((id) => {
      updatedInputsByHash[id].value = "";
      updatedInputsByHash[id].viewValue = "";
    });
    refreshUrlQuery(updatedInputsByHash);
    setInputsByHash(updatedInputsByHash);
  };

  const clearTagValue = (id) => {
    const updatedInputsByHash = {
      ...inputsByHash,
      [id]: {
        ...inputsByHash[id],
        value: "",
        viewValue: "",
      },
    };
    setInputsByHash(updatedInputsByHash);
    refreshUrlQuery(updatedInputsByHash);
  };

  const refreshUrlQuery = (inputsByHash) => {
    urlQuery.push({
      smartFilter: JSON.stringify(getDataForQueryUrl(inputsByHash)),
    });
  };

  const getFilter = (excludeInputIds = null) => {
    let filter = {};

    inputsById.forEach((id) => {
      if (excludeInputIds && excludeInputIds.includes(id)) return
      const input = inputsByHash[id];
      const value = input.hasOwnProperty("submitAccessor")
        ? input.submitAccessor(input.value)
        : input.value;
      if (value === "") return;
      filter[id] = value;
    });

    return filter;
  };

  const applyConfig = (config) => {
    const updatedInputsByHash = cloneDeep(inputsByHash);

    inputsById.forEach((id) => {
      if (config.hasOwnProperty(id)) {
        updatedInputsByHash[id] = {
          ...updatedInputsByHash[id],
          value: config[id].value,
          viewValue: config[id].viewValue || config[id].value,
        };
      } else {
        updatedInputsByHash[id] = {
          ...updatedInputsByHash[id],
          value: getInitValue(inputsByHash[id]),
          viewValue: getInitViewValue(),
        };
      }
    });

    refreshUrlQuery(updatedInputsByHash);
    setInputsByHash(updatedInputsByHash);
  };

  return (
    <SmartFilterContext.Provider
      value={{
        inputsById: inputsById,
        inputsByHash: inputsByHash,
        init: init,
        applyFormInputs: applyFormInputs,
        clearAllTagValues: clearAllTagValues,
        clearTagValue: clearTagValue,
        getFilter: getFilter,
        applyConfig: applyConfig,
      }}
    >
      {children}
    </SmartFilterContext.Provider>
  );
};

export default SmartFilterProvider;
