    import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types'
import style from './TableHeader.module.scss';
import clsx from "clsx";

const styles = theme => ({
    root: {
      background: 'rgba(83, 196, 204, 0.2)'
    },
});


function TableHeader(props) {
    const {classes, columns, showSelect, rows, editableTable, collapsibleTable} = props;
    const { order, orderBy, handleSetSort, customRowActions } = props;

    return (
        <TableHead className={clsx({
            [classes.root]: true,
            [style.subTableHeader]: props.isSubTable,
        })}>
            <TableRow>
              { showSelect && rows.length > 0 && ( <TableCell className='checkbox-col' padding="checkbox" /> )}
                {collapsibleTable && <TableCell></TableCell>}
                {columns.map((col, index) => (
                    <TableCell
                        key={index}
                        sortDirection={orderBy === col.id ? order : false}
                        style={col.style || {}}
                    >
                        <TableSortLabel
                            active={orderBy === col.id}
                            direction={order}
                            onClick={handleSetSort.bind(this, col.id)}
                        >
                            {col.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
              { (customRowActions || editableTable) &&  (<TableCell className='actions-col' />) }
            </TableRow>
        </TableHead>
    );
}

TableHeader.propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    handleSetSort: PropTypes.func.isRequired,
}

TableHeader.defaultProps = {
    showActions: false,
    showSelect: false,
    editableTable: false,
    collapsibleTable: false
}


export default withStyles(styles)(TableHeader);