import { useNotificationCenter } from "NotificationCenter";
import withForm from "withForm";
import MainHeader from "components/templateParts/MainHeader";
import { editProfileConfig } from "config/resources/userProfileResource";
import style from "./UserEditContainer.module.scss";
import React from "react";
import dataProvider, { UPDATE } from "providers/dataProvider";
import { compose } from "redux";
import MainSingleFormContent from "components/templateParts/MainSingleFormContent/MainSingleFormContent";
import BaseForm from "components/ui_components/BaseForm/BaseForm";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useHistory } from "react-router-dom";
import authStorage from "providers/authStorage.js";
import { FormControlsGroup } from "components/ui_components/Forms";

const withFormConfig = editProfileConfig;
const inputsGroupsById = withFormConfig.inputsGroupsById;
const inputsGroupsByHash = withFormConfig.inputsGroupsByHash;
const access = authStorage.getAccess();

const UserProfileEdit = (props) => {
  const { inputsById, inputsByHash, handleInputs } = props;
  const { isFormValid, resetErrors, getSubmitData } = props;

  const notificationCenter = useNotificationCenter();
  const history = useHistory();

  const handleClickSubmit = () => {
    if (isFormValid()) {
      resetErrors();

      dataProvider(UPDATE, `mfa/profile/${access.userId}`, {
        data: getSubmitData(true, true, true),
      })
        .then((response) => {
          notificationCenter.show("Successfully updated", "info");
          history.push("/user-profile");
        })
        .catch((error) => {
          notificationCenter.show(error.message, "warning");
        });
    }
  };

  const handleClickCancel = () => {
    history.push("/user-profile");
  };

  return (
    <div className={style.root}>
      <MainHeader title={"Edit User Profile"} />

      <MainSingleFormContent>
        <BaseForm
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          inputsGroupsById={inputsGroupsById}
          inputsGroupsByHash={inputsGroupsByHash}
        />

        <FormControlsGroup>
          <ButtonBase
            onClick={handleClickSubmit}
            className="button formButton formButton__mainContained"
          >
            Save
          </ButtonBase>
          <ButtonBase
            onClick={handleClickCancel}
            className="button formButton formButton__secondaryOutlined"
          >
            Cancel
          </ButtonBase>
        </FormControlsGroup>
      </MainSingleFormContent>
    </div>
  );
};

export default compose(
  withForm({
    ...editProfileConfig,
    mode: "edit",
  })
)(UserProfileEdit);
