import React from 'react';
import Modal from '@material-ui/core/Modal';
import ButtonBase from '@material-ui/core/ButtonBase';
import styles from './PhysCreatePromptModal.module.scss';

export default function (props) {

    const {modalOpen, handleClickNo, handleClickYes, handleCloseModal} = props;

    const handleCustomClose = () => {
        handleCloseModal()
        handleClickNo()
    }

    return (
        <Modal open={modalOpen} onClose={handleCustomClose}>
          <div className={styles.root}>
            <h2 className="ttl ttl-2">
              Would you like to create a physician?
            </h2>
            <div className={`${'buttonGroup'} ${styles.buttonGroup}`}>
              <ButtonBase
                  className="button formButton formButton__secondaryOutlined"
                  onClick={handleCustomClose}>
                No
              </ButtonBase>
              <ButtonBase
                  className="button formButton formButton__mainContained"
                  onClick={handleClickYes}
              >
                Yes
              </ButtonBase>
            </div>
          </div>
        </Modal>
    );

}