import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BroomIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M17.2,5.5c0.9,0.4,1.8,0.9,2.5,1.6C22.3,5.4,24,3.4,24,0C20.2,0,18.1,3.3,17.2,5.5z M20.8,2.4
	c0.3-0.2,0.6-0.5,1-0.6c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2-0.1,0.3-0.3,0.3c-0.3,0.2-0.6,0.3-0.8,0.5
	c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3C20.7,2.6,20.8,2.5,20.8,2.4z M20,3.8
	c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3c-0.3,0.4-0.5,0.9-0.8,1.3c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.1,0-0.2,0
	c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3c0.2-0.5,0.5-1,0.8-1.4C19.6,3.7,19.8,3.7,20,3.8z M0.6,12.7c1.3,0.2,3.9,0.5,6.3-0.3
	c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.1,0.4c0,0.1-0.1,0.3-0.3,0.3c-1.2,0.4-2.5,0.6-3.8,0.6c-0.9,0-1.8-0.1-2.7-0.2
	c0.3,0.7,0.6,1.4,0.9,2C3,15.8,5.4,16,7,14.9c-1,1.3-2.3,2.3-3.7,3c0.8,0.9,1.6,1.6,2.5,2.3c2.1-0.8,6.2-2.8,7.4-6.1
	c-0.1,0.4-1.2,6-3.8,8.2c1.3,0.6,2.7,1,4.2,1.3c0.1-1,0.3-2.9,1.3-5.9c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0.1,0.3,0.3,0.3,0.5
	c-1,3-1.3,4.8-1.3,5.7c0,0,0,0,0,0c1.4,0.2,2.8,0.3,4.2,0.3c0,0-0.8-2.5,0-4.6c0.8-2.1,2.5-4.9,2.5-7.9c0-2.1-2.5-5.8-6.6-5.8
	c-6.6,0-7.4,3.8-9.9,4.6C1.7,11.2,0,9.9,0,9.9c0,0.9,0.1,1.9,0.4,2.8C0.5,12.7,0.5,12.7,0.6,12.7z"/>
    </SvgIcon>
);

export default BroomIcon;