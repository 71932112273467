import React, { useState } from 'react'
import { SmartTable } from 'components/ui_components/table'
import { Search } from 'components/ui_components'
import ButtonBase from '@material-ui/core/ButtonBase';
import helpers from 'utils/helpers';
import styles from './AddClientsModal.module.scss';
import { withNotificationCenter } from 'NotificationCenter'
import dataProvider, { UPDATE } from "providers/dataProvider";
import { compose } from 'redux'

const clientTableColConfig = [
  {
    id: 'id',
    label: 'Client ID'
  },
  {
    id: 'clientName',
    label: 'Name'
  },
  {
    id: 'clientPhone',
    label: 'Phone Number'
  }
]

const AddClientsModal = (props) => {
  const [searchString, setSearchString] = useState(null);
  const [selectedClients, setSelectedClients] = useState([]);
  const { physId, handleAddNewAssociatedClients, handleCloseModal, notificationCenter } = props;
  const { selected } = props;

  const searchCallback = (searchString) => {
    setSearchString(searchString);
  }

  const handleSelectedClients = (clients) => {
    setSelectedClients(clients);
  }

  const handleSubmit = () => {
    let data = {
      clientIds: helpers.getAllValues(selectedClients, 'id')
    }

    dataProvider(UPDATE, 'physicians', { data: data, id: physId })
    .then((response) => {
      notificationCenter.show(response.message, 'info')
      handleAddNewAssociatedClients()
      handleCloseModal();
    })
    .catch(error => {
      notificationCenter.show(error.message, 'warning')
    });
  }

  return (
    <React.Fragment>
      <div>
        <Search callback={searchCallback}/>
        <SmartTable
          columns={clientTableColConfig}
          resource={'clients'}
          order={'desc'}
          showSelect={true}
          showDelete={false}
          showSelectAll={false}
          searchString={searchString}
          searchBy="clientName" handleSubmit
          handleSelectRowCallBack={handleSelectedClients}
          defaultSelected={selected}
          useGlobalSelect={true}
        />
      </div>

      <div className={`${'buttonGroup'} ${styles.buttonGroup}`}>
        <ButtonBase
          className="button formButton formButton__secondaryOutlined"
          onClick={handleCloseModal}>
          Cancel
        </ButtonBase>
        <ButtonBase
          className="button formButton formButton__mainContained"
          onClick={handleSubmit}
        >
          Add
        </ButtonBase>
      </div>
    </React.Fragment>

  );

}

export default compose(
  withNotificationCenter()
)(AddClientsModal);