import React from 'react';
import MainHeader from 'components/templateParts/MainHeader';
import SearchBar from 'components/templateParts/SearchBar';
import SmartViewsSidebar from 'components/SmartViewsSidebar/SmartViewsSidebar'
import Footer from 'components/Footer/Footer';
import { BaseListComponent } from 'components/base'
import { smartFilterConfig, tableColConfig } from 'config/resources/freezerReverseLookup';
import ExportAllButton from 'components/ui_components/buttons/ExportAllButton.js';

const FreezerReverseLookup = (props) => {
  return (
    <div>
      <MainHeader title="Freezer Reverse Lookup"/>
      <SearchBar/>
      <SmartViewsSidebar isOpen={true}
                         resource="freezer_specs"
                         smartFilterConfig={smartFilterConfig}>
        <div className="mainContainer">
          <div className="mainContainer__inner">
            <BaseListComponent
              {...props}
              tableColConfig={tableColConfig}
              deleteConfirmationMessagePostfix="Freezer"
              handleRowClick={null}
              disableRowClick={true}
              toolbarExtraComponents={
                <ExportAllButton
                  resource='freezer_specs/export'
                />
              }
              showDelete={false}
              showSelect={false}
              showSelectAll={false}
            />
          </div>
          <Footer/>
        </div>
      </SmartViewsSidebar>
    </div>
  );
}

export default FreezerReverseLookup;