import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  helperText: {
    lineHeight: 1.1,
    letterSpacing: 0,
  }
}));

const NumberContainer = ({ input, inputId, handleInputs, value }) => {
  const classes = useStyles();

  let required = input.hasOwnProperty('yup');

  if (input.hasOwnProperty('required')) {
    required = input.required;
  }

  return (
    <TextField
      type="number"
      error={input.error}
      key={inputId}
      name={inputId}
      value={value || ''}
      inputProps={input.inputProps}
      required={required}
      label={input.label}
      helperText={input.error && input.errorMessage}
      onChange={handleInputs}
      FormHelperTextProps={{
        className: classes.helperText
      }}
    />
  );
}

export default NumberContainer;