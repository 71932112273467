import helpers from 'utils/helpers';
import moment from 'moment';

export default {
    inputsGroupsById: ['fromDate', 'toDate'],
    inputsGroupsByHash: {
        fromDate: {
            title: 'Date From',
            ids: [
                'fromDate'
            ]
        },
        toDate: {
            title: 'Date To',
            ids: [
                'toDate'
            ]
        }
    },
    inputsById: [
        'fromDate', 'toDate'
    ],
    inputsByHash: {
        fromDate: {
            label: '',
            type: 'date',
            submitValueAccessor: d => moment(d).format('M/DD/YYYY'),
            yup: helpers.getYupRequired('Date From')
        },

        toDate: {
            label: '',
            type: 'date',
            submitValueAccessor: d => moment(d).format('M/DD/YYYY'),
            yup: helpers.getYupRequired('Date To')
        }
    }
}