import React, { useState, useEffect, useCallback } from "react";
import styles from "./ReportsTable.module.scss";
import dataProvider, { GET_LIST } from "providers/dataProvider";
import ReportsTableRow from "./ReportsTableRow";
import { qaReportsConfig } from "config/resources/reportsResource";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ProgressBar from "components/ui_components/ProgressBar/ProgressBar";
import moment from "moment";
import PropTypes from "prop-types";
import authStorage from "providers/authStorage.js";

const ReportsTable = (props) => {
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const currentDate = new Date();
  const currentDateMinusMonth = moment(new Date()).subtract(1, "month");

  const loadData = useCallback(() => {
    dataProvider(GET_LIST, props.listResource)
      .then(({ data, total }) => {
        setDataLoaded(true);
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.listResource]);

  useEffect(() => {
    loadData();
    authStorage.on(authStorage.AUTHENTICATE, loadData);
    return () => authStorage.off(authStorage.AUTHENTICATE, loadData);
  }, [loadData]);

  return (
    <Table aria-label="simple table" className={styles.root}>
      <TableBody>
        {dataLoaded ? (
          data.map((item) => (
            <ReportsTableRow
              key={item.id}
              downloadResource={props.downloadResource}
              reportCode={item.reportCode}
              reportDesc={item.reportDesc}
              withFormConfig={{
                inputsByHash: qaReportsConfig.inputsByHash,
                inputsById: qaReportsConfig.inputsById,
                initData: {
                  toDate: currentDate,
                  fromDate: currentDateMinusMonth,
                },
              }}
              inputsGroupsByHash={qaReportsConfig.inputsGroupsByHash}
              inputsGroupsById={qaReportsConfig.inputsGroupsById}
            />
          ))
        ) : (
          <TableRow>
            <TableCell className={styles.EmptyRow} colSpan={4}>
              <div className={styles.dataLoadingWrap}>
                Data loading
                <ProgressBar />
              </div>
            </TableCell>
          </TableRow>
        )}

        {dataLoaded && data.length === 0 && (
          <TableRow>
            <TableCell className={styles.EmptyRow} colSpan={4}>
              <div className={styles.dataLoadingWrap}>No data found</div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default ReportsTable;

ReportsTable.propTypes = {
  listResource: PropTypes.string.isRequired,
};
