import React, { PureComponent } from 'react';
import MainHeader from 'components/templateParts/MainHeader';
import { compose } from 'redux'
import dataProvider, { CREATE } from "providers/dataProvider";
import withForm from 'withForm';
import styles from "./RequisitionsCreateContainer.module.scss";
import ButtonBase from '@material-ui/core/ButtonBase';
import WidgetPatientContainer from './WidgetPatientContainer';
import { withNotificationCenter } from 'NotificationCenter'
import { withRouter } from 'react-router-dom'
import { WidgetGroup } from 'components/ui_components/Widgets'
import withDropZone from 'hocs/withDropZone.js';
import DropZone from 'components/ui_components/DropZone/DropZone.js';
import WidgetType1 from 'components/ui_components/Widgets/WidgetType1/WidgetType1.js';
import { uploadReqFileByDoctype } from 'utils';
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar'
import { dateOfBirthValidation } from 'utils';
import {
  inputsByHash,
  inputsById,
  inputsGroupsById,
  inputsGroupsByHash
} from "config/resources/requisitionsResource/create";
import BaseForm from 'components/ui_components/BaseForm/BaseForm.js';
import {
  convertDateCollectedFromToServerUTC,
  getClientTzFromExtraSelectedOptionData,
} from 'components/pages/requisitions/RequisitionsCreate/reqCreateHelpers';

const withFormConfig = {
  inputsByHash,
  inputsById,
};
const resource = 'requisitions';

class RequisitionsCreateContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedPatient: null,
      isNewPatient: false,
      selectedPatientIsEdited: false,
      isFormSubmitting: false,
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { notificationCenter, isFormValid, resetErrors, getSubmitData, getExtraSelectedOptionData } = this.props;

    if (isFormValid()) {
      resetErrors();

      let allFieldsData = getSubmitData(false);

      try {
        dateOfBirthValidation(
          allFieldsData.patientDobYear,
          allFieldsData.patientDobMonth,
          allFieldsData.patientDobDate,
        );
      }
      catch (e) {
        notificationCenter.show(e.message, 'error')
        return;
      }

      let dateCollectedInUTC = null;
      let extraSelectedOptionData = getExtraSelectedOptionData();
      let clientTz = getClientTzFromExtraSelectedOptionData(extraSelectedOptionData);
      try {
        dateCollectedInUTC = convertDateCollectedFromToServerUTC(
          allFieldsData,
          clientTz,
        )
      }

      catch (e) {
        notificationCenter.show(e.message, 'error');
        return;
      }

      try {
        dateOfBirthValidation(
          allFieldsData.dateCollectedYear,
          allFieldsData.dateCollectedMonth,
          allFieldsData.dateCollectedDate,
        );
      }
      catch (e) {
        notificationCenter.show(e.message, 'error')
        return;
      }

      this.setState({
        isFormSubmitting: true
      });


      let data = {
        ...getSubmitData(),
        patientDob: `${allFieldsData.patientDobMonth}/${allFieldsData.patientDobDate}/${allFieldsData.patientDobYear}`,
        dateCollected: dateCollectedInUTC,
      }

      if (this.state.selectedPatient && !this.state.isNewPatient) {
        data.patientId = this.state.selectedPatient.id;
      }

      dataProvider(CREATE, resource, { data: data })
      .then((response) => {
        const reqID = response.data.id;

        notificationCenter.show('Successfully created', 'info')

        if (this.props.isValidDropZone({ handleErrors: true })) {
          uploadReqFileByDoctype(reqID, this.props.dropZoneFiles, 'SCANREQ')
          .then(() => {
            this.setState({
              isFormSubmitting: false
            });
            notificationCenter.show('Report Uploaded', 'info')
            this.props.history.push(`/${resource}/` + reqID + '/show');
          })
          .catch(error => {
            this.setState({
              isFormSubmitting: false
            });
            notificationCenter.show(error.message, 'warning')
            this.props.history.push(`/${resource}/` + reqID + '/show');
          });
        } else {
          this.setState({
            isFormSubmitting: false
          });
          this.props.history.push(`/${resource}/` + reqID + '/show');
        }
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
        this.setState({
          isFormSubmitting: false
        });
      });
    }
  }

  handleClickSuggestedPatient = (patient) => {
    this.setState({
      selectedPatient: patient,
      selectedPatientIsEdited: false
    })
    let updateInputs = [];

    inputsGroupsByHash.createPatient.inputsById.forEach(id => {
      updateInputs.push({ id: id, value: patient[id] })
    })
    this.props.handleBulkInputs(updateInputs);
  }

  handleNewPatientCheck = (e) => {
    this.setState((prevState) => {
      return {
        isNewPatient: !prevState.isNewPatient
      }
    })
  }

  handleEditSelectedPatient = () => {
    this.setState({
      selectedPatientIsEdited: true
    })
  }

  render() {
    const { inputsByHash, inputsById } = this.props;
    const formSubmitData = this.props.getSubmitData(false);

    const baseFormStyles = {
      rootStyles: 'max-width: none;',
      inputWrapStyles: 'max-width: 180px; width: 100%;',
      groupStyles: 'padding: 0;',
      groupTitleStyles: 'font-weight: 700; font-size: 18px; margin: 10px 0 0;',
    }

    return (
      <div className={styles.root}>
        <MainHeader title="Add Requisition"/>
        <form onSubmit={this.handleSubmit}>
          {this.state.isFormSubmitting && <ProgressBar/>}
          <div className={styles.widgetsWrapper}>
            <div className={styles.container}>
              <WidgetGroup>
                {inputsById.length > 0 && inputsGroupsById.map((groupID) => {
                  if (groupID === 'createPatient') {
                    let submitData = {};

                    inputsGroupsByHash[groupID].inputsById.forEach(id => {
                      if (id === 'patientRaceCode' || id === 'patientEthnicityCode') return;
                      submitData[id] = formSubmitData[id]
                    })

                    return <WidgetPatientContainer
                      baseFormStyles={baseFormStyles}
                      key={groupID}
                      title={inputsGroupsByHash[groupID].title}
                      inputsByHash={inputsByHash}
                      inputsById={inputsGroupsByHash[groupID].inputsById}
                      inputsGroupsByHash={inputsGroupsByHash[groupID].inputsGroupsByHash}
                      inputsGroupsById={inputsGroupsByHash[groupID].inputsGroupsById}
                      handleInputs={this.props.handleInputs}
                      submitData={submitData}
                      handleSelectSuggestedPatient={this.props.handleBulkInputs}
                      handleClickSuggestedPatient={this.handleClickSuggestedPatient}
                      handleNewPatientCheck={this.handleNewPatientCheck}
                      handleEditSelectedPatient={this.handleEditSelectedPatient}
                      isNewPatient={this.state.isNewPatient}

                    />
                  } else {
                    return (
                      <WidgetType1
                        key={groupID}
                        title={inputsGroupsByHash[groupID].title}
                        content={
                          <BaseForm
                            {...baseFormStyles}
                            inputsByHash={inputsByHash}
                            inputsGroupsByHash={inputsGroupsByHash[groupID].inputsGroupsByHash}
                            inputsGroupsById={inputsGroupsByHash[groupID].inputsGroupsById}
                            handleInputs={this.props.handleInputs}
                          />
                        }
                        styles={`width: 100%;`}
                      />)
                  }
                })}

                <WidgetType1
                  title="Scanned Requisition"
                  content={<DropZone
                    dropZoneError={this.props.dropZoneError}
                    handleDropFiles={this.props.handleDropFiles}
                    dropZoneFiles={this.props.dropZoneFiles}
                    isDropZoneUploading={this.props.isDropZoneUploading}
                  />}
                >

                </WidgetType1>
              </WidgetGroup>
            </div>
          </div>

          <div className={styles.controls}>
            <ButtonBase
              type="submit"
              className="button formButton formButton__mainContained"
            >
              Create
            </ButtonBase>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withNotificationCenter(),
  withForm(withFormConfig),
  withDropZone
)(RequisitionsCreateContainer)