import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BurgerButton from "components/ui_components/buttons/BurgerButton.js";
import { compose } from "redux";
import { connect } from "react-redux";
import { setSidebarVisibility } from "actions";
import LogoIcon from '../../icons/LogoIcon'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      background: "#42484C",
      height: "48px",
      alignItems: "center",
      justifyContent: "space-between",
      overflow: "hidden",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
      paddingRight: theme.mainLayout.paddingRight,
    },
    logo: {
      width: "45px",
      height: "45px",
    },
    logoWrap: {
      color: "white",
      whiteSpace: "nowrap",
      fontSize: "19px",
      letterSpacing: "1.2px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "0 0 0 2px",
      "& span": {
        padding: "0 0 0 10px",
      },
    },
  };
});

const MobileTopMenu = ({ isSidebarOpen, setSidebarVisibility }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.logoWrap}>
        <LogoIcon id="mobile" className={classes.logo}/>
        <span>PHD</span>
      </div>
      <BurgerButton
        id={"mobileSidebarTrigger"}
        hoverAnimation={false}
        isActive={isSidebarOpen}
        onClick={() => {
          isSidebarOpen
            ? setSidebarVisibility(false)
            : setSidebarVisibility(true);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ layout }) => ({
  isSidebarOpen: layout.isSidebarOpen,
});

export default compose(connect(mapStateToProps, { setSidebarVisibility }))(
  MobileTopMenu
);
