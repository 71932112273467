import React, { PureComponent } from 'react'
import { SmartTable } from 'components/ui_components/table'
import { compose } from 'redux'
import withForm from 'withForm';
import dataProvider, { CREATE } from "providers/dataProvider";
import AddWidgetContainer from '../AddWidget/AddWidgetContainer';
import styles from './BillingNotesGroupContainer.module.scss';
import Can from 'Can';
import { userPermissions } from 'config/userRoles';
import helpers from 'utils/helpers';
import { withNotificationCenter } from 'NotificationCenter'
import { userRoles } from '../../../../../../../config/userRoles';

class BillingNotesGroupContainer extends PureComponent {

  constructor(props) {
    super(props);

    this.permissionsToEdit = helpers.can(userPermissions.reqEntity.show.reportsDelivery.edit);

    this.state = {
      dataLoading: false
    }
  }

  handleSubmit = () => {
    const { notificationCenter, isFormValid, getSubmitData, resetErrors, reqID, clearInputs } = this.props;

    resetErrors().then(() => {
      if (!isFormValid()) return;

      this.setState({
        dataLoading: true
      })

      dataProvider(CREATE, `requisitions/${reqID}/bill_notes`, { data: getSubmitData() })
      .then((response) => {
        this.setState({
          dataLoading: false
        })
        notificationCenter.show('Successfully created', 'info')
        this.smartTable.loadData();
        clearInputs();
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
        this.setState({
          dataLoading: false
        })
      });
    })


  }

  render() {
    const { handleInputs, inputsById, inputsByHash, reqID, tableConfig } = this.props;
    const { dataLoading } = this.state;

    return (
      <div className={styles.root}>
        <Can allowedRoles={userPermissions.reqEntity.show.reportsDelivery.edit}>
          <AddWidgetContainer
            handleInputs={handleInputs}
            inputsById={inputsById}
            inputsByHash={inputsByHash}
            dataLoading={dataLoading}
            handleSubmit={this.handleSubmit}
            widgetTitle="Billing Notes"
            reqID={reqID}
            inputWrapStyles={`width: 100%;`}
          />
        </Can>
        <Can allowedRoles={[
          userRoles.admin,
          userRoles.client,
          userRoles.management,
          userRoles.physician,
          userRoles.gdbstaff,
        ]}>
          <div className={styles.tableWrap}>
            <SmartTable
              resource={`requisitions/${reqID}/bill_notes`}
              showPagination={false}
              onRef={ref => (this.smartTable = ref)}
              columns={tableConfig}
              showSelect={this.permissionsToEdit}
              showDelete={this.permissionsToEdit}
              showSelectAll={this.permissionsToEdit}
            />
          </div>
        </Can>
      </div>
    )
  }
}

export default compose(
  withNotificationCenter(),
  withForm()
)(BillingNotesGroupContainer)