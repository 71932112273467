import React from "react";
import styles from "./PrintSinglePreviewLayout.module.scss";
import { concatClientCityStateZip } from "utils/textFormat";
import { getSalesRepColumnVal } from "config/resources/requisitionsResource/tableCol";

const PrintSinglePreviewLayoutComponent = (props) => {
  const { physList, clientData } = props;
  const physData = physList[0];

  return (
    <div className={styles.root}>
      <ul>
        <li>
          {physData.physLastName}, {physData.physFirstName},{" "}
          {physData.physTitle}
        </li>
        <li>
          {clientData.legacyClientId} {clientData.clientName}
        </li>
        <li>
          {clientData.clientAddr1} {clientData.clientAddr2}
        </li>
        <li>
          {concatClientCityStateZip(
            clientData.clientCity,
            clientData.clientState,
            clientData.clientZip
          )}
        </li>
        {clientData.clientCountry === "United States" ? null : 
            <li>{clientData.clientCountry}</li>
          }
        <li>Phone: {clientData.clientPhone}</li>
        {clientData.clientFaxEmail && <li>Fax: {clientData.clientFaxEmail}</li>}
        <li>{getSalesRepColumnVal(clientData.salesJson)}</li>
      </ul>
    </div>
  );
};

export default PrintSinglePreviewLayoutComponent;
