import React from 'react';
import BaseForm from 'components/ui_components/BaseForm/BaseForm.js';

const PhysiciansCreateStep1Container = (props) => {
    const {inputsGroupsById, inputsGroupsByHash, handleInputs, inputsById, inputsByHash} = props;

    return (
      <BaseForm
        inputsById={inputsById}
        inputsByHash={inputsByHash}
        handleInputs={handleInputs}
        inputsGroupsById={inputsGroupsById}
        inputsGroupsByHash={inputsGroupsByHash}
      />
    );
}

export default PhysiciansCreateStep1Container;