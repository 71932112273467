import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CigaretteIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M0,18.6H18v3.6H0V18.6z M22.2,18.6H24v3.6h-1.8V18.6z M19.2,18.6H21v3.6h-1.8V18.6z M20.2,8.7
	C20.9,8,21.4,7,21.4,5.8c0-2.2-1.8-4-4-4v1.8c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2v1.8c2.7,0,4.8,2.2,4.8,4.9v2.7H24v-2.7
	C24,12,22.4,9.8,20.2,8.7z M16.8,11.6H15c-1.2,0-2.2-1.2-2.2-2.4s1-2.1,2.2-2.1V5.4c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4h1.8
	c1.3,0,2.4,0.9,2.4,2.5v1.6H21v-2C21,13.3,19.1,11.6,16.8,11.6z"/>
    </SvgIcon>
);

export default CigaretteIcon;