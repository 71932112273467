const getArrayOfObjectFromObjectByKeys = (inputsByHash, needleKeys) => {
  const response = [];

  Object.values(inputsByHash).forEach(objectValue => {
    const obj = {};
    needleKeys.forEach((needleKey) => {
        obj[needleKey] = objectValue[needleKey];
    });
    response.push(obj);
  });
  
  return response;
};

export { getArrayOfObjectFromObjectByKeys };