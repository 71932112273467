import React, { useState } from "react";
import withForm from "withForm";
import dataProvider, { UPDATE } from "providers/dataProvider";
import { withNotificationCenter } from "NotificationCenter";
import { compose } from "redux";
import { WidgetEditable } from "components/ui_components/Widgets";
import BaseInputList from "components/ui_components/BaseInputList/BaseInputList";
import { useNotificationCenter } from "NotificationCenter";
import { BaseWidgetViewList } from "components/ui_components/Widgets/WidgetViewLists";
import { clientICDNotesConfig } from "config/resources/requisitionsResource/billingTab";

const ClientICDNotes = ({ legacyIcdNote, ...props }) => {
  const {
    reqData,
    inputsByHash,
    inputsById,
    handleInputs,
    getSubmitData,
    isFormValid,
    resetErrors,
    setReqDataByKey,
  } = props;

  const notificationCenter = useNotificationCenter();
  const [isEditMode, setEditMode] = useState(false);

  const handleSubmit = async () => {
    resetErrors();

    if (isFormValid())
      dataProvider(
        UPDATE,
        `requisitions/${reqData.id}/patient_hists/${reqData.patientHistId}`,
        { data: getSubmitData() }
      )
        .then(({ data, message }) => {
          notificationCenter.show(message, "info");
          setReqDataByKey(data, 'legacyIcdNote');
          setEditMode(false);
        })
        .catch((error) => {
          notificationCenter.show(error.message, "error");
        });
  };

  return (
    <WidgetEditable
      title={"Client ICD Notes"}
      wrapStyles={`padding: 20px 0 0;`}
      handleSubmit={handleSubmit}
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      viewContent={
        <BaseWidgetViewList
          ids={clientICDNotesConfig.viewConfig.inputsById}
          inputsByHash={clientICDNotesConfig.viewConfig.inputsByHash}
          data={reqData}
          showIcons={false}
        />
      }
      editContent={
        <BaseInputList
          inputsByHash={inputsByHash}
          inputsById={inputsById}
          handleInputs={handleInputs}
        />
      }
    />
  );
};

export default compose(withForm(), withNotificationCenter())(ClientICDNotes);
