import {
    OPEN_AUTH_MODAL,
    CLOSE_AUTH_MODAL
} from './../config/actionTypes';


const initState = {
    authModalIsOpen: false
}

export default (previousState = initState, action) => {
    switch (action.type) {
        case OPEN_AUTH_MODAL:
            return {
                ...previousState,
                authModalIsOpen: true
            }
        case CLOSE_AUTH_MODAL:
            return {
                ...previousState,
                authModalIsOpen: false
            }
        default:
            return previousState
    }
}