import React from "react";
import PropTypes from "prop-types";
import { DownloadIcon } from "icons";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import WidgetType1 from "components/ui_components/Widgets/WidgetType1/WidgetType1";
import { isMobile } from "react-device-detect";
import { SmartTable } from "components/ui_components/table";
import {
  sectionsConfig,
  tableConfig,
} from "config/resources/requisitionsResource/patientHistoryTab.js";
import { Box, Typography, Grid } from "@material-ui/core";
import WidgetGroup from "components/ui_components/Widgets/WidgetGroup/WidgetGroup";
import useDownloader from "hooks/useDownloader";

const PatientDocs = ({ patientId }) => {
  const { handleDownloadClick, dataLoading } = useDownloader({
    resourceAccessor: (row) => {
      console.log(row);
      return isMobile
        ? `requisitions/${row.reqId}/docs/${row.id}/view`
        : `requisitions/${row.reqId}/download_docs/${row.id}`;
    },
  });

  let customRowActions = [
    {
      icon: isMobile ? <VisibilityOutlinedIcon /> : <DownloadIcon />,
      handleOnClick: handleDownloadClick,
      tooltipText: isMobile ? "View report" : "Download reports",
      showProgress: dataLoading,
    },
  ];

  return (
    <Grid container>
      <Grid item sm={12} md={6}>
        <WidgetGroup>
          <WidgetType1 title={"Patient Document History"}>
            {sectionsConfig.map((section) => {
              return (
                <Box mt={"20px"}>
                  <Typography variant={"p"}>{section.title}</Typography>
                  <SmartTable
                    resource={`patients/${patientId}/docs`}
                    filter={{ docTypeCode: section.docTypeCode }}
                    showPagination={false}
                    showSelect={false}
                    showDelete={false}
                    showSelectAll={false}
                    hasExternalInitData={true}
                    columns={tableConfig}
                    customRowActions={customRowActions}
                  />
                </Box>
              );
            })}
          </WidgetType1>
        </WidgetGroup>
      </Grid>
    </Grid>
  );
};

PatientDocs.propTypes = {
  patientId: PropTypes.string.isRequired,
};

export default PatientDocs;
