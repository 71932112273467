import React, { PureComponent } from 'react'
import styles from "./DemographicWidgetContainer.module.scss";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withNotificationCenter } from 'NotificationCenter'
import dataProvider, { UPDATE_LIST } from "providers/dataProvider";
import PropTypes from "prop-types";
import helpers from 'utils/helpers';
import { userPermissions } from 'config/userRoles';
import { compose } from 'redux';
import StyledCheckbox from 'components/ui_components/StyledCheckbox/StyledCheckbox';
import { WidgetType1 } from 'components/ui_components/Widgets';

class DemographicWidgetContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.timer = null

    this.state = {
      checked: this.props.isDemQcPass === 1
    }
  }

  handleChange = () => {
    this.autoSave();
    this.setState((prevState) => {
      return {
        checked: !prevState.checked
      }
    })
  }

  autoSave = (data, resource, successfullyMessage) => {
    const { notificationCenter } = this.props;

    if (this.timer) {
      clearTimeout(this.timer)
    }

    this.timer = setTimeout(() => {
      dataProvider(UPDATE_LIST,
        `requisitions/${this.props.reqID}/dem_qc`,
        { data: { isDemQcPass: this.state.checked ? 1 : 0 } })
      .then((response) => {
        notificationCenter.show('Successfully updated', 'info')
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
      });
    }, 500)
  }


  render() {
    return (
      <div className={styles.root}>
        <WidgetType1
          title="Demographic QC"
          content={<FormControlLabel control={<StyledCheckbox
            checked={this.state.checked}
            onChange={this.handleChange}
            disabled={!helpers.can(userPermissions.reqEntity.show.reportsDelivery.edit)}
            value="checked"
          />} label="Pass"/>}
          widgetContentStyles={`margin: 20px 0 0;`}
        />
      </div>
    )
  }
}

DemographicWidgetContainer.propTypes = {
  reqID: PropTypes.string.isRequired,
  isDemQcPass: PropTypes.number.isRequired,

}

export default compose(
  withNotificationCenter()
)(DemographicWidgetContainer)