import React from "react";
import { SmartTable } from "components/ui_components/table";
import { compose } from "redux";

let smartTableRef = {};

const tableColConfig = [
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "userEmail",
    label: "Email",
  },
  {
    id: "lastLoginDate",
    label: "Last Login Date",
  }
];

const AuthorizedUserTab = ({
  clientId,
}) => {
  return (
    <React.Fragment>
      <SmartTable
        onRef={(ref) => (smartTableRef.current = ref)}
        resource={`clients/${clientId}/users`}
        perPage={-1}
        orderBy="userId"
        showPagination={false}
        showSelect={false}
        showSelectAll={false}
        columns={tableColConfig}
      />
    </React.Fragment>
  );
};

export default compose()(AuthorizedUserTab);
