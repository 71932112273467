export default function (debug) {
    return new Promise((resolve, reject) => {
        if (debug) {
            setTimeout(() => {
                reject({
                    response: {
                        status: debug.responseWithErrorStatus   
                    }
                })
              }, 100)
        }
        else {
            resolve(true);   
        }
    })
}