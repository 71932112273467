import React from "react";
import dataProvider, { GET_ONE } from "providers/dataProvider";
import cloneDeep from "lodash/cloneDeep";
import helpers from "utils/helpers.js";
import { withAuthCenter } from "AuthCenter";

let yup = require("yup");

const withForm = (config) => (WrappedComponent) => {
  class HOC extends React.Component {
    _isMounted = false;

    constructor(props) {
      super(props);

      this.config = config || this.props.withFormConfig || {};
      this.options = this.config.options || {};
      this.extraData = this.options.extraData || {};

      if (!this.config.hasOwnProperty("inputsById")) {
        console.error("inputsById is required in withForm HOC");
        return;
      }
      if (!this.config.hasOwnProperty("inputsByHash")) {
        console.error("inputsByHash is required in withForm HOC");
        return;
      }

      this.state = {
        inputsById: [],
        dataLoaded: false,
        inputsByHash: {},
      };

      this.schema = yup.object().shape(this.getSchema(this.config));
    }

    componentDidMount() {
      this._isMounted = true;
      this.initState().then(() => {
        if (this.config.initData) {
          this.setInitValues(this.config.initData).then(() => {
            this.initRelativeInputs();
            this.handleRelativeFieldVisibility();
            this.initFieldVisibilityByLoadedData(this.config.initData);
          });
        }
        if (this.config.mode === "edit") {
          this.loadData();
        }
        this.initSingleValidation();
      });
    }

    componentDidUpdate(prevProps) {
      if (prevProps.authCenter.token !== this.props.authCenter.token) {
        this.initState().then(() => {
          if (this.config.initData) {
            this.setInitValues(this.config.initData);
            this.initRelativeInputs();
          }
          if (this.config.mode === "edit") {
            this.loadData();
          }
          this.initSingleValidation();
        });
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    initSingleValidation = () => {
      let updatedInputsByHash = { ...this.state.inputsByHash };

      this.state.inputsById.forEach((id) => {
        if (!this.config.inputsByHash[id].hasOwnProperty("yup")) return;
        if (!this.config.inputsByHash[id].hasOwnProperty("singleValidation"))
          return;

        let yupObj = { [id]: this.config.inputsByHash[id].yup };

        updatedInputsByHash[id].yupSchema = yup.object().shape(yupObj);
      });

      this.setState({
        inputsByHash: updatedInputsByHash,
      });
    };

    loadData = () => {
      console.log("loadData");
      let self = this;
      dataProvider(GET_ONE, this.props.resource, { id: this.props.id }).then(
        ({ data, total }) => {
          if (this._isMounted) {
            self.setInitValues(data).then(() => {
              self.initRelativeInputs();
              self.handleRelativeFieldVisibility();
              self.initFieldVisibilityByLoadedData(data);
            });
          }
        }
      );
    };

    handleRelativeFieldVisibility = () => {
      this.state.inputsById.forEach((id) => {
        const input = this.state.inputsByHash[id];
        if (!input.hasOwnProperty("relativeHideVisibilityInputs")) return;
        const updatedInputsByHash = cloneDeep(this.state.inputsByHash);

        input.relativeHideVisibilityInputs.forEach((relativeInputID) => {
          const relativeInput = updatedInputsByHash[relativeInputID];
          if (relativeInput.hasOwnProperty("hideVisibilityAccessor")) {
            relativeInput.visibility = !relativeInput.hideVisibilityAccessor(
              input.value, this.extraData
            );
          }
        });

        this.setState({
          inputsByHash: updatedInputsByHash,
        });
      });
    };

    initFieldVisibilityByLoadedData = (data) => {
      let updatedInputsByHash = cloneDeep(this.state.inputsByHash);

      this.state.inputsById.forEach((id) => {
        const input = this.state.inputsByHash[id];
        if (!input.hasOwnProperty("fieldVisibilityAccessorByLoadedData"))
          return;

        updatedInputsByHash[
          id
        ].visibilityByLoadedData = input.fieldVisibilityAccessorByLoadedData(data);
      });

      this.setState({
        inputsByHash: updatedInputsByHash,
      });
    };

    setInitValues = (data) => {
      let self = this;
      return new Promise(function (resolve) {
        let updatedInputsByHash = { ...self.state.inputsByHash };

        self.state.inputsById.forEach((id) => {
          const input = updatedInputsByHash[id];
          let value = data[id];

          if (input.hasOwnProperty("setInitValueAccessor")) {
            value = input.setInitValueAccessor(data);
          }
          updatedInputsByHash[id].value = value;
        });

        self.setState(
          {
            inputsByHash: updatedInputsByHash,
          },
          () => {
            resolve();
          }
        );
      });
    };

    getSchema = (options = {}) => {
      const { skipInvisibleFields = true } = options;
      let yupObj = {};

      this.config.inputsById.forEach((id) => {
        const input = this.config.inputsByHash[id];
        if (!input) {
          console.error(`Key ${id} missing in inputsByHash config`);
          console.error("Current inputsByHash config:");
          console.error(this.config.inputsByHash);
        }

        if (
          !input.hasOwnProperty("yup") ||
          (input.hasOwnProperty("singleValidation") && input.singleValidation) ||
          (skipInvisibleFields && this.state.inputsByHash[id] && !this.state.inputsByHash[id].visibility)
        ) {
          return;
        }

        yupObj[id] = this.config.inputsByHash[id].yup;
      });

      return yupObj;
    };

    clearInputsById = (ids) => {
      this.handleRelativeFieldVisibility();

      let self = this;
      return new Promise(function (resolve) {
        let updatedInputsByHash = cloneDeep(self.state.inputsByHash);

        ids.forEach((id) => {
          let input = updatedInputsByHash[id];
          let value = "";

          if (input.type === "checkbox" || input.subType === "multi") {
            value = [];
          }
          if (input.type === "singleCheckbox") {
            value = 0;
          }

          updatedInputsByHash[id].value = value;
        });

        self.setState(
          {
            inputsByHash: updatedInputsByHash,
          },
          resolve
        );
      });
    };

    clearInputs = (clearToInitValues = false) => {
      this.handleRelativeFieldVisibility();

      let self = this;
      return new Promise(function (resolve) {
        let updatedInputsByHash = cloneDeep(self.state.inputsByHash);

        self.state.inputsById.forEach((id) => {
          let input = updatedInputsByHash[id];
          let value = "";

          if (input.type === "checkbox" || input.subType === "multi") {
            value = [];
          }
          if (input.type === "singleCheckbox") {
            value = 0;
          }

          updatedInputsByHash[id].value = value;

          if (
            clearToInitValues &&
            self.config.inputsByHash[id].hasOwnProperty("value")
          ) {
            if (helpers.isFunction(self.config.inputsByHash[id].value)) {
              updatedInputsByHash[id].value = self.config.inputsByHash[
                id
              ].value();
            } else {
              updatedInputsByHash[id].value =
                self.config.inputsByHash[id].value;
            }
          }
        });

        self.setState(
          {
            inputsByHash: updatedInputsByHash,
          },
          resolve
        );
      });
    };

    initState = () => {
      let self = this;
      return new Promise(function (resolve, reject) {
        let inputsByHash = {};
        self.config.inputsById.forEach((id) => {
          const input = self.config.inputsByHash[id];

          let value = "";
          if (input.hasOwnProperty("value")) {
            if (helpers.isFunction(input.value)) {
              value = input.value();
            } else {
              value = input.value;
            }
          } else if (input.type === "checkbox") {
            value = [];
          } else if (input.type === "singleCheckbox") {
            value = 0;
            if (input.hasOwnProperty("value")) {
              value = input.value;
            }
          }

          if (input.hasOwnProperty("isDisabled")) {
            input.disabled = input.isDisabled(self.config.initData)
          }

          inputsByHash[id] = {
            ...input,
            value: value,
            visibility: true,
          };
        });

        self.setState(
          {
            inputsByHash: inputsByHash,
            inputsById: self.config.inputsById,
            dataLoaded: true,
            isConfigInit: true,
          },
          () => {
            resolve();
          }
        );
      });
    };

    handleInputs = (e, extraSelectedOptionData = null) => {
      let self = this;
      const targetValue = e.target.value;
      const targetName = e.target.name;

      return new Promise(function (resolve, reject) {
        self.handleRelativeInputs(targetName, targetValue);
        self.handleRelativeAutoFillInputs(targetName, targetValue);

        self.setState(
          (prevState) => {
            let inputData = {
              ...prevState.inputsByHash[targetName],
              value: targetValue,
            };

            if (extraSelectedOptionData) {
              inputData.extraSelectedOptionData = extraSelectedOptionData;
            }

            return {
              inputsByHash: {
                ...prevState.inputsByHash,
                [targetName]: inputData,
              },
            };
          },
          () => {
            self.handleRelativeFieldVisibility();
            resolve();
          }
        );
      });
    };

    handleBulkInputs = (inputs) => {
      this.handleRelativeFieldVisibility();

      inputs.forEach((input) => {
        this.setState((prevState) => ({
          inputsByHash: {
            ...prevState.inputsByHash,
            [input.id]: {
              ...prevState.inputsByHash[input.id],
              value: input.value || "",
            },
          },
        }));
      });
    };

    handleRelativeAutoFillInputs = (inputID, inputValue) => {
      const input = this.state.inputsByHash[inputID];
      if (!input.hasOwnProperty("relativeAutoFillInputs")) return;

      const updatedInputsByHash = cloneDeep(this.state.inputsByHash);

      input.relativeAutoFillInputs.forEach((relativeInputID) => {
        updatedInputsByHash[relativeInputID].relativeAutoFillInputsData = {
          ...inputValue,
        };
      });

      this.setState({
        inputsByHash: updatedInputsByHash,
      });
    };

    initRelativeInputs = () => {
      this.state.inputsById.forEach((id) => {
        const input = this.state.inputsByHash[id];
        if (!input.hasOwnProperty("relativeInputs")) return;
        if (input.value === "" || input.value === null) return;

        const updatedInputsByHash = cloneDeep(this.state.inputsByHash);
        input.relativeInputs.forEach((relativeInputID) => {
          updatedInputsByHash[relativeInputID].relativeInputsData = {
            ...updatedInputsByHash[relativeInputID].relativeInputsData,
            [id]: input.relatedValue || input.value,
          };
        });

        this.setState({
          inputsByHash: updatedInputsByHash,
        });
      });
    };

    handleRelativeInputs = (inputID, inputValue) => {
      const input = this.state.inputsByHash[inputID];
      if (!input.hasOwnProperty("relativeInputs")) return;

      let updatedInputsByHash;

      try {
        updatedInputsByHash = cloneDeep(this.state.inputsByHash);
      } catch {
        updatedInputsByHash = { ...this.state.inputsByHash };
      }

      if (input.resetRelativeInputs) {
        input.resetRelativeInputs.forEach((relativeInputID) => {
          updatedInputsByHash[relativeInputID].value = null;
          updatedInputsByHash[relativeInputID].value =
            input.subType === "multi" ? [] : "";
        });
      }



      if (inputValue === '') {
        input.relativeInputs.forEach((relativeInputID) => {
          delete updatedInputsByHash[relativeInputID].relativeInputsData[inputID]
        });
      }
      else {
        input.relativeInputs.forEach((relativeInputID) => {
          updatedInputsByHash[relativeInputID].relativeInputsData = {
            ...updatedInputsByHash[relativeInputID].relativeInputsData,
            [inputID]: input.relatedValue || inputValue,
          };
        });
      }

      this.setState({
        inputsByHash: updatedInputsByHash,
      });
    };

    getInputValues = () => {
      let inputValues = {};

      this.state.inputsById.forEach((id) => {
        inputValues[id] = this.state.inputsByHash[id].value;
      });

      return inputValues;
    };

    isInputValid = (id, handleErrors = true) => {
      try {
        return this.state.inputsByHash[
          id
        ].yupSchema.validateSync(this.getInputValues(), { abortEarly: false });
      } catch (error) {
        if (handleErrors) {
          this.handleErrorsSingleInput(error);
        }
        return false;
      }
    };

    getFormValidationErrors = () => {
      try {
        return !this.schema.validateSync(this.getInputValues(), {
          abortEarly: false,
        });
      } catch (error) {
        return error;
      }
    };

    isFormValid = (handleErrors = true) => {
      this.schema = yup.object().shape(this.getSchema());

      try {
        return this.schema.validateSync(this.getInputValues(), {
          abortEarly: false,
        });
      } catch (error) {
        if (handleErrors) {
          this.handleErrors(error);
        }
        return false;
      }
    };

    isValidRelativeInputs = () => {
      let returnVal = true;

      this.state.inputsById.forEach((inputId) => {
        let input = this.state.inputsByHash[inputId];

        if (input.hasOwnProperty("relativeValidationFromInputsIds")) {
          input.relativeValidationFromInputsIds.forEach((id) => {
            const relativeInputValue = this.state.inputsByHash[id].value;
            if (relativeInputValue !== null && relativeInputValue !== "") {
              if (!this.isInputValid(inputId)) returnVal = false;
            }
          });
        }
      });
      return returnVal;
    };

    resetErrors = () => {
      let self = this;
      return new Promise(function (resolve) {
        let updatedInputsByHash = { ...self.state.inputsByHash };

        self.state.inputsById.forEach((inputId) => {
          updatedInputsByHash[inputId] = {
            ...self.state.inputsByHash[inputId],
            error: false,
            errorMessage: null,
          };
        });

        self.setState(
          {
            inputsByHash: updatedInputsByHash,
          },
          resolve
        );
      });
    };

    handleErrorsSingleInput = (error) => {
      let updatedInputsByHash = { ...this.state.inputsByHash };

      error.inner.forEach((validationError) => {
        updatedInputsByHash[validationError.path] = {
          ...updatedInputsByHash[validationError.path],
          error: true,
          errorMessage: validationError.message,
        };
      });

      this.setState({
        inputsByHash: updatedInputsByHash,
      });
    };

    handleErrors = (error) => {
      let updatedInputsByHash = { ...this.state.inputsByHash };

      this.state.inputsById.forEach((inputId) => {
        updatedInputsByHash[inputId] = {
          ...this.state.inputsByHash[inputId],
          error: false,
          errorMessage: null,
        };
      });

      error.inner.forEach((validationError) => {
        updatedInputsByHash[validationError.path] = {
          ...updatedInputsByHash[validationError.path],
          error: true,
          errorMessage: validationError.message,
        };
      });

      this.setState({
        inputsByHash: updatedInputsByHash,
      });
    };

    getSubmitData = (
      checkExcludeSubmit = true,
      skipNull = true,
      skipEmpty = false,
      skipInvisible = true
    ) => {
      let data = {};
      this.state.inputsById.forEach((id) => {
        const input = this.state.inputsByHash[id];
        const submitID = input.hasOwnProperty("submitViaKey")
          ? input.submitViaKey
          : id;

        if (
          checkExcludeSubmit &&
          input.hasOwnProperty("excludeSubmit") &&
          input.excludeSubmit
        )
          return;
        if (skipInvisible && !input.visibility) return;

        if (skipNull && input.value === null) return;
        if (skipEmpty && input.value === "") return;

        let value = null;

        if (input.hasOwnProperty("submitValueAccessor")) {
          value = input.submitValueAccessor(input.value);
        } else if (input.hasOwnProperty("submitValueGlobalAccessor")) {
          value = input.submitValueGlobalAccessor(this.state.inputsByHash);
        } else {
          value = input.value;
        }

        if (skipNull && value === null) return;
        if (skipEmpty && value === "") return;

        data[submitID] = value;
      });
      return data;
    };

    getExtraSelectedOptionData = () => {
      let data = {};
      this.state.inputsById.forEach((id) => {
        const input = this.state.inputsByHash[id];

        if (input.hasOwnProperty("extraSelectedOptionData")) {
          data[id] = input.extraSelectedOptionData;
        }
      });
      return data;
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleBulkInputs={this.handleBulkInputs}
          dataLoaded={this.state.dataLoaded}
          inputsGroups={this.state.inputsGroups}
          inputsByHash={this.state.inputsByHash}
          inputsById={this.state.inputsById}
          clearInputs={this.clearInputs}
          clearInputsById={this.clearInputsById}
          resetErrors={this.resetErrors}
          handleErrors={this.handleErrors}
          isFormValid={this.isFormValid}
          getInputValues={this.getInputValues}
          handleInputs={this.handleInputs}
          getSubmitData={this.getSubmitData}
          isInputValid={this.isInputValid}
          getFormValidationErrors={this.getFormValidationErrors}
          initRelativeInputs={this.initRelativeInputs}
          isValidRelativeInputs={this.isValidRelativeInputs}
          getExtraSelectedOptionData={this.getExtraSelectedOptionData}
        />
      );
    }
  }

  return withAuthCenter()(HOC);
};


withForm.defaultProps = {
  withFormConfig: {},
};

export default withForm;
