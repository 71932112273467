import helpers from "utils/helpers";

export default {
  inputsById: ["batchTypeCode"],

  inputsByHash: {
    batchTypeCode: {
      label: "Batch Type",
      type: "autocomplete",
      accessor: (d) => ({
        value: d.batchTypeCode,
        label: d.batchTypeDesc,
      }),
      resource: "lovs/BATCHTYPE",
      yup: helpers.getYupRequired("Batch Type"),
    },
  },
};
