import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

const stylesSizeSmall = `
  width: 20px !important;
  height: 20px !important;
`;

const ProgressBar = (props) => {
  const StyledProgressBar = styled.div`
       position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: ${props.backgroundColor || "rgba(255, 255, 255, .7)"};
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      ${props.rootStyles}
    `;

  let circleProgressStyles = ``;

  if (props.size === 'small') {
    circleProgressStyles += stylesSizeSmall;
  }

  const StyledCircularProgress = styled(CircularProgress)`
      && {
        ${circleProgressStyles}
      }
  `;

  return (
    <StyledProgressBar>
      <StyledCircularProgress/>
    </StyledProgressBar>
  );
}


export default ProgressBar;


ProgressBar.defaultProps = {
  size: 'medium',
  rootStyles: '',
}