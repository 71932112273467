export default {
    failPassCodesWidget: {
        inputsByHash: {
            failCode: {
                label: 'Fail Code',
                resource: 'reasons/FAIL',
                excludeSubmit: true,
                accessor: (d) => {
                    return ({value: d.id, label: `${d.failCode} - ${d.failDesc}`})
                },

                selectedOptionsAccessor: (d) => {return d.filter(item => {return item.reasonTypeCode === 'FAIL'}).map(function(obj) {return obj.reasonId})},
                autoSave: {
                    resourceAccessor: (d) => {
                        return `requisitions/${d.reqID}/fail_codes`
                    },
                    dataAccessor: (d) => {
                        return {failIds: d}
                    }
                }
            },
            passCode: {
                label: 'Pass Code',
                resource: 'reasons/PASS',
                accessor: (d) => {
                    return ({value: d.id, label: `${d.passCode} - ${d.passDesc}`})
                },
                selectedOptionsAccessor: (d) => {return d.filter(item => {return item.reasonTypeCode === 'PASS'}).map(function(obj) {return obj.reasonId})},
                autoSave: {
                    resourceAccessor: (d) => {
                        return `requisitions/${d.reqID}/pass_codes`
                    },
                    dataAccessor: (d) => {
                        return {passIds: d}
                    }
                }
            }
        },

        inputsById: ['failCode', 'passCode'],
    },
    statusWidget: {
        inputsByHash: {
            status: {
                label: 'Status',
                type: 'select',
                resource: 'req_status_types',
                accessor: (d) => {
                    return ({value: d.reqStatusCode, label: `${d.reqStatusDesc}`})
                },
                autoSave: {
                    resourceAccessor: (d) => {
                        return `requisitions/${d.reqID}`
                    },
                    dataAccessor: (d) => {
                        return {reqStatusCode: d}
                    }
                }
            }
        },
        inputsById: ['status']
    }
}