import React, {PureComponent} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import styles from './select.module.scss';
import dataProvider, {GET_LIST} from "providers/dataProvider";
import withAutoSave from './withAutoSave';

class SingleSelectContainer extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            options: [],
            value: this.props.value
        }
    }

    componentDidMount() {
        const {input} = this.props

        this.initOption(input);
    }

    initOption = (input) => {
        if (input.hasOwnProperty('resource')) {
            this.loadOptions();
        } else if (input.hasOwnProperty('customOptionsType')) {
            this.setState({
                options: input.options
            })
        } else if (input.hasOwnProperty('options')) {
            this.setState({
                options: input.options.map((o) => ({value: o, label: o}))
            })
        }
    }

    handleInputs = e => {
        const {input, reqID, autoSaveCallBack} = this.props;

        e.stopPropagation()
        const value = e.target.value;

        if (this.props.input.hasOwnProperty('autoSave')) {
            const resource = input.autoSave.hasOwnProperty('resourceAccessor') ? input.autoSave.resourceAccessor({reqID: reqID}) : null;
            const data = input.autoSave.hasOwnProperty('dataAccessor') ? input.autoSave.dataAccessor(value) : value;
            this.props.autoSave(data, resource, 'Successfully updated').then((response) => {
                autoSaveCallBack();
                this.setState({
                    value: value
                })
            });
        }
    }

    getFilter = (input) => {
        try {
            return input.filter || (input.relativeInputsFilterAccessor && input.relativeInputsFilterAccessor(input.relativeInputsData)) || {};
        } catch {
            return false;
        }
    }

    getResource = (input, options) => {
        try {
            return input.resource || (input.resourceAccessor && input.resourceAccessor(options)) || input.relativeInputResourceAccessor(input.relativeInputsData);
        } catch {
            return false
        }
    }

    loadOptions() {
        const {input, options} = this.props;
        const filter = this.getFilter(input);
        const resource = this.getResource(input, options)

        if (!filter) return;
        if (!resource) return;

        dataProvider(GET_LIST, resource, {
            filter,
            pagination: {
                perPage: -1
            }
        })
            .then(({data, total}) => {
                let options = data.map(input.accessor)
                this.setState({
                    options
                })
            })
    }

    render() {
        const {input, inputId, style, disabled} = this.props
        const {options, value} = this.state;

        return (
            <FormControl className={style.textField}>
                <InputLabel htmlFor="age-simple">{input.label}</InputLabel>
                <Select
                    disabled={disabled}
                    error={input.error}
                    value={value}
                    onChange={this.handleInputs}
                    name={inputId}
                    className={styles.field}
                >
                    {options.map((option, key) => (<MenuItem key={key} value={option.value}>{option.label}</MenuItem>))}
                </Select>
            </FormControl>

        )
    }
}


SingleSelectContainer.defaultProps = {
    autoSaveCallBack: () => {},
    style: {
        textField: ''
    }
}

export default withAutoSave(SingleSelectContainer);