import React from "react";
import useExtraDataLoader from "hooks/useExtraDataLoader";

const withExtraDataLoader = (conf) => (Component) => {
  return (props) => {

    const config = conf || props.extraDataConfig || {
      byId: [],
      byHash: {},
    }

    const { data, isDataLoading } = useExtraDataLoader(config);
    return <Component extraData={data} isExtraDataLoading={isDataLoading} {...props} />;
  };
};

export default withExtraDataLoader;
