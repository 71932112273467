import React, { PureComponent } from "react";
import styles from "./TestsContainer.module.scss";
import { SmartTable } from "components/ui_components/table";
import { testsTabConfig } from "config/resources/requisitionsResource";
import { compose } from "redux";
import dataProvider, { BULK_DELETE, UPDATE } from "providers/dataProvider";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import helpers from "utils/helpers";
import { withNotificationCenter } from "NotificationCenter";
import ButtonBase from "@material-ui/core/ButtonBase";
import Can from "Can";
import { userRoles, userGroups } from "config/userRoles";
import clsx from "clsx";
import SidebarAddTestForm from "./SidebarAddTestForm";
import ExportProcessedTestButton from "components/pages/requisitions/RequisitionsShow/Tabs/Tests/ExportProcessedTestButton.js";
import withModal from "withModal";
import BaseModal from "components/ui_components/BaseModal/BaseModal.js";

const { tableColConfig } = testsTabConfig;
const availableRolesForEdit = ["LOG", "ACC", "PROCESS"];
const availableRolesForModify = ["PROCESS"];

const extraDataConfig = {
  byId: ["reasonsFail", "reasonsPass"],
  byHash: {
    reasonsFail: {
      resource: "reasons/FAIL",
    },
    reasonsPass: {
      resource: "reasons/PASS",
    },
  },
};

class TestsContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modifyInProgress: false,
    };

    this.modifyTestID = null;

    this.isEditAvailable = availableRolesForEdit.includes(
      this.props.reqData.reqStatusCode
    );
    this.isModifyAvailable = availableRolesForModify.includes(
      this.props.reqData.reqStatusCode
    );
  }

  refreshInputsData = () => {
    this.funcComRef();
  };

  deleteCallback = (items, resource, loadData) => {
    const { reqID } = this.props;
    let ids = items.map((i) => i.id);

    dataProvider(BULK_DELETE, `requisitions/${reqID}/req_tests`, { id: ids })
      .then(({ message }) => {
        loadData();
        this.refreshInputsData();
        this.props.notificationCenter.show(message);
      })
      .catch((e) => {
        this.props.notificationCenter.show(e.message, "error");
      });
  };

  reloadTable = () => {
    this.smartTable.loadData();
  };

  handleClickModify = (rows) => {
    const row = rows[0];
    const { reqID } = this.props;
    this.modifyTestID = row.id;

    const testFailCodeArr = helpers.stringToArr(row.testFailCodes);

    let isTestHasSpecialFailIdsOrEmpty = testFailCodeArr.some((r) =>
      ["901", "902", "903"].includes(r)
    );

    if (testFailCodeArr.length === 0) {
      isTestHasSpecialFailIdsOrEmpty = true;
    }

    if (isTestHasSpecialFailIdsOrEmpty) {
      this.props.handleOpenModal();
    } else {
      this.handleModifyFlow(reqID, this.modifyTestID);
    }
  };

  handleModifyConfirmYes = () => {
    this.handleModifyFlow(this.props.reqID, this.modifyTestID);
    this.props.handleCloseModal();
  };

  handleModifyConfirmNo = () => {
    this.handleModifyCancelFlow(this.props.reqID, this.modifyTestID);
    this.props.handleCloseModal();
  };

  handleModifyFlow = (reqID, testID) => {
    this.setState({
      modifyInProgress: true,
    });
    dataProvider(UPDATE, `requisitions/${reqID}/req_tests/${testID}/modify`, {
      data: {},
    })
      .then((response) => {
        this.props.notificationCenter.show("Successfully modified", "info");
        this.smartTable.loadData();
        this.setState({
          modifyInProgress: false,
        });
      })
      .catch((error) => {
        this.props.notificationCenter.show(error.message, "warning");
        this.setState({
          modifyInProgress: false,
        });
      });
  };

  handleModifyCancelFlow = (reqID, testID) => {
    this.setState({
      modifyInProgress: true,
    });
    dataProvider(
      UPDATE,
      `requisitions/${reqID}/req_tests/${testID}/cancel_mod`,
      {
        data: {},
      }
    )
      .then((response) => {
        this.props.notificationCenter.show("Modify is canceled ", "info");
        this.smartTable.loadData();
        this.setState({
          modifyInProgress: false,
        });
      })
      .catch((error) => {
        this.props.notificationCenter.show(error.message, "warning");
        this.setState({
          modifyInProgress: false,
        });
      });
  };

  render() {
    const { reqID } = this.props;

    let customRowActions = [
      {
        icon: <EditIcon />,
        handleOnClick: this.handleClickModify,
        showProgress: this.state.modifyInProgress,
        isHiddenAccessor: (d) =>
          d.isModifiable === 0 || !this.isModifyAvailable,
        tooltipText: "Modify",
      },
    ];

    return (
      <React.Fragment>
        <BaseModal
          title="Existing test results are about to be modified."
          isOpen={this.props.modalOpen}
          controls={
            <React.Fragment>
              <ButtonBase
                className="button formButton formButton__secondaryOutlined"
                onClick={this.handleModifyConfirmYes}
              >
                Yes
              </ButtonBase>
              <ButtonBase
                className="button formButton formButton__mainContained"
                onClick={this.handleModifyConfirmNo}
              >
                No
              </ButtonBase>
            </React.Fragment>
          }
        ></BaseModal>
        <div className={styles.root}>
          {this.isEditAvailable && (
            <Can
              allowedRoles={[...userGroups.superAdmins, userRoles.collaborator]}
            >
              <SidebarAddTestForm
                reloadTableCallBack={this.reloadTable}
                reqID={reqID}
                refreshInputsData={this.refreshInputsData}
                forwardRef={(ref) => {
                  this.funcComRef = ref;
                }}
              />
            </Can>
          )}
          <div
            className={clsx([
              helpers.can([
                ...userGroups.superAdmins,
                userRoles.collaborator,
              ]) && this.isEditAvailable
                ? styles.main
                : styles.mainFullWidth,
            ])}
          >
            <SmartTable
              onRef={(ref) => (this.smartTable = ref)}
              deleteCallback={this.deleteCallback}
              columns={helpers.byHashByIDConfToArray(
                tableColConfig.tableColById,
                tableColConfig.tableColByHash
              )}
              resource={`requisitions/${reqID}/req_tests`}
              perPage={100}
              showPagination={true}
              showSelect={true}
              showDelete={!helpers.can(userRoles.sales) && this.isEditAvailable}
              showSelectAll={true}
              extraDataConfig={extraDataConfig}
              customRowActions={customRowActions}
              toolbarExtraComponents={
                !helpers.can(userRoles.sales) && <ExportProcessedTestButton reqID={reqID} />
              }
              rowAccessorExtraData={{
                isEditAvailable: this.isEditAvailable,
                reqData: {
                  labTz: this.props.reqData.labTz,
                  labTzAbbr: this.props.reqData.labTzAbbr,
                },
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TestsContainer.propTypes = {
  reqID: PropTypes.string.isRequired,
};

export default compose(withNotificationCenter(), withModal)(TestsContainer);
