import React from "react";
import Typography from "@material-ui/core/Typography";

export default function ({ clientData }) {
  return (
    <div>
      <Typography gutterBottom>
        <Typography variant="h5" gutterBottom>
          Client Notes
        </Typography>
        {clientData.clientNote}
      </Typography>
      <Typography gutterBottom>
        <Typography variant="h5" gutterBottom>
          Delivery Note
        </Typography>
        {clientData.clientDeliveryNote}
      </Typography>
    </div>
  );
}
