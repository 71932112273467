import React from 'react'
import styles from './IssuesListContainer.module.scss';
import IssuesListItemComponent from './IssuesListItemComponent';
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar';

const IssuesContainer = (props) => {
    const {issuesList, issueStatusList, reqID, issuesDataLoading, userDepartmentCodes} = props;
    return (
        <div className={styles.root}>
            <div className={styles.progressBarWrap}>
                {issuesDataLoading && <ProgressBar/>}
                {issuesList.map(item => <IssuesListItemComponent key={item.id} data={item} issueStatusList={issueStatusList} reqID={reqID} userDepartmentCodes={userDepartmentCodes}/>)}
            </div>
        </div>
    );
}

export default IssuesContainer