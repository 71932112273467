import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MainHeader from 'components/templateParts/MainHeader';
import dataProvider, { CREATE } from "providers/dataProvider";
import withForm from 'withForm';
import { createConfig } from "config/resources/collaboratorsResource";
import style from './CollaboratorCreate.module.scss'
import { compose } from 'redux'
import { withNotificationCenter } from 'NotificationCenter'
import BaseForm from 'components/ui_components/BaseForm/BaseForm';
import BaseFormControls from 'components/ui_components/BaseFormControls/BaseFormControls';
import { useHistory } from 'react-router-dom';

const styles = () => ({
  main: {
    margin: '32px 26px 0',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '30px 15px 30px'
  },
});

const inputsGroupsById = createConfig.inputsGroupsById;
const inputsGroupsByHash = createConfig.inputsGroupsByHash;

const CollaboratorCreate = (props) => {
  const { notificationCenter, isFormValid, resetErrors, getSubmitData } = props;
  const { classes, inputsById, inputsByHash, handleInputs} = props;
  const history = useHistory();

  const handleSubmit = () => {
    if (isFormValid()) {
      resetErrors();

      dataProvider(CREATE, 'collaborators', { data: getSubmitData(true, true, true) })
      .then((response) => {
        notificationCenter.show('Successfully created', 'info')
        history.push(`/collaborators/` + response.data.id + '/show');
      })
      .catch(error => {
        notificationCenter.show(error.message, 'warning')
      });
    }
  }


  return (
    <div className={style.root}>
      <MainHeader title="Create Collaborator"/>
      <div className={classes.main}>

        <BaseForm
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          inputsGroupsById={inputsGroupsById}
          inputsGroupsByHash={inputsGroupsByHash}
          rootStyles={'display: flex; flex-wrap: wrap;'}
        />

        <BaseFormControls
          type={'create'}
          handleClickSubmit={handleSubmit}
          submitLabel="Create Collaborator"
          resource="collaborators"
          id={props.id}
        />
      </div>
    </div>
  );

}

export default compose(
  withNotificationCenter(),
  withForm({ ...createConfig }),
  withStyles(styles),
)(CollaboratorCreate)