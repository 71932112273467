import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const GlassTube2Icon = (props) => (
    <SvgIcon {...props}>
        <path d="M22.9,19.8L16.5,8.1v-7c0-0.7-0.5-1.2-1.2-1.2H8.9c-0.7,0-1.2,0.5-1.2,1.2v7L1.4,19.8c-0.5,0.9-0.5,2,0.1,2.8
	C2,23.5,2.9,24,3.9,24h16.4c1,0,2-0.5,2.5-1.4C23.3,21.7,23.3,20.6,22.9,19.8z M13.2,14.9c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1
	c-0.6,0-1-0.5-1-1C12.1,15.3,12.6,14.9,13.2,14.9z M10.3,8.7c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1s-1-0.5-1-1C9.3,9.2,9.7,8.7,10.3,8.7z
	 M10.3,12.3c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7C8.6,13,9.4,12.3,10.3,12.3z M19.8,20.8
	c-0.2,0.4-0.6,0.6-1,0.6H5.4c-0.4,0-0.8-0.2-1-0.6c-0.2-0.4-0.2-0.8,0-1.2L6,16.7c0.2-0.4,0.7-0.6,1.1-0.6c2.1,0.2,4.5,2,6.7,1.9
	c1.1,0,2.2-0.5,3.2-1c0.6-0.3,1.3-0.1,1.6,0.5l1.2,2.1C20,20,20,20.4,19.8,20.8z"/>
    </SvgIcon>
);

export default GlassTube2Icon;