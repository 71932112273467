import React from 'react';
import PropTypes from 'prop-types';
import WidgetListComponent from "./WidgetListComponent";
import style from './SingleViewHeader.module.scss';

const WidgetListByGroupsComponent = props => {
    const {iconsDataByHash, iconsDataGroupByHash, iconsDataGroupsByID} = props;
    return (
        <div>
            {iconsDataGroupsByID.map(groupID =>
                <div key={groupID}>
                    <div className={style.groupTitle}>
                        {iconsDataGroupByHash[groupID].title}
                    </div>
                    <WidgetListComponent key={groupID}
                         iconsById={iconsDataGroupByHash[groupID].iconsData}
                         iconsByHash={iconsDataByHash}
                    />
                </div>

            )}
        </div>
    );
};

WidgetListByGroupsComponent.propTypes = {
    iconsDataByHash: PropTypes.object.isRequired,
    iconsDataGroupByHash: PropTypes.object.isRequired,
    iconsDataGroupsByID: PropTypes.array.isRequired,
};

export default WidgetListByGroupsComponent;