import React from 'react';
import styled from 'styled-components';

const defaultStyles = `
    margin: 20px 0 0;
    box-sizing: border-box;
    padding: 0 10px 0;
    display: flex;
    align-items: flex-end;
    width: 33.3333%;
    
    >div {
      width: 100%;
    }
  `

const InputWrap = styled.div`
   &&{
      ${defaultStyles}
      ${props => props.styles}
    }
  `;

export default function ({ children, styles }) {
  return (
    <InputWrap styles={styles}>
      {children}
    </InputWrap>
  )
}