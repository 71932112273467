import React, {Component} from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell';
import styles from "./BaseTableRowCollapse.module.scss";
import Collapse from '@material-ui/core/Collapse';

class BaseTableRowCollapse extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <TableRow className={styles.tableRow}>
                <TableCell colSpan={this.props.totalColumns} className={styles.tableCell}>
                    <Collapse in={this.props.isCollapseOpen} timeout="auto" unmountOnExit className={styles.collapse}>
                        {this.props.content}
                    </Collapse>
                </TableCell>
            </TableRow>
        )
    }
}

export default BaseTableRowCollapse