import React, {Component} from 'react'
import styles from './LabNotesContainer.module.scss';
import {SmartTable} from 'components/ui_components/table'
import {compose} from 'redux'
import PropTypes from "prop-types";
import {labNotesTabConfig} from "config/resources/requisitionsResource/index.js";
import {connect} from 'react-redux';
import helpers from 'utils/helpers';
import AddNoteContainer from './AddNoteContainer.js';

const {notesTableColConfig, notesHistoryTableColConfig, addNoteFormConfig} = labNotesTabConfig;

class LocationsContainer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedRows: [],
            isUploading: false
        }
    }

    handleSuccessfullySubmit = () => {
        this.smartTable.loadData();
    }

    render() {
        const {reqID} = this.props;

        return (
            <div className={styles.root}>
                <div className={styles.main}>
                    <AddNoteContainer withFormConfig={addNoteFormConfig} reqID={reqID} handleSuccessfullySubmit={this.handleSuccessfullySubmit}/>

                    <div className={styles.tableWrap}>

                        <SmartTable
                            resource={`requisitions/${reqID}/lab_notes`}
                            showPagination={true}
                            showSelect={true}
                            showDelete={true}
                            showSelectAll={true}
                            onRef={ref => (this.smartTable = ref)}
                            columns={
                                helpers.byHashByIDConfToArray(notesTableColConfig.tableColById, notesTableColConfig.tableColByHash)}
                        />
                    </div>

                    <div className={styles.tableWrap}>
                        <h3 className='ttl ttl-3'>
                            History
                        </h3>
                        <SmartTable
                            columns={
                                helpers.byHashByIDConfToArray(notesHistoryTableColConfig.tableColById, notesHistoryTableColConfig.tableColByHash)}
                            resource={`requisitions/${reqID}/lab_notes/histories`}
                            showPagination={true}
                            showSelect={false}
                            showDelete={false}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

LocationsContainer.propTypes = {
    reqID: PropTypes.string.isRequired
}

const mapStateToProps = ({specimenLocations}) => ({
    addSpecimenLocationModalIsOpen: specimenLocations.addSpecimenLocationModalIsOpen
})

export default compose(
    connect(
        mapStateToProps,
        null
    ),
)(LocationsContainer)