import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BadgeIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M19.5,2.5h-4V1C15.5,0.4,15,0,14.5,0h-5C9,0,8.5,0.4,8.5,1v1.6h-4C4,2.5,3.6,3,3.6,3.5V23C3.6,23.6,4,24,4.5,24
	h15c0.5,0,0.9-0.4,0.9-0.9V3.5C20.4,3,20,2.5,19.5,2.5z M10.4,1.9h3.2v3.5L12,6.3l-1.6-0.9V1.9z M18.6,22.1H5.4V4.4h3.1V6
	c0,0.3,0.2,0.7,0.5,0.8l2.5,1.4c0.3,0.2,0.6,0.2,0.9,0L15,6.8c0.3-0.2,0.5-0.5,0.5-0.8V4.4h3.1V22.1z M8.3,11.7h7.5
	c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9H8.3c-0.5,0-0.9-0.4-0.9-0.9C7.3,12.1,7.7,11.7,8.3,11.7z M16.7,17.8
	c0,0.5-0.4,0.9-0.9,0.9H8.3c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h7.5C16.3,16.9,16.7,17.3,16.7,17.8z"/>
    </SvgIcon>
);

export default BadgeIcon;