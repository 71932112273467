import React, { useState } from 'react';
import styles from './AuthForm.module.scss'
import LoginForm from './LoginForm/LoginForm';
import ConfirmSignInForm from './ConfirmSignInForm/ConfirmSignInForm.js';
import NewPasswordForm from './NewPasswordForm/NewPasswordForm';
import ForgotPasswordForm from 'components/AuthForm/ForgotPassword/ForgotPasswordForm.js';
import { useAuthCenter } from 'AuthCenter';
import { useNotificationCenter } from 'NotificationCenter';
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar.js';


const AuthForm = ({ onLoginOk }) => {
  const authCenter = useAuthCenter();
  const notificationCenter = useNotificationCenter();
  const [dataLoading, setDataLoading] = useState(false);

  const onConfirmOk = (accessToken) => {
    authCenter.mfaLogin(accessToken)
    .then(() => {
      notificationCenter.show('Successfully login')
      setDataLoading(false);
      onLoginOk();
    })
    .catch(e => {
      notificationCenter.show(e.message, 'error');
      setDataLoading(false);
    })
  }

  const handleSubmitChangeTemporaryPassword = async (submitData) => {
    try {
      await authCenter.changePassword(submitData.newPwd).then(() => {

      })
    }
    catch (e) {
      notificationCenter.show(e.message, 'error')
    }
  }

  const handleSubmitPasswordExpired = async (submitData) => {
    try {
      await authCenter.mfaChangeExpiredPassword(submitData.newPwd);
    }
    catch (e) {
      notificationCenter.show(e.message, 'error')
    }
  }


  return (
    <div className={styles.root}>
      {dataLoading && <ProgressBar/>}
      {authCenter.authStatus === 'login' && <LoginForm setDataLoading={setDataLoading}/>}
      {authCenter.authStatus === 'confirmSignIn' && <ConfirmSignInForm onConfirmOk={onConfirmOk} setDataLoading={setDataLoading} />}
      {authCenter.authStatus === 'forgotPassword' && <ForgotPasswordForm setDataLoading={setDataLoading}/>}
      {authCenter.authStatus === 'changeTemporaryPassword' &&
      <NewPasswordForm onSubmitOk={handleSubmitChangeTemporaryPassword}/>}
      {authCenter.authStatus === 'passwordExpired' &&
      <NewPasswordForm onSubmitOk={handleSubmitPasswordExpired}/>}
    </div>
  );
}

export default AuthForm;