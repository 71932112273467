import React from "react";
import BaseTableRowCollapse from "./BaseTableRowCollapse";
import ProcessingSubTable from "components/ui_components/table/subTables/ProcessingSubTable";
import ReleasedReportsSubTable from "components/ui_components/table/ReleasedReportsSubTable";
import TestReportsUploadSubTable from "components/ui_components/table/subTables/TestReportsUploadSubTable";
import ActivityRowCollapseContent from "./RowCollapseContent/ActivityRowCollapseContent";
import ResultedTestsSubTable from "components/ui_components/table/subTables/ResultedTestsSubTable.js";
import BatchCsvUploadSubTable from "components/ui_components/table/subTables/BatchCsvUploadSubTable.js";
import HistorySubTable from "components/ui_components/table/subTables/HistorySubTable";
import ScanDocumentsSubTable from "components/ui_components/table/subTables/ScanDocumentsSubTable";
import SpecimentsSubTable from './subTables/SpecimensSubTable'

export default function ({
  row,
  handleSubTableRowsInputData,
  columns,
  isCollapseOpen,
  handleHideCollapse,
  collapsibleTableContentType,
  collapsibleTableProps,
  subTableData,
  extraData,
}) {
  const collapsibleTableContent = {
    batchCsvUpload: (
      <BatchCsvUploadSubTable key="batchCsvUpload" uploadId={row.id} />
    ),
    testReportsUpload: (
      <TestReportsUploadSubTable key="testReportsUpload" uploadId={row.id} />
    ),
    testResults: (
      <ProcessingSubTable
        key="processingSubTable"
        parentRow={row}
        reqID={row.id}
        handleSubTableRowsInputData={handleSubTableRowsInputData}
        collapsibleTableProps={collapsibleTableProps}
        subTableData={subTableData}
        extraData={extraData}
      />
    ),
    releasedReports: (
      <ReleasedReportsSubTable
        key="releasedReports"
        downloadCallSource="DELIVER"
        parentRow={row}
        reqID={row.id}
        handleSubTableRowsInputData={handleSubTableRowsInputData}
        extraData={extraData}
      />
    ),
    releasedReportsDelivered: (
      <ReleasedReportsSubTable
        downloadCallSource="DELIVER"
        key="releasedReports"
        parentRow={row}
        reqID={row.id}
        handleSubTableRowsInputData={handleSubTableRowsInputData}
        extraData={extraData}
      />
    ),
    releasedReportsReleased: (
      <ReleasedReportsSubTable
        key="releasedReportsResulted"
        downloadCallSource="RELEASE"
        showDeliveryAction={true}
        arentRow={row}
        reqID={row.id}
        handleSubTableRowsInputData={handleSubTableRowsInputData}
      />
    ),
    releasedReportsResulted: (
      <ReleasedReportsSubTable
        key="releasedReports"
        downloadCallSource="RESULT"
        parentRow={row}
        reqID={row.id}
        handleSubTableRowsInputData={handleSubTableRowsInputData}
      />
    ),
    releasedReportsReported: (
      <ReleasedReportsSubTable
        key="releasedReportsReported"
        downloadCallSource="REPORT"
        extraData={extraData}
        parentRow={row}
        reqID={row.id}
        handleSubTableRowsInputData={handleSubTableRowsInputData}
        showLabQcResultSelect={true}
      />
    ),
    resultedTests: (
      <ResultedTestsSubTable
        key="resultedTests"
        parentRow={row}
        reqID={row.id}
      />
    ),
    reqHistory: <HistorySubTable key="reqHistory" parentRow={row} />,
    speciments: <SpecimentsSubTable key="reqHistory" reqId={row.id} reqData={row}/>,
    activity: <ActivityRowCollapseContent key="activity" parentRow={row} />,
    scanDocuments: (
      <ScanDocumentsSubTable key="scanDocuments" reqId={row.id} />
    ),
  };

  const content = collapsibleTableContentType.map((content) => {
    return collapsibleTableContent[content];
  });

  return (
    <BaseTableRowCollapse
      totalColumns={columns.length + 3}
      isCollapseOpen={isCollapseOpen}
      handleHideCollapse={handleHideCollapse}
      content={content}
    />
  );
}
