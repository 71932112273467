import helpers from "utils/helpers";

export default {
  inputsByHash: {
    issueCode: {
      label: "Pre-defined issue",
      type: "autocomplete",
      resource: "issue_types",
      relativeAutoFillInputs: ["reporterDeptId", "resolverDeptId", "issueDesc"],
      submitValueAccessor: (d) => d.deptIssueCode,
      accessor: (d) => {
        return { value: d, label: d.deptIssueDesc };
      },
    },
    reporterDeptId: {
      label: "Reporter department",
      yup: helpers.getYupRequired("Reporter department"),
      type: "autocomplete",
      resource: "depts",
      autoFillAccessor: (d) => d.reporterDeptId,
      accessor: (d) => {
        return { value: d.id, label: d.deptName };
      },
    },
    resolverDeptId: {
      label: "Resolver department",
      yup: helpers.getYupRequired("Reporter department"),
      type: "autocomplete",
      resource: "depts",
      autoFillAccessor: (d) => d.resolverDeptId,
      accessor: (d) => {
        return { value: d.id, label: d.deptName };
      },
    },
    issueDesc: {
      label: "Description",
      autoFillAccessor: (d) => d.deptIssueDesc,
      yup: helpers.getYupRequired("Description"),
    },
    issueNote: {
      label: "Note",
    },
  },

  inputsById: [
    "issueCode",
    "reporterDeptId",
    "resolverDeptId",
    "issueDesc",
    "issueNote",
  ],
};
