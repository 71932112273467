import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormButton from "../ui_components/buttons/FormButton";
import CustomSmartView from "./CustomSmartView";
import SmartInput from "components/ui_components/smart_input";
import selectStyles from "./selectStyles.module.scss";
import { useSmartFilter } from "SmartFilterProvider";

const styles = (theme) => ({
  formTitle: {
    fontSize: "18px",
    fontWeight: "700",
    padding: "17px 0 0",
  },
  formControl: {
    padding: "10px 0 0",
    width: "100%",
  },
  formLabel: {
    margin: "0 0 6px",
    fontSize: "16px",
  },
  formButtonGroup: {
    display: "flex",
    margin: "0 -6px",
    padding: "23px 0 0",
    boxSizing: "border-box",
  },
  formButton: {
    margin: "0 6px",
    padding: "2px 6px",
  },
  textField: {
    border: "1px solid #6D6D6D",
  },
});

const SortAndFilter = (props) => {
  const { classes, config, smartFilterForm } = props;
  const { applyFormInputs } = useSmartFilter(config);

  const {
    inputsById,
    inputsByHash,
    handleInputs,
    clearAllInputs,
    getInputsByHash,
  } = smartFilterForm;

  const onFilterSubmit = (e) => {
    e.preventDefault();
    applyFormInputs(getInputsByHash());
  };

  return (
    <form>
      <div className={classes.form}>
        <div className={classes.formTitle}>Search Options</div>

        {inputsById.map((id, key) => {
          let input = { ...inputsByHash[id] };

          let inputId = id;
          input.label = null;
          if (!input.type) {
            input.type = "input";
          }
          let params = {
            inputId,
            input,
            style: classes,
            handleInputs: handleInputs,
            selectStyles: selectStyles,
          };

          if (input.hasOwnProperty("can") && !input.can) {
            return null;
          }

          return (
            <FormControl className={classes.formControl} key={id}>
              <div className={classes.formLabel}>{inputsByHash[id].label}</div>
              <SmartInput key={key} {...params} />
            </FormControl>
          );
        })}

        <div className={classes.formButtonGroup}>
          <FormButton
            fullWidth={true}
            onClick={clearAllInputs}
            className={classes.formButton}
          >
            Clear
          </FormButton>

          <FormButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth={true}
            onClick={onFilterSubmit}
            className={classes.formButton}
          >
            Filter
          </FormButton>
        </div>
      </div>

      <CustomSmartView
        resource={props.resource}
        inputsById={inputsById}
        inputsByHash={inputsByHash}
      />
    </form>
  );
};

export default withStyles(styles)(SortAndFilter);
