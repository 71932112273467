import helpers from 'utils/helpers';
import 'moment/locale/de'
import moment from 'moment';
import {
  formatServerDateTimeToTimeZoneWithAbbr,
} from 'utils/dateTimeFormat.js';

export default {
  inputsByHash: {
    specimenTypeCode: {
      label: 'Specimen Type',
      type: 'radio',
      resource: 'lovs/SPECIMENTYPE',
      accessor: (d) => {
        return ({ value: d.specimenTypeCode, label: `${d.specimenTypeDesc}` })
      },
      yup: helpers.getYupRequired('Specimen Type'),
    },
    temperatureTypeCode: {
      label: 'Temperature',
      type: 'radio',
      resource: 'lovs/TEMPERATURETYPE',
      accessor: (d) => {
        return ({ value: d.temperatureTypeCode, label: `${d.temperatureTypeDesc}` })
      },
      yup: helpers.getYupRequired('Temperature')
    },
    containerTypeCode: {
      label: 'Container',
      type: 'radio',
      resource: 'lovs/CONTAINERTYPE',
      accessor: (d) => {
        return ({ value: d.containerTypeCode, label: `${d.containerTypeDesc}` })
      },
      yup: helpers.getYupRequired('Container')
    },
    tubeCount: {
      type: 'number',
      inputProps: { min: "1" },
      label: 'Number of Tubes',
      value: 1,
      yup: helpers.getYupRequired('Number of Tubes')
    },
    dateReceivedMonth: {
      label: 'Month',
      yup: helpers.getYupDateMonthRequired(null, true),
      excludeSubmit: true,
      value: moment(new Date()).format('M')
    },
    dateReceivedDate: {
      label: 'Date',
      yup: helpers.getYupDateDayRequired(null, true),
      excludeSubmit: true,
      value: moment(new Date()).format('D')
    },
    dateReceivedYear: {
      label: 'Year',
      yup: helpers.getYupDateYearRequired(null, true),
      excludeSubmit: true,
      value: moment(new Date()).format('YYYY')
    },
    dateReceivedMinutes: {
      label: 'Minutes',
      yup: helpers.getYupTimeMinutesRequired(null, true),
      excludeSubmit: true,
      value: () => moment(new Date()).format('m')
    },
    dateReceivedHours: {
      label: 'Hours',
      yup: helpers.getYupTimeHoursRequired(null, true),
      excludeSubmit: true,
      value: () => moment(new Date()).format('h')
    },
    dateReceivedAmPm: {
      label: 'AM/PM',
      yup: helpers.getYupRequired(null, true),
      type: 'select',
      options: [
        { value: 'am', label: 'AM', shortKeyCode: 'a' },
        { value: 'pm', label: 'PM', shortKeyCode: 'p' }
      ],
      value: () => moment(new Date()).format('a'),
      excludeSubmit: true
    }
  },
  inputsById: [
    'specimenTypeCode', 'temperatureTypeCode', 'containerTypeCode', 'tubeCount', 'dateReceivedMonth', 'dateReceivedDate', 'dateReceivedYear', 'dateReceivedMinutes', 'dateReceivedHours', 'dateReceivedAmPm'
  ],

  tableColConfig: [
    {
      id: 'receivedAt',
      label: 'Received At',
      accessor: (d, extraData) => {
       return (`${formatServerDateTimeToTimeZoneWithAbbr(d.dateReceived, 'dateTime', extraData.reqData.labTz, extraData.reqData.labTzAbbr)}`)
      }
    },
    {
      id: 'specTypeDesc',
      label: 'Specimen'
    },
    {
      id: 'temperatureTypeDesc',
      label: 'Temperature'
    },
    {
      id: 'containerTypeDesc',
      label: 'Container'
    },
    {
      id: 'tubeCount',
      label: 'Tubes #'
    },
    {
      id: 'specCreateByUsername',
      label: 'Received By'
    }
  ],

  inputsGroupsById: ['group1', 'group2', 'group3'],
  inputsGroupsByHash: {
    group1: {
      ids: ['specimenTypeCode', 'temperatureTypeCode', 'containerTypeCode', 'tubeCount']
    },
    group2: {
      title: 'Received Date',
      ids: ['dateReceivedMonth', 'dateReceivedDate', 'dateReceivedYear'],
    },
    group3: {
      title: 'Received Time',
      ids: ['dateReceivedHours', 'dateReceivedMinutes', 'dateReceivedAmPm'],
    }
  }
}