import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CheckIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M23.5,5.4L10.8,18.1c-0.3,0.4-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5l-7.6-7.6c-0.7-0.7-0.7-1.9,0-2.6
	c0.7-0.7,1.9-0.7,2.6,0l6.3,6.3L20.8,2.8c0.7-0.7,1.9-0.7,2.6,0C24.2,3.5,24.2,4.7,23.5,5.4z"/>
    </SvgIcon>
);

export default CheckIcon;