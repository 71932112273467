export default [
  {
    id: "legacyClientId",
    label: "Client ID",
  },
  {
    id: "clientName",
    label: "Name",
  },
  {
    id: "clientPhone",
    label: "Phone Number",
  },
  {
    id: "clientFaxEmail",
    label: "Fax",
  },
  {
    id: "clientCountry",
    label: "Country",
  },
  {
    id: "clientCity",
    label: "City",
  },
  {
    id: "clientState",
    label: "State",
  },
  {
    id: "clientZip",
    label: "Zip Code",
  },
];
