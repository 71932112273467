import React, {PureComponent} from 'react'
import styles from './AddNoteContainer.module.scss';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from "clsx";
import { compose } from 'redux'
import withForm from 'withForm';
import SmartInput from 'components/ui_components/smart_input'
import dataProvider, {CREATE} from "providers/dataProvider";
import {paymentsTabConfig} from "config/resources/requisitionsResource/index.js";
import {withNotificationCenter} from 'NotificationCenter'
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar';

const {inputsGroupsById, inputsGroupsByHash} = paymentsTabConfig.addFormConfig;

class AddPaymentContainer extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            dataLoading: false
        }
    }

    handleSubmit = () => {
        const {notificationCenter, isFormValid, getSubmitData, resetErrors, reqID, handleSuccessfullySubmit, clearInputs} = this.props;


        if (isFormValid()) {
            this.setState({
                dataLoading: true
            })

            let allFieldsData = getSubmitData(false);

            let data = {
                ...getSubmitData(),
                paymentDate: `${allFieldsData.paymentMonth}/${allFieldsData.paymentDate}/${allFieldsData.paymentYear}`,
            }

            resetErrors();

            dataProvider(CREATE, `requisitions/${reqID}/payments`, {data: data})
                .then((response) => {
                    this.setState({
                        dataLoading: false
                    })
                    notificationCenter.show('Successfully created', 'info')
                    handleSuccessfullySubmit();
                    clearInputs();
                })
                .catch(error => {
                    notificationCenter.show(error.message, 'warning')
                    this.setState({
                        dataLoading: false
                    })
                });
        }
    }

    render() {
        const {handleInputs, inputsById, inputsByHash} = this.props;

        return (
            <div className={styles.root}>
                {this.state.dataLoading && <ProgressBar/>}
                <div className={styles.header}>
                    <div className={styles.title}>
                        Add Payment
                    </div>

                    <div className={styles.controls}>
                        <ButtonBase
                            onClick={this.handleSubmit}
                            className={clsx([styles.button], [styles.buttonContained])}
                        >
                            Add
                        </ButtonBase>
                    </div>
                </div>

                <div className={styles.inputList}>
                    {inputsById.length > 0 ? inputsGroupsById.map(groupId => {
                        return (
                            <div key={groupId} className={clsx(styles['inputs-' + groupId], styles.formGroup)}>
                                {inputsGroupsByHash[groupId].title && <div className={styles.groupTitle}>
                                    {inputsGroupsByHash[groupId].title}
                                </div>}
                                <div className={styles.groupInputsList}>
                                    {inputsGroupsByHash[groupId].ids.map((inputId, index) => {
                                        const input = inputsByHash[inputId];
                                        let params = {inputId, input, styles, handleInputs}
                                        return (
                                            <div className={clsx([styles[`inputWrap-${inputId}`]], [styles.inputWrap])}>
                                                <SmartInput key={index} {...params} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }) : null}

                </div>

            </div>
        );
    }
}

export default compose (
    withForm(),
    withNotificationCenter()
)(AddPaymentContainer)