import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';


const styles = theme => {
    return ({
        root: {
            height: '25px',
            margin: '9px 5px 0',
            background: 'rgba(83, 196, 204, 0.5)',
            borderRadius: '50px'
        },
        label: {
            paddingLeft: '7px',
            paddingRight: '7px'
        },
        deleteIcon: {
            width: '20px',
            height: '20px',
            margin: '0 2px 0 0',
            fill: '#53C4CC',
            transition: '.5s',
            '&:hover': {
                opacity: .5
            }
        }
    })};



const MyChip = props => {
    const {classes} = props;
    return (
        <Chip {...props} classes={classes}
        />
    );
}


export default withStyles(styles)(MyChip);