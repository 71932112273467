import React, { PureComponent } from 'react'
import dataProvider, { GET_LIST } from "providers/dataProvider";
import SuggestionsPatientsListComponent
  from 'components/pages/requisitions/SuggestionsPatientsListComponent';

class WidgetSuggestedPatientsContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.timer = null

    this.state = {
      dataLoading: false,
      patientsData: []
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.submitData) === JSON.stringify(this.props.submitData)) return;
    const filterData = {};

    this.props.inputsById.forEach(id => {
      if (
        this.props.submitData[id] === '' ||
        this.props.submitData[id] === undefined ||
        Number.isNaN(this.props.submitData[id])
      )
        return false;
      filterData[id] = this.props.submitData[id]
    })

    if (this.timer) {
      clearTimeout(this.timer)
    }

    this.props.handleEditSelectedPatient();

    this.timer = setTimeout(() => {
      this.loadPatients(filterData)
    }, 1000)
  }

  loadPatients = (filterData) => {
    if (this.props.isNewPatient) return;
    this.setState({
      dataLoading: true
    })
    dataProvider(GET_LIST, 'patients', { filter: filterData })
    .then(({ data, total }) => {
      this.setState({
        dataLoading: false,
        patientsData: data
      })
    })
  }

  render() {
    return (
      <SuggestionsPatientsListComponent
        dataLoading={this.state.dataLoading}
        handleNewPatientCheck={this.props.handleNewPatientCheck}
        handleClickSuggestedPatient={this.props.handleClickSuggestedPatient}
        patientsData={this.state.patientsData}
        isNewPatient={this.props.isNewPatient}
      />
    )
  }
}

export default WidgetSuggestedPatientsContainer;