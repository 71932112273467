import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M12.9,9.9c0.4,0.5,0.5,1.2,0.5,2.1v0.4c0,0.9-0.2,1.6-0.5,2.1c-0.4,0.5-0.9,0.7-1.5,0.7h-0.8v-6h0.8
	C12,9.2,12.5,9.4,12.9,9.9z M24,1.5v21c0,0.8-0.7,1.5-1.5,1.5h-21C0.7,24,0,23.3,0,22.5v-21C0,0.7,0.7,0,1.5,0h21
	C23.3,0,24,0.7,24,1.5z M15.8,12c0-0.9-0.2-1.7-0.6-2.4c-0.4-0.7-0.9-1.2-1.6-1.6c-0.7-0.4-1.4-0.6-2.3-0.6H8.2V17h3.2
	c0.8,0,1.6-0.2,2.3-0.6c0.7-0.4,1.2-0.9,1.6-1.6c0.4-0.7,0.6-1.5,0.6-2.4V12z"/>
    </SvgIcon>
);

export default DIcon;