import { stringify } from "query-string";
import config from "../config";
import errors from "./handleError";
import axios from "axios";
import authStorage from "providers/authStorage.js";
import apiErrorSimulator from "./apiErrorSimulator";

const API_URL = config.apiUrl;
const API_REPORT_URL = config.apiReportUrl;
const BULK_DELETE = "bulk_delete";
const BULK_EDIT = "bulk_edit";
const UPDATE_LIST = "update_list";
const UPDATE_ITEM = "update_item";
const UPLOAD_FILE = "upload_file";
const UPLOAD_FILE_S3 = "upload_file_s3";
const DOWNLOAD_FILE = "download_file";
const READ_NOTIFICATION = "read_notification";
const BULK_REPORT_UPLOADER = "bulk_report_uploader";
const DOWNLOAD_FILE_GET = "download_file_get";
const GET_LIST = "get_list";
const GET_ONE = "get_one";
const GET_MANY = "get_many";
const GET_MANY_REFERENCE = "get_many_reference";
const POST = "post";
const CREATE = "create";
const UPDATE = "update";
const DELETE = "delete";
const SEND_PASSWORD_LINK = "send_password_link";

const CancelToken = axios.CancelToken;
let sourceAll = CancelToken.source();

const getApiEndpoint = (apiEndpointType = "primary") => {
  const endpoints = {
    primary: API_URL,
    report: API_REPORT_URL,
  };
  return endpoints[apiEndpointType];
};

const httpClient = (url, options = {}, type) => {
  if (!options.headers) {
    options.headers = {};
  }

  if (!(type === "upload_file_s3")) {
    // if (!authStorage.getToken()) {
    //   return Promise.resolve();
    // }

    options.headers.token = authStorage.getToken();
  }

  options.url = url;
  return axios.request(options);
};

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (
  type,
  resource,
  params = {},
  apiEndointType = "primary"
) => {
  const options = {};
  const apiEndpoint = getApiEndpoint(apiEndointType);

  switch (type) {
    case GET_LIST: {
      let filter = {};
      let sort = {};
      let pagination = {};
      if (params.pagination) {
        let { page, perPage } = params.pagination;
        pagination = {
          page: JSON.stringify(page),
          rows: JSON.stringify(perPage),
        };
      }
      if (params.filter && Object.keys(params.filter).length > 0) {
        let prepare_filter = Object.keys(params.filter).map((field) => {
          return `${field}:${params.filter[field]}`;
        });
        filter = { filter: `[${prepare_filter}]` };
      }
      if (params.sort && params.sort.field) {
        let { field, order } = params.sort;
        sort = { sort: `[${field},${order}]` };
      }
      const query = {
        ...sort,
        ...pagination,
        ...filter,
      };

      return {
        url: `${apiEndpoint}/${resource}?${stringify(query)}`,
        options: options,
      };
    }
    case GET_ONE:
      const url = params.hasOwnProperty("id")
        ? `${API_URL}/${resource}/${params.id}`
        : `${API_URL}/${resource}`;
      return {
        url: url,
        options: options,
      };
    case GET_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      return { url: `${API_URL}/${resource}?${stringify(query)}` };
    }
    case GET_MANY_REFERENCE: {
      // const {page, perPage} = params.pagination;
      // const {field, order} = params.sort;
      // const query = {
      //
      // };
      return { url: `${API_URL}/${resource}` };
    }
    case UPDATE_LIST:
      return {
        url: `${API_URL}/${resource}`,
        options: { method: "PUT", data: params.data },
      };

    case UPDATE_ITEM:
      return {
        url: `${API_URL}/${resource}`,
        options: { method: "PUT", data: params.data },
      };

    case BULK_REPORT_UPLOADER:
      let pagination = {};
      if (params.pagination) {
        let { page, perPage } = params.pagination;
        pagination = {
          page: JSON.stringify(page),
          rows: JSON.stringify(perPage),
        };
      }

      const query = {
        ...pagination,
      };

      return {
        url: `${API_URL}/${resource}?${stringify(query)}`,
        options: { method: "GET", data: params.data },
      };

    case UPLOAD_FILE:
      return {
        url: `${apiEndpoint}/${resource}`,
        options: { method: 'POST', data: params.data }
      };

    case UPLOAD_FILE_S3:
      let formData = new FormData();

      formData.set("key", params.data["key"]);
      formData.set("x-amz-algorithm", params.data["x-amz-algorithm"]);
      formData.set("x-amz-credential", params.data["x-amz-credential"]);
      formData.set("x-amz-date", params.data["x-amz-date"]);
      formData.set("x-amz-security-token", params.data["x-amz-security-token"]);
      formData.set("policy", params.data["policy"]);
      formData.set("x-amz-signature", params.data["x-amz-signature"]);
      formData.append("file", params.data["downloadFile"]);

      return {
        url: `${resource}`,
        options: { method: "POST", data: formData },
      };

    case DOWNLOAD_FILE:
      const apiEnpoint = getApiEndpoint(apiEndointType);
      return {
        url: `${apiEnpoint}/${resource}`,
        options: { method: "POST", data: params.data },
      };

    case DOWNLOAD_FILE_GET:
      return {
        url: `${API_URL}/${resource}`,
        options: { method: "GET", data: params.data },
      };

    case SEND_PASSWORD_LINK:
      return {
        url: `${API_URL}/mfa/reset_pwd/${params.userId}`,
        options: {
          method: "PUT",
          data: {
            userId: params.userId,
          },
        },
      };

    case UPDATE:
      const UPDATE_url = params.hasOwnProperty("id")
        ? `${API_URL}/${resource}/${params.id}`
        : `${API_URL}/${resource}`;
      return {
        url: UPDATE_url,
        options: { method: "PUT", data: params.data },
      };

    case POST:
      return {
        url: `${apiEndpoint}/${resource}`,
        options: { ...options, method: 'POST', data: params.data },
      };

    case CREATE:
      return {
        url: `${apiEndpoint}/${resource}`,
        options: { ...options, method: 'POST', data: params.data },
      };
    case READ_NOTIFICATION:
      return {
        url: `${API_URL}/users/${params.userId}/notifications/${params.reqID}/read`,
        options: { method: "POST", data: {} },
      };
    case DELETE:
      const DELETE_url = params.hasOwnProperty("id")
        ? `${API_URL}/${resource}/${params.id}`
        : `${API_URL}/${resource}`;
      return {
        url: DELETE_url,
        options: { method: "DELETE" },
      };
    case BULK_DELETE:
      return {
        url: `${API_URL}/${resource}/${BULK_DELETE}?id=${params.id}`,
        options: { method: "DELETE" },
      };
    case BULK_EDIT:
      return {
        url: `${API_URL}/${resource}/${BULK_EDIT}`,
        options: { method: "PUT", data: params.data },
      };
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (
  response,
  type,
  resource,
  params
) => {
  switch (type) {
    case GET_LIST:
      return {
        data: response.data.data || [],
        message: response.data.message,
        total: response.data.total,
      };
    case BULK_DELETE: {
      return response.data;
    }
    case UPLOAD_FILE:
      return { data: response.data.data };
    case DOWNLOAD_FILE:
      return { data: response.data.data, message: response.data.message };
    case DOWNLOAD_FILE_GET:
      return { data: response.data.data, message: response.data.message };
    case UPLOAD_FILE_S3:
      return { status: response.status };
    case POST:
      return {
        data: response.data.data
      };
    case CREATE:
      return {
        data: { ...params.data, id: response.data.data.id },
        message: response.data.message,
      };
    case UPDATE_LIST:
      return { data: { ...params.data } };
    case UPDATE_ITEM:
      return { data: { ...params.data }, message: response.data.message };
    case UPDATE:
      return { data: response.data.data, message: response.data.message };
    case BULK_REPORT_UPLOADER:
      return { data: response.data.data };
    default:
      const data =
        (response.data.data !== null && response.data.data.length) > 0
          ? response.data.data[0]
          : false;
      return { data: data };
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */

export default async (type, resource, params, dataProviderOptions = {}) => {
  const {
    debug = "null",
    apiEndointType = "primary",
    cancelToken = null,
  } = dataProviderOptions;

  if (debug &&
    debug.hasOwnProperty('responseWithErrorStatus') &&
    window.confirm(`Simulate server error - ${debug.responseWithErrorStatus} ?`)
  ) {
    return apiErrorSimulator(debug)
      .then((response) => {
        return convertHTTPResponseToDataProvider(
          response,
          type,
          resource,
          params
        );
      })
      .catch((err) => {
        return errors.handle(err);
      });
  } else {
    const { options, url } = convertDataProviderRequestToHTTP(
      type,
      resource,
      params,
      apiEndointType
    );

    if (cancelToken) {
      options.cancelToken = cancelToken;
    } else {
      options.cancelToken = sourceAll.token;
    }

    return httpClient(url, options, type)
      .then((response) => {
        return convertHTTPResponseToDataProvider(
          response,
          type,
          resource,
          params
        );
      })
      .catch((err) => {
        return errors.handle(err);
      });
  }
};

const cancelCurrentCalls = () => {
  sourceAll.cancel("All requests canceled");
  sourceAll = CancelToken.source();
};

export {
  BULK_DELETE,
  BULK_EDIT,
  UPDATE_LIST,
  UPDATE_ITEM,
  UPLOAD_FILE,
  UPLOAD_FILE_S3,
  DOWNLOAD_FILE,
  READ_NOTIFICATION,
  BULK_REPORT_UPLOADER,
  DOWNLOAD_FILE_GET,
  GET_ONE,
  GET_LIST,
  POST,
  CREATE,
  DELETE,
  UPDATE,
  SEND_PASSWORD_LINK,
  cancelCurrentCalls,
};
