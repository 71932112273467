import React from "react";
import SmartFilterContext from "./SmartFilterContext";

export default function withSmartFilter() {
  return (Component) => (props) => {
    return (
      <SmartFilterContext.Consumer>
        {(smartFilter) => {
        //   console.log(smartFilter);
          return (
            <Component
              smartFilter={{
                init: smartFilter.init,
                handleInputs: smartFilter.handleInputs,
                applyFilter: smartFilter.applyFilter,
                clearAllTagValues: smartFilter.clearAllTagValues,
                clearTagValue: smartFilter.clearTagValue,
                getFilter: smartFilter.getFilter,
              }}
              {...props}
            />
          );
        }}
      </SmartFilterContext.Consumer>
    );
  };
}
