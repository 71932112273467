import React from "react";
import AddEventsModalContainer from "components/pages/clients/ClientsShow/AddEventsModalContainer.js";
import useModal from "hooks/useModal.js";
import SmartTable from "components/ui_components/table/SmartTable.js";
import { TableHeaderAddButton } from "components/ui_components/buttons";

const eventsTableConfig = [
  {
    id: "eventName",
    label: "Name",
  },
  {
    id: "programName",
    label: "Type",
  },
];

let smartTableRef = {};

const EventsTab = ({ clientId, isEditAvailable }) => {
  const { modalIsOpen, handleCloseModal, handleOpenModal } = useModal();

  const handleSuccessfulSubmitNewEvent = () => {
    smartTableRef.current.loadData();
  };

  return (
    <React.Fragment>
      {isEditAvailable && (
        <AddEventsModalContainer
          modalOpen={modalIsOpen}
          handleCloseModal={handleCloseModal}
          handleSuccessfulSubmitNewEvent={handleSuccessfulSubmitNewEvent}
          clientID={clientId}
        />
      )}

      <SmartTable
        resource={`clients/${clientId}/events`}
        onRef={(ref) => (smartTableRef.current = ref)}
        columns={eventsTableConfig}
        perPage={-1}
        showSelect={isEditAvailable}
        showSelectAll={isEditAvailable}
        deleteResource="events"
        showPagination={false}
        toolbarExtraComponents={
          isEditAvailable && (
            <TableHeaderAddButton onClick={handleOpenModal}>
              Event
            </TableHeaderAddButton>
          )
        }
      />
    </React.Fragment>
  );
};

export default EventsTab;
