import React from 'react';
import Input from '@material-ui/core/Input';
import withAutoFill from './withAutoFill';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-input': {
      padding: '6px 10px 7px'
    }
  },
}));

const Password = ({ input, inputId, handleInputs, style, autoComplete, value }) => {
  const classes = useStyles();

  return (
    <FormControl className={style.textField} error={input.error}>
      <InputLabel htmlFor="adornment-password">
        {input.error ? input.errorMessage : input.label}
      </InputLabel>
      <Input
        classes={classes}
        autoComplete={autoComplete || input.autoComplete}
        key={inputId}
        name={inputId}
        type={'password'}
        value={value || ''}
        required={input.hasOwnProperty('yup')}
        onChange={handleInputs}
        startAdornment={input.icon &&
          <InputAdornment position="start">
            {input.icon}
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

export default withAutoFill(Password);