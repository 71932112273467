import React from 'react';
import styles from './StatusTableCellValue.module.scss';
import clsx from "clsx";

function StatusTableCellValueContainer(props) {
    return (
        <span className={clsx(
            styles[props.status],
            styles.root)
        }>
            {props.children}
        </span>
    );
}

export default StatusTableCellValueContainer;