import React from 'react';
import moment from 'moment-timezone';
import IconValueWarning from '../../../components/ui_components/table/IconValue/IconValueWarning'
import { formatServerDateTimeToLocal } from '../../../utils/dateTimeFormat'

export default [
  {
    id: "userFirstName",
    label: "First Name",
  },
  {
    id: "userLastName",
    label: "Last Name",
  },
  {},
  {
    id: "userName",
    label: "Username",
  },
  {
    id: "userEmail",
    label: "Email",
  },
  {
    id: "userRoleDesc",
    label: "Role",
  },
  {
    id: "userStatusDesc",
    label: "Status",
  },
  {
    id: 'userCreateDate',
    label: 'Date Added',
    accessor: (d) => formatServerDateTimeToLocal(d.userCreateDate, 'date'),
  },
  {
    id: 'lastLogonDate',
    label: 'Last Login Date',
    accessor: (d) => formatServerDateTimeToLocal(d.lastLogonDate, 'dateTime'),
  },
  {
    id: 'tempPwdExpiredDate',
    label: 'Temp Password Expiration Date',
    style: {
      display: 'flex',
      justifyContent: 'center',
    },
    accessor: (d) => {
      if (!d.tempPwdExpiredDate) {
        return;
      }
      const expirationDate = formatServerDateTimeToLocal(d.tempPwdExpiredDate, 'date');
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ paddingRight: 5 }}>
            {expirationDate}
          </span>
          {moment(expirationDate).unix() <= moment().unix() && (
            <IconValueWarning />
          )}
        </div>
      )
    }
  }
];
