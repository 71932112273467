import { useState, useEffect } from "react";
import axios from "axios";
import dataProvider, { GET_LIST } from "providers/dataProvider";

export default function (config) {
  const [data, setData] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(false);

  const init = () => {
    axios
    .all(
      config.byId.map(id => {
        const item = config.byHash[id];
        return dataProvider(GET_LIST, item.resource);
      })
  )
  .then(
    axios.spread((...responses) => {
      const data = {};
      config.byId.forEach((id, index) => {
        data[id] = responses[index].data;
      })
      setData(data);
      setIsDataLoading(false)
    })
  );
  }
  useEffect(init, []);

  return {
    data,
    isDataLoading
  };
}
