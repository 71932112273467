import React from 'react';
import withForm from 'withForm';
import { changePasswordFormConfig } from "config";
import { compose } from 'redux'
import { BaseForm } from 'components/ui_components/Forms';
import { ButtonGroup } from 'components/ui_components/buttons';
import ButtonBase from '@material-ui/core/ButtonBase';

const NewPasswordForm = (props) => {
  const { isFormValid, resetErrors, getSubmitData, inputsById, inputsByHash, handleInputs, onSubmitOk } = props;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid()) {
      resetErrors();

      const { newPwd, currentPassword } = getSubmitData();

      onSubmitOk(
        currentPassword,
        newPwd,
      );
    }
  }

  return (
    <React.Fragment>
      <form>
        <BaseForm
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          inputWrapStyles={`width: 100%;`}
        />

        <ButtonGroup rootStyles={'padding: 20px 0 0; text-align: center;'}>
          <ButtonBase
            onClick={handleSubmit}
            type="submit"
            className="button formButton formButton__mainContained"
          >
            Submit
          </ButtonBase>
        </ButtonGroup>
      </form>
    </React.Fragment>
  );
}

export default compose(
  withForm(changePasswordFormConfig)
)(NewPasswordForm)