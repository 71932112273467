import React from "react";
import { DownloadIcon } from "icons";
import { SmartTable } from "components/ui_components/table";
import Uploader from "components/ui_components/Uploader.js";
import clientDocumentsTable from "config/resources/clientsResource/clientDocumentsTable.js";
import useDownloader from "hooks/useDownloader.js";

let smartTableRef = {};

const ClientDocuments = ({ clientId, isEditAvailable }) => {
  const { handleDownloadClick, dataLoading } = useDownloader({
    resourceAccessor: (row) =>
      `clients/${row.clientId}/docs/${row.id}/download`,
  });
  const onSubmitOk = () => {
    smartTableRef.current.loadData();
  };

  return (
    <SmartTable
      resource={`clients/${clientId}/docs`}
      perPage={-1}
      columns={clientDocumentsTable}
      onRef={(ref) => (smartTableRef.current = ref)}
      showPagination={false}
      showSelect={isEditAvailable}
      showSelectAll={isEditAvailable}
      customRowActions={[
        {
          icon: <DownloadIcon />,
          handleOnClick: handleDownloadClick,
          tooltipText: "Download document",
          showProgress: dataLoading,
        },
      ]}
      toolbarExtraComponents={
        isEditAvailable && (
          <Uploader
            onSubmitOk={onSubmitOk}
            initResource={`clients/${clientId}/docs/init`}
            finishResource={`clients/${clientId}/docs`}
          >
            Document
          </Uploader>
        )
      }
    />
  );
};

export default ClientDocuments;
