const yup = require('yup');
const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

const getYupDateDay = () => {
  return yup
  .number()
  .typeError('Date must be a number')
  .min(1, 'Invalid date')
  .max(31, 'Invalid date')
  .transform(emptyStringToNull)
  .nullable()
}

const getYupDateMonth = () => {
  return yup
  .number()
  .typeError('Month must be a number')
  .min(1, 'Invalid month')
  .max(12, 'Invalid month')
  .transform(emptyStringToNull)
  .nullable()
}

const getYupDateYear = () => {
  return yup
  .string()
  .matches(/^\d+$/)
  .min(4, 'Invalid year')
  .max(4, 'Invalid year')
  .transform(emptyStringToNull)
  .nullable()
}

export {
  emptyStringToNull,
  getYupDateDay,
  getYupDateMonth,
  getYupDateYear,
};