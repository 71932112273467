import React from "react";
import { Save, HourglassEmpty } from "@material-ui/icons";
import {
  NextStatusIcon,
  BackStatusIcon,
  CryoIcon,
  BarCodeIcon,
  DownloadIcon,
  Delivery2Icon,
  NotificationIcon,
} from "icons";
import { userRoles } from "config/userRoles";
import { find } from "lodash";

const getDefaultExtraTableConfiguration = () => {
  return {
    collapsibleTable: false,
  };
};

const getResource = (status) => {
  const resourceConf = {
    All: "requisitions/ALL",
    AllForClient: "requisitions/CLIENTPHYSROLE",
    AllForPhysician: "requisitions/CLIENTPHYSROLE",
    Logged: "requisitions/LOG",
    Accessioned: "requisitions/ACC",
    Processing: "requisitions/PROCESS",
    Resulted: "requisitions/RESULT",
    Reported: "requisitions/REPORT",
    Released: "requisitions/RELEASE",
    Delivered: "requisitions/DELIVER",
    Billing: "requisitions/BILL",
  };
  return resourceConf[status];
};

const getAction = ({ action, handler, tooltipText = null, ...rest }) => {
  const actions = {
    goToStatus: {
      icon: <BackStatusIcon />,
      tooltipText: tooltipText || "Send to next status",
      ...rest,
    },
    goToNextStatus: {
      icon: <NextStatusIcon />,
      tooltipText: tooltipText || "Send to next status",
      ...rest,
    },
    printCryo: {
      icon: <CryoIcon />,
      tooltipText: tooltipText || "Print Cryo Label",
      ...rest,
    },
    printBarCode: {
      icon: <BarCodeIcon />,
      tooltipText: tooltipText || "Print Bar Code",
      ...rest,
    },
    download: {
      icon: <DownloadIcon />,
      tooltipText: tooltipText || "Download Reports",
      ...rest,
    },
    delivery: {
      icon: <Delivery2Icon />,
      tooltipText: tooltipText || "Delivery",
      ...rest,
    },
  };

  return {
    handleOnClick: handler,
    ...actions[action],
  };
};

const getToolBarAction = ({ action, handler, tooltipText = null, ...rest }) => {
  const actions = {
    download: {
      icon: <DownloadIcon />,
      tooltipText: tooltipText || "Download reports",
      ...rest,
    },
    delivery: {
      icon: <Delivery2Icon />,
      tooltipText: tooltipText || "Delivery",
      ...rest,
    },
    goToNextStatus: {
      icon: <NextStatusIcon />,
      tooltipText: tooltipText || "Send to next status",
      ...rest,
    },
    notifyPatient: {
      icon: <NotificationIcon />,
      tooltipText: tooltipText || "Notify Patient",
      ...rest,
    }
  };

  return {
    handleOnClick: handler,
    ...actions[action],
  };
};

const getExtraTableConfiguration = ({
  status,
  actionHandlers,
  resultsInputData,
  smartFilter,
}) => {
  const STATUSES = {
    AllForClient: {
      collapsibleTable: true,
      collapsibleTableContentType: ["releasedReports"],
      customRowActions: [
        getAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadDeliverSource,
          isHiddenAccessor: (d) => {
            return d.isDownloadable === 0;
          },
        }),
        {
          icon: <HourglassEmpty />,
          tooltipText: "Result Pending",
          isHiddenAccessor: (d) => {
            return d.isDownloadable !== 0;
          },
        },
      ],
      customToolbarActions: [
        getToolBarAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadOnlyIsDownloadable,
          isHiddenAccessor: (rows, selectedRows) => {
            if (find(selectedRows, { isDownloadable: 1 })) {
              return false;
            } else {
              return true;
            }
          },
        }),
        getToolBarAction({
          action: "notifyPatient",
          handler: actionHandlers.handleBulkNotify,
          visibleForRoles: [], // [userRoles.collaborator, userRoles.client, userRoles.physician]
        }),
      ],
    },
    AllForPhysician: {
      collapsibleTable: true,
      collapsibleTableContentType: ["releasedReports"],
      customRowActions: [
        getAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadDeliverSource,
          isHiddenAccessor: (d) => {
            return d.isDownloadable === 0;
          },
        }),
        {
          icon: <HourglassEmpty />,
          tooltipText: "Result Pending",
          isHiddenAccessor: (d) => {
            return d.isDownloadable !== 0;
          },
        },
      ],
      customToolbarActions: [
        getToolBarAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadOnlyIsDownloadable,
          isHiddenAccessor: (rows, selectedRows) => {
            if (find(selectedRows, { isDownloadable: 1 })) {
              return false;
            } else {
              return true;
            }
          },
        }),
        getToolBarAction({
          action: "notifyPatient",
          handler: actionHandlers.handleBulkNotify,
          visibleForRoles: [], // [userRoles.collaborator, userRoles.client, userRoles.physician]
        }),
      ],
    },
    All: {
      customRowActions: [
        getAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadDeliverSource,
          hideForRoles: [userRoles.sales],
        }),
      ],
      customToolbarActions: [
        getToolBarAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadDeliverSource,
          hideForRoles: [userRoles.sales],
        }),
        getToolBarAction({
          action: "notifyPatient",
          handler: actionHandlers.handleBulkNotify,
          visibleForRoles: [], // [userRoles.collaborator, userRoles.client, userRoles.physician]
        }),
      ],
    },
    Logged: {
      customRowActions: [
        getAction({
          action: "goToNextStatus",
          tooltipText: "Send to Accessioned",
          handler: actionHandlers.handleBulkNextStatusLogSource,
        }),
        getAction({
          action: "printBarCode",
          handler: actionHandlers.handlePrintBarCodeAction,
        }),
        getAction({
          action: "printCryo",
          handler: actionHandlers.handlePrintCryoAction,
        }),
      ],
      customToolbarActions: [
        getToolBarAction({
          action: "goToNextStatus",
          tooltipText: "Send to Accessioned",
          handler: actionHandlers.handleBulkNextStatusLogSource,
        }),
      ],
    },
    Accessioned: {
      collapsibleTable: true,
      collapsibleTableContentType: ["reqHistory", "scanDocuments", "speciments"],
      customRowActions: [
        getAction({
          action: "goToNextStatus",
          tooltipText: "Send to Processing",
          handler: actionHandlers.handleBulkNextStatusAccSource,
        }),
      ],
      customToolbarActions: [
        getToolBarAction({
          action: "goToNextStatus",
          tooltipText: "Send to Processing",
          handler: actionHandlers.handleBulkNextStatusAccSource,
        }),
      ],
    },
    Processing: {
      collapsibleTable: true,
      collapsibleTableContentType: ["testResults"],
      collapsibleTableProps: {
        isVisibleShowButtonAccessor: (row) => row.testCodes !== null,
        filter: smartFilter,
      },
      customRowActions: [
        {
          icon: <Save />,
          handleOnClick: actionHandlers.handleSaveTestResults,
          tooltipText: "Save",
          isHiddenAccessor: (row) => {
            return !resultsInputData.hasOwnProperty(row.id);
          },
        },
      ],
    },
    Resulted: {
      collapsibleTable: true,
      collapsibleTableContentType: ["resultedTests", "releasedReportsResulted"],
      customRowActions: [
        getAction({
          action: "goToNextStatus",
          handler: actionHandlers.handleBulkNextStatusResultSource,
          tooltipText: "Send to Reported",
        }),
        getAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadResultSource,
        }),
      ],
      customToolbarActions: [
        getToolBarAction({
          action: "goToNextStatus",
          handler: actionHandlers.handleBulkNextStatusResultSource,
          tooltipText: "Send to Reported",
        }),
        getToolBarAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadResultSource,
        }),
      ],
    },
    Reported: {
      collapsibleTable: true,
      collapsibleTableContentType: ["releasedReportsReported"],
      collapsibleTableProps: {
        isVisibleShowButtonAccessor: (row) => row.latestReportedDate !== null,
      },
      customRowActions: [
        getAction({
          action: "goToNextStatus",
          tooltipText: "Send to Released",
          handler: actionHandlers.handleBulkNextStatusReportSource,
        }),
        getAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadReportSource,
        }),
      ],
      customToolbarActions: [
        getToolBarAction({
          action: "goToNextStatus",
          tooltipText: "Send to Released",
          handler: actionHandlers.handleBulkNextStatusReportSource,
        }),
        getToolBarAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadReportSource,
        }),
      ],
    },
    Released: {
      collapsibleTable: true,
      collapsibleTableContentType: ["releasedReportsReleased"],
      customRowActions: [
        getAction({
          action: "goToNextStatus",
          tooltipText: "Send to Delivered",
          handler: actionHandlers.handleBulkNextStatusReleaseSource,
        }),
        getAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadReleaseSource,
        }),
        getAction({
          action: "delivery",
          handler: actionHandlers.handleDeliveryClick,
        }),
      ],
      customToolbarActions: [
        getToolBarAction({
          action: "goToNextStatus",
          tooltipText: "Send to Delivered",
          handler: actionHandlers.handleBulkNextStatusReleaseSource,
        }),
        getToolBarAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadReleaseSource,
        }),
        getToolBarAction({
          action: "delivery",
          handler: actionHandlers.handleBulkDeliveryClick,
        }),
      ],
    },
    Delivered: {
      collapsibleTable: true,
      collapsibleTableContentType: ["releasedReportsDelivered"],
      customRowActions: [
        getAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadDeliverSource,
        }),
      ],
      customToolbarActions: [
        getToolBarAction({
          action: "download",
          handler: actionHandlers.handleBulkDownloadDeliverSource,
        }),
      ],
    },
  };
  const currentConfiguration = STATUSES[status] || {};

  return { ...getDefaultExtraTableConfiguration, ...currentConfiguration };
};

export {
  getExtraTableConfiguration,
  getDefaultExtraTableConfiguration,
  getResource,
};
