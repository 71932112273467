import React from "react";
import {
  Route,
  Redirect
} from "react-router-dom";

import authStorage from 'providers/authStorage.js';
import { canByDepartments, canByRoles } from "utils/permissions.js";

const SmartRoute = ({ layout, layoutProps, accessRoles, accessDepts, component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const renderComponent = layout
          ? React.createElement(
              layout,
              { ...props, ...layoutProps },
              React.createElement(component, props)
            )
          : React.createElement(component, props);

      if (authStorage.getAccess()) {
        const hasAccess = canByRoles(accessRoles) && canByDepartments(accessDepts);
        return (hasAccess ?
            renderComponent :
            <Redirect
              to={{
                pathname: "/403",
                from: props.from
              }}
            />
        )
      } else {
        return <Redirect
          to={{
            pathname: "/login",
            from: props.from
          }}
        />
      }
    }}/>
  );
};

export default SmartRoute;

SmartRoute.defaultProps = {
  accessRoles: [],
  accessDepts: [],
};
