export const yesNoAccessor = (d, key) => {
  return d[key] ? "Y" : "N";
};

export const yesNoAccessorFull = (value) => {
  return value ? "Yes" : "No";
};

export const heightAccessor = (height, heightUom) => {
  let value = "";

  if (!height || !heightUom) return "";

  if (heightUom === "CM") {
    value = `${height} ${heightUom}`;
  } else if (heightUom === "INCH") {
    const feetValue = `${Math.floor(height / 12)} Feet`;

    const inchesNumValue = height % 12;
    const inchesValue = inchesNumValue !== 0 ? `${inchesNumValue} Inches` : "";

    value = `${feetValue} ${inchesValue}`;
  }
  return value;
};

export const weightAccessor = (weight, weightUom) => {
  if (!weight || !weight) return "";

  return `${weight} ${weightUom}`;
};
