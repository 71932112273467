import { formatServerDateTimeToLocal } from "utils/dateTimeFormat.js";

export default [
  {
    id: "modifiedDate",
    label: "Time",
    accessor: (d) => formatServerDateTimeToLocal(d.modifiedDate, "dateTime"),
  },
  {
    id: "auditActionDesc",
    label: "Action",
  },
  {
    id: "modifiedByName",
    label: "User",
  },
];
