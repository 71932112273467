import React, { useState } from 'react';
import { TableHeaderAddButton } from 'components/ui_components/buttons';
import {
  Delivery2Icon
} from 'icons'
import dataProvider, { GET_LIST } from "providers/dataProvider"
import { useNotificationCenter } from 'NotificationCenter';

const ExportProcessedTestButton = ({ reqID }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const notificationCenter = useNotificationCenter();

  const handleClick = () => {
    setDataLoading(true);
    dataProvider(GET_LIST, `requisitions/${reqID}/req_tests/export`).then(({ data, message }) => {
      if (data.hasOwnProperty('url')) {
        window.open(data.url);
        notificationCenter.show(
          'If download failed, please click here',
          'info',
          'Download',
          () => {},
          data.url
        )
      }
      else {
        notificationCenter.show(message, 'warning')
      }
      setDataLoading(false);
    }).catch(error => {
      notificationCenter.show(error.message, 'warning')
      setDataLoading(false);
    });
  }

  return (
    <TableHeaderAddButton
      icon={<Delivery2Icon/>}
      onClick={handleClick}
      showProgressBar={dataLoading}
    >
      Export Processed Test
    </TableHeaderAddButton>
  )
}

export default ExportProcessedTestButton;