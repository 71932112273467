import { formatServerDateTimeToLocal } from 'utils/dateTimeFormat.js';

export default {
  tableColConfig: [
    {
      id: 'modifiedDate',
      label: 'Time',
      accessor: d => formatServerDateTimeToLocal(d.modifiedDate, 'dateTime'),
    },
    {
      id: 'reqActionDesc',
      label: 'Action'
    },
    {
      id: 'modifiedByName',
      label: 'User'
    },
  ]
}