import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Person3Icon = (props) => (
    <SvgIcon {...props}>
        <path d="M22.9,0H1.1C0.5,0,0,0.5,0,1.1v21.8C0,23.5,0.5,24,1.1,24h21.8c0.6,0,1.1-0.5,1.1-1.1V1.1C24,0.5,23.5,0,22.9,0
	z M15.3,15.8l0,1.7H7.8l0-1.7v-1.4c0-0.7,0.8-1,1.4-1.2c0.1-0.1,0.3-0.1,0.4-0.2c0,0-0.1-0.1-0.1-0.1c-0.5-0.6-1.1-1.2-1.1-2.4
	c0-0.1-0.1-0.7-0.1-0.9c-0.2-2,0.9-2.3,2.4-2.8c0.2-0.1,0.5-0.1,0.7-0.2c0.3-0.1,0.6-0.2,0.9-0.2c0.6,0,1.2,0.2,1.6,0.7
	c0.5,0.6,0.8,1.5,0.7,2.5l-0.1,0.9c0,1.2-0.6,1.8-1.1,2.4c0,0-0.1,0.1-0.1,0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.6,0.2,1.4,0.5,1.4,1.2
	V15.8z"/>
    </SvgIcon>
);

export default Person3Icon;