import React, {PureComponent} from 'react';
import Modal from '@material-ui/core/Modal';
import ButtonBase from '@material-ui/core/ButtonBase';
import styles from './AddEventsModalContainer.module.scss';
import dataProvider, {CREATE} from "providers/dataProvider";
import withForm from 'withForm'
import style from "./AddEventsModalContainer.module.scss";
import SmartInput from 'components/ui_components/smart_input';
import { compose } from 'redux'
import {withNotificationCenter} from 'NotificationCenter';

let yup = require('yup');

let withFormConfig = {
  inputsById: ['eventName', 'programId'],
  inputsByHash: {
    programId: {
      label: 'Event Type',
    type: 'autocomplete',
    resource: 'programs',
    accessor: (d) => ({value: d.id, label: d.programName}),
      yup: yup
          .string()
          .required('Program ID is required')
    },
    eventName: {
      label: 'Name',
      yup: yup.string()
          .required('Event Name is required')
    }
  }
}

class ClientsModalContainer extends PureComponent {

  handleSubmit = () => {
    const {notificationCenter, handleCloseModal, handleSuccessfulSubmitNewEvent, clearInputs} = this.props;
    this.props.resetErrors();

    if (!this.props.isFormValid()) return;

    const {eventName, programId} = this.props.getSubmitData();
    let data = {clientId: parseInt(this.props.clientID), eventName: eventName, programId: parseInt(programId)};

    dataProvider(CREATE, 'events', {data: data})
        .then((response) => {
          notificationCenter.show('Successfully create', 'info')
          handleCloseModal();
          clearInputs();
          handleSuccessfulSubmitNewEvent(response.data)
        })
        .catch(error => {
          notificationCenter.show(error.message, 'warning')
        });
  }

  render() {
    const {modalOpen, handleCloseModal} = this.props;

    return (
        <Modal open={modalOpen} onClose={handleCloseModal}>
          <div className={styles.root}>
            <h2 className="ttl ttl-2">
              Add Event
            </h2>
            <div className={style.inputsGroup}>
              {this.props.inputsById.map((inputId, index) => {
                const input = this.props.inputsByHash[inputId];

                let params = {inputId, input, style: styles, handleInputs: this.props.handleInputs}
                return (
                    <div key={index} className={style.textFieldWrap}>
                      <SmartInput {...params} />
                    </div>)
              })}

            </div>

            <div className={`${'buttonGroup'} ${styles.buttonGroup}`}>
              <ButtonBase
                  className="button formButton formButton__secondaryOutlined"
                  onClick={handleCloseModal}>
                Cancel
              </ButtonBase>
              <ButtonBase
                  className="button formButton formButton__mainContained"
                  onClick={this.handleSubmit}
              >
                Add
              </ButtonBase>
            </div>
          </div>
        </Modal>
    );
  }
}

export default compose(
    withForm(withFormConfig),
    withNotificationCenter()
)(ClientsModalContainer)