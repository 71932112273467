import helpers from "utils/helpers";
let yup = require("yup");

export default {
  inputsById: [
    "userFirstName",
    "userLastName",
    "userPhone",
    "userEmail",
    "deptIds",
    "userName",
    "userRoleCode",
    "physician",
    "collaborator",
    "clientId",
    "sales",
    "labId",
    "isSubscribed",
  ],

  inputsGroupsByHash: {
    group1: {
      title: "Group1",
      ids: ["userFirstName", "userLastName", "userPhone", "userEmail"],
    },
    group2: {
      title: "Group2",
      ids: ["deptIds"],
    },
    group3: {
      title: "Group3",
      ids: ["userName"],
    },
    group4: {
      ids: ["isSubscribed"],
    },
    group5: {
      title: "Group5",
      ids: [
        "userRoleCode",
        "physician",
        "collaborator",
        "clientId",
        "sales",
        "labId",
      ],
    },
  },

  inputsGroupsById: ["group1", "group2", "group3", "group4", "group5"],

  inputsByHash: {
    userFirstName: {
      label: "First Name",
      yup: helpers.getYupRequired("First Name"),
    },
    userLastName: {
      label: "Last Name",
      yup: helpers.getYupRequired("Last Name"),
    },
    userPhone: {
      label: "Phone Number",
      type: "phone",
      yup: yup
        .string()
        .matches(/\d{11}/, "Phone number allowed in format 00(000) 000-0000"),
    },
    userEmail: {
      label: "Email",
      yup: helpers.getYupRequired("Email"),
    },
    deptIds: {
      label: "Department",
      type: "autocomplete",
      subType: "multi",
      resource: "depts",
      accessor: (d) => ({ value: d.id, label: d.deptName }),
      yup: helpers.getYupRequired("Department"),
    },
    userName: {
      label: "Username",
      yup: helpers.getYupRequired("Username"),
    },
    isSubscribed: {
      label: "Subscribe to email notification",
      type: "radio",
      options: [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ],
      value: 0,
      submitValueAccessor: (d) => parseInt(d),
    },
    userRoleCode: {
      label: "Role",
      type: "radio",
      resource: "roles",
      accessor: (d) => ({ value: d.roleCode, label: d.roleDesc }),
      yup: helpers.getYupRequired("Role"),
    },

    sales: {
      label: "Sales",
      type: "autocomplete",
      resource: "sales",
      accessor: (d) => ({
        value: d.id,
        label: `${d.salesFirstName}, ${d.salesLastName}`,
      }),
      yup: helpers.getYupRequired("Sales"),
      excludeSubmit: true,
      singleValidation: true,
    },
    collaborator: {
      label: "Collaborator",
      type: "autocomplete",
      resource: "collaborators",
      accessor: (d) => {
        return { value: d.id, label: d.colaName };
      },
      excludeSubmit: true,
      yup: helpers.getYupRequired("Collaborator"),
      singleValidation: true,
    },
    clientId: {
      label: "Client",
      type: "autocomplete",
      resource: "clients",
      accessor: (d) => {
        return { value: d.id, label: `${d.legacyClientId} - ${d.clientName}` };
      },
      excludeSubmit: true,
      yup: helpers.getYupRequired("Client"),
      singleValidation: true,
    },
    physician: {
      label: "Choose a physician",
      type: "autocomplete",
      resource: "physicians",
      accessor: (d) => {
        return { value: d.id, label: `${d.physFirstName} ${d.physLastName}` };
      },
      excludeSubmit: true,
      yup: helpers.getYupRequired("Physician"),
      singleValidation: true,
    },
    labId: {
      label: "Choose a lab",
      type: "autocomplete",
      resource: "labs",
      accessor: (d) => {
        return { value: d.id, label: `${d.labName}` };
      },
      excludeSubmit: true,
      yup: helpers.getYupRequired("Labs"),
      singleValidation: true,
    },
  },
};
