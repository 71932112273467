import React from "react";
import { Switch } from "react-router-dom";
import MainLayout from "MainLayout";

import FrontPage from "components/pages/frontPage/FrontPage.js";

import PhysiciansListComponent from "components/pages/physicians/PhysiciansList/PhysiciansListComponent";
import PhysiciansEditContainer from "components/pages/physicians/PhysiciansEdit/PhysiciansEditContainer";
import PhysiciansShowContainer from "components/pages/physicians/PhysiciansShow/PhysiciansShowContainer";
import PhysiciansCreateContainer from "components/pages/physicians/PhysiciansCreate/PhysiciansCreateContainer";

import CollaboratorsList from "components/pages/collaborators/CollaboratorsList/CollaboratorsList";
import CollaboratorCreate from "components/pages/collaborators/CollaboratorCreate/CollaboratorCreate";
import CollaboratorsShow from "components/pages/collaborators/CollaboratorsShow/CollaboratorsShow";
import CollaboratorEdit from "components/pages/collaborators/CollaboratorEdit/CollaboratorEdit";

import ClientsListContainer from "components/pages/clients/ClientsList/ClientsListComponent";
import ClientsShowContainer from "components/pages/clients/ClientsShow/ClientsShowContainer";
import ClientCreateContainer from "components/pages/clients/ClientCreate/ClientCreateContainer";
import ClientEditContainer from "components/pages/clients/ClientEdit/ClientEditContainer";

import UsersListContainer from "components/pages/users/UsersList/UsersListComponent";
import UserCreateContainer from "components/pages/users/UserCreate/UserCreateContainer.js";
import UserShowContainer from "components/pages/users/UserShow/UserShowContainer";
import UserEditContainer from "components/pages/users/UserEdit/UserEditContainer";

import UserProfileShow from "components/pages/userProfile/UserProfileShow/UserProfileShow";
import UserProfileEdit from "components/pages/userProfile/UserProfileEdit/UserProfileEdit";

import SalesListContainer from "components/pages/sales/SalesList/SalesListComponent";
import SalesShowContainer from "components/pages/sales/SalesShow/SalesShowContainer";
import SalesCreateContainer from "components/pages/sales/SalesCreate/SalesCreateContainer";
import SalesEditContainer from "components/pages/sales/SalesEdit/SalesEditContainer";

import RequisitionsListComponent from "components/pages/requisitions/RequisitionsList/RequisitionsListComponent";
import RequisitionsShowComponent from "components/pages/requisitions/RequisitionsShow/RequisitionsShowContainer";
import RequisitionsCreateComponent from "components/pages/requisitions/RequisitionsCreate/RequisitionsCreateContainer";

import LoginPage from "components/pages/login/LoginContainer.js";
import ResetPasswordPage from "components/pages/resetPassword/ResetPasswordContainer";

import FreezerReverseLookup from "components/pages/freezerReverseLookup/FreezerReverseLookup.js";

import { userRoles, userGroups, userPermissions } from "config/userRoles";

import SmartRoute from "SmartRoute";
import GuestRoute from "GuestRoute";
import Reports from "components/pages/reports/Reports";
import TestReportsUpload from "components/pages/testReportsUpload/TestReportsUploadContainer";

import BatchCsvUpload from "components/pages/batchCsvUpload/BatchCsvUpload.js";

import authStorage from "providers/authStorage.js";
import Error404 from "components/pages/errors/Error404";
import Error403 from "components/pages/errors/Error403";

import SubscribePage from "components/pages/subscribe/SubscribePageContainer";

const Routes = () => (
  <Switch>
    <GuestRoute path="/login" component={LoginPage} />

    <GuestRoute path="/reset/:token" exact component={ResetPasswordPage} />

    <GuestRoute
      path="/unsubscribe/:token"
      allowRegistered
      exact
      component={(props) =>
        React.createElement(SubscribePage, {
          type: "unsubscribe",
          ...props,
        })
      }
    />
    
    <GuestRoute
      path="/resubscribe/:token"
      allowRegistered
      exact
      component={(props) =>
        React.createElement(SubscribePage, {
          type: "resubscribe",
          ...props,
        })
      }
    />

    <SmartRoute
      path={`/`}
      layout={MainLayout}
      exact
      component={(props) => React.createElement(FrontPage, {})}
      accessRoles={userGroups.allRegistered}
    />

    <SmartRoute
      path={`/collaborators/create`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(CollaboratorCreate, {
          resource: "collaborators",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />

    <SmartRoute
      path={`/freezer-specs/`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(FreezerReverseLookup, {
          resource: "freezer_specs",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />

    <SmartRoute
      path={`/collaborators/:id`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(CollaboratorEdit, {
          resource: "collaborators",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />
    <SmartRoute
      path={`/collaborators/:id/show`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(CollaboratorsShow, {
          resource: "collaborators",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />

    <SmartRoute
      path={`/collaborators/`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(CollaboratorsList, {
          resource: "collaborators",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />

    <SmartRoute
      path={`/clients/create`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(ClientCreateContainer, {
          resource: "clients",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />
    <SmartRoute
      path={`/clients/:id`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(ClientEditContainer, {
          resource: "clients",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />
    <SmartRoute
      path={`/clients/:id/show`}
      layout={MainLayout}
      layoutProps={{ showFooter: true }}
      exact
      component={(props) =>
        React.createElement(ClientsShowContainer, {
          resource: "clients",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={[...userGroups.superAdmins, userRoles.sales]}
    />
    <SmartRoute
      path={`/clients/`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(ClientsListContainer, {
          resource: "clients",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={[...userGroups.superAdmins, userRoles.sales]}
    />

    <SmartRoute
      path={`/physicians/create`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(PhysiciansCreateContainer, {
          resource: "physicians",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />
    <SmartRoute
      path={`/physicians/:id`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(PhysiciansEditContainer, {
          resource: "physicians",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />
    <SmartRoute
      path={`/physicians/:id/show`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(PhysiciansShowContainer, {
          resource: "physicians",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={[...userGroups.superAdmins, userRoles.sales]}
    />
    <SmartRoute
      path={`/physicians/`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(PhysiciansListComponent, {
          resource: "physicians",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={[...userGroups.superAdmins, userRoles.sales]}
    />

    <SmartRoute
      path={`/sales/create`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(SalesCreateContainer, {
          resource: "sales",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />
    <SmartRoute
      path={`/sales/:id`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(SalesEditContainer, {
          resource: "sales",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />
    <SmartRoute
      path={`/sales/:id/show`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(SalesShowContainer, {
          resource: "sales",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />
    <SmartRoute
      path={`/sales/`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(SalesListContainer, {
          resource: "sales",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />

    <SmartRoute
      path={`/requisitions/create`}
      exact
      layout={MainLayout}
      component={RequisitionsCreateComponent}
      accessRoles={[
        userRoles.admin,
        userRoles.collaborator,
        userRoles.management,
        userRoles.gdbstaff,
      ]}
    />
    <SmartRoute
      path={`/requisitions/`}
      exact
      layout={MainLayout}
      component={RequisitionsListComponent}
      accessRoles={userGroups.allRegistered}
    />
    <SmartRoute
      path={`/requisitions/:id/show`}
      layout={MainLayout}
      component={RequisitionsShowComponent}
      accessRoles={[
        userRoles.admin,
        userRoles.collaborator,
        userRoles.management,
        userRoles.gdbstaff,
        userRoles.sales,
      ]}
    />

    <SmartRoute
      path={`/users/create`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(UserCreateContainer, {
          resource: "users",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userPermissions.userEntity.resource}
    />
    <SmartRoute
      path={`/users/:id`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(UserEditContainer, {
          resource: "users",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userPermissions.userEntity.resource}
    />
    <SmartRoute
      path={`/users/:id/show`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(UserShowContainer, {
          resource: "users",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userPermissions.userEntity.resource}
    />
    <SmartRoute
      path={`/users/`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(UsersListContainer, {
          resource: "users",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userPermissions.userEntity.resource}
    />

    <SmartRoute
      path={`/user-profile`}
      layout={MainLayout}
      exact
      component={(props) => {
        const access = authStorage.getAccess();
        return React.createElement(UserProfileShow, {
          resource: "users",
          id: access.userId,
          ...props,
        });
      }}
      accessRoles={userPermissions.userInfo.resource}
    />
    <SmartRoute
      path={`/user-profile/edit`}
      layout={MainLayout}
      exact
      component={(props) => {
        const access = authStorage.getAccess();
        return React.createElement(UserProfileEdit, {
          resource: "users",
          id: access.userId,
          ...props,
        });
      }}
      accessRoles={userPermissions.userInfo.resource}
    />

    <SmartRoute
      path={`/freezer-specs/`}
      layout={MainLayout}
      exact
      component={(props) =>
        React.createElement(FreezerReverseLookup, {
          resource: "freezer_specs",
          id: props.match.params.id,
          ...props,
        })
      }
      accessRoles={userGroups.superAdmins}
    />

    <SmartRoute
      path={`/reports`}
      layout={MainLayout}
      component={Reports}
      accessRoles={userGroups.allRegistered}
    />

    <SmartRoute
      path={`/test-reports-upload`}
      layout={MainLayout}
      component={TestReportsUpload}
      accessRoles={userGroups.allRegistered}
    />

    <SmartRoute
      path={`/batch-csv-upload`}
      layout={MainLayout}
      component={BatchCsvUpload}
      accessRoles={userGroups.superAdmins}
    />

    <SmartRoute path="/403" component={Error403} layout={MainLayout} />
    <SmartRoute component={Error404} layout={MainLayout} />
  </Switch>
);

export default Routes;
