import helpers from "utils/helpers";

export default {
  inputsById: ["loadFileTypeCode"],

  inputsByHash: {
    loadFileTypeCode: {
      label: "Load Type",
      type: "autocomplete",
      accessor: (d) => ({
        value: d.loadFileTypeCode,
        label: d.loadFileTypeDesc,
      }),
      resource: "lovs/LOADFILETYPE",
      yup: helpers.getYupRequired("Payment Load"),
    },
  },
};
