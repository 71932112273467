import React from 'react';
import withForm from 'withForm';
import { verifyCodeFormConfig } from "config/loginPage";
import { compose } from 'redux'
import { useAuthCenter } from 'AuthCenter';
import { useNotificationCenter } from 'NotificationCenter';
import { BaseForm } from 'components/ui_components/Forms';
import { ButtonGroup } from 'components/ui_components/buttons';
import ButtonBase from '@material-ui/core/ButtonBase';

const ConfirmSignInForm = (props) => {
  const { isFormValid, resetErrors, getSubmitData, inputsById, inputsByHash, handleInputs, onConfirmOk } = props;
  const authCenter = useAuthCenter();
  const notificationCenter = useNotificationCenter();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid()) {
      resetErrors();

      try {
        props.setDataLoading(true);
        await authCenter.confirmSignIn(getSubmitData().code)
        .then((accessToken) => {
          onConfirmOk(accessToken);
        })
        .catch(err => {
          notificationCenter.show(err.message, 'error')
          props.setDataLoading(false);
        })
      }
      catch (e) {
        notificationCenter.show(e.message, 'error')
      }
    }
  }

  return (
    <React.Fragment>
      <h2 className="ttl ttl-6 text-center">
        An authentication code has been sent to your phone.<br/>
        Please enter the code below.
      </h2>

      <form>
        <BaseForm
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          inputWrapStyles={`width: 100%;`}
        />

        <ButtonGroup rootStyles={'padding: 20px 0 0; text-align: center;'}>
          <ButtonBase
            onClick={handleSubmit}
            className="button formButton formButton__mainContained"
            type='submit'
          >
            Authenticate
          </ButtonBase>
        </ButtonGroup>
      </form>

      {/*<ButtonGroup rootStyles={'padding: 20px 0 0; text-align: center;'}>*/}
        {/*<Button color="primary">*/}
          {/*Did not receive the code ?*/}
        {/*</Button>*/}
      {/*</ButtonGroup>*/}
    </React.Fragment>
  );
}

export default compose(
  withForm(verifyCodeFormConfig)
)(ConfirmSignInForm)