import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RulerIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M23.9,8.7C24,8.9,24,9.2,23.9,9.6c-0.1,0.3-0.2,0.6-0.5,0.7L4.8,20.9C4.5,21,4.2,21,3.9,21
	c-0.3-0.1-0.6-0.3-0.7-0.6l-3-5.1C0,15.1,0,14.8,0.1,14.5c0.1-0.3,0.3-0.6,0.6-0.7l2.6-1.5l2.2,3.8c0.1,0.2,0.2,0.2,0.4,0.1l0.5-0.3
	c0.2-0.1,0.2-0.2,0.1-0.4l-2.2-3.8l2.1-1.2l1,1.8c0.1,0.2,0.2,0.2,0.4,0.1l0.5-0.3c0.2-0.1,0.2-0.2,0.1-0.4l-1-1.8l2.1-1.2l2.2,3.8
	c0.1,0.1,0.2,0.2,0.4,0.1l0.5-0.3c0.2-0.1,0.2-0.2,0.1-0.4l-2.2-3.8L12.5,7l1,1.8C13.7,8.9,13.8,9,14,8.9l0.5-0.3
	c0.2-0.1,0.2-0.2,0.1-0.4l-1-1.8l2.1-1.2L17.9,9c0.1,0.1,0.2,0.2,0.4,0.1l0.5-0.3c0.2-0.1,0.2-0.2,0.1-0.4l-2.2-3.8l2.6-1.5
	C19.5,3,19.8,3,20.2,3c0.3,0.1,0.6,0.3,0.7,0.6L23.9,8.7z"/>
    </SvgIcon>
);

export default RulerIcon;