import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BackStatusIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M0.1,12.3C0.1,12.3,0.1,12.3,0.1,12.3l6.9,7c0.2,0.2,0.6,0.2,0.8,0c0.1-0.1,0.2-0.2,0.2-0.4v-3.7h8.8
	c0.3,0,0.7-0.1,0.7-0.4V9.1c0-0.4-0.3-0.7-0.7-0.7H7.9V5c0-0.2-0.1-0.4-0.3-0.5H7.4C7.2,4.5,7.1,4.6,7,4.7l-6.8,6.9
	C0,11.8-0.1,12.1,0.1,12.3z M19.1,15.2c0.3,0,0.5-0.2,0.5-0.5V8.8c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v5.8
	C18.5,15,18.8,15.2,19.1,15.2z M21.3,15.2c0.3,0,0.5-0.2,0.5-0.5V8.8c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v5.8
	C20.7,15,21,15.2,21.3,15.2z M23.5,15.2c0.3,0,0.5-0.2,0.5-0.5V8.8c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v5.8
	C22.9,15,23.1,15.2,23.5,15.2z"/>
  </SvgIcon>
);

export default BackStatusIcon;