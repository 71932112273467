let fetcher;

fetcher = fetch;


class Dymo {
    constructor(options) {
        options = options || {};

        this.hostname = options.hostname || 'localhost';
        this.port = options.port || 41951;
        this.printerName = options.printerName;
        this.printerParams = options.printerParams
    }

    get apiUrl() {
        return `https://${this.hostname}:${this.port}/DYMO/DLS/Printing`;
    }

    print(printerName, labelXml, printerParams, labelSetXml) {
        let label = `printerName=${encodeURIComponent(printerName)}&printParamsXml=${encodeURIComponent(printerParams)}&labelXml=${encodeURIComponent(labelXml)}&labelSetXml=${encodeURIComponent(labelSetXml)}`;

        if (typeof process !== 'undefined' && process.env) {
            process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'; // TODO: Bundle the certificates.
        }

        return fetcher(`${this.apiUrl}/PrintLabel`,
            {
                method: 'POST',
                body: label,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => response.text())
            .then((result) => result);
    }

    getPrinters() {
        if (typeof process !== 'undefined' && process.env) {
            process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'; // TODO: Bundle the certificates.
        }

        return fetcher(`${this.apiUrl}/GetPrinters`)
            .then((response) => response.text());
    }
}

export default Dymo;
