import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CalendarIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M20.7,2.2h-1.1V0h-2.2v2.2H6.5V0H4.4v2.2H3.3c-1.2,0-2.2,1-2.2,2.2v17.5c0,1.2,1,2.2,2.2,2.2h17.5
	c1.2,0,2.2-1,2.2-2.2V4.4C22.9,3.2,21.9,2.2,20.7,2.2z M20.7,21.8H3.3V7.6h17.5V21.8z"/>
    </SvgIcon>
);

export default CalendarIcon;