import React from 'react';
import NotificationMenuItemContainer from 'components/Notifications/NotificationMenuItemContainer'
import styles from './SidebarSubMenu.module.scss';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CustomMenuItem from './CustomMenuItem';
import Can from 'Can';
import { userPermissions } from "config/userRoles";
import { useAuthCenter } from 'AuthCenter'
import { NavLink, useHistory } from 'react-router-dom';
import routesConfig from 'config/routesConfig'
import PersonIcon from 'icons/PersonIcon';

const SidebarSubMenuContainer = (props) => {
  const authCenter = useAuthCenter();
  const history = useHistory();

  const handleLogOut = () => {
    authCenter.logOut().then(() => {
      history.push('/login');
    });
  }

  return (
    <div className={styles.root}>
      <CustomMenuItem>
        <NavLink to={`/${routesConfig.resources.userProfile.baseUrl}`}><PersonIcon/> User Profile</NavLink>
      </CustomMenuItem>
      <Can allowedRoles={userPermissions.notifications.root}>
        <NotificationMenuItemContainer handleSidebarClose={props.handleSidebarClose}/>
      </Can>
      <CustomMenuItem onClick={handleLogOut}>
        <span>
          <ExitToAppIcon/> Log-out
        </span>
      </CustomMenuItem>
    </div>
  );
}

export default SidebarSubMenuContainer;