import React from 'react'
import styles from './ActivityRowCollapseContent.module.scss';

const ReleasedReportsSubTable = (props) => {
  const newData = JSON.parse(props.parentRow.newData) || [];
  const oldData = JSON.parse(props.parentRow.oldData) || [];

  let renderNewDataItems = [];
  let renderOldDataItems = [];

  try {
    newData.forEach(data => {
      let group = [];
      for (let [key, value] of Object.entries(data)) {
        group.push({
          key: key,
          value: value
        })
      }
      renderNewDataItems.push(group);
    })

    oldData.forEach(data => {
      let group = [];
      for (let [key, value] of Object.entries(data)) {
        group.push({
          key: key,
          value: value
        })
      }
      renderOldDataItems.push(group);
    })
  }
  catch (e) {
    console.error(e.message);
  }

  return (
    <div className={styles.root}>
      <div className={styles.dataItem}>
        <div className={styles.dataItemTitle}>
          New data:
        </div>

        <div className="dataItemValue">
          {renderNewDataItems.map((subGroup, indexSubGroup) => {
            return (
              <ul key={`subGroup-${indexSubGroup}`}>
                {subGroup.map((item, indexItem) => {
                  return (
                    <li key={`subGroup-${indexSubGroup}-item-${indexItem}`}>
                      <span>{item.key}</span> - <span>{`${item.value}`}</span>
                    </li>
                  )
                })}
              </ul>
            )
          })}
        </div>

      </div>
      <div className={styles.dataItem}>
        <div className={styles.dataItemTitle}>
          Old data:
        </div>
        <div className="dataItemValue">
          {renderOldDataItems.map((subGroup, indexSubGroup) => {
            return (
              <ul key={`subGroup-${indexSubGroup}`}>
                {subGroup.map((item, indexItem) => {
                  return (
                    <li key={`subGroup-${indexSubGroup}-item-${indexItem}`}>
                      <span>{item.key}</span> - <span>{item.value}</span>
                    </li>
                  )
                })}
              </ul>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ReleasedReportsSubTable;