import history from 'history.js';
const queryString = require("query-string");

const urlQuery = {
  getHistory() {
    return history
  },
  getLocation() {
    return history.location
  },
  getQuery() {
    const location = this.getLocation();
    return queryString.parse(location.search);
  },
  push(params) {
    const location = this.getLocation();
    let query = this.getQuery();
    query = { ...query, ...params }
    history.push(`${location.pathname}?${queryString.stringify(query)}`)
  },
  getByKey(key) {
    let query = this.getQuery();
    if (Object.hasOwnProperty.bind(query)(key)) {
      try {
        return JSON.parse(query[key]);  
      }
      catch {
        return query[key];
      }
    }
    else {
      return null;
    }
  },
  getByKeyByQuery(key, query) {
    const parsedQuery = queryString.parse(query);
    if (Object.hasOwnProperty.bind(parsedQuery)(key)) {
      try {
        return JSON.parse(parsedQuery[key]);  
      }
      catch {
        return parsedQuery[key];
      }
    }
    else {
      return null;
    }
  }
}

export default urlQuery;