import React, { Component } from 'react'
import dataProvider, { GET_ONE, GET_LIST } from "providers/dataProvider";
import MainHeader from 'components/templateParts/MainHeader';
import SingleViewHeader from 'components/templateParts/SingleViewHeader/SingleViewHeader';
import BuildingsIcon from 'icons/BuildingsIcon';
import PersonIcon from 'icons/PersonIcon';
import EmailIcon from 'icons/EmailIcon';
import { PhoneIcon } from 'icons';
import styles from "./SalesModalContainer.module.scss";
import { BaseTable } from 'components/ui_components/table'
import { concatWithSeparator } from 'utils/textFormat.js';

class SalesShowContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      dataLoaded: false,
      associatedClientsData: [],
      associatedStaffData: [],
    }
    this.loadData();
    this.loadAssociatedClients();
    this.loadAssociatedStaff();

  }

  loadData() {
    dataProvider(GET_ONE, this.props.resource, { id: this.props.id })
    .then(({ data, total }) => {
      this.setState({
        dataLoaded: true,
        data: data
      })
    })
  }

  loadAssociatedClients = () => {
    dataProvider(GET_LIST, this.props.resource + '/' + this.props.id + '/clients', {
      pagination: {
        perPage: -1
      }
    })
    .then(({ data, total }) => {
      this.setState({
        associatedClientDataLoaded: true,
        associatedClientsData: data
      })
    })
  }

  loadAssociatedStaff = () => {
    dataProvider(GET_LIST, this.props.resource + '/' + this.props.id + '/staff', {
      pagination: {
        perPage: -1
      }
    })
    .then(({ data, total }) => {
      this.setState({
        associatedStaffDataLoaded: true,
        associatedStaffData: data
      })
    })
  }

  render() {
    if (!this.state.dataLoaded) return null;
    const {
      data,
      associatedClientsData,
      associatedStaffData
    } = this.state;

    const widgetsConfigByHash = {
      about: {
        title: 'About',
        type: '1',
        iconsData: [
          {
            id: 'salesTypeCode',
            title: 'Sales Person Type',
            value: data.salesTypeDesc,
            icon: <PersonIcon/>
          },
          {
            id: 'reportTo',
            title: 'Report To',
            value: data.reportTo,
            icon: <PersonIcon/>
          },
          {
            id: 'salesRepCode',
            title: 'Sales Rep Code / Client Ext ID',
            value: data.salesRepCode,
            icon: <PersonIcon/>
          },
          {
            id: 'profession',
            title: 'Title',
            value: data.salesTitle,
            icon: <PersonIcon/>
          },
          {
            id: 'salesCompanyName',
            title: 'Sales Company Name',
            value: data.salesCompanyName,
            icon: <BuildingsIcon/>
          }
        ],
        notesList: [
          {
            id: 'salesNote',
            title: 'Sales Note',
            content: data.salesNote
          }
        ]
      },
      contact: {
        id: 'contact',
        title: 'Contact',
        type: '2',
        iconsData: [
          {
            id: 'email',
            title: 'Email',
            value: `<a href="mailto:${data.salesEmail}">${data.salesEmail}</a>`,
            icon: <EmailIcon/>
          },
          {
            id: 'salesPhone',
            title: 'Phone',
            value: data.salesPhone,
            icon: <PhoneIcon/>
          }
        ]
      }
    }

    const widgetsConfigById = ['about', 'contact']

    const widgetsConfigGroups = [
      ['about', 'contact']
    ]

    const clientsColConfig = [
      {
        id: 'legacyClientId',
        label: 'Client ID'
      },
      {
        id: 'clientName',
        label: 'Name',
        linkAccessor: row => `/clients/${row.id}/show`,
      },
      {
        id: 'clientFaxEmail',
        label: 'Fax'
      },
      {
        id: 'clientPhone',
        label: 'Phone Number'
      }
    ]

    const staffColConfig = [
      {
        id: 'id',
        label: 'Sales ID'
      },
      {
        id: 'salesFirstName',
        label: 'First Name'
      },
      {
        id: 'salesLastName',
        label: 'Last Name'
      },
      {
        id: 'salesTitle',
        label: 'Title'
      }
    ]

    return (
      <div>
        <MainHeader title="View Sales"/>
        <SingleViewHeader
          editLink={"/sales/" + data.id}
          title={concatWithSeparator([data.salesFirstName, data.salesMiddleName, data.salesLastName], ' ')}
          widgetsConfigByHash={widgetsConfigByHash}
          widgetsConfigById={widgetsConfigById}
          widgetsConfigGroups={widgetsConfigGroups}
        />

        <div className={`${styles.sectionClientsTable} "singlePage__section"`}>
          <div className={`${'singlePage__container'}`}>
            <div className="singlePage__title4">
              Staff
            </div>
            <div className={styles.tableWrap}>
              <BaseTable
                columns={staffColConfig}
                rows={associatedStaffData}
              />
            </div>
          </div>
        </div>


        <div className={`${styles.sectionClientsTable} "singlePage__section"`}>
          <div className={`${'singlePage__container'}`}>
            <div className="singlePage__title4">
              Clients
            </div>
            <div className={styles.tableWrap}>
              <BaseTable
                columns={clientsColConfig}
                rows={associatedClientsData}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SalesShowContainer