import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CryoIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M1.2,9.1c0.5-0.5,1.1-0.8,1.9-0.8c1,0,1.8,0.3,2.2,1c0.3,0.4,0.4,0.8,0.4,1.1H4.5C4.4,10.2,4.3,10,4.2,9.9
	c-0.2-0.3-0.6-0.4-1-0.4c-0.5,0-0.8,0.2-1.1,0.6c-0.3,0.4-0.4,0.9-0.4,1.6c0,0.7,0.1,1.2,0.4,1.5c0.3,0.3,0.6,0.5,1.1,0.5
	c0.4,0,0.8-0.1,1-0.4c0.1-0.2,0.2-0.4,0.3-0.7h1.3c-0.1,0.7-0.4,1.2-0.8,1.6c-0.4,0.4-1,0.6-1.7,0.6c-0.9,0-1.5-0.3-2-0.8
	c-0.5-0.6-0.7-1.3-0.7-2.3C0.4,10.5,0.7,9.7,1.2,9.1z M10.7,8.6c0.2,0.1,0.4,0.2,0.6,0.4c0.1,0.2,0.2,0.3,0.3,0.5
	c0.1,0.2,0.1,0.4,0.1,0.6c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.4,0.5-0.7,0.6c0.3,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0.2,0.5,0.2,1v0.4
	c0,0.3,0,0.5,0,0.6c0,0.2,0.1,0.3,0.2,0.3v0.2h-1.4c0-0.1-0.1-0.2-0.1-0.3c0-0.2-0.1-0.3-0.1-0.5l0-0.6c0-0.4-0.1-0.6-0.2-0.8
	c-0.1-0.1-0.4-0.2-0.7-0.2H8v2.4H6.8V8.5h2.9C10.1,8.5,10.4,8.5,10.7,8.6z M8,9.5v1.6h1.4c0.3,0,0.5,0,0.6-0.1
	c0.2-0.1,0.4-0.3,0.4-0.7c0-0.4-0.1-0.6-0.3-0.7C9.9,9.6,9.7,9.5,9.4,9.5H8z M16,8.5h1.4l-2,3.8v2.3h-1.3v-2.3l-2.1-3.8h1.5l1.3,2.7
	L16,8.5z M22.9,14c-0.5,0.5-1.1,0.7-2,0.7c-0.9,0-1.5-0.2-2-0.7c-0.6-0.6-0.9-1.4-0.9-2.5c0-1.1,0.3-2,0.9-2.5
	c0.5-0.5,1.1-0.7,2-0.7c0.9,0,1.5,0.2,2,0.7c0.6,0.6,0.9,1.4,0.9,2.5C23.8,12.6,23.5,13.5,22.9,14z M22.1,13.1
	c0.3-0.4,0.4-0.9,0.4-1.6c0-0.7-0.1-1.2-0.4-1.6c-0.3-0.4-0.7-0.6-1.2-0.6c-0.5,0-0.9,0.2-1.2,0.6c-0.3,0.4-0.5,0.9-0.5,1.6
	s0.2,1.2,0.5,1.6c0.3,0.4,0.7,0.6,1.2,0.6C21.4,13.7,21.8,13.5,22.1,13.1z"/>
    </SvgIcon>
);

export default CryoIcon;