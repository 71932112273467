import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';

const StyledMenuItem = styled(MenuItem)`
&& {
  border: 0;
  color: #758081;
  padding: 0; 
    svg {
        padding: 0 19px 0 0;
    }
  
  a, span {
    color: inherit;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 16px;
    width: 100%;
    
    &.active {
        color: #53C4CC;
            &::before: {
                content: "''",
                    display: 'block',
                    position: 'absolute',
                    background: '#53C4CC',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '3px',
                    height: '50%'
            }
        }
      }
  }
   
  &:hover {
    background: #7AC1C6;
    a, span {
      color: white !important;
    }
  }

`;

const CustomMenuItem = React.forwardRef((props, ref) => {
  return (
    <StyledMenuItem {...props} innerRef={ref}>
      {props.children}
    </StyledMenuItem>
  );
})


export default CustomMenuItem;