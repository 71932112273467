import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HeadFlashIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M13.1,0c-1.4,0-2.7,0.3-3.9,0.9C6.1,2.4,3.9,5.5,3.9,9.2v1.2v0.3l-1.9,3.5c-0.5,0.9,0,1.6,1,1.6h0.9v0v1.5v1.8
	c0,1.1,0.9,1.9,1.9,1.9l2.6-0.4l0,2.6v0c0,0.3,0.3,0.6,0.6,0.5l9.5-1.7c0.4-0.1,0.6-0.4,0.6-0.8V21v-0.3v-4.8c1.8-1.7,3-4.1,3-6.7
	C22.2,4.1,18.1,0,13.1,0z M15.1,8.4c-0.9,1.6-1.8,3.2-2.8,4.8c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.3,0.2c-0.2-0.1-0.1-0.2-0.1-0.3
	c0.1-0.4,0.1-0.8,0.2-1.2c0.1-0.7,0.3-1.4,0.4-2.1c0-0.1,0-0.1,0-0.2c-0.1,0-0.2,0-0.2,0c-0.5,0-1,0-1.6,0c-0.3,0-0.4-0.1-0.2-0.4
	c0.9-1.6,1.8-3.2,2.8-4.8c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.3-0.2c0.2,0.1,0.1,0.2,0.1,0.3c-0.1,0.4-0.1,0.8-0.2,1.2
	c-0.1,0.7-0.3,1.4-0.4,2.1c0,0.1,0,0.1,0,0.2c0.1,0,0.2,0,0.2,0c0.5,0,1,0,1.6,0C15.1,8,15.2,8.1,15.1,8.4z"/>
    </SvgIcon>
);

export default HeadFlashIcon;