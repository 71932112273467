// in src/NotFound.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export default () => (
    <Card>
        <CardContent>
            <h1>403: Forbidden</h1>
        </CardContent>
    </Card>
);