import clsx from "clsx";
import styles from "./BaseForm.module.scss";
import React from "react";
import SmartInput from "components/ui_components/smart_input";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { isEmpty } from "lodash";

const defaultRootStyles = `
  margin: auto;
  max-width: 1130px;
`;

const defaultGroupTitleStyles = `
  font-size: 18px;
  font-weight: 700;
`;

const defaultInputWrapStyles = `
  margin: 10px 0 0;
  box-sizing: border-box;
  padding: 0 10px 0;
  width: 33.3333%;
  
  >div {
    width: 100%;
  }
`;

const defaultGroupStyles = `
  padding: 20px 0 0;
  
    + .formGroup {
      padding: 50px 0 0;
    }
`;
const Root = styled.div`
  && {
    ${defaultRootStyles}
    ${(props) => props.styles}
  }
`;

const InputWrap = styled.div`
  && {
    ${defaultInputWrapStyles}
    ${(props) => props.styles}
  }
`;

const GroupTitle = styled.div`
  && {
    ${defaultGroupTitleStyles}
    ${(props) => props.styles}
  }
`;

const Group = styled.div`
  && {
    ${defaultGroupStyles}
    ${(props) => props.styles}
  }
`;

const BaseForm = (props) => {
  const {
    inputsByHash,
    inputsGroupsById,
    inputsGroupsByHash,
    handleInputs,
    readOnly,
    smartInputOptions,
    rootStyles,
  } = props;

  if (isEmpty(inputsByHash)) {
    return null;
  }

  return (
    <Root styles={rootStyles}>
      {inputsGroupsById.map((groupId) => {
        const group = inputsGroupsByHash[groupId];

        let groupStyles = props.groupStyles;
        if (group.groupStyles) groupStyles += group.groupStyles;

        return (
          <Group
            key={groupId}
            className={clsx(styles["inputs-" + groupId])}
            styles={groupStyles}
          >
            {group.title && (
              <GroupTitle styles={props.groupTitleStyles}>
                {group.title}
              </GroupTitle>
            )}

            <div className={styles.groupInputsList}>
              {group.ids.map((nestedGroups, index) => {
                return (
                  <div
                    key={`${groupId}_${index}`}
                    className={styles.nestedGroupsWrap}
                  >
                    {nestedGroups.map((inputId, index) => {
                      if (!inputsByHash.hasOwnProperty(inputId)) {
                        console.error(
                          `${inputId} - missing in inputsByHash or inputsById`
                        );
                        return null;
                      }
                      const input = inputsByHash[inputId];
                      let params = {
                        inputId,
                        input,
                        handleInputs,
                        options: smartInputOptions,
                      };
                      let styles = ``;

                      if (props.inputWrapStyles)
                        styles += props.inputWrapStyles;
                      if (group.inputStyles) styles += group.inputStyles; //deprecated
                      if (group.inputWrapStyles)
                        styles += group.inputWrapStyles;
                      if (input.styles) styles += input.styles; //deprecated
                      if (input.inputWrapStyles)
                        styles += input.inputWrapStyles;

                      if (input.hasOwnProperty("visibility")) {
                        if (!input.visibility) return null;
                      }

                      if (input.hasOwnProperty("visibilityByLoadedData")) {
                        if (!input.visibilityByLoadedData) return null;
                      }

                      return (
                        <InputWrap
                          key={`${groupId}_${index}`}
                          className={styles.inputWrap}
                          styles={styles}
                        >
                          {readOnly ? (
                            <TextField
                              key={inputId}
                              name={inputId}
                              defaultValue={input.value}
                              required
                              label={
                                input.error ? input.errorMessage : input.label
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          ) : (
                            <SmartInput key={index} {...params} />
                          )}
                        </InputWrap>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </Group>
        );
      })}
    </Root>
  );
};

BaseForm.defaultProps = {
  inputsGroupsByHash: {},
  inputsGroupsById: [],
  inputsByHash: {},
  handleInputs: () => {},
  readOnly: false,
  groupTitleStyles: ``,
  groupStyles: ``,
  rootStyles: ``,
  inputWrapStyles: null,
  smartInputOptions: {},
};

export default BaseForm;
