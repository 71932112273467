import React from "react";
import theme from "./config/theme";
import AuthModal from "AuthCenter/AuthModal/AuthModalContainer";
import { StylesProvider } from "@material-ui/core/styles";
import "./styles/styles.scss";
import { Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { NotificationCenterProvider } from "NotificationCenter";
import { AuthCenterProvider } from "AuthCenter";
import { SmartFilterProvider } from "SmartFilterProvider";
import Routes from "routes/Routes";
import history from "history.js";

const App = () => (
  <ThemeProvider theme={theme}>
    <StylesProvider injectFirst>
      <NotificationCenterProvider>
        <AuthCenterProvider>
          <Router history={history}>
            <SmartFilterProvider>
              <Routes />
            </SmartFilterProvider>
          </Router>
          <AuthModal />
        </AuthCenterProvider>
      </NotificationCenterProvider>
    </StylesProvider>
  </ThemeProvider>
);

export default App;
