import React, {Component} from 'react';
import dataProvider, {GET_LIST} from "providers/dataProvider";
import FormGroup from '@material-ui/core/FormGroup';
import StyledCheckbox from 'components/ui_components/StyledCheckbox/StyledCheckbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';
import style from './CheckboxContainer.module.scss';
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar'
import clsx from "clsx";

const styles = theme => ({
    label: {
        padding: '7px 0 0',
        margin: '0'
    },

    radioGroup: {
        padding: '0 13px'
    },

    radioGroupError: {
        '& label': {
            color: 'red'
        }
    },
});

class CheckboxContainer extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false;

        this.state = {
            options: [],
            dataLoading: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.onRef(this)
        const {input} = this.props;

        if (input.hasOwnProperty('resource') || input.hasOwnProperty('resourceAccessor')) {
            if (!input.hasOwnProperty('relativeInputsFilterAccessor')) {
                this.loadData()
            }
        }
        if (input.hasOwnProperty('options')) {
            this.setState({
                options: input.options
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.onRef(undefined)
    }

    componentDidUpdate(prevProps) {
        if (this.props.input.hasOwnProperty('relativeInputsFilterAccessor') && (JSON.stringify(this.props.input.relativeInputsData) !== JSON.stringify(prevProps.input.relativeInputsData))) {
            console.log('loadData');
            this.loadData();
        }
    }

    getFilter = (input) => {
        try {
            return input.filter || (input.relativeInputsFilterAccessor && input.relativeInputsFilterAccessor(input.relativeInputsData)) || {};
        } catch {
            return false;
        }
    }

    getResource = (input, options) => {
        try {
            return input.resource || (input.resourceAccessor && input.resourceAccessor(options)) || input.relativeInputResourceAccessor(input.relativeInputsData);
        } catch {
            return false
        }
    }

    loadData() {
        const {input, options} = this.props;
        const filter = this.getFilter(input);
        const resource = this.getResource(input, options)

        this.setState({
            dataLoading: true
        })

        if (!filter) return;
        if (!resource) return;

        dataProvider(GET_LIST, resource, {filter})
            .then(({data, total}) => {
                let options = data.map(input.accessor)
                if (this._isMounted) {
                    this.setState({
                        isDataLoaded: true,
                        options,
                        dataLoading: false
                    })
                }
            })
    }

    handleChange = (e) => {
        const {input} = this.props;
        let values = input.value;

        if (e.target.checked) {
            values.push(e.target.value);
        }
        else {
            var index = values.indexOf(e.target.value);
            if (index > -1) {
                values.splice(index, 1);
            }
        }
        this.props.handleInputs({target: {value: values, name: e.target.name}})
    }

    render() {
        const {options, dataLoading} = this.state;
        const {classes, input, inputId} = this.props;

        return (
            <div className={clsx(style.radioGroupWrap, {[style.radioGroupError]: input.hasOwnProperty('yup') && input.error})}>
                <div className={style.radioInputLabel}>
                    {input.error ? input.errorMessage : input.label}
                </div>


                <div className={style.formGroupWrap}>
                    {dataLoading && <ProgressBar/>}
                    <FormGroup>
                        {options.map((option, index) => {
                            return (
                                <FormControlLabel key={index}
                                                  className={classes.label}
                                                  control={<StyledCheckbox size='small'/>}
                                                  checked={input.value.includes(option.value.toString())}
                                                  onChange={this.handleChange}
                                                  value={option.value.toString()}
                                                  name={inputId}
                                                  label={option.label}/>)}
                            )
                        }

                    </FormGroup>
                </div>

            </div>
        );
    }
}

export default withStyles(styles)(CheckboxContainer);

CheckboxContainer.defaultProps = {
    onRef: () => {}
}