import React from 'react';
import styles from './Login.module.scss'
import AuthForm from 'components/AuthForm/AuthForm';
import history from 'history.js';
import LogoIcon from '../../../icons/LogoIcon'

const LoginContainer = () => {
  const onLoginOk = () => {
    history.push("/");
  }

  return (
    <div className={styles.root}>
      <div className={styles.poweredBy}>
        Powered by PHD
      </div>
      <div className={styles.container}>
        <div className={styles.formWrapper}>
          <div className={styles.companyLogo}>
            <LogoIcon id="login" className={styles.logo}/>
          </div>
          <div className={styles.authFormWrapper}>
            <div className={styles.formTitle}>
              LIS Login
            </div>
            <AuthForm
              onLoginOk={onLoginOk}
            />
          </div>
        </div>
      </div>
    </div>
  );

}

export default LoginContainer;