import React from "react";
import BaseButton from "components/ui_components/buttons/BaseButton";

const baseStyles = `
    font-size: 13px;
    border-radius: 5px;
    transition: opacity .5s;
  `;

const extraStyles = {
  outlined: `
    border: 1px solid;
    padding: 6px 24px;
  `,
  contained: `
    padding: 7px 25px;
  `,
};

const WidgetControlButton = (props) => {
  let defaultStyles = baseStyles;

  if (props.variant === "contained") {
    defaultStyles += extraStyles.contained;
  } else if (props.variant === "outlined") {
    defaultStyles += extraStyles.outlined;
  }

  return (
    <BaseButton
      defaultStyles={defaultStyles}
      loading={props.loading}
      {...props}
    >
      {props.children}
    </BaseButton>
  );
};

export default WidgetControlButton;

WidgetControlButton.defaultProps = {
  color: null,
  themePaletteColor: "primary.main",
  variant: "contained",
  loading: false,
};
