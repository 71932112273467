import React, { Component } from "react";
import MainHeader from "components/templateParts/MainHeader";
import styles from "./Reports.module.scss";
import {
  QAReportsContainer,
  AddHocReportsContainer,
  SummaryReportsContainer,
} from "./Tabs";
import { Route } from "react-router-dom";
import { compose } from "redux";
import clsx from "clsx";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { WidgetType1 } from "components/ui_components/Widgets";
import ClientEventRequisitions from 'components/pages/reports/Tabs/AddHocReports/ClientEventRequisitions.js';

class Reports extends Component {
  constructor(props) {
    super(props);

    this.tabsConfigById = [
      "qaReports",
      "adHocReports",
      "summaryReports",
    ];

    this.tabsConfigByHash = {
      qaReports: {
        label: "QA Reports",
        widgets: [
          {
            component: <QAReportsContainer />,
            title: "Reports - QA Reports",
          },
        ],
      },
      adHocReports: {
        label: "Misc Reports",
        widgets: [
          {
            component: <AddHocReportsContainer />,
            title: "Reports - Ad Hoc Resulted Tests for Reporting",
          },
          {
            component: <ClientEventRequisitions />,
            title: "Reports - Client Event Requisitions",
          },
        ],
      },
      summaryReports: {
        label: "Summary Reports",
        widgets: [
          {
            component: <SummaryReportsContainer />,
            title: "Reports - Summary Reports",
          },
        ],
      },
    };
  }

  handleTabChange = (id) => {
    const { history, match } = this.props;
    history.push(`${match.url}/` + id);
  };

  render() {
    const { match } = this.props;

    const currentTabComponent = (props) => {
      const tabID = this.tabsConfigByHash.hasOwnProperty(
        props.match.params.tabSlug
      )
        ? props.match.params.tabSlug
        : "qaReports";
      const currentTab = this.tabsConfigByHash[tabID];

      return (
        <div>
          <MainHeader
            title={
              currentTab.hasOwnProperty("title") ? currentTab.title : "Reports"
            }
          />
          <div className={clsx([styles.introBar])}>
            <div className={`${"singlePage__container"}`}>
              <ul className={styles.tabControls}>
                {this.tabsConfigById.map((id) => (
                  <li
                    key={id}
                    onClick={this.handleTabChange.bind(this, id)}
                    className={clsx(
                      { [styles.tabItem__active]: id === tabID },
                      [styles.tabItem]
                    )}
                  >
                    {this.tabsConfigByHash[id].label}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="mainSingleContainer">
            {currentTab.widgets.map((widget, index) => <WidgetType1
              title={widget.title}
              key={index}
              wrapStyles="margin: 20px 0 0;"
              widgetContentStyles="padding: 20px 27px 20px;"
              content={widget.component}
            />)}
          </div>
        </div>
      );
    };

    return (
      <div className={styles.root}>
        <Route exact path={`${match.url}`} component={currentTabComponent} />
        <Route path={`${match.url}/:tabSlug`} component={currentTabComponent} />
      </div>
    );
  }
}

export default compose(connect(null, { push }))(Reports);
