import decorateWithEvents from 'utils/decorateWithEvents';
import { cancelCurrentCalls } from './dataProvider';
import axios from 'axios'

const errors = decorateWithEvents({
    status408: 'status408',
    status403: 'status403',
    status420: 'status420',
    status490: 'status490',
    handle(error) {
      if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      }
  
      if (axios.isCancel(error)) {
        return Promise.reject({ message: `Error: ${error.message}` })
      }

      let message = '';

      try {
        message = error.response.data.message || 'Unknown Error'
      }
      catch (e) {
        message = 'Server Error1'
      }

      let status = '';

      // console.log(error);

      try {
        status = error.response.status
      }
      catch (e) {
        status = '500';
      }

      // console.log('current-status: ' + status);

      if (status === 408) {
        console.log('handle-408');
        cancelCurrentCalls();
        this.emit(this.status408);
      }
      else if (status === 403) {
        console.log('handle-403');
        this.emit(this.status403);
      }
      else if (status === 420) {
        cancelCurrentCalls();
        console.log('handle-420');
        this.emit(this.status420);
      }
      else if (status === 490) {
        console.log('handle-490');
        this.emit(this.status490);
      }

      return Promise.reject({ status: status, message: `Error: ${message}` }) // eslint-disable-line prefer-promise-reject-errors
    }
});

export default errors;