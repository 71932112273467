import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ListIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M21.3,2.7v18.7H2.7V2.7H21.3z M22.8,0H1.2C0.5,0,0,0.5,0,1.2v21.6C0,23.3,0.5,24,1.2,24h21.6
	c0.5,0,1.2-0.7,1.2-1.2V1.2C24,0.5,23.3,0,22.8,0z M10.7,5.3h8V8h-8V5.3z M10.7,10.7h8v2.7h-8V10.7z M10.7,16h8v2.7h-8V16z M5.3,5.3
	H8V8H5.3V5.3z M5.3,10.7H8v2.7H5.3V10.7z M5.3,16H8v2.7H5.3V16z"/>
    </SvgIcon>
);

export default ListIcon;