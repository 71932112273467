import React from "react";
import styles from "./PatientHistory.module.scss";
import WidgetType1 from "components/ui_components/Widgets/WidgetType1/WidgetType1";
import WidgetGroup from "components/ui_components/Widgets/WidgetGroup/WidgetGroup";
import AreaChart from "Charts/AreaChart.js";
import { GET_LIST } from "providers/dataProvider";
import useLoadData from "hooks/useLoadData";
import ProgressBar from "components/ui_components/ProgressBar/ProgressBar.js";
import { formatDateTime } from "utils/dateTimeFormat";
import { fade } from "@material-ui/core";
import PatientDocs from "components/pages/requisitions/RequisitionsShow/Tabs/PatientHistory/PatientDocs";

const PatientHistory = ({ reqData }) => {
  const { data, dataLoading, dataLoaded } = useLoadData(
    GET_LIST,
    `patients/${reqData.patientId}/serial`
  );
  

  const getData = (data) => {
    const filteredData = data.filter(
      (item) =>
        item.result !== "N/A" &&
        item.result !== null &&
        item.processData !== null
    );
    return filteredData.map((item) => {
      const processData = Date.parse(item.processDate);
      return [processData, item.result];
    });
  };

  const getExtraData = (data) => {
    return data.map((item) => {
      return {
        result: item.result,
        isDiabetic: item.isDiabetic === 1 ? "Yes" : "No",
        isFamilyHistory: item.isFamilyHistory === 1 ? "Yes" : "No",
        processDate: formatDateTime(item.processDate, "date"),
        reqId: item.reqId,
      };
    });
  };

  const filteredData = data.filter(
    (item) => item.processDate !== false && item.processDate !== null
  );
  const series = [
    {
      name: "1003 Test Result",
      data: getData(filteredData),
      extraData: getExtraData(filteredData),
    },
  ];

  const tooltip = {
    shared: false,
    intersect: true,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const extraData = w.config.series[seriesIndex].extraData[dataPointIndex];
      const reqLink = `<a href="/requisitions/${extraData.reqId}/show">${extraData.reqId}</a>`;
      return `
          <div class="${styles.tooltip}">
          <div>
                PULS Result: ${extraData.result}
                </div>
                <div>
                Diabetic: ${extraData.isDiabetic}
                </div>
                <div>
                Family HX of MI: ${extraData.isFamilyHistory}
                </div>
                <div>
                Process Date: ${extraData.processDate}
                </div>
                <div>
                     Requisition ID: ${reqLink}
                </div>
        </div>
      `;
    },
  };

  const yaxis = {
    title: {
      text: 'PULS Result'
    },
    labels: {
      style: {
        colors: fade("#2C3367", 0.4),
      },
    },
  };
  const xaxis = {
    type: "datetime",
    tickPlacement: "on",
    labels: {
      style: {
        colors: fade("#2C3367", 0.6),
      },
    },
    title: {
      text: 'Process Date'
    },
  };

  let content = null;

  if (dataLoading) {
    content = <ProgressBar />;
  } else if (!dataLoading && dataLoaded) {
    content = (
      <AreaChart
        noData={dataLoaded && filteredData.length === 0}
        series={series}
        options={{ tooltip: tooltip, yaxis: yaxis, xaxis: xaxis }}
      />
    );
  }

  return (
    <div className={styles.root}>
      <WidgetGroup>
        <WidgetType1
          title={"Patient History"}
          content={content}
          styles={`width: 100%;`}
          widgetContentStyles="padding: 20px 27px 20px;"
        />
      </WidgetGroup>

      <PatientDocs patientId={reqData.patientId}/>  
    </div>
  );
};

export default PatientHistory;
