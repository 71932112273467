import React, { PureComponent } from "react";
import MainHeader from "components/templateParts/MainHeader";
import SearchBar from "components/templateParts/SearchBar";
import SmartViewsSidebar from "../../../SmartViewsSidebar/SmartViewsSidebar";
import AddPostButton from "../../../ui_components/buttons/AddPostButton";
import Footer from "components/Footer/Footer";
import StatusBarContainer from "./StatusBarContainer";
import SmartTable from "components/ui_components/table/SmartTable";
import dataProvider, {
  UPDATE,
  GET_LIST,
  DOWNLOAD_FILE,
  UPDATE_LIST,
} from "providers/dataProvider";
import helpers from "utils/helpers";
import {
  getSmartFilterConfig,
  tableColConfig,
} from "config/resources/requisitionsResource/index.js";
import { compose } from "redux";
import withModal from "withModal";
import DeliveryModalContainer from "components/pages/requisitions/RequisitionsShow/Tabs/ReportsDelivery/DeliveryModalContainer";
import Can from "Can";
import { userRoles, userGroups } from "config/userRoles";
import printBarcode from "printer/layout/printBarcode.js";
import printCryo from "printer/layout/printCryo.js";
import { TableHeaderAddButton } from "components/ui_components/buttons";
import Dymo from "printer/printer";
import { withNotificationCenter } from "NotificationCenter";
import {
  getExtraTableConfiguration,
  getDefaultExtraTableConfiguration,
  getResource,
} from "./reqListHelpers";
import { Delivery2Icon } from "icons";
import { withSmartFilter } from 'SmartFilterProvider';
import urlQuery from "urlQuery";
import { filter } from 'lodash';
import filterArrayConfigByPermissions from 'utils/filterArrayConfigByPermissions';
import { withAuthCenter } from 'AuthCenter'

const queryString = require("query-string");

const dymo = new Dymo({
  hostname: "127.0.0.1",
});

const extraDataConfig = {
  byId: ["reasonsFail", "reasonsPass", "labQcStatus", "flags", "depts"],
  byHash: {
    reasonsFail: {
      resource: "reasons/FAIL"
    },
    reasonsPass: {
      resource: "reasons/PASS",
    },
    labQcStatus: {
      resource: "lovs/LABQCSTATUS",
    },
    flags: {
      resource: "flags",
    },
    depts: {
      resource: "depts",
    }
  },
};

class RequisitionsListComponent extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.query = queryString.parse(props.location.search);

    let initFilterStatus = tableColConfig.groupsByHash.hasOwnProperty(
      this.query.status
    )
      ? this.query.status
      : "All";

    if (helpers.can([userRoles.client])) {
      initFilterStatus = "AllForClient";
    } else if (helpers.can([userRoles.physician])) {
      initFilterStatus = "AllForPhysician";
    }

    this.state = {
      currentFilterStatus: initFilterStatus,
      selectedRows: [],
      extraTableConfiguration: getDefaultExtraTableConfiguration(),
      resultsInputData: {},
      deliveryReqID: null,
      deliveryModalType: "",
      downloadReportsByFilterInProgress: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevStatus = urlQuery.getByKeyByQuery('status', prevProps.location.search);
    const currentStatus = urlQuery.getByKeyByQuery('status', this.props.location.search);
    if (prevStatus !== currentStatus) {
      const status = urlQuery.getByKeyByQuery('status', this.props.location.search) || 'All';
      this.smartTable.setPage({}, 0);
      this.setState({
        currentFilterStatus: status
      })
    }
  }

  handleSaveTestResults = (rows) => {
    const { resultsInputData } = this.state;

    const row = rows[0];
    const data = {
      reqTests: resultsInputData[row.id].resultsInputDataById.map((id) => {
        return {
          reqTestId: id,
          result: resultsInputData[row.id].resultsInputDataByHash[id],
        };
      }),
    };

    dataProvider(UPDATE, `requisitions/${row.id}/req_tests/bulk_edit`, {
      data: data,
    })
      .then((response) => {
        this.props.notificationCenter.show("Successfully updated", "info");
        this.smartTable.loadData();
        this.setState({
          resultsInputData: [],
        });
      })
      .catch((error) => {
        this.props.notificationCenter.show(error.message, "warning");
      });
  };

  handleChangeStatus = (status) => {
    this.smartTable.setPage({}, 0);
    urlQuery.push({status: status})
    // this.setState(
    //   {
    //     currentFilterStatus: status,
    //   },
    //   () => {
    //     urlQuery.push({status: status})
    //   }
    // );
  };

  handlePrintCryoAction = (rows) => {
    try {
      printCryo(dymo, rows[0]);
    } catch (err) {
      console.error(err);
      this.props.notificationCenter.show("Printing error", "warning");
    }
  };

  handlePrintBarCodeAction = (rows) => {
    try {
      printBarcode(dymo, rows[0]);
    } catch (err) {
      console.error(err);
      this.props.notificationCenter.show("Printing error", "warning");
    }
  };

  handleBulkDownloadResultSource = (rows) => {
    this.handleBulkDownload(rows, "RESULT");
  };

  handleBulkDownloadReportSource = (rows) => {
    this.handleBulkDownload(rows, "REPORT");
  };

  handleBulkDownloadReleaseSource = (rows) => {
    this.handleBulkDownload(rows, "RELEASE");
  };

  handleBulkDownloadDeliverSource = (rows) => {
    this.handleBulkDownload(rows, "DELIVER");
  };

  handleBulkDownloadOnlyIsDownloadable = (rows) => {
    const filteredRows = filter(rows, { isDownloadable: 1 })
    this.handleBulkDownload(filteredRows);
  }

  handleBulkDownload = async (rows, callSource = "RESULT") => {
    try {
      const response = await dataProvider(
        DOWNLOAD_FILE,
        `requisitions/bulk_download`,
        {
          data: {
            reqIds: helpers.getAllValues(rows, "id"),
            callSource,
          },
        },
        {
          apiEndointType: "report"
        }
      );

      if (response.data !== null && response.data.hasOwnProperty("url")) {
        window.open(response.data.url);
        this.props.notificationCenter.showDownloadNotification(
          response.data.url
        );
      } else {
        this.props.notificationCenter.show(response.message, "error");
      }
    } catch (error) {
      this.props.notificationCenter.show(error.message, "error");
    }
  };

  handleBulkNotify = async (rows) => {
    try {
      await dataProvider(
        UPDATE_LIST,
        `requisitions/send_notif`,
        {
          data: {
            reqIds: helpers.getAllValues(rows, "id"),
          },
        },
      );
      this.reloadTable();
    } catch (error) {
      this.props.notificationCenter.show(error.message, "error");
    }
  };

  handleBulkNextStatusLogSource = (rows) => {
    this.handleNextStatus(rows, "LOG");
  };

  handleBulkNextStatusAccSource = (rows) => {
    this.handleNextStatus(rows, "ACC");
  };

  handleBulkNextStatusResultSource = (rows) => {
    this.handleNextStatus(rows, "RESULT");
  };

  handleBulkNextStatusReportSource = (rows) => {
    this.handleNextStatus(rows, "REPORT");
  };

  handleBulkNextStatusReleaseSource = (rows) => {
    this.handleNextStatus(rows, "RELEASE");
  };

  handleNextStatus = (rows, callSource) => {
    dataProvider(
      UPDATE,
      `requisitions/${
        rows[0].reqStatusCode
      }/next_status/bulk_move?id=${helpers.getAllValues(rows, "id")}`,
      {
        data: {
          callSource: callSource,
        },
      }
    )
      .then((response) => {
        this.props.notificationCenter.show(
          `Successfully updated status`,
          "info"
        );
        this.smartTable.loadData();
        this.statusBar.loadData();
      })
      .catch((error) => {
        this.props.notificationCenter.show(error.message, "warning");
      });
  };

  handleDeliveryClick = (rows) => {
    this.setState(
      {
        deliveryReqID: rows[0].id,
        deliveryModalType: "single",
      },
      () => {
        this.props.handleOpenModal();
      }
    );
  };

  handleBulkDeliveryClick = (rows) => {
    this.setState(
      {
        selectedRows: rows,
        deliveryModalType: "bulk",
      },
      () => {
        this.props.handleOpenModal();
      }
    );
  };

  handleGoToStatus = (rows) => {
    let reqStatusCode = null;

    if (rows[0].reqStatusCode === "RELEASE") {
      reqStatusCode = "ACC";
    } else {
      return;
    }

    dataProvider(UPDATE, `requisitions`, {
      data: { reqStatusCode: reqStatusCode },
      id: rows[0].id,
    })
      .then((response) => {
        this.props.notificationCenter.show(
          `Successfully updated status`,
          "info"
        );
        this.smartTable.loadData();
        this.statusBar.loadData();
      })
      .catch((error) => {
        this.props.notificationCenter.show(error.message, "warning");
      });
  };

  handleSubTableRowsInputData = (data) => {
    const { inputDataByHash, row, parentRow } = data;

    this.setState((prevState) => {
      const parentRowID = parentRow.id;

      let updatedRow = {};

      if (prevState.resultsInputData.hasOwnProperty(parentRowID)) {
        updatedRow = {
          resultsInputDataById: prevState.resultsInputData[
            parentRow.id
          ].resultsInputDataById.includes(row.id)
            ? prevState.resultsInputData[parentRow.id].resultsInputDataById
            : prevState.resultsInputData[
                parentRow.id
              ].resultsInputDataById.concat(row.id),
          resultsInputDataByHash: {
            ...prevState.resultsInputData[parentRow.id].resultsInputDataByHash,
            [row.id]: inputDataByHash.result,
          },
        };
      } else {
        updatedRow = {
          resultsInputDataById: [row.id],
          resultsInputDataByHash: { [row.id]: inputDataByHash.result },
        };
      }

      return {
        resultsInputData: {
          ...prevState.resultsInputData,
          [parentRow.id]: updatedRow,
        },
      };
    });
  };

  reloadTable = () => {
    this.smartTable.loadData();
  };

  handleDownloadReportsByFilter = () => {
    this.setState({
      downloadReportsByFilterInProgress: true,
    });
    dataProvider(
      GET_LIST,
      `${getResource(this.state.currentFilterStatus)}/export`,
      {
        filter: { ...this.props.smartFilter.getFilter() },
      },
      {
        apiEndointType: 'report',
      }
    )
      .then(({ data, total, message }) => {

        if (data !== null && data.hasOwnProperty("url")) {
          window.open(data.url);
          this.props.notificationCenter.show(
            "If download failed, please click here",
            "info",
            "Download",
            () => {},
            data.url
          );
        } else {
          this.props.notificationCenter.show(message, "error");
        }
        this.setState({
          downloadReportsByFilterInProgress: false,
        });
      })
      .catch((error) => {
        this.props.notificationCenter.show(error.message, "warning");
        this.setState({
          downloadReportsByFilterInProgress: false,
        });
      });
  };

  render() {
    const {
      deliveryReqID,
      selectedRows,
      deliveryModalType,
    } = this.state;
    const {
      modalOpen,
      handleCloseModal,
      tableColConfigByHashWithExtraData,
    } = this.props;

    console.log('req list render');

    const tableFilter = this.props.smartFilter.getFilter();
    // const subTableFilter = this.props.smartFilter.getFilter();

    const currentColumnGroup =
      tableColConfig.groupsByHash[this.state.currentFilterStatus];
    let columns = currentColumnGroup;

    if (currentColumnGroup.hasOwnProperty("tableColById")) {
      columns = helpers.byHashByIDConfToArray(
        currentColumnGroup.tableColById,
        tableColConfigByHashWithExtraData || currentColumnGroup.tableColByHash
      );
    }

    const actionHandlers = {
      handleGoToStatus: this.handleGoToStatus,
      handleNextStatus: this.handleNextStatus,
      handleBulkNextStatusLogSource: this.handleBulkNextStatusLogSource,
      handleBulkNextStatusAccSource: this.handleBulkNextStatusAccSource,
      handleBulkNextStatusReportSource: this.handleBulkNextStatusReportSource,
      handleBulkNextStatusResultSource: this.handleBulkNextStatusResultSource,
      handleBulkNextStatusReleaseSource: this.handleBulkNextStatusReleaseSource,
      handlePrintCryoAction: this.handlePrintCryoAction,
      handlePrintBarCodeAction: this.handlePrintBarCodeAction,
      handleBulkDownloadResultSource: this.handleBulkDownloadResultSource,
      handleBulkDownloadReportSource: this.handleBulkDownloadReportSource,
      handleBulkDownloadReleaseSource: this.handleBulkDownloadReleaseSource,
      handleBulkDownloadDeliverSource: this.handleBulkDownloadDeliverSource,
      handleDeliveryClick: this.handleDeliveryClick,
      handleBulkDownload: this.handleBulkDownload,
      handleBulkNotify: this.handleBulkNotify,
      handleBulkDownloadOnlyIsDownloadable: this.handleBulkDownloadOnlyIsDownloadable,
      handleBulkDeliveryClick: this.handleBulkDeliveryClick,
      handleSaveTestResults: this.handleSaveTestResults,
    };

    const smartFilterConfig = getSmartFilterConfig();
    const filteredByPermissionsSmartFilterConfig = filterArrayConfigByPermissions(smartFilterConfig, this.props.authCenter.access.userRoleCode);

    return (
      <div>
        <DeliveryModalContainer
          modalOpen={modalOpen}
          handleCloseModal={handleCloseModal}
          resource={
            deliveryModalType === "single"
              ? `requisitions/${deliveryReqID}/rep_delivery`
              : `requisitions/bulk_delivery`
          }
          handleSubmitOK={this.reloadTable}
          submitData={
            deliveryModalType === "single"
              ? {}
              : {
                  reqIds: helpers.getAllValues(selectedRows, "id"),
                }
          }
        />

        <MainHeader
          title="Requisitions"
          showFilterButton={true}
          button={
            <Can
              allowedRoles={[
                userRoles.admin,
                userRoles.collaborator,
                userRoles.management,
                userRoles.gdbstaff,
              ]}
            >
              <AddPostButton title="Requisitions" href="/requisitions/create" />
            </Can>
          }
        />
        <SearchBar />
        <SmartViewsSidebar
          isOpen={true}
          resource="requisitions"
          smartFilterConfig={filteredByPermissionsSmartFilterConfig}
        >
          <div className="mainContainer">
            <div className="mainContainer__inner">
              <Can allowedRoles={userGroups.superAdmins}>
                <StatusBarContainer
                  onRef={(ref) => (this.statusBar = ref)}
                  handleChangeStatus={this.handleChangeStatus}
                  currentFilterStatus={this.state.currentFilterStatus}
                />
              </Can>
              <SmartTable
                {...getExtraTableConfiguration({
                  status: this.state.currentFilterStatus,
                  actionHandlers: actionHandlers,
                  smartFilter: tableFilter,
                  resultsInputData: this.state.resultsInputData,
                })}
                onRef={(ref) => (this.smartTable = ref)}
                columns={columns}
                filter={tableFilter}
                resource={getResource(this.state.currentFilterStatus)}
                showDelete={false}
                handleSubTableRowsInputData={this.handleSubTableRowsInputData}
                extraDataConfig={extraDataConfig}
                order={"desc"}
                toolbarExtraComponents={
                  <React.Fragment>
                    <Can notAllowedRoles={[userRoles.client, userRoles.physician, userRoles.sales]}>
                      <TableHeaderAddButton
                        icon={<Delivery2Icon />}
                        onClick={this.handleDownloadReportsByFilter}
                        showProgressBar={
                          this.state.downloadReportsByFilterInProgress
                        }
                      >
                          Export All
                      </TableHeaderAddButton>
                    </Can>
                  </React.Fragment>
                }
              />
            </div>
            <Footer />
          </div>
        </SmartViewsSidebar>
      </div>
    );
  }
}

export default compose(
  withAuthCenter(),
  withSmartFilter(),
  withModal,
  withNotificationCenter()
)(RequisitionsListComponent);
