import {resourcesConfig} from './resources';
import resetPasswordConfigWithUserName from './resetPasswordWithUserName.js';
import changePasswordFormConfig from './changePasswordFormConfig';

export { default as resetPasswordConfig } from './resetPassword.js';

export {
    changePasswordFormConfig,
    resourcesConfig,
    resetPasswordConfigWithUserName,
}

export default {
    apiUrl: process.env.REACT_APP_API_ENDPOINT,
    apiReportUrl: process.env.REACT_APP_API_REPORT_ENDPOINT,
    cognito: {
        region: process.env.REACT_APP_AWS_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
    },
}