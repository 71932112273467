import React, {PureComponent} from 'react';
import withAutoSave from './withAutoSave';
import helpers from "../../../utils/helpers";
import MultiselectComponent from './MultiselectComponent';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { userRoles } from 'config/userRoles';

const styles = theme => {
    return ({
        formControl: {
            width: 150,
            maxWidth: 150,
        }
    })};

class TestPassCodeMultiSelectContainer extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            selectedOptions: props.value
        }
    }

    handleChange = e => {
        e.stopPropagation()
        const value = e.target.value;
        this.props.onChange(value);

        const {input, optionsInputData} = this.props;
        const updatedIds = helpers.idConverter(value, optionsInputData, 'passCode', 'id');

        const data = {
            "passIds" : updatedIds
        }

        if (this.props.input.hasOwnProperty('autoSave')) {
            const resource = input.autoSave.hasOwnProperty('resourceAccessor') ? input.autoSave.resourceAccessor({reqID: this.props.reqID, testID: this.props.testID}) : null;
            this.props.autoSave(data, resource, 'Pass Codes successfully updated');
        }

        this.setState({
            selectedOptions: value
        })
    }

    handleClick = e => {
        e.stopPropagation()
    }

    render() {
        const {input, inputId, optionsInputData, classes, onMouseLeave, onMouseOver} = this.props;
        let {selectedOptions} = this.state;

        const formattedOptions = optionsInputData.map(option => {return {label: `${option.passCode} - ${option.passDesc}`, value: option.passCode}})

        return (
            <MultiselectComponent
                disabled={helpers.can([userRoles.sales])}
                onMouseLeave={onMouseLeave}
                onMouseOver={onMouseOver}
                selectedOptions={selectedOptions}
                classes={classes}
                options={formattedOptions}
                label={input.label}
                value={selectedOptions}
                name={inputId}
                onClick={this.handleClick}
                onChange={this.handleChange}
                renderValue={selected => selected.join(', ')}
            />
        )
    }
}

TestPassCodeMultiSelectContainer.propTypes = {
    optionsInputData: PropTypes.array.isRequired
}

export default withStyles(styles)(withAutoSave(TestPassCodeMultiSelectContainer));
