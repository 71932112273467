import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import TablePagination from '@material-ui/core/TablePagination';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

export default function ({ total, perPage, page, handleSetPage, handleSetPerPage }) {
  const StyledTablePagination = styled(TablePagination)`
    &&{
        font-size: 13px;
        
        .MuiTypography-root {
            font-size: 13px;
        }
        
        .MuiToolbar-root {
          min-height: 40px;
          padding: 0;
        }
        
    }
`;

  const StyledTablePaginationActions = styled.div`
    &&{
       flex-shrink: 0;
       max-height: 40px;
       overflow: hidden;
       
       .MuiButtonBase-root {
          padding: 10px 12px;
       }
    }
`;

  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
      onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <StyledTablePaginationActions>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </StyledTablePaginationActions>
    );
  }

  return <StyledTablePagination
    rowsPerPageOptions={[25, 50, 75, 100]}
    component="div"
    count={total}
    ActionsComponent={TablePaginationActions}
    rowsPerPage={perPage}
    page={page - 1}
    backIconButtonProps={{
      'aria-label': 'Previous Page',
    }}
    nextIconButtonProps={{
      'aria-label': 'Next Page',
    }}
    onChangePage={handleSetPage}
    onChangeRowsPerPage={handleSetPerPage}
  />
}