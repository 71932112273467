import React from "react";
import MainHeader from "components/templateParts/MainHeader";
import SearchBar from "components/templateParts/SearchBar";
import SmartViewsSidebar from "../../../SmartViewsSidebar/SmartViewsSidebar";
import { BaseListComponent } from "components/base";
import AddPostButton from "../../../ui_components/buttons/AddPostButton";
import Footer from "components/Footer/Footer";
import { smartFilterConfig } from "config/resources/physiciansResource/index.js";
import ExportAllButton from "components/ui_components/buttons/ExportAllButton.js";
import { userRoles } from "config/userRoles";
import { canNotByRoles } from "utils/permissions";

const PhysiciansListComponent = (props) => {
  const isEditAvailable = canNotByRoles([userRoles.sales]);

  return (
    <div>
      <MainHeader
        title="Physicians"
        button={
          isEditAvailable && (
            <AddPostButton title="Physicians" href="/physicians/create" />
          )
        }
      />
      <SearchBar />
      <SmartViewsSidebar
        isOpen={true}
        resource="physicians"
        smartFilterConfig={smartFilterConfig}
      >
        <div className="mainContainer">
          <div className="mainContainer__inner">
            <BaseListComponent
              {...props}
              deleteConfirmationMessagePostfix="physician"
              editableTable={isEditAvailable}
              showDelete={isEditAvailable}
              toolbarExtraComponents={
                <ExportAllButton resource="physicians/export" />
              }
            />
          </div>
          <Footer />
        </div>
      </SmartViewsSidebar>
    </div>
  );
};

export default PhysiciansListComponent;
