import React from 'react';

import {
  HouseIcon,
  CardIcon,
  EmailIcon,
  PersonIcon,
  PhoneIcon,
  FaxIcon,
} from 'icons';

export default {
  colaPortalPrefDesc: {
    icon: <HouseIcon/>,
    label: 'Patient Portal Preferences',
  },
  colaAddr1: {
    icon: <HouseIcon/>,
    label: 'Address 1',
  },
  colaAddr2: {
    icon: <HouseIcon/>,
    label: 'Address 2',
  },
  colaCity: {
    icon: <HouseIcon/>,
    label: 'City',
  },
  colaState: {
    icon: <HouseIcon/>,
    label: 'State',
  },
  colaCountry: {
    icon: <HouseIcon/>,
    label: 'Country',
  },
  colaZip: {
    icon: <HouseIcon/>,
    label: 'Zip',
  },
  colaLabDirector: {
    icon: <PersonIcon/>,
    label: 'Laboratory Director',
  },
  colaLabNpi: {
    icon: <CardIcon/>,
    label: 'Laboratory NPI',
  },
  colaTransmitMethodDesc: {
    icon: <HouseIcon/>,
    label: 'Test Results Delivery Method',
  },
  colaBillTypeDesc: {
    icon: <HouseIcon/>,
    label: 'Bill to',
  },
  colaFaxEmail: {
    icon: <FaxIcon/>,
    label: 'Secure Fax',
  },
  isLisInterface: {
    icon: <HouseIcon/>,
    label: 'Colaborator interfacing with LIS',
    accessor: (d) => `${d.isLisInterface === 1 ? 'Yes' : 'No'}`
  },
  isOrderIdAvailable: {
    icon: <HouseIcon/>,
    label: 'Order ID available',
    accessor: (d) => `${d.isOrderIdAvailable === 1 ? 'Yes' : 'No'}`
  },
  colaNote: {
    icon: <HouseIcon/>,
    label: 'Note',
  },
  colaDeliveryNote: {
    icon: <HouseIcon/>,
    label: 'Delivery Note',
  },
  reportNameFormatDesc: {
    icon: <HouseIcon/>,
    label: 'Reports Name Format',
  },
  templateFilename: {
    icon: <HouseIcon/>,
    label: 'Template File Name',
  },
  reportDeliverToDesc: {
    icon: <HouseIcon/>,
    label: 'Report Deliver To ',
  },
  idPrintoutDesc: {
    icon: <HouseIcon/>,
    label: 'Printout Code',
  },
  colaEmail: {
    icon: <EmailIcon/>,
    label: 'Email',
  },
  colaPhone: {
    icon: <PhoneIcon/>,
    label: 'Phone',
  },
}