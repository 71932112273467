import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BarCodeIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M7.9,5c0.2,0.3,0.3,0.6,0.3,0.9c0,0.4-0.1,0.7-0.3,0.9C7.8,7,7.7,7.1,7.5,7.2C7.8,7.3,8,7.5,8.2,7.7
	c0.2,0.2,0.2,0.5,0.2,0.9c0,0.4-0.1,0.7-0.3,1C8,9.7,7.9,9.9,7.7,10c-0.2,0.1-0.4,0.2-0.7,0.3s-0.5,0.1-0.8,0.1H3.5v-6h2.9
	C7.1,4.4,7.6,4.6,7.9,5z M4.7,5.4v1.3h1.5c0.3,0,0.5,0,0.6-0.1S7,6.3,7,6.1c0-0.3-0.1-0.5-0.3-0.6C6.5,5.5,6.3,5.4,6,5.4H4.7z
	 M4.7,7.8v1.6h1.5c0.3,0,0.5,0,0.6-0.1C7,9.1,7.1,8.9,7.1,8.5C7.1,8.2,7,8,6.7,7.9C6.6,7.8,6.4,7.8,6.1,7.8H4.7z M11.2,4.4h1.4
	l2.1,6h-1.4L13,9.2h-2.2l-0.4,1.2H9L11.2,4.4z M11.1,8.1h1.5l-0.8-2.4L11.1,8.1z M19.4,4.5c0.2,0.1,0.4,0.2,0.6,0.4
	c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.3-0.4,0.5-0.7,0.6C19.7,7.6,19.9,7.8,20,8
	c0.1,0.2,0.2,0.5,0.2,0.9v0.4c0,0.3,0,0.5,0,0.6c0,0.2,0.1,0.3,0.2,0.3v0.2H19c0-0.1-0.1-0.2-0.1-0.3c0-0.2-0.1-0.3-0.1-0.5l0-0.6
	c0-0.4-0.1-0.6-0.2-0.8C18.6,8.1,18.3,8,18,8h-1.2v2.4h-1.2v-6h2.9C18.8,4.4,19.1,4.4,19.4,4.5z M16.8,5.4V7h1.4
	c0.3,0,0.5,0,0.6-0.1c0.2-0.1,0.4-0.3,0.4-0.7c0-0.4-0.1-0.6-0.3-0.7c-0.1-0.1-0.3-0.1-0.6-0.1H16.8z M1,13c0.5-0.5,1.1-0.7,1.8-0.7
	c1,0,1.7,0.3,2.2,1c0.3,0.4,0.4,0.7,0.4,1.1H4.2c-0.1-0.3-0.2-0.5-0.3-0.7c-0.2-0.3-0.6-0.4-1-0.4c-0.4,0-0.8,0.2-1.1,0.6
	c-0.3,0.4-0.4,0.9-0.4,1.6c0,0.7,0.1,1.2,0.4,1.5c0.3,0.3,0.6,0.5,1,0.5c0.4,0,0.8-0.1,1-0.4c0.1-0.2,0.2-0.4,0.3-0.7h1.2
	C5.3,17,5.1,17.5,4.6,18s-1,0.6-1.7,0.6c-0.9,0-1.5-0.3-2-0.8c-0.5-0.6-0.7-1.3-0.7-2.3C0.2,14.4,0.4,13.6,1,13z M11.1,17.9
	c-0.5,0.5-1.1,0.7-2,0.7c-0.9,0-1.5-0.2-2-0.7c-0.6-0.6-0.9-1.4-0.9-2.5c0-1.1,0.3-1.9,0.9-2.5c0.5-0.5,1.1-0.7,2-0.7
	c0.9,0,1.5,0.2,2,0.7c0.6,0.6,0.9,1.4,0.9,2.5C12,16.5,11.7,17.3,11.1,17.9z M10.3,17c0.3-0.4,0.4-0.9,0.4-1.6
	c0-0.7-0.1-1.2-0.4-1.6s-0.7-0.6-1.2-0.6s-0.9,0.2-1.2,0.5c-0.3,0.4-0.4,0.9-0.4,1.6c0,0.7,0.1,1.2,0.4,1.6c0.3,0.4,0.7,0.6,1.2,0.6
	S10,17.3,10.3,17z M16.5,12.5c0.4,0.1,0.8,0.4,1,0.8c0.2,0.3,0.4,0.6,0.4,1c0.1,0.3,0.1,0.7,0.1,1c0,0.8-0.2,1.5-0.5,2
	c-0.4,0.7-1.1,1.1-2,1.1H13v-6h2.6C15.9,12.4,16.2,12.4,16.5,12.5z M14.2,13.4v3.9h1.2c0.6,0,1-0.3,1.2-0.9c0.1-0.3,0.2-0.7,0.2-1.1
	c0-0.6-0.1-1.1-0.3-1.4c-0.2-0.3-0.6-0.5-1.1-0.5H14.2z M23.4,13.5h-3.2v1.3h2.9v1h-2.9v1.5h3.3v1.1H19v-6h4.4V13.5z"/>
    </SvgIcon>
);

export default BarCodeIcon;