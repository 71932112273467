import React from 'react';
import helpers from 'utils/helpers';
import Lock from '@material-ui/icons/Lock';

export default {
  label: 'Current password',
  type: 'password',
  yup: helpers.getYupRequired('Current password'),
  autocomplete: 'new-password',
  icon: <Lock/>,
}