import React from 'react';
import Modal from '@material-ui/core/Modal';
import ButtonBase from '@material-ui/core/ButtonBase';
import styles from './DeleteConfirmModal.module.scss';
import ProgressBar from 'components/ui_components/ProgressBar/ProgressBar';

const DeleteConfirmModal = (props) => {
  const { modalOpen, handleCloseModal, handleConfirmCallback, numberOfItems, deleteConfirmationMessagePostfix } = props;

  const handleClickDelete = () => {
    handleCloseModal();
    handleConfirmCallback();
  }

  const getTitle = () => {
    let message = `Are you sure you want to delete this ${deleteConfirmationMessagePostfix}?`;

    if (numberOfItems > 1) {
      message = `Are you sure you want to delete ${numberOfItems} ${deleteConfirmationMessagePostfix}(s)?`;
    }

    return message;
  }

  return (
    <Modal open={modalOpen}>
      <div className={styles.root}>
        {props.actionDeleteInProgress && <ProgressBar/>}
        <h2 className="ttl ttl-2">
          {getTitle()}
        </h2>
        <h3>
          This item will be deleted immediately. You can’t undo this action.
        </h3>
        <div className={`${'buttonGroup'} ${styles.buttonGroup}`}>
          <ButtonBase
            className="button formButton formButton__secondaryOutlined"
            onClick={handleCloseModal}>
            Cancel
          </ButtonBase>
          <ButtonBase
            className="button formButton formButton__errorContained"
            onClick={handleClickDelete}
          >
            Delete
          </ButtonBase>
        </div>
      </div>
    </Modal>
  );
}

DeleteConfirmModal.defaultProps = {
  deleteConfirmationMessagePostfix: null,
  numberOfItems: 1,
  actionDeleteInProgress: false,
}

export default DeleteConfirmModal;