import React from "react";
import styles from "./BaseWidgetViewList.module.scss";
import styled from "styled-components";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Box } from "@material-ui/core";
import { useNotificationCenter } from 'NotificationCenter';

const StyledItem = styled.div`
  && {
    ${(props) => props.styles}
  }
`;

const StyledTooltipValue = styled.span`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
`;

const BaseWidgetViewList = (props) => {
  const {
    theme,
    title,
    ids,
    inputsByHash,
    data,
    showIcons,
    itemStyles,
  } = props;

  const notificationCenter = useNotificationCenter();
  return (
    <div className={styles[`rootTheme_${theme}`]}>
      {title && <div className={styles.listTitle}>{title}</div>}
      <div className={styles.itemsList}>
        {ids.map((id) => {
          const input = inputsByHash[id];
          const value = input.accessor ? input.accessor(data) : data[id];

          const onClickCallBack = input.hasOwnProperty("onClickCallBack")
            ? input.onClickCallBack
            : false;

          const linkAccessor = input.hasOwnProperty("linkAccessor")
            ? input.linkAccessor
            : false;

          const withTooltip = input.hasOwnProperty("tooltipValue")
            ? input.tooltipValue
            : false;

          const validatedValue = value !== "null" && value;
          let activeItemProps = {};

          let renderValue = <span {...activeItemProps}>{validatedValue}</span>;

          if (withTooltip) {
            renderValue = (
              <Tooltip
                arrow
                title={
                  <React.Fragment>
                    <Box display={'flex'} alignItems={'center'}>
                      <Box fontSize={"13px"}>{validatedValue}</Box>
                      <Box ml={1}>
                        <CopyToClipboard onCopy={() => {
                          notificationCenter.show('Copied to clipboard')
                        }} text={validatedValue}>
                          <FileCopyIcon />
                        </CopyToClipboard>
                      </Box>
                    </Box>
                  </React.Fragment>
                }
                interactive
              >
                <StyledTooltipValue>{validatedValue}</StyledTooltipValue>
              </Tooltip>
            );
          } else if (linkAccessor) {
            renderValue = (
              <Link to={linkAccessor(data)} className={styles.itemLink}>
                {validatedValue}
              </Link>
            );
          }

          if (onClickCallBack) {
            activeItemProps = {
              className: styles.itemLink,
              onClick: () => {
                input.onClickCallBack(data);
              },
            };
          }

          return (
            <StyledItem
              key={id}
              className={clsx([styles.item], {
                [styles.itemWithIcon]: showIcons,
              })}
              styles={itemStyles}
            >
              <div className={styles.itemLabel}>{input.label}</div>
              <div className={styles.itemValue}>{renderValue}</div>
              {showIcons && <div className={styles.icon}>{input.icon}</div>}
            </StyledItem>
          );
        })}
      </div>
    </div>
  );
};

export default BaseWidgetViewList;

BaseWidgetViewList.defaultProps = {
  showIcons: true,
  title: null,
  theme: "default",
};
