import React, { useEffect, useState } from "react";
import dataProvider, { GET_ONE } from "providers/dataProvider";
import Footer from "components/Footer/Footer";
import styles from "./FrontPage.module.scss";
import authStorage from "providers/authStorage.js";
import MobileTopMenu from "components/templateParts/MobileTopMenu.js";
import LargeLogoIcon from '../../../icons/LargeLogoIcon'

const FrontPage = (props) => {
  const [data, setData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  const access = authStorage.getAccess();

  useEffect(() => {
    const loadData = () => {
      dataProvider(GET_ONE, "users", { id: access.userId }).then(
        ({ data, total }) => {
          setData(data);
          setDataLoaded(true);
        }
      );
    };

    loadData();
  }, [access.userId]);

  return (
    <div className={styles.root}>
      <MobileTopMenu />
      <div className={styles.introSection}>
        <div className={styles.innerWrap}>
          <div>
            <LargeLogoIcon className={styles.logo}/>
          </div>
          <h1 className={styles.title}>
            Welcome to Lab Information System (LIS)
          </h1>
          <div className={styles.userName}>
            {dataLoaded && data.userFirstName + " " + data.userLastName}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FrontPage;
