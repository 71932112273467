import cloneDeep from 'lodash/cloneDeep';

import {
    UPDATE_SMART_FILTER_INPUT_VALUE,
    INIT_SMART_FILTERS,
    CLEAR_ALL_SMART_FILTER_TAG_VALUES,
    CLEAR_SMART_FILTER_TAG_VALUE,
    APPLY_SMART_FILTERS,
    CLEAR_ALL_SMART_FILTER_INPUT_VALUES,
    APPLY_SMART_FILTER_CONFIG
} from './../config/actionTypes';

const initState = {
    tagsById: [],
    tagsByHash: {}
}

export default (state = initState, action) => {
    let updatedTagsByHash = {};

    switch (action.type) {
        case INIT_SMART_FILTERS:
            return {...state, ...action.payload}

        case APPLY_SMART_FILTERS:
            updatedTagsByHash = cloneDeep(state.tagsByHash);
            state.tagsById.forEach(id => {
                updatedTagsByHash[id].tagValue = cloneDeep(updatedTagsByHash[id].inputValue);
                updatedTagsByHash[id].viewValue = cloneDeep(updatedTagsByHash[id].labelValue)

            })
            return {...state, tagsByHash: updatedTagsByHash}

        case UPDATE_SMART_FILTER_INPUT_VALUE:
            updatedTagsByHash = {...state.tagsByHash};
            updatedTagsByHash[action.payload.name].inputValue = action.payload.value;
            updatedTagsByHash[action.payload.name].labelValue = action.payload.labelValue;
            return {...state, tagsByHash: updatedTagsByHash}

        case CLEAR_SMART_FILTER_TAG_VALUE:
            updatedTagsByHash = {...state.tagsByHash};
            updatedTagsByHash[action.payload].tagValue = '';
            updatedTagsByHash[action.payload].viewValue = '';
            return {...state, tagsByHash: updatedTagsByHash}

        case CLEAR_ALL_SMART_FILTER_INPUT_VALUES:
            updatedTagsByHash = {...state.tagsByHash};
            state.tagsById.forEach(id => {
                let input = updatedTagsByHash[id];
                updatedTagsByHash[id] = {
                    ...updatedTagsByHash[id],
                    inputValue: input.type === 'checkbox' ? [] : '',
                    labelValue: ''
                }
            })
            return {...state, tagsByHash: updatedTagsByHash}

        case CLEAR_ALL_SMART_FILTER_TAG_VALUES:
            updatedTagsByHash = {...state.tagsByHash};
            state.tagsById.forEach(id => {
                updatedTagsByHash[id] = {
                    ...updatedTagsByHash[id],
                    tagValue: '',
                    viewValue: '',
                }
            })
            return {...state, tagsByHash: updatedTagsByHash}


        case APPLY_SMART_FILTER_CONFIG:
            updatedTagsByHash = {...state.tagsByHash};
            state.tagsById.forEach(id => {
                let tagValue = '';
                  let viewValue = '';
                  let inputValue = '';

                if (action.payload.hasOwnProperty(id)) {
                  tagValue = action.payload[id].value;
                  viewValue = action.payload[id].viewValue;
                  inputValue = action.payload[id].value;
                }

                updatedTagsByHash[id] = {
                    ...updatedTagsByHash[id],
                    tagValue: tagValue,
                    viewValue: viewValue,
                    inputValue: inputValue,
                }
            })
            return {...state, tagsByHash: updatedTagsByHash}

        default:
            return state
    }
}