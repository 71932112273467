import React from 'react';
import IconSearch from '@material-ui/icons/Search';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        border: '1px solid #C9C9C9',
        borderRadius: '5px',
        padding: '6px 10px 9px 40px',
        fontSize: '20px',
        width: '100%',
        boxSizing: 'border-box'
    },
    inputWrap: {
        position: 'relative',
        width: '100%'

    },
    inputIcon: {
        position: 'absolute',
        left: '12px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#C9C9C9'
    }
});

const SearchInput = props => {
    const {classes} = props;
    return (
        <div className={classes.inputWrap}>
            <IconSearch className={classes.inputIcon}/>
            <input
                className={classes.root}
                placeholder="Search Requisition"
            />
        </div>
    );
}


export default withStyles(styles)(SearchInput);