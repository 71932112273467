import React, { Component } from 'react'
import Modal from '@material-ui/core/Modal';
import { SmartTable } from 'components/ui_components/table'
import styles from './LocationsModalContainer.module.scss';
import TableFilter from './TableFilter/TableFilter';
import { locationsTabConfig } from "config/resources/requisitionsResource/index.js";
import ButtonBase from '@material-ui/core/ButtonBase';
import withForm from 'withForm';
import SmartInput from 'components/ui_components/smart_input'
import dataProvider, { CREATE } from "providers/dataProvider";
import { compose } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { closeAddSpecimenLocationModal } from "actions";
import { withNotificationCenter } from 'NotificationCenter'

const { createSpecimenLocationTableConfig, createSpecimenLocationFormConfig, locationsTabFilterConfig } = locationsTabConfig;

class LocationsModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocations: [],
      filterData: null,
      trackerIds: [],
      tableError: false,
    }
  }

  handleSelectRow = (rows) => {
    this.setState({
      selectedLocations: rows
    })
  }

  getLocationsIds = () => {
    return this.state.selectedLocations.map(row => row.id)
  }

  handleSubmit = () => {
    const trackerIds = this.getLocationsIds();

    const { isFormValid, resetErrors } = this.props;

    if (!isFormValid()) return;
    resetErrors();

    if (!this.isTableValid()) return;

    let data = this.props.getSubmitData();
    data.trackerIds = trackerIds;
    dataProvider(CREATE, `requisitions/${this.props.reqID}/spec_locs`, { data: data })
    .then((response) => {
      this.handleSuccessfullySubmit();
    })
    .catch(error => {
      this.props.notificationCenter.show(error.message, 'warning')
    });
  }

  handleSuccessfullySubmit = () => {
    this.closeAddSpecimenLocationModal();
    this.props.notificationCenter.show('Successfully created', 'info')
    this.props.handleSubmitLocation();
  }

  isTableValid = () => {
    const trackerIds = this.getLocationsIds();
    const { aliquot } = this.props.getSubmitData();

    if (trackerIds.length === parseInt(aliquot)) {
      this.resetTableErrors();
      return true;
    } else {
      this.handleTableErrors();
      return false;
    }
  }

  resetTableErrors = () => {
    this.setState({
      tableError: false
    })
  }

  handleTableErrors = () => {
    this.setState({
      tableError: true
    })
  }

  closeAddSpecimenLocationModal = () => {
    this.props.closeAddSpecimenLocationModal()
    this.props.clearInputs(true).then(this.props.resetErrors)
    this.setState({
      filterData: null
    })
  }

  handleFilters = (filter) => {
    this.setState({
      filterData: filter
    })
  }

  render() {
    const { modalOpen, handleCloseModal, inputsById, inputsByHash, handleInputs, getSubmitData, labID } = this.props;
    const { tableError } = this.state;
    const { aliquot } = getSubmitData();

    return (
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <div className={`${styles.root} modalContainer`}>
          <div className={styles.modalHeader}>
            <h2 className={`${styles.modalHeaderTitle} ttl ttl-2`}>
              Add Specimen Location
            </h2>
            <div className={`${'buttonGroup'} ${styles.modalControls}`}>
              <ButtonBase
                className="button formButton formButton__mainContained"
                onClick={this.handleSubmit}>
                Add Location to Requisition
              </ButtonBase>

              <ButtonBase
                className="button formButton formButton__secondaryOutlined"
                onClick={this.closeAddSpecimenLocationModal}>
                Cancel
              </ButtonBase>
            </div>
          </div>
          <div>
            <div>
                <div className={styles.inputList}>
                  {inputsById.map((inputId, index) => {
                    const input = inputsByHash[inputId];
                    let params = { inputId, input, handleInputs }
                    return (
                      <div key={index} className={styles.inputWrap}>
                        <SmartInput {...params}/>
                      </div>
                    )
                  })}
                </div>

                <TableFilter
                  handleFilters={this.handleFilters}
                  labID={labID}
                  withFormConfig={{
                    inputsById: locationsTabFilterConfig.inputsById,
                    inputsByHash: locationsTabFilterConfig.inputsByHash
                  }}/>

                <div className={styles.tableWrap}>
                  <SmartTable
                    filter={this.state.filterData}
                    columns={createSpecimenLocationTableConfig}
                    resource={`labs/${labID}/empty_storages`}
                    showSelect={true}
                    showDelete={false}
                    showSelectAll={false}
                    searchString={this.state.searchString}
                    handleSelectRowCallBack={this.handleSelectRow}
                    useGlobalSelect={true}
                    showPagination={false}
                  />
                </div>

                {tableError && aliquot > 0 ?
                  <div className={styles.formErrorMessage}>You are only allowed to
                    select {aliquot} location</div> : null}
              </div>
          </div>

        </div>
      </Modal>
    );
  }
}


const mapDispatchToProps = dispatch =>
  bindActionCreators({
    closeAddSpecimenLocationModal
  }, dispatch)


export default compose(
  connect(null, mapDispatchToProps),
  withForm(createSpecimenLocationFormConfig),
  withNotificationCenter()
)(LocationsModalContainer)

LocationsModalContainer.defaultProps = {
  labID: 1,
}