import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import dataProvider, { CREATE, UPDATE_ITEM } from "providers/dataProvider";
import BaseForm from "components/ui_components/BaseForm/BaseForm";
import { useNotificationCenter } from "NotificationCenter";
import BaseModal from "components/ui_components/BaseModal/BaseModal.js";
import PropTypes from "prop-types";

const ModalWithAddForm = (props) => {
  const {
    handleCloseModal,
    modalIsOpen,
    submitOkCallback,
    submitResource,
    clearInputs,
    getSubmitData,
    isEditMode,
    isFormValid,
    resetErrors,
  } = props;

  const notificationCenter = useNotificationCenter();

  const handleSubmit = () => {
    resetErrors();

    if (!isFormValid()) return;

    dataProvider(isEditMode ? UPDATE_ITEM : CREATE, submitResource, {
      data: getSubmitData(true, true, true),
    })
      .then((response) => {
        notificationCenter.show(response.message, "info");
        submitOkCallback();
        handleCloseModal();
        clearInputs();
      })
      .catch((error) => {
        notificationCenter.show(error.message, "error");
      });
  };

  const onClickCloseModal = () => {
    clearInputs();
    handleCloseModal();
  };

  return (
    <BaseModal
      isOpen={modalIsOpen}
      onClose={handleCloseModal}
      title={props.title}
      controls={
        <React.Fragment>
          <ButtonBase
            className="button formButton formButton__secondaryOutlined"
            onClick={onClickCloseModal}
          >
            Cancel
          </ButtonBase>
          <ButtonBase
            className="button formButton formButton__mainContained"
            onClick={handleSubmit}
          >
            {isEditMode ? "Save" : "Add"}
          </ButtonBase>
        </React.Fragment>
      }
      rootStyles={`max-width: 460px;`}
    >
      <BaseForm
        inputsById={props.inputsById}
        inputsByHash={props.inputsByHash}
        handleInputs={props.handleInputs}
        inputsGroupsById={props.inputsGroupsById}
        inputsGroupsByHash={props.inputsGroupsByHash}
        inputWrapStyles={props.inputWrapStyles}
        groupTitleStyles={`font-size: 13px;`}
        groupStyles={`
              padding: 7px 0 0;
              + .formGroup {
                padding: 10px 0 0;
              }
          `}
      />
    </BaseModal>
  );
};

ModalWithAddForm.defaultProps = {
  modalIsOpen: false,
  submitResource: "",
  title: null,
  isEditMode: false,
  inputWrapStyles: "width: 50%; margin: 7px 0 0;",
};

ModalWithAddForm.propTypes = {
  submitResource: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  clearInputs: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  getSubmitData: PropTypes.func.isRequired,
  handleInputs: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  resetErrors: PropTypes.func.isRequired,
  isFormValid: PropTypes.func.isRequired,
  inputWrapStyles: PropTypes.string,
};

export default ModalWithAddForm;
