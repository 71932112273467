export default {
  requisitionsInformation: {
    title: "Requisition Information",

    edit: {
      inputsById: [
        "colaId",
        "clientId",
        "orderingClientPhysId",
        "copyToClientId",
        "copyToClientPhysId",
        "eventId",
        "extOrderId",
        "labId",
        "extAccessionId",
        "isFasting",
        "dateCollectedMonth",
        "dateCollectedDate",
        "dateCollectedYear",
        "timeCollectedHours",
        "timeCollectedMinutes",
        "timeCollectedAmPm",
        "collectCode",
        "collectedBy",
      ],
      inputsGroupsByHash: {
        group1: {
          ids: [
            ["colaId", "clientId", "orderingClientPhysId", "labId", "eventId"],
            [
              "extOrderId",
              "extAccessionId",
              "copyToClientId",
              "copyToClientPhysId",
            ],
            ["collectCode", "collectedBy", "isFasting"],
          ],
        },
        group2: {
          title: "Collected Date and Time",
          ids: [
            [
              "dateCollectedMonth",
              "dateCollectedDate",
              "dateCollectedYear",
              "timeCollectedHours",
              "timeCollectedMinutes",
              "timeCollectedAmPm",
            ],
          ],
        },
        group3: {
          title: "Centrifuged Time",
          ids: [
            ["timeCentrifugedHour", "timeCentrifugedMin", "timeCentrifugedTod"],
          ],
        },
      },
      inputsGroupsById: ["group1", "group2"],
    },
    view: {
      byId: [
        "colaName",
        "clientName",
        "physician",
        "eventName",
        "extOrderId",
        "labName",
        "extAccessionId",
        "isFasting",
        "dateCollected",
        "timeCollected",
        "timeCentrifuged",
        "collectDesc",
        "collectedBy",
      ],
      byGroups: [
        {
          ids: ["labName"],
        },
        {
          ids: ["colaName", "clientName", "physician"],
        },
        {
          ids: ["eventName", "extOrderId", "extAccessionId"],
        },
        {
          ids: ["collectDesc", "collectedBy", "isFasting"],
        },
        {
          ids: ["dateCollected", "timeCollected", "timeCentrifuged"],
        },
        {
          ids: ["copyToClient", "copyToPhysician"],
        },
      ],
    },
  },
  patientInformation: {
    title: "Patient Information",

    edit: {
      inputsById: [
        "patientDobMonth",
        "patientDobDate",
        "patientDobYear",
        "patientLastName",
        "patientFirstName",
        "patientMiddleName",
        "patientGenderCode",
        "patientRaceCode",
        "patientEthnicityCode",
        "patientSsn",
      ],

      inputsGroupsByHash: {
        group1: {
          title: "Patient date of birth",
          ids: [
            [
              "patientDobMonth",
              "patientDobDate",
              "patientDobYear",
              "patientLastName",
              "patientFirstName",
              "patientMiddleName",
            ],
          ],
        },
        group2: {
          ids: [[]],
        },
        group3: {
          ids: [
            [
              "patientGenderCode",
              "patientRaceCode",
              "patientEthnicityCode",
              "patientSsn",
            ],
          ],
        },
      },
      inputsGroupsById: ["group1", "group2", "group3"],
    },

    view: {
      byId: [
        "name",
        "patientGenderDesc",
        "patientDob",
        "patientRaceDesc",
        "patientEthnicityDesc",
        "patientSsn",
      ],
      byGroups: [
        {
          ids: [
            "name",
            "patientGenderDesc",
            "patientDob",
            "patientRaceDesc",
            "patientEthnicityDesc",
            "patientSsn",
          ],
        },
      ],
    },
  },
  patientHistory: {
    title: "Patient History",
    edit: {
      inputsById: [
        "patientCountryId",
        "patientStateId",
        "patientCity",
        "patientZip",
        "patientAddr1",
        "patientAddr2",
        "patientEmail",
        "patientHomePhone",
        "patientWorkPhone",
        "isSmoker",
        "isDiabetic",
        "isFamilyHistory",
        "isPatientHistory",
        "isStress",
        "isLowHdl",
        "isLipidMed",
        "isHypertension",
        "isBloodMed",
        "isMedNecessity",
        "sbp",
        "dbp",
        "weight",
        "weightUom",
        "heightFeet",
        "heightInches",
        "heightCm",
        "heightUom",
        "legacyIcdNote",
        "isCovidVac",
        "famHistT2DCode",
        "isSelfT2DGest",
      ],
      inputsGroupsByHash: {
        group1: {
          ids: [
            [
              "patientAddr1",
              "patientAddr2",
              "patientCountryId",
              "patientStateId",
              "patientCity",
              "patientZip",
            ],
            ["patientEmail", "patientHomePhone", "patientWorkPhone"],
            [
              "sbp",
              "dbp",
              "isSmoker",
              "isStress",
              "isHypertension",
              "isBloodMed",
            ],
            [
              "isDiabetic",
              "isLipidMed",
              "isLowHdl",
              "isMedNecessity",
              "isFamilyHistory",
              "isPatientHistory",
            ],
            ["isCovidVac", "isSelfT2DGest", "famHistT2DCode"],
            ["weight", "weightUom"],
            ["heightFeet", "heightInches", "heightCm", "heightUom"],
          ],
        },
      },
      inputsGroupsById: ["group1"],
    },
    view: {
      byId: [
        "address",
        "patientEmail",
        "isSmoker",
        "isDiabetic",
        "isFamilyHistory",
        "isPatientHistory",
        "isStress",
        "isLowHdl",
        "isLipidMed",
        "isHypertension",
        "isBloodMed",
        "isMedNecessity",
        "sbp",
        "dbp",
        "isCovidVac",
        "famHistT2DDesc",
        "isSelfT2DGest",
        "weight",
        "height",
        "isCovidVac",
        "isObesity",
      ],
      byGroups: [
        {
          ids: ["address", "patientEmail"],
        },
        {
          ids: ["patientWorkPhone", "patientHomePhone"],
        },
        {
          ids: ["weight", "height", "isObesity", "isCovidVac"],
        },
        {
          ids: ["isSmoker", "isDiabetic", "isPatientHistory", "isSelfT2DGest"],
        },
        {
          ids: ["isStress", "isLowHdl", "isFamilyHistory", "famHistT2DDesc"],
        },
        {
          ids: ["isHypertension", "isBloodMed", "isLipidMed"],
        },
        {
          ids: ["sbp", "dbp", "isMedNecessity"],
        },
      ],
    },
  },
};
