import React, { PureComponent } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import MainHeader from "components/templateParts/MainHeader";
import CreatePhysiciansStep1Container from "./PhysiciansCreateStep1Container";
import CreatePhysiciansStep2Container from "./PhysiciansCreateStep2Container";
import CreatePhysiciansStep3Container from "./PhysiciansCreateStep3Container";
import dataProvider, { CREATE } from "providers/dataProvider";
import withForm from "withForm";
import { physiciansResource } from "config/resources/physiciansResource";
import { compose } from "redux";
import { withNotificationCenter } from "NotificationCenter";
const queryString = require("query-string");

const styles = () => ({
  main: {
    margin: "32px 26px 0",
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    backgroundColor: "white",
    padding: "30px 15px 30px",
  },
  stepper: {
    background: "transparent",
    padding: "24px 24px 0",
  },
  controls: {
    textAlign: "center",
    padding: "39px 0 0",
    "& button": {
      margin: "0 10px",
    },
  },
});

const clientTableColConfig = [
  {
    id: "legacyClientId",
    label: "Client ID",
  },
  {
    id: "clientName",
    label: "Name",
  },
  {
    id: "clientPhone",
    label: "Phone Number",
  },
];

const steps = [
  "General Information",
  "Associated clients",
  "Confirm and Finish",
];
const withFormConfig = physiciansResource.createPhysConfig;
const inputsGroupsById = withFormConfig.inputsGroupsById;
const inputsGroupsByHash = withFormConfig.inputsGroupsByHash;

class PhysiciansCreateContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      selectedClients: [],
    };

    this.query = queryString.parse(props.location.search);
  }

  handleReset() {
    this.setState({
      activeStep: 0,
    });
  }

  handleSecondStepSelectedClients = (clients) => {
    this.setState({
      selectedClients: clients,
    });
  };

  handleNext = () => {
    let self = this;
    if (this.state.activeStep === 0) {
      if (self.props.isFormValid()) {
        self.props.resetErrors();
        this.setState((prevState, props) => {
          return { activeStep: prevState.activeStep + 1 };
        });
      }
    } else {
      this.setState((prevState, props) => {
        return { activeStep: prevState.activeStep + 1 };
      });
    }
  };

  handleBack = () => {
    if (this.state.activeStep === 0) {
      if (Object.prototype.hasOwnProperty.call(this.query, "relatedClient")) {
        this.props.history.push(
          "/clients/" + this.query.relatedClient + "/show"
        );
      } else {
        this.props.history.push("/physicians");
      }
    }
    this.setState((prevState, props) => {
      return { activeStep: prevState.activeStep - 1 };
    });
  };

  getClientsIds = () => {
    return this.state.selectedClients.map((client) => client.id);
  };

  getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <CreatePhysiciansStep1Container
            handleInputs={this.props.handleInputs}
            inputsById={this.props.inputsById}
            inputsByHash={this.props.inputsByHash}
            inputsGroupsById={inputsGroupsById}
            inputsGroupsByHash={inputsGroupsByHash}
          />
        );
      case 1:
        return (
          <CreatePhysiciansStep2Container
            colConfig={clientTableColConfig}
            handleSelectedClients={this.handleSecondStepSelectedClients}
            selectedClients={this.state.selectedClients}
          />
        );
      case 2:
        return (
          <CreatePhysiciansStep3Container
            inputsById={this.props.inputsById}
            inputsByHash={this.props.inputsByHash}
            inputsGroupsById={inputsGroupsById}
            inputsGroupsByHash={inputsGroupsByHash}
            colConfig={clientTableColConfig}
            selectedClients={this.state.selectedClients}
          />
        );
      default:
        return "Uknown stepIndex";
    }
  };

  submit = () => {
    const { history, notificationCenter } = this.props;
    let data = this.props.getSubmitData();

    data.clientIds = this.getClientsIds();

    dataProvider(CREATE, "physicians", { data: data })
      .then((response) => {
        notificationCenter.show("Successfully created", "info");
        if (Object.prototype.hasOwnProperty.call(this.query, "relatedClient")) {
          history.push("/clients/" + this.query.relatedClient + "/show");
        } else {
          history.push("/physicians/" + response.data.id + "/show");
        }
      })
      .catch((error) => {
        notificationCenter.show(error.message, "warning");
      });
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <div className={classes.root}>
        <MainHeader title="Create Physicians" />
        <div className={classes.main}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={classes.stepper}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
                <ButtonBase
                  className="button formButton formButton__secondaryOutlined"
                  onClick={this.handleReset}
                >
                  Reset
                </ButtonBase>
              </div>
            ) : (
              this.getStepContent(activeStep)
            )}
          </div>

          <div className={classes.controls}>
            <ButtonBase
              onClick={this.handleBack}
              className="button formButton formButton__secondaryOutlined"
            >
              Back
            </ButtonBase>
            <ButtonBase
              onClick={
                activeStep === steps.length - 1 ? this.submit : this.handleNext
              }
              className="button formButton formButton__mainContained"
            >
              {activeStep === steps.length - 1 ? "Create Physician" : "Next"}
            </ButtonBase>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withForm(withFormConfig),
  withStyles(styles),
  withNotificationCenter()
)(PhysiciansCreateContainer);
