import React, {PureComponent} from 'react';
import styles from './DateContainer.module.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import clsx from "clsx";

class DateField extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...props,
            date: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                date: Date.parse(this.props.value)
            })
        }
    }

    handleChange = (date) => {
        this.setState({date})
        const format = 'MM/DD/YYYY'
        const value = date === null ? '' : moment(date).format(format)
        let e = {target: { value, name: this.props.inputId}}
        this.props.handleInputs(e)
    }

    render() {
        const {input, inputId} = this.props;
        const {date} = this.state

        let minDate = new Date();
        minDate.setFullYear( minDate.getFullYear() - 100 );

        return (
            <div className={styles.root}>

                <DatePicker
                    showYearDropdown
                    minDate={minDate}
                    maxDate={new Date()}
                    name={inputId}
                    placeholderText={`${input.yup ? '*' : ''}${input.label}`}
                    selected={date}
                    onChange={this.handleChange}
                    className={clsx({
                        [styles.field]: true,
                        [styles.error]: input.error
                    })}
                />
            </div>

        );
    }
}

export default DateField;