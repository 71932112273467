import React from "react";
import SmartTable from "components/ui_components/table/SmartTable";
import helpers from "utils/helpers";

const tableColById = [
  "hdl",
  "ldl",
  "trig",
  "sdLdlC",
  "vLdlC",
  "cholHdl",
  "nonHdlC",
  "totalChol",
  "apoA1",
  "apoB",
  "apoBApoA1",
  "lp",
  "hsCrp",
  "hba1c",
  "igG",
  "igGReagent",
  "igM",
  "igMReagent",
  "covidAg",
  "IgGII",
  "elpBlpScore",
  "epbBlpCategory",
  "pulsScore",
  "puls",
  "dp",
  "dpInterpretation",
];

const tableColByHash = {
  apoA1: {
    label: "ApoA1",
  },
  apoB: {
    label: "ApoB",
  },
  apoBApoA1: {
    label: "ApoB / ApoA1*",
  },
  cholHdl: {
    label: "Chol / HDL*",
  },
  elpBlpScore: {
    label: "ELP / BLP Score",
  },
  epbBlpCategory: {
    label: "ELP / BLP Category",
  },
  hba1c: {
    label: "HbA1c",
  },
  hdl: {
    label: "HDL",
  },
  hsCrp: {
    label: "hs-CRP",
  },
  igG: {
    label: "Covid-2 IgG",
  },
  igM: {
    label: "Covid-2 IgM",
  },
  igGReagent: {
    label: "Covid-2 IgG Reagent",
  },
  igMReagent: {
    label: "Covid-2 IgM Reagent",
  },
  covidAg: {
    label: "Covid Ag",
  },
  IgGII: {
    label: "IgGII",
  },
  ldl: {
    label: "LDL",
  },
  lp: {
    label: "Lp(a)",
  },
  nonHdlC: {
    label: "Non-HDL-C*",
  },
  puls: {
    label: "PULS",
  },
  pulsScore: {
    label: "PULS Score",
  },
  sdLdlC: {
    label: "sd-LDL-C*",
  },
  trig: {
    label: "Triglycerides",
  },
  vLdlC: {
    label: "vLDL-C*",
  },
  totalChol: {
    label: "Total Chol",
  },
  dp: {
    label: "DP",
  },
  dpInterpretation: {
    label: "DP Interpretation",
  },
};

const ResultedTestsSubTable = ({ reqID }) => {
  return (
    <SmartTable
      resource={`requisitions/${reqID}/req_resulted_tests`}
      orderBy="reqId"
      columns={helpers.byHashByIDConfToArray(tableColById, tableColByHash)}
      isSubTable={true}
      showSelect={false}
      showPagination={false}
    />
  );
};

export default ResultedTestsSubTable;
