import React, { Component } from 'react'
import styles from './LocationsContainer.module.scss';
import { SmartTable } from 'components/ui_components/table'
import { compose } from 'redux'
import PropTypes from "prop-types";
import LocationsModalContainer from "./LocationsModalContainer";
import withForm from 'withForm';
import { locationsTabConfig } from "config/resources/requisitionsResource/index.js";
import { connect } from 'react-redux';
import dataProvider, { DELETE } from "providers/dataProvider";
import { openAddSpecimenLocationModal } from "actions";
import {TableHeaderAddButton} from 'components/ui_components/buttons';
import { PlusIcon, BroomIcon } from 'icons';

const { tableColConfig, createSpecimenLocationFormConfig } = locationsTabConfig;

class LocationsContainer extends Component {
  deleteCallback = (items, resource, loadData) => {
    let ids = items.map((i) => (i.id))
    const discardResource = `requisitions/${this.props.reqID}/spec_locs/bulk_discard?id=${ids}`;

    dataProvider(DELETE, discardResource)
    .then(({ data, total }) => {
      loadData()
    })
  }

  handleSubmitLocation = () => {
    this.smartTable.loadData()
  }

  render() {
    const { reqID, labID } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.main}>
          <SmartTable
            columns={tableColConfig}
            resource={`requisitions/${reqID}/spec_locs`}
            onRef={ref => (this.smartTable = ref)}
            showPagination={false}
            showSelect={true}
            showDelete={true}
            showSelectAll={true}
            deleteCallback={this.deleteCallback}
            deleteButtonIcon={<BroomIcon/>}
            perPage={-1}
            toolbarExtraComponents={
              <TableHeaderAddButton icon={<PlusIcon/>}
                                    onClick={this.props.openAddSpecimenLocationModal}>
                Place
              </TableHeaderAddButton>}
          />
        </div>

        <LocationsModalContainer
          modalOpen={this.props.addSpecimenLocationModalIsOpen}
          reqID={reqID}
          labID={labID}
          handleRowClick={this.handleClickRow}
          handleCloseModal={this.props.handleCloseModal}
          handleSubmitLocation={this.handleSubmitLocation}
        />
      </div>
    );
  }
}

LocationsContainer.propTypes = {
  reqID: PropTypes.string.isRequired
}

const mapStateToProps = ({ specimenLocations }) => ({
  addSpecimenLocationModalIsOpen: specimenLocations.addSpecimenLocationModalIsOpen
})

export default compose(
  connect(
    mapStateToProps,
    { openAddSpecimenLocationModal }
  ),
  withForm(createSpecimenLocationFormConfig),
)(LocationsContainer)