import {
    USER_READ_ALL_NOTIFICATIONS,
    USER_HAS_UNREAD_NOTIFICATIONS
} from './../config/actionTypes';

const initState = {
    userHasNotification: false
}

export default (state = initState, action) => {
    switch (action.type) {
        case USER_READ_ALL_NOTIFICATIONS:
            return {...state, userHasNotification: false}

        case USER_HAS_UNREAD_NOTIFICATIONS:
            return {...state, userHasNotification: true}

        default:
            return state
    }
}