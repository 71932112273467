import React, { useState } from 'react'
import FailPassCodesWidgetContainer from './FailPassCodesWidgetContainer';
import StatusWidgetContainer from './StatusWidgetContainer';
import styles from './StatusContainer.module.scss';
import BaseButton from '../../../../../ui_components/buttons/BaseButton'
import ConfirmModal from '../../../../../ui_components/modals/ConfirmModal'
import { compose } from 'redux'
import withModal from 'withModal'
import dataProvider, { POST } from '../../../../../../providers/dataProvider'
import { withRouter } from 'react-router-dom'
import ProgressBar from '../../../../../ui_components/ProgressBar/ProgressBar'

const baseStyles = `
    font-size: 13px;
    border-radius: 5px;
    padding: 10px 25px;
    transition: opacity .5s;
  `;

const StatusContainer = (props) => {
  const {
    history,
    modalOpen,
    handleOpenModal,
    handleCloseModal,
    modalID,
    reqID,
    reqStatusCode,
    handleRequisitionUpdate
  } = props;
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading && <ProgressBar />}
      <ConfirmModal
        title="Duplicated requisition will be treated as separate independent requisition. Are you sure you want to duplicate this requisition?"
        message=""
        modalIsOpen={modalOpen && modalID === "requisitionDuplicateModal"}
        handleCloseModal={handleCloseModal}
        handleSubmit={() => {
          setLoading(true);
          dataProvider(POST, `requisitions/${reqID}/copy`, {})
            .then((d) => {
              setLoading(false);
              history.push(`/requisitions/${d.data.id}/show`);
            })
          handleCloseModal();
        }}
      />
      <div className={styles.root}>
        <FailPassCodesWidgetContainer reqID={reqID}/>
        <StatusWidgetContainer
          reqID={reqID}
          reqStatusCode={reqStatusCode}
          handleRequisitionUpdate={handleRequisitionUpdate}
        />
      </div>
      <div className={styles.root}>
        <BaseButton
          themePaletteColor="primary.main"
          defaultStyles={baseStyles}
          onClick={handleOpenModal.bind(this, "requisitionDuplicateModal")}
        >
          Duplicate This Requisition
        </BaseButton>
      </div>
    </>
);
}

export default compose(
  withModal,
  withRouter,
)(StatusContainer)