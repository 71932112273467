import React, { useEffect, useState } from "react";
import { useNotificationCenter } from "NotificationCenter";
import axios from "axios";
import config from "config";
import { Typography, Box } from "@material-ui/core";
import ProgressBar from "components/ui_components/ProgressBar/ProgressBar";

const SubscribePageContainer = (props) => {
  const notificationCenter = useNotificationCenter();
  const verificationCode = props.match.params.token;
  const [subscribeStateChanged, setSubscribeStateChanged] = useState(false);
  const [sendDataInProgress, setSendDataInProgress] = useState(false);

  useEffect(() => {
    const endpoint =
      props.type === "resubscribe"
        ? `users/${verificationCode}/resubscribe`
        : `users/${verificationCode}/unsubscribe`;

    setSendDataInProgress(true);
    axios
      .request({
        method: "PUT",
        url: `${config.apiUrl}/${endpoint}`,
        data: {},
      })
      .then((response) => {
        setSendDataInProgress(false);
        setSubscribeStateChanged(true);
        notificationCenter.show(response.data.message);
      })
      .catch((err) => {
        setSendDataInProgress(false);
        notificationCenter.show(err.response.data.message, "error");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, verificationCode]);

  return (
    <div>
      <Box
        height={"100vh"}
        textAlign={"center"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {sendDataInProgress && <ProgressBar />}
        {subscribeStateChanged && (
          <Box maxWidth="1000px">
            <Typography variant={"h4"}>
              {props.type === "resubscribe"
                ? "Resubscribe successful."
                : "Unsubscribe successful."} 
            </Typography>
            <Typography variant={"h5"}>
              {props.type === "resubscribe"
                ? "You will receive email notification from PHDC LIS."
                : "You will no longer receive email notification from PHDC LIS."}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default SubscribePageContainer;
