import React, {PureComponent} from 'react'
import TableCell from '@material-ui/core/TableCell';
import {withStyles} from '@material-ui/core/styles';
import SmartInput from 'components/ui_components/smart_input'
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from "prop-types";
import helpers from '../../../../utils/helpers';


const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);


class TestFailCodeEditTableCell extends PureComponent {
    constructor(props) {
        super(props)
        const {col, row} = this.props;
        this.state = {
            isTooltipOpen: false,
            selectOptions: row[col.id] === 'NULL' ? [] : helpers.stringToArr(row[col.id])
        }
    }

    handleTooltipClose = () => {
        this.setState({
            isTooltipOpen: false
        })
    }

    handleTooltipOpen = () => {
        this.setState({
            isTooltipOpen: true
        })
    }

    handleInputs = (value) => {
        this.setState({
            selectOptions: value
        })
    }

    autoSaveCallBack = () => {
        this.props.loadDataSmartTableData()
    }

    render() {
        const {col, optionsData} = this.props;
        const {selectOptions} = this.state;

        return (
            <TableCell align="right">
                <HtmlTooltip
                    open={this.state.isTooltipOpen}
                    title={
                        <React.Fragment>
                            {selectOptions.map((selectOption, index) => {
                                if (selectOption === '') return false;
                                const optionsDataItem = optionsData.find(option => option.failCode === selectOption)
                                if (!optionsDataItem) return false;
                                return (
                                    <div key={index}>
                                        {optionsDataItem.failCode} - {optionsDataItem.failDesc}
                                    </div>
                                )
                            })}
                        </React.Fragment>
                    }
                >

                <SmartInput
                    onMouseOver={selectOptions.length > 0 ? this.handleTooltipOpen : null}
                    onMouseLeave={this.handleTooltipClose}
                    reqID={this.props.reqID}
                    testID={this.props.testID}
                    autoSaveCallBack={this.autoSaveCallBack}
                    input={{
                        id: col.id,
                        type: "testFailCodeMultiSelect",
                        cache: col.cache,
                        resource: col.resource,
                        accessor: col.accessor,
                        autoSave: col.autoSave,
                        value: selectOptions
                    }}
                    optionsInputData={optionsData}
                    inputId={col.id}
                    onChange={this.handleInputs}
                />

                </HtmlTooltip>
            </TableCell>)
    }
}

export default TestFailCodeEditTableCell


TestFailCodeEditTableCell.propTypes = {
    optionsData: PropTypes.array.isRequired
}

TestFailCodeEditTableCell.defaultProps = {
    optionsData: []
}