import { newPassword, newPasswordConfirm } from 'config/inputs';

export default {
  inputsById: [
    'newPwd', 'newPwdConfirm'
  ],

  inputsByHash: {
    newPwd: newPassword,
    newPwdConfirm: newPasswordConfirm,
  }
}