let yup = require("yup");

export const salesResource = {
  tableColConfig: [
    {
      id: "salesFirstName",
      label: "First Name",
    },
    {
      id: "salesLastName",
      label: "Last Name",
    },
    {
      id: "salesEmail",
      label: "Email",
    },
    {
      id: "salesTitle",
      label: "Profession",
    },
  ],
  createConfig: {
    inputsById: [
      "salesFirstName",
      "salesMiddleName",
      "salesLastName",
      "salesEmail",
      "salesPhone",
      "salesTitle",
      "reportToId",
      "salesCompanyName",
      "salesNote",
      "salesTypeCode",
      "salesRepCode",
    ],

    inputsGroupsByHash: {
      group1: {
        title: "Sales Information",
        ids: [
          ["salesFirstName", "salesMiddleName", "salesLastName"],
          ["salesTitle"],
          ["salesEmail", "salesPhone"],
          ["salesCompanyName"],
          ["salesRepCode"],
          ["salesTypeCode"],
          ["reportToId"],
          ["salesNote"],
        ],
      },
    },

    inputsGroupsById: ["group1"],

    inputsByHash: {
      salesFirstName: {
        label: "First Name",
        yup: yup
          .string()
          .required("First Name is required")
          .nullable("First Name is required"),
      },
      salesMiddleName: {
        label: "Middle Name",
      },
      salesLastName: {
        label: "Last Name",
        yup: yup
          .string()
          .required("Last Name is required")
          .nullable("Last Name is required"),
      },
      salesEmail: {
        label: "Email",
        yup: yup
          .string()
          .required("Email is required")
          .nullable("Email is required"),
      },
      salesPhone: {
        label: "Phone",
      },
      salesTitle: {
        label: "Title",
        type: "select",
        options: [
          { value: "Manager", label: "Manager" },
          {
            value: "Sales Rep.",
            label: "Sales Rep.",
          },
          { value: "Service Rep.", label: "Service Rep." },
          {
            value: "VP of Sales",
            label: "VP of Sales",
          },
          {
            value: "Regional Sales Director",
            label: "Regional Sales Director",
          },
        ],
        yup: yup
          .string()
          .required("Profession is required")
          .nullable("Profession is required"),
      },
      reportToId: {
        label: "Report To",
        type: "autocomplete",
        resource: "sales",
        accessor: (d) => ({ value: d.id, label: `${d.salesFirstName} ${d.salesLastName}` }),
      },
      salesCompanyName: {
        label: "Sales company name",
      },
      salesRepCode: {
        label: "Sales Rep code",
      },
      salesNote: {
        label: "Sales note",
        type: "multiline",
        styles: `width: 100%`,
      },
      salesTypeCode: {
        label: "Sales Type Code",
        type: "autocomplete",
        resource: "lovs/SALESTYPE",
        accessor: (d) => ({ value: d.salesTypeCode, label: d.salesTypeDesc }),
        yup: yup
          .string()
          .required("Sales Type Code is required")
          .nullable("Sales Type Code is required"),
      },
    },
  },
};
